import { isEmpty, isNil } from "lodash";
import { GlAlert } from "models/alert.model";

export const pipeName = "alertFilterByLevel";
export const alertFilterByLevelPipe: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (alerts: GlAlert[], levels: string[]) => {
    if (isNil(alerts) || isNil(levels) || isEmpty(levels)) {
      return [];
    }

    // filter by level
    return alerts?.filter((a) => levels.includes(a.level));
  };
};
