import { ThreadService } from "./thread.service";
import { GlThread, GlMessage } from "../../../models/messages.model";

export class ThreadFacadeService {
  static injectionName = "ThreadFacadeService";
  private threads: GlThread[] = [];
  constructor(private ThreadService: ThreadService) {
    "ngInject";
  }

  init(
    patientRecordId?: number,
    clinicId?: number,
    unread?: number,
    clinicMessages?: number
  ) {
    this.reset();
    return this.ThreadService.getAll(
      patientRecordId,
      clinicId,
      unread,
      clinicMessages
    ).then((threads) => {
      this.threads = threads;
      return this.threads;
    });
  }

  reset() {
    this.threads = [];
  }

  newThread(message: string, patientRecordId: number) {
    // Post new message
    return this.ThreadService.newThread(message, patientRecordId).then(
      (updatedThread) => {
        this.threads = [...this.threads, updatedThread];
        return updatedThread;
      }
    );
  }

  newMessage(message: string, thread: GlThread) {
    // Post Reply
    return this.ThreadService.postReply(thread.id, message).then(
      (updatedThread) => {
        this.threads = this.threads.map((existingThread) => {
          return existingThread.id === updatedThread.id
            ? updatedThread
            : existingThread;
        });
        return updatedThread;
      }
    );
  }

  editMessage(message: string, editMessage: GlMessage) {
    // Edit Message
    return this.ThreadService.editMessage(editMessage.id, message).then(
      (updatedThread) => {
        this.threads = this.threads.map((existingThread) => {
          return existingThread.id === updatedThread.id
            ? updatedThread
            : existingThread;
        });
        return updatedThread;
      }
    );
  }

  delete(message: GlMessage) {
    // Delete
    return this.ThreadService.deleteMessage(message.id).then(
      (updatedThread) => {
        this.threads = this.threads.map((existingThread) => {
          return existingThread.id === updatedThread.id
            ? updatedThread
            : existingThread;
        });
        return updatedThread;
      }
    );
  }

  getThreads() {
    return this.threads;
  }
}
