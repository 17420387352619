import { GlClinicActiveRecord } from "models/clinic-active-records.model";
import { ClinicData } from "models/clinic.model";
import { IGlPatientData, User } from "models/user.model";
import * as moment from "moment";
import {
  DocumentImportService,
  IDocumentImportMatchError,
  IDocumentImportMatchUpload,
} from "../../services/document-import/document-import.service";
import "./document-uploads.scss";

export interface IDocumentMatchWithId extends IDocumentImportMatchUpload {
  id: number;
  patient: User;
}

export interface IDocumentErrorWithId extends IDocumentImportMatchError {
  id: number;
  patient: User;
}

const STORAGE_KEY_LAST_CHECK_TIME = "gl:lastCheckTime";
const STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL = "gl:documentUploadUpdateInterval";

const UPDATE_INTERVAL_MANUAL = "Manual";
const UPDATE_INTERVAL_2_MINS = "2 Mins";
const UPDATE_INTERVAL_5_MINS = "5 Mins";
const UPDATE_INTERVAL_10_MINS = "10 Mins";
const UPDATE_INTERVAL_30_MINS = "30 Mins";

export class DocumentUploadsController
  implements
    angular.IComponentController,
    angular.IOnChanges,
    angular.IOnDestroy {
  documentImportIsEnabled = false;
  watchUploads = false;

  // @Inputs()
  clinicConfig: ClinicData;
  activeRecords: GlClinicActiveRecord[] = [];

  checkInProgress = false;
  patientCheckProgressCounter = 0;
  patientCheckProgressTotal = 0;
  lastUpdateTime: Date;
  uploadInProgress = false; // No uploads at the moment

  documents: IDocumentMatchWithId[] = [];
  errors: IDocumentErrorWithId[] = [];
  nextAutomaticCheckTimer: angular.IPromise<void>;

  updateIntervals = [
    UPDATE_INTERVAL_MANUAL,
    UPDATE_INTERVAL_2_MINS,
    UPDATE_INTERVAL_5_MINS,
    UPDATE_INTERVAL_10_MINS,
    UPDATE_INTERVAL_30_MINS,
  ];

  updateInterval =
    this.$window.localStorage.getItem(STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL) ||
    UPDATE_INTERVAL_10_MINS;

  constructor(
    private $q: angular.IQService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private DocumentImportService: DocumentImportService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.clinicConfig && this.clinicConfig) {
      this.checkIfDocumentUploadsAreEnabled();
    }
    if (this.documentImportIsEnabled) {
      this.lastUpdateTime = this.getNextUpdateTime();
      this.watchUploads = !!this.lastUpdateTime;
      if (
        this.watchUploads &&
        this.activeRecords &&
        this.activeRecords.length > 0
      ) {
        this.initAutoCheckTimer();
      } else {
        this.cancelAutomaticTimer();
      }
    }
  }

  $onDestroy() {
    this.cancelAutomaticTimer();
  }

  stopWatch() {
    this.watchUploads = false;
    this.$window.localStorage.removeItem(STORAGE_KEY_LAST_CHECK_TIME);
  }

  startWatch() {
    this.watchUploads = true;
    this.doAutomaticCheck();
  }

  dismissError(id: number) {
    this.errors = this.errors.filter((e) => e.id !== id);
  }

  checkNow() {
    this.setUpdateTime();
    this.patientCheckProgressCounter = 0;
    this.checkInProgress = true;
    this.documents = [];
    this.errors = [];
    const activeRecordsToImport = this.DocumentImportService.patientsToCheckForDocuments(
      this.activeRecords || []
    );

    // this.importOrthancDocForActiveRecord(this.activeRecords[0]).finally(
    //   () => (this.checkInProgress = false)
    // );
    this.patientCheckProgressTotal = activeRecordsToImport.length;
    activeRecordsToImport
      .reduce((promises, ar, index) => {
        return promises.then(() => {
          this.patientCheckProgressCounter = index + 1;
          return promises.then(() => this.importOrthancDocForActiveRecord(ar));
        });
      }, this.$q.resolve())
      .finally(() => {
        this.checkInProgress = false;
      });
  }

  removeUpload(id: number) {
    this.documents = this.documents.filter((d) => d.id !== id);
  }

  updateTimerInterval() {
    this.$window.localStorage.setItem(
      STORAGE_KEY_DOCUMENT_UPDATE_INTERVAL,
      this.updateInterval
    );
    this.cancelAutomaticTimer();
    this.initAutoCheckTimer();
  }

  private cancelAutomaticTimer() {
    if (this.nextAutomaticCheckTimer) {
      this.$timeout.cancel(this.nextAutomaticCheckTimer);
      this.nextAutomaticCheckTimer = null;
    }
  }

  private initAutoCheckTimer() {
    if (
      this.nextAutomaticCheckTimer ||
      this.updateInterval === UPDATE_INTERVAL_MANUAL
    ) {
      // do nothing as the time is already running;
      return;
    }
    const now = moment();
    if (now.isAfter(this.getNextUpdateTime())) {
      this.doAutomaticCheck();
    } else {
      this.setNextUpdateInterval();
    }
  }

  private doAutomaticCheck() {
    this.checkNow();
    this.setNextUpdateInterval();
  }

  private setNextUpdateInterval() {
    this.nextAutomaticCheckTimer = this.$timeout(
      () => this.doAutomaticCheck(),
      moment(this.getNextUpdateTime()).diff(moment(), "milliseconds")
    );
  }

  private importOrthancDocForActiveRecord(ar: GlClinicActiveRecord) {
    return this.DocumentImportService.importPatientDocumentsFromForum(
      (ar.patient.data as IGlPatientData).file_no
      // "02346"
    )
      .then((orthancInstances) =>
        this.DocumentImportService.automaticallyUploadDocumentsForRecord(
          ar.record,
          orthancInstances
        )
      )
      .then((results) => {
        this.addUploads(results.uploads, ar.patient);
        this.addErrors(results.errors, ar.patient);
      })
      .catch((error) => {
        console.error("Error importing documents for ", ar, error);
      });
  }

  private addUploads(documents: IDocumentImportMatchUpload[], patient: User) {
    documents.forEach((d) => {
      this.documents.push({
        ...d,
        patient,
        id: Math.random(),
      });
    });
  }

  private addErrors(errors: IDocumentImportMatchError[], patient: User) {
    errors.forEach((d) => {
      this.errors.push({
        ...d,
        patient,
        id: Math.random(),
      });
    });
  }

  private checkIfDocumentUploadsAreEnabled() {
    const {
      dicom_server_aet: orthancAet,
      dicom_server_url: orthancUrl,
      forum_server_aet: forumAet,
    } = this.clinicConfig;
    this.documentImportIsEnabled = !!orthancUrl || !!orthancAet || !!forumAet;
    if (this.documentImportIsEnabled) {
      this.DocumentImportService.setConfig({
        ...(orthancUrl && { orthancUrl }),
        ...(orthancAet && { orthancAet }),
        ...(forumAet && { forumAet }),
      });
    }
    return this.documentImportIsEnabled;
  }

  private getNextUpdateTime() {
    const updateInterval = this.getUpdateIntervalInMins();

    const lastUpdateTimeFromLocalStorage = this.$window.localStorage.getItem(
      STORAGE_KEY_LAST_CHECK_TIME
    );
    return (
      lastUpdateTimeFromLocalStorage &&
      moment(lastUpdateTimeFromLocalStorage)
        .add(updateInterval, "minutes")
        .toDate()
    );
  }

  private getUpdateIntervalInMins() {
    switch (this.updateInterval) {
      case UPDATE_INTERVAL_2_MINS:
        return 2;
      case UPDATE_INTERVAL_5_MINS:
        return 5;
      case UPDATE_INTERVAL_10_MINS:
        return 10;
      case UPDATE_INTERVAL_30_MINS:
        return 30;
    }
  }

  private setUpdateTime() {
    const now = new Date();
    this.$window.localStorage.setItem(
      STORAGE_KEY_LAST_CHECK_TIME,
      now.toISOString()
    );
    this.lastUpdateTime = now;
  }
}

export class DocumentUploadsComponent implements angular.IComponentOptions {
  static selector = "documentUploads";
  static template = require("./document-uploads.html");
  static controller = DocumentUploadsController;
  static bindings = {
    activeRecords: "<",
    clinicConfig: "<",
  };
}
