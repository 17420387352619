import { every, filter, isNil, orderBy, sortBy } from "lodash";
import {
  GlVisitDrop,
  GlVisitDropDilation,
  IGlVisitDropDilationState,
} from "models/patient-record.model";
import moment = require("moment");

/**
 * Contains key helpers for Visit Drop including ways to keep track of the dilation drops
 */
export class VisitDropHelperService {
  static injectionName: string = "VisitDropHelperService";
  ALMOST_FINISHED_MINS_CUTOFF: number = 5;

  constructor() {
    "ngInject";
  }

  // has active dilation timer? just show all honestly me thinks
  getActiveDilationDrops(visitDrops: GlVisitDrop[]) {
    const sorted: GlVisitDrop[] = filter(
      sortBy(visitDrops ?? [], [
        (o) => {
          return Date.parse(o?.dilation?.end_time);
        },
      ]),
      (d) => !isNil(d.dilation)
    );
    return sorted;
  }

  // get by state
  getDropsByDilationState(
    visitDrops: GlVisitDrop[],
    state: IGlVisitDropDilationState
  ) {
    if (isNil(visitDrops)) {
      return [];
    }
    // else check
    switch (state) {
      case "NONE":
        // no dilation timer instantiated
        return visitDrops.filter((d) => isNil(d?.dilation));
      // other cases are a fall through thing
      case "ONGOING":
        return visitDrops.filter((d) => this.dilationTimerOngoing(d?.dilation));
      case "FINISHED":
        return visitDrops.filter((d) =>
          this.dilationTimerFinished(d?.dilation)
        );
      case "COMPLETE":
        return visitDrops.filter((d) => d?.dilation?.state === state);
      default:
        return [];
    }
  }

  // completed dilation timers that arent complete yet
  getDilationTimerStatus(
    dilation: GlVisitDropDilation
  ): IGlVisitDropDilationState {
    if (isNil(dilation)) {
      return "NONE";
    } else if (this.dilationTimerComplete(dilation)) {
      return "COMPLETE";
    } else if (this.dilationTimerFinished(dilation)) {
      return "FINISHED";
    } else if (this.dilationTimerOngoing(dilation)) {
      return "ONGOING";
    } else {
      return "NONE";
    }
  }

  // EXPLICIT DEFINITIONS OF STATES
  // ongoing is when the end time is greater than current time
  dilationTimerOngoing(dilation: GlVisitDropDilation) {
    if (!isNil(dilation) && !isNil(dilation?.end_time)) {
      // create objects of both
      const currTime = moment();
      const finishTime = moment(dilation?.end_time);
      // do same or after comparison
      return currTime.isBefore(finishTime) && dilation?.state === "ONGOING";
    }
    return false;
  }

  // pseudo state
  dilationTimerAlmostFinished(dilation: GlVisitDropDilation) {
    if (!isNil(dilation) && !isNil(dilation?.end_time)) {
      // must be ongoing
      if (!this.dilationTimerOngoing(dilation)) {
        return false;
      }

      const currTime = moment();
      const finishTime = moment(dilation?.end_time);
      const diffDuration = moment.duration(finishTime.diff(currTime));
      // less than 0 hours because thats already in finished realm

      return (
        // check if close to done hours wise
        diffDuration.hours() <= 0 &&
        // mins between 0 and cutoff
        diffDuration.minutes() >= 0 &&
        diffDuration.minutes() <= this.ALMOST_FINISHED_MINS_CUTOFF &&
        // account for overall as well
        currTime.isBefore(finishTime)
      );
    }
    return false;
  }

  // dilation finished but not marked as complete
  dilationTimerFinished(dilation: GlVisitDropDilation) {
    if (!isNil(dilation) && !isNil(dilation?.end_time)) {
      // create objects of both
      const currTime = moment();
      const finishTime = moment(dilation?.end_time);
      // do same or after comparison
      return (
        currTime.isSameOrAfter(finishTime) && dilation?.state !== "COMPLETE"
      );
    }
    return false;
  }

  // completed?
  dilationTimerComplete(dilation: GlVisitDropDilation) {
    return dilation?.state === "COMPLETE";
  }

  // TIMERS
  getDilationTimeRemaining(dilation: GlVisitDropDilation): moment.Duration {
    if (!dilation) {
      return null;
    }
    // time
    const currTime = moment();
    const endTime = moment(dilation?.end_time);
    const diff = moment.duration(endTime.diff(currTime));
    return diff;
  }

  // get
  getDropTooltipText(drop: GlVisitDrop) {
    const { dilation } = drop;
    const dilationState: IGlVisitDropDilationState =
      this.getDilationTimerStatus(dilation);

    switch (dilationState) {
      case "NONE":
        return "";
      case "ONGOING":
        return `${this.getDilationTimeRemainingAsText(dilation)} remaining`;
      // finished and complete wont have text at all
      case "FINISHED":
        return "Review post dilation IOP";
      case "COMPLETE":
        return "Post dilation IOP completed";
      default:
        return "";
    }
  }

  getDilationTimeRemainingAsText(dilation: GlVisitDropDilation): string {
    const timeDiff: moment.Duration = this.getDilationTimeRemaining(dilation);
    if (!timeDiff) {
      return "";
    }
    return `${timeDiff.hours()} hr ${timeDiff.minutes()} mins`;
  }

  // returns the latest dilation timer which is active
  getSoonestOngoingDilationDrop(vistiDrops: GlVisitDrop[]): GlVisitDrop {
    const dilationTimers: GlVisitDrop[] = filter(
      vistiDrops ?? [],
      (d: GlVisitDrop) => this.dilationTimerOngoing(d?.dilation)
    );

    const sortedByFinishTime: GlVisitDrop[] = orderBy(
      dilationTimers,
      (d) => new Date(d?.dilation?.end_time)
    );
    if (sortedByFinishTime?.length) {
      return sortedByFinishTime[0];
    }
    return null;
  }

  allDilationTimersFinished(dilationDrops: GlVisitDrop[]) {
    // otherwise check if all complete or finished
    // will fail if none because what can you even sign?
    return every(
      dilationDrops,
      (d) =>
        this.dilationTimerFinished(d?.dilation) ||
        this.dilationTimerComplete(d?.dilation)
    );
  }

  allDilationDropsValueFilled(dilationDrops: GlVisitDrop[]) {
    // all of the drops must have a value in the iop_after left or right field
    return every(
      dilationDrops,
      (d) =>
        !isNil(d?.dilation?.iop_after?.left) &&
        !isNil(d?.dilation?.iop_after?.right)
    );
  }

  private calculateDilationEndTimeDate(
    time: Date | string,
    hours: number,
    minutes: number
  ) {
    return moment(time)
      .add(hours ?? 0, "hours")
      .add(minutes ?? 0, "minutes")
      .toDate();
  }
}
