// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { PrintLetterPage } from "./print-letter";
import { AuthService } from "../../core/services/auth.service";
import { LetterTemplateMcesComponent } from "./components/letter-template-mces/letter-template-mces";
import { LetterTemplateSunburyComponent } from "./components/letter-template-subury/letter-template-sunbury";
import { PdfTemplateMcesComponent } from "./components/pdf-template-mces/pdf-template-mces";
import { PdfTemplateSunburyComponent } from "./components/pdf-template-subury/pdf-template-sunbury";
import { LetterContainerComponent } from "./components/letter-conatainer/letter-container.component";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("print-letter", {
    url:
      "/patient/{patientId:int}/letter/{letterId:string}/print?{print:bool}&{pdf:bool}&{email:bool}&{addressees:string}",
    component: PrintLetterPage.selector,
    data: {
      requiresLogin: true,
    },
    resolve: {
      user: (AuthService: AuthService) => {
        "ngInject";
        return AuthService.getUser();
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.print-letter", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(PrintLetterPage.selector, PrintLetterPage)
  .component(LetterTemplateMcesComponent.selector, LetterTemplateMcesComponent)
  .component(
    LetterTemplateSunburyComponent.selector,
    LetterTemplateSunburyComponent
  )
  .component(PdfTemplateMcesComponent.selector, PdfTemplateMcesComponent)
  .component(PdfTemplateSunburyComponent.selector, PdfTemplateSunburyComponent)
  .component(LetterContainerComponent.selector, LetterContainerComponent)
  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
