export const pipeName = "vaccine";

export function vaccinePipe() {
  "ngInject";
  return (vaxType: string) => {
    switch (vaxType) {
      case "novax":
        return "Patient has not had a COVID vaccine.";
      case "singledose":
        return "Patient has had a single dose of the COVID vaccine.";
      case "doubledosed":
        return "Patient has had a double dose of the COVID vaccine.";
      case "avappointment":
        return "Patient is awaiting a COVID vaccine appointment.";
      case "medicalexemption":
        return "Patient has a medical exemption from taking the COVID vaccine.";
      case "refusal":
        return "Patient refuses to get the COVID vaccine.";
      default:
        return "<p>No vaccine status has been selected.</p>";
    }
  };
}
