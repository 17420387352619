// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { AdminVisitSummaryComponent } from "./components/data/admin-visit-summary/admin-visit-summary";
import { BilateralDicomFieldComponent } from "./components/data/bilateral-dicom-field/bilateral-dicom-field";
import { BilateralHeader } from "./components/data/bilateral-header/bilateral-header";
import { BilateralNumber } from "./components/data/bilateral-number/bilateral-number";
import { BilateralRiskCalculator } from "./components/data/bilateral-risk-calculator/bilateral-risk-calculator";
import { BilateralSelect } from "./components/data/bilateral-select/bilateral-select";
import { BilateralTextInput } from "./components/data/bilateral-text-input/bilateral-text-input";
import { BilateralTextareaComponent } from "./components/data/bilateral-textarea/bilateral-textarea";
import { ButtonRecordLockedComponent } from "./components/data/button-record-locked/button-record-locked";
import { ButtonRecordProgressComponent } from "./components/data/button-record-progress/button-record-progress";
import { ButtonRecordSaveSignComponent } from "./components/data/button-record-save-sign/button-record-save-sign";
import { CreateProviderComponent } from "./components/data/create-provider/create-provider";
import { DayProceduresComponent } from "./components/data/day-procedures/day-procedures";
import { DicomFieldComponent } from "./components/data/dicom-field/dicom-field";
import { DrugSearchComponent } from "./components/data/drug-search/drug-search";
import { EmailLetterComponent } from "./components/data/email-letter/email-letter";
import { GeneralFindings } from "./components/data/general-findings/general-findings";
import { GlDefaultsComponent } from "./components/data/gl-defaults/gl-defaults.component";
import { GlDrop } from "./components/data/gl-drop/gl-drop";
import { GlDrug } from "./components/data/gl-drug/gl-drug";
import { GlFavouriteGroup } from "./components/data/gl-favourite-group/gl-favourite-group";
import { GlIopComponent } from "./components/data/gl-iop/gl-iop";
import { GlLetterClinicalDataComponent } from "./components/data/gl-letter-clinical-data/gl-letter-clinical-data";
import { GlLetterDropComponent } from "./components/data/gl-letter-drop/gl-letter-drop";
import { GlLetterObservationsTableComponent } from "./components/data/gl-letter-observations-table/gl-letter-observations-table";
import { GlLetterSignatureComponent } from "./components/data/gl-letter-signature/gl-letter-signature";
import { GlLetter } from "./components/data/gl-letter/gl-letter";
import { glLetterConfiguration } from "./components/data/gl-letter/gl-letter.config";
import { GlPatientContactComponent } from "./components/data/gl-patient-contact/gl-patient-contact";
import { GlPatientProviderComponent } from "./components/data/gl-patient-provider/gl-patient-provider";
import { GlPatientRegisteredProvider } from "./components/data/gl-patient-registered-provider/gl-patient-registered-provider";
import { GlPrescriptionComponent } from "./components/data/gl-prescription/gl-prescription";
import { GlProcedureComponent } from "./components/data/gl-procedure/gl-procedure";
import { GlSurgeryComponent } from "./components/data/gl-surgery/gl-surgery";
import { ManagementWhoWhatWhenComponent } from "./components/data/management-who-what-when/management-who-what-when";
import { MedicalHistory } from "./components/data/medical-history/medical-history";
import { MessengerMessages } from "./components/data/messenger-messages/messenger-messages";
import { MessengerThreads } from "./components/data/messenger-threads/messenger-threads";
import { PatientDemographicsFormComponent } from "./components/data/patient-demographics-form/patient-demographics-form";
import { PatientDemographicsComponent } from "./components/data/patient-demographics/patient-demographics";
import { PatientDiagnosisModifierComponent } from "./components/data/patient-diagnosis-modifier/patient-diagnosis-modifier.component";
import { PatientDiagnosisComponent } from "./components/data/patient-diagnosis/patient-diagnosis";
import { PatientDocumentComponent } from "./components/data/patient-document/patient-document";
import { PatientDropsComponent } from "./components/data/patient-drops/patient-drops";
import { PatientDrugTable } from "./components/data/patient-drug-table/patient-drug-table";
import { PatientDrugsComponent } from "./components/data/patient-drugs/patient-drugs";
import { PatientHeader } from "./components/data/patient-header/patient-header";
import { PatientHistoryDropsComponent } from "./components/data/patient-history-drops/patient-history-drops";
import { PatientHistory } from "./components/data/patient-history/patient-history";
import { PatientInfo } from "./components/data/patient-info/patient-info";
import { PatientInjectionsComponent } from "./components/data/patient-injections/patient-injections";
import { PatientLetters } from "./components/data/patient-letters/patient-letters";
import { PatientNotesComponent } from "./components/data/patient-notes/patient-notes";
import { PatientPrescriptionsComponent } from "./components/data/patient-prescriptions/patient-prescriptions";
import { PatientProcedures } from "./components/data/patient-procedures/patient-procedures";
import { PatientProviders } from "./components/data/patient-providers/patient-providers";
import { PatientUploadEditComponent } from "./components/data/patient-upload-edit/patient-uploads-edit.component";
import { PatientUploads } from "./components/data/patient-uploads/patient-uploads";
import { PatientVaccine } from "./components/data/patient-vaccine/patient-vaccine";
import { PatientVaccineLabel } from "./components/data/patient-vaccine/vaccine-label/vaccine-label";
import { PosteriorLens } from "./components/data/posterior-lens/posterior-lens";
import { RefractionComponent } from "./components/data/refraction/refraction";
import { SegmentAnterior } from "./components/data/segment-anterior/segment-anterior";
import { SegmentCct } from "./components/data/segment-cct/segment-cct";
import { SegmentClinicalData } from "./components/data/segment-clinical-data/segment-clinical-data";
import { SegmentDocuments } from "./components/data/segment-documents/segment-documents";
import { SegmentField } from "./components/data/segment-field/segment-field";
import { SegmentGonio } from "./components/data/segment-gonio/segment-gonio";
import { SegmentLensStatus } from "./components/data/segment-lens-status/segment-lens-status";
import { SegmentLettersComponent } from "./components/data/segment-letters/segment-letters";
import { SegmentManagementCommentsComponent } from "./components/data/segment-management-comments/segment-management-comments";
import { SegmentManagement } from "./components/data/segment-management/segment-management";
import { SegmentPosterior } from "./components/data/segment-posterior/segment-posterior";
import { SegmentVirtualRecord } from "./components/data/segment-virtual-record/segment-virtual-record";
import { SegmentVisitProcedures } from "./components/data/segment-visit-procedures/segment-visit-procedures";
import { SubscriptionComponent } from "./components/data/subscription/subscription";
import { UserFavouriteDrugsGroup } from "./components/data/user-favourite-drugs-group/user-favourite-drugs-group";
import { VaComponent } from "./components/data/va/va";
import { VisitDropHistoryComponent } from "./components/data/visit-drop-history/visit-drop-history";
import { VisitDropComponent } from "./components/data/visit-drop/visit-drop";
import { VisitWhatComponent } from "./components/data/visit-what/visit-what";
import { CreditCardComponent } from "./components/other/credit-card/credit-card";
import { PatientBillingDetailsComponent } from "./components/other/patient-billing-details/patient-billing-details";
import { SimpleHeaderComponent } from "./components/other/simple-header/simple-header";
import { UpdateCreditCardComponent } from "./components/other/update_credit_card/update_credit_card";
import { CopyButton } from "./components/ui/button-copy/button-copy";
import { ButtonPlinyComponent } from "./components/ui/button-pliny/button-pliny.component";
import { RecordButton } from "./components/ui/button-record/button-record";
import { ButtonUploadsComponent } from "./components/ui/button-uploads/button-uploads.component";
import { Card } from "./components/ui/card/card";
import { GlRow } from "./components/ui/data-row/data-row";
import { GlButtonEyeComponent } from "./components/ui/gl-button-eye/gl-button-eye";
import { GlDocumentComponent } from "./components/ui/gl-document/gl-document";
import { GlSpinner } from "./components/ui/gl-spinner/gl-spinner";
import { GlTextarea } from "./components/ui/gl-textarea/gl-textarea";
import { GlNavBar } from "./components/ui/navbar/navbar.component";
import { PlinyLogoComponent } from "./components/ui/pliny-logo/pliny-logo.component";
import { Row } from "./components/ui/row/row";
import { View } from "./components/ui/view/view";
import { GlEmbedSrcDirective } from "./directive/embed-src/embed-src";
import { GlModel } from "./directive/gl-model";
import { GlOnDestroyDirective } from "./directive/gl-on-destroy";
import { abbreviate, abbreviatePipeName } from "./pipes/abbreviate";
import {
  activeReferral,
  pipeName as activeReferralPipeName,
} from "./pipes/active-referral.pipe";
import { agePipe, pipeName as agePipeName } from "./pipes/age.pipe";
import {
  alertFilterByLevelPipe,
  pipeName as alertFilterByLevelPipeName,
} from "./pipes/alert-filter-by-level.pipe";
import {
  alertLevelToClassPipe,
  pipeName as alertLevelToClassPipeName,
} from "./pipes/alert-level-to-class.pipe";
import { pipe as bcvaPipe, pipeName as bcvaPipeName } from "./pipes/bcva.pipe";
import { capitalize, capitalizePipeName } from "./pipes/capitalize";
import { cleanDeep, pipeName } from "./pipes/deep-clean";
import {
  dicomFieldById,
  pipeName as dicomFieldByIdPipeName,
} from "./pipes/dicom-field-by-id.pipe";
import {
  documentNamePipe,
  pipeName as documentNamePipeName,
} from "./pipes/document-name.pipe";
import {
  drugRowClassTypePipe,
  pipeName as drugRowClassTypePipeName,
} from "./pipes/drug-row-class-type.pipe";
import {
  drugsHasAuthorityDrugPipe,
  pipeName as drugsHasAuthorityDrugPipeName,
} from "./pipes/drugs-has-authority-drug.pipe";
import { eyeOrder, pipeName as eyeOrderPipeName } from "./pipes/eye-order";
import { eyeSide, pipeName as eyeSidePipeName } from "./pipes/eye-side";
import {
  favouriteGroupIdsToNamesPipe,
  pipeName as favouriteGroupIdsToNamesPipeName,
} from "./pipes/favourite-group-ids-to-names.pipe";
import {
  formatDrugDisplayName,
  pipeName as formatDrugDisplayNamePipeName,
} from "./pipes/format-drug-display-name.pipe";
import {
  formatPrescriptionNumber,
  pipeName as formatPrescriptionNumberPipeName,
} from "./pipes/format-prescription-number.pipe";
import { genderPipe, pipeName as genderPipeName } from "./pipes/gender.pipe";
import {
  getProcedureByKeyPipe,
  pipeName as getProcedureByKeyPipeName,
} from "./pipes/get-procedure-by-key.pipe";
import {
  pipe as glUserFullNamePipe,
  pipeName as glUserFullNamePipeName,
} from "./pipes/gl-user-full-name.pipe";
import {
  pipe as hasDiagnosisPipe,
  pipeName as hasDiagnosisPipeName,
} from "./pipes/has-diagnosis.pipe";
import {
  pipe as glInitialsPipe,
  pipeName as glInitialsPipeName,
} from "./pipes/initials.pipe";
import {
  pipe as injectionTotalPipe,
  pipeName as injectionTotalPipeName,
} from "./pipes/injections-total.pipe";
import { iopColor, pipeName as iopColorPipeName } from "./pipes/iop-color";
import {
  isEditMode,
  pipeName as isEditModePipeName,
} from "./pipes/is-edit-mode";
import {
  pipe as isEmptyPipe,
  pipeName as isEmptyPipeName,
} from "./pipes/is-empty.pipe";
import {
  isoToDatePipe,
  pipeName as isoToDatePipeName,
} from "./pipes/iso-to-date.pipe";
import {
  lettersByTag,
  pipeName as lettersByTagPipeName,
} from "./pipes/letters-by-tag.pipe";
import {
  lifeExpectancyPipe,
  lifeExpectancyPipeName,
} from "./pipes/life-expectancy";
import {
  otherField,
  pipeName as otherFieldPipeName,
} from "./pipes/other-field";
import {
  pipe as patientDiagnosisPipe,
  pipeName as patientDiagnosisPipeName,
} from "./pipes/patient-diagnosis.pipe";
import {
  prescriptionDrugsNames,
  pipeName as prescriptionDrugsNamesPipeName,
} from "./pipes/prescription-drugs-names";
import {
  procedureDate,
  pipeName as procedureDatePipeName,
} from "./pipes/procedure-date";
import {
  routineComplication,
  pipeName as routineComplicationPipeName,
} from "./pipes/procedure-routine-complication";
import {
  programCodeToDrugTypesPipe,
  pipeName as programCodeToDrugTypesPipeName,
} from "./pipes/program-code-to-drug-types.pipe";
import { pronounPipe, pipeName as pronounPipeName } from "./pipes/pronoun.pipe";
import {
  providerPipe,
  pipeName as providerPipeName,
} from "./pipes/provider.pipe";
import {
  relativeDatePipe,
  pipeName as relativeDatePipeName,
} from "./pipes/relative-date.pipe";
import {
  reverseArray,
  pipeName as reverseArrayPipeName,
} from "./pipes/reverse-array";
import {
  serverDate,
  pipeName as serverDatePipeName,
} from "./pipes/server-date";
import {
  pipe as titleCasePipe,
  pipeName as titleCasePipeName,
} from "./pipes/title-case.pipe";
import {
  drugAuthDescSimplify,
  pipeName as drugAuthDescSimplifyPipeName,
} from "./pipes/treatment-auth-desc-simplify";

import { AimComponent } from "./components/data/aim/aim";
import { AlertActionBox } from "./components/data/alert-action-box/alert-action-box";
import { BilateralPosteriorLens } from "./components/data/bilateral-posterior-lens/bilateral-posterior-lens";
import { BilateralSelectMultiple } from "./components/data/bilateral-select-multiple/bilateral-select-multiple";
import { CataractsSectionComponent } from "./components/data/cataracts-section/cataracts-section";
import { DiagnosisIconPopover } from "./components/data/diagnosis-icon-popover/diagnosis-icon-popover";
import { GlDropzone } from "./components/data/gl-dropzone/gl-dropzone";
import { GlLetterDrugComponent } from "./components/data/gl-letter-drug/gl-letter-drug";
import { GlLetterDryEyeTableComponent } from "./components/data/gl-letter-dry-eye-table/gl-letter-dry-eye-table";
import { GlSelect } from "./components/data/gl-select/gl-select";
import { GlSignature } from "./components/data/gl-signature/gl-signature";
import { GlaucomaSectionComponent } from "./components/data/glaucoma-section/glaucoma-section";
import { PatientDrugsPrescribeComponent } from "./components/data/patient-drugs-prescribe/patient-drugs-prescribe";
import { PostDilationIopComponent } from "./components/data/post-dilation-iop/post-dilation-iop";
import { SegmentDilationRecord } from "./components/data/segment-dilation-record/segment-dilation-record";
import { SegmentDryEye } from "./components/data/segment-dry-eye/segment-dry-eye";
import { SegmentFieldNumber } from "./components/data/segment-field-number/segment-field-number";
import { ButtonClear } from "./components/ui/button-clear/button-clear";
import { GlButtonCustomComponent } from "./components/ui/gl-button-custom/gl-button-custom";
import { GlButtonEyeOrderComponent } from "./components/ui/gl-button-eye-order/gl-button-eye-order";
import { TypeaheadFocus } from "./directive/typeahead-focus/typeahead-focus.directive";
import {
  typeaheadQueryHighlightFormat,
  pipeName as typeaheadQueryHighlightFormatPipeName,
} from "./pipes/typeahead-query-highlight-format.pipe";
import { vaccinePipe, pipeName as vaccinePipeName } from "./pipes/vaccine.pipe";
import { AddressHelperService } from "./services/AddressHelperService/address.helper.service";
import { PatientProcedureFormService } from "./services/PatientProcedureFormService/patient-procedure-form.service";
import { VisitDropHelperService } from "./services/VisitDropHelper/VisitDropHelper.service";
import { AccessLockService } from "./services/access-lock.service";
import { AlertHelperService } from "./services/alert-helper.service";
import { Appendix } from "./services/appendix";
import { AppointmentService } from "./services/appointments/appointments.service";
import { AuthService } from "./services/auth.service";
import { ClinicService } from "./services/clinic.service";
import { ContactService } from "./services/contact.service";
import { DataService } from "./services/data.service";
import { DiagnosisService } from "./services/diagnosis.service";
import { DicomTagHelper } from "./services/dicom-tag-helper/dicom-tag-helper";
import { DocumentWindowService } from "./services/document-window.service";
import { DocumentsService } from "./services/documents-service/documents.service";
import { DropSorter } from "./services/drop-sorter.service";
import { DrugsDbService } from "./services/drugs-db/drugs-db.service";
import { ErrorAppendix } from "./services/error-appendix.service";
import { GlModelService } from "./services/gl-model.service";
import { InjectionHelperService } from "./services/injection-helper/injection-helper.service";
import { InvoiceService } from "./services/invoice.service";
import { LetterTypesService } from "./services/letter-types.service";
import { LetterService } from "./services/letter.service";
import { Moment } from "./services/moment.service";
import { OrthancApiService } from "./services/orthanc-api/orthanc-api.service";
import { PageTitleService } from "./services/page-title.service";
import { PatientClinicService } from "./services/patient-clinic.service";
import { PatientContactService } from "./services/patient-contact.service";
import { PatientDocumentService } from "./services/patient-document.service";
import { PatientProcedureHelperService } from "./services/patient-procedure-helper/patient-procedure-helper.service";
import { PatientProcedureService } from "./services/patient-procedure.service";
import { PatientRecordService } from "./services/patient-record/patient-record.service";
import { PatientService } from "./services/patient.service";
import { PdfPrintService } from "./services/pdf-print.service";
import { PlansService } from "./services/plans.service";
import { PrescriptionsService } from "./services/prescriptions/prescriptions.service";
import { ProviderModel } from "./services/provider-v1.service";
import { ProviderService } from "./services/provider.service";
import { QzPrinterService } from "./services/qz-printer/qz-printer";
import { SegmentHelperService } from "./services/segment-helper.service";
import { SignatureService } from "./services/signature-service/signature.service";
import { SubscriptionService } from "./services/subscription.service";
import { ThreadFacadeService } from "./services/thread-facade.service";
import { ThreadService } from "./services/thread.service";
import { UserFavouriteDrugsService } from "./services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { VisualAcuityService } from "./services/va.service";
import { ValueAutofillService } from "./services/value-autofill/value-autofill.service";
/**
 * Import Module Components
 */

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider): void => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

const routing = (): void =>
  // $stateProvider: angular.ui.IStateProvider
  {
    "ngInject";
    // $stateProvider.state("app", {
    //   abstract: true,
    //   url: "/app",
    //   component: App.selector
    // });
  };

export const moduleName = angular
  .module("gl.core", ["ui.router", "inspinia"])
  /**
   * Components
   */
  .component(AdminVisitSummaryComponent.selector, AdminVisitSummaryComponent)
  .component(
    BilateralDicomFieldComponent.selector,
    BilateralDicomFieldComponent
  )
  .component(BilateralHeader.selector, BilateralHeader)
  .component(BilateralNumber.selector, BilateralNumber)
  .component(BilateralRiskCalculator.selector, BilateralRiskCalculator)
  .component(BilateralSelect.selector, BilateralSelect)
  .component(BilateralTextInput.selector, BilateralTextInput)
  .component(BilateralTextareaComponent.selector, BilateralTextareaComponent)
  .component(ButtonPlinyComponent.selector, ButtonPlinyComponent)
  .component(ButtonRecordLockedComponent.selector, ButtonRecordLockedComponent)
  .component(
    ButtonRecordProgressComponent.selector,
    ButtonRecordProgressComponent
  )
  .component(
    ButtonRecordSaveSignComponent.selector,
    ButtonRecordSaveSignComponent
  )
  .component(ButtonUploadsComponent.selector, ButtonUploadsComponent)
  .component(Card.selector, Card)
  .component(CopyButton.selector, CopyButton)
  .component(CreateProviderComponent.selector, CreateProviderComponent)
  .component(CreditCardComponent.selector, CreditCardComponent)
  .component(DayProceduresComponent.selector, DayProceduresComponent)
  .component(DicomFieldComponent.selector, DicomFieldComponent)
  .component(EmailLetterComponent.selector, EmailLetterComponent)
  .component(GlButtonEyeComponent.selector, GlButtonEyeComponent)
  .component(GlButtonEyeOrderComponent.selector, GlButtonEyeOrderComponent)
  .component(GlButtonCustomComponent.selector, GlButtonCustomComponent)
  .component(GlDefaultsComponent.selector, GlDefaultsComponent)
  .component(GlDocumentComponent.selector, GlDocumentComponent)
  .component(GlDrop.selector, GlDrop)
  .component(GlIopComponent.selector, GlIopComponent)
  .component(GlLetter.selector, GlLetter)
  .component(
    GlLetterClinicalDataComponent.selector,
    GlLetterClinicalDataComponent
  )
  .component(GlLetterDropComponent.selector, GlLetterDropComponent)
  .component(GlLetterDrugComponent.selector, GlLetterDrugComponent)
  .component(
    GlLetterObservationsTableComponent.selector,
    GlLetterObservationsTableComponent
  )
  .component(
    GlLetterDryEyeTableComponent.selector,
    GlLetterDryEyeTableComponent
  )
  .component(GlLetterSignatureComponent.selector, GlLetterSignatureComponent)
  .component(GlNavBar.selector, GlNavBar)
  .component(GlPatientProviderComponent.selector, GlPatientProviderComponent)
  .component(GlPatientContactComponent.selector, GlPatientContactComponent)
  .component(GlPatientRegisteredProvider.selector, GlPatientRegisteredProvider)
  .component(GlProcedureComponent.selector, GlProcedureComponent)
  .component(GlRow.selector, GlRow)
  .component(GlSpinner.selector, GlSpinner)
  .component(GlSurgeryComponent.selector, GlSurgeryComponent)
  .component(GlTextarea.selector, GlTextarea)
  .component(
    ManagementWhoWhatWhenComponent.selector,
    ManagementWhoWhatWhenComponent
  )
  .component(MedicalHistory.selector, MedicalHistory)
  .component(MessengerMessages.selector, MessengerMessages)
  .component(MessengerThreads.selector, MessengerThreads)
  .component(
    PatientBillingDetailsComponent.selector,
    PatientBillingDetailsComponent
  )
  .component(
    PatientDemographicsComponent.selector,
    PatientDemographicsComponent
  )
  .component(
    PatientDemographicsFormComponent.selector,
    PatientDemographicsFormComponent
  )
  .component(PatientDiagnosisComponent.selector, PatientDiagnosisComponent)
  .component(
    PatientDiagnosisModifierComponent.selector,
    PatientDiagnosisModifierComponent
  )
  .component(PatientDocumentComponent.selector, PatientDocumentComponent)
  .component(PatientUploadEditComponent.selector, PatientUploadEditComponent)
  .component(PatientDropsComponent.selector, PatientDropsComponent)
  .component(PatientHeader.selector, PatientHeader)
  .component(PatientHistory.selector, PatientHistory)
  .component(PatientVaccine.selector, PatientVaccine)
  .component(PatientVaccineLabel.selector, PatientVaccineLabel)
  .component(
    PatientHistoryDropsComponent.selector,
    PatientHistoryDropsComponent
  )
  .component(PatientInfo.selector, PatientInfo)
  .component(PatientInjectionsComponent.selector, PatientInjectionsComponent)
  .component(PatientLetters.selector, PatientLetters)
  .component(PatientNotesComponent.selector, PatientNotesComponent)
  .component(PatientProcedures.selector, PatientProcedures)
  .component(PatientProviders.selector, PatientProviders)
  .component(PatientUploads.selector, PatientUploads)
  .component(PlinyLogoComponent.selector, PlinyLogoComponent)
  .component(PosteriorLens.selector, PosteriorLens)
  .component(RecordButton.selector, RecordButton)
  .component(RefractionComponent.selector, RefractionComponent)
  .component(Row.selector, Row)
  .component(SegmentAnterior.selector, SegmentAnterior)
  .component(GeneralFindings.selector, GeneralFindings)
  .component(SegmentCct.selector, SegmentCct)
  .component(SegmentClinicalData.selector, SegmentClinicalData)
  .component(SegmentDocuments.selector, SegmentDocuments)
  .component(SegmentField.selector, SegmentField)
  .component(SegmentGonio.selector, SegmentGonio)
  .component(SegmentLensStatus.selector, SegmentLensStatus)
  .component(SegmentLettersComponent.selector, SegmentLettersComponent)
  .component(SegmentManagement.selector, SegmentManagement)
  .component(
    SegmentManagementCommentsComponent.selector,
    SegmentManagementCommentsComponent
  )
  .component(SegmentPosterior.selector, SegmentPosterior)
  .component(SegmentVirtualRecord.selector, SegmentVirtualRecord)
  .component(SegmentVisitProcedures.selector, SegmentVisitProcedures)
  .component(SimpleHeaderComponent.selector, SimpleHeaderComponent)
  .component(SubscriptionComponent.selector, SubscriptionComponent)
  .component(UpdateCreditCardComponent.selector, UpdateCreditCardComponent)
  .component(VaComponent.selector, VaComponent)
  .component(View.selector, View)
  .component(VisitDropComponent.selector, VisitDropComponent)
  .component(VisitDropHistoryComponent.selector, VisitDropHistoryComponent)
  .component(VisitWhatComponent.selector, VisitWhatComponent)
  .component(
    PatientPrescriptionsComponent.selector,
    PatientPrescriptionsComponent
  )
  .component(GlPrescriptionComponent.selector, GlPrescriptionComponent)
  .component(DrugSearchComponent.selector, DrugSearchComponent)
  .component(GlDrug.selector, GlDrug)
  .component(PatientDrugsComponent.selector, PatientDrugsComponent)
  .component(PatientDrugTable.selector, PatientDrugTable)
  .component(UserFavouriteDrugsGroup.selector, UserFavouriteDrugsGroup)
  .component(GlFavouriteGroup.selector, GlFavouriteGroup)
  .component(
    PatientDrugsPrescribeComponent.selector,
    PatientDrugsPrescribeComponent
  )
  .component(SegmentDryEye.selector, SegmentDryEye)
  .component(SegmentFieldNumber.selector, SegmentFieldNumber)
  .component(AlertActionBox.selector, AlertActionBox)
  .component(DiagnosisIconPopover.selector, DiagnosisIconPopover)
  .component(BilateralPosteriorLens.selector, BilateralPosteriorLens)
  .component(BilateralSelectMultiple.selector, BilateralSelectMultiple)
  .component(PostDilationIopComponent.selector, PostDilationIopComponent)
  .component(SegmentDilationRecord.selector, SegmentDilationRecord)
  .component(CataractsSectionComponent.selector, CataractsSectionComponent)
  .component(GlaucomaSectionComponent.selector, GlaucomaSectionComponent)
  .component(GlSelect.selector, GlSelect)
  .component(GlSignature.selector, GlSignature)
  .component(ButtonClear.selector, ButtonClear)
  .component(GlDropzone.selector, GlDropzone)
  .component(AimComponent.selector, AimComponent)
  /**
   * Directives
   */
  .directive(GlModel.selector, GlModel.factory())
  .directive(GlOnDestroyDirective.selector, GlOnDestroyDirective.factory())
  .directive(GlEmbedSrcDirective.selector, GlEmbedSrcDirective.factory())
  .directive(TypeaheadFocus.selector, TypeaheadFocus.factory())
  /**
   * Services
   */
  .service(AccessLockService.injectionName, AccessLockService)
  .service(AppointmentService.injectionName, AppointmentService)
  .service(Appendix.injectionName, Appendix)
  .service(AuthService.injectionName, AuthService)
  .service(ClinicService.injectionName, ClinicService)
  .service(ContactService.injectionName, ContactService)
  .service(PatientContactService.injectionName, PatientContactService)
  .service(DataService.injectionName, DataService)
  .service(DiagnosisService.injectionName, DiagnosisService)
  .service(DicomTagHelper.injectionName, DicomTagHelper)
  .service(DocumentsService.injectionName, DocumentsService)
  .service(DocumentWindowService.injectionName, DocumentWindowService)
  .service(DropSorter.injectionName, DropSorter)
  .service(GlModelService.injectionName, GlModelService)
  .service(InjectionHelperService.injectionName, InjectionHelperService)
  .service(InvoiceService.injectionName, InvoiceService)
  .service(LetterService.injectionName, LetterService)
  .service(LetterTypesService.injectionName, LetterTypesService)
  .service(OrthancApiService.injectionName, OrthancApiService)
  .service(PageTitleService.injectionName, PageTitleService)
  .service(PatientClinicService.injectionName, PatientClinicService)
  .service(PatientDocumentService.injectionName, PatientDocumentService)
  .service(
    PatientProcedureHelperService.injectionName,
    PatientProcedureHelperService
  )
  .service(PatientProcedureService.injectionName, PatientProcedureService)
  .service(PatientRecordService.injectionName, PatientRecordService)
  .service(PatientService.injectionName, PatientService)
  .service(PdfPrintService.injectionName, PdfPrintService)
  .service(PlansService.injectionName, PlansService)
  .service(PrescriptionsService.injectionName, PrescriptionsService)
  .service(ProviderModel.injectionName, ProviderModel)
  .service(ProviderService.injectionName, ProviderService)
  .service(QzPrinterService.injectionName, QzPrinterService)
  .service(SegmentHelperService.injectionName, SegmentHelperService)
  .service(SignatureService.injectionName, SignatureService)
  .service(SubscriptionService.injectionName, SubscriptionService)
  .service(ThreadFacadeService.injectionName, ThreadFacadeService)
  .service(ThreadService.injectionName, ThreadService)
  .service(VisualAcuityService.injectionName, VisualAcuityService)
  .service(DrugsDbService.injectionName, DrugsDbService)
  .service(UserFavouriteDrugsService.injectionName, UserFavouriteDrugsService)
  .service(ErrorAppendix.injectionName, ErrorAppendix)
  .service(AlertHelperService.injectionName, AlertHelperService)
  .service(AddressHelperService.injectionName, AddressHelperService)
  .service(ValueAutofillService.injectionName, ValueAutofillService)
  .service(VisitDropHelperService.injectionName, VisitDropHelperService)
  .service(
    PatientProcedureFormService.injectionName,
    PatientProcedureFormService
  )
  /**
   * Factory
   */
  /* eslint-disable @typescript-eslint/unbound-method */
  .factory(Moment.selector, Moment.factory)
  /**
   * Filters
   */
  .filter(abbreviatePipeName, abbreviate)
  .filter(agePipeName, agePipe)
  .filter(bcvaPipeName, bcvaPipe)
  .filter(capitalizePipeName, capitalize)
  .filter(dicomFieldByIdPipeName, dicomFieldById)
  .filter(activeReferralPipeName, activeReferral)
  .filter(lettersByTagPipeName, lettersByTag)
  .filter(documentNamePipeName, documentNamePipe)
  .filter(eyeSidePipeName, eyeSide)
  .filter(eyeOrderPipeName, eyeOrder)
  .filter(routineComplicationPipeName, routineComplication)
  .filter(genderPipeName, genderPipe)
  .filter(glUserFullNamePipeName, glUserFullNamePipe)
  .filter(glInitialsPipeName, glInitialsPipe)
  .filter(hasDiagnosisPipeName, hasDiagnosisPipe)
  .filter(injectionTotalPipeName, injectionTotalPipe)
  .filter(iopColorPipeName, iopColor)
  .filter(isEditModePipeName, isEditMode)
  .filter(isEmptyPipeName, isEmptyPipe)
  .filter(lifeExpectancyPipeName, lifeExpectancyPipe)
  .filter(otherFieldPipeName, otherField)
  .filter(patientDiagnosisPipeName, patientDiagnosisPipe)
  .filter(pipeName, cleanDeep)
  .filter(procedureDatePipeName, procedureDate)
  .filter(pronounPipeName, pronounPipe)
  .filter(providerPipeName, providerPipe)
  .filter(vaccinePipeName, vaccinePipe)
  .filter(relativeDatePipeName, relativeDatePipe)
  .filter(serverDatePipeName, serverDate)
  .filter(titleCasePipeName, titleCasePipe)
  .filter(drugsHasAuthorityDrugPipeName, drugsHasAuthorityDrugPipe)
  .filter(drugAuthDescSimplifyPipeName, drugAuthDescSimplify)
  .filter(prescriptionDrugsNamesPipeName, prescriptionDrugsNames)
  .filter(reverseArrayPipeName, reverseArray)
  .filter(isoToDatePipeName, isoToDatePipe)
  .filter(formatPrescriptionNumberPipeName, formatPrescriptionNumber)
  .filter(programCodeToDrugTypesPipeName, programCodeToDrugTypesPipe)
  .filter(drugRowClassTypePipeName, drugRowClassTypePipe)
  .filter(getProcedureByKeyPipeName, getProcedureByKeyPipe)
  .filter(formatDrugDisplayNamePipeName, formatDrugDisplayName)
  .filter(favouriteGroupIdsToNamesPipeName, favouriteGroupIdsToNamesPipe)
  .filter(alertLevelToClassPipeName, alertLevelToClassPipe)
  .filter(alertFilterByLevelPipeName, alertFilterByLevelPipe)
  .filter(typeaheadQueryHighlightFormatPipeName, typeaheadQueryHighlightFormat)
  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(glLetterConfiguration)
  .config(routing).name;
