import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { QzPrinterService } from "app/core/services/qz-printer/qz-printer";
import { Patient, User } from "models/user.model";

import {
  GlPrescription,
  GlPrescriptionDrugData,
} from "models/prescription.model";
import "./settings-print.scss";

class SettingsPrintPageController
  implements angular.IController, angular.IOnInit
{
  user: User;

  qzTrayFindPrintersInProgress = false;
  qzTrayIsInstalled = false;
  availablePrinters: string[];
  selectedPrinter = this.QzPrinterService.getSelectedPrinter();
  advancedPrinterOptions = this.QzPrinterService.getAdvancedOptions();
  advanceSettingsStringModel = JSON.stringify(this.advancedPrinterOptions);
  qzServerHost = this.QzPrinterService.getQzHost()?.host;
  printerTray = this.QzPrinterService.getPrinterTray();
  printInProgress = false;
  printPrescriptionInProgress = false;

  marginTop = this.advancedPrinterOptions?.margins?.top || 1.5;
  marginRight = this.advancedPrinterOptions?.margins?.right || 1;
  marginBottom = this.advancedPrinterOptions?.margins?.bottom || 0;
  marginLeft = this.advancedPrinterOptions?.margins?.left || 0;

  constructor(
    private $window: angular.IWindowService,
    private toastr: angular.toastr.IToastrService,
    private PrescriptionsService: PrescriptionsService,
    private QzPrinterService: QzPrinterService,
    public $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  $onInit() {
    //
    this.detectPrinters();
  }

  detectPrinters() {
    this.qzTrayFindPrintersInProgress = true;
    return this.QzPrinterService.findPrinters()
      .then((printers) => {
        this.availablePrinters = printers;
        this.qzTrayIsInstalled = true;
        this.QzPrinterService.setQzTrayIsInstalled(true);
      })
      .catch(() => {
        this.qzTrayIsInstalled = false;
        this.QzPrinterService.setQzTrayIsInstalled(false);
      })
      .finally(() => (this.qzTrayFindPrintersInProgress = false));
  }

  updateSelectedPrinter() {
    this.QzPrinterService.setSelectedPrinter(this.selectedPrinter);
  }

  printerAdvancedOptionsDidChange() {
    this.QzPrinterService.setAdvancedOptions(this.advancedPrinterOptions);
  }

  printerTrayDidChange() {
    //
    this.QzPrinterService.setPrinterTray(this.printerTray);
  }

  testPrint() {
    this.printInProgress = true;
    this.QzPrinterService.print(
      "<html><body><h1>Hello World</h1></body></html>"
    ).finally(() => (this.printInProgress = false));
  }

  testPrintPrescription() {
    this.printPrescriptionInProgress = true;
    const patient = {
      id: 1,
      name: "Joan Smith",
      data: {
        first_name: "Joan",
        last_name: "Smith",
        medicare_number: "12345678N",
        birth_date: Date.now() / 1000,
        address_line1: "1 Spring St",
        suburb: "Melbourne",
        state: "Vic",
        postcode: "3000",
        gender: "F",
      },
    } as Patient;

    // this data is directly from mims technically
    const drugData: GlPrescriptionDrugData = {
      mq: 4000,
      atc: "L01AA06",
      dose: 1,
      mp_pt: "ifosfamide",
      repeats: 19,
      root: "IVI",
      frequency: "Nocte",
      item_code: "4448D",
      misc_flag: null,
      pack_size: "1",
      rstr_flag: "U",
      brand_name: "Holoxan",
      program_code: "IP",
      treatment_id: 13488,
      tpuu_or_mpp_pt: "Holoxan 1 g powder for injection, vial",
      date_created_at: "2023-10-12T12:05:36+11:00",
      authority_script: false,
      restriction_flag: "U",
      restriction_text: null,
      treatment_of_code: null,
      date_required_flag: null,
      text_required_flag: null,
      dangerous_drug_code: "",
    };

    // sample data
    const prescription: GlPrescription = {
      id: 1,
      patient_id: 1,
      authority_number: "999",
      created_by_id: 1,
      updated_by_id: 1,
      created_at: "2023-09-19 01:09:33.555Z",
      updated_at: "2023-09-19 01:09:33.555Z",
      created_by: this.user,
      updated_by: this.user,
      data: [drugData],
      treatments: [],
    };

    this.toastr.info("Printing test prescription to PDF and printer...");

    return this.PrescriptionsService.pdf({
      drugs: [drugData],
      patient,
      prescriber: this.user,
      prescription,
    })
      .then((prescriptionPdf) => {
        this.$window.open(URL.createObjectURL(prescriptionPdf));
        return this.QzPrinterService.printPdf(prescriptionPdf);
      })
      .finally(() => (this.printPrescriptionInProgress = false));
  }

  marginDidChange() {
    this.QzPrinterService.setAdvancedOptions({
      ...JSON.parse(this.advanceSettingsStringModel),
      margins: {
        top: this.marginTop || 1.5,
        bottom: this.marginBottom || 0,
        left: this.marginLeft || 1,
        right: this.marginRight || 0,
      },
      units: "cm",
    });
    this.advancedPrinterOptions = this.QzPrinterService.getAdvancedOptions();
    this.advanceSettingsStringModel = JSON.stringify(
      this.advancedPrinterOptions
    );
  }

  hostDidChange() {
    this.QzPrinterService.setQzHost(this.qzServerHost);
  }
}

export class SettingsPrintPageComponent implements angular.IComponentOptions {
  static selector = "glSettingsPrintPage";
  static template = require("./settings-print.html");
  static controller = SettingsPrintPageController;
  static bindings = {
    user: "<",
  };
}
