import { get } from "lodash";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { DiagnosisService } from "../../../services/diagnosis.service";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import "./patient-header.scss";

class PatientHeaderController {
  record: PatientRecordData;
  consolidatedHistory: PatientRecordData;
  recordHistory: PatientRecord[];

  constructor(
    private DiagnosisService: DiagnosisService,
    private PatientRecordService: PatientRecordService
  ) {
    "ngInject";
  }

  getValueAtPath(path: string) {
    return get(this.record, path, get(this.consolidatedHistory, path));
  }

  getPrimaryDiagnosis(side: IGlSide) {
    const managementDiagnosis = this.getValueAtPath(
      `management.diagnosis_array`
    );
    if (managementDiagnosis) {
      const primaryDiagnosis = this.DiagnosisService.getPrimaryDiagnosis(
        managementDiagnosis,
        side
      );
      return primaryDiagnosis;
    }
  }

  getMaxIop(side: IGlSide) {
    return this.PatientRecordService.getMaxIopForSide(
      side,
      this.recordHistory,
      this.record
    );
  }

  getFromCurrentRecordOrConsolidatedHistory(path: string) {
    return get(this.record, path, get(this.consolidatedHistory, path));
  }
}

export class PatientHeader implements angular.IComponentOptions {
  static selector = "patientHeader";
  static template = require("./patient-header.html");
  static controller = PatientHeaderController;
  static bindings = {
    record: "<",
    consolidatedHistory: "<",
    recordHistory: "<",
  };
}
