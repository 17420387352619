import { IPromise } from "angular";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PatientService } from "app/core/services/patient.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { isNil } from "lodash";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";
import { PatientRecord } from "models/patient-record.model";
import { GlPrescription } from "models/prescription.model";
import { Patient, User } from "models/user.model";
import { PatientRecordService } from "../../../../../app/core/services/patient-record/patient-record.service";
import "./patient-merge.scss";
import angular = require("angular");

export class PatientMergeController implements angular.IController {
  user: User;
  acknowledgeMergeIssues: boolean = false;
  acknowledgeMergeRisks: boolean = false;
  mergeInProgress: boolean = false;
  patientMergeForm: angular.IFormController;

  // PATIENT STUFF
  oldPatient: Patient;
  newPatient: Patient;

  // records
  oldPatientRecords: PatientRecord[];
  newPatientRecords: PatientRecord[];

  // latest
  latestOldPatientRecord: PatientRecord;
  latestNewPatientRecord: PatientRecord;

  // FOR RECORD HISTORY
  // drops
  oldPatientDrops: PatientProcedureDrop[];
  newPatientDrops: PatientProcedureDrop[];

  // inhouseprocedures
  oldPatientInHouseProcedures: PatientProcedureInHouse[];
  newPatientInHouseProcedures: PatientProcedureInHouse[];

  // external procedures
  oldPatientExternalProcedures: PatientProcedureExternal[];
  newPatientExternalProcedures: PatientProcedureExternal[];

  // drugs
  oldPatientDrugs: PatientProcedureDrug[];
  newPatientDrugs: PatientProcedureDrug[];

  // presriptions
  oldPatientPrescriptions: GlPrescription[];
  newPatientPrescriptions: GlPrescription[];

  // ref
  oldPatientSearchRef: JQLite;
  newPatientSearchRef: JQLite;

  // mergeErrors?
  mergeIssues: string[];

  constructor(
    private toastr: angular.toastr.IToastrService,
    private PatientService: PatientService,
    private PatientRecordService: PatientRecordService,
    private PatientProcedureService: PatientProcedureService,
    private PrescriptionsService: PrescriptionsService
  ) {
    "ngInject";
  }

  // old patient on select then get their records
  onSelectOldPatient(patient: Patient) {
    this.oldPatient = patient;
    this.fetchOldPatientRecords(patient);
    this.fetchOldPatientProcedureData(patient);
    this.fetchOldPatientPrescriptions(patient?.id);
    // check if any other issues
    this.checkPotentialMergeIssues();
  }

  // old patient record
  fetchOldPatientRecords(patient: Patient) {
    this.getRecordsForPatient(patient)
      .then((records) => {
        this.oldPatientRecords = records;
        // get latest record to operate on
        this.latestOldPatientRecord = records?.length
          ? records?.slice(-1)?.[0]
          : null;
      })
      .catch(() => {
        this.oldPatientRecords = [];
        this.latestOldPatientRecord = null;
      });
  }

  fetchOldPatientProcedureData(patient: Patient) {
    this.PatientProcedureService.getAllForPatientAsObject({
      patient: patient,
    })
      .then(({ drops, inHouseProcedures, externalProcedures, drugs }) => {
        this.oldPatientDrops = drops;
        this.oldPatientInHouseProcedures = inHouseProcedures;
        this.oldPatientExternalProcedures = externalProcedures;
        this.oldPatientDrugs = drugs;
      })
      .catch(() => {
        this.oldPatientDrops = [];
        this.oldPatientInHouseProcedures = [];
        this.oldPatientExternalProcedures = [];
        this.oldPatientDrugs = [];
      });
  }

  fetchOldPatientPrescriptions(patientId: number) {
    this.PrescriptionsService.fetchPrescriptions(patientId)
      .then((prescriptions) => {
        this.oldPatientPrescriptions = prescriptions;
      })
      .catch(() => {
        this.oldPatientPrescriptions = [];
      });
  }

  // NEW PATINET
  // new patient on select then get their records
  onSelectNewPatient(patient: Patient) {
    this.newPatient = patient;
    this.fetchNewPatientRecords(patient);
    this.fetchNewPatientProcedureData(patient);
    this.fetchNewPatientPrescriptions(patient?.id);
    // check if any merge issues
    this.checkPotentialMergeIssues();
  }

  // get historical records for other user
  fetchNewPatientRecords(patient: Patient) {
    this.getRecordsForPatient(patient)
      .then((records) => {
        this.newPatientRecords = records;
        // get latest record to operate on
        this.latestNewPatientRecord = records?.length
          ? records?.slice(-1)?.[0]
          : null;
      })
      .catch(() => {
        this.newPatientRecords = [];
        this.latestNewPatientRecord = null;
      });
  }

  fetchNewPatientProcedureData(patient: Patient) {
    this.PatientProcedureService.getAllForPatientAsObject({
      patient: patient,
    })
      .then(({ drops, inHouseProcedures, externalProcedures, drugs }) => {
        this.newPatientDrops = drops;
        this.newPatientInHouseProcedures = inHouseProcedures;
        this.newPatientExternalProcedures = externalProcedures;
        this.newPatientDrugs = drugs;
      })
      .catch(() => {
        this.newPatientDrops = [];
        this.newPatientInHouseProcedures = [];
        this.newPatientExternalProcedures = [];
        this.newPatientDrugs = [];
      });
  }

  fetchNewPatientPrescriptions(patientId: number) {
    this.PrescriptionsService.fetchPrescriptions(patientId)
      .then((prescriptions) => {
        this.newPatientPrescriptions = prescriptions;
      })
      .catch(() => {
        this.newPatientPrescriptions = [];
      });
  }

  // fetch records for patient
  getRecordsForPatient(patient: Patient): IPromise<PatientRecord[]> {
    if (!patient) {
      return Promise.resolve([]);
    }

    // else get records
    return this.PatientRecordService.getRecordHistoryForUser(patient.id).then(
      (records) => records
    );
  }

  // MISC HELPERS
  swapPatients() {
    // ignore if nothing
    if (isNil(this.oldPatient) || isNil(this.newPatient)) {
      return;
    }

    // otherwise swap

    // first hold values temporarily
    const tempPatient: Patient = this.oldPatient;
    const tempPatientRecords: PatientRecord[] = this.oldPatientRecords;
    const latestTempPatientRecord: PatientRecord = this.latestOldPatientRecord;
    const tempPatientDrops: PatientProcedureDrop[] = this.oldPatientDrops;
    const tempPatientInHouseProcedures: PatientProcedureInHouse[] =
      this.oldPatientInHouseProcedures;
    const tempPatientExternalProcedures: PatientProcedureExternal[] =
      this.oldPatientExternalProcedures;
    const tempPatientDrugs: PatientProcedureDrug[] = this.oldPatientDrugs;
    const tempPatientPrescriptions: GlPrescription[] =
      this.oldPatientPrescriptions;

    // set for old to new
    this.oldPatient = this.newPatient;
    this.oldPatientRecords = this.newPatientRecords;
    this.latestOldPatientRecord = this.latestNewPatientRecord;
    this.oldPatientDrops = this.newPatientDrops;
    this.oldPatientInHouseProcedures = this.newPatientInHouseProcedures;
    this.oldPatientExternalProcedures = this.newPatientExternalProcedures;
    this.oldPatientDrugs = this.newPatientDrugs;
    this.oldPatientPrescriptions = this.newPatientPrescriptions;

    // set temp to old
    this.newPatient = tempPatient;
    this.newPatientRecords = tempPatientRecords;
    this.latestNewPatientRecord = latestTempPatientRecord;
    this.newPatientDrops = tempPatientDrops;
    this.newPatientInHouseProcedures = tempPatientInHouseProcedures;
    this.newPatientExternalProcedures = tempPatientExternalProcedures;
    this.newPatientDrugs = tempPatientDrugs;
    this.newPatientPrescriptions = tempPatientPrescriptions;
  }

  // CONTINGENCY CHECKS
  bothPatientsSelected() {
    return !isNil(this.oldPatient) && !isNil(this.newPatient);
  }

  checkIfCanMerge(): boolean {
    return (
      // understand the possible conflicts
      this.acknowledgeMergeIssues &&
      // understand the possible issues
      this.acknowledgeMergeRisks &&
      // patiuents must be selected
      !isNil(this.oldPatient) &&
      !isNil(this.newPatient) &&
      // must not be the same
      this.oldPatient.id !== this.newPatient.id
    );
  }

  // handle merge
  handleMergePatients() {
    if (isNil(this.oldPatient) || isNil(this.newPatient)) {
      return this.toastr.error(
        "Please ensure both old and new patients are specified"
      );
    }

    if (this?.oldPatient?.id === this?.newPatient?.id) {
      return this.toastr.error("Both patients must be different.");
    }

    const doubleConfirmMerge: boolean = window.confirm(
      "Are you sure you want to merge these two patients? This action is irreversible."
    );

    // flag
    this.mergeInProgress = true;
    // cancelled
    if (doubleConfirmMerge) {
      this.PatientService.mergePatients({
        old_patient_id: this.oldPatient.id,
        new_patient_id: this.newPatient.id,
      })
        .then(() => {
          this.oldPatient = null;
          this.oldPatientRecords = null;
          this.latestOldPatientRecord = null;

          this.newPatient = null;
          this.newPatientRecords = null;
          this.latestNewPatientRecord = null;

          // reset
          this.acknowledgeMergeRisks = this.acknowledgeMergeIssues = false;
          this.mergeIssues = [];

          // clear search bars for ref
          this.resetSearchBar(this?.oldPatientSearchRef);
          this.resetSearchBar(this?.newPatientSearchRef);

          // success
          this.toastr.success("Successfully merged patients!");
        })
        .catch(() => {
          this.toastr.error("Error merging patients, please try again.");
        })
        .finally(() => {
          this.mergeInProgress = false;
        });
    } else {
      this.mergeInProgress = false;
    }
  }

  // given a search bar ref, reset it
  resetSearchBar(ref: JQLite) {
    const searchBarRef: any = angular.element(ref);

    if (ref) {
      searchBarRef?.[0]?.resetSearch();
    }
  }

  // ERROR FUNCTIONS
  // get merger issues
  getMergeIssuesAsString() {
    return this.mergeIssues.length ? this.mergeIssues.join(", ") : "";
  }
  // these are just suggestions and can be ignored if they
  // know what they are doing
  checkPotentialMergeIssues() {
    // reset first
    this.mergeIssues = [];

    // if nothing dont bother
    if (isNil(this.oldPatient) || isNil(this.newPatient)) {
      this.acknowledgeMergeIssues = true;
      return;
    }

    // else check
    if (this.oldPatient.data.first_name !== this.newPatient.data.first_name) {
      this.mergeIssues.push("First Name");
    }

    if (this.oldPatient.data.last_name !== this.newPatient.data.last_name) {
      this.mergeIssues.push("Last Name");
    }

    // dob?
    if (this.oldPatient.data.birth_date !== this.newPatient.data.birth_date) {
      this.mergeIssues.push("Birth Date");
    }

    // medicare?
    if (
      this.oldPatient.data.medicare_number !==
      this.newPatient.data.medicare_number
    ) {
      this.mergeIssues.push("Medicare Number");
    }

    // maybe phone number?
    if (this.oldPatient.data.phone !== this.newPatient.data.phone) {
      this.mergeIssues.push("Phone Number");
    }

    // at the end of it do a check to see if we need to act
    this.acknowledgeMergeIssues = !this.mergeIssues?.length;
  }
}

export class PatientMergeComponent implements angular.IComponentOptions {
  static selector = "glPatientMerge";
  static template = require("./patient-merge.html");
  static controller = PatientMergeController;
  static bindings = {
    user: "<",
  };
}
