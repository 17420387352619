import * as angular from "angular";
import { PatientPage } from "./patient";
import { SetProviderForRecordFromPatientComponent } from "./components/set-provider-for-record/set_provider_record";
/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.patient", {
    url: "/patient/:patient_id",
    component: PatientPage.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.patient", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(PatientPage.selector, PatientPage)
    .component(
      SetProviderForRecordFromPatientComponent.selector,
      SetProviderForRecordFromPatientComponent
    )

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
