import { LetterService } from "app/core/services/letter.service";

import { Letter } from "models/letter.model";
import { User } from "models/user.model";
import "./email-letter.scss";

class EmailLetterModalController {
  user: User;
  letter: Letter;
  clinicId: number;
  patient: User;
  subject: string;
  emailTemplate: string;
  emailTo: string;
  emailFrom: string;
  referrer: string;
  emailBody: string;
  baseSfPdf: string;

  close: (args: any) => void;

  constructor(private LetterService: LetterService) {
    "ngInject";
  }

  sendEmail() {
    if (this.clinicId === 50) {
      this.emailFrom = "reception@mces.melbourne";
    } else if (this.clinicId === 2) {
      this.emailFrom = "reception@sunburyeyesurgeons.com.au";
    }
    this.LetterService.sendEmail(
      this.letter,
      this.emailTo,
      this.emailFrom,
      this.referrer,
      this.subject,
      this.emailBody,
      this.baseSfPdf
    );
  }
}

export class EmailLetterComponent implements angular.IComponentOptions {
  static selector = "emailLetter";
  static template = require("./email-letter.html");
  static controller = EmailLetterModalController;
  static bindings = {
    user: "<",
    clinicId: "<",
    referrer: "<",
    letter: "<",
    subject: "<",
    emailTo: "<",
    baseSfPdf: "<",
    emailBody: "<",
  };
}
