import { Patient, User } from "models/user.model";

export const pipeName = "glUserFullName";
export function pipe() {
  "ngInject";
  return (user: User | Patient, { showPreferredName = false } = {}) => {
    const preferredName = (user as Patient)?.data?.preferred_name;
    const { first_name = "", last_name = "" } = user?.data ?? {};
    return showPreferredName && preferredName
      ? [first_name, `(${preferredName})`, last_name].join(" ")
      : [first_name, last_name].join(" ");
  };
}
