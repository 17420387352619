import { PatientRecordData } from "models/patient-record.model";

class VisitDropHistoryController implements angular.IController {
  recordData: PatientRecordData;

  constructor() {
    "ngInject";
  }
}

export class VisitDropHistoryComponent implements angular.IComponentOptions {
  static selector = "glVisitDropHistory";
  static template = require("./visit-drop-history.html");
  static controller = VisitDropHistoryController;
  static bindings = {
    recordData: "<",
  };
}
