import { get, includes, values } from "lodash";
import { User } from "models/user.model";
import { PatientRecordData } from "../../../../../models/patient-record.model";
import { Appendix } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";
import "./management-who-what-when.scss";

class ManagementWhoWhatWhenController extends GlFormController
  implements angular.IController {
  recordData: PatientRecordData;
  user: User;
  whenOptions = this.appendix.get("managementFrequency", true);

  constructor(private appendix: Appendix) {
    "ngInject";
    super();
  }

  isWhatFieldChecked() {
    const management_what = get(this.recordData, "management.what", {});
    return includes(values(management_what), true) ? true : false;
  }
}

export class ManagementWhoWhatWhenComponent
  implements angular.IComponentOptions {
  static selector = "managementWhoWhatWhen";
  static template = require("./management-who-what-when.html");
  static controller = ManagementWhoWhatWhenController;
  static bindings = {
    mode: "@?",
    isEditable: "<?",
    recordData: "<",
    user: "<",
  };
}
