// <i class="fa fa-spinner fa-spin" ng-if="$ctrl.loadingSubscription && !$ctrl.subscriptionDetails"></i>

export class GlSpinner implements angular.IComponentOptions {
  static selector = "glSpinner";
  static bindings = {
    ngClass: "<"
  };
  static template =
    '<i class="fa fa-spinner fa-spin" ng-class="$ctrl.ngClass"></i>';
}
