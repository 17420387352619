import { copy } from "angular";
import { Appendix } from "app/core/services/appendix";
import {
  IConsolidatedInjection,
  InjectionHelperService,
} from "app/core/services/injection-helper/injection-helper.service";
import { IGlSide } from "models/gl-side.model";
import { IGlInjectionRecord, IGlInjectionRecordData } from "models/injection";
import { Patient } from "models/user.model";
import { IGlFormMode } from "../../../../../models/gl-form-mode";
import { PatientProcedureInjection } from "../../../../../models/patient-procedure";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import "./patient-injections.scss";

export type NewOrExisting<T extends PatientProcedureInjection> = Pick<
  T,
  "data" | "id"
>;

export class PatientInjectionsController
  implements angular.IComponentController
{
  // @Inputs()
  currentRecordId: number;
  injections: IConsolidatedInjection[];
  mode: IGlFormMode = "display";

  // @Outputs

  // Controller Properties
  showInjectionForm = false;
  injectionOptions = this.appendix.get("intravitreal-injection");
  frequencyOptions = this.appendix.get("injection-frequency", true);
  eyeModel: IGlSide;
  leftInjectionModel: NewOrExisting<PatientProcedureInjection>;
  rightInjectionModel: NewOrExisting<PatientProcedureInjection>;
  saveInProgress = false;
  deleteInProgress = false;
  dropsForRecord: PatientProcedureInjection[];
  patient: Patient;
  editInjectionForm: angular.IFormController;

  constructor(
    private $q: angular.IQService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private appendix: Appendix,
    private InjectionHelperService: InjectionHelperService,
    private PatientProcedureService: PatientProcedureService
  ) {
    "ngInject";
  }

  isEditMode() {
    return this.mode === "edit";
  }

  addInjection(injectionDefaults?: PatientProcedureInjection) {
    if (!injectionDefaults) {
      this.eyeModel = "both";
      this.leftInjectionModel = {
        data: {
          eye: "left",
          name: undefined,
          frequency: undefined,
          initial_count: undefined,
          repeat_count: undefined,
        },
      };
      this.rightInjectionModel = {
        data: {
          eye: "right",
          name: undefined,
          frequency: undefined,
          initial_count: undefined,
          repeat_count: undefined,
        },
      };
    } else {
      this.eyeModel = injectionDefaults.data.eye;
      if (injectionDefaults.data.eye === "left") {
        this.leftInjectionModel = { data: { ...injectionDefaults.data } };
        // delete initial count if the defaults includes it
        this.leftInjectionModel.data.initial_count = undefined;
      }
      if (injectionDefaults.data.eye === "right") {
        this.rightInjectionModel = { data: { ...injectionDefaults.data } };
        this.rightInjectionModel.data.initial_count = undefined;
      }
    }
    this.showInjectionForm = true;
    this.setEditFormToPristine();
  }

  editInjection(
    injection: PatientProcedureInjection,
    event: CustomEvent<MouseEvent>
  ) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (injection.record_id !== this.currentRecordId) {
      this.addInjection(injection);
    } else {
      if (injection.data.eye === "left") {
        this.eyeModel = "left";
        this.leftInjectionModel = copy(injection);
      }
      if (injection.data.eye === "right") {
        this.eyeModel = "right";
        this.rightInjectionModel = copy(injection);
      }
      this.showInjectionForm = true;
      this.setEditFormToPristine();
    }
  }

  canEditInjection(injection: PatientProcedureInjection) {
    return injection.record_id === this.currentRecordId;
  }

  getDrops() {
    return this.dropsForRecord || this.injections;
  }

  saveClicked() {
    let savePromise;
    if (!this.leftInjectionModel?.id && !this.rightInjectionModel?.id) {
      const data: any = {};
      if (["both", "left"].includes(this.eyeModel)) {
        data.left = this.leftInjectionModel.data;
      }
      if (["both", "right"].includes(this.eyeModel)) {
        data.right = this.rightInjectionModel.data;
      }
      savePromise = this.PatientProcedureService.createInjection(
        this.currentRecordId,
        data
      );
    } else {
      const injectionToUpdate = this.leftInjectionModel?.id
        ? this.leftInjectionModel
        : this.rightInjectionModel;
      savePromise = this.PatientProcedureService.updateInjection(
        this.currentRecordId,
        injectionToUpdate
      );
    }

    this.$q
      .resolve()
      .then(() => {
        this.saveInProgress = true;
        return savePromise;
      })
      .then(() => {
        this.cancelClicked();
      })
      .finally(() => (this.saveInProgress = false));
  }

  deleteClicked() {
    const shouldDeleteInjection = this.$window.confirm(
      "Are you sure you want to remove this drop?"
    );
    if (shouldDeleteInjection) {
      this.$q
        .resolve()
        .then(() => {
          this.deleteInProgress = true;
          if (this.leftInjectionModel?.id) {
            return this.PatientProcedureService.deleteInjection(
              this.currentRecordId,
              this.leftInjectionModel.id
            );
          }
        })
        .then(() => {
          if (this.rightInjectionModel?.id) {
            return this.PatientProcedureService.deleteInjection(
              this.currentRecordId,
              this.rightInjectionModel.id
            );
          }
        })
        .then(() => this.cancelClicked())
        .finally(() => (this.deleteInProgress = false));
    }
  }

  cancelClicked() {
    this.showInjectionForm = false;
    this.leftInjectionModel = undefined;
    this.rightInjectionModel = undefined;
  }

  injectionTypeDidChange(side: "left" | "right") {
    const injection =
      side === "left" ? this.leftInjectionModel : this.rightInjectionModel;
    const name = injection?.data?.name;
    const defaultFrequency = ["triesence", "ozurdex"].includes(name?.key)
      ? this.frequencyOptions.find((f) => f.key === "1")
      : this.frequencyOptions.find((f) => f.key === "4");
    if (defaultFrequency) {
      injection.data.frequency = defaultFrequency;
      this.injectionFrequencyDidChange(side);
    }
    if (!name) {
      // clear all other fields;
      injection.data.frequency =
        injection.data.initial_count =
        injection.data.repeat_count =
          undefined;
    }
  }

  injectionFrequencyDidChange(side: "left" | "right") {
    const injection =
      side === "left" ? this.leftInjectionModel : this.rightInjectionModel;
    const frequency = injection?.data?.frequency;
    if (frequency) {
      injection.data.repeat_count = frequency.repeats;
    }
  }

  copyName(toSide: "left" | "right") {
    const fromSide = toSide === "left" ? "right" : "left";
    const fromData = this.getInjectionDataForSide(fromSide);
    const toData = this.getInjectionDataForSide(toSide);
    const name = fromData?.data?.name;
    const nameOther = fromData?.data?.name_other;
    if (name) {
      toData.data.name = { ...name };
    }
    if (nameOther) {
      toData.data.name_other = nameOther;
    }
    this.injectionTypeDidChange(toSide);
  }

  formatInjectionForDisplay(record: IGlInjectionRecord, side: IGlSide) {
    const data: IGlInjectionRecordData = record.data?.[side];

    const routineStr = data?.routine ? "Routine" : "Complicated";
    const delayedStr = data?.delayed ? " (Delayed)" : "";
    return routineStr + delayedStr;
  }

  getInjectionBgColor(injectionGroup: IConsolidatedInjection) {
    // return error/red if any one of the completed injections is complicated
    const isComplicated = injectionGroup.injections
      .flatMap((i) => i.records ?? [])
      .find((r) => {
        if (injectionGroup.eye === "left") {
          return r.data?.left?.routine === false;
        } else {
          return r.data?.right?.routine === false;
        }
      });
    if (isComplicated) {
      return "bg-complicated";
    } else if (
      injectionGroup.injections[0].data?.frequency?.key === "complete"
    ) {
      return "bg-completed";
    }
  }

  getInjectionNumber(injection: PatientProcedureInjection, recordId: number) {
    return this.InjectionHelperService.getInjectionCountForRecord(
      injection,
      recordId
    );
  }

  getInjectionGroupTotal(injectionGroup: IConsolidatedInjection) {
    // not empty
    return this.InjectionHelperService.getInjectionGroupTotal(injectionGroup);
  }

  private getInjectionDataForSide(side: "left" | "right") {
    return side === "left" ? this.leftInjectionModel : this.rightInjectionModel;
  }

  private setEditFormToPristine() {
    // use timeout so we set the form after angular has had a chance to update
    // hacky - yes - but it works
    this.$timeout().then(() => {
      if (this.editInjectionForm) {
        this.editInjectionForm.$setPristine();
      }
    });
  }
}

export class PatientInjectionsComponent implements angular.IComponentOptions {
  static selector = "patientInjections";
  static template = require("./patient-injections.html");
  static controller = PatientInjectionsController;
  static bindings = {
    currentRecordId: "<",
    injections: "<",
    mode: "@",
    recordData: "<",
  };
}
