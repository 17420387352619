import * as moment from "moment";
import {
  IOrthancDicomQueryParams,
  IOrthancDicomTags,
  IOrthancInstance,
  IOrthancModalitiesQueryResponse,
  IOrthancPatient,
  IOrthancQueryRetrieveResponse,
  IOrthancStudiesExpanded,
} from "./orthanc.models";

const DICOM_SERVER_HOST = "http://localhost";
const FORUM_DICOM_AET = "ForumTest";
const GLAUCONET_DICOM_AET = "GLAUCONET";

const MODAILITY_ASCAN = "OAM";
const MODAILITY_OCT = "OPT";
const MODAILITY_DISC = "XC";
const MODAILITY_FIELD = "OPV";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MODAILITY_IOL = "IOL";

// DICOM FIELDS
// ACD = 771b,100e

export interface IOrthancApiServiceConfig {
  orthancUrl: string;
  orthancAet: string;
  forumAet: string;
}

export class OrthancApiService {
  static injectionName = "OrthancApiService";

  private defaultConfig: IOrthancApiServiceConfig = {
    orthancUrl: DICOM_SERVER_HOST,
    orthancAet: GLAUCONET_DICOM_AET,
    forumAet: FORUM_DICOM_AET,
  };
  private config = this.defaultConfig;

  constructor(
    private $http: angular.IHttpService,
    private $timeout: angular.ITimeoutService
  ) {
    "ngInject";
  }

  setConfig(config: Partial<IOrthancApiServiceConfig>) {
    this.config = { ...this.defaultConfig, ...config };
  }

  findNewImages() {
    return this.$http
      .post<IOrthancModalitiesQueryResponse>(
        `${this.config.orthancUrl}/modalities/${this.config.forumAet}/query`,
        {
          Level: "Study",
          Query: {
            AccessionNumber: "",
            PatientBirthDate: "",
            PatientID: "00167",
            PatientName: "",
            PatientSex: "",
            StudyDate: moment().format("YYYYMMDD"),
            StudyDescription: "*",
            ModalitiesInStudy: [
              MODAILITY_ASCAN,
              MODAILITY_DISC,
              MODAILITY_FIELD,
              MODAILITY_OCT,
              // MODAILITY_IOL
            ].join("\\"),
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        return (
          this.$timeout(100)
            .then(() => this.queryGetAnswers(data.Path))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .then((answers) => {
              return this.queryRetrieve(data.Path);
            })
        );
      });
  }

  queryRemoteAet(
    remoteAet: string = this.config.forumAet,
    Level: "Patient" | "Study" = "Study",
    params: IOrthancDicomQueryParams = {}
  ) {
    return this.$http
      .post<IOrthancModalitiesQueryResponse>(
        `${this.config.orthancUrl}/modalities/${remoteAet}/query`,
        {
          Level,
          Query: params,
        }
      )
      .then((response) => response.data);
  }

  /**
   *
   * @param patientIds optional - if specified will only return studies for these patient ids
   * @param remoteAet
   */
  queryRemoteDicomForGlDocuments(
    patientId?: string,
    remoteAet: string = this.config.forumAet,
    params: IOrthancDicomQueryParams = {}
  ) {
    return this.queryRemoteAet(remoteAet, "Study", {
      PatientID: patientId || "*",
      StudyDate: moment().format("YYYYMMDD"),
      ...params,
    });
  }

  queryGetAnswers(path: string): angular.IPromise<IOrthancDicomTags[]>;
  queryGetAnswers(
    path: string,
    expand: true
  ): angular.IPromise<IOrthancDicomTags[]>;
  queryGetAnswers(path: string, expand: false): angular.IPromise<string[]>;
  queryGetAnswers(path: string, expand: boolean = true) {
    const params = expand ? { expand } : undefined;
    return this.$http
      .get(`${this.config.orthancUrl}${path}/answers`, { params })
      .then((response) => response.data);
  }

  queryRetrieve(path: string) {
    return this.$http
      .post<IOrthancQueryRetrieveResponse>(
        `${this.config.orthancUrl}${path}/retrieve`,
        this.config.orthancAet
      )
      .then((response) => response.data);
  }

  queryDelete(id: string) {
    return this.$http
      .delete(`${this.config.orthancUrl}/queries/${id}`)
      .then((response) => response.data);
  }

  queryAnswersGetContent(path: string, id: string) {
    this.$http
      .get<any[]>(`${this.config.orthancUrl}${path}/answers/${id}/content`)
      .then((response) => response.data);
  }

  queryAnswersRetrieve(path: string, id: string) {
    return this.$http
      .post<IOrthancQueryRetrieveResponse>(
        `${this.config.orthancUrl}${path}/answers/${id}/retrieve`,
        this.config.orthancAet
      )
      .then((response) => response.data);
  }

  find(patientIds: string[]) {
    return this.$http.post<IOrthancPatient[]>(
      `${this.config.orthancUrl}/tools/find`,
      {
        Level: "Patient",
        Expand: true,
        Query: {
          PatientID: patientIds.join("\\"),
        },
      }
    );
  }

  patientsList(): angular.IPromise<IOrthancPatient[]>;
  patientsList(expand: true): angular.IPromise<IOrthancPatient[]>;
  patientsList(expand: false): angular.IPromise<string[]>;
  patientsList(expand: boolean = true) {
    const params = expand ? { expand } : undefined;

    return this.$http
      .get(this.patientsGetPath(), {
        params,
      })
      .then((response) => {
        return response.data;
      });
  }

  patientDelete(id: string) {
    return this.$http
      .delete(this.patientsGetPath(id))
      .then((response) => response.data);
  }

  patientGetInstanceIds(id: string) {
    return this.$http
      .post<string[]>(this.getFindPath(), {
        Level: "Instance",
        Query: {
          PatientID: id,
          StudyDate: moment().format("YYYYMMDD"),
        },
      })
      .then((response) => response.data);
  }

  studiesGet(): angular.IPromise<IOrthancStudiesExpanded[]>;
  studiesGet(expand: true): angular.IPromise<IOrthancStudiesExpanded[]>;
  studiesGet(expand: false): angular.IPromise<string[]>;
  studiesGet(expand: boolean = true) {
    const params = expand ? { expand } : undefined;

    return this.$http
      .get(`${this.config.orthancUrl}/studies`, {
        params,
      })
      .then((response) => response.data);
  }

  instanceGet(instance: string) {
    return this.$http
      .get<IOrthancInstance>(this.instanceGetPath(instance))
      .then((response) => response.data);
  }

  instanceGetTags(instance: string) {
    return this.$http
      .get<IOrthancDicomTags>(this.instanceGetPath(instance, "/tags"))
      .then((response) => response.data);
  }

  instanceGetFrames(instance: string) {
    return this.$http
      .get<number[]>(this.instanceGetPath(instance, "/frames"))
      .then((response) => response.data);
  }

  instanceFindBySopInstanceUid(uid: string) {
    return this.$http
      .post<string[]>(this.getFindPath(), {
        Level: "Instance",
        Query: {
          SOPInstanceUID: uid,
        },
      })
      .then((response) => response.data);
  }

  /**
   * @description returns the document, either JPG or PDF
   * It only works for single frame images. For multiframe images this won't work
   * @param instance
   */
  instanceGetFile(instance: string) {
    // first try to get the pdf
    return this.$http
      .get<Blob>(this.instanceGetPath(instance, "/pdf"), {
        responseType: "blob",
      })
      .catch(() => {
        // if this didn't work then try to get the preview
        return this.$http.get<Blob>(
          this.instanceGetPath(instance, "/preview"),
          {
            responseType: "blob",
            headers: {
              Accept: "image/jpeg",
            },
          }
        );
      })
      .then((response) => response.data);
  }

  private instanceGetPath(instance: string, suffix: string = "") {
    return `${this.config.orthancUrl}/instances/${instance}${suffix}`;
  }

  private patientsGetPath(id: string = "") {
    const idPath = id && `/${id}`;
    return `${this.config.orthancUrl}/patients${idPath}`;
  }

  private getFindPath() {
    return `${this.config.orthancUrl}/tools/find`;
  }
}
