import { isFunction } from "angular";
import { AccessLockService } from "app/core/services/access-lock.service";
import { AccessLock } from "models/access-lock.model";
import "./button-record-locked.scss";

class ButtonRecordLockedController
  implements angular.IController, angular.IOnChanges {
  // Input
  recordId: number;
  unlockInProgress: boolean;

  // Output
  overrideLockedClicked: () => void;

  currentAccessLock: AccessLock;

  constructor(
    private AccessLockService: AccessLockService,
    private $window: angular.IWindowService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.recordId && this.recordId) {
      this.AccessLockService.getAccessLock({ recordId: this.recordId }).then(
        (accessLock) => {
          this.currentAccessLock = accessLock;
        }
      );
    }
  }

  overrideLockButtonClicked() {
    const confirm = this.$window.confirm(
      "Are you sure you want to override this access lock? If another person is updating this record at the same time, you will loose data."
    );
    if (confirm && isFunction(this.overrideLockedClicked)) {
      this.overrideLockedClicked();
    }
  }
}

export class ButtonRecordLockedComponent implements angular.IComponentOptions {
  static selector = "glButtonRecordLocked";
  static controller = ButtonRecordLockedController;
  static template = require("./button-record-locked.html");
  static bindings = {
    recordId: "<",
    overrideLockedClicked: "&",
    unlockInProgress: "<",
  };
}
