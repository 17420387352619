/**
 * Import the Component styles
 */
import { AppointmentService } from "app/core/services/appointments/appointments.service";
import { PageTitleService } from "app/core/services/page-title.service";
import { PatientService } from "app/core/services/patient.service";
import { every, isEmpty, isNil, orderBy } from "lodash";
import { Appointment } from "../../../models/appointment.model";
import { GlClinicActiveRecord } from "../../../models/clinic-active-records.model";
import { GlThread } from "../../../models/messages.model";
import {
  GlPatientRecordWorkflowState,
  PatientRecord,
} from "../../../models/patient-record.model";
import { GlUserTypeString, Patient, User } from "../../../models/user.model";
import { AccessLockService } from "../../core/services/access-lock.service";
import { APPOINTMENT_REGEX, Appendix } from "../../core/services/appendix";
import { AuthService } from "../../core/services/auth.service";
import { ClinicService } from "../../core/services/clinic.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import { ThreadFacadeService } from "../../core/services/thread-facade.service";
import "./dashboard.scss";
import { getSavedProviderId } from "./directives/persist-value.directive";
import moment = require("moment");

export class DashboardController
  implements angular.IController, angular.IOnInit, angular.IOnDestroy
{
  // @Input
  user: User;
  recordsForDocumentImport: GlClinicActiveRecord[];
  refreshingRecordListInProgress = false;
  threads: GlThread[];
  clinicThreads: GlThread[];
  showUserMsg = true;
  allPatients: Patient[];
  referralsSent: GlClinicActiveRecord[];
  referralsReceived: GlClinicActiveRecord[];
  selectedPatientFilter: User;
  allClinicProviders: User[];
  appointments: Appointment[];
  signedRecords: GlClinicActiveRecord[];
  inProgressRecords: GlClinicActiveRecord[];
  virtualReviews: GlClinicActiveRecord[];
  // dilationReviews: GlClinicActiveRecord[];
  createNewRecordInProgress = false;
  createNewInjectionInProgress = false;
  patientWithResultsRequiringReview: Patient[];

  // forms
  cataractConsentForms: GlClinicActiveRecord[];
  activeCataractConsentForms: GlClinicActiveRecord[];
  signedCataractConsentForms: GlClinicActiveRecord[];

  private refreshIntervalHandle;

  constructor(
    private $q: angular.IQService,
    private $window: angular.IWindowService,
    private AppointmentService: AppointmentService,
    private AuthService: AuthService,
    private AccessLockService: AccessLockService,
    private ClinicService: ClinicService,
    private DASHBOARD_REFRESH_INTERVAL: number,
    private PageTitleService: PageTitleService,
    private PatientService: PatientService,
    private toastr: angular.toastr.IToastrService,
    public $interval: angular.IIntervalService,
    public PatientRecordService: PatientRecordService,
    public ThreadFacadeService: ThreadFacadeService,
    public appendix: Appendix
  ) {
    "ngInject";
  }

  $onDestroy() {
    if (this.refreshIntervalHandle) {
      this.$interval.cancel(this.refreshIntervalHandle);
    }
  }

  $onInit() {
    this.fetchAllClinicProviders().then(() => this.updateDashboard());
    this.updateMessages();
    this.PageTitleService.set("Dashboard - GlaucoNet");
  }

  setState(state: string, activeRecord: GlClinicActiveRecord) {
    return this.PatientRecordService.updateWorkflow(state, activeRecord.record)
      .catch((error) => {
        console.error("Unable to change state", error);
        return this.toastr.error("Unable to change state");
      })
      .then(() => this.updateDashboard());
  }

  setArrived(appointment: Appointment) {
    this.createNewRecordInProgress = true;
    const isRetinaRegex = APPOINTMENT_REGEX.Retina.regex;
    const isGlaucomaRegex = APPOINTMENT_REGEX.Glaucoma.regex;
    const isCataractRegex = APPOINTMENT_REGEX.Cataract.regex;
    let workflowStateToSet: GlPatientRecordWorkflowState;
    if (isRetinaRegex.test(appointment.description.toLocaleLowerCase())) {
      workflowStateToSet = "Arrived (R)";
    } else if (
      isGlaucomaRegex.test(appointment.description.toLocaleLowerCase())
    ) {
      workflowStateToSet = "Arrived (G)";
    } else if (
      isCataractRegex.test(appointment.description.toLocaleLowerCase())
    ) {
      workflowStateToSet = "Arrived (C)";
    } else {
      workflowStateToSet = "Arrived (O)";
    }
    return this.PatientRecordService.create(appointment.patient.id, {
      appointment_id: appointment.id,
      provider_id: appointment.provider.id,
      workflowState: workflowStateToSet,
    })
      .catch((error) => {
        this.toastr.error("Unable to create a new record");
        console.error(error);
      })
      .finally(() => {
        this.createNewRecordInProgress = false;
        return this.updateDashboard();
      });
  }

  setArrivedTechOnly(appointment: Appointment) {
    this.createNewRecordInProgress = true;

    const workflowStateToSet: GlPatientRecordWorkflowState = "tech_record";

    return this.PatientRecordService.create(appointment.patient.id, {
      appointment_id: appointment.id,
      provider_id: appointment.provider.id,
      workflowState: workflowStateToSet,
      type: "tech_record",
    })
      .catch((error) => {
        this.toastr.error("Unable to create a new record");
        console.error(error);
      })
      .finally(() => {
        this.createNewRecordInProgress = false;
        return this.updateDashboard();
      });
  }

  createNewInjection(appointment: Appointment) {
    this.createNewInjectionInProgress = true;
    return this.PatientRecordService.create(appointment.patient.id, {
      type: "procedure",
      appointment_id: appointment.id,
      workflowState: "Arrived (INJ)",
    })
      .catch((error) => {
        this.toastr.error("Unable to create a new injection record");
        console.error(error);
      })
      .finally(() => {
        this.createNewInjectionInProgress = false;
        return this.updateDashboard();
      });
  }

  updateMessages() {
    const getThreadsForUserPromise = this.getThreadsForUser();
    const getThreadsForClinicPromise = this.getThreadsForClinic();

    return this.$q
      .all([getThreadsForUserPromise, getThreadsForClinicPromise])
      .then(([userThreads, clinicThreads]) => {
        this.threads = userThreads;
        this.clinicThreads = clinicThreads;
      });
  }

  updateDashboard() {
    // if there is a refreshInterval in progress, cancel it and restart it
    // so that it only updates every 15 seconds

    this.refreshAppointments();
    this.resetRefreshInterval();
    this.refreshingRecordListInProgress = true;
    const includeVirtualReviews = this.AuthService.userIs("ophthalmologist");
    const dashboardRecordsPromise = this.ClinicService.getRecordsForClinic({
      includeVirtualReviews,
      clinic: this.user.clinic,
      activeRecords: true,
      ...(this.selectedPatientFilter && {
        filterPatientsByProvider: this.selectedPatientFilter.id,
      }),
    });

    this.ClinicService.getClinicReferrals({
      clinic: this.user.clinic,

      ...(this.selectedPatientFilter && {
        filterPatientsByProvider: this.selectedPatientFilter.id,
      }),
    }).then((referralsSent) => (this.referralsSent = referralsSent));
    this.ClinicService.getClinicReceivedReferrals({
      clinic: this.user.clinic,

      ...(this.selectedPatientFilter && {
        filterPatientsByProvider: this.selectedPatientFilter.id,
      }),
    }).then(
      (referralsReceived) => (this.referralsReceived = referralsReceived)
    );

    this.migratePatientProviderToContacts();

    this.ClinicService.getClinicResultsByReviewStatus({
      clinic: this.user.clinic,

      ...(this.selectedPatientFilter && {
        filterPatientsByProvider: this.selectedPatientFilter.id,
      }),
    }).then((resultsByReviewStatus) => {
      this.patientWithResultsRequiringReview = resultsByReviewStatus;
    });

    // fetches by clinic
    return this.$q
      .all([dashboardRecordsPromise])
      .then(([records]) => {
        //
        this.inProgressRecords = this.filterInProgressRecords(records);

        this.virtualReviews = this.filterVirtualReviews(records);
        this.signedRecords = this.filterSignedRecords(records);

        // forms
        this.cataractConsentForms = this.filterCataractConsentForms(records);

        this.recordsForDocumentImport = [
          ...this.inProgressRecords,
          ...this.signedRecords,
        ];
      })
      .finally(() => (this.refreshingRecordListInProgress = false));
  }

  deleteRecord(record: PatientRecord) {
    return this.PatientRecordService.delete(record)
      .then(() => {
        return this.toastr.success("Successfully deleted the record");
      })
      .catch((error) => {
        console.error("Unable to delete record", error);
        return this.toastr.error("Unable to delete this record");
      })
      .then(() => this.updateDashboard());
  }

  showMsg(msgType: string) {
    if (msgType === "clinic") {
      this.showUserMsg = false;
    } else {
      this.showUserMsg = true;
    }
  }

  resetRefreshInterval() {
    if (this.refreshIntervalHandle) {
      this.$interval.cancel(this.refreshIntervalHandle);
    }
    this.refreshIntervalHandle = this.$interval(
      () => this.updateDashboard(),
      this.DASHBOARD_REFRESH_INTERVAL
    );
  }

  getThreadsForUser() {
    if (!this.user) {
      return;
    } // early exit if no clinic id

    return this.ThreadFacadeService.init(null, this.user.clinic_id, 1, 0);
  }

  getThreadsForClinic() {
    if (!this.user.clinic_id) {
      return;
    } // early exit if no clinic id

    return this.ThreadFacadeService.init(null, this.user.clinic_id, 1, 1);
  }

  sortByRecordDate(ar1: GlClinicActiveRecord, ar2: GlClinicActiveRecord) {
    return (
      moment(ar1.record.appointment_date || ar1.record.created_at).valueOf() -
      moment(ar2.record.appointment_date || ar2.record.created_at).valueOf()
    );
  }

  filterInProgressRecords(activeRecords: GlClinicActiveRecord[]) {
    const defaultInProgressRecords = activeRecords
      .filter((ar) => {
        const notVROrCompletedVR: boolean =
          !ar.record.optom_record_id ||
          (ar.record.optom_record_id && !this.isPendingVirtualReview(ar));
        const notDROrCompletedDR: boolean =
          !ar.record.dilation_record_id ||
          (ar.record.dilation_record_id && !this.isPendingDilationReview(ar));

        return (
          ar.record.data_status !== "SIGNED" &&
          notVROrCompletedVR &&
          notDROrCompletedDR &&
          !(ar.lock && this.user.id === ar.lock.user_id)
        );

        // original
        // return (
        //   ar.record.data_status !== "SIGNED" &&
        //   (!ar.record.optom_record_id ||
        //     (ar.record.optom_record_id && !this.isPendingVirtualReview(ar))) &&
        //   !(ar.lock && this.user.id === ar.lock.user_id)
        // );
      })
      .sort((ar1, ar2) => {
        return this.sortByRecordDate(ar1, ar2);
      });

    const myInProgressRecords = this.findActiveRecordsBelongingToCurrentUser(
      defaultInProgressRecords
    );

    const removeRecordsFromDefault = myInProgressRecords.map((ar) => {
      return ar.record.id;
    });

    const otherActiveRecords = defaultInProgressRecords.filter((ar) => {
      return !removeRecordsFromDefault.includes(ar.record.id);
    });

    const sortedInProgressRecords =
      myInProgressRecords.concat(otherActiveRecords);
    const lockedInProgressRecords = activeRecords.filter((ar) => {
      if (ar.lock?.expires_at < moment().format("YYYY-MM-DD HH:mm:ss")) {
        this.AccessLockService.disablePageLock({
          force: true,
          recordId: ar.record.id,
        });
      }
      return (
        ar.lock &&
        this.user.id === ar.lock.user_id &&
        ar.record.data_status !== "SIGNED"
      );
    });

    // otherwise return as is
    let filteredRecords: GlClinicActiveRecord[] =
      lockedInProgressRecords.concat(sortedInProgressRecords);

    // EDGE CASE: workflow state different to provider user type
    if (this.selectedPatientFilter) {
      filteredRecords = this.filterRecordsWithDifferentWorkflowState(
        filteredRecords,
        this.selectedPatientFilter
      );
    }

    return filteredRecords;
  }

  findActiveRecordsWithWorkflowStateSameAsCurrentUser(
    activeRecords: GlClinicActiveRecord[]
  ) {
    const filtered: GlClinicActiveRecord[] = activeRecords
      .filter((ar) => {
        const notVROrCompletedVR: boolean =
          !ar.record.optom_record_id ||
          (ar.record.optom_record_id && !this.isPendingVirtualReview(ar));
        const notDROrCompletedDR: boolean =
          !ar.record.dilation_record_id ||
          (ar.record.dilation_record_id && !this.isPendingDilationReview(ar));

        return (
          ar.record.data_status !== "SIGNED" &&
          notVROrCompletedVR &&
          notDROrCompletedDR
        );
      })
      .sort((ar1, ar2) => {
        return this.sortByRecordDate(ar1, ar2);
      });

    return filtered;
  }

  findActiveRecordsBelongingToCurrentUser(
    activeRecords: GlClinicActiveRecord[]
  ) {
    return activeRecords.filter((ar) => {
      if (ar.record.workflow_state == null) {
        return false;
      }

      switch (true) {
        case ar.record.workflow_state.toLowerCase().includes("admin"):
          if (this.user.type.name === "administrator") {
            return true;
          }
          break;

        case ar.record.workflow_state.toLowerCase().includes("tech"):
          if (this.user.type.name === "technician") {
            return true;
          }
          break;

        case ar.record.workflow_state.toLowerCase().includes("optometrist"):
          if (this.user.type.name === "optometrist") {
            return true;
          }
          break;

        case ar.record.workflow_state.toLowerCase().includes("ophthal"):
          if (this.user.type.name === "ophthalmologist") {
            return true;
          }
          break;

        case ar.record.workflow_state.toLowerCase().includes("virtual review"):
          if (this.user.type.name === "ophthalmologist") {
            return true;
          }
          break;

        case ar.record.workflow_state.toLowerCase().includes("injection"):
          if (this.user.type.name === "ophthalmologist") {
            return true;
          }
          break;

        default:
          return false;
          break;
      }
    });
  }

  /*
    more granular filter for workflow state
    which handles the edge case of 
    opthal as provider but with optom etc etc etc etc

    user here refers to the selectedPatientFilter
  */
  filterRecordsWithDifferentWorkflowState(
    activeRecords: GlClinicActiveRecord[],
    filteredProvider: User
  ) {
    return (activeRecords ?? []).filter((ar) => {
      const recordWorkflowStateUserType: GlUserTypeString =
        this.appendix.getUserTypeFromWorkflowState(ar.record.workflow_state);

      const recordProvider: User = this.getClinicProviderById(
        ar.record.provider_id
      );

      if (
        // edge case 1: workflow is with opthal but current user is an optom (even if owned)
        (recordWorkflowStateUserType === "ophthalmologist" &&
          filteredProvider?.type?.name === "optometrist") ||
        // edge case 2: workflow is with optom but current user is an ophthal (even if owned)
        (recordWorkflowStateUserType === "optometrist" &&
          filteredProvider?.type?.name === "ophthalmologist")
      ) {
        return false;
      }

      // edge case 3: same provider type but different assigned provider
      if (
        /*
          current record workflow state is same as 
          - filtered provider type
          - record provider type name
      */
        every(
          [filteredProvider?.type?.name, recordProvider?.type?.name],
          (t) => t === recordWorkflowStateUserType
        ) &&
        // filtered provider is different to record provider
        filteredProvider?.id !== ar.record.provider_id
      ) {
        return false;
      }

      // else continue on even if provider is empty
      return true;
    });
  }

  filterVirtualReviews(records: GlClinicActiveRecord[]) {
    const filteredRecords: GlClinicActiveRecord[] = (records ?? []).filter(
      (ar) =>
        ar.record.workflow_state === "virtual review" &&
        this.isPendingVirtualReview(ar)
    );
    // order the filtered records by appointment date or date created
    // asc === oldest record first
    return orderBy(
      filteredRecords,
      (ar) => new Date(ar?.record?.appointment_date || ar?.record?.created_at),
      ["asc"]
    );
  }

  // filterDilationReviews(records: GlClinicActiveRecord[]) {
  //   return records.filter((ar) => {
  //     return (
  //       ar.record.workflow_state === "dilation review" &&
  //       this.isPendingDilationReview(ar)
  //     );
  //   });
  // }

  isPendingDilationReview(ar: GlClinicActiveRecord) {
    return isEmpty(ar.record.data);
  }

  isPendingVirtualReview(ar: GlClinicActiveRecord) {
    return isEmpty(ar.record.data);
  }

  filterSignedRecords(records: GlClinicActiveRecord[]) {
    // exclude "my patients" from "all patients"
    return records.filter(
      (ar) =>
        ar.record.data_status === "SIGNED" &&
        (!ar.record.optom_record_id ||
          (ar.record.optom_record_id && !this.isPendingVirtualReview(ar)))
    );
  }

  showVirtualReviews() {
    return true;
  }

  migratePatientProviderToContacts() {
    return this.PatientService.getAllPatients().then(
      (patients) => (this.allPatients = patients as Patient[])
    );
  }

  deleteAppointment(appointment: Appointment) {
    return this.AppointmentService.delete(appointment.id).then(() =>
      this.refreshAppointments()
    );
  }

  openPlinyDashboard() {
    this.$window.open("/pliny/");
  }

  getClinicProviderById(providerId: number): User {
    if (isNil(this.allClinicProviders) || !this.allClinicProviders?.length) {
      return null;
    }

    return (this.allClinicProviders ?? []).find((p) => p.id === providerId);
  }

  filterCataractConsentForms(records: GlClinicActiveRecord[]) {
    return records.filter((ar) => ar.record.type === "consent_form_cataract");
  }

  filterActiveCataractConsentForms(records: GlClinicActiveRecord[]) {
    return records.filter(
      (ar) =>
        ar.record.type === "consent_form_cataract" &&
        !this._cataractConsentFormComplete(ar.record)
    );
  }

  filterSignedCataractConsentForms(records: GlClinicActiveRecord[]) {
    return records.filter(
      (ar) =>
        ar.record.type === "consent_form_cataract" &&
        this._cataractConsentFormComplete(ar.record)
    );
  }

  // dobule signatrue
  private _cataractConsentFormComplete(record: PatientRecord) {
    return (
      record.type === "consent_form_cataract" &&
      record?.data_status === "SIGNED" &&
      record.data?.signature_data.patient?.status === "SIGNED"
    );
  }

  private refreshAppointments() {
    return this.AppointmentService.get({
      date: moment().toISOString(),
      ...(this.selectedPatientFilter && {
        providerId: this.selectedPatientFilter.id,
      }),
    }).then((appointments) => (this.appointments = appointments));
  }

  private fetchAllClinicProviders() {
    return this.ClinicService.getProvidersForClinic(this.user.clinic).then(
      (providers) => {
        const allowedTypes: GlUserTypeString[] = [
          "ophthalmologist",
          "optometrist",
        ];

        this.allClinicProviders = providers.filter((u) =>
          allowedTypes.includes(u.type.name)
        );
        // by default filter patients either by the last saved filter id or by
        // the current user if they are a provider

        const defaultProvider = this.allClinicProviders.find((u) => {
          return u.id === this.user.id;
        });

        this.selectedPatientFilter = getSavedProviderId({
          clinicId: this.user.clinic.id,
          providers: this.allClinicProviders,
          $window: this.$window,
          defaultProvider,
        });
      }
    );
  }
}

export class DashboardPage implements angular.IComponentOptions {
  static selector = "dashboard";
  static template = require("./dashboard.html");
  static controller = DashboardController;
  static bindings = {
    user: "<",
  };
}
