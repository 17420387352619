import { IPromise } from "angular";
import { defaults, isNil, uniq } from "lodash";
import { IGlApiResponse } from "models/gl-api-response.model";
import {
  PatientProcedureCategory,
  PatientProcedureDrug,
  PatientProcedureType,
} from "models/patient-procedure";
import {
  GlFavouriteGroupType,
  UserFavouriteDrugGroup,
} from "models/user-favourite-drugs";
import { GlStaff } from "models/user.model";
import { API_PATH_v2 } from "../api-paths";

// DRUG TEMPLATES
const PROCEDURE_TYPE_DRUGS: PatientProcedureType = "drugs";
const CATEGORY_TYPE_FAVOURITE: PatientProcedureCategory = "favourite";

// GROUPS
// can expand to different types as well
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GROUP_TYPE_GLOBAL: GlFavouriteGroupType = "global";
const GROUP_TYPE_USER: GlFavouriteGroupType = "user";

// GLOBAL GROUP ID
const GLOBAL_GROUP_ID: number = 1;

export interface IFavouriteDrugInterface {
  drug_id?: string;
  favourite_group_ids: number[];
}

export interface IFavouriteGroupInterface {
  name: string;
}

export interface IFavouriteGroupDrugUpdateInterface {
  group_id: string;
  drug_ids: number[];
}

export class UserFavouriteDrugsService {
  static injectionName = "UserFavouriteDrugsService";
  apiV2Base = `${this.API_URL}${API_PATH_v2}`;

  private favouriteDrugs: PatientProcedureDrug[];
  private favouriteGroups: UserFavouriteDrugGroup[];

  constructor(private API_URL: string, private $http: angular.IHttpService) {
    "ngInject";
  }

  reset() {
    this.favouriteDrugs = [];
    this.favouriteGroups = [];
  }

  // REGULAR FAVOURITES
  getUserFavouriteDrugs() {
    return this.favouriteDrugs;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchUserFavouriteDrugs(userId: number) {
    return this.$http
      .get<IGlApiResponse<PatientProcedureDrug[]>>(
        `${this.apiV2Base}/procedures/favourites`
      )
      .then((response) => {
        this.favouriteDrugs = response.data.data;
        return this.favouriteDrugs;
      });
  }

  createUserFavouriteDrug(
    userId: number,
    drug: PatientProcedureDrug,
    params: IFavouriteDrugInterface
  ): IPromise<PatientProcedureDrug> {
    drug.id = null; // edge case converting regular drugs to favourite drugs
    defaults(drug, {
      type: PROCEDURE_TYPE_DRUGS,
      drug_id: drug.data.id,
      category: CATEGORY_TYPE_FAVOURITE,
    });
    return this.$http
      .post<PatientProcedureDrug>(`${this.apiV2Base}/procedures/favourites`, {
        ...drug,
        ...params,
      })
      .then((response) => {
        return response.data;
      });
  }

  updateUserFavouriteDrug(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userId: number,
    drug: PatientProcedureDrug,
    params: IFavouriteDrugInterface
  ): IPromise<PatientProcedureDrug> {
    defaults(drug, {
      type: PROCEDURE_TYPE_DRUGS,
      drug_id: drug.data.id,
    });

    return this.$http
      .put<PatientProcedureDrug>(
        `${this.apiV2Base}/procedures/favourites/${drug.id}`,
        {
          ...drug,
          ...params,
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteUserFavouriteDrug(userId: number, favouriteDrugId: number) {
    return this.$http
      .delete<IGlApiResponse<PatientProcedureDrug>>(
        `${this.apiV2Base}/procedures/favourites/${favouriteDrugId}`
      )
      .then((response) => {
        return response.data;
      });
  }

  // GROUPS
  getUserFavouriteDrugGroups() {
    return this.favouriteGroups;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchUserFavouriteDrugGroups(userId: number) {
    return this.$http
      .get<IGlApiResponse<UserFavouriteDrugGroup[]>>(
        `${this.apiV2Base}/procedures/favourites/groups`
      )
      .then((response) => {
        this.favouriteGroups = response.data.data;
        return this.favouriteGroups;
      });
  }

  createUserFavouriteDrugGroup(
    userId: number,
    name: string,
    type: GlFavouriteGroupType = GROUP_TYPE_USER
  ) {
    return this.$http
      .post<IGlApiResponse<UserFavouriteDrugGroup>>(
        `${this.apiV2Base}/procedures/favourites/groups`,
        { user_id: userId, name, type }
      )
      .then((response) => {
        return response.data.data;
      });
  }

  updateUserFavouriteDrugGroup(userId: number, name: string, groupId: number) {
    return this.$http
      .put<IGlApiResponse<UserFavouriteDrugGroup>>(
        `${this.apiV2Base}/procedures/favourites/groups/${groupId}`,
        { user_id: userId, name, group_id: groupId }
      )
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteUserFavouriteDrugGroup(userId: number, groupId: number) {
    return this.$http
      .delete<IGlApiResponse<UserFavouriteDrugGroup>>(
        `${this.apiV2Base}/procedures/favourites/groups/${groupId}`
      )
      .then((response) => {
        return response.data.data;
      });
  }

  // this targets within the USER's own groups and not others
  checkIfGroupNameUnique(name: string) {
    if (!this?.favouriteGroups.length) {
      return true;
    }

    // if not found its unique
    return isNil(this.favouriteGroups.find((g) => g.name === name));
  }

  /**
   * a group can be edited only
   * - if user and group are defined
   * - user owns the group
   * - no restrictions on who can edit are imposed
   * - if restrictions exist, they are a part of it
   *
   * - EDGE CASE: no group means new is created
   */
  canEditFavouriteGroup(group: UserFavouriteDrugGroup, user: GlStaff) {
    // no user defined
    if (!user) {
      return false;
    }

    // if theres no groupings should be allowed
    if (!group) {
      return true;
    }

    if (group?.user_id === user.id) {
      return true;
    }

    // however if one is defined we should check
    if (!isNil(group?.editable_by_user_ids)) {
      return group.editable_by_user_ids.includes(user.id);
    }

    if (!isNil(group?.editable_by_user_types)) {
      return group.editable_by_user_types.includes(user.type_id);
    }

    return true;
  }

  getGlobalGroupId() {
    return GLOBAL_GROUP_ID;
  }

  // MANIPULATORS

  // get global group
  getGlobalGroup() {
    if (isNil(this.favouriteGroups)) {
      return;
    }

    return this.favouriteGroups.find((g) => g.id === GLOBAL_GROUP_ID);
  }

  // get favourite drugs which also includes global
  getFavouriteDrugsWithGlobals() {
    const globalGroup: UserFavouriteDrugGroup = this.getGlobalGroup();
    const combinedDrugs: PatientProcedureDrug[] = this.favouriteDrugs.concat(
      globalGroup?.drugs
    );

    return uniq(combinedDrugs);
  }

  // returns all drugs extracted from favourite groups
  getDrugsInGroups() {
    if (isNil(this.favouriteGroups)) {
      return [];
    }
    return this.favouriteGroups.reduce(
      (combined, curr) => combined.concat(curr.drugs ?? []),
      []
    );
  }

  // get all favourite groups that can be edited by user
  getEditableFavouriteGroups(user: GlStaff) {
    if (!this?.favouriteGroups) {
      return [];
    }

    return this?.favouriteGroups.filter((g) =>
      this.canEditFavouriteGroup(g, user)
    );
  }
}
