import { sha256 } from "js-sha256";
import { IGlApiResponse } from "models/gl-api-response.model";
import * as qz from "qz-tray";
import { API_PATH_v2 } from "../api-paths";
import { AuthService } from "../auth.service";

const STORAGE_KEY_PREFIX = "gl:printer:";
const STORAGE_KEY_DEFAULT_PRINTER = `${STORAGE_KEY_PREFIX}default-printer`;
const STORAGE_KEY_PRINTER_TRAY = `${STORAGE_KEY_PREFIX}printer-tray`;
const STORAGE_KEY_ADVANCED_OPTIONS = `${STORAGE_KEY_PREFIX}advanced-options`;
const STORAGE_KEY_QZ_IS_INSTALLED = `${STORAGE_KEY_PREFIX}qz-tray-is-installed`;
const STORAGE_KEY_QZ_SERVER_HOST = `${STORAGE_KEY_PREFIX}qz-host`;

const DEFAULT_QZ_OPTIONS = {
  margins: {
    top: 1.5,
    right: 0,
    bottom: 0,
    left: 1,
  },
  units: "cm",
};

export class QzPrinterService {
  static injectionName = "QzPrinterService";
  readonly qz = qz;

  private selectedPrinter: string;
  private printerTray: string;
  private advancePrinterOptions: any = {};
  private qzTrayIsInstalled = false;
  private qzServerHost: string;

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private $window: angular.IWindowService,
    private API_URL: string,
    private AuthService: AuthService
  ) {
    "ngInject";
    this.initQz();
    this.loadConfigFromStorage();
  }

  setSelectedPrinter(printer: string) {
    this.selectedPrinter = printer;
    this.saveConfigToStorage();
  }

  getSelectedPrinter() {
    return this.selectedPrinter;
  }

  setPrinterTray(tray: string) {
    this.printerTray = tray;
    this.saveConfigToStorage();
  }

  getPrinterTray() {
    return this.printerTray;
  }

  setAdvancedOptions(options: any) {
    this.advancePrinterOptions = options;
    this.saveConfigToStorage();
  }

  getAdvancedOptions() {
    return this.advancePrinterOptions;
  }

  getQzTrayIsInstalled() {
    return this.qzTrayIsInstalled;
  }

  setQzTrayIsInstalled(isInstalled: boolean) {
    this.qzTrayIsInstalled = isInstalled;
    this.saveConfigToStorage();
  }

  findPrinters() {
    return this.qz.websocket
      .connect(this.getQzHost())
      .then(this.qz.printers.find)
      .then((printers) => this.qz.websocket.disconnect().then(() => printers));
  }

  print(html: string) {
    const data = [
      {
        type: "html", // "pdf",
        format: "plain", // or 'plain' if the data is raw HTML
        data: html,
      },
    ];
    return this.qz.websocket
      .connect(this.getQzHost())
      .then(this.getPrinter.bind(this))
      .then((printer) => {
        const config = this.getConfig(printer);
        return this.qz.print(config, data);
      })
      .finally(this.qz.websocket.disconnect)
      .catch((err) => console.error(err));
  }

  printPdf(pdf: Blob) {
    return this.qz.websocket
      .connect(this.getQzHost())
      .then(this.getPrinter.bind(this))
      .then((printer) =>
        this.convertBlobToBase64(pdf).then((pdfStr) => {
          const config = this.getConfig(printer);
          const data = [
            {
              type: "pdf", // "pdf",
              format: "base64", // or 'plain' if the data is raw HTML
              data: pdfStr,
            },
          ];
          return this.qz.print(config, data);
        })
      )
      .finally(this.qz.websocket.disconnect);
  }

  setQzHost(host: string) {
    this.qzServerHost = host;
    this.saveConfigToStorage();
  }

  getQzHost() {
    if (this.qzServerHost) {
      return { host: this.qzServerHost };
    }
    const plinyServerUrl = this.AuthService.user.clinic?.data?.dicom_server_url;
    if (!plinyServerUrl) {
      return;
    }
    const parsedUrl = new URL(plinyServerUrl);
    return { host: parsedUrl.hostname };
  }

  private getPrinter() {
    return this.selectedPrinter
      ? this.$q.resolve(this.selectedPrinter)
      : this.qz.printers.getDefault();
  }

  private getConfig(printer: string) {
    return this.qz.configs.create(printer, {
      ...this.advancePrinterOptions,
      ...(this.printerTray && { printerTray: this.printerTray }),
    });
  }

  private saveConfigToStorage() {
    if (this.selectedPrinter) {
      this.$window.localStorage.setItem(
        STORAGE_KEY_DEFAULT_PRINTER,
        this.selectedPrinter
      );
    } else {
      this.$window.localStorage.removeItem(STORAGE_KEY_DEFAULT_PRINTER);
    }

    if (this.printerTray) {
      this.$window.localStorage.setItem(
        STORAGE_KEY_PRINTER_TRAY,
        this.printerTray
      );
    } else {
      this.$window.localStorage.removeItem(STORAGE_KEY_PRINTER_TRAY);
    }

    if (this.advancePrinterOptions) {
      this.$window.localStorage.setItem(
        STORAGE_KEY_ADVANCED_OPTIONS,
        JSON.stringify(this.advancePrinterOptions)
      );
    } else {
      this.$window.localStorage.removeItem(STORAGE_KEY_ADVANCED_OPTIONS);
    }

    if (this.qzTrayIsInstalled) {
      this.$window.localStorage.setItem(STORAGE_KEY_QZ_IS_INSTALLED, "true");
    } else {
      this.$window.localStorage.removeItem(STORAGE_KEY_QZ_IS_INSTALLED);
    }

    if (this.qzServerHost) {
      this.$window.localStorage.setItem(
        STORAGE_KEY_QZ_SERVER_HOST,
        this.qzServerHost
      );
    } else {
      this.$window.localStorage.removeItem(STORAGE_KEY_QZ_SERVER_HOST);
    }
  }

  private loadConfigFromStorage() {
    this.selectedPrinter = this.$window.localStorage.getItem(
      STORAGE_KEY_DEFAULT_PRINTER
    );
    this.printerTray = this.$window.localStorage.getItem(
      STORAGE_KEY_PRINTER_TRAY
    );
    this.qzServerHost =
      this.$window.localStorage.getItem(STORAGE_KEY_QZ_SERVER_HOST) ??
      this.getQzHost()?.host;
    try {
      const savedOption = this.$window.localStorage.getItem(
        STORAGE_KEY_ADVANCED_OPTIONS
      );
      this.advancePrinterOptions = savedOption
        ? JSON.parse(savedOption)
        : DEFAULT_QZ_OPTIONS;
    } catch (error) {
      //
    }
    const qzTrayIsInstalledVal = this.$window.localStorage.getItem(
      STORAGE_KEY_QZ_IS_INSTALLED
    );
    this.qzTrayIsInstalled = qzTrayIsInstalledVal
      ? JSON.parse(
          this.$window.localStorage.getItem(STORAGE_KEY_QZ_IS_INSTALLED)
        )
      : false;
  }

  private convertBlobToBase64(blob: Blob) {
    return this.$q<string>((resolve) => {
      const fr = new FileReader();
      fr.onload = () => {
        const data = fr.result as string;
        resolve(data.split(",")[1]);
      };
      fr.readAsDataURL(blob);
    });
  }

  private initQz() {
    this.qz.api.setPromiseType(this.$q);
    this.qz.api.setSha256Type((data) => sha256(data));
    this.qz.security.setCertificatePromise((resolve, reject) =>
      this.$http
        .get<string>(`${this.API_URL}${API_PATH_v2}/print/cert`, {
          cache: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    );
    this.qz.security.setSignaturePromise(
      (hash) => (resolve, reject) =>
        this.$http
          .post<IGlApiResponse<string>>(
            `${this.API_URL}${API_PATH_v2}/print/sign`,
            {
              hash,
            }
          )
          .then((response) => resolve(response.data.data))
          .catch((error) => reject(error))
    );
  }
}
