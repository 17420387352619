import { DicomTagHelper } from "../services/dicom-tag-helper/dicom-tag-helper";
import { IOrthancDicomTags } from "../services/orthanc-api/orthanc.models";

export const pipeName = "dicomFieldById";
export function dicomFieldById(DicomTagHelper: DicomTagHelper) {
  "ngInject";
  return (dicomTags: IOrthancDicomTags, id: string) => {
    return dicomTags && id && DicomTagHelper.getTagForDicomId(id, dicomTags);
  };
}
