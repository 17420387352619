import { copy, isFunction } from "angular";
import { User } from "../../../../../models/user.model";
import { IPatientResource } from "../../../services/patient.service";
import { GlFormController } from "../../gl-form-controller";

class DemographicsController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  patient: IPatientResource;
  patientEditModel: User;

  editPatientInfo: angular.IFormController;
  saveInProgress = false;
  onCancel: () => void;

  constructor() {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.mode && this.isEditMode() && this.patient) {
      this.patientEditModel = copy(this.patient.toJSON());
    }
  }

  saveClicked() {
    Object.assign(this.patient, this.patientEditModel);
    this.saveInProgress = true;
    this.patient
      .$update()
      .then(() => {
        this.editPatientInfo.$setPristine();
        // cancel after successful save
        this.cancelClicked();
      })
      .finally(() => (this.saveInProgress = false));
  }

  cancelClicked() {
    if (isFunction(this.onCancel)) {
      this.onCancel();
    }
  }
}

export class PatientDemographicsComponent implements angular.IComponentOptions {
  static selector = "patientDemographics";
  static template = require("./patient-demographics.html");
  static controller = DemographicsController;
  static bindings = {
    patient: "<",
    mode: "@?",
    isEditable: "<?",
    onCancel: "&",
    saveInProgress: "<?",
  };
}
