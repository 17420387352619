import { VisitDropHelperService } from "app/core/services/VisitDropHelper/VisitDropHelper.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { cloneDeep, defaults, isNil, pullAt } from "lodash";
import {
  GlVisitDrop,
  GlVisitDropDilation,
  PatientRecord,
  PatientRecordData,
} from "models/patient-record.model";
import { Appendix } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";
import moment = require("moment");

export class VisitDropController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  record: PatientRecordData;
  dilationRecord: PatientRecord;
  dropTypes = this.appendix.get("visitProcedureDropTypes");
  removeDrop: (drop: GlVisitDrop) => void;
  showEditForm = false;

  dilationTimerActive: boolean = false;
  dilationTimerMarkedComplete: boolean = false;
  editModel: GlVisitDrop;
  editIndex: number;

  hours: number;
  minutes: number;

  dropsErrorMessages = this.ErrorAppendix.getDropsErrorMessages();

  // mock
  visitDropsController: angular.IFormController;

  constructor(
    public appendix: Appendix,
    private ErrorAppendix: ErrorAppendix,
    private toastr: angular.toastr.IToastrService,
    private VisitDropHelperService: VisitDropHelperService,
    private $window: angular.IWindowService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (
      (changes.record || changes.isEditable) &&
      this.record &&
      this.isEditable
    ) {
      defaults(this.record, { visit_drops: [] });
    }
  }

  add() {
    if (this.isDilationRecordSigned()) {
      return this.toastr.error(
        this.dropsErrorMessages.edit.unsign_before_changing
      );
    }
    const defaultDrop = this.appendix.getDefaultFromDictionary(
      "visitProcedureDropTypes"
    );
    this.editModel = { type: undefined, time: new Date(), eye: "both" };
    if (defaultDrop) {
      this.editModel.type = defaultDrop;
    }
    this.showEditForm = true;
    this.dilationTimerActive = false;
  }

  edit(drop: GlVisitDrop, index: number) {
    if (this.isDilationRecordSigned()) {
      return this.toastr.error(
        this.dropsErrorMessages.edit.unsign_before_changing
      );
    }

    this.editModel = cloneDeep(drop);
    this.editIndex = index;
    this.showEditForm = true;

    // is active?
    // determine if tis active based on if drop has an exisitng one and if edit model has one
    this.dilationTimerActive = !isNil(drop.dilation);
    // // if not active we instantiate one
    // if (!this.dilationTimerActive) {
    //   this._instantiateDilationTimer();
    // }
    // is complete?
    this.dilationTimerMarkedComplete =
      this.editModel?.dilation?.state === "COMPLETE";
  }

  cancel() {
    this.editIndex = undefined;
    this.editModel = undefined;
    this.showEditForm = false;

    // dilation details
    this.dilationTimerActive = false;
    this.dilationTimerMarkedComplete = false;
  }

  save() {
    // check if theres a dilation record and if its signed
    if (this.isDilationRecordSigned()) {
      return this.toastr.error(
        this.dropsErrorMessages.edit.unsign_before_changing
      );
    }

    // else continue
    if (this.editModel?.dilation && this.dilationTimerActive) {
      // if ticked as complete this will act as a master override
      if (this.dilationTimerMarkedComplete) {
        this.editModel.dilation.state = "COMPLETE";
      } else {
        // otherwise treated as ongoing
        this.editModel.dilation.state = "ONGOING";
      }
    }

    // then see how to save
    if (this.editIndex < this.record.visit_drops.length) {
      this.record.visit_drops[this.editIndex] = this.editModel;
    } else {
      this.record.visit_drops.push(this.editModel);
    }
    this.cancel();
  }

  // eslint-disable-next-line
  delete(drop: GlVisitDrop, index: number) {
    if (this.isDilationRecordSigned()) {
      return this.toastr.error(
        this.dropsErrorMessages.edit.unsign_before_changing
      );
    }
    const shouldDeleteDrop = this.$window.confirm(
      "Are you sure you want to delete this drop?"
    );
    if (shouldDeleteDrop) {
      pullAt(this.record.visit_drops, index);
      this.visitDropsController.$setDirty();
    }
  }

  // DILLATION
  shouldShowDilationTimer() {
    // for now pilocarpine beause other might be needed as well
    return this.editModel.type.key !== "pilocarpine";
  }

  // can mark as complete?
  canMarkDilationAsComplete(drop: GlVisitDrop) {
    // no dilation?
    if (isNil(drop?.dilation)) {
      return false;
    }
    // if timer is before end time?
    const currentTime: moment.Moment = moment();
    const endTime: moment.Moment = moment(drop?.dilation.end_time);
    if (!currentTime.isSameOrAfter(endTime)) {
      return false;
    }

    // otherwise you can toggle
    return true;
  }

  // drop time change
  dropTimeDidChange() {
    // also update dillation timer
    this.dilationTimerDidChange();
  }

  // minutes did change
  // dilationTimerValueDidChange(current: GlVisitDrop, previous: GlVisitDrop) {
  //   // minutes overflow
  //   console.log(current.dilation.minutes, previous.dilation.minutes);
  //   if (
  //     current.dilation.minutes >= 60 &&
  //     current.dilation.minutes > previous.dilation.minutes
  //   ) {
  //     if (current.dilation.hours < 3) {
  //       this.editModel.dilation.hours += 1;
  //     }
  //     // increment hours and mins
  //     this.editModel.dilation.minutes = 0;
  //   }
  // }

  // timer did change
  dilationTimerDidChange() {
    // then calculate
    if (this.editModel?.dilation?.end_time) {
      this.editModel.dilation.end_time = this._getDilationEndTimeDate(
        this.editModel.time,
        this.editModel.dilation.hours,
        this.editModel.dilation.minutes
      ).toISOString();
    }
  }

  // checkbox
  toggleDilationTimer() {
    // if active
    if (this.dilationTimerActive) {
      // // if empty instantiate
      if (!this.editModel.dilation) {
        this._instantiateDilationTimer();
      }
      // else retain
    } else {
      // else inactive and time to disable/toggle off
      this.editModel.dilation = null;
    }
  }

  //dilation timer is marked as complete
  dilationTimerComplete(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerComplete(dilation);
  }

  // dilation finished
  dilationTimerFinished(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerFinished(dilation);
  }

  dilationTimerOngoing(dilation: GlVisitDropDilation) {
    return this.VisitDropHelperService.dilationTimerOngoing(dilation);
  }

  // TIME
  getDilationTimeRemaining(dilation: GlVisitDropDilation): moment.Duration {
    return this.VisitDropHelperService.getDilationTimeRemaining(dilation);
  }

  getDilationTimeRemainingAsText(dilation: GlVisitDropDilation): string {
    return this.VisitDropHelperService.getDilationTimeRemainingAsText(dilation);
  }

  getDropTooltipText(drop: GlVisitDrop): string {
    return this.VisitDropHelperService.getDropTooltipText(drop);
  }

  // DILATION RECORD CHECK
  isDilationRecordSigned() {
    return this?.dilationRecord?.data_status === "SIGNED";
  }

  private _instantiateDilationTimer() {
    this.editModel.dilation = {
      hours: 1,
      minutes: 0,
      state: "ONGOING",
      // default 1 hr
      end_time: moment(this.editModel.time).add(1, "hour").toISOString(),
    };
  }

  private _getDilationEndTimeDate(
    time: Date | string,
    hours: number,
    minutes: number
  ) {
    return moment(time)
      .add(hours ?? 0, "hours")
      .add(minutes ?? 0, "minutes")
      .toDate();
  }
}

export class VisitDropComponent implements angular.IComponentOptions {
  static selector = "visitDrop";
  static template = require("./visit-drop.html");
  static controller = VisitDropController;
  static bindings = {
    record: "<",
    drop: "<",
    mode: "@?",
    isEditable: "<?",
    removeDrop: "&?",
    dilationRecord: "<",
  };
}
