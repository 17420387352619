import { User } from "models/user.model";
import { AuthService } from "app/core/services/auth.service";
import "./settings-advanced.scss";

class SettingsAdvancedPageController implements angular.IController {
  user: User;
  updateInProgress = false;
  editAdvancedForm: angular.IFormController;

  constructor(
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService,
    public $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  saveProfile() {
    this.updateInProgress = true;
    return this.AuthService.update(this.user)
      .then((user) => {
        this.user = user;
        this.editAdvancedForm.$setPristine();
        this.toastr.success("Saved user settings!");
      })
      .catch((error) => {
        console.error({ error });
        this.toastr.error("An error occurred.  Please try again");
      })
      .finally(() => (this.updateInProgress = false));
  }
}

export class SettingsAdvancedPageComponent
  implements angular.IComponentOptions
{
  static selector = "glSettingsAdvancedPage";
  static template = require("./settings-advanced.html");
  static controller = SettingsAdvancedPageController;
  static bindings = {
    user: "<",
  };
}
