export const pipeName = "iopColor";
export const iopColor: angular.Injectable<angular.FilterFactory> = () => {
  "ngInject";
  return (iop: number, target_iop: number) => {
    if (!iop || !target_iop) {
      return "alert-info";
    }
    if (iop <= target_iop) {
      return "alert-success";
    } else if (iop === target_iop + 1 || iop === target_iop + 2) {
      return "alert-warning";
    } else if (iop > target_iop + 2) {
      return "alert-danger";
    } else {
      return "alert-danger";
    }
  };
};
