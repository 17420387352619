import { IComponentOptions, IController } from "angular";
import { Appendix } from "app/core/services/appendix";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { isNil } from "lodash";
import { PatientRecordData } from "models/patient-record.model";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";

class SegmentDryEyeController extends GlFormController implements IController {
  record: PatientRecordData;
  ngRequired: boolean = true;

  osdiClassification: string = "";
  osdiRanges = this.appendix.getNumericRangesByKey("osdi");
  diagnosisErrors = this.ErrorAppendix.getDiagnosisErrorMessages();

  constructor(
    private appendix: Appendix,
    private ErrorAppendix: ErrorAppendix
  ) {
    "ngInject";
    super();
  }

  updateOsdiClassification(osdiScore: number) {
    let classification: string = "";

    if (!this.osdiRanges || isNil(osdiScore)) {
      classification = "";
    } else if (isNaN(osdiScore) || osdiScore < 0 || osdiScore > 100) {
      classification = this.diagnosisErrors.osdi.value;
    } else {
      for (const range of this.osdiRanges) {
        if (osdiScore > range.min && osdiScore <= range.max) {
          classification = range.title;
          break;
        }
      }
    }
    this.osdiClassification = classification;
  }
}

export class SegmentDryEye implements IComponentOptions {
  static selector = "segmentDryEye";
  static template = require("./segment-dry-eye.html");
  static controller = SegmentDryEyeController;
  static bindings = {
    enableLeft: "<",
    enableRight: "<",
    record: "<",
    ...GlFormControllerBindings,
  };
}
