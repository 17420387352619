import "@uirouter/angularjs";
import "angular";
import "angular-i18n/angular-locale_en-au";
import "ladda";
import "moment";

import * as Dropzone from "dropzone";
const dz = Dropzone;
dz.autoDiscover = false;

/**
 * VENDOR CSS
 */
import "angular-toastr/dist/angular-toastr.css";
import "animate.css/animate.css";
import "dropzone/dist/dropzone.css";
import "textangularjs/dist/textAngular.css";
