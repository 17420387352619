import { IGlSide } from "models/gl-side.model";
import { GlBilateral, GlDiagnosis } from "models/patient-record.model";
import {
  DiagnosisService,
  GlManagementType,
} from "../services/diagnosis.service";

export const pipeName = "hasDiagnosis";
export const pipe: angular.Injectable<angular.FilterFactory> = (
  DiagnosisService: DiagnosisService
) => {
  "ngInject";
  return (
    diagnosisArray: GlBilateral<GlDiagnosis[]>,
    disease: GlManagementType,
    side: IGlSide = "both"
  ) => {
    return DiagnosisService.diagnosisIncludesDisease(
      diagnosisArray,
      disease,
      side
    );
  };
};
