import { PatientProcedureDrop } from "../../../../../models/patient-procedure";

class GlLetterDropController implements angular.IController {
  drop: PatientProcedureDrop;
}

export class GlLetterDropComponent implements angular.IComponentOptions {
  static selector = "glLetterDrop";
  static template = require("./gl-letter-drop.html");
  static controller = GlLetterDropController;
  static bindings = {
    drop: "<"
  };
}
