import { IGlApiResponse } from "../../../models/gl-api-response.model";
import { Subscription } from "../../../models/subscription.model";
import { API_PATH } from "./api-paths";

export class SubscriptionService {
  static injectionName = "SubscriptionService";
  $httpDefaultCache = this.$cacheFactory.get("$http");
  constructor(
    private $http: angular.IHttpService,
    private $cacheFactory: angular.ICacheFactoryService,
    private API_URL: string
  ) {
    "ngInject";
  }

  ////////////////

  get(params: { patient_id: number }) {
    const id = params.patient_id;
    const url = this.getUrlForId(id);
    // Request to the API for the user associated with the token
    return this.$http
      .get<IGlApiResponse<Subscription>>(url, {
        cache: true
      })
      .then(response => {
        return response.data.data;
      });
  }

  create(params: { patient_id: number; token?: string }) {
    const id = params.patient_id;
    const url = this.getUrlForId(id);
    // Request to the API for the user associated with the token
    this.flushCacheForId(id);
    return this.$http
      .post<IGlApiResponse<Subscription>>(url, params)
      .then(response => {
        return response.data.data;
      });
  }

  cancel(params: { patient_id: number }) {
    const id = params.patient_id;
    const url = this.getUrlForId(id);
    // Request to the API for the user associated with the token
    this.flushCacheForId(id);
    return this.$http.delete(url).then(response => {
      return response.data;
    });
  }

  resume(params: { patient_id: number }) {
    const id = params.patient_id;
    const url = this.getUrlForId(id);
    // Request to the API for the user associated with the token
    this.flushCacheForId(id);
    return this.$http.put(url, params).then(response => {
      return response.data;
    });
  }

  getUrlForId(id: number) {
    return `${this.API_URL}${API_PATH}` + "/patient/" + id + "/subscription";
  }

  flushCacheForId(id: number) {
    const url = this.getUrlForId(id);
    this.$httpDefaultCache.remove(url);
  }
}
