import { IPromise } from "angular";
import { cloneDeep, get, isEmpty } from "lodash";
import { ProcedureDetails } from "../../../../../models/gl-external-procedure";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  PatientProcedureExternal,
  PatientProcedureType,
} from "../../../../../models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { GlFormController } from "../../gl-form-controller";
import "./patient-procedures.scss";

export const PATIENT_PROCEDURE_TEMPORARY_CREATE_EVENT: string =
  "patientProcedure:temporaryCreate";
export const PATIENT_PROCEDURE_TEMPORARY_CLEAR_EVENT: string =
  "patientProcedure:temporaryClear";
export const PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_REQ: string =
  "patientProcedure:temporaryHasProcedureReq";
export const PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES: string =
  "patientProcedure:temporaryHasProcedureRes";
export const PATIENT_PROCEDURE_TEMPORARY_CONFIRM_AUTOFILL: string =
  "patientProcedure:temporaryConfirmAutofill";

export class PatientProceduresController
  extends GlFormController
  implements angular.IComponentController, angular.IOnChanges
{
  record: PatientRecord;
  procedureToEdit: PatientProcedureExternal | object;
  procedures: PatientProcedureExternal[];
  saveProcedureInProgress = false;
  deleteProcedureInProgress = false;
  type: PatientProcedureType = "surgical";
  historicalEditableOnly = false;

  /*
    a temporary array to store any procedures 
    that need user action to save (e.g. autofills...)
    to distinguish uniqueness we check procedure_date which will be 
    overridden on save 

    autofill for post op <-> when will not work until 
    a procedure is confirmed 
  */
  temporaryProceduresToDisplay: PatientProcedureExternal[] = [];
  filteredProceduresToDisplay: PatientProcedureExternal[] = [];

  constructor(
    private $rootScope: angular.IRootScopeService,
    private toastr: angular.toastr.IToastrService,
    private PatientProcedureService: PatientProcedureService
  ) {
    "ngInject";
    super();
  }

  // INITIALISE LISTENERS
  $onInit(): void {
    // listens for the create event triggered on diagnosis autofill
    // creates or updates a temporary procedure
    this.$rootScope.$on(
      PATIENT_PROCEDURE_TEMPORARY_CREATE_EVENT,
      (
        event: any,
        tempProcedure: PatientProcedureExternal,
        recordId: number
      ) => {
        // guard: should only trigger for referenced record id procedures
        if (
          this.temporaryProceduresToDisplay?.includes(tempProcedure) ||
          this?.PatientProcedureService.activeExternalProcedureExists(
            this.filteredProceduresToDisplay,
            tempProcedure
          ) ||
          this?.record?.id !== recordId
        ) {
          return;
        }
        // 1. check if an exisitng temp procedure exists of the same type
        const existingProcedureTypeIndex: number =
          this.getIndexOfTemporaryProcedure(tempProcedure);

        // case a: non-exisitng means straightforward add to temporary and merge
        if (existingProcedureTypeIndex === -1) {
          this.addTemporaryProcedure(cloneDeep(tempProcedure));
          this.toastr.info(
            `${tempProcedure.data.nameAppendix.name} procedure has been autofilled!`
          );
        } else {
          // already existing and has not been autofill confirmed, yet, alter
          // immediately
          const temporaryIndex: number =
            this.temporaryProceduresToDisplay?.findIndex(
              (p) =>
                p.data.nameAppendix.name ===
                tempProcedure.data.nameAppendix.name
            );

          this.temporaryProceduresToDisplay.splice(
            temporaryIndex,
            1,
            cloneDeep(tempProcedure)
          );

          this.toastr.info(
            `${tempProcedure.data.nameAppendix.name} procedure has been updated!`
          );
        }

        // finally update it all with a request
        return this?.$rootScope?.$broadcast(
          PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES,
          tempProcedure.data.nameAppendix.name,
          tempProcedure.data.eye,
          recordId
        );
      }
    );

    // clears a temporary procedure on intercept
    this.$rootScope.$on(
      PATIENT_PROCEDURE_TEMPORARY_CLEAR_EVENT,
      (event: any, diagnosisName: string, recordId: number) => {
        // guard: should only trigger for referenced record id procedures
        // and only if the procedure exists as an autofill
        if (this?.record?.id !== recordId) {
          return;
        }

        // otherwise look for it and clear it
        const temporaryIndex: number =
          this.temporaryProceduresToDisplay?.findIndex(
            (p) => p.data.nameAppendix.name === diagnosisName
          );

        // not found dont bother
        if (temporaryIndex === -1) {
          return;
        }

        // toehrwise remove
        this.removeTemporaryProcedureByIndex(temporaryIndex);

        // then always update
        this.updateDiagnosisProcedureAutofillMap(diagnosisName, recordId);

        this.toastr.info(`Successfully undone ${diagnosisName} autofill!`);
      }
    );

    // request listener that returns if a procedure has been autofilled
    // based on diagnosis and record id
    this.$rootScope.$on(
      PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_REQ,
      (event: any, diagnosisName: string, recordId: number) => {
        // guard: should only trigger for referenced record id procedures
        // and only if the procedure exists as an autofill
        if (this?.record?.id !== recordId) {
          return;
        }

        // autofill
        const existingProcedure =
          this.PatientProcedureService.activeExternalProcedureExistsByDiagnosis(
            this.temporaryProceduresToDisplay,
            diagnosisName
          );

        // if found, then return the side
        return this?.$rootScope?.$broadcast(
          PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES,
          diagnosisName,
          existingProcedure?.data?.eye ?? null,
          recordId
        );
      }
    );

    // if autofill was confirmed from diagnosis side, confirm it on procedure side
    this.$rootScope.$on(
      PATIENT_PROCEDURE_TEMPORARY_CONFIRM_AUTOFILL,
      (event: any, diagnosisName: string, recordId: number) => {
        // guard: should only trigger for referenced record id procedures
        // and only if the procedure exists as an autofill
        if (this?.record?.id !== recordId) {
          return;
        }

        // find the procedure to save
        const existingProcedure =
          this.PatientProcedureService.activeExternalProcedureExistsByDiagnosis(
            this.temporaryProceduresToDisplay,
            diagnosisName
          );
        const existingProcedureIndex =
          this.getIndexOfTemporaryProcedure(existingProcedure);

        // only accept autofill if both work
        if (existingProcedure && existingProcedureIndex >= 0) {
          // save
          this.acceptAutofillProcedure(
            existingProcedure,
            existingProcedureIndex
          );
        }

        this.toastr.success(
          `Successfully confirmed ${diagnosisName} procedure autofill!`
        );
      }
    );
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.procedures || changes.record) {
      // work out which procedures to display
      this.filterProceduresToDisplay();
    }
  }

  // PROCEDURE DISPLAY RELATED
  filterProceduresToDisplay() {
    if (!this.procedures) {
      return;
    }
    // temporary ones are technically newest
    this.filteredProceduresToDisplay = this.procedures;
    if (this.record) {
      // only display procedures associated with this record
      // it belongs to this record if
      this.filteredProceduresToDisplay = this.procedures.filter((p) =>
        this.showProcedureInEditMode(p)
      );
    }
  }

  showProcedureInEditMode(procedure: PatientProcedureExternal) {
    return this.PatientProcedureService.showProcedureInEditMode(
      procedure,
      this.record.id
    );
  }

  // EDITING
  addProcedure() {
    this.procedureToEdit = {};
  }

  edit(procedure: PatientProcedureExternal) {
    this.procedureToEdit = procedure;
  }

  cancel() {
    this.procedureToEdit = undefined;
  }

  // PROCEDURE CRUD
  save(procedure: PatientProcedureExternal) {
    this.saveProcedureInProgress = true;
    let savePromise: IPromise<PatientProcedureExternal>;
    if (this.historicalEditableOnly) {
      procedure = { ...procedure, status: "historical" };
    }
    if (procedure.id) {
      savePromise = this.PatientProcedureService.updateExternalProcedure(
        this.record?.id,
        procedure
      );
    } else {
      savePromise = this.PatientProcedureService.createExternalProcedure(
        this.record?.id,
        procedure
      );
    }
    return savePromise
      .then((createdProcedure: PatientProcedureExternal) => {
        this.procedureToEdit = undefined;
        // cleanup for temporary procedures
        if (!procedure.id && procedure.is_temp) {
          this.removeTemporaryProcedure(procedure);
          // autofill side as well
          this?.$rootScope?.$broadcast(
            PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES,
            procedure.data.nameAppendix.name,
            null,
            this.record.id
          );
        }
        this.toastr.success(
          `Successfully ${procedure.id ? "updated" : "created"} procedure!`
        );
        return createdProcedure;
      })
      .finally(() => (this.saveProcedureInProgress = false));
  }

  // breaks it into either delete from temporary procedure or actual procedure
  handleDelete(procedure: PatientProcedureExternal) {
    // if temp delete by executing an undo
    if (procedure.is_temp) {
      this.deleteProcedureInProgress = true;
      this.undoAutofillProcedure(procedure);
      this.cancel();
      this.deleteProcedureInProgress = false;
    } else {
      // otherwise delete as normal
      return this.deleteProcedure(procedure);
    }
  }

  // deletes a regular procedure
  deleteProcedure(procedure: PatientProcedureExternal) {
    this.deleteProcedureInProgress = true;
    this.PatientProcedureService.deleteExternalProcedure(
      this.record?.id,
      procedure
    )
      .then(() => {
        this.cancel();
        this.toastr.success("Successfully deleted procedure!");
      })
      .finally(() => (this.deleteProcedureInProgress = false));
  }

  // PROCEDURE DISPLAY RELATED
  // procedure has side?
  hasSide(procedure: PatientProcedureExternal, side: IGlSide) {
    const eyeSide = get(procedure, "data.eye");
    return ["both", side].includes(eyeSide);
  }

  getProcedureDate(procedure: PatientProcedureExternal, side: IGlSide) {
    if (procedure.data?.[side]) {
      return procedure.data[side].date;
    } else {
      return procedure.procedure_date;
    }
  }

  // generates a summary line of the procedure
  getProcedureSummary(procedure: PatientProcedureExternal, side: IGlSide) {
    const { data } = procedure;

    /*
      if another name exists and the key is actually other
        use that
        otherwise check level 2 appendix
          use display text or the name
          otherwise general name
    */
    const name =
      data.name_other && data?.level2Appendix.key === "other"
        ? data.name_other
        : data.level2Appendix
        ? data.level2Appendix.displayText || data.level2Appendix.name
        : data.name;

    // some cases we use name_other as a description key
    const descOther: string =
      !isEmpty(data?.name_other) && data?.level2Appendix.key !== "other"
        ? ` (${data.name_other})`
        : "";

    // if no details exist go simple
    const details: ProcedureDetails = data[side];
    if (!details) {
      // this is an older record type
      return `${name}${descOther}`.trim();
    }

    // routine if needed?
    const routineStr = !details.complete
      ? ""
      : details.routine
      ? "Routine"
      : "Complicated";

    /*
        if incomplete
          nothing
          if routine
            show routine
            otherwise complicated
      */
    return `${routineStr} ${name}${descOther}`.trim();
  }

  getProcedureEyeOrder(procedure: PatientProcedureExternal) {
    // order of procedure if it counts
    switch (procedure?.data?.order) {
      case "left_right":
        return "(L -> R)";
      case "right_left":
        return "(R -> L)";
      default:
        return "";
    }
  }

  // procedure background cosmetic
  getProcedureBackground(procedure: PatientProcedureExternal, side: IGlSide) {
    const completionDate = this.getProcedureDate(procedure, side);
    const details: ProcedureDetails = procedure.data[side];

    if (procedure.is_temp) {
      return "bg-info"; // autofill is temporary
    } else if (procedure.status === "historical") {
      return "bg-secondary";
    } else if (!completionDate) {
      return "bg-warning";
    } else if (completionDate && (!details || (details && details.routine))) {
      // if complete  & routine then green
      return "alert-success";
    } else if (completionDate && "routine" in details && !details.routine) {
      // if complete & complicated then red
      return "alert-danger";
    }
  }

  // TEMPORARY PROCEDURE STUFF
  getIndexOfTemporaryProcedure(procedure: PatientProcedureExternal): number {
    // check by name appendix association, we just
    // need the main operation and as long as its not a temp one
    return this.temporaryProceduresToDisplay?.findIndex((p) => {
      return p.data.nameAppendix.key === procedure.data.nameAppendix.key;
    });
  }

  // straight forward push
  addTemporaryProcedure(procedure: PatientProcedureExternal) {
    // if not exists
    this.temporaryProceduresToDisplay.push(procedure);
  }

  getTemporaryProcedureIndex(procedure: PatientProcedureExternal) {
    return this.temporaryProceduresToDisplay?.findIndex(
      (p) => p.data.nameAppendix.name === procedure.data.nameAppendix.name
    );
  }

  removeTemporaryProcedure(procedure: PatientProcedureExternal) {
    // find by diagnosis
    const index: number = this.temporaryProceduresToDisplay?.findIndex(
      (p) => p.data.nameAppendix.name === procedure.data.nameAppendix.name
    );

    if (index !== -1) {
      this.temporaryProceduresToDisplay.splice(index, 1);
      this.toastr.success("Successfully removed temporary procedure!");
    }
  }
  // remove by index
  removeTemporaryProcedureByIndex(index: number) {
    if (index >= 0) {
      this.temporaryProceduresToDisplay.splice(index, 1);
      this.toastr.success("Successfully removed temporary procedure!");
    }
  }

  // DIAG <-> EXTERNAL PROCEDURE RELATED
  acceptAutofillProcedure(procedure: PatientProcedureExternal, index: number) {
    // accepting is just creating and then removing
    this.save(procedure).then(() => {
      this.removeTemporaryProcedureByIndex(index);
      // then always update
      this.updateDiagnosisProcedureAutofillMap(
        procedure?.data?.nameAppendix?.name,
        this.record.id
      );
    });
  }

  // regular undo
  undoAutofillProcedure(procedure: PatientProcedureExternal) {
    this.removeTemporaryProcedure(procedure);

    // then clear by updating
    this.updateDiagnosisProcedureAutofillMap(
      procedure.data.nameAppendix.name,
      this.record.id
    );
  }

  // just as simple as undo'ing
  undoAutofillProcedureByIndex(
    procedure: PatientProcedureExternal,
    index: number
  ) {
    this.removeTemporaryProcedureByIndex(index);

    // then clear by updating
    this.updateDiagnosisProcedureAutofillMap(
      procedure.data.nameAppendix.name,
      this.record.id
    );
  }

  // if a procedure autofill is confirmed/undone here
  // will trigger this to either confirm or cancel the autofill
  updateDiagnosisProcedureAutofillMap(diagnosisName: string, recordId: number) {
    // autofill
    const existingProcedure =
      this.PatientProcedureService.activeExternalProcedureExistsByDiagnosis(
        this.temporaryProceduresToDisplay,
        diagnosisName
      );

    // if found, then return the side
    // otherwise will return null to indicate to delte
    return this?.$rootScope?.$broadcast(
      PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES,
      diagnosisName,
      existingProcedure?.data?.eye ?? null,
      recordId
    );
  }
}

export class PatientProcedures implements angular.IComponentOptions {
  static selector = "patientProcedures";
  static template = require("./patient-procedures.html");
  static controller = PatientProceduresController;
  static bindings = {
    mode: "@",
    record: "<",
    procedures: "<",
    type: "@",
    historicalEditableOnly: "<?",
  };
}
