import { isEmpty } from "lodash";
import { IGlSide } from "models/gl-side.model";
import { PatientProcedureInjection } from "models/patient-procedure";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import {
  IGlInjectionRecord,
  IGlInjectionRecordData,
  IGlInjectionWho,
} from "../../../../../models/injection";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../../../core/components/gl-form-controller";
import {
  IConsolidatedInjection,
  IGlPatientProcedureInjectionExtended,
  InjectionHelperService,
} from "../../../../core/services/injection-helper/injection-helper.service";
import "./injection-form.component.scss";
import moment = require("moment");

interface IInjectionState {
  activeInjections: IGlPatientProcedureInjectionExtended[];
  mostRecentInjection: IGlInjectionRecord;
  previousCycleCount: number;
  previousTotalCount: number;
  selectedConsolidatedInjection: IConsolidatedInjection;
  selectedInjection: IGlPatientProcedureInjectionExtended;
}

class InjectionFormController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // @Inputs()
  debug = false;
  leftInjectionDetails: IGlInjectionRecordData;
  rightInjectionDetails: IGlInjectionRecordData;
  allLeftInjections: IConsolidatedInjection[];
  allRightInjections: IConsolidatedInjection[];
  isEditable = true;
  recordId: number;
  addInjection: number;

  // Class Bindings
  form: angular.IFormController;
  // Datepicker settings
  // Set to track date popup state
  datePopupIsOpen = false;
  // Toggles the state
  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  leftState: IInjectionState = {
    activeInjections: [],
    mostRecentInjection: undefined,
    previousCycleCount: undefined,
    previousTotalCount: undefined,
    selectedConsolidatedInjection: undefined,
    selectedInjection: undefined,
  };

  rightState: IInjectionState = {
    activeInjections: [],
    mostRecentInjection: undefined,
    previousCycleCount: undefined,
    previousTotalCount: undefined,
    selectedConsolidatedInjection: undefined,
    selectedInjection: undefined,
  };

  constructor(private InjectionHelperService: InjectionHelperService) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    const isNewInjection =
      isEmpty(this.leftInjectionDetails) && isEmpty(this.rightInjectionDetails);

    if (changes.allLeftInjections && this.allLeftInjections) {
      this.leftState.activeInjections = this.filterInjectionsForSelectBox(
        this.allLeftInjections
      );
    }

    if (changes.allRightInjections && this.allRightInjections) {
      this.rightState.activeInjections = this.filterInjectionsForSelectBox(
        this.allRightInjections
      );
    }

    // LEFT SIDE
    if (
      (changes.allLeftInjections || changes.leftInjectionDetails) &&
      this.allLeftInjections &&
      this.leftInjectionDetails
    ) {
      // this injection is already linked to a procedure, so set the selected
      // injection to the one specified by the procedure_id
      if (this.leftInjectionDetails.procedure_id) {
        this.leftState.selectedInjection = this.allLeftInjections
          .flatMap((i) => i.injections)
          .find((i) => i.id === this.leftInjectionDetails.procedure_id);
      } else if (isNewInjection) {
        // only set the default injection if both left & right injection details
        // are empty. This means that the deault is only loaded when creating an
        // injection. Not after editing of the injection has commenced
        this.leftState.selectedInjection = this.leftState.activeInjections[0];
        this.selectedInjectionDidChange("left");
      }
      this.updateConsolidatedInjectionForSelectedInjection(
        "left",
        this.leftInjectionDetails.procedure_id
      );
      this.setInjectionDefaults(
        this.leftInjectionDetails,
        this.leftState.selectedInjection
      );
    }

    // RIGHT SIDE
    if (
      (changes.allRightInjections || changes.rightInjectionDetails) &&
      this.allRightInjections &&
      this.rightInjectionDetails
    ) {
      // this injection is already linked to a procedure, so set the selected
      // injection to the one specified by the procedure_id
      if (this.rightInjectionDetails.procedure_id) {
        this.rightState.selectedInjection = this.allRightInjections
          .flatMap((i) => i.injections)
          .find((i) => i.id === this.rightInjectionDetails.procedure_id);
      } else if (isNewInjection) {
        // only set the default injection if both left & right injection details
        // are empty. This means that the deault is only loaded when creating an
        // injection. Not after editing of the injection has commenced
        this.rightState.selectedInjection = this.rightState.activeInjections[0];
        this.selectedInjectionDidChange("right");
      }
      this.updateConsolidatedInjectionForSelectedInjection(
        "right",
        this.rightInjectionDetails.procedure_id
      );

      this.setInjectionDefaults(
        this.rightInjectionDetails,
        this.rightState.selectedInjection
      );
    }
  }

  selectedInjectionDidChange(side: IGlSide) {
    const state = side === "left" ? this.leftState : this.rightState;
    if (!state.selectedInjection) {
      return;
    }
    if (side === "left") {
      this.leftInjectionDetails.procedure_id = state.selectedInjection?.id;
      this.setInjectionDefaults(
        this.leftInjectionDetails,
        state.selectedInjection
      );
    }
    if (side === "right") {
      this.rightInjectionDetails.procedure_id = state.selectedInjection?.id;
      this.setInjectionDefaults(
        this.rightInjectionDetails,
        state.selectedInjection
      );
    }
    this.updateConsolidatedInjectionForSelectedInjection(
      side,
      state.selectedInjection.id
    );
  }

  getFormattedInjection(injection: PatientProcedureInjection) {
    if (!injection) {
      return;
    }
    // const { currentCycle } = injection;
    return `${injection.data.name_other || injection.data.name.name} - ${
      injection.data.frequency.name
    }`;
  }

  getClassForMostRecentInjection(
    selectedInjection: IGlPatientProcedureInjectionExtended,
    weeksSinceLastInjection: number
  ) {
    if (!selectedInjection) {
      return;
    }
    const targetInterval = +selectedInjection.data.frequency.key;
    const diff = Math.abs(targetInterval - weeksSinceLastInjection);
    if (diff <= 1) {
      return "alert-info";
    } else if (1 < diff && diff <= 2) {
      return "alert-warning";
    } else if (3 < diff) {
      return "alert-danger";
    }
  }

  routineDidChange(injectionDetails: IGlInjectionRecordData) {
    const defaultRoutineInjectionComment = "Routine Injection";

    if (!injectionDetails.comment) {
      injectionDetails.comment = injectionDetails.routine
        ? defaultRoutineInjectionComment
        : null;
    }
  }

  getInjectionTotal(side: IGlSide) {
    const state = side === "left" ? this.leftState : this.rightState;
    const injectionDetails =
      side === "left" ? this.leftInjectionDetails : this.rightInjectionDetails;
    this.addInjection = this.recordId ? 0 : 1;

    return (
      Math.max(state.previousTotalCount, 1) + // we set the minimum to be 1 to fix a display bug
      (injectionDetails.count ?? state.previousCycleCount)
    );
  }

  private findMostRecentInjection(
    consolidatedInjection: IConsolidatedInjection,
    side: IGlSide
  ) {
    const allPreviousInjections = consolidatedInjection.injections
      .flatMap((i) => i.records)
      .filter((r) => r.id !== this.recordId && side in r.data);
    return allPreviousInjections[0];
  }

  private filterInjectionsForSelectBox(
    consolidatedInjections: IConsolidatedInjection[]
  ) {
    return consolidatedInjections
      .map((i) => i.currentCycle)
      .filter((i) => i.data.frequency.key !== "complete");
  }

  private updateConsolidatedInjectionForSelectedInjection(
    side: IGlSide,
    injectionId: number
  ) {
    const state = side === "left" ? this.leftState : this.rightState;
    const allConsolidatedInjections =
      side === "left" ? this.allLeftInjections : this.allRightInjections;
    if (!state.selectedInjection) {
      return;
    }

    const consolidatedInjection = allConsolidatedInjections.find(
      (consolidateInject) =>
        consolidateInject.injections.some((inject) => inject.id === injectionId)
    );

    // we count records that are COMPLETE only as a previous cycle count
    const previousCycleCount =
      this.InjectionHelperService.getCycleCountForSelectedInjectionRecord(
        state.selectedInjection
      );

    state.selectedConsolidatedInjection = consolidatedInjection;
    state.previousTotalCount =
      state.selectedConsolidatedInjection.currentTotal - previousCycleCount;
    state.previousCycleCount = previousCycleCount;
    state.mostRecentInjection = this.findMostRecentInjection(
      consolidatedInjection,
      side
    );
  }

  private setInjectionDefaults(
    injectionDetails: IGlInjectionRecordData,
    selectedInjection: IGlPatientProcedureInjectionExtended
  ) {
    if (!selectedInjection) {
      return;
    }
    const defaults: Partial<IGlInjectionRecordData> = {
      routine: true,
      who: this.defaultInjectionWhoField(selectedInjection),
    };
    if (injectionDetails.date == null) {
      injectionDetails.date = new Date();
    } else if (!(injectionDetails?.date instanceof Date)) {
      injectionDetails.date = moment(injectionDetails.date).toDate();
    }
    Object.assign(injectionDetails, defaults, injectionDetails);
    this.routineDidChange(injectionDetails);
  }

  private defaultInjectionWhoField(
    selectedInjection: IGlPatientProcedureInjectionExtended
  ): IGlInjectionWho {
    const cycleTotal = selectedInjection.data.repeat_count;
    const cycleFrequency = selectedInjection.data.frequency;
    const cycleCount = selectedInjection.cycleCount;

    const injectFrequencies = ["1", "4", "6"];

    if (cycleCount >= cycleTotal - 1) {
      return "review";
    } else if (injectFrequencies.includes(cycleFrequency.key)) {
      return "injection";
    } else {
      return "opt-injection";
    }
  }
}

export class InjectionFormComponent implements angular.IComponentOptions {
  static selector = "glInjectionForm";
  static template = require("./injection-form.component.html");
  static controller = InjectionFormController;
  static bindings = {
    debug: "<?",
    injectionCount: "<?",
    leftInjectionDetails: "<",
    rightInjectionDetails: "<",
    allLeftInjections: "<",
    allRightInjections: "<",
    recordId: "<",
    ...GlFormControllerBindings,
  };
}
