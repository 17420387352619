import { PatientService } from "app/core/services/patient.service";
import { User } from "../../../../../models/user.model";
import "./patient-search.scss";

class PatientSearchController implements angular.IController {
  // @Input()
  allPatients: User[];

  constructor(private PatientService: PatientService) {
    "ngInject";
  }

  searchPatients(q: string) {
    if (q) {
      return this.PatientService.searchPatientsByName({ q }).then(
        (response) => {
          return response;
        }
      );
    }
  }
}

export class PatientSearchComponent implements angular.IComponentOptions {
  static selector = "patientSearch";
  static template = require("./patient-search.html");
  static controller = PatientSearchController;
  static bindings = {
    allPatients: "<",
  };
}
