import { isFunction } from "angular";
import "./alert-action-box.scss";

// OPTIONAL: alert box that can be used for certain sections
// else popover is preferred
class AlertActionBoxController
  implements angular.IController, angular.IOnChanges
{
  closable: boolean = true;

  onClose: () => void;

  constructor() {
    "ngInject";
  }

  $onChanges(): void {
    return;
  }

  handleOnClose() {
    if (isFunction(this.onClose)) {
      this.onClose();
    }
  }
}

export class AlertActionBox implements angular.IComponentOptions {
  static selector = "alertActionBox";
  static transclude = {
    title: "?alertActionBoxTitle",
    content: "alertActionBoxContent",
    buttons: "?alertActionBoxButtons",
  };
  static template = require("./alert-action-box.html");
  static controller = AlertActionBoxController;
  static bindings = {
    closable: "<",
    onClose: "&",
    class: "@?",
  };
}
