import { IConsolidatedInjection } from "app/core/services/injection-helper/injection-helper.service";
import { IGlSignature } from "app/core/services/signature-service/signature.service";
import * as cleanDeep from "clean-deep";
import { get, isEmpty, pick } from "lodash";
import { PatientContact } from "models/patient-contact.model";
import { IGlSide } from "../../../../../models/gl-side.model";
import { GlLetterType } from "../../../../../models/letter.model";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "../../../../../models/patient-procedure";
import {
  GlPatientProviders,
  GlProvider,
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { DiagnosisService } from "../../../services/diagnosis.service";
import { GlModelService } from "../../../services/gl-model.service";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";

export class LetterController implements angular.IComponentController {
  // Controller Inputs
  consolidatedRecord: PatientRecord;
  record: PatientRecord;
  records: PatientRecord[];
  history: PatientRecordData;
  patient: User;
  // This determines who the letter will be sent to. The addressee details will then be automatically imported
  letterType: GlLetterType;
  // Set the following to true if the addressee is a referrer
  isReferrer: boolean;
  // Set the following to be the details of the managing optom
  // this will then be use to write who the user is visiting
  managingOptom: PatientClinic;
  user: User;
  signature: IGlSignature;
  providers: GlPatientProviders;
  contacts: PatientContact[];
  // Calculated properties
  date = new Date();
  activeDrops: PatientProcedureDrop[];
  dropsCommenced: PatientProcedureDrop[];
  proceduresPerformed: PatientProcedureInHouse[];
  proceduresBooked: PatientProcedureExternal[];
  consolidatedInjections: IConsolidatedInjection[];
  consolidatedInjectionsForLetter: any;
  consolidatedInjectionsDoneForLetter: any;
  proceduresInRecord: PatientProcedureExternal[];
  dropsInRecord: PatientProcedureDrop[];
  selectedReferrer: any;

  // drugs
  activeDrugs: PatientProcedureDrug[];
  drugsCommenced: PatientProcedureDrug[];
  drugsInRecord: PatientProcedureDrug[];

  constructor(
    private DiagnosisService: DiagnosisService,
    private GlModelService: GlModelService,
    private PatientRecordService: PatientRecordService,
    private PatientProcedureService: PatientProcedureService
  ) {
    "ngInject";
  }

  setDrops(drops: PatientProcedureDrop[]) {
    // filter all the drops to get a list of drops started in this visit.
    this.dropsCommenced = drops?.filter(
      (d) => d.data.treatment_start_record_id === this.record.id
    );
    // filter all the drops to get a list of drops commenced in this visit
    this.activeDrops = drops?.filter((d) => !d.data.treatment_end_date);
  }

  setDrugs(drugs: PatientProcedureDrug[]) {
    // set that to a list of one off drugs or current drugs
    this.drugsCommenced = drugs?.filter(
      (d) =>
        d.record_id === this.record.id &&
        (!d.data.treatment_end_date || d.data.one_off)
    );

    // then active drugs to be those with no end date
    this.activeDrugs = drugs?.filter((d) => !d.data.treatment_end_date);
  }

  setInHouseProcedures(procedures: PatientProcedureInHouse[]) {
    this.proceduresPerformed =
      this.PatientProcedureService.getRecordInHouseProcedures(
        procedures,
        this.record.id
      );
  }

  getProcedureDateToCheck(procedure: PatientProcedureExternal) {
    // return procedure direct if exist immediately
    if (procedure.procedure_date) {
      return new Date(procedure.procedure_date);
    }

    // else check through all
    let date: string | number | Date;
    // eye
    if (procedure.data.eye && (procedure.data?.left || procedure.data?.right)) {
      switch (procedure.data.eye) {
        case "both":
          date = procedure.data?.left?.date ?? procedure.data?.right?.date;
          break;
        case "left":
          date = procedure.data?.left?.date;
          break;
        case "right":
          date = procedure.data?.right?.date;
          break;
        default:
          date = procedure.created_at;
          break;
      }
    }

    // default
    return new Date(date ?? procedure.created_at);
  }

  setExternalProcedures(procedures: PatientProcedureExternal[]) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    this.proceduresInRecord = procedures.filter(
      (p) => this.getProcedureDateToCheck(p) > sixMonthsAgo
    );

    this.proceduresBooked = procedures.filter(
      (p) => p.data.created_in_record_id === this.record.id
    );
  }

  hasProceduresBooked() {
    return this.proceduresBooked.length > 0 ? true : false;
  }

  setConsolidatedInjections(injections: IConsolidatedInjection[]) {
    this.consolidatedInjections = injections?.filter((consolidatedInjection) =>
      consolidatedInjection.injections?.some(
        (i) => i.record_id === this.record.id
      )
    );
  }

  setConsolidatedInjectionsDoneForLetter(injections: IConsolidatedInjection[]) {
    this.consolidatedInjectionsDoneForLetter = {
      left: { amount: 0, types: [] },
      right: { amount: 0, types: [] },
      both: { amount: 0, types: [] },
    };

    const injectionsDoneToday = injections?.filter(
      (consolidatedInjection) =>
        consolidatedInjection.currentTotal > 0 &&
        consolidatedInjection.injections?.some(
          (i) => i.record_id === this.record.id
        )
    );
    injectionsDoneToday?.forEach((inj) => {
      inj.injections.forEach((individualInj) => {
        if (individualInj.record_id === this.record.id) {
          if (individualInj.data.eye === "right") {
            this.consolidatedInjectionsDoneForLetter.right.amount += 1;
            this.consolidatedInjectionsDoneForLetter.right.types.push({
              name: this.injectionIsOther(inj)
                ? this.injectionIsOther(inj)
                : individualInj.data.name.displayText,
              amount: 1,
            });
          } else if (individualInj.data.eye === "left") {
            this.consolidatedInjectionsDoneForLetter.left.amount += 1;
            this.consolidatedInjectionsDoneForLetter.left.types.push({
              name: this.injectionIsOther(inj)
                ? this.injectionIsOther(inj)
                : individualInj.data.name.displayText,
              amount: 1,
            });
          } else if (individualInj.data.eye === "both") {
            this.consolidatedInjectionsDoneForLetter.both.amount += 1;
            this.consolidatedInjectionsDoneForLetter.both.types.push({
              name: this.injectionIsOther(inj)
                ? this.injectionIsOther(inj)
                : individualInj.data.name.displayText,
              amount: 1,
            });
          }
        }
      });
    });
  }

  setConsolidatedInjectionsForLetter(injections: IConsolidatedInjection[]) {
    this.consolidatedInjectionsForLetter = {
      left: { amount: 0, types: [] },
      right: { amount: 0, types: [] },
      both: { amount: 0, types: [] },
    };
    injections?.forEach((inj) => {
      if (inj.eye === "right" && inj.currentTotal > 0) {
        this.consolidatedInjectionsForLetter.right.amount += inj.currentTotal;
        this.consolidatedInjectionsForLetter.right.types.push({
          name: this.injectionIsOther(inj)
            ? this.injectionIsOther(inj)
            : inj.name.displayText,
          amount: inj.currentTotal,
        });
      } else if (inj.eye === "left" && inj.currentTotal > 0) {
        this.consolidatedInjectionsForLetter.left.amount += inj.currentTotal;
        this.consolidatedInjectionsForLetter.left.types.push({
          name: this.injectionIsOther(inj)
            ? this.injectionIsOther(inj)
            : inj.name.displayText,
          amount: inj.currentTotal,
        });
      } else if (inj.eye === "both" && inj.currentTotal > 0) {
        this.consolidatedInjectionsForLetter.both.amount += inj.currentTotal;
        this.consolidatedInjectionsForLetter.both.types.push({
          name: this.injectionIsOther(inj)
            ? this.injectionIsOther(inj)
            : inj.name.displayText,
          amount: inj.currentTotal,
        });
      }
    });
  }

  injectionIsOther(injection: IConsolidatedInjection) {
    return injection.currentCycle.data.name_other;
  }

  showForLetterType(...args: string[]) {
    return args.includes(this.letterType);
  }

  hasFields(fields: string[]) {
    const dataFields = pick(this.record.data, fields);
    return !isEmpty(cleanDeep(dataFields));
  }

  getAddressee() {
    if (this.letterType === "gp") {
      return this.getFromConsolidatedRecord("providers.gp");
    } else if (this.letterType === "optometrist") {
      return this.getFromConsolidatedRecord("providers.optometrist");
    } else if (this.letterType === "referrer") {
      return this.getFromConsolidatedRecord("providers.referrerDetails");
    } else if (this.letterType === "managing_optom") {
      if (!this.managingOptom) {
        return;
      }
      const details: GlProvider = {
        firstName: this.managingOptom.provider.data.first_name,
        lastName: this.managingOptom.provider.data.last_name,
        clinicName: this.managingOptom.clinic.name,
      };
      return details;
    } else {
      return this.providers[this.selectedReferrer];
    }
  }

  getFieldToDisplay(
    fieldName: string,
    side: IGlSide,
    otherFieldName: string = `${fieldName}_other`
  ) {
    const val = get(this.record.data, `${fieldName}.${side}`);
    return get(
      this.record.data,
      `${otherFieldName}.${side}`,
      val && (val.name || val)
    );
  }

  getFromConsolidatedRecord(field: string) {
    return this.GlModelService.getFromRecordOrHistory(
      field,
      this.record.data,
      this.history
    );
  }

  getDiagnosis() {
    const diagnosis = this.record?.data?.management?.diagnosis_array;
    if (diagnosis) {
      return this.DiagnosisService.getDiagnosisForLetter(diagnosis);
    }
  }

  iopIsAtTarget() {
    const todaysIop = get(this.record.data, "iop");
    const target = get(this.record.data, "management.target_iop");
    return todaysIop?.left <= target?.left && todaysIop?.right <= target?.right;
  }

  getWhoText() {
    const who = get(this.record.data, "management.who");
    if (who === "ophthalmologist") {
      return "me";
    } else if (who === "technician") {
      return "technician";
    } else if (this.letterType === "managing_optom") {
      return "you";
    } else if (this.managingOptom) {
      return `${this.managingOptom.provider.data.first_name} ${this.managingOptom.provider.data.last_name}`;
    } else {
      return "their optometrist";
    }
  }

  getWhatText() {
    return this.PatientRecordService.getWhatStringFromRecord(
      this.record.data
    ).join(", ");
  }
}
