// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { DrugsDbService } from "app/core/services/drugs-db/drugs-db.service";
import { AuthService } from "../../core/services/auth.service";
import { MainPage } from "./main";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main", {
    abstract: true,
    component: MainPage.selector,
    data: {
      requiresLogin: true,
    },
    resolve: {
      user: (AuthService: AuthService) => {
        "ngInject";
        return AuthService.getUser();
      },
      customDrugs: (DrugsDbService: DrugsDbService) => {
        "ngInject";
        return DrugsDbService.getAllCustomDrugs()
          .then((customDrugs) => {
            return customDrugs;
          })
          .catch(() => {
            return [];
          });
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.main", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(MainPage.selector, MainPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
