import { IGlOption } from "app/core/services/appendix";
import { DiagnosisService } from "app/core/services/diagnosis.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { ValueAutofillService } from "app/core/services/value-autofill/value-autofill.service";
import { cloneDeep, get, isNil, set } from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlBilateral,
  GlDiagnosis,
  GlLens,
  GlLensObservation,
  PatientRecordData,
} from "models/patient-record.model";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import {
  PATIENT_DIAGNOSIS_PREFILL_TRIGGER_EVENT,
  PATIENT_DIAGNOSIS_UPDATE_PROCEDURE_EVENT,
} from "../patient-diagnosis/patient-diagnosis";
import angular = require("angular");

// wrapper for posteriror to accomodate for state logic
export class BilateralPosteriorLensController
  extends GlFormController
  implements angular.IComponentController
{
  PARENT_KEY: string = "lens";
  PARENT_KEY_APPENDIX: string = "lensPhakic";

  record: PatientRecordData;
  recordId: number = this.$stateParams.recordId;
  enableRight: boolean = false;
  enableLeft: boolean = false;

  // for autofill referencing
  timestampKeyArrayStatus: GlBilateral<number[]> = {};
  timestampKeyArrayObservations: GlBilateral<number[]> = {};

  // attributes for posterior lens specifically
  lensAttributes: string[] = this.PatientRecordService.getLensAttributes();
  diagnosisErrors = this.ErrorAppendix.getDiagnosisErrorMessages();

  bilateralPosteriorLensForm: angular.IFormController;

  // refs for the L/R controllers
  rightPosteriorLensRef: HTMLElement;
  leftPosteriorLensRef: HTMLElement;

  constructor(
    private PatientRecordService: PatientRecordService,
    private ValueAutofillService: ValueAutofillService,
    private toastr: angular.toastr.IToastrService,
    private DiagnosisService: DiagnosisService,
    private ErrorAppendix: ErrorAppendix,
    private $stateParams: angular.ui.IStateParamsService,
    private $rootScope: angular.IRootScopeService,
    private $timeout: angular.ITimeoutService
  ) {
    "ngInject";
    super();
  }

  // if lens and regular status
  handleAutofillLens(
    section: "status" | "observation",
    side: IGlSideBilateral,
    timestampKey: number,
    previousLensData: GlLens,
    currentStatus: IGlOption,
    index: number
  ) {
    // confirm no duplicate diagnoses exists
    const autofillDiagnosisExists: boolean =
      this.PatientRecordService.checkIfAutofillDiagnosisExists(
        this.record,
        side,
        currentStatus
      );

    // also confirm no duplicate observations exist
    const existingObservations: GlLensObservation[] =
      this?.record?.lens?.observations[side]?.filter(
        (o) => o?.type?.key === currentStatus?.key
      ) ?? [];

    // double check if theres any existing same diagnosis
    if (existingObservations.length > 1 || autofillDiagnosisExists) {
      this.ValueAutofillService.removeAutofillValueByKeyAndSide({
        parent_key: `${this.PARENT_KEY}`,
        option_key: currentStatus?.key,
        side,
        timestamp_key: timestampKey,
      });

      // this is to prevent screen overload
      // HREF CHECK
      return this.ValueAutofillService.shouldShowAutofillWarnings()
        ? this.toastr.info(this.diagnosisErrors.autofill.diagnosis_exists)
        : null;
    }

    // if all ok continue
    this.handleAutofill(
      section,
      side,
      timestampKey,
      previousLensData,
      currentStatus,
      index
    );
  }

  // autofill on click on specific autofill field
  handleAutofill(
    section: "status" | "observation",
    side: IGlSideBilateral,
    timestampKey: number,
    previousLensData: GlLens,
    currentStatus: IGlOption,
    index: number
  ) {
    // otherwise no dupes continue
    this.ValueAutofillService.setKeyTimestampAtIndex({
      timestampArray:
        section === "status"
          ? this.timestampKeyArrayStatus
          : this.timestampKeyArrayObservations,
      side,
      index,
      timestamp: timestampKey,
    });

    // set timestamp
    // push old changes first
    this.setPreviousStatusToStack(previousLensData, side, currentStatus);
    // autofill
    this.PatientRecordService.autofillDiagnosisSide({
      record: this.record,
      side,
      timestamp_key: timestampKey,
      parent_key: this.PARENT_KEY,
      option: currentStatus
        ? {
            name: currentStatus?.name,
            key: currentStatus?.key,
            diagnosis: currentStatus?.name,
          }
        : null,
    });

    // handle for diagnosis <-> external procedure
    // we also need to check for any linked procedure autofills
    this.$timeout(100).then(() => {
      this.updateAutofillExternalProcedure(side, currentStatus);
    });
  }

  // prefills are like autofill on init
  handlePrefill(
    section: "status" | "observation",
    side: IGlSideBilateral,
    timestampKey: number,
    currentStatus: IGlOption,
    index: number
  ) {
    // set internal reference
    this.ValueAutofillService.setKeyTimestampAtIndex({
      timestampArray:
        section === "status"
          ? this.timestampKeyArrayStatus
          : this.timestampKeyArrayObservations,
      side,
      index,
      timestamp: timestampKey,
    });

    // a simplified version of option
    const option = currentStatus
      ? {
          name: currentStatus?.name,
          key: currentStatus?.key,
          diagnosis: currentStatus?.name,
        }
      : null;

    // if the existing diagnosis is found then activate prefill
    // get index of found diagnosis
    const existingDiagnosisIndex: number =
      this.PatientRecordService.getIndexOfDiagnosisByOption(
        this.record.management.diagnosis_array,
        side,
        option
      );
    // dont bother if not there
    if (existingDiagnosisIndex === -1) {
      return;
    }

    // prefills have no previous state, its technically just empty
    this.PatientRecordService.autofillDiagnosisSideAsPrefill({
      record: this.record,
      side,
      timestamp_key: timestampKey,
      parent_key: this.PARENT_KEY,
      option,
    });

    // then also trigger diagnosis side
    this.$rootScope.$broadcast(
      PATIENT_DIAGNOSIS_PREFILL_TRIGGER_EVENT,
      currentStatus?.name,
      this.recordId
    );
  }

  // copy button
  handleOnCopy(side: IGlSideBilateral, previousRecordData: PatientRecordData) {
    // status
    const currentStatus: IGlOption = this.record.lens.status[side];
    if (isNil(currentStatus)) {
      return;
    }

    const currentStatusHasAutofill: boolean =
      this.ValueAutofillService.isOptionAutofill(
        `${this.PARENT_KEY}.status`,
        currentStatus.key
      );

    // we have to check the observations also
    // if not go through all observations with autofill and handle appropriately
    const observationsWithAutofill: GlLensObservation[] =
      this.record.lens.observations[side].filter((o) =>
        // autofill exists?
        this.ValueAutofillService.isOptionAutofill(
          `${this.PARENT_KEY}.observations`,
          o?.type?.key
        )
      );

    let timestampKey: number;

    // ENACT A CHANGE
    this.formDidChange();

    // CHECK PREVIOUS DATA FIRST
    // do a check if the previous status or any observations has any existing
    // autofill and remove
    const previousLensData: GlLens = previousRecordData?.lens;
    const previousStatus: IGlOption = previousLensData?.status[side];
    const previousObservations: GlLensObservation[] =
      previousLensData?.observations[side];

    // 1. undo any previous autofills
    // any previous status was an autofill?
    if (this.isOptionAutofillActive(side, previousStatus, "status", 0)) {
      const relatedDiagnosis: GlDiagnosis =
        this.DiagnosisService.getDiagnosisAutofillMapping(previousStatus.key);
      // undo after finding related diagnosis
      this.PatientRecordService.undoAutofillForDiagnosisSideOnly({
        record: this.record,
        side,
        diagnosisKey:
          this.DiagnosisService.convertDiagnosisOptionToKey(relatedDiagnosis),
      });
    }

    // any previous observations that were autofill?
    if (previousObservations?.length) {
      // go from bottom to not mess with index
      for (const [_index, _prevObs] of previousObservations
        .reverse()
        .entries()) {
        if (
          this.isOptionAutofillActive(
            side,
            _prevObs?.type,
            "observations",
            _index
          )
        ) {
          const relatedDiagnosis: GlDiagnosis =
            this.DiagnosisService.getDiagnosisAutofillMapping(
              _prevObs?.type?.key
            );
          // undo after finding related diagnosis
          this.PatientRecordService.undoAutofillForDiagnosisSideOnly({
            record: this.record,
            side,
            diagnosisKey:
              this.DiagnosisService.convertDiagnosisOptionToKey(
                relatedDiagnosis
              ),
          });
          // this.undoAutofillDiagnosis(side, timestampKey, _prevObs?.type);
        }
      }
    }

    // CHECK WHICH SIDE TO AUTOFILL FOR
    // status case
    if (currentStatusHasAutofill) {
      // status has no index
      // create timestamp
      timestampKey = this.ValueAutofillService.setKeyTimestampByIndex({
        timestampArray: this.timestampKeyArrayStatus,
        side,
        index: 0,
      });

      // check if autofill else go to observations and find the first reference
      this.handleAutofillLens(
        "status",
        side,
        timestampKey,
        previousRecordData.lens,
        currentStatus,
        0
      );
    } else if (observationsWithAutofill.length) {
      // observation case
      for (const [
        index,
        observationStatus,
      ] of observationsWithAutofill.entries()) {
        // create timestamp
        timestampKey = this.ValueAutofillService.setKeyTimestampByIndex({
          timestampArray: this.timestampKeyArrayObservations,
          side,
          index,
        });

        this.handleAutofillLens(
          "observation",
          side,
          timestampKey,
          previousRecordData.lens,
          observationStatus.type,
          index
        );
      }
    }
  }

  // update changes
  setPreviousStatusToStack(
    previousLensData: GlLens,
    side: IGlSideBilateral,
    currentStatus: IGlOption
  ) {
    // go through all attributes
    for (const key of this.lensAttributes) {
      const lateralKey = `${key}.${side}`;

      // observations
      switch (key) {
        case "observations":
          if (isNil(this?.record)) {
            break;
          }

          const foundObservationIndex: number =
            this.record.lens[key][side]?.findIndex(
              (o) => o?.type?.key === currentStatus?.key
            ) ?? -1;

          // only apply if found
          if (foundObservationIndex >= 0) {
            this.PatientRecordService.updateChangesStack(
              `${this.PARENT_KEY}.${lateralKey}`,
              previousLensData[key][side]?.[foundObservationIndex]
            );
          } else {
            // otherwise default case
            this.PatientRecordService.updateChangesStack(
              `${this.PARENT_KEY}.${lateralKey}`,
              get(previousLensData, lateralKey)
            );
          }
          break;
        default:
          this.PatientRecordService.updateChangesStack(
            `${this.PARENT_KEY}.${lateralKey}`,
            get(previousLensData, lateralKey)
          );
      }
    }
  }

  // undo autofill diagnosis
  undoAutofillDiagnosis(
    side: IGlSideBilateral,
    timestampKey: number,
    observation: IGlOption
  ) {
    // side
    this.PatientRecordService.undoAutofillFromObservationSide({
      record: this.record,
      parent_key: "lens",
      timestamp_key: timestampKey,
      side,
      option: observation,
    });

    // we also need to check for any linked procedure autofills
    this.$timeout(100).then(() => {
      this.updateAutofillExternalProcedure(side, observation);
    });
  }

  // external procedure call
  updateAutofillExternalProcedure(
    side: IGlSideBilateral,
    observation: IGlOption
  ) {
    const diagnosis: GlDiagnosis =
      this.DiagnosisService.getDiagnosisAutofillMapping(observation.key);

    if (diagnosis) {
      this.$rootScope.$broadcast(
        PATIENT_DIAGNOSIS_UPDATE_PROCEDURE_EVENT,
        diagnosis,
        this.recordId
      );
    }
  }

  // accept autofill
  acceptAutofillDiagnosis(
    side: IGlSideBilateral,
    timestampKey: number,
    observation: IGlOption
  ) {
    // handles diagnosis side
    this.PatientRecordService.confirmAutofillDiagnosisFromObservation({
      // record: this.record,
      option: observation,
      parent_key: this.PARENT_KEY,
      side,
      timestamp_key: timestampKey,
    });
  }

  // is option autofill active?
  isOptionAutofillActive(
    side: IGlSideBilateral,
    option: IGlOption,
    lensKey: "status" | "observations" = "status",
    index?: number
  ) {
    if (isNil(option)) {
      return false;
    }

    // CHECK SPECIAL CASE
    // for lens we have to differentiate between status and obseravations
    switch (lensKey) {
      // if status check immediately
      case "status":
        const statusTimestamp: number =
          this.ValueAutofillService.getKeyTimestampFromIndex({
            timestampArray: this.timestampKeyArrayStatus,
            side,
            index: index ?? 0,
          });
        return !isNil(
          this.ValueAutofillService.getAutofillValueByKeyAndSide({
            parent_key: this.PARENT_KEY,
            option_key: option?.key,
            side,
            timestamp_key: statusTimestamp,
          })
        );

      // if observation check multiples
      case "observations":
        // check if this is the second one
        // otherwise check
        const observationTimestamp: number =
          this.ValueAutofillService.getKeyTimestampFromIndex({
            timestampArray: this.timestampKeyArrayObservations,
            side,
            index,
          });

        return !isNil(
          this.ValueAutofillService.getAutofillValueByKeyAndSide({
            parent_key: this.PARENT_KEY,
            option_key: option?.key,
            side,
            timestamp_key: observationTimestamp,
          })
        );

      // if not applicable just continue
      default:
        return false;
    }
  }

  // set form dirty
  formDidChange() {
    this.bilateralPosteriorLensForm.$setDirty();
  }

  // this clears all selections including rows etc
  /**
   * @ignore
   * ONLY USE IF WE ENABLE CLEAR FOR BILATERAL POSTERIOR LENS AGAIN
   */
  clearSelections() {
    // set to not examined
    const NOT_EXAMINED: IGlOption = {
      name: "Not Examined",
      key: "not-examined",
    };

    // get a copy of the old data first
    const oldLensData = cloneDeep(this.record.lens);

    const leftLens: any = angular.element(this.leftPosteriorLensRef)?.[0];
    const rightLens: any = angular.element(this.rightPosteriorLensRef)?.[0];

    // then apply a clear change
    set(this.record, "lens.status", {
      left: NOT_EXAMINED,
      right: NOT_EXAMINED,
    });
    leftLens?.lensDidChange(oldLensData);
    rightLens?.lensDidChange(oldLensData);
  }
}

export class BilateralPosteriorLens {
  static selector = "bilateralPosteriorLens";
  static template = require("./bilateral-posterior-lens.html");
  static controller = BilateralPosteriorLensController;
  static bindings = {
    enable: "<",
    record: "<",
    enableRight: "<",
    enableLeft: "<",
    isEditable: "<?",
    ...GlFormControllerBindings,
  };
}
