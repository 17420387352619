export class SegmentLensStatus implements angular.IComponentOptions {
  static selector = "segmentLensStatus";
  static template = require("./segment-lens-status.html");
  static bindings = {
    mode: "@?",
    isEditable: "<?",
    record: "<",
    enableLeft: "<",
    enableRight: "<"
  };
}
