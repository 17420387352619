import { Provider } from "../../../models/provider.model";
import { API_PATH } from "./api-paths";

export interface IProviderResourceClass<T>
  extends angular.resource.IResourceClass<T> {
  getByType: angular.resource.IResourceMethod<T>;
}

export class ProviderService {
  static injectionName = "ProviderService";
  public ngResource: IProviderResourceClass<
    angular.resource.IResource<Provider>
  >;
  private cache: angular.ICacheObject;
  private path = `${this.API_URL}${API_PATH}/providers`;

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $resource: angular.resource.IResourceService
  ) {
    "ngInject";
    this.ngResource = this.$resource(this.path, undefined, {
      getByType: {
        url: `${this.path}/:type`,
        isArray: true,
        method: "GET",
      },
    }) as IProviderResourceClass<angular.resource.IResource<Provider>>;
    this.cache = this.$cacheFactory.get("$http");
  }

  /**
   * Due to a quirk in the server, this returns an array of 1 item
   * @param id
   */
  get(id: number) {
    return this.ngResource.query({ id });
  }

  getByType(type: "optometrist" | "gp") {
    return this.ngResource.getByType({ type });
  }
}
