import { AuthService } from "app/core/services/auth.service";
import { ClinicService } from "app/core/services/clinic.service";
import { User, UserAuxiliaryClinic } from "models/user.model";

class SettingsUserClinicPageController
  implements angular.IController, angular.IOnInit {
  // Input
  user: User;

  // Properties
  updateInProgress = false;
  editProviderNumberForm: angular.IFormController;
  clinicId = this.$stateParams.id as number;
  userAuxiliaryClinicDetails: UserAuxiliaryClinic;

  constructor(
    private $stateParams: angular.ui.IStateParamsService,
    private AuthService: AuthService,
    private ClinicService: ClinicService,
    private toastr: angular.toastr.IToastrService,
    public $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  $onInit() {
    //
    this.userAuxiliaryClinicDetails = this.user.auxiliary_clinics.find(
      clinic => clinic.id === this.clinicId
    );
  }

  saveProfile() {
    this.updateInProgress = true;
    return this.ClinicService.updateUserClinicDetails(
      this.user,
      this.userAuxiliaryClinicDetails,
      {
        provider_number: this.userAuxiliaryClinicDetails.pivot.data
          .provider_number,
        prescription_address: this.userAuxiliaryClinicDetails.pivot.data
          .prescription_address,
      }
    )
      .then(() => this.AuthService.getUser())
      .then(user => {
        this.user = user;
        this.editProviderNumberForm.$setPristine();
      })
      .catch(error => {
        console.error({ error });
        this.toastr.error("An error occurred.  Please try again");
      })
      .finally(() => (this.updateInProgress = false));
  }
}

export class SettingsUserClinicPageComponent
  implements angular.IComponentOptions {
  static selector = "glSettingsUserClinicPage";
  static template = require("./user-clinic.html");
  static controller = SettingsUserClinicPageController;
  static bindings = {
    user: "<",
  };
}
