import { Patient, User } from "models/user.model";
import { PatientRecordService } from "../../../app/core/services/patient-record/patient-record.service";

export class SettingsAdministrativePageController
  implements angular.IController
{
  user: User;
  mergeInProgress: boolean;
  patientMergeForm: angular.IFormController;

  // PATIENT STUFF
  oldPatient: Patient;
  currentPatient: Patient;

  constructor(
    private toastr: angular.toastr.IToastrService,
    private PatientRecordService: PatientRecordService
  ) {
    "ngInject";
  }
}

export class SettingsAdministrativePageComponent
  implements angular.IComponentOptions
{
  static selector = "glSettingsAdministrativePage";
  static template = require("./settings-administrative.html");
  static controller = SettingsAdministrativePageController;
  static bindings = {
    user: "<",
  };
}
