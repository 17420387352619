import { isString } from "angular";
import { get, sortBy } from "lodash";
import { PatientRecord } from "../../../../../models/patient-record.model";

import { User } from "models/user.model";
import "./patient-notes.scss";

interface INote {
  author: string;
  note: string;
  date: string;
  virtualReviewNote?: INote;
  dilationReviewNote?: INote;
}

const MANAGEMENT_COMMENT_KEY = "data.management.comments";
class PatientNotesController
  implements angular.IController, angular.IOnChanges
{
  records: PatientRecord[];
  notes: INote[];
  glUserFullNameFilter = this.$filter<(user: User) => string>("glUserFullName");

  constructor(private $filter: angular.IFilterService) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.records && this.records) {
      this.notes = this.extractNotesFromRecords();
    }
  }

  extractNotesFromRecords() {
    let notes: INote[] = [];

    notes = this.records.reduce((notesAcc: INote[], r: PatientRecord) => {
      const recordNote = get(r, MANAGEMENT_COMMENT_KEY);
      const author = this.glUserFullNameFilter(
        r.data_signed_by ?? r.created_by
      );
      const date = r.updated_at;
      if (isString(recordNote)) {
        const noteForDisplay: INote = {
          date,
          author,
          note: recordNote,
        };

        // test for virtual review
        const virtualReview = r.virtual_review;
        const virtualReviewNote = get(virtualReview, MANAGEMENT_COMMENT_KEY);

        // dilation review also
        const dilationReview = r.dilation_review;
        const dilationReviewNote = get(dilationReview, MANAGEMENT_COMMENT_KEY);

        // test
        if (virtualReview && virtualReviewNote) {
          noteForDisplay.virtualReviewNote = {
            date: virtualReview.updated_at,
            author:
              virtualReview.data_signed_by_id &&
              this.glUserFullNameFilter(virtualReview.data_signed_by),
            note: virtualReviewNote,
          };
        } else if (dilationReview && dilationReviewNote) {
          noteForDisplay.dilationReviewNote = {
            date: dilationReview.updated_at,
            author:
              dilationReview.data_signed_by_id &&
              this.glUserFullNameFilter(dilationReview.data_signed_by),
            note: dilationReviewNote,
          };
        }
        notesAcc.push(noteForDisplay);
      } else if (Array.isArray(recordNote)) {
        recordNote.forEach((n) =>
          notesAcc.push({
            note: n.comment,
            author: n.user.name,
            date: n.timestamp,
          })
        );
      }

      return notesAcc;
    }, []);
    return sortBy(notes, (n) => new Date(n.date)).reverse();
  }
}

export class PatientNotesComponent implements angular.IComponentOptions {
  static selector = "patientNotes";
  static template = require("./patient-notes.html");
  static controller = PatientNotesController;
  static bindings = {
    records: "<",
  };
}
