import { first } from "lodash";
import { Clinic } from "../../../models/clinic.model";
import { IGlApiResponse } from "../../../models/gl-api-response.model";
import { PatientClinic } from "../../../models/patient-clinic.model";
import { User } from "../../../models/user.model";
import { API_PATH_v2 } from "./api-paths";

export class PatientClinicService {
  static injectionName = "PatientClinicService";
  apiBase = `${this.API_URL}${API_PATH_v2}`;

  patientClinics: PatientClinic[];
  managingOptom: PatientClinic;
  patientId: number;

  constructor(private API_URL: string, private $http: angular.IHttpService) {
    "ngInject";
  }

  /**
   *
   * Service Methods to get the patientClinics for a specific user
   */

  getManagingOptom() {
    if (this.patientClinics) {
      return first(
        this.patientClinics.filter(
          (p) => p.provider?.type.name === "optometrist"
        )
      );
    } else {
      return;
    }
  }

  getManagingOphthal() {
    if (this.patientClinics) {
      return first(
        this.patientClinics.filter(
          (p) => p?.provider?.type?.name === "ophthalmologist"
        )
      );
    } else {
      return;
    }
  }

  initForPatient({
    patientId,
    patient,
  }: {
    patientId?: number;
    patient?: User;
  }) {
    this.patientId = patientId || patient.id;
    return this.fetchClinicsForPatient();
  }
  clear() {
    this.patientClinics = [];
    this.patientId = null;
  }

  fetchClinicsForPatient() {
    return this.get(this.patientId).then((result) => {
      this.patientClinics = result;
      this.managingOptom = this.patientClinics.find(
        (c) => c.provider?.type.name === "optometrist"
      );
    });
  }

  updateForPatient(clinic: Clinic, provider: User) {
    return this.update(this.patientId, clinic, provider);
  }

  deleteForPatient(clinic: Clinic) {
    return this.delete(this.patientId, clinic);
  }

  /**
   *
   * API Methods
   */

  get(patientId: number) {
    return this.$http
      .get<IGlApiResponse<PatientClinic[]>>(this.getPath(patientId))
      .then((response) => response.data.data);
  }

  update(patientId: number, clinic: Clinic, provider: User) {
    return this.$http
      .put<IGlApiResponse<PatientClinic>>(this.getPath(patientId, clinic), {
        provider_id: provider.id,
      })
      .then((response) => response.data);
  }

  delete(patientId: number, clinic: Clinic) {
    return this.$http.delete(this.getPath(patientId, clinic));
  }

  getPath(patientId: number, clinic?: Clinic) {
    if (clinic) {
      return `${this.apiBase}/patients/${patientId}/clinic/${clinic.id}`;
    } else {
      return `${this.apiBase}/patients/${patientId}/clinic`;
    }
  }
}
