import { IAttributes, IController, IScope, isNumber, isString } from "angular";

/**
 * Fixes annoying issue where focusing on typeahead doesnt even trigger it
 */
export class TypeaheadFocus implements angular.IDirective<angular.IScope> {
  static selector = "typeaheadFocus";
  require = "ngModel";
  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new TypeaheadFocus();
    };
    return directive;
  }

  link(
    scope: IScope,
    element: JQLite,
    attrs: IAttributes,
    ngModel: IController
  ) {
    //trigger the popup on 'click' because 'focus'
    //is also triggered after the item selection
    const force_focus = () => {
      const viewValue =
        // has to be a string of certain length
        (isString(ngModel.$viewValue) && ngModel.$viewValue?.trim()?.length) ||
        // or a number
        isNumber(ngModel.$viewValue)
          ? String(ngModel.$viewValue)
          : "";

      //restore to null value so that the typeahead can detect a change
      if (ngModel?.$viewValue?.length) {
        ngModel.$setViewValue(null);
      }

      //force trigger the popup
      ngModel.$setViewValue(" ");

      //set the actual value in case there was already a value in the input
      ngModel.$setViewValue(viewValue || " ");
    };

    // can be triggered in two ways
    // more stable
    element.on("click", force_focus);

    // this is more unreliable ish but can be re-activated
    // element.on("focus", force_focus);

    // //compare function that treats the empty space as a match
    // scope.emptyOrMatch = (actual: string, expected: string) => {
    //   if (expected === " ") {
    //     return true;
    //   }
    //   return actual.indexOf(expected) > -1;
    // };
  }
}
