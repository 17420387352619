export class GlRow implements angular.IComponentOptions {
  static selector = "glRow";
  static transclude = {
    left: "glLeft",
    title: "glTitle",
    right: "glRight"
  };
  static template =
    '<div class="row m-b-sm">' +
    '<div class="col-xs-4 text-center" ng-transclude="right"></div>' +
    '<div class="col-xs-4 text-center" ng-transclude="title"></div>' +
    '<div class="col-xs-4 text-center" ng-transclude="left"></div>' +
    "</div>";
}
