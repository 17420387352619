import { ClinicService } from "app/core/services/clinic.service";
import { GlModelService } from "app/core/services/gl-model.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { PatientService } from "app/core/services/patient.service";
import { PatientContactService } from "app/core/services/patient-contact.service";
import { Clinic } from "models/clinic.model";
import { Contact } from "models/contact.model";
import { GlPatientProviders } from "models/patient-record.model";
import { Patient, User } from "models/user.model";
import { ContactService } from "app/core/services/contact.service";

class ContactMigrationController
  implements angular.IController, angular.IOnInit
{
  user: User;
  currentClinic: Clinic;
  allPatients: Patient[];
  providers: GlPatientProviders[];
  savePatientProviderInProgress: boolean;
  newContact: Contact;
  currentPatientMigrating: Patient;
  totalPatientsForClinic: number;
  totalPatientsMigrated: number = 0;

  constructor(
    public toastr: angular.toastr.IToastrService,
    private $q: angular.IQService,
    private $window: angular.IWindowService,
    private PatientRecordService: PatientRecordService,
    private ContactService: ContactService,
    private PatientService: PatientService,
    private PatientContactService: PatientContactService,
    private ClinicService: ClinicService,
    private GlModelService: GlModelService
  ) {
    "ngInject";
  }

  $onInit() {
    return this.getAllPatients();
  }

  convertProviderToContact(
    providerType: any,
    patientId?: number,
    type?: string,
    referrer?: boolean
  ) {
    this.savePatientProviderInProgress = true;
    return this.$q
      .resolve()
      .then(() => {
        this.createOrSavePatientContact(
          providerType,
          type,
          patientId,
          referrer
        );
      })
      .catch((error) => {
        this.toastr.error(error);
      })
      .finally(() => (this.savePatientProviderInProgress = false));
  }

  createOrSavePatientContact(
    contact: any,
    type?: string,
    patientId?: number,
    referrer?: boolean
  ) {
    this.newContact = {
      clinic_name: "",
      first_name: "",
      email: "",
      last_name: "",
      address: "",
      phone: "",
      fax: "",
    };
    this.newContact.clinic_name = contact?.clinicName;
    this.newContact.first_name = contact?.firstName;
    this.newContact.last_name = contact?.lastName;
    this.newContact.email = contact?.email;
    this.newContact.address = contact?.address;
    this.newContact.phone = contact?.phone;
    this.newContact.fax = contact?.fax;
    this.ContactService.createContact(this.newContact)
      .then((c) => {
        this.PatientContactService.update(patientId, c, type, referrer).catch(
          (error) => {
            this.$window.localStorage.setItem(
              "Patient contact association creation error:",
              error
            );
          }
        );
      })
      .catch((error) => {
        this.$window.localStorage.setItem("Contact create error:", error);
      });
  }

  getAllPatients() {
    return this.PatientService.getAllPatients()
      .then((patients) => {
        this.allPatients = patients as Patient[];
        this.totalPatientsForClinic = this.allPatients.length;
      })
      .then(() => {
        this.ClinicService.getClinicDetails(this.user.clinic_id).then((c) => {
          this.currentClinic = c;
        });
      });
  }

  async migratePatientProviderToContacts() {
    for (const patient of this.allPatients) {
      this.currentPatientMigrating = patient;
      this.totalPatientsMigrated += 1;
      await this.PatientRecordService.getRecordHistoryForUser(patient.id)
        .then((records) => {
          const consolidatedProviderFromRecords =
            this.GlModelService.getConsolidatedRecordFromHistory(
              records
            ).providers;

          if (consolidatedProviderFromRecords) {
            if (consolidatedProviderFromRecords.referrer) {
              // prevent legacy code from breaking
              // eslint-disable-next-line
              var currentPatientReferrer =
                consolidatedProviderFromRecords.referrer;
            }
            return this.convertProviderToContact(
              consolidatedProviderFromRecords.gp,
              patient.id,
              "GP",
              currentPatientReferrer === "gp" ? true : false
            )
              .catch((error) => {
                this.$window.localStorage.setItem(
                  `Patient ID: ${patient.name} ${patient.id}: `,
                  error
                );
              })
              .then(() => {
                return this.convertProviderToContact(
                  consolidatedProviderFromRecords.optometrist,
                  patient.id,
                  "Optometrist",
                  currentPatientReferrer === "optometrist" ? true : false
                );
              })
              .catch((error) => {
                this.$window.localStorage.setItem(
                  `Patient ID: ${patient.name} ${patient.id}: `,
                  error
                );
              })
              .then(() => {
                if (consolidatedProviderFromRecords.referrerDetails) {
                  return this.convertProviderToContact(
                    consolidatedProviderFromRecords.referrerDetails,
                    patient.id,
                    "Other",
                    true
                  ).catch((error) => {
                    this.$window.localStorage.setItem(
                      `Patient ID: ${patient.name} ${patient.id}: `,
                      error
                    );
                  });
                }
              });
          }
        })
        .then(() => {
          this.totalPatientsForClinic -= 1;
        })
        .catch((error) => {
          this.$window.localStorage.setItem(
            `Patient ID: ${patient.name} ${patient.id}: `,
            error
          );
        });
    }
  }
}

export class ContactMigrationPage implements angular.IComponentOptions {
  static selector = "contactMigration";
  static template = require("./contact-migration.html");
  static controller = ContactMigrationController;
  static bindings = {
    user: "<",
  };
}
