import moment = require("moment");

export const pipeName = "relativeDate";
export function relativeDatePipe() {
  "ngInject";
  return (dateStr: string) => {
    try {
      // force not american date
      return moment(dateStr).calendar(null, {
        sameElse: "DD/MM/YYYY",
      });
    } catch {
      return;
    }
  };
}
