/**
 * This is the basic functionality of many of the glauconet controllers. This
 * base class can be extended and allows these utility functions to be reused.
 */

import { IGlFormMode } from "models/gl-form-mode";

export class GlFormController {
  // if set to true, the form can be toggled between display and edit mode
  // without affecting the data if set to false, then the edit form will be
  // removed, triggering gl-model to remove any of the underlying data fields
  protected isEditable = false;
  protected mode: IGlFormMode;
  protected glRequired: string[];

  public isDisplayMode() {
    return (
      (!this.isEditable && this.mode !== "json") ||
      (this.isEditable && !["edit", "json", "create"].includes(this.mode))
    );
  }

  public isCreateMode() {
    return this.mode === "create";
  }

  public isEditMode() {
    return this.mode === "edit";
  }

  public isJsonMode() {
    return this.mode === "json";
  }

  isRequired(fieldName: string, defaultVal: boolean = false) {
    return Array.isArray(this.glRequired)
      ? this.glRequired.includes(fieldName)
      : defaultVal;
  }
}

export const GlFormControllerBindings = {
  isEditable: "<?",
  mode: "@",
  glRequired: "<?",
};
