import { InjectionHelperService } from "app/core/services/injection-helper/injection-helper.service";
import { PatientProcedureInjection } from "models/patient-procedure";

export const pipeName = "injectionsTotal";
export function pipe(InjectionHelperService: InjectionHelperService) {
  "ngInject";
  return (injection: PatientProcedureInjection) => {
    if (!injection) {
      return;
    }
    return InjectionHelperService.getInjectionCountForRecord(injection);
  };
}
