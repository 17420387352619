import * as moment from "moment";

export class Moment {
  static selector = "moment";

  static factory() {
    return moment;
  }

  constructor() {
    "ngInject";
  }
}
