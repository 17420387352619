export function sortDatesDesc(d1: Date, d2: Date) {
  let date1: Date;
  if (d1) {
    date1 = new Date(d1);
  } else {
    date1 = new Date(d1);
  }

  let date2: Date;
  if (d2) {
    date2 = new Date(d2);
  } else {
    date2 = new Date(d2);
  }
  return date2.getTime() - date1.getTime();
}

export function sortDatesAsc(d1: Date, d2: Date) {
  let date1: Date;
  if (d1) {
    date1 = new Date(d1);
  } else {
    date1 = new Date(d1);
  }

  let date2: Date;
  if (d2) {
    date2 = new Date(d2);
  } else {
    date2 = new Date(d2);
  }
  return date1.getTime() - date2.getTime();
}
