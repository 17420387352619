export const pipeName = "routineComplication";
export const routineComplication: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (routine: boolean, complete: boolean) => {
    // if not complete dont bother with it
    if (!complete) {
      return "";
    }
    // otherwise return depending on routine
    return routine ? "Uncomplicated" : "Complicated";
  };
};
