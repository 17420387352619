// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { AdminInjectionSummaryComponent } from "./components/admin-injection-summary/admin-injection-summary.component";
import { CopyButtonNew } from "./components/button-copy-new/button-copy-new";
import { InjectionFormComponent } from "./components/injection-form/injection-form.component";
import { InjectionsPage } from "./injections";
import { pipeName, weeksAgoPipe } from "./pipes/weeks-ago.pipe";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.injections", {
    url: "/patient/{patientId:int}/injections/{recordId:int}?{debug:bool}",
    component: InjectionsPage.selector,
    data: {
      requiresLogin: true,
    },
    params: {
      // This makes the record_id optional
      recordId: {
        squash: true,
        value: null,
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.injections", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(
      AdminInjectionSummaryComponent.selector,
      AdminInjectionSummaryComponent
    )
    .component(CopyButtonNew.selector, CopyButtonNew)
    .component(InjectionFormComponent.selector, InjectionFormComponent)
    .component(InjectionsPage.selector, InjectionsPage)
    .filter(pipeName, weeksAgoPipe)
    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
