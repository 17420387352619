import { AuthService } from "../../../services/auth.service";
import { User } from "../../../../../models/user.model";

import "./navbar.component.scss";

class NavbarController {
  public user: User;
  constructor(
    public AuthService: AuthService,
    private $state: angular.ui.IStateService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onInit() {
    //
  }

  logOut() {
    this.AuthService.logOut();
    this.$state.go("login");
    this.toastr.success("You have been logged out", "Log Out");
  }
}

export class GlNavBar implements angular.IComponentOptions {
  static selector = "glNavBar";
  static template = require("./navbar.component.html");
  static controller = NavbarController;
  static bindings = {
    user: "<",
  };
}
