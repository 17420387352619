import {
  PatientProcedure,
  PatientProcedureDrop,
  PatientProcedureDrug,
} from "models/patient-procedure";

export const pipeName = "prescriptionDrugsNames";
export const prescriptionDrugsNames: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (
    drugs: PatientProcedureDrug[] | PatientProcedureDrop[] | PatientProcedure[]
  ) => {
    if (drugs.length) {
      return drugs
        .map((drug) => {
          // drug name then check if authority or not
          return `${drug.brand_name ?? drug.name}${
            drug.authority_script && !drug?.private ? " (A)" : ""
          }`;
        })
        .join(", ");
    }
    return "No drugs found";
  };
};
