import { isUndefined } from "angular";
import {
  cloneDeep,
  isNaN,
  isNull,
  isObject,
  values,
  isEmpty as lodashIsEmpty,
  isArray,
} from "lodash";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { GlModelService } from "../services/gl-model.service";

export enum GlModelDefaultMode {
  default = "default",
  none = "none",
  always = "always",
  /**
   * When the defaultMode is set to onFocus, no matter which user is logged in,
   * the defaults will only be set when the control is focused
   */
  onFocus = "onFocus",
}

class GlModelController implements angular.IOnDestroy, angular.IPostLink {
  ngModelCtrl: angular.INgModelController;
  path: string = "";
  defaultMode = GlModelDefaultMode.default;
  key: string;
  /**
   * This input can be used to manually set a dynamic default for this field. If
   * this is set, then the default value will be set to the value. This can be
   * used for setting defaults from DICOM data
   */
  default: any;
  ngModel: any;

  constructor(
    private $element: JQLite,
    private AuthService: AuthService,
    private DataService: DataService,
    private GlModelService: GlModelService
  ) {
    "ngInject";
  }

  $postLink() {
    this.$element.on("focus", () => this.onFocus());
    if (
      this.defaultMode !== GlModelDefaultMode.none &&
      this.GlModelService.glModelDefaultsIsEnabled &&
      this.shouldLoadDefaultsOnInit()
    ) {
      this.setDefaults();
    }
  }

  $onDestroy() {
    this.ngModelCtrl.$setViewValue(undefined, "glModelOnDestroy");
  }

  onFocus() {
    this.setDefaults();
  }

  isEmpty(value: any) {
    return (
      isUndefined(value) ||
      isNull(value) ||
      isNaN(value) ||
      // arrays case and check values as well
      (isArray(value) &&
        (lodashIsEmpty(value) ||
          values(value).some((v) => {
            return this.isEmpty(v);
          }))) ||
      // object edge case (arrays are also objects so we have to check)
      (isObject(value) &&
        !isArray(value) &&
        values(value).some((v) => {
          return this.isEmpty(v);
        }))
    );
  }

  setDefaults() {
    // Only set defaults if we aren't overwriting the original ngModel value
    // We can only set defaults if there is a key or path to get defaults for
    // DEBUGGING
    // const str = "refraction.sphere";
    // if ((this.key || "").includes(str) || (this.path || "").includes(str)) {
    //   console.log(this.path, this.isEmpty(this.ngModel), this.ngModel);
    // }

    if (this.isEmpty(this.ngModel) && (this.key || this.path)) {
      const consolidatedValue = this.GlModelService.getRecordConsolidatedValue(
        this.path
      );

      if (!this.isEmpty(this.default)) {
        /**
         * If the field has a "default input" that is not empty, set the
         * ng-model to the default value input This is used for some fields such
         * as Field MD/PSD where the value is set from the current visual field
         * dicom data
         */
        this.ngModelCtrl.$setViewValue(cloneDeep(this.default), "glModel");
        this.ngModelCtrl.$render();
      } else if (!this.isEmpty(consolidatedValue)) {
        /**
         * Else find the most recent record with this value and set the value of
         * the field to this value if it exists
         */
        this.ngModelCtrl.$setViewValue(cloneDeep(consolidatedValue), "glModel");
        this.ngModelCtrl.$render();
      } else {
        /**
         * Otherwise, look up the default value from the appendix.ts for this
         * field. If it exists, then set the value of the field to this value
         * and add the class to indicate the value has been set to a pre-set
         * from appendix.ts
         */
        const defaultsFromDataService = this.DataService.getDefaultForPath(
          this.path,
          this.key
        );
        // // useful gl-model debugging - commented out as only required in debugging
        // // refer to top of function for str
        // if ((this.key || "").includes(str) || (this.path || "").includes(str)) {
        //   console.log({
        //     str,
        //     defaults: defaultsFromDataService,
        //     path: this.path,
        //     key: this.key,
        //   });
        // }
        if (!this.isEmpty(defaultsFromDataService)) {
          this.$element.addClass("gl-model-default-data");

          this.ngModelCtrl.$setViewValue(
            cloneDeep(defaultsFromDataService),
            "glModel"
          );
          // It is necessary to call $render after $setViewValue for inputs
          // to update correctly
          // https://stackoverflow.com/questions/26145161/setviewvalue-in-directive-on-input-not-updating-actual-visible-input-value
          this.ngModelCtrl.$render();
        }
      }
    }
  }

  private shouldLoadDefaultsOnInit() {
    return (
      this.defaultMode !== GlModelDefaultMode.onFocus &&
      (this.AuthService.userIs("ophthalmologist") ||
        this.AuthService.userIs("optometrist") ||
        this.defaultMode === GlModelDefaultMode.always)
    );
  }
}

export class GlModel implements angular.IDirective<angular.IScope> {
  static selector = "glModel";
  static controller = GlModelController;

  scope = {
    path: "@",
    defaultMode: "@",
    key: "@",
    default: "<?",
    ngModel: "<",
  };
  require = {
    ngModelCtrl: "ngModel",
  };
  controller = GlModelController;
  bindToController = true;

  constructor() {
    "ngInject";
  }

  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new GlModel();
    };
    return directive;
  }
}
