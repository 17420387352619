// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { moduleName as SettingsPageModule } from "../main.settings/settings.module";
import { GlValidateSignatureDirective } from "./directives/gl-validate-signature";
import { SettingsProfilePageComponent } from "./settings-profile";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.settings.profile", {
    url: "/settings/profile",
    component: SettingsProfilePageComponent.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.settings.profile", [
      SettingsPageModule,
      "ui.router",
      "gl.core",
    ])

    /**
     * Register Module Components
     */
    .component(
      SettingsProfilePageComponent.selector,
      SettingsProfilePageComponent
    )

    /**
     * Register Module Directive
     */
    .directive(
      GlValidateSignatureDirective.selector,
      GlValidateSignatureDirective.factory()
    )
    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
