import { isFunction } from "angular";
import { PatientDocumentService } from "app/core/services/patient-document.service";
import { cloneDeep, get, isEmpty, isNil, set } from "lodash";
import { User } from "models/user.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import {
  ExtraDetails,
  PatientDocument,
  ReferralDetails,
} from "../../../../../models/patient-document.model";
import { PatientRecordData } from "../../../../../models/patient-record.model";
import {
  Appendix,
  DOCUMENT_TAGS_V2,
  IGlOption,
} from "../../../../core/services/appendix";
import { IPatientResource } from "../../../../core/services/patient.service";
import { GlFormController } from "../../gl-form-controller";
import { GlDropzoneFileWrapper } from "../gl-dropzone/gl-dropzone";
import "./patient-uploads.scss";
import angular = require("angular");

export interface IGlDocToggleOptions {
  openedDocumentDatePicker: boolean;
  openedEditMode: boolean;
  openedReferralDatePicker: boolean;
  openedReferralExpiryDatePicker: boolean;
  activeReferral: boolean | null;
  referralName: string | null;
  referralProvderNumber: string | null;
  referralDate: Date | null;
  referralTimeFrame: IGlOption | null;
  referralExpiryDate?: Date | null;
}

export class PatientUploadsController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // @Input
  patientId: number;
  patientDocuments: PatientDocument[];
  record: PatientRecordData;
  patient: IPatientResource;
  managingOptom: PatientClinic;
  managingOphthal: PatientClinic;
  deleteInProgress = false;
  user: User;

  referralValidTimeFrames = this.appendix.get("referralValidTimeFrames");
  documentTagsV2 = DOCUMENT_TAGS_V2.documents.filter((d) => !d.legacy);
  documentTagsV2Types = Array.from(
    new Set(DOCUMENT_TAGS_V2.documents.map((d) => d.type))
  );

  docEditMode: number;

  // @outputs
  onDocumentUpload: () => void;

  // main strucutre that is used to display
  // all the uploaded documents for edit
  // different to the FileObjectWrapper and will need
  // to be converted after upload
  docToggleOptions: {
    [key: number]: IGlDocToggleOptions;
  } = [];

  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  // generic document related
  selectedTag: any;
  freeText: string;
  extraDetails: ExtraDetails;

  setAsReferral = false;
  referralDetails: ReferralDetails;
  referralName: string;
  referralProvderNumber: string;
  referralDate: Date;
  referralTimeFrame: IGlOption; // how long until expired
  openedReferralDatePicker = false;

  dropzoneRef: JQLite;

  // model that we use to make changes to the original file
  queuedFileToEdit: GlDropzoneFileWrapper;
  uploadFilesInProgress: boolean = false;

  // error checkers
  allFilesTagged: boolean = false;

  constructor(
    public appendix: Appendix,
    private $timeout: angular.ITimeoutService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $window: angular.IWindowService,
    private PatientDocumentService: PatientDocumentService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    //set setAsReferral default to true if no active referrals
    if (changes.patientDocuments && this.patientDocuments) {
      /*
        everytime documents are updated, map it out based on 
        the docToggleOptions interface above
      */
      this.patientDocuments.map((d) => {
        this.docToggleOptions[d.id] = {
          openedDocumentDatePicker: false,
          openedEditMode: false,
          openedReferralDatePicker: false,
          openedReferralExpiryDatePicker: false,
          activeReferral: d.data.tags
            ? d.data.tags.split(",").some((t) => t.toLowerCase() === "referral")
            : false,
          referralName: d.data.referral_details?.referral_name,
          referralProvderNumber: d.data.referral_details?.provider_number,
          referralDate: d.data.referral_details?.referral_date
            ? new Date(d.data.referral_details?.referral_date)
            : null,
          referralTimeFrame: d.data.referral_details?.referral_time_frame,
          // referralExpiryDate: d.data.referral_details?.expiry_date
          //   ? new Date(d.data.referral_details?.expiry_date)
          //   : null,
        };
      });

      // if there arent any referral tags in any of the documents
      // set to true
      if (
        !this.patientDocuments
          .map((d) => d.data.tags)
          .find((d) =>
            d?.split(",").find((t) => t.toLowerCase() === "referral")
          )
      ) {
        this.setAsReferral = true;
      }
    }
  }

  documentChanged() {
    if (isFunction(this.onDocumentUpload)) {
      this.onDocumentUpload();
    }
    this.resetState();
  }

  resetState() {
    this.selectedTag = null;
    this.setAsReferral = false;
    this.freeText = "";
    this.extraDetails = null;
    this.queuedFileToEdit = undefined;
  }

  getTagsAsArray(tags: string) {
    return tags?.split(",");
  }

  /* REFERRAL CHECK */
  // documents have referral
  documentsHasReferral() {
    return this.PatientDocumentService.getDocumentReferralsUrl(
      this.patientDocuments
    );
  }

  /* DUPLICATE CHECKERS */
  selectedTagHasReferralDuplicate(): boolean {
    // if dupe detected, get prompt response
    return (
      this.documentsHasReferral() > -1 &&
      (this.selectedTag.name === "referral" ||
        (this.freeText === "referral" &&
          this.selectedTag.type === "Correspondence"))
    );
  }

  // has referrral?
  freeTextHasReferralDuplicate() {
    // get prompt respomse
    return this.freeText.toLowerCase().includes("referral");
  }

  /* DUPLCIATE REFERRAL HANDLER FLOW */
  handleDuplicateReferralThenSave() {
    // handle the setting of the dupe file
    return this.setExistingReferralsToOld()
      .then(() => {
        // save the file to edit
        return this.saveQueuedFileToEdit();
      })
      .catch((err: any) => {
        console.error("dupe referral save", err);
        this.toastr.error(
          "An error occured whilst replacing the existing referral with the new one, please try again"
        );
      });
  }

  getDuplicateReferralConfirmation(): boolean {
    return this.$window.confirm(
      "Patient already has a referral. Queuing another referral will mark all current referrals as old, if you change your mind you will have to reset the tags manually. Do you want to continue?"
    );
  }

  // returns a chain promise that focuses on documents that need to be updated
  setExistingReferralsToOld() {
    // get documents is a referral and no old referral tag and
    // we are uploading a generic document
    const documentsToUse: PatientDocument[] = this.patientDocuments.filter(
      (pd: PatientDocument) =>
        pd.data?.tags?.toLowerCase().includes("referral") &&
        !pd.data?.tags?.toLowerCase().includes("referral-old") &&
        pd.data.field === "generic"
    );

    // return a promise chain
    return Promise.all(
      documentsToUse.map((pd: PatientDocument) => {
        // replace all those tags
        const tags = pd.data.tags.replace("referral", "referral-old");
        return this.PatientDocumentService.updateReferralTag(pd, tags)
          .then(() => {
            if (isFunction(this.onDocumentUpload)) {
              this.onDocumentUpload();
            }
          })
          .then(() => {
            // set only if the other tag is selected
            // which activates freeText
            if (this.selectedTag?.name === "other") {
              this.selectedTag = {
                type: "Other",
                name: "other",
                title: "Other",
              };
            }
          });
      })
    );
  }

  docNameForDisplay(name: string) {
    if (name.length > 50) {
      return name.slice(0, 50) + "...";
    } else {
      return name;
    }
  }

  toggleReviewedStatus(doc: PatientDocument) {
    try {
      let reviewMessageConfirm =
        "Are you sure you want to mark this result as reviewed?";
      const newReviewStatus = {
        user: this.user.name,
        date: new Date().toISOString(),
        status: "",
      };

      if (
        doc.data.review_details &&
        doc.data.review_details.status === "REVIEWED"
      ) {
        reviewMessageConfirm = `Are you sure you want to remove this reviewed by status (Reviewed by ${
          doc.data.review_details.user
        } ${new Date(doc.data.review_details.date).toLocaleDateString()})`;
        newReviewStatus.status = "NEEDS_REVIEW";
      } else {
        newReviewStatus.status = "REVIEWED";
      }

      const toggleReviewedStatus = this.$window.confirm(reviewMessageConfirm);

      if (toggleReviewedStatus) {
        this.PatientDocumentService.updateDocumentReviewDetails(
          doc,
          newReviewStatus
        ).then(() => {
          if (isFunction(this.onDocumentUpload)) {
            this.onDocumentUpload();
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleDocDatePicker(
    doc: PatientDocument,
    pickerType: string,
    closeAll: boolean = false
  ) {
    for (const key of Object.keys(this.docToggleOptions)) {
      this.docToggleOptions[key] = {
        ...this.docToggleOptions[key],
        [pickerType]: false,
      };
    }

    if (!closeAll) {
      this.docToggleOptions[doc.id] = {
        ...this.docToggleOptions[doc.id],
        [pickerType]: true,
      };
    }
  }

  changeDocDate(date: Date, doc: PatientDocument) {
    try {
      this.toggleDocDatePicker(doc, "openedDocumentDatePicker");
      this.PatientDocumentService.updateDocumentDate(
        doc,
        date.toISOString()
      ).then(() => {
        if (isFunction(this.onDocumentUpload)) {
          this.onDocumentUpload();
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  saveReferralChanges(doc: PatientDocument) {
    try {
      const currentReferralStatus = doc.data.tags
        .split(",")
        .some((t) => t.toLowerCase() === "referral");
      if (
        (!currentReferralStatus &&
          this.docToggleOptions[doc.id].activeReferral) ||
        (currentReferralStatus && !this.docToggleOptions[doc.id].activeReferral)
      ) {
        this.toggleActiveReferral(
          doc,
          this.docToggleOptions[doc.id].activeReferral
        );
      }

      // this.toggleDocDatePicker(doc);
      this.PatientDocumentService.updateReferralDetails(doc, {
        referral_name: this.docToggleOptions[doc.id].referralName,
        provider_number: this.docToggleOptions[doc.id].referralProvderNumber,
        referral_date:
          this.docToggleOptions[doc.id].referralDate?.toISOString(),
        referral_time_frame: this.docToggleOptions[doc.id].referralTimeFrame,
        user: this.user.name,
        creation_update_date: new Date(),
        // expiry_date:
        // this.docToggleOptions[doc.id].referralExpiryDate.toISOString(),
      }).then(() => {
        if (isFunction(this.onDocumentUpload)) {
          this.onDocumentUpload();
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  toggleActiveReferral(doc: PatientDocument, newReferralStatus: boolean) {
    this.patientDocuments.forEach((pd) => {
      if (
        pd.data?.tags?.toLowerCase().includes("referral") &&
        !pd.data?.tags?.toLowerCase().includes("referral-old") &&
        pd.data.field === "generic"
      ) {
        const tags = pd.data.tags.replace("referral", "referral-old");
        this.PatientDocumentService.updateReferralTag(pd, tags).then(() => {
          if (isFunction(this.onDocumentUpload)) {
            // this.onDocumentUpload();
          }
        });
      }
    });

    if (newReferralStatus) {
      let tags = "";
      if (doc.data.tags.includes("referral")) {
        tags = doc.data.tags.replace("referral-old", "referral");
      } else {
        tags = `${doc.data.tags},referral`;
      }

      this.PatientDocumentService.updateReferralTag(doc, tags).then(() => {
        if (isFunction(this.onDocumentUpload)) {
          // this.onDocumentUpload();
        }
      });
    }
  }

  deleteFile(doc: PatientDocument) {
    let canChangeMode = true;
    if (alert) {
      canChangeMode = this.$window.confirm(
        "Are you sure you want to delete " + doc.name + "?"
      );
    }
    if (canChangeMode) {
      this.deleteInProgress = true;
      this.PatientDocumentService.delete(doc)
        .then(() => (doc = undefined))
        .then(() => this.documentChanged())
        .finally(() => (this.deleteInProgress = false));
    }
  }

  parseReferralDetail() {
    this.referralDetails = {
      referral_name: this.referralName,
      provider_number: this.referralProvderNumber,
      referral_date: this.referralDate,
      user: this.user.name,
      referral_time_frame: this.referralTimeFrame,
      // expiry_date: expiryDate ? expiryDate : undefined,
    };
  }

  addMonths(numOfMonths: number, date: Date = new Date()) {
    date.setMonth(date.getMonth() + numOfMonths);
    return date;
  }

  // editing uploaded files, not to be confused with the queued
  editFile(document: PatientDocument) {
    this.$uibModal
      .open({
        component: "patientUploadEdit",
        resolve: {
          document,
        },
      })
      .result.then((document) => {
        if (document) {
          return this.PatientDocumentService.updateDocument(document).then(
            () => {
              this.documentChanged();
            }
          );
        }
      })
      .catch(() => {
        // the dialog was closed - do nothing
      });
  }

  /**
   * CUSTOM DROPZONE RELATED MULTI_UPLOAD
   */
  // DROPZONE RELATED
  // for now its just if the files are tagged
  canUploadQueuedFiles() {
    return this.allFilesTagged;
  }

  // are all of them tagged?
  checkIfAllFilesTagged() {
    // if ref exists, check
    if (this?.dropzoneRef) {
      const dropzoneRef: any = angular.element(this?.dropzoneRef);
      this.allFilesTagged = dropzoneRef?.[0].allFilesTagged();
    } else {
      // otherwise no because we cant find a reference
      this.allFilesTagged = false;
    }
    return this.allFilesTagged;
  }

  getQueuedFiles() {
    if (this?.dropzoneRef) {
      const dropzoneRef: any = angular.element(this?.dropzoneRef);
      return dropzoneRef?.[0].getQueuedFiles();
    }
    return [];
  }

  // clears internal queue
  resetQueuedFiles() {
    if (this?.dropzoneRef) {
      const dropzoneRef: any = angular.element(this?.dropzoneRef);
      this.allFilesTagged = false;
      return dropzoneRef?.[0].resetQueuedFiles();
    }
  }

  // sets queued file to edit
  setQueuedFileToEdit(fileObj: GlDropzoneFileWrapper) {
    // first off do a reset as selectedTag and freeText are shared here
    this.resetState();

    // selected tag related
    const foundTag = this.documentTagsV2.find(
      (t) => t.name === fileObj?.data?.tags
    );

    // tag selection

    // if the wrapper data doesnt have a tag either then ignore and set to underifned
    if (isNil(foundTag) && !isNil(fileObj?.data?.tags)) {
      this.selectedTag = { type: "Other", name: "other", title: "Other" };
      this.freeText = fileObj?.data?.tags;
    } else if (isNil(foundTag) && isNil(fileObj?.data?.tags)) {
      // otherwise if no tag was specified (i.e. new file)
      this.selectedTag = null;
    } else {
      // otherwise set to found tag
      this.selectedTag = foundTag;
    }

    // set file and then defaults
    this.queuedFileToEdit = cloneDeep(fileObj);
  }

  handleCancelQueuedFileToEdit() {
    this.queuedFileToEdit = undefined;
    this.resetState();
    this.checkIfAllFilesTagged();
  }

  // passes down the current model to save
  handleSaveQueuedFileToEdit() {
    // A. if it is an other tag, check free text if there is a duplicate
    if (
      this.selectedTag?.name === "other" &&
      this.freeTextHasReferralDuplicate()
    ) {
      // we place it here to break off the chain and stop uploads
      if (!this.getDuplicateReferralConfirmation()) {
        return;
      }
      // set promise to handle duplciate first
      this.handleDuplicateReferralThenSave();
    } else if (
      // B. otherwise if it isnt a freetext tag and there is a duplciate
      this?.selectedTag?.name !== "other" &&
      this.selectedTagHasReferralDuplicate()
    ) {
      // we place it here to break off the chain and stop uploads
      if (!this.getDuplicateReferralConfirmation()) {
        return;
      }

      // set promise to handle duplciate first
      this.handleDuplicateReferralThenSave();
    } else {
      // C. otherwise save normally as is
      this.saveQueuedFileToEdit();
    }
  }

  // handle upload
  handleUploadQueuedFiles() {
    // get the files from queue
    const wrappedFiles: GlDropzoneFileWrapper[] = this.getQueuedFiles();
    if (isNil(wrappedFiles) || isEmpty(wrappedFiles)) {
      return;
    }

    // then map them into params
    const mappedFiles = wrappedFiles.map((f) => {
      const tags = get(f, "data.tags");

      //results will always need a review
      const review_status = tags === "Results";

      set(f, "data.review_status", review_status);

      return f;
    });

    // then send them off to upload
    this.uploadFilesInProgress = true;
    return this.PatientDocumentService.uploadDocumentsForPatient(
      mappedFiles,
      this.patient.id
    )
      .then(() => {
        // handle update and reset
        this.documentChanged();

        // also remove/reset all queued files
        this.resetQueuedFiles();
        // clear in case
        this.handleCancelQueuedFileToEdit();

        this.toastr.success(
          `Successfully uploaded ${mappedFiles.length} file(s)!`
        );
      })
      .catch((err: any) => {
        console.error(err);
        this.toastr.error("Error uploading files, please try agian");
      })
      .finally(() => {
        this.uploadFilesInProgress = false;
        this.checkIfAllFilesTagged();
      });
  }

  handleDeleteQueuedFileToEdit() {
    const confirm: boolean = this.$window.confirm(
      "Are you sure you want to delete this file for uploading?"
    );

    // only execute if confirmed and ref exists
    if (this?.dropzoneRef && confirm) {
      try {
        const dropzoneRef: any = angular.element(this?.dropzoneRef);
        dropzoneRef?.[0].removeQueuedFileByFileWrapper(this.queuedFileToEdit);

        // reset everything
        this.handleCancelQueuedFileToEdit();
        this.resetState();
      } catch (err: any) {
        console.error("deleteQueuedFileToEdit", err);
      }
    }
  }

  // show upload button?
  showUploadButton() {
    return (
      isNil(this.queuedFileToEdit) &&
      this.getQueuedFiles()?.length &&
      this.allFilesTagged
    );
  }

  setTagForFile(file: GlDropzoneFileWrapper) {
    // conversion related for tags
    set(
      file,
      "data.tags",
      this.selectedTag.name === "other" ? this.freeText : this.selectedTag.name
    );
  }

  saveQueuedFileToEdit() {
    // if confirmed, do the old-tag replacement thingy
    // 2. continue
    // this should be part of an edit chain
    if (this?.dropzoneRef) {
      const fileToSave: GlDropzoneFileWrapper = this.queuedFileToEdit;
      const dropzoneRef: any = angular.element(this?.dropzoneRef);

      // if not referral type and referral details exist, delete them
      if (
        this.selectedTag.name !== "referral" &&
        fileToSave?.data?.referral_details
      ) {
        delete fileToSave.data.referral_details;
      }

      // conversion related for tags
      this.setTagForFile(fileToSave);

      // queue
      dropzoneRef?.[0].updateQueuedFile(fileToSave);

      // reset everything
      this.handleCancelQueuedFileToEdit();

      // then check for errors
      this.checkIfAllFilesTagged();
    }
  }

  // sets the same tags for all queued files
  setTagsForAllQueuedFiles() {
    const queuedFiles: GlDropzoneFileWrapper[] = this.getQueuedFiles() ?? [];
    queuedFiles.forEach((fileObj: GlDropzoneFileWrapper) => {
      // set based on selected tag
      !isNil(this.selectedTag) && this.setTagForFile(fileObj);
    });

    this.checkIfAllFilesTagged();
  }
}

export class PatientUploads implements angular.IComponentOptions {
  static selector = "patientUploads";
  static template = require("./patient-uploads.html");
  static controller = PatientUploadsController;
  static bindings = {
    mode: "@?",
    onDocumentUpload: "&?",
    patient: "<",
    patientDocuments: "<",
    patientId: "<",
    record: "<",
    user: "<",
  };
}
