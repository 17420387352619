import { IGlSide } from "models/gl-side.model";
import { Patient } from "models/user.model";

const WINDOW_NAME_LEFT = "documentReviewLeft";
const WINDOW_NAME_RIGHT = "documentReviewRight";

const DOCUMENT_VIEWER_ROUTE = "main.document-viewer";

export class DocumentWindowService {
  static injectionName = "DocumentWindowService";
  windowObjectLeftReference: Window = null;
  windowLeftUrl = "";
  windowObjectRightReference: Window = null;
  windowRightUrl = "";

  constructor(
    private $state: angular.ui.IStateService,
    private $window: angular.IWindowService
  ) {
    "ngInject";
  }

  ////////////////

  openDocumentWindows(patient_id: number, record_id: number) {
    // Configure URLs to open in new windows

    //
    this.openRightWindow(this.gnetUploadsUrl(patient_id, record_id, "right"));
    this.openLeftWindow(this.gnetUploadsUrl(patient_id, record_id, "left"));
    //
  }

  openPlinyViewer(patient: Patient, view?: string) {
    this.openRightWindow(this.plinyViewUrl(patient, "right", view));
    this.openLeftWindow(this.plinyViewUrl(patient, "left", view));
  }

  openRightWindow(url: string) {
    if (
      !this.windowObjectRightReference ||
      this.windowObjectRightReference.closed
    ) {
      this.windowRightUrl = url;
      this.windowObjectRightReference = this.$window.open(
        url,
        WINDOW_NAME_RIGHT,
        "resizable,scrollbars,status"
      );
    } else if (this.windowObjectRightReference.location.href !== url) {
      this.windowRightUrl = url;
      this.windowObjectRightReference = this.$window.open(
        url,
        WINDOW_NAME_RIGHT,
        "resizable,scrollbars,status"
      );
      this.windowObjectRightReference.focus();
    } else {
      this.windowObjectRightReference.location.reload();
      this.windowObjectRightReference.focus();
    }
  }

  openLeftWindow(url: string) {
    if (
      !this.windowObjectLeftReference ||
      this.windowObjectLeftReference.closed
    ) {
      this.windowLeftUrl = url;
      this.windowObjectLeftReference = this.$window.open(
        url,
        WINDOW_NAME_LEFT,
        "resizable,scrollbars,status"
      );
    } else if (this.windowObjectLeftReference.location.href !== url) {
      this.windowLeftUrl = url;
      this.windowObjectLeftReference = this.$window.open(
        url,
        WINDOW_NAME_LEFT,
        "resizable,scrollbars,status"
      );
      this.windowObjectLeftReference.focus();
    } else {
      this.windowObjectLeftReference.location.reload();
      this.windowObjectLeftReference.focus();
    }
  }

  private gnetUploadsUrl(patient_id: number, record_id: number, side: IGlSide) {
    const params = {
      patient_id: patient_id,
      record_id: record_id,
      side,
    };
    const urlRight = this.$state.href(DOCUMENT_VIEWER_ROUTE, params, {
      absolute: false,
    });
    return urlRight;
  }

  private plinyViewUrl(patient: Patient, side: IGlSide, view?: string) {
    const patientId = patient.data.file_no;
    let url = `/pliny/patient/${patientId.padStart(5, "0")}/documents`;

    const eyeSide = side === "left" ? "L" : side === "right" ? "R" : undefined;
    if (eyeSide) {
      url += `;eye=${eyeSide}`;
    }
    if (view) {
      url += `;mode=${view.toLocaleLowerCase()}`;
    }
    return url;
  }
}
