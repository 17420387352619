/**
 * Import the Component styles
 */
import { element } from "angular";
import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import * as FileSaver from "file-saver";
import {
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";
import { GlPrescription } from "models/prescription.model";
import { parseServerDate } from "../../../lib/parse_server_date";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../models/patient-record.model";
import { User } from "../../../models/user.model";
import { GlModelService } from "../../core/services/gl-model.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import { PdfPrintService } from "../../core/services/pdf-print.service";
import "./print-record.scss";

class PrintRecordPageController
  implements angular.IController, angular.IOnInit, angular.IOnDestroy
{
  patientId = this.$stateParams.patientId as number;
  recordId = this.$stateParams.recordId as number;

  shouldPrintOnLoad = (this.$state.params.print as boolean) || false;
  // small flag that enables after data has been loaded`
  canPrint: boolean = false;
  user: User;
  patient: IPatientResource;
  record: PatientRecord;
  today = new Date();
  pdfGenerationInProgress = false;
  recordHistory: PatientRecord[];
  consolidatedRecord: PatientRecordData;
  inHouseProceduresToDisplay: PatientProcedureInHouse[];
  externalProceduresToDisplay: PatientProcedureExternal[];
  prescriptionsToDisplay: GlPrescription[];
  drugsToDisplay: PatientProcedureDrug[];

  constructor(
    private $q: angular.IQService,
    private $stateParams: angular.ui.IStateParamsService,
    private $window: angular.IWindowService,
    private GlModelService: GlModelService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    private PatientRecordService: PatientRecordService,
    private PatientService: PatientService,
    private PdfPrintService: PdfPrintService,
    private PrescriptionsService: PrescriptionsService,
    private $timeout: angular.ITimeoutService,
    public $state: angular.ui.IStateService,
    public PatientProcedureService: PatientProcedureService
  ) {
    "ngInject";
  }

  $onInit() {
    const fetchPatientPromise = this.updatePatientDetails();

    const fetchRecordPromise = this.PatientRecordService.get(
      this.patientId,
      this.recordId
    );
    // this takes the longest to execute so we can use this as an indicator
    const fetchRecordHistoryPromise =
      this.PatientRecordService.getRecordHistoryForUser(this.patientId);

    // this fetches all the procedures to update the service
    const fetchProceduresPromise =
      this.PatientProcedureService.getAllForPatient({
        patientId: this.patientId,
      });
    const fetchPrescriptionsPromise =
      this.PrescriptionsService.fetchPrescriptions(this.patientId);

    this.$q
      .all([
        fetchPatientPromise,
        fetchRecordPromise,
        fetchRecordHistoryPromise,
        fetchProceduresPromise,
        fetchPrescriptionsPromise,
      ])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(([patient, record, recordHistory]) => {
        // set record
        this.record = record;

        // set record history
        this.recordHistory = recordHistory;
        this.consolidatedRecord =
          this.GlModelService.getConsolidatedRecordFromHistory(
            recordHistory.filter((r) => r.id !== this.recordId)
          );

        // procedures
        this.inHouseProceduresToDisplay =
          this.PatientProcedureHelperService.getInHouseProceduresForRecord(
            this.record,
            this.PatientProcedureService.getInHouseProcedures()
          );

        this.externalProceduresToDisplay =
          this.PatientProcedureHelperService.getExternalProceduresForRecord(
            this.record,
            this.PatientProcedureService.getExternalProcedures()
          );

        // prescriptions
        this.prescriptionsToDisplay =
          this.PrescriptionsService.getPrescriptions().filter(
            (p) => p.record_id === this.recordId
          );

        // drugs
        this.drugsToDisplay = this.PatientProcedureService.getDrugs().filter(
          (d) => d.record_id === this.recordId
        );
      })
      // introduce a delay to allow data to fully sync
      // magic number bad but we need the delay
      .then(() => {
        return this.$timeout(625);
      })
      .then(() => {
        // only after resources are loaded we can print
        // if this errors out refresh the page
        this.canPrint = true;

        // then see if we need to print on load based on a flag
        if (this.shouldPrintOnLoad) {
          this.print();
        }
      });
  }

  $onDestroy() {
    // clear any saved patient procedures on destroy
    this.PatientProcedureService.reset();
    this.PrescriptionsService.reset();
  }

  updatePatientDetails() {
    const p = this.PatientService.get(this.patientId);
    p.$promise.then(() => {
      this.patient = p;
    });
    return p;
  }

  getRecordHistory() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  print() {
    this.pdfGenerationInProgress = true;
    element(document.querySelector(".print-footer .footer-left")).append("");

    const doc = document.querySelector("html");
    // convert the style sheets to absolute urls
    doc.querySelectorAll('link[rel="stylesheet"]').forEach((node) => {
      const absoluteUrl = new URL(
        node.getAttribute("href"),
        new URL(this.$window.location.href)
      );
      node.setAttribute("href", absoluteUrl.href);
    });
    const contentToPrint = element(doc);
    return this.PdfPrintService.print({
      headerData: element(document.querySelector("#print-header-wrap")).html(),
      footerData: element(document.querySelector("#print-footer-wrap")).html(),
      pageData: contentToPrint.html(),
      background: false,
    })
      .then((response) => {
        const fileName = this.getFileName();
        FileSaver.saveAs(response, fileName);
      })
      .finally(() => (this.pdfGenerationInProgress = false));
  }

  private getFileName() {
    const recordDate = parseServerDate(this.record.updated_at);
    return `${this.patient.data.last_name}_${
      this.patient.data.first_name
    }_${recordDate.format("YYYYMMDD")}.pdf`;
  }
}

export class PrintRecordPage implements angular.IComponentOptions {
  static selector = "glPrintRecord";
  static template = require("!html-loader?interpolate=require!./print-record.html");
  static controller = PrintRecordPageController;
  static bindings = {
    user: "<",
  };
}
