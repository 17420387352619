import { GlLetterType } from "../../../models/letter.model";

export const pipeName = "provider";
export function providerPipe() {
  "ngInject";
  return (provider: GlLetterType | number) => {
    switch (provider) {
      case "gp":
        return "GP";

      case "managing_optom":
        return "Managing Optom";

      case "referrer":
        return "Referrer";

      case "optometrist":
        return "Optometrist";

      default:
        return provider;
    }
  };
}
