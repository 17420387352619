// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { SearchBarTokenised } from "../../core/components/data/search-bar-tokenised/search-bar-tokenised";
import { moduleName as coreModule } from "../../core/core.module";
import { moduleName as documentImportModule } from "../main.document-upload/document-upload.module";
import { DashboardPatientAlertIcon } from "./components/dashboard-patient-alert-icon/dashboard-patient-alert-icon";
import { DashboardPatientListComponent } from "./components/dashboard-patient-list/dashboard-patient-list";
import { DashboardPatientComponent } from "./components/dashboard-patient/dashboard-patient";
import { PatientListImportComponent } from "./components/patient-list-import/patient-list-import";
import { PatientSearchComponent } from "./components/patient-search/patient-search";
import { ReferralListComponent } from "./components/referral-list/referral-list.component";
/**
 * Import Module Components
 */ import { DashboardPage } from "./dashboard";
import { PersistValueDirective } from "./directives/persist-value.directive";
import {
  appointmentDatePipe,
  pipeName as appointmentDatePipeName,
} from "./pipes/appointment-date.pipe";
import {
  workflowStatePipe,
  pipeName as workflowStatePipeName,
} from "./pipes/workflow.pipe";
/**
 * Module Routes
 */

const routing = (
  $stateProvider: angular.ui.IStateProvider,
  $urlRouterProvider: angular.ui.IUrlRouterProvider
): void => {
  "ngInject";
  $stateProvider.state("main.dashboard", {
    url: "/dashboard",
    component: DashboardPage.selector,
    data: {
      requiresLogin: true,
    },
  });

  $urlRouterProvider.otherwise("/dashboard");
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider): void => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.dashboard", [
      "ui.router",
      coreModule,
      documentImportModule,
    ])

    /**
     * Register Module Components
     */
    .component(DashboardPage.selector, DashboardPage)
    .component(
      DashboardPatientListComponent.selector,
      DashboardPatientListComponent
    )
    .component(PatientListImportComponent.selector, PatientListImportComponent)
    .component(PatientSearchComponent.selector, PatientSearchComponent)
    .component(ReferralListComponent.selector, ReferralListComponent)
    .component(DashboardPatientAlertIcon.selector, DashboardPatientAlertIcon)
    .component(SearchBarTokenised.selector, SearchBarTokenised)

    .directive(
      DashboardPatientComponent.selector,
      DashboardPatientComponent.factory
    )
    .directive(PersistValueDirective.selector, PersistValueDirective.factory())
    .filter(workflowStatePipeName, workflowStatePipe)
    .filter(appointmentDatePipeName, appointmentDatePipe)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
