import {
  IComponentOptions,
  IController,
  IOnChanges,
  IOnChangesObject,
  isFunction,
} from "angular";
import { QzPrinterService } from "app/core/services/qz-printer/qz-printer";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureDrugSelectionWrapper,
} from "models/patient-procedure";
import { PatientRecord } from "models/patient-record.model";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { GlStaff, Patient } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";

// regular, global, user defaults always
const N_DEFAULT_TABS: number = 2;

class PatientDrugsPrescribeController
  extends GlFormController
  implements IController, IOnChanges
{
  patient: Patient;
  prescriber: GlStaff;
  record: PatientRecord;
  drugs: PatientProcedureDrug[] | [];
  drops: PatientProcedureDrop[] | [];
  favouriteDrugs: PatientProcedureDrugSelectionWrapper[] | [];

  drugGroupGlobal: UserFavouriteDrugGroup;
  favouriteDrugGroups: UserFavouriteDrugGroup[] | [];
  displayedFavouriteDrugGroups: UserFavouriteDrugGroup[] | [];

  drugToEdit: PatientProcedureDrug | object = undefined;

  // current tab
  nDefaultTabs: number = N_DEFAULT_TABS;

  // can be re-used for favourites
  saveDrugInProgress: boolean = false;
  updateDrugInProgress: boolean = false;
  deleteDrugInProgress: boolean = false;
  searchOrEditDrugInProgress: boolean = false;

  // group
  saveFavouriteGroupInProgress: boolean = false;
  deleteFavouriteGroupInProgress: boolean = false;
  useRecordIdOnPrescribe: boolean = false;

  displayButtonOptions: boolean = false;

  onDrugsUpdate: (arg: { patientId: number }) => void;
  onPrescriptionsUpdate: (arg: { patientId: number }) => void;
  onFavouritesUpdate: (arg: { userId: number }) => void;

  constructor(public QzPrinterService: QzPrinterService) {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes?.favouriteDrugGroups && this.favouriteDrugGroups) {
      this.drugGroupGlobal = this.favouriteDrugGroups[0];
      this.displayedFavouriteDrugGroups =
        this.favouriteDrugGroups.slice(1) ?? [];
    }
  }

  // UPDATES
  handleDrugsUpdate() {
    if (isFunction(this.onDrugsUpdate)) {
      this.onDrugsUpdate({ patientId: this.patient.id });
    }
  }

  handlePrescriptionsUpdate() {
    if (isFunction(this.onPrescriptionsUpdate)) {
      this.onPrescriptionsUpdate({ patientId: this.patient.id });
    }
  }

  handleFavouritesUpdate() {
    if (isFunction(this.onFavouritesUpdate)) {
      this.onFavouritesUpdate({ userId: this.prescriber.id });
    }
  }

  // CREATE DRUG
  toggleCreateDrug() {
    this.searchOrEditDrugInProgress = true;
  }

  toggleCancelDrug() {
    this.searchOrEditDrugInProgress = false;
  }

  cancelDrug() {
    this.drugToEdit = undefined; // reset
    this.toggleCancelDrug();
  }

  // FAVOURITES  GROUP
  getFavouriteDrugs() {
    return this.favouriteDrugs;
  }

  getFavouriteDrugGroups() {
    return this.favouriteDrugGroups;
  }

  /**
   * only shows user favourite drugs
   * LAYOUT IS
   * - regular drugs
   * - global favourites
   * - user default favourites
   * - any other groups
   * - add favourite group
   */
  getDisplayableFavouriteDrugGroups() {
    return this.displayedFavouriteDrugGroups;
  }
}

export class PatientDrugsPrescribeComponent implements IComponentOptions {
  static selector = "patientDrugsPrescribe";
  static template = require("./patient-drugs-prescribe.html");
  static controller = PatientDrugsPrescribeController;
  static bindings = {
    patient: "<",
    prescriber: "<",
    record: "<?",
    drugs: "<",
    drops: "<",
    favouriteDrugs: "<",
    favouriteDrugGroups: "<",
    onDrugsUpdate: "&",
    onPrescriptionsUpdate: "&",
    onFavouritesUpdate: "&",
    useRecordIdOnPrescribe: "<",
  };
}
