// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { PrintRecordPage } from "./print-record";
import { AuthService } from "../../core/services/auth.service";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("print-record", {
    url: "/patient/{patientId:int}/record/{recordId:int}/print?{print:bool}",
    component: PrintRecordPage.selector,
    data: {
      requiresLogin: true
    },
    resolve: {
      user: (AuthService: AuthService) => {
        "ngInject";
        return AuthService.getUser();
      }
    }
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.print-record", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(PrintRecordPage.selector, PrintRecordPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
