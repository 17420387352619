import { PatientRecord } from "../../../../../models/patient-record.model";
import {
  DocumentsService,
  IRecordDocuments,
} from "../../../../core/services/documents-service/documents.service";
import { IPatientResource } from "../../../../core/services/patient.service";

class GridViewController implements angular.IController, angular.IOnChanges {
  patientId = this.$stateParams.patient_id;
  recordKey = parseInt(this.$stateParams.record_id, 10);
  side = this.$stateParams.side as string;
  patient: IPatientResource;
  recordHistory: PatientRecord[];
  currentRecord: PatientRecord;
  showHistory = false;
  currentDocuments: IRecordDocuments;

  setCurrentRecord: ({ record }: { record: PatientRecord }) => void;

  constructor(
    private $stateParams: angular.ui.IStateParamsService,
    private DocumentsService: DocumentsService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.currentRecord && this.currentRecord) {
      this.currentDocuments = this.DocumentsService.getNamedDocuments(
        this.currentRecord.documents
      );
    }
  }
}

export class GridViewComponent implements angular.IComponentOptions {
  static selector = "gridView";
  static template = require("./grid-vew.html");
  static controller = GridViewController;
  static bindings = {
    recordHistory: "<",
    currentRecord: "<",
    setCurrentRecord: "&?",
  };
}
