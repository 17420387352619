import { get, isEmpty, set } from "lodash";
import * as moment from "moment";
import { IGlSide } from "../../../../../models/gl-side.model";
import { PatientRecordData } from "../../../../../models/patient-record.model";
import { Appendix } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

const GONIO_FIELD_SUPERIOR = "gonioSuperior";
const GONIO_FIELD_TEMPORAL = "gonioTemporal";
const GONIO_FIELD_NASAL = "gonioNasal";
const GONIO_FIELD_INFERIOR = "gonioInferior";

const GONIO_FIELDS = [
  GONIO_FIELD_SUPERIOR,
  GONIO_FIELD_TEMPORAL,
  GONIO_FIELD_NASAL,
  GONIO_FIELD_INFERIOR,
  "gonioFinding",
  "gonioFindingFree",
  "gonioComment",
  "gonioCommentFree",
];

export class SegmentGonioController
  extends GlFormController
  implements angular.IController
{
  gonioOptions = this.appendix.get("gonio");
  gonioCommentOptions = this.appendix.get("gonioComment");
  gonioFindingOptions = this.appendix.get("gonioFinding");
  datepickerOptions: any;
  repeatOptions = this.appendix.get("repeatUnit", true);
  record: PatientRecordData;
  ngRequired = true;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  updateNextGonioDate() {
    if (this.record.repeat?.units) {
      const today = moment();
      this.record.gonio_date = today
        .add(this.record.repeat.amount as any, this.record.repeat.units.key)
        .toISOString();
    }
  }

  hasGonio(side: string) {
    return GONIO_FIELDS.reduce(
      (hasGonio: boolean, key: string) =>
        hasGonio || !isEmpty(get(this.record, `${key}.${side}`)),
      false
    );
  }

  copySuperiorValue(side: IGlSide) {
    const superiorValue = get(this.record, `${GONIO_FIELD_SUPERIOR}.${side}`);
    if (superiorValue) {
      [GONIO_FIELD_INFERIOR, GONIO_FIELD_NASAL, GONIO_FIELD_TEMPORAL].forEach(
        (field) => {
          set(this.record, `${field}.${side}`, superiorValue);
        }
      );
    }
  }

  clearFields() {
    this._clearBilateralOptionField("gonioSuperior");
    this._clearBilateralOptionField("gonioTemporal");
    this._clearBilateralOptionField("gonioNasal");
    this._clearBilateralOptionField("gonioInferior");

    this._clearBilateralOptionField("gonioFinding");
    this._clearBilateralTextField("gonioFindingFree");

    this._clearBilateralOptionField("gonioComment");
    this._clearBilateralTextField("gonioCommentFree");
  }

  private _clearBilateralOptionField(key: string) {
    set(this.record, key, {
      left: undefined,
      right: undefined,
    });
  }

  private _clearBilateralTextField(key: string) {
    set(this.record, key, {
      left: "",
      right: "",
    });
  }
}

export class SegmentGonio implements angular.IComponentOptions {
  static selector = "segmentGonio";
  static template = require("./segment-gonio.html");
  static controller = SegmentGonioController;
  static bindings = {
    enableLeft: "<",
    enableRight: "<",
    isEditable: "<?",
    mode: "@?",
    ngRequired: "<?",
    record: "<",
  };
}
