// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { moduleName as SettingsPageModule } from "../main.settings/settings.module";
import { SettingsUserClinicPageComponent } from "./user-clinic";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.settings.user-clinic", {
    url: "/settings/user-clinic/{id:int}",
    component: SettingsUserClinicPageComponent.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.settings.user-clinic", [
      SettingsPageModule,
      "ui.router",
      "gl.core",
    ])

    /**
     * Register Module Components
     */
    .component(
      SettingsUserClinicPageComponent.selector,
      SettingsUserClinicPageComponent
    )

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
