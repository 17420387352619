import * as cleanDeep from "clean-deep";
import { isEmpty } from "lodash";

export const pipeName = "isEmpty";
export const pipe: angular.Injectable<angular.FilterFactory> = () => {
  "ngInject";
  return (input: any) => {
    return isEmpty(cleanDeep(input));
  };
};
