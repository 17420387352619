import { isFunction } from "angular";
import { AccessLock } from "models/access-lock.model";
import { IGlApiResponse } from "models/gl-api-response.model";
import { API_PATH_v2 } from "./api-paths";
import { AuthService } from "./auth.service";

export class AccessLockService {
  static injectionName = "AccessLockService";
  private recordId: number;
  private currentLock: AccessLock;
  private onBeforeUnloadCb: () => string;

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private $window: angular.IWindowService,
    private API_URL: string,
    private AuthService: AuthService
  ) {
    "ngInject";
  }

  initPatientLock({
    recordId,
    onBeforeUnload,
  }: {
    recordId: number;
    onBeforeUnload?: () => string;
  }) {
    this.recordId = recordId;
    this.onBeforeUnloadCb = onBeforeUnload;

    return this.enablePageLock({ recordId }).then((lock) => {
      // this.accessLockForSession = lock;
      // only register the window unload handlers if getting the access lock is successful
      this.$window.onbeforeunload = this.onBeforeUnload.bind(this);
      this.$window.onunload = this.onUnload.bind(this);
      this.currentLock = lock;
      return lock;
    });
  }

  clearPatientLock() {
    return this.disablePageLock({ recordId: this.recordId }).then(() => {
      // this.accessLockForSession = undefined;
      this.recordId = undefined;
      this.$window.onbeforeunload = undefined;
      this.$window.onunload = undefined;
    });
  }

  // We have the ability to lock a screen. When a user is logged in and accesses certain pages
  // such as any page where they enter patient data - we want to block other people from being
  // able to edit that file until the original user leaves the page
  enablePageLock({ recordId }: { recordId?: number }) {
    const id = recordId;
    return this.$http
      .post<IGlApiResponse<AccessLock>>(this.getLockUrl(id), {})
      .then((response) => response.data.data);
  }

  disablePageLock({ force, recordId }: { force?: boolean; recordId?: number }) {
    // const id = recordId || patient.record.id;
    const id = recordId;
    const url = this.getLockUrl(id);
    // Request to the API for the user associated with the token
    return this.$http
      .delete(url, {
        ...(force && {
          data: {
            force: 1,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }),
      })
      .then((response) => {
        this.currentLock = undefined;
        return response.data;
      })
      .catch((error) => {
        console.error("Error deleting access lock on page unload", error);
      });
  }

  disablePageLockSynchronously({ recordId }: { recordId?: number }) {
    // const id = patientId || patient.id;
    const id = recordId;
    const url = this.getLockUrl(id);
    if (fetch && "keepalive" in Request.prototype) {
      // Synchronous XMLHttp Requests are deprecated by browsers.
      // Fetch "keepalive" is the recommended alternative (other than sendbeacon)
      // https://github.com/w3c/beacon/pull/27#issu                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ecomment-241549321
      const headers = new Headers();
      headers.set(
        "authorization",
        `Bearer ${this.AuthService.getAccessToken()}`
      );
      const result = fetch(url, {
        method: "DELETE",
        headers,
        keepalive: true,
        mode: "cors",
        body: JSON.stringify({ method: "DELETE" }),
      });
      return this.$q.resolve(result);
    } else {
      return this.$q((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.open("DELETE", url, false); //the false is for making the call synchronous
        xmlhttp.setRequestHeader(
          "authorization",
          `Bearer ${this.AuthService.getAccessToken()}`
        );
        xmlhttp.onloadend = () => resolve();
        xmlhttp.onerror = () => reject();

        xmlhttp.send();
      });
    }
  }

  getAccessLock({ recordId }: { recordId?: number }) {
    // const id = patientId || patient.id;

    const id = recordId;
    const url = this.getLockUrl(id);
    // Request to the API for the user associated with the token
    return this.$http
      .get<IGlApiResponse<AccessLock>>(url)
      .then((response) => response.data.data);
  }

  private getLockUrl(recordId: number) {
    return `${this.API_URL}${API_PATH_v2}/records/${recordId}/lock`;
  }

  private onBeforeUnload() {
    if (isFunction(this.onBeforeUnloadCb)) {
      return this.onBeforeUnloadCb();
    }
  }

  private onUnload() {
    // console.log(this.currentLock);
    if (this.currentLock) {
      this.disablePageLockSynchronously({
        recordId: this.recordId,
      });
    }
  }
}
