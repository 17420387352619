import { defaults, get, has, max, set, unset } from "lodash";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { Appendix } from "../../../services/appendix";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import { GlFormController } from "../../gl-form-controller";
import "./gl-iop.scss";

export class GlIopController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  static $inject = [Appendix.injectionName, PatientRecordService.injectionName];

  record: PatientRecordData;
  stats = this.appendix.getOptions("iop");
  enableLeft = true;
  enableRight = true;
  recordHistory: PatientRecord[];
  isAutomaticMaxIop = { left: false, right: false };

  constructor(
    public appendix: Appendix,
    private PatientRecordService: PatientRecordService
  ) {
    super();
  }

  // eslint-disable-next-line
  $onChanges(changes: angular.IOnChangesObject) {
    if (this.isEditMode() && this.record) {
      const iop = { left: null, right: null };
      defaults(this.record.iop, iop);
      this.isAutomaticMaxIop.left = !has(this.record, "max_iop.left");
      this.isAutomaticMaxIop.right = !has(this.record, "max_iop.right");
    }
  }

  getMaxIop(side: IGlSide) {
    return this.PatientRecordService.getMaxIopForSide(
      side,
      this.recordHistory,
      this.record
    );
  }

  getIop(side: IGlSide) {
    return +get(this.record, `iop.${side}`);
  }

  getCurrentMaxIop(side: IGlSide) {
    return +get(this.record, `max_iop.${side}`);
  }

  setCurrentMaxIop(side: IGlSide, value: number) {
    set(this.record, `max_iop.${side}`, value);
  }

  setMaxIopToAutomatic(side: IGlSide) {
    // automatic Max IOP is the default. So to set to automatic Max iop delete
    // any max_iop setting
    unset(this.record, `max_iop.${side}`);
    this.isAutomaticMaxIop[side] = true;
  }

  setManualMaxIop(side: IGlSide) {
    this.setCurrentMaxIop(side, this.getMaxIop(side));
    this.isAutomaticMaxIop[side] = false;
  }

  getMinMaxIop(side: IGlSide) {
    return max([this.getIop(side), this.getHistoricalMaxIop(side)]);
  }

  private getHistoricalMaxIop(side: IGlSide) {
    return this.PatientRecordService.getMaxIopForSide(side, this.recordHistory);
  }
}

export class GlIopComponent implements angular.IComponentOptions {
  static selector = "glIop";
  static template = require("./gl-iop.html");
  static controller = GlIopController;
  static bindings = {
    record: "<",
    recordHistory: "<",
    enableLeft: "<?",
    enableRight: "<?",
    mode: "@?",
    isEditable: "<?",
  };
}
