import { User } from "../../../models/user.model";

class ClinicAdminController implements angular.IController {
  public user: User;
  constructor() {
    "ngInject";
  }
}

export class ClinicAdminComponent implements angular.IComponentOptions {
  static selector = "clinicAdminPage";
  static template = require("./clinic-admin.html");
  static controller = ClinicAdminController;
  static bindings = {
    user: "<"
  };
}
