class SimpleHeaderController {
  constructor() {
    "ngInject";
  }
}

export class SimpleHeaderComponent implements angular.IComponentOptions {
  static selector = "simpleHeader";
  static template = require("./simple-header.html");
  static controller = SimpleHeaderController;
  static bindings = {
    patient: "<",
    title: "@"
  };
}
