export const abbreviatePipeName = "abbreviate";
export const abbreviate: angular.Injectable<angular.FilterFactory> = () => {
  "ngInject";
  return (input: string, type: string) => {
    if (type === "procedure") {
      switch (input) {
        case "right":
          return "R/E";
        case "both":
          return "B/E";
        case "left":
          return "L/E";
        case "peripheralIridotomy":
          return "PI";
      }
    }
    return input;
  };
};
