import { defaults, pullAt } from "lodash";
import { Appendix } from "../../../services/appendix";

class SegmentVisitProceduresController
  implements angular.IController, angular.IOnChanges {
  mode: string;
  record: any;

  constructor(public appendix: Appendix) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.record && this.record) {
      defaults(this.record, { visit_procedures: [] });
    }
  }

  addDrop() {
    this.record.visit_procedures.push({});
  }

  removeDrop(index: number) {
    pullAt(this.record.visit_procedures, index);
  }
}

export class SegmentVisitProcedures implements angular.IComponentOptions {
  static selector = "segmentVisitProcedures";
  static template = require("./segment-visit-procedures.html");
  static controller = SegmentVisitProceduresController;
  static bindings = {
    mode: "@",
    record: "<",
  };
}
