// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { ClinicAdminComponent } from "./clinic-admin";
import { EditClinicComponent } from "./components/edit-clinic/edit-clinic";
import { EditUserComponent } from "./components/edit-user/edit-user";

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.clinic-admin", {
    url: "/admin",
    component: ClinicAdminComponent.selector,
    data: {
      requiresLogin: true
    }
  });

  $urlRouterProvider.otherwise("/dashboard");
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.clinic-admin", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(ClinicAdminComponent.selector, ClinicAdminComponent)
    .component(EditClinicComponent.selector, EditClinicComponent)
    .component(EditUserComponent.selector, EditUserComponent)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
