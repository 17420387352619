import { PatientProcedureDrug } from "models/patient-procedure";

export const pipeName = "formatDrugDisplayName";
export const formatDrugDisplayName: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (drug: PatientProcedureDrug) => {
    const isAuthorityDrug: boolean =
      drug.data.authority_script || drug.data.restriction_flag === "A";
    const isPrivateScript: boolean = drug.data?.private ?? false;

    return `${drug.data.brand_name}${
      isAuthorityDrug && !isPrivateScript ? " (A)" : ""
    }`;
  };
};
