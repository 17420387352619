// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { CataractConsentPage } from "./cataract-consent";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.cataract-consent", {
    url: "/patient/{patientId:int}/cataract-consent/{recordId:int}?{debug:bool}&{form-record:int}&{goToMessages:bool}",
    component: CataractConsentPage.selector,
    data: {
      requiresLogin: true,
    },
    params: {
      // This makes the recordId optional
      recordId: {
        squash: true,
        value: null,
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.cataract-consent", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(CataractConsentPage.selector, CataractConsentPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
