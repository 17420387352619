import { GL_VIEWS } from "app/pages/main.record/components/view-manager/view-manager.component";
import { cloneDeep } from "lodash";
import { Appendix, IGlOption } from "./appendix";
import { GlModelService } from "./gl-model.service";

const CATARACT_DEFAULT_LENS_STATUS = {
  name: "Cataract",
  key: "cataract",
};
const CATARACT_DEFAULT_LENS_OBSERVATION = [
  {
    type: {
      name: "Nuclear Sclerosis",
      key: "nuclearSclerosis",
      requireQuantifier: true,
      quantifier: {
        name: "2+",
        key: "2+",
      },
    },
  },
  {
    type: {
      name: "Posterior Subcapsular",
      key: "posteriorSubcapsular",
      requireQuantifier: true,
      quantifier: {
        name: "1+",
        key: "1+",
        default: true,
      },
    },
  },
];

export class DataService {
  static injectionName = "DataService";
  selectedCondition: GL_VIEWS;

  constructor(
    private appendix: Appendix,
    private GlModelService: GlModelService
  ) {
    "ngInject";
  }

  getDefaultKey(key: string) {
    return this.appendix.getDefaultKey(key);
  }

  getDefaultForPath(path: string, key: string) {
    let defaultValue: any = this.getDefaultKey(key);
    if (this.selectedCondition === "Cataract") {
      if (path.includes("lens.observations")) {
        defaultValue = cloneDeep(CATARACT_DEFAULT_LENS_OBSERVATION);
      }
      if (path.includes("lens.status") && !path.includes("lens.status.other")) {
        defaultValue = cloneDeep(CATARACT_DEFAULT_LENS_STATUS);
      }
      if (path.includes("oct_gcc")) {
        defaultValue = undefined;
      }
    } else if (this.selectedCondition === "Retina") {
      if (key === "vit") {
        defaultValue = this.appendix
          .get("vit")
          .find((opt: IGlOption) => opt.key === "clear");
      }
      if (path.includes("oct_gcc") || path.includes("oct_rnfl")) {
        defaultValue = undefined;
      }
    }
    return cloneDeep(this.GlModelService.get(path) || defaultValue);
  }
}
