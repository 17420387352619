import { PatientService } from "app/core/services/patient.service";
import { cloneDeep, isEmpty, isFunction, isNil, isString } from "lodash";
import { Patient, UserQueryWrapper } from "models/user.model";
import "./search-bar-tokenised.scss";
import angular = require("angular");
import moment = require("moment");

// interface BootstrapModal {
//   open: () => void;
//   close: () => void;
// }

interface SearchQueryParamsInterface {
  key: string;
  value: string;
  key_other?: string;
  value_other?: any; // multi-card
}

// TO DO: change this to a binding param
const DEFAULT_SEARCH_FILL_OPTIONS: SearchQueryParamsInterface[] = [
  {
    key: "first_name",
    value: "john",
  },
  {
    key: "last_name",
    value: "cotter",
  },
  {
    key: "mobile",
    value: "04 1234 5678",
  },
  {
    key: "medicare_number",
    value: "0123 45678 9",
  },
  {
    key: "birth_date",
    value: "23/08/1945",
  },
  {
    key: "other",
    key_other: "custom_key",
    value: "custom_value",
  },
];

const DATE_FORMATS: string[] = [
  // SLASHES
  "D/M/YY",
  "D/MM/YY",
  "D/MMM/YY",

  "D/M/YYYY",
  "D/MM/YYYY",
  "D/MMM/YYYY",

  // SPACES
  "D M YY",
  "D MM YY",
  "D MMM YY",

  "D M YYYY",
  "D MM YYYY",
  "D MMM YYYY",

  // DASHES
  "D-M-YY",
  "D-MM-YY",
  "D-MMM-YY",

  "D-M-YYYY",
  "D-MM-YYYY",
  "D-MMM-YYYY",

  // ALT
  "d/MM/yyyy",
  "D/MM/yyyy",
];

export class SearchBarTokenisedController implements angular.IController {
  // typeahead options
  searchOptions = {
    debounce: {
      default: 500,
      blur: 250,
    },
    getterSetter: true,
  };

  // what we send over when searching
  queryParams: SearchQueryParamsInterface[] = [];
  // input text
  searchText: string = "";
  // is main input bar focused? (css)
  // focused: boolean = false;

  // modal element
  $uibModal: any;

  // on select patient
  onSelectPatient: (arg: { patient: Patient }) => void;

  // TO DO: possibly consider injected services?
  constructor(
    private PatientService: PatientService,
    private $state: angular.ui.IStateService,
    $uibModal: any
  ) {
    "ngInject";
    this.$uibModal = $uibModal;
  }

  // custom select callback if exists
  /** bootstrap code specifies typeahead-on-select to have
   *  these attributes and they are defined as type any in
   *  the official code, the only thing we know is
   *  $model will be a DB record
   **/
  selectPatient(
    $item: any,
    $model: Patient,
    // eslint-disable-next-line
    $label: any,
    // eslint-disable-next-line
    $event: any
  ) {
    // if a custom one is declared then use that
    if (isFunction(this.onSelectPatient)) {
      this.onSelectPatient({ patient: $model });
    } else {
      // otherwise defualt is redirect to user page
      this.$state.go("main.patient", {
        patient_id: $model.id,
      });
    }
    this._untriggerInputFocusEvent();
  }

  // FETCH QUERY PARAMS
  getQueryParams() {
    return this.queryParams;
  }

  getExampleKeyOptions() {
    return DEFAULT_SEARCH_FILL_OPTIONS;
  }

  // QUERY PARAM LOGIC
  // tokenise tring if they matfh a certain regex
  async handleFilterQueryParamTokens() {
    const res: string = this._filterQueryParamTokens(this.searchText);
    this.searchText = res;
    // microdelay to avoid race condition with typeahead focus
    return await new Promise((resolve) => setTimeout(resolve, 75));
  }

  // remove query param
  removeQueryParam(index: number): void {
    if (index >= 0) {
      this.queryParams.splice(index, 1);
    }
  }

  // KEYPRESS
  onKeyPress(keyEvent: KeyboardEvent) {
    switch (keyEvent.key) {
      // special edge case to accomodate for
      // typeahead focus
      case " ":
        this.handleFilterQueryParamTokens();
        break;
      case "Enter":
        this.searchPatients();
        this._triggerInputFocusEvent();
        break;
      default:
        break;
    }
  }

  // SEARCh
  searchPatients() {
    const params: Record<string, string | number> = {};

    // CASE: prevent full search
    if (isEmpty(this.searchText.trim()) && isEmpty(this.queryParams)) {
      return;
    }
    // search text?
    if (!isEmpty(this.searchText.trim()) && isString(this.searchText)) {
      params.q = this.searchText?.trim() ?? null;
    }

    // query params?
    if (!isEmpty(this.queryParams)) {
      this.queryParams.reduce((prev, curr) => {
        // if other then use specified key otherwise use the regualr one
        const _keyToUse: string =
          curr.key === "other" ? curr?.key_other ?? "" : curr.key;

        // if birth_date is the key then convert value to unix
        if (_keyToUse === "birth_date") {
          const momentDate = moment(curr.value.trim(), DATE_FORMATS);
          prev[_keyToUse] =
            momentDate.unix() ?? curr.value?.trim() ?? curr.value;
        } else {
          // otherwise default case
          prev[_keyToUse] = curr.value?.trim() ?? curr.value;
        }

        return prev;
      }, params);
    }

    // TO DO: change this to a generalised  binding function which can be inejcted
    return this.PatientService.searchPatientsByName(params).then((response) => {
      if (!isEmpty(response)) {
        return response.map((p: UserQueryWrapper) => {
          return {
            ...p,
            queries: this.queryParams ?? {},
          };
        });
      }
      return response;
    });
  }

  // BUTTON
  // we do search by mock triggering input clock
  searchButtonClicked() {
    // this.searchPatients();
    this._triggerInputFocusEvent();
  }

  triggerInputFocus() {
    this._triggerInputFocusEvent();
  }

  // clear search queries (including the tokenised ones)
  resetSearch() {
    this.searchText = "";
    this.queryParams = [];
  }

  // MODAL LOGIC
  openModal() {
    // refer internally
    const _queryParams: SearchQueryParamsInterface[] = cloneDeep(
      this.queryParams
    );

    // check on case with birth_date
    // case where manual input and empty
    const _birthDateNewIndex: number = _queryParams?.findIndex(
      (p) => p.key === "birth_date" && p.value && isNil(p.value_other)
    );
    // if found manually add the date for reference
    if (_birthDateNewIndex >= 0) {
      _queryParams[_birthDateNewIndex].value_other = moment(
        _queryParams[_birthDateNewIndex]?.value,
        DATE_FORMATS
      )?.toDate();
    }

    // modal instance
    const instance = this.$uibModal.open({
      templateUrl: "queryModal.html",
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      // CUSTOM CONTROLLER
      controllerAs: "$modal",
      controller: [
        "$uibModalInstance",
        function ($uibModalInstance: any) {
          // DATEPICKER STUFF
          // datepicker options
          this.datepickerOptions = {
            showWeeks: false,
            format: "d/MM/yyyy",
            startingDay: 1,
            formatDay: "dd",
            formatMonth: "MM",
            formatYear: "yyyy",
            ngModelOptions: {
              timezone: "Australia/Melbourne",
            },
          };
          this.datePickerAltFormats = DATE_FORMATS;
          this.handleDateSelect = (date: Date, index: number) => {
            const dateString: string = moment(date).format("D/MM/YYYY");
            this.updateQueryParamValue(dateString, index);
          };
          // goes to catch
          this.ok = () => $uibModalInstance.close(this.queryParams);
          // goes to then
          this.cancel = () => $uibModalInstance.dismiss("dismissing");
          // query params (if empty add a row else leave it)
          this.queryParams = isEmpty(_queryParams)
            ? [
                DEFAULT_SEARCH_FILL_OPTIONS[0] ?? {
                  key: "key",
                  value: "value",
                },
              ]
            : _queryParams;
          // add
          this.addQueryParam = (index: number) => {
            const defaultOption: SearchQueryParamsInterface = {
              key: "first_name",
              value: "john",
            };
            // determine whetehr to use a prefil option or default
            const nQueryParams: number = this.queryParams.length ?? 0;
            // check which one to use
            const prefilOption: SearchQueryParamsInterface =
              DEFAULT_SEARCH_FILL_OPTIONS[nQueryParams] ?? defaultOption;

            // decide to use prefil option or just add
            if (isEmpty(this.queryParams)) {
              this.queryParams = [prefilOption];
            } else {
              this.queryParams.splice(index + 1, 0, prefilOption);
            }
          };
          // remove
          this.removeQueryParam = (index: number) => {
            this.queryParams.splice(index, 1);
          };
          // update
          this.updateQueryParamKey = (key: string, index: number) => {
            this.queryParams[index] = {
              ...this.queryParams[index],
              key,
              value: null,
              value_other: null,
            };
          };
          this.updateQueryParamKeyOther = (
            key_other: string,
            index: number
          ) => {
            this.queryParams[index] = {
              ...this.queryParams[index],
              key_other,
            };
          };
          this.updateQueryParamValue = (value: string, index: number) => {
            this.queryParams[index] = {
              ...this.queryParams[index],
              value,
            };
          };
          this.isEmpty = isEmpty;
          this.optionKeys = DEFAULT_SEARCH_FILL_OPTIONS.map((k) => k.key);
        },
      ],
    });

    // callback at the end
    instance.result.then(
      (queryParams: SearchQueryParamsInterface[]) => {
        // set new params
        this.queryParams = queryParams ?? [];
      },
      // surpress as we need dismissal callback
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  private _triggerInputFocusEvent() {
    const inputElement: HTMLElement = document.getElementById(
      "search-bar-tokenised-input"
    );
    const searchBarTokenisedElement: HTMLElement = document.getElementById(
      "search-bar-tokenised-parent"
    );

    // parent
    if (!isNil(searchBarTokenisedElement)) {
      searchBarTokenisedElement.click();
      searchBarTokenisedElement.focus();
    }

    // if element is defined
    if (!isNil(inputElement)) {
      inputElement.click();
      inputElement.focus();
      // inputElement.dispatchEvent(new Event('focus'))
      inputElement.dispatchEvent(
        new Event("input", {
          bubbles: true,
        })
      );
    }
  }

  private _untriggerInputFocusEvent() {
    const inputElement: HTMLElement = document.getElementById(
      "search-bar-tokenised-input"
    );
    const searchBarTokenisedElement: HTMLElement = document.getElementById(
      "search-bar-tokenised-parent"
    );

    // parent
    if (!isNil(searchBarTokenisedElement)) {
      searchBarTokenisedElement.blur();
    }

    // if element is defined
    if (!isNil(inputElement)) {
      inputElement.blur();
      // inputElement.dispatchEvent(new Event('focus'))
      inputElement.dispatchEvent(
        new Event("blur", {
          bubbles: true,
        })
      );
    }
  }

  // main on-change function
  _filterQueryParamTokens(s: string) {
    //<key>:<optional space><value><space>
    // key: space? <date validator> | <any quoted string> | <digits> | <string>
    const queryRegex: RegExp =
      // eslint-disable-next-line no-useless-escape
      /(\w+):[ ]?(\d{1,2}[.-\/ ](\d{1,2}|[a-zA-Z]{3,9})[.-\/ ]\d{2,4})|(\w+):[ ]?("([^"]+)"|\d+|[-\w+\/]+)/gm;

    const regexpResult: RegExpMatchArray = s.match(queryRegex);
    if (!isNil(regexpResult) && !isEmpty(regexpResult)) {
      for (const res of regexpResult) {
        // tokenise
        const [key, value]: string[] = res.trim().split(":");
        this._updateQueryParam(key.trim(), value.trim(), this.queryParams);

        // then remove
        s = s.replace(res, "").trim();
      }
      // if after filtering its empty make sure we pad it with an extra
      // space to trigger modal
      if (isEmpty(s)) {
        s = " ";
      }
    }
    return s;
  }

  // use internal reference
  private _updateQueryParam(
    key: string,
    value: string,
    queryParams: SearchQueryParamsInterface[]
  ) {
    // check if existing
    const existingIndex: number = queryParams.findIndex((p) => p.key === key);

    // if existing
    if (existingIndex >= 0) {
      queryParams[existingIndex] = {
        key,
        value,
      };
    } else {
      // otherwise just add
      queryParams.push({
        key,
        value,
      });
    }
  }

  // use internal reference
  private _removeQueryParam(
    key: string,
    queryParams: SearchQueryParamsInterface[]
  ) {
    // check if existing
    const existingIndex: number = queryParams.findIndex((p) => p.key === key);
    if (existingIndex >= 0) {
      queryParams.splice(existingIndex, 1);
    }
  }
}

export class SearchBarTokenised implements angular.IComponentOptions {
  static selector = "searchBarTokenised";
  static template = require("./search-bar-tokenised.html");
  static controller = SearchBarTokenisedController;
  static bindings = {
    onSelectPatient: "&?",
  };
}
