// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { VirtualReviewPage } from "./virtual-review";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.virtual-review", {
    url: "/patient/{patientId:int}/virtual-review/{recordId:int}?{debug:bool}&{review-record:int}&{goToMessages:bool}",
    component: VirtualReviewPage.selector,
    data: {
      requiresLogin: true,
    },
    params: {
      // This makes the recordId optional
      recordId: {
        squash: true,
        value: null,
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.virtual-review", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(VirtualReviewPage.selector, VirtualReviewPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
