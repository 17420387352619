import { PatientRecord } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import "./button-record-save-sign.scss";

const MCES_CLINIC_ID = 50;
const SUNBURY_CLINIC_ID = 2;
class ButtonRecordSaveSignController implements angular.IController {
  // @Input()
  currentState: string;
  disableSave: boolean = true;
  disableSign = false;
  record: PatientRecord;
  recordType: "history" | "record" = "record";
  user: User;

  // button functions
  reopenRecord: () => void;
  signRecord: (args?: { skipOphthalReview: boolean }) => void;
  save: () => void;

  constructor() {
    "ngInject";
  }

  signRecordAndSkipOphthalReview() {
    this.signRecord({ skipOphthalReview: true });
  }

  ifSigned() {
    return !!this.record && this.record.data_signed_by_id;
  }

  userIsOptometrist() {
    return !!(this.user.type.name === "optometrist");
  }

  canSign() {
    return (
      (this.user.type.name === "optometrist" &&
        this.record.type !== "virtual_review") ||
      this.user.type.name === "ophthalmologist" || // this also works for consent form
      this.recordType === "history" ||
      (this.record.type === "tech_record" &&
        this.user?.type?.name === "technician")
    );
  }

  fromSameClinic() {
    return (
      !this.record?.clinic || this.record?.clinic?.id === this.user?.clinic.id
    );
  }

  canReOpen() {
    return !!(
      this.user?.type?.name === "ophthalmologist" ||
      (this.user?.type?.name === "optometrist" &&
        this.record?.type !== "virtual_review") ||
      this.recordType === "history" ||
      (this.record.type === "tech_record" &&
        this.user?.type?.name === "technician")
    );
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  isWhiteListed() {
    // Simply allow optoms at MCES & Sunbury to send patients to other team members.
    if (this.user) {
      return [MCES_CLINIC_ID, SUNBURY_CLINIC_ID].includes(this.user.clinic_id);
    }
  }
}

export class ButtonRecordSaveSignComponent
  implements angular.IComponentOptions
{
  static selector = "buttonRecordSaveSign";
  static template = require("./button-record-save-sign.html");
  static controller = ButtonRecordSaveSignController;
  static bindings = {
    completeRecord: "&",
    disableSave: "<?",
    disableSign: "<?",
    record: "<",
    recordType: "<?",
    reopenInProgress: "<",
    reopenRecord: "&",
    save: "&",
    saveInProgress: "<",
    signInProgress: "<",
    signRecord: "&",
    user: "<",
    patient: "<",
  };
}
