/**
 * Import the Component styles
 */
import { IGlCardState } from "app/core/components/ui/card/card";
import { AccessLockService } from "app/core/services/access-lock.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { IConsolidatedInjection } from "app/core/services/injection-helper/injection-helper.service";
import { PageTitleService } from "app/core/services/page-title.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import {
  SegmentHelperService,
  Segments,
} from "app/core/services/segment-helper.service";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { isNil } from "lodash";
import { AccessLock } from "models/access-lock.model";
import { IGlFormMode } from "models/gl-form-mode";
import {
  IGlInjectionRecord,
  IGlInjectionRecordData,
} from "../../../models/injection";
import { Letter } from "../../../models/letter.model";
import {
  GlPatientRecordWorkflowState,
  PatientRecord,
} from "../../../models/patient-record.model";
import { User } from "../../../models/user.model";
import { GlModelService } from "../../core/services/gl-model.service";
import { LetterService } from "../../core/services/letter.service";
import { PatientClinicService } from "../../core/services/patient-clinic.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import "./injections.scss";

const DEFAULT_GL_CARD_STATE: IGlCardState = {
  collapsed: false,
  isEditable: false,
  mode: "display",
  leftEnabled: true,
  rightEnabled: true,
};

// eslint-disable-next-line
function glCardStateFactory(state: Partial<IGlCardState>) {
  return { ...DEFAULT_GL_CARD_STATE, ...state };
}

type GlCardStates = { [key in Segments]: Partial<IGlCardState> };

const DEFAULT_SEGMENT_STATES: Partial<GlCardStates> = {
  notes: { ...DEFAULT_GL_CARD_STATE },
  info: { ...DEFAULT_GL_CARD_STATE },
  providers: { ...DEFAULT_GL_CARD_STATE },
  medicalHistory: { ...DEFAULT_GL_CARD_STATE },
  externalProcedures: { ...DEFAULT_GL_CARD_STATE },
  inHouseProcedures: { ...DEFAULT_GL_CARD_STATE },
  management: { ...DEFAULT_GL_CARD_STATE },
  letters: { ...DEFAULT_GL_CARD_STATE },
  dayProcedures: { ...DEFAULT_GL_CARD_STATE },
  drugs: { ...DEFAULT_GL_CARD_STATE },
  prescriptions: { ...DEFAULT_GL_CARD_STATE },
};

const DEFAULT_TECHNICIAN_SEGMENT_COLLAPSED: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  letters: { collapsed: true },
  externalProcedures: { collapsed: true },
  inHouseProcedures: { collapsed: true },
  management: { collapsed: true, mode: "display" },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  info: { collapsed: false },
  medicalHistory: { collapsed: false },
};

const DEFAULT_ADMIN_SEGMENT_STATE: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  notes: { collapsed: true },
  info: { collapsed: false },
  providers: { collapsed: false },
  medicalHistory: { collapsed: false },
  letters: { collapsed: false },
  externalProcedures: { collapsed: true },
  inHouseProcedures: { collapsed: true },
  management: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
};

const DEFAULT_OPTOMETRIST_SEGMENT_COLLAPSED: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  notes: { collapsed: false },
  info: { collapsed: false, isEditable: false },
  providers: { collapsed: true, isEditable: false },
  letters: { collapsed: true },
  externalProcedures: { collapsed: false },
  inHouseProcedures: { collapsed: false },
  management: { collapsed: false, mode: "edit" },
  todaysDrops: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  medicalHistory: { collapsed: false },
};

const DEFAULT_OPHTHAL_SEGMENT_COLLAPSED: {
  [key in Segments]?: Partial<IGlCardState>;
} = {
  notes: { collapsed: false },
  info: { collapsed: false, isEditable: false },
  providers: { collapsed: true, isEditable: false },
  externalProcedures: { collapsed: false },
  inHouseProcedures: { collapsed: false },
  management: { collapsed: false, mode: "edit" },
  patientUploads: { collapsed: true },
  drugs: { collapsed: false },
  prescriptions: { collapsed: false },
  medicalHistory: { collapsed: false },
};

class InjectionsController
  implements angular.IController, angular.IOnInit, angular.IOnDestroy
{
  user: User;
  patientAllLetters: Letter[];
  patient: IPatientResource;
  patientId = this.$stateParams.patientId as number;
  debug: boolean = this.$stateParams.debug;
  leftEyeInjections: IConsolidatedInjection[];
  rightEyeInjections: IConsolidatedInjection[];
  recordId = this.$stateParams.recordId as number;
  records: PatientRecord[];
  record: IGlInjectionRecord = {
    data: { left: {}, right: {} },
    workflow_state: "injection",
  } as IGlInjectionRecord;

  segmentStates: Partial<GlCardStates> = {};

  createNewRecordInProgress = false;
  createNewTechRecordInProgress = false;
  accessLockForRecord: AccessLock;

  injectionForm: angular.IFormController;
  saveInProgress = false;
  signInProgress = false;
  lockInProgress = false;
  reopenInProgress = false;
  disableAccessLockInProgress = false;
  saveAndProgressInProgress = false;

  recordErrorMessages = this.ErrorAppendix.getRecordsErrorMessages();

  constructor(
    private $q: angular.IQService,
    private $state: angular.ui.IStateService,
    private $stateParams: angular.ui.IStateParamsService,
    private $timeout: angular.ITimeoutService,
    private $window: angular.IWindowService,
    private AccessLockService: AccessLockService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    private PageTitleService: PageTitleService,
    private PatientProcedureService: PatientProcedureService,
    private PatientRecordService: PatientRecordService,
    private PatientService: PatientService,
    private PrescriptionsService: PrescriptionsService,
    private UserFavouriteDrugsService: UserFavouriteDrugsService,
    private SegmentHelperService: SegmentHelperService,
    public PatientClinicService: PatientClinicService,
    public toastr: angular.toastr.IToastrService,
    public ErrorAppendix: ErrorAppendix
  ) {
    "ngInject";
  }

  $onInit() {
    this.patient = this.PatientService.get(this.patientId);
    this.patient.$promise.then((patient) =>
      this.PageTitleService.set(
        `${patient.data.first_name} ${patient.data.last_name} - Complete Injection - GlaucoNet`
      )
    );

    this.LetterService.init(this.patientId).then(
      (letters) => (this.patientAllLetters = letters)
    );

    const getRecordHistoryPromise =
      this.PatientRecordService.getRecordHistoryForUser(this.patientId);

    const patientProceduresPromise = this.updatePatientProcedures(
      this.patientId
    );
    const patientClinicsPromise = this.PatientClinicService.initForPatient({
      patientId: this.patientId,
    });
    const accessLockPromise = this.AccessLockService.initPatientLock({
      recordId: this.recordId,
      onBeforeUnload: this.onBeforeUnload.bind(this),
    }).catch(() => undefined);

    const patientPrescriptionsPromise = this.updatePatientPrescriptions(
      this.patientId
    );

    const userFavouriteDrugsPromise =
      this.updatePrescriberFavouritesCombinedHandler(this.user.id);

    let getRecordPromise: angular.IPromise<
      void | IGlInjectionRecord | PatientRecord
    > = this.$q.resolve();

    if (this.recordId != null) {
      getRecordPromise = this.PatientRecordService.get(
        this.patientId,
        this.recordId
      ).then((record) => {
        const injection = isInjectionRecord(record);
        this.record = injection;
        // make sure this injection data has keys for both right and left eyes
        // just in case
        this.record.data = {
          left: {} as IGlInjectionRecordData,
          right: {} as IGlInjectionRecordData,
          ...this.record.data,
        };
        return injection;
      });
    }

    this.$q
      .all([
        this.patient.$promise,
        getRecordHistoryPromise,
        getRecordPromise,
        patientProceduresPromise,
        patientClinicsPromise,
        accessLockPromise,
        patientPrescriptionsPromise,
        userFavouriteDrugsPromise,
      ])
      .then(
        ([
          // maintain attributes in case needed
          /* eslint-disable */
          patient,
          recordHistory,
          injection,
          procedures,
          patientClinics,
          accessLock,
          /* eslint-enable */
        ]) => {
          this.records = recordHistory;
          this.GlModelService.setRecordHistory(recordHistory);

          this.setInitialCardStates(this.record);

          const consolidatedInjections =
            this.PatientProcedureService.getConsolidatedInjections();
          this.leftEyeInjections = consolidatedInjections.filter(
            (injection) => {
              return injection.currentCycle.data.eye === "left";
            }
          );

          this.rightEyeInjections = consolidatedInjections.filter(
            (injection) => {
              return injection.currentCycle.data.eye === "right";
            }
          );

          this.accessLockForRecord = accessLock;
        }
      )
      .catch((error) => {
        console.error("Record Page - Error loading details", error);
        this.toastr.error("Unable to load patient details");
        this.$state.go("main.dashboard");
      })
      .finally(() => {
        //
      });
  }

  $onDestroy() {
    // clear any saved patient procedures on destroy
    this.PatientProcedureService.reset();
    this.PrescriptionsService.reset();
  }

  uiCanExit() {
    if (
      this.injectionForm.$dirty &&
      !this.$window.confirm(
        "There are unsaved changes, are you sure you want to exit without saving?"
      )
    ) {
      return false;
    }
    // this callback can be used to release any record locks
    return this.accessLockForRecord
      ? this.AccessLockService.clearPatientLock()
      : true;
  }

  onBeforeUnload() {
    if (this.injectionForm?.$dirty) {
      return "Are you sure you want to close this window and discard any changes?";
    }
  }

  recordHistory() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  getConsolidatedRecord() {
    return this.GlModelService.consolidatedRecordHistory;
  }

  userHasAccessLockForRecord() {
    return !!this.accessLockForRecord;
  }

  isSigned() {
    return this.record?.data_status === "SIGNED";
  }

  completeRecord() {
    this.record.status = "COMPLETE";
    return this.saveAndProgressClicked();
  }

  saveAndProgressClicked(nextWorkflowState?: GlPatientRecordWorkflowState) {
    this.record.workflow_state = nextWorkflowState;
    return this.$q
      .resolve()
      .then(() => {
        this.saveAndProgressInProgress = true;
        return this.PatientRecordService.updateAndSetPractitioner(
          this.record,
          this.user
        );
      })
      .then(() => this.setFormPristine())
      .then(() => {
        // the record is save, so redirect back to the dashboard
        this.toastr.success(
          "Successfully saved injection record, re-directing to dashboard..."
        );
        return this.$state.go("main.dashboard");
      })
      .catch((error) => {
        console.error("Error in saveAndProgressClicked saving record", error);
        this.toastr.error(this.recordErrorMessages.save.error_try_again);
      })
      .finally(() => {
        this.saveAndProgressInProgress = false;
      });
  }

  save() {
    this.saveInProgress = true;
    if ("id" in this.record) {
      return this.PatientRecordService.update(this.record as any)
        .then((record) => {
          this.toastr.success("Successfully saved injection record!");
          const injection = isInjectionRecord(record);
          this.record = injection;
        })
        .catch(() => {
          this.toastr.error(this.recordErrorMessages.save.error_try_again);
        })
        .finally(() => {
          this.saveInProgress = false;
          // this.setAutoSaveTimeout();
        });
    } else {
      return this.PatientRecordService.createInjection(this.patientId, {
        record: this.record,
      })
        .then((record) => {
          this.toastr.success(
            "Successfully created injection record, redirecting now..."
          );
          const injection = isInjectionRecord(record);
          this.record = injection;
          this.recordId = injection.id;
          this.$state.go(
            "main.injections",
            {
              patient_id: this.patientId,
              record_id: this.recordId,
            },
            { notify: false, reload: false }
          );
        })
        .catch(() => {
          this.toastr.error(
            this.recordErrorMessages.injection.create.error_try_again
          );
        })
        .finally(() => {
          this.saveInProgress = false;
          // this.setAutoSaveTimeout();
        });
    }
  }

  signRecord() {
    this.$q
      .resolve()
      .then(() => {
        this.signInProgress = true;
        if (!this.record.id) {
          return this.PatientRecordService.createInjection(this.patientId, {
            record: this.record,
          }).then((record) => (this.record = record as IGlInjectionRecord));
        }
      })
      .then(() => {
        return this.PatientRecordService.sign({
          record: this.record as any,
        });
      })
      .then((record) => {
        this.toastr.success("Successfully signed injection record!");
        this.record = isInjectionRecord(record);
        this.injectionForm.$setPristine();
      })
      .catch((err): any => {
        console.error(err);
        this.toastr.error(this.recordErrorMessages.sign.error_try_again);
      })
      .finally(() => {
        this.signInProgress = false;
      });
  }

  reopenRecord() {
    this.reopenInProgress = true;
    this.PatientRecordService.reopen(this.record as any)
      .then((record) => {
        this.record = isInjectionRecord(record);
        this.toastr.success("Successfully re-opened injection record!");
      })
      .catch(() => {
        this.toastr.error(this.recordErrorMessages.re_open.error_try_again);
      })
      .finally(() => (this.reopenInProgress = false));
  }

  overrideAccessLock() {
    return this.$q
      .resolve()
      .then(() => {
        this.disableAccessLockInProgress = true;
        return this.AccessLockService.disablePageLock({
          recordId: this.recordId,
          force: true,
        });
      })
      .then(() => {
        this.toastr.success("Successfully overriden access lock!");
        return this.$state.reload();
      })
      .catch(() => {
        this.toastr.error(
          this.recordErrorMessages.access_lock.override.error_try_again
        );
      })
      .finally(() => (this.disableAccessLockInProgress = false));
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }

  canEditRecord() {
    return !this.recordIsSigned() && this.userHasAccessLockForRecord();
  }

  // clinic
  fromSameClinic() {
    return !!(
      this.record &&
      this.user &&
      this.record.clinic?.id === this.user.clinic.id
    );
  }

  // SEGEMENTS

  // CONSIDERATION: move all segmenet related functions and attributes into
  // a single class that can be extended from to avoid duplicate code
  setInitialCardStates(record: PatientRecord) {
    let segmentDefaultsForCurrentUser = DEFAULT_TECHNICIAN_SEGMENT_COLLAPSED;
    if (this.user.type.name === "ophthalmologist") {
      segmentDefaultsForCurrentUser = DEFAULT_OPHTHAL_SEGMENT_COLLAPSED;
    } else if (this.user.type.name === "optometrist") {
      segmentDefaultsForCurrentUser = DEFAULT_OPTOMETRIST_SEGMENT_COLLAPSED;
    } else if (this.user.type.name === "administrator") {
      segmentDefaultsForCurrentUser = DEFAULT_ADMIN_SEGMENT_STATE;
    }
    // for each segment work out what the current state should be
    this.segmentStates = Object.keys(DEFAULT_SEGMENT_STATES).reduce(
      (segmentState, segment) => {
        // check if this record has data for the given segment
        const recordHasDataForSegment =
          this.SegmentHelperService.recordHasSegment(record.data, segment);
        const collapsed = recordHasDataForSegment
          ? false
          : segmentDefaultsForCurrentUser[segment]?.collapsed;
        // the segment state is a combination of
        // the default segment state
        // the segmentDefaults for the current user type
        // and if there is any data in the record for the current segment
        segmentState[segment] = {
          ...DEFAULT_SEGMENT_STATES[segment],
          ...segmentDefaultsForCurrentUser[segment],
          collapsed,
        };
        if (this.canEditRecord()) {
          segmentState[segment] = {
            ...segmentState[segment],
            isEditable: false, //recordHasDataForSegment
          };
        } else {
          segmentState[segment] = {
            ...segmentState[segment],
            isEditable: false,
            mode: "display",
          };
        }

        return segmentState;
      },
      {}
    );
  }

  getCollapsed(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return state.collapsed;
  }

  toggleCollapsed(segment: Segments) {
    this.segmentStates[segment].collapsed =
      !this.segmentStates[segment].collapsed;
  }

  getDisplayMode(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return !this.fromSameClinic() || this.recordIsSigned()
      ? "display"
      : state.mode;
  }

  setDisplayMode(segment: Segments, displayMode: IGlFormMode) {
    const state = this.segmentStates[segment];
    if (state) {
      state.mode = displayMode;
      state.collapsed = false;
      // set all the other modes to display
      if (displayMode === "edit") {
        this.defaultOtherSegmentsToEdit(segment);
      }
    }
  }

  getIsEditable(segment: Segments) {
    const state = this.segmentStates[segment] || DEFAULT_GL_CARD_STATE;
    return !this.fromSameClinic() ? "false" : state.isEditable;
  }

  setIsEditable(segment: Segments, isEditable: boolean) {
    const state = this.segmentStates[segment];
    if (state) {
      state.isEditable = isEditable;
      if (state.isEditable) {
        // when setting isEditable to true, default to edit mode
        this.setDisplayMode(segment, "edit");
        state.collapsed = false;
      } else {
        this.setDisplayMode(segment, "display");
      }
    }
  }

  updatePatientPrescriptions(patientId: number) {
    if (patientId !== undefined) {
      return this.PrescriptionsService.fetchPrescriptions(patientId);
    }
  }

  updatePatientProcedures(patientId: number) {
    if (patientId !== undefined) {
      return this.PatientProcedureService.getAllForPatient({
        patientId: this.patientId,
      });
    }
  }

  // FAVOURITE DRUGS
  updatePrescriberFavouriteDrugs(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugs(userId);
    }
  }

  updatePrescriberFavouriteDrugGroups(userId: number) {
    if (!isNil(userId)) {
      return this.UserFavouriteDrugsService.fetchUserFavouriteDrugGroups(
        userId
      );
    }
  }

  updatePrescriberFavouritesCombinedHandler(userId: number) {
    this.updatePrescriberFavouriteDrugs(userId);
    this.updatePrescriberFavouriteDrugGroups(userId);
  }

  // This sets the form to pristine after a short delay to allow gl-model to set
  // any defaults for fields
  private setFormPristine() {
    return this.$timeout(250).then(() => this.injectionForm?.$setPristine());
  }

  private defaultOtherSegmentsToEdit(selectedSegment: string) {
    Object.keys(this.segmentStates).forEach((segmentName) => {
      if (
        this.segmentStates[segmentName].mode === "edit" &&
        ![selectedSegment, "management"].includes(segmentName)
      ) {
        this.segmentStates[segmentName].mode = "display";
      }
    });
  }
}

export class InjectionsPage implements angular.IComponentOptions {
  static selector = "injections";
  static template = require("./injections.html");
  static controller = InjectionsController;
  static bindings = {
    user: "<",
  };
}

function isInjectionRecord(record: IGlInjectionRecord | PatientRecord) {
  return record.type === "procedure" && (record as IGlInjectionRecord);
}
