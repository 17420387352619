export const pipeName = "eyeSide";
export const eyeSide: angular.Injectable<angular.FilterFactory> = () => {
  "ngInject";
  return (input: string, fullWords: boolean = false) => {
    switch (input) {
      case "right":
        return fullWords ? "right eye" : "R/E";
      case "left":
        return fullWords ? "left eye" : "L/E";
      case "both":
        return fullWords ? "both eye" : "B/E";
      default:
        return input;
    }
  };
};
