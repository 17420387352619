import { extend, isFunction } from "angular";
import { GlLetterType, Letter } from "../../../../../models/letter.model";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { Appendix } from "../../../../core/services/appendix";
import { GlModelService } from "../../../../core/services/gl-model.service";
import { LetterService } from "../../../../core/services/letter.service";
import { IGlSignature } from "../../../../core/services/signature-service/signature.service";
import { ReferralLetterController } from "./letter-controller";

export const GL_LETTER_EVENT_UPDATE = "glLetter:updateLetters";
export const GL_LETTER_EVENT_SAVE = "glLetter:saveLetters";

class GlLetterController
  implements angular.IComponentController, angular.IOnChanges, angular.IOnInit
{
  // Inputs
  canDelete: boolean;
  letters: Letter[];
  mode: "edit" | "display" = "edit";
  patient: User;
  record: PatientRecord;
  signature: IGlSignature;
  template: string;
  type: GlLetterType;
  user: User;

  // Outputs
  letterDidChange: ({
    letter,
    notSaved,
  }: {
    letter: string;
    notSaved?: boolean;
  }) => void;

  referralLetter: Letter;
  // This is ngModel letter string.
  compiledTemplate: string = "";
  letterTemplate: string = "";
  // this is a compiled angular template object. Used to compile the template & data into a letter
  angularTemplate: JQLite;
  saveInProgress = false;
  deleteInProgress = false;
  letterForm?: angular.IFormController;

  showLetterTemplate = false;

  constructor(
    public appendix: Appendix,
    private $compile: angular.ICompileService,
    private $scope: angular.IScope,
    private $controller: angular.IControllerService,
    private $timeout: angular.ITimeoutService,
    private GlModelService: GlModelService,
    private LetterService: LetterService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.letters && this.letters) {
      this.referralLetter = this.letters.find((l) => l.type === "referral");
      // if the letter is set, don't automatically compile the new letter
      if (this.referralLetter) {
        this.compiledTemplate = this.referralLetter.compiled_template;
      }
    }
  }

  $onInit() {
    this.$scope.$on(GL_LETTER_EVENT_UPDATE, () =>
      this.letterTemplateDidChange()
    );
    this.$scope.$on(GL_LETTER_EVENT_SAVE, () => this.save());
  }

  letterTemplateDidChange() {
    const template = this.getLetterTemplate();
    if (template) {
      this.compileTemplate(template);
    }
  }

  createLetter() {
    this.showLetterTemplate = true;
    this.compileTemplate(this.template);
  }

  getLetterTemplate() {
    if (!this.template) {
      if (this.referralLetter?.letter_template) {
        this.template = this.referralLetter.letter_template;
      }
    }
    return this.template;
  }

  compileTemplate(templateString: string) {
    const controller = this.$controller(ReferralLetterController);

    controller.record = this.record;
    controller.patient = this.patient;

    controller.user = this.user;
    controller.signature = this.signature;

    const $scope = this.$scope.$new(true);
    extend($scope, { $ctrl: controller });
    // wrap the raw template in a div to get the entire node once compiled
    const template = `<div>${templateString}</div>`;
    this.angularTemplate = this.$compile(template)($scope);
    /**
     * Use $timeout to allow angular digest cycle to complete before the template
     * is fully compiled
     */
    return this.$timeout().then(() => {
      this.compiledTemplate = this.angularTemplate.html();
      if (this.letterForm) {
        this.letterForm.$setDirty();
        this.ngOnChange();
      }
    });
  }

  ngOnChange() {
    if (isFunction(this.letterDidChange)) {
      this.letterDidChange({
        letter: this.compiledTemplate,
        notSaved: this.letterForm.$pristine ? false : true,
      });
    }
  }

  save() {
    this.saveInProgress = true;
    const letter: Partial<Letter> = {
      compiled_template: this.compiledTemplate,
      patient_record_id: this.record.id,
      type: this.type,
      status: "created",
    };
    // for backwards compatibility, only add letter_template if it exists
    if (this.template) {
      letter.letter_template = this.template;
    }
    // if (this.type === "managing_optom" && this.managingOptom) {
    //   letter.clinic_id = this.managingOptom.clinic.id;
    //   letter.recipient_id = this.managingOptom.provider.id;
    // }

    const promise = this.referralLetter
      ? this.LetterService.update({
          ...this.referralLetter,
          compiled_template: this.compiledTemplate,
        })
      : this.LetterService.createForPatient(letter);

    promise
      .then(() => {
        if (this.letterForm) {
          this.letterForm.$setPristine();
        }
        return this.LetterService.fetchAllForPatient();
      })
      .finally(() => (this.saveInProgress = false));
  }

  cancel() {
    this.letterForm.$setPristine();
    this.compiledTemplate = this.referralLetter.compiled_template;
    this.ngOnChange();
  }

  delete() {
    this.deleteInProgress = true;
    this.LetterService.delete(this.referralLetter)
      .then(() => this.LetterService.fetchAllForPatient())
      .finally(() => (this.deleteInProgress = false));
  }

  print(key?: string) {
    this.LetterService.openPrintLetterWindow(
      this.referralLetter.patient_id,
      [this.referralLetter.id],
      true,
      key
    );
  }

  pdfPreview(key?: string) {
    this.LetterService.openPdfLetterWindow(
      this.referralLetter.patient_id,
      [this.referralLetter.id],
      true,
      key
    );
  }

  isEditMode() {
    return this.mode === "edit";
  }
}

export class ReferralLetterComponent implements angular.IComponentOptions {
  static selector = "glReferralLetter";
  static template = require("./referral-letter.component.html");
  static controller = GlLetterController;
  static bindings = {
    canDelete: "<?",
    letters: "<",
    letterDidChange: "&?",
    managingOptom: "<",
    mode: "<?",
    patient: "<",
    record: "<",
    signature: "<",
    template: "<",
    type: "<",
    user: "<",
  };
}
