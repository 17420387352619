import { get, set, cloneDeep } from "lodash";

class CopyButtonNewController implements angular.IController {
  leftSymbol: string = "fa-arrow-left";
  rightSymbol: string = "fa-arrow-right";
  fields: string[];
  leftRecord: any;
  rightRecord: any;

  constructor() {
    "ngInject";
  }

  onClickRight() {
    this.fields.forEach((field) => {
      const fromValue = get(this.rightRecord, field);
      set(this.leftRecord, field, cloneDeep(fromValue));
    });
  }

  onClickLeft() {
    this.fields.forEach((field) => {
      const fromValue = get(this.leftRecord, field);
      set(this.rightRecord, field, cloneDeep(fromValue));
    });
  }
}
export class CopyButtonNew implements angular.IComponentOptions {
  static selector = "buttonCopyNew";
  static transclude = true;
  static bindings = {
    leftRecord: "<",
    rightRecord: "<",
    fields: "<",
    leftSymbol: "<?",
    rightSymbol: "<?",
  };
  static controller = CopyButtonNewController;
  static template = `<p class="m-b-0 p-0">
      <button tabindex="-1"
        tooltip-placement="top"
        uib-tooltip="Copy data from Left to Right"
        class="btn btn-xs m-b-0"
        ng-click="$ctrl.onClickLeft()"
      >
        <i class="fa" ng-class="$ctrl.leftSymbol"></i>
      </button>
      <ng-transclude></ng-transclude>
      <button tabindex="-1"
        tooltip-placement="top"
        uib-tooltip="Copy data from Right to Left"
        class="btn btn-xs m-b-0"
        ng-click="$ctrl.onClickRight()"
      >
        <i class="fa" ng-class="$ctrl.rightSymbol"></i>
      </button>
    </p>`;
}
