import { IOrthancDicomTags } from "app/core/services/orthanc-api/orthanc.models";

export class DicomFieldController implements angular.IController {
  dicomTags: IOrthancDicomTags;
  dicomId: string;

  constructor() {
    "ngInject";
  }
}

export class DicomFieldComponent implements angular.IComponentOptions {
  static selector = "dicomField";
  static template = require("./dicom-field.html");
  static controller = DicomFieldController;
  static bindings = {
    dicomTags: "<",
    dicomId: "<"
  };
}
