import { IGlSide } from "../../../../../models/gl-side.model";
import { isFunction } from "angular";

class GlButtonEyeController implements angular.IController, angular.IOnInit {
  eye: IGlSide;
  ngModelCtrl: angular.INgModelController;
  ngChange: () => void;
  disabled = false;

  constructor() {
    "ngInject";
  }

  $onInit() {
    if (!this.ngModelCtrl) {
      return;
    }
    // hook up this control to the ngModel value
    this.ngModelCtrl.$render = () => {
      this.eye = this.ngModelCtrl.$modelValue;
    };
  }

  valueDidChange() {
    this.ngModelCtrl.$setViewValue(this.eye);
    if (isFunction(this.ngChange)) {
      this.ngChange();
    }
  }
}

export class GlButtonEyeComponent implements angular.IComponentOptions {
  static selector = "glButtonEye";
  static template = require("./gl-button-eye.html");
  static require = {
    ngModelCtrl: "ngModel",
  };
  static controller = GlButtonEyeController;
  static bindings = {
    ngChange: "&?",
    disabled: "<?",
  };
}
