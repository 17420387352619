import { PatientDocument } from "./../../../models/patient-document.model";
import { PatientRecord } from "models/patient-record.model";
import { sortDatesAsc } from "../helpers/date-helper";

export const pipeName = "activeReferral";
export function activeReferral() {
  "ngInject";
  return (
    patientRecords: PatientRecord[],
    patientDocuments: PatientDocument[]
  ) => {
    if (!patientDocuments) {
      return;
    }
    return getActiveReferral(patientRecords, patientDocuments);
  };
}

export function getActiveReferral(
  patientRecords: PatientRecord[],
  patientDocuments: PatientDocument[]
) {
  if (!patientRecords || !patientDocuments) {
    return;
  }

  const patientDocument = patientDocuments.find((d) =>
    d.data.tags?.split(",").find((t) => t.toLowerCase() === "referral")
  );
  if (!patientDocument) {
    return undefined;
  }

  const recordsSinceActiveReferral = patientRecords
    .filter((r) => {
      return (
        new Date(r.appointment_date || r.created_at) >=
        new Date(patientDocument.data.referral_details?.referral_date)
      );
    })
    // eslint-disable-next-line
    .sort((r1, r2) => {
      return sortDatesAsc(
        new Date(r1.appointment_date),
        new Date(r1.appointment_date)
      );
    });

  return getReferralStatus(patientDocument, recordsSinceActiveReferral[0]);
}

function getReferralStatus(
  patientDocument: PatientDocument,
  recordSinceActiveReferral: PatientRecord
) {
  let expiryStatus: string;
  let klass: string;
  if (patientDocument.data.referral_details?.referral_time_frame?.key) {
    const expiryTimeFrame =
      patientDocument.data.referral_details.referral_time_frame.key;
    if (expiryTimeFrame === "0") {
      expiryStatus = "No Expiry Date";
    } else if (!recordSinceActiveReferral) {
      expiryStatus = `The referral period of ${+expiryTimeFrame} months has not been initiated`;
    } else {
      const expiryDate = addMonths(
        +expiryTimeFrame,
        new Date(
          recordSinceActiveReferral.appointment_date ||
            recordSinceActiveReferral.created_at
        )
      );
      const expiryDays = Math.ceil(
        (expiryDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
      );

      if (expiryDays > 0) {
        expiryStatus = `Expires in ${expiryDays} days`;
      } else {
        expiryStatus = "Expired";
        klass = "text-danger";
      }

      if (expiryDays > 0 && expiryDays < 14) {
        klass = "text-warning";
      }
    }
    return { url: patientDocument.url, expiryStatus, klass };
  } else {
    return { url: patientDocument.url, expiryStatus, klass };
  }
}

function addMonths(numOfMonths: number, date: Date = new Date()) {
  date.setMonth(date.getMonth() + numOfMonths);
  return date;
}
