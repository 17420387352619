export class SegmentCct implements angular.IComponentOptions {
  static selector = "segmentCct";
  static template = require("./segment-cct.html");
  static bindings = {
    mode: "@?",
    isEditable: "<?",
    record: "<",
    enableLeft: "<",
    enableRight: "<"
  };
}
