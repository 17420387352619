import { AuthService } from "app/core/services/auth.service";
import {
  IGlSignature,
  SignatureService,
} from "app/core/services/signature-service/signature.service";
import { isEmpty, isNil } from "lodash";
import { User } from "models/user.model";
import "./settings-profile.scss";

class SettingsProfilePageController implements angular.IController {
  user: User;
  updateInProgress = false;
  editProfileForm: angular.IFormController;

  // edit signature
  signature: IGlSignature;
  signatureToUpload: any;
  editSignatureForm: angular.IFormController;

  signatureUpdateInProgress: boolean = false;

  constructor(
    private AuthService: AuthService,
    private SignatureService: SignatureService,
    private toastr: angular.toastr.IToastrService,
    public $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  $onInit() {
    if (this.user) {
      this.SignatureService.fetchAll().then(
        ([signature] = []) => (this.signature = signature)
      );
    }
  }

  saveProfile() {
    this.updateInProgress = true;
    return this.AuthService.update(this.user)
      .then((user) => {
        this.user = user;
        this.editProfileForm.$setPristine();
      })
      .catch((error) => {
        console.error({ error });
        this.toastr.error("An error occurred.  Please try again");
      })
      .finally(() => (this.updateInProgress = false));
  }

  // TO DO: sort out character limit for b64
  saveSignature() {
    this.signatureUpdateInProgress = true;
    const signatureToUse: string =
      !isEmpty(this.signatureToUpload) && !isNil(this.signatureToUpload)
        ? this.signatureToUpload
        : this.signature.image;
    return this.SignatureService.update({
      ...this.signature,
      image: signatureToUse,
    })
      .then((signature) => {
        this.signature = signature;
        this.signatureToUpload = null;
        this.editSignatureForm.$setPristine();
      })
      .catch((error) => {
        console.error({ error });
        this.toastr.error("An error occurred.  Please try again");
      })
      .finally(() => (this.signatureUpdateInProgress = false));
  }

  // remove uploaded file
  removeUploadedFile() {
    const fileInput = document.getElementById(
      "signature-upload-input"
    ) as HTMLInputElement;

    // only remove if you can find the element
    if (fileInput) {
      // convert
      fileInput.files = null;
      fileInput.value = null;
      this.signatureToUpload = null;
    }
  }

  // extensions and mime types
  getSignatureFileTypes() {
    return ".png,.jpg,.jpeg,.svg, image/jpeg, image/png, image/jpeg, image/svg+xml";
  }
}

export class SettingsProfilePageComponent implements angular.IComponentOptions {
  static selector = "glSettingsProfilePage";
  static template = require("./settings-profile.html");
  static controller = SettingsProfilePageController;
  static bindings = {
    user: "<",
  };
}
