import { ClinicService } from "../../../../core/services/clinic.service";
import { Clinic } from "../../../../../models/clinic.model";
import { User, IGlUserType } from "../../../../../models/user.model";

class EditUserController implements angular.IController, angular.IOnInit {
  clinic: Clinic;
  staff: User[];
  loadStaffInProgress = false;
  showCreateUserForm = false;
  userTypes: IGlUserType[];
  newUser: Partial<User> = {};
  password: string = null;
  passwordConfirmation: string = null;

  constructor(
    private ClinicService: ClinicService,
    private $window: angular.IWindowService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onInit() {
    this.loadStaff();
    this.ClinicService.getUserTypes().then((types) => (this.userTypes = types));
  }

  loadStaff() {
    this.loadStaffInProgress = true;
    return this.ClinicService.getStaffForClinic(this.clinic)
      .then((users) => (this.staff = this.sortUsersList(users)))
      .finally(() => (this.loadStaffInProgress = false));
  }

  deleteUser(user: User) {
    if (!this.$window.confirm("Are you sure?")) {
      return;
    }
    this.ClinicService.deleteUser(user).then((user) => {
      this.toastr.success(`${user.name} was deleted`);
      return this.loadStaff();
    });
  }

  sortUsersList(users: User[]) {
    return [...users].sort((u1, u2) =>
      u1.data.last_name
        .toLowerCase()
        .localeCompare(u2.data.last_name.toLowerCase())
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  passwordsMatch(password: string) {
    return (
      this.password &&
      this.passwordConfirmation &&
      this.password === this.passwordConfirmation
    );
  }
}

export class EditUserComponent implements angular.IComponentOptions {
  static selector = "clinicEditUser";
  static template = require("./edit-user.html");
  static controller = EditUserController;
  static bindings = {
    clinic: "<",
  };
}
