// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";

/**
 * Import Module Components
 */
import { LoginPage } from "./login";

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("login", {
    url: "/login",
    component: LoginPage.selector,
    data: {
      pageTitle: "Log In",
      specialClass: "gray-bg",
      bodyClass: "gray-bg",
      title: "Login - GlaucoNet",
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.login", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(LoginPage.selector, LoginPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
