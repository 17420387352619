import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { PatientDocument } from "../../../../../models/patient-document.model";
import { Appendix, DOCUMENT_TAGS_V2 } from "../../../services/appendix";
import "./patient-upload-edit.scss";
import angular = require("angular");
import { cloneDeep } from "lodash";
import moment = require("moment");

class PatientUploadEditController
  implements angular.IController, angular.IOnChanges
{
  // @Input
  resolve: { document: PatientDocument };

  documentModel: Pick<PatientDocument, "id" | "data">;

  // uiBootstrap Modal Bindings
  close: (args?: { $value: Partial<PatientDocument> }) => void;

  referralValidTimeFrames = this.appendix.get("referralValidTimeFrames");
  documentTagsV2 = DOCUMENT_TAGS_V2.documents.filter((d) => !d.legacy);
  documentTagsV2Types = Array.from(
    new Set(DOCUMENT_TAGS_V2.documents.map((d) => d.type))
  );

  datePickerIsOpen = false;
  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  selectedTag;

  constructor(public appendix: Appendix) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.resolve?.currentValue?.document) {
      const { id, data, created_at } = this.resolve.document;
      const clonedData = cloneDeep(data);
      if (clonedData.updated_created_date) {
        clonedData.updated_created_date = new Date(data.updated_created_date);
      } else {
        const createdAtDate = moment(created_at).toDate();
        clonedData.updated_created_date = createdAtDate;
      }

      if (clonedData.referral_details?.referral_date) {
        clonedData.referral_details.referral_date = new Date(
          clonedData.referral_details.referral_date
        );
      }

      this.documentModel = { id, data: clonedData };
      this.selectedTag =
        this.documentTagsV2.find((t) => t.name === data.tags) ??
        this.documentTagsV2.find((t) => t.name === "other");
    }
  }

  save() {
    this.close({ $value: this.documentModel });
  }

  cancel() {
    this.close();
  }

  tagDidChange() {
    this.documentModel.data.tags = this.selectedTag.name;
  }
}

export class PatientUploadEditComponent implements angular.IComponentOptions {
  static selector = "patientUploadEdit";
  static template = require("./patient-upload-edit.html");
  static controller = PatientUploadEditController;
  static bindings = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
}
