import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  copy,
  isFunction,
} from "angular";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { defaultsDeep, get, isDate, isEmpty, isNil, map } from "lodash";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { PatientProcedureDrop } from "../../../../../models/patient-procedure";
import { Appendix, IPrescriptionDetail } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

import "./gl-drop.scss";

const DEFAULT_DROP_FREQUENCY = "BD";
const DEFAULT_DROP_QUANTITY = 1;
const DEFAULT_DROP_REPEATS = 5;
const DEFAULT_DROP_DOSE = 1;

/* eslint-disable */
/**
 * @deprecated replaced with gl-drug
 * but can be used as a reference for adding in legacy
 * items
 */
class GlDropController
  extends GlFormController
  implements IComponentController, IOnChanges
{
  drop: PatientProcedureDrop;
  dropModel: PatientProcedureDrop;
  dropInfo: string | IPrescriptionDetail;
  recordId: number;
  dropTypes = this.appendix.getDrops();
  discontinuationReasons = this.appendix.get(
    "treatmentReasonForDiscontinuation"
  );
  dropFrequencyOptions = this.appendix.get("drugFrequency");
  // Datepicker settings
  // Set to track date popup state
  procedureDatePopup = { opened: false };
  procedureEndDatePopup = { opened: false };
  // Toggles the state
  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  showAuthorityField: boolean = false;
  deleteInProgress = false;
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  // to handle adding/removing from favourite groups
  selectedFavouriteGroupOption: string;
  selectedFavouriteGroups: string[] = [];
  favouriteDrugGroups: UserFavouriteDrugGroup[];

  onSave: (arg: { drop: PatientProcedureDrop }) => void;
  onDelete: (arg: { drop: PatientProcedureDrop }) => void;
  onCancel: () => void;

  constructor(
    public appendix: Appendix,
    private PrescriptionsService: PrescriptionsService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.drop && this.drop) {
      this.dropModel = copy(this.drop);
      defaultsDeep(this.dropModel, {
        data: {
          treatment_start_date: new Date(),
          treatment_start_record_id: this.recordId,
          drop_number: 1,
          quantity: 1,
          repeats: DEFAULT_DROP_REPEATS,
          dose: 1,
          favourite: false, // default not a favourite drug
        },
      });
      // convert any treatment_start/end_date to date objects
      const startDate = get(this.dropModel, "data.treatment_start_date");
      const endDate = get(this.dropModel, "data.treatment_end_date");
      const dropNumber = get(this.dropModel, "data.drop_number");
      if (startDate && !isDate(startDate)) {
        this.dropModel.data.treatment_start_date = new Date(startDate);
      }
      if (endDate && !isDate(endDate)) {
        this.dropModel.data.treatment_end_date = new Date(endDate);
      }
      if (dropNumber) {
        this.dropModel.data.drop_number = parseInt(dropNumber, 10);
      }

      if (!isEmpty(this.drop)) {
        this.dropModel.data.name = this.drop.data.name;
      }
      // drop info
      this.dropInfo = this.getDropPrescriptionText(this.dropModel.data);

      // group ids
      const drugGroupIds = get(this.dropModel, "favourite_groups");
      if (!isEmpty(drugGroupIds)) {
        this.selectedFavouriteGroups = map(drugGroupIds, (groupId) => {
          const _found = this.favouriteDrugGroups.find((g) => g.id === groupId);
          return _found?.name;
        });
      }
    }
  }

  // FAVOURITES
  // favouriteGroupOptions
  handleSelectFavouriteGroup(groupName: string, mode: "add" | "remove") {
    if (isNil(groupName)) {
      return;
    }

    // adding works if it just doesnt exist
    // this also accounts for adding via select and duplicates
    if (mode === "add") {
      if (!this.selectedFavouriteGroups.includes(groupName)) {
        this.selectedFavouriteGroups.push(groupName);
      }
    } else {
      // otherwise we remove if manually excluded
      const indexOfSelectedGroupName: number =
        this.selectedFavouriteGroups.findIndex((g) => g === groupName);

      if (indexOfSelectedGroupName >= 0) {
        this.selectedFavouriteGroups.splice(indexOfSelectedGroupName, 1);
      }
    }
  }

  toggleCreateAsFavourite() {
    this.dropModel.data.favourite = !this.dropModel.data.favourite;
    // if deselected, clear all favourites
    if (!this.dropModel.data.favourite) {
      this.selectedFavouriteGroups = [];
    }
  }

  openDatepicker(popup: string) {
    if (popup !== "procedure_end_date") {
      this.procedureDatePopup.opened = true;
    } else {
      this.procedureEndDatePopup.opened = true;
    }
  }

  saveClicked() {
    if (isFunction(this.onSave)) {
      if (this.dropModel.data.one_off) {
        this.dropModel.data.treatment_end_date =
          this.dropModel.data.treatment_start_date;
      }

      // if not a favourite delete
      if (!this.dropModel.data.favourite) {
        delete this.dropModel.favourite_groups;
      } else {
        // otherwise convert to id to apply as an argument
        this.dropModel.favourite_groups = this.selectedFavouriteGroups.map(
          (groupName) => {
            const _found = this.favouriteDrugGroups.find(
              (g) => g.name === groupName
            );
            return _found.id;
          }
        );
      }

      this.onSave({ drop: this.dropModel });
    }
  }

  dropTypeDidChange() {
    // set the defaults given the drop type
    const dropAppendixData = this.dropTypes.find(
      (drop) => drop.name === this.dropModel.data.name
    );

    this.dropModel.data.quantity =
      (dropAppendixData && dropAppendixData.quantity) || DEFAULT_DROP_QUANTITY;
    this.dropModel.data.drop_frequency =
      (dropAppendixData && dropAppendixData.frequency) ||
      DEFAULT_DROP_FREQUENCY;
    this.dropModel.data.instructions = dropAppendixData?.instructions ?? "";
    this.dropModel.data.repeats =
      dropAppendixData?.repeats || DEFAULT_DROP_REPEATS;
    this.dropModel.data.one_off = dropAppendixData?.onceOff ?? false;
    this.dropModel.data.authority_script =
      dropAppendixData?.authorityScript ?? false;
    this.dropModel.data.authority_number =
      dropAppendixData?.streamlinedAuthorityNumber ?? null;
    this.dropModel.data.dose = DEFAULT_DROP_DOSE;

    this.dropInfo = this.getDropPrescriptionText(this.dropModel.data);
  }

  endDateDidChange() {
    // if this drop has an endData - set the record id - otherwise clear it
    if (isDate(this.dropModel.data.treatment_end_date)) {
      this.dropModel.data.treatment_end_record_id = this.recordId;
    } else {
      delete this.dropModel.data.treatment_end_record_id;
    }
  }

  generateAuthorityPrescriptionNumber(prescriptionNumber: number) {
    return this.PrescriptionsService.formatAuthorityPrescriptionNumber(
      prescriptionNumber
    );
  }

  getDropPrescriptionText(drop: GlDrop) {
    return this.appendix.getTreatmentPrescriptionText(drop);
  }
}

export class GlDrop implements IComponentOptions {
  static selector = "glDrop";
  static template = require("./gl-drop.html");
  static controller = GlDropController;
  static bindings = {
    drop: "<",
    favouriteDrugGroups: "<",
    onSave: "&",
    onCancel: "&",
    onDelete: "&",
    mode: "@?",
    recordId: "<",
    isEditable: "<?",
    saveInProgress: "<?",
    deleteInProgress: "<?",
    showAuthorityField: "<?",
  };
}
