import { copy } from "angular";
import { ContactService } from "app/core/services/contact.service";
import { Clinic } from "../../../../../models/clinic.model";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import { User } from "../../../../../models/user.model";
import { ClinicService } from "../../../services/clinic.service";
import { PatientClinicService } from "../../../services/patient-clinic.service";
import { IPatientResource } from "../../../services/patient.service";

interface IGlRegisteredProviderScope extends angular.IScope {
  patientOptomForm: angular.IFormController;
}

class GlRegisteredProviderController
  implements angular.IController, angular.IOnChanges
{
  mode: string;
  patient: IPatientResource;
  provider: PatientClinic;
  modelPatientOptomClinic: Clinic;
  modelPatientOptom: User;
  newContact: any;
  loadingPatientClinics = false;
  searchClinicInProgress = false;
  clinicProviders: User[];
  savePatientProviderInProgress = false;
  deleteProviderInProgress = false;

  constructor(
    private PatientClinicService: PatientClinicService,
    private ClinicService: ClinicService,
    private ContactService: ContactService,
    private $q: angular.IQService,
    private $scope: IGlRegisteredProviderScope
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.provider) {
      this.resetForm();
    }
    if (changes.mode && this.mode === "edit") {
      this.fetchProvidersForSelectedClinic();
    }
  }

  searchClinics(searchString: string) {
    const allClinics = this.ClinicService.getAll();
    return allClinics.$promise.then((clinics) => {
      const normalizedSearchString = searchString.toLowerCase();
      return clinics.filter((c) =>
        c.name.toLowerCase().includes(normalizedSearchString)
      );
    });
  }

  fetchProvidersForSelectedClinic() {
    if (this.modelPatientOptomClinic?.id) {
      return this.ClinicService.getProvidersForClinic(
        this.modelPatientOptomClinic
      ).then((providers) => {
        this.clinicProviders = providers;
        // by default select the first provider
        // check if the current optom is also in this clinic
        const optom = this.clinicProviders.find(
          (provider) =>
            this.modelPatientOptom && this.modelPatientOptom.id === provider.id
        );
        if (this.clinicProviders.length > 0 && !optom) {
          this.modelPatientOptom = this.clinicProviders[0];
        }
      });
    }
  }

  createOrSavePatientOptom() {
    this.convertUserProviderToContact(this.modelPatientOptom).then(() => {
      this.ContactService.createContact(this.newContact);
    });
  }

  convertUserProviderToContact(user: User) {
    this.newContact = {
      clinic_name: "",
      first_name: "",
      email: "",
      last_name: "",
    };
    return this.ClinicService.getClinicDetails(user.clinic_id)
      .then((clinic) => {
        this.newContact.clinic_name = clinic?.name;
      })
      .then(() => {
        this.newContact.first_name = user.data.first_name;
        this.newContact.last_name = user.data.last_name;
        this.newContact.email = user.email;
      });
  }

  savePatientOptom() {
    this.savePatientProviderInProgress = true;
    this.$q
      .resolve()
      .then(() => {
        if (this.provider) {
          // there is a currently registered provider, so delete it first
          return this.PatientClinicService.deleteForPatient(
            this.provider.clinic
          );
        }
      })
      .then(() => {
        this.createOrSavePatientOptom();
        return this.PatientClinicService.updateForPatient(
          this.modelPatientOptomClinic,
          this.modelPatientOptom
        );
      })
      .then(() => this.PatientClinicService.fetchClinicsForPatient())
      .finally(() => (this.savePatientProviderInProgress = false));
  }

  deleteProvider() {
    this.deleteProviderInProgress = true;
    this.PatientClinicService.deleteForPatient(this.provider.clinic)
      .then(() => this.PatientClinicService.fetchClinicsForPatient())
      .finally(() => (this.deleteProviderInProgress = false));
  }

  cancel() {
    this.resetForm();
  }

  resetForm() {
    //
    if (this.provider) {
      this.modelPatientOptom = copy(this.provider.provider);
      this.modelPatientOptomClinic = copy(this.provider.clinic);
      this.fetchProvidersForSelectedClinic();
    } else {
      this.modelPatientOptom = null;
      this.modelPatientOptomClinic = null;
    }
    if (this.$scope.patientOptomForm) {
      this.$scope.patientOptomForm.$setPristine();
    }
  }
}

export class GlPatientRegisteredProvider implements angular.IComponentOptions {
  static selector = "glPatientRegisteredProvider";
  static template = require("./gl-patient-registered-provider.html");
  static controller = GlRegisteredProviderController;
  static require = {
    patientOptomFormCtrl: "?patientOptomForm",
  };
  static bindings = {
    mode: "@",
    patient: "<",
    provider: "<",
  };
}
