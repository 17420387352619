import {
  PatientConsentFormRecord,
  PatientReferral,
} from "models/patient-record.model";
import { GlClinicActiveRecord } from "../../../../../models/clinic-active-records.model";
import { User } from "../../../../../models/user.model";

import "./referral-list.component.scss";

class ReferralListComponentController implements angular.IController {
  // @Input()
  referrals: GlClinicActiveRecord[];
  user: User;

  constructor(private $state: angular.ui.IStateService) {
    "ngInject";
  }

  lockedByAnotherUser(record: GlClinicActiveRecord) {
    return record.lock && record.lock.user_id !== this.user.id;
  }

  getClassForReferral(ar: GlClinicActiveRecord) {
    if (!ar) {
      return;
    }
    const referral = ar.record as PatientReferral;
    switch (referral.referral_status?.status?.key) {
      case "accepted":
        return "referral-accepted";
      case "booked":
        return "referral-booked";
      case "declined":
        return "referral-declined";
      default:
        return "referral-pending";
    }
  }

  openPatientRecord(activeRecord: GlClinicActiveRecord) {
    if (activeRecord.record.type === "virtual_review") {
      this.$state.go("main.virtual-review", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
        "review-record": activeRecord.record.optom_record_id,
      });
    } else if (activeRecord.record.type === "consent_form_cataract") {
      this.$state.go("main.cataract-consent", {
        patientId: activeRecord.patient.id,
        recordId: (activeRecord.record as PatientConsentFormRecord)
          .parent_record_id,
        "form-record": activeRecord.record.id,
      });
    } else if (activeRecord.record.type === "tech_record") {
      this.$state.go("main.record-tech", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
      });
    } else if (activeRecord.record.type === "procedure") {
      this.$state.go("main.injections", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
      });
    } else if (activeRecord.record.type === "referral") {
      this.$state.go("main.referral", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
      });
    } else {
      this.$state.go("main.record", {
        patientId: activeRecord.patient.id,
        recordId: activeRecord.record.id,
        "review-record": activeRecord.record.optom_record_id,
      });
    }
  }
}

export class ReferralListComponent implements angular.IComponentOptions {
  static selector = "glReferralList";
  static template = require("./referral-list.component.html");
  static controller = ReferralListComponentController;
  static bindings = {
    referrals: "<",
    user: "<",
  };
}
