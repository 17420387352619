import * as angular from "angular";
import { moduleName as SettingsPageModule } from "../main.settings/settings.module";
import { GlCustomDrug } from "./components/gl-custom-drug/gl-custom-drug";
import { SettingsCustomDrugs } from "./settings-custom-drugs";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.settings.custom-drugs", {
    url: "/settings/custom-drugs",
    component: SettingsCustomDrugs.selector,
    data: {
      requiresLogin: true,
    },
  });
};

const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.settings.custom-drugs", [
      SettingsPageModule,
      "ui.router",
      "gl.core",
    ])

    /**
     * Register Module Components
     */
    .component(SettingsCustomDrugs.selector, SettingsCustomDrugs)
    .component(GlCustomDrug.selector, GlCustomDrug)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
