// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { moduleName as SettingsPageModule } from "../main.settings/settings.module";
import { GlValidateJsonDirective } from "./directives/gl-validate-json";
import { SettingsPrintPageComponent } from "./settings-print";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.settings.print", {
    url: "/settings/print",
    component: SettingsPrintPageComponent.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.settings.print", [
      SettingsPageModule,
      "ui.router",
      "gl.core",
    ])

    /**
     * Register Module Components
     */
    .component(SettingsPrintPageComponent.selector, SettingsPrintPageComponent)
    .directive(
      GlValidateJsonDirective.selector,
      GlValidateJsonDirective.factory()
    )

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
