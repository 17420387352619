import { isNil } from "lodash";
import { GlAlert, GlAlertLevel } from "models/alert.model";

export interface IGlAlertCategory {
  name: string;
  key: string;
}

const GENERIC_ALERT_LEVEL_VALUES: GlAlertLevel[] = [
  "Low",
  // "Medium",
  "High",
];

// colour coding
const ALERT_LEVEL_CLASS_MAP = {
  low: "color-green-alert", // black for default
  // medium: "color-yellow-alert", // yellow like amber
  high: "color-red-danger", // red severe
};

// different alert category types
const ALERT_CATEGORY_OPTIONS: {
  [key: string]: IGlAlertCategory[];
} = {
  patient: [
    {
      name: "Allergy",
      key: "allergy",
    },
    {
      name: "Other",
      key: "other",
    },
  ],
};

export class AlertHelperService {
  static injectionName = "AlertHelperService";

  constructor() {
    "ngInject";
  }

  getGenericAlertLevelValues() {
    return GENERIC_ALERT_LEVEL_VALUES;
  }

  getAlertLevelClassMapping() {
    return ALERT_LEVEL_CLASS_MAP;
  }

  getAlertCategoryOptionsByKey(key: string) {
    const res: IGlAlertCategory[] = ALERT_CATEGORY_OPTIONS[key];
    return !isNil(res) ? res : [];
  }

  filterAllergyAlerts(alerts: GlAlert[]): GlAlert[] {
    return (alerts ?? []).filter(
      (a) =>
        a.type === "Allergy" ||
        (a?.type === "Other" &&
          a?.type_other?.toLowerCase()?.includes("allergy"))
    );
  }

  // given an allergy message, convert it
  convertAllergyToAlert(allergy: string): GlAlert {
    return {
      message: allergy,
      level: "High",
      type: "Allergy",
    };
  }
}
