import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  IOnChangesObject,
  copy,
  isFunction,
} from "angular";
import {
  ErrorAppendix,
  GlErrorMessage,
} from "app/core/services/error-appendix.service";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { isEmpty, isNil } from "lodash";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { GlStaff } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";
import "./gl-favourite-group.scss";

const NAME_MIN_LENGTH: number = 2;
const NAME_MAX_LENGTH: number = 2;
class GlFavouriteGroupController
  extends GlFormController
  implements IComponentController, IOnChanges
{
  prescriber: GlStaff;

  favouriteGroup: UserFavouriteDrugGroup;
  favouriteGroupModel: UserFavouriteDrugGroup;

  favouriteGroupNameMin: number = NAME_MIN_LENGTH;
  favouriteGroupNameMax: number = NAME_MAX_LENGTH;

  groupSaveErrors: GlErrorMessage[] = [];
  groupSaveWarnings: GlErrorMessage[] = [];

  groupErrorMessages = this.ErrorAppendix.getFavouriteGroupErrorMessage();

  saveFavouriteGroupInProgress: boolean = false;
  deleteFavouriteGroupInProgress: boolean = false;

  onSave: (arg: { group: UserFavouriteDrugGroup }) => void;
  onDelete: (arg: { group: UserFavouriteDrugGroup }) => void;
  onCancel: () => void;

  constructor(
    private UserFavouriteDrugsService: UserFavouriteDrugsService,
    private ErrorAppendix: ErrorAppendix
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes?.favouriteGroup && this.favouriteGroup) {
      this.favouriteGroupModel = copy(this.favouriteGroup);
    }

    if (
      (changes?.favouriteGroup && this?.favouriteGroup) ||
      (changes?.favouriteGroupModel && this?.favouriteGroupModel)
    ) {
      this.handleErrorAndWarningChecks(this.favouriteGroupModel);
    }
  }

  saveClicked() {
    if (isFunction(this.onSave)) {
      this.onSave({ group: this.favouriteGroupModel });
    }
  }

  deleteClicked() {
    if (isFunction(this.onDelete)) {
      this.onDelete({ group: this.favouriteGroupModel });
    }
  }

  cancelClicked() {
    if (isFunction(this.onCancel)) {
      this.onCancel();
    }
  }

  isDeletable() {
    // default global group cannot be deleted at all
    return this?.favouriteGroupModel?.id && this.favouriteGroupModel.id !== 1;
  }

  isSavable() {
    return isEmpty(this.getErrorsByType("save"));
  }

  // ERROR HANDLERS
  handleErrorAndWarningChecks(group: UserFavouriteDrugGroup) {
    this.checkGroupSaveErrors(group);
    // this.checkGroupSaveWarnings(group);
  }

  checkGroupSaveErrors(group: UserFavouriteDrugGroup) {
    const errors: GlErrorMessage[] = [];
    const groupToCheck: UserFavouriteDrugGroup =
      group ?? this.favouriteGroupModel;

    // length constraints
    if (
      isNil(groupToCheck?.name) ||
      groupToCheck?.name?.length < this.favouriteGroupNameMin ||
      groupToCheck?.name?.length > 100
    ) {
      errors.push({
        key: "group",
        type: "save",
        message: this.groupErrorMessages.favourite_group.create.name_length,
      });
    }

    this.groupSaveErrors = errors;
  }

  getErrorsByType(type: string) {
    return this?.groupSaveErrors?.filter((e) => e.type === type) ?? [];
  }

  getWarningsByType(type: string) {
    return this?.groupSaveWarnings?.filter((e) => e.type === type) ?? [];
  }
}

export class GlFavouriteGroup implements IComponentOptions {
  static selector = "glFavouriteGroup";
  static template = require("./gl-favourite-group.html");
  static controller = GlFavouriteGroupController;
  static bindings = {
    prescriber: "<",
    saveFavouriteGroupInProgress: "<",
    favouriteGroup: "<",
    onSave: "&",
    onDelete: "&",
    onCancel: "&",
  };
}
