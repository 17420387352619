import { Dropin } from "braintree-web-drop-in";
import { Subscription } from "../../../models/subscription.model";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import { SubscriptionService } from "../../core/services/subscription.service";

class UserPaymentController implements angular.IController, angular.IOnInit {
  showDropinContainer = true;
  isError = false;
  paymentReady = false;
  cardReady = false;
  buyInProgress = false;
  braintreeInstance: Dropin;
  patient_id: number;
  subscriptionSuccessful = false;
  manualSubscribeInProgress = false;
  subscriptionDetails: Subscription;
  patient: IPatientResource;

  constructor(
    private toastr: angular.toastr.IToastrService,
    private PatientService: PatientService,
    private $stateParams: angular.ui.IStateParamsService,
    private $scope: angular.IScope,
    private SubscriptionService: SubscriptionService
  ) {
    "ngInject";
  }

  ////////////////

  $onInit() {
    this.patient_id = this.$stateParams.patient_id;
    this.patient = this.PatientService.get(this.patient_id);
  }

  subscribe() {
    this.buyInProgress = true;
    this.braintreeInstance
      .requestPaymentMethod()
      .then((payload: any) => {
        // we have a token, so subscribe
        return this.SubscriptionService.create({
          patient_id: this.patient_id,
          token: payload.nonce,
        });
      })
      .then((result) => {
        this.subscriptionDetails = result;
        this.subscriptionSuccessful = true;
      })
      .catch(() => {
        this.toastr.error("Error submitting payment, please try again");
      })
      .finally(() => {
        // required as the braintree callback kicks outside angular zone
        this.$scope.$evalAsync(() => {
          this.buyInProgress = false;
        });
      });
  }

  manualSubscribe() {
    this.manualSubscribeInProgress = true;
    this.SubscriptionService.create({
      patient_id: this.patient_id,
    })
      .then((result) => {
        this.subscriptionDetails = result;
        this.subscriptionSuccessful = true;
      })
      .catch(() => {
        this.toastr.error("Error submitting payment, please try again");
      })
      .finally(() => {
        // required as the braintree callback kicks outside angular zone
        this.$scope.$evalAsync(() => {
          this.manualSubscribeInProgress = false;
        });
      });
  }

  validateCard() {
    this.braintreeInstance
      .requestPaymentMethod()
      .then(() => {
        // console.log("register card", event);
        this.updatePaymentReady(true);
      })
      .catch((err) => {
        console.error("Error occured when validating credit card", err);
        this.toastr.error(
          "Could not validate credit card. Please check the details and try again"
        );
      });
  }

  braintreeOnCreate(instance: Dropin) {
    // Form is not submitted by default when paymentMethodNonceReceived is implemented
    this.braintreeInstance = instance;
    this.setCardButtonState(instance.isPaymentMethodRequestable());
    instance.on("noPaymentMethodRequestable", () => {
      this.updatePaymentReady(false);
      this.updateCardReady(false);
    });
    instance.on("paymentMethodRequestable", (event) => {
      this.setCardButtonState(event.paymentMethodIsSelected);
    });
  }

  setCardButtonState(isPaymentMethodRequestable: boolean) {
    if (isPaymentMethodRequestable) {
      this.updatePaymentReady(true);
    } else {
      this.updatePaymentReady(false);
      this.updateCardReady(true);
    }
  }

  updatePaymentReady(val: boolean) {
    this.$scope.$evalAsync(() => {
      this.paymentReady = val;
    });
  }

  updateCardReady(val: boolean) {
    this.$scope.$evalAsync(() => {
      this.cardReady = val;
    });
  }
}

export class PaymentStepThreeComponent implements angular.IComponentOptions {
  static selector = "paymentStepThree";
  static template = require("./user-payment-step3.html");
  static controller = UserPaymentController;
}
