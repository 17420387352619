/**
 * Import the Component styles
 */
import { AuthService } from "../../core/services/auth.service";
import "./login.scss";

class LoginPageController {
  public email = "";
  public password = "";
  public loginInProgress: boolean = false;

  constructor(
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService,
    private $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  async doLogin() {
    this.loginInProgress = true;
    try {
      await this.AuthService.login(this.email, this.password);
      this.gotoSelectClinic();
    } catch (err) {
      const message = err.data?.message ? err.data.message : err.message;
      this.toastr.error(message, "Login Failed");
    } finally {
      this.loginInProgress = false;
    }
  }

  gotoSelectClinic() {
    this.$state.go("main.select-clinic");
  }
}

export class LoginPage implements angular.IComponentOptions {
  static selector = "login";
  static template = require("./login.html");
  static controller = LoginPageController;
}
