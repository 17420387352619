import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  IOnChangesObject,
  IPromise,
  isFunction,
} from "angular";
import { Appendix } from "app/core/services/appendix";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PdfPrintService } from "app/core/services/pdf-print.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { QzPrinterService } from "app/core/services/qz-printer/qz-printer";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { isEmpty, isNil, map, omit, orderBy } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureDrugSelectionWrapper,
} from "models/patient-procedure";
import { PatientRecord } from "models/patient-record.model";
import {
  GlPrescriptionDrugDataSearchDecorator,
  UserFavouriteDrugGroup,
} from "models/user-favourite-drugs";
import { GlStaff, Patient } from "models/user.model";
import "./patient-drugs.scss";
export class PatientDrugsController
  implements IComponentController, IOnChanges
{
  PRESCRIPTION_CHUNK_SIZE: number =
    this.appendix.getDrugsPerPrescriptionChunk();
  mode: IGlFormMode = "display";
  record: PatientRecord;

  // local
  drops: PatientProcedureDrop[] | [];
  drugs: PatientProcedureDrug[] | [];
  drugToEdit: PatientProcedureDrug | object = undefined;
  // allows for selection reference to singular drug
  displayDrugsCombined: PatientProcedureDrugSelectionWrapper[] | [];

  selectedDrugs: PatientProcedureDrug[] = [];

  favouriteDrugs: PatientProcedureDrugSelectionWrapper[] | [];
  favouriteDrugGroups: UserFavouriteDrugGroup[] | [];
  favouriteDrugGroupToEdit: UserFavouriteDrugGroup | object = undefined;

  // errors
  errorMessages = this.ErrorAppendix.getAllErrorMessages();

  // user stuff
  patient: Patient;
  prescriber: GlStaff;

  // can be re-used for favourites
  createDrugInProgress: boolean = false;
  saveDrugInProgress: boolean = false;
  updateDrugInProgress: boolean = false;
  deleteDrugInProgress: boolean = false;
  searchOrEditDrugInProgress: boolean = false;

  createPrescriptionsSelectedInProgress: boolean = false;
  createPrescriptionsAllInProgress: boolean = false;

  // whether it should be appended to a record or not
  useRecordIdOnPrescribe: boolean = true;

  // display option buttons
  isEditable: boolean = false;
  // allow drug creation or not
  // this is to handle the case with favourite drugs where
  // you can still create drugs in the edit screen
  disableCreate: boolean = false;

  // print mode to hide buttons etc
  printMode: boolean = false;

  onDrugsUpdate: (arg: { patientId: number }) => void;
  onPrescriptionsUpdate: (arg: { patientId: number }) => void;
  onFavouritesUpdate: (arg: { userId: number }) => void;

  constructor(
    public appendix: Appendix,
    private $window: angular.IWindowService,
    private toastr: angular.toastr.IToastrService,
    private ErrorAppendix: ErrorAppendix,
    private PrescriptionsService: PrescriptionsService,
    private PatientProcedureService: PatientProcedureService,
    private PdfPrintService: PdfPrintService,
    public QzPrinterService: QzPrinterService,
    public UserFavouriteDrugsService: UserFavouriteDrugsService
  ) {
    "ngInject";
  }

  $onChanges(changes: IOnChangesObject): void {
    if ((changes?.drops && this?.drops) || (changes?.drugs && this?.drugs)) {
      // combine drugs first
      this.displayDrugsCombined = this.combineDropsAndDrugs(
        this.drops,
        this.drugs
      );

      // then do a spot check for selected drugs that dont exist there
      for (const drug of this.displayDrugsCombined) {
        const isSelected = this.selectedDrugs?.find((d) => d.id === drug.id);
        if (isSelected) {
          drug.selected = true;
        }
      }
    }

    if (changes?.favouriteDrugs && this?.favouriteDrugs) {
      this.favouriteDrugs = this.sortProcedureDrugByDate(this.favouriteDrugs);
    }
  }

  // is print style
  isPrintUrl() {
    return this.PdfPrintService.isPrintUrl();
  }

  // FAVOURITES
  getFavouriteDrugs() {
    return this.favouriteDrugs;
  }

  getFavouriteDrugGroups() {
    return this.favouriteDrugGroups;
  }

  // optoms and ophthals can prescribe only
  canPrescribe() {
    return this.PrescriptionsService.canPrescribe(this.prescriber);
  }

  // UPDATES
  handleDrugsUpdate() {
    if (isFunction(this.onDrugsUpdate)) {
      this.onDrugsUpdate({ patientId: this.patient.id });
    }
  }

  handlePrescriptionsUpdate() {
    if (isFunction(this.onPrescriptionsUpdate)) {
      this.onPrescriptionsUpdate({ patientId: this.patient.id });
    }
  }

  handleFavouritesUpdate() {
    if (isFunction(this.onFavouritesUpdate)) {
      this.onFavouritesUpdate({ userId: this.prescriber.id });
    }
  }

  handleCreatePrescriptionsFromSelection() {
    if (this.selectedDrugs.length) {
      this.createPrescriptionsSelectedInProgress = true;

      // merge both together from an empty array
      const mergedDrugs: PatientProcedureDrug[] = [].concat(this.selectedDrugs);

      // create prescriptions
      this.createPrescriptionsFromDrugs(mergedDrugs).finally(() => {
        this.createPrescriptionsSelectedInProgress = false;
        this.clearSelectedDrugs();
      });
    }
  }

  // creates prescription based on category selected
  handleCreatePrescriptionsFromAll() {
    if (!isNil(this.displayDrugsCombined)) {
      this.createPrescriptionsAllInProgress = true;

      // filter to only drugs without an end date (i.e. active)
      const _drugs = this.displayDrugsCombined.filter(
        (d: PatientProcedureDrugSelectionWrapper) => {
          return !d?.data?.treatment_end_date;
        }
      );
      // then print out
      this.createPrescriptionsFromDrugs(_drugs).finally(() => {
        this.createPrescriptionsAllInProgress = false;
      });
    }
  }

  /**
   * purpose: combine drops and drugs together as one unified
   * interface to display (PatientProcedureDrug)
   *
   * 1. use helper function to concat them
   * 2. order by created_at date field
   * 3. then sort in descending order
   */
  combineDropsAndDrugs(
    drops: PatientProcedureDrop[],
    drugs: PatientProcedureDrug[]
  ) {
    // merge them together
    const merged: PatientProcedureDrug[] =
      this.PatientProcedureService.mergeProcedureDropsLegacyWithDrugs(
        drops,
        drugs
      );

    // order by date and return
    return this.sortProcedureDrugByDate(merged);
  }

  // default is descending
  sortProcedureDrugByDate(
    drugs: PatientProcedureDrug[],
    descending: boolean = true
  ) {
    return orderBy(
      drugs,
      [(d) => new Date(d.created_at), "created_at"],
      descending ? "desc" : "asc"
    );
  }

  // consolidating drops and drugs together
  getDrugsAndDrops() {
    return this.displayDrugsCombined;
  }

  // EDIT FLAG
  isEditMode() {
    return this.mode === "edit";
  }

  isCreateMode() {
    return this.mode === "create";
  }

  // add search result: new one for pbs api
  addDrugFromSearch(drugData: GlPrescriptionDrugDataSearchDecorator) {
    // if an existing favourite template selected, reference it
    let foundFavourite: PatientProcedureDrug;

    // check if selected template references a drug id by chance
    if (!isNil(drugData?.favourite_drug_id)) {
      // find the drug amongst all the favourites including globals
      foundFavourite =
        this.UserFavouriteDrugsService.getFavouriteDrugsWithGlobals().find(
          (d) => d.id === drugData?.favourite_drug_id
        );
    }

    // if we find a match then reference that favourite template instead
    if (foundFavourite) {
      this.drugToEdit = {
        ...foundFavourite,
        data: omit(foundFavourite, [
          "favourite",
          "favourite_drug_id",
          "favourite_groups",
          "queries",
        ]),
      };
    } else {
      // otherwise just use the regular template
      // set to edit
      this.drugToEdit = {
        ...this.drugToEdit,
        // for regular drugs just omit as we need the template only
        data: omit(drugData, [
          "favourite",
          "favourite_drug_id",
          "favourite_groups",
          "queries",
        ]),
      };
    }

    this.toastr.success(`Editing ${drugData.brand_name} below...`);
  }

  // editing
  addDrug() {
    this.drugToEdit = {};
    this.toggleCreateDrug();
  }

  editDrug(index: number) {
    this.drugToEdit = this.displayDrugsCombined[index];
  }

  toggleCreateDrug() {
    this.searchOrEditDrugInProgress = true;
  }

  toggleCancelDrug() {
    this.searchOrEditDrugInProgress = false;
  }

  cancelDrug() {
    this.drugToEdit = undefined; // reset
    this.toggleCancelDrug();
  }

  // to handle the regular drugs toggling thing avoiding dupes
  toggleSelectionChecked(drug: PatientProcedureDrug, state: boolean = false) {
    const index: number = this.displayDrugsCombined.findIndex(
      (d: PatientProcedureDrug) => d.id === drug.id
    );
    if (index !== -1) {
      this.displayDrugsCombined[index].selected = state;
    }
  }

  // toggles
  toggleSelection(drug: PatientProcedureDrug) {
    const indexOfSelectedDrug = this.selectedDrugs.findIndex(
      (d: PatientProcedureDrug) => d.id === drug.id
    );

    if (indexOfSelectedDrug === -1) {
      this.toggleSelectionChecked(drug, true);
      this.selectedDrugs.push(drug);
    } else {
      this.toggleSelectionChecked(drug, false);
      this.selectedDrugs.splice(indexOfSelectedDrug);
    }
  }

  clearSelectedDrugs() {
    // remove
    this.selectedDrugs = [];
    // set the checked state to false to reset
    for (const drug of this.displayDrugsCombined) {
      drug.selected = false;
    }
  }

  // if exists means that its selected
  checkIfDrugSelected(drug: PatientProcedureDrug) {
    return this.selectedDrugs.find((d) => d.id === drug.id);
  }

  // SAVES
  handleSaveDrug(drug: PatientProcedureDrug) {
    // a favourite is decided when its toggled and
    // user_id is assigned to the prescriber
    if (drug.data.favourite && drug.user_id === this.prescriber.id) {
      return this.saveDrugFavourite(drug);
    }

    this.saveDrugRegular(drug);
  }

  handleCreateDrug(drug: PatientProcedureDrug) {
    this.saveDrugRegular({
      ...drug,
      id: null,
      user_id: this.patient.id,
    });
  }

  // DELETES
  handleDeleteDrug(drug: PatientProcedureDrug) {
    this.deleteDrugRegular(drug);
  }

  // this will push this to the API, same as update
  saveDrugRegular(drug: PatientProcedureDrug) {
    this.saveDrugInProgress = true;
    const savePromise: IPromise<PatientProcedureDrug> =
      this._createDrugSavePromise(drug);

    savePromise
      .then((_drug) => {
        this.toastr.success(
          `Successfully ${_drug?.id ? "saved" : "created"} ${
            _drug.data.brand_name
          }!`
        );
        this.drugToEdit = undefined;
      })
      .catch(() => {
        this.toastr.error(this.errorMessages.drugs.save);
      })
      .finally(() => {
        this.saveDrugInProgress = false;
        this.handleDrugsUpdate();
      });
  }

  saveDrugFavourite(drug: PatientProcedureDrug) {
    // prescriber must be there
    if (isNil(this.prescriber)) {
      return this.toastr.error(this.errorMessages.error.refresh_try_again);
    }

    // favourite drug group must be defined if a favourite
    if (isNil(drug?.favourite_groups) || isEmpty(drug?.favourite_groups)) {
      return this.toastr.error(
        this.errorMessages.favourite.group_selection_empty
      );
    }

    // then execute
    this.saveDrugInProgress = true;
    const savePromise: IPromise<PatientProcedureDrug> =
      this._createFavouriteDrugSavePromise(drug);

    savePromise
      .then(() => {
        this.toastr.success(
          `Successfully ${
            drug?.id ? "updated" : "created"
          } user favourite drug ${drug.data.brand_name}!`
        );
        this.drugToEdit = undefined;
      })
      .catch(() => {
        this.toastr.error(
          `Error ${drug?.id ? "saving" : "creating"} ${
            drug.data.brand_name
          } user favourite drug, please try agian.`
        );
      })
      .finally(() => {
        this.saveDrugInProgress = false;
        this.handleFavouritesUpdate();
      });
  }

  // delete
  deleteDrugRegular(drug: PatientProcedureDrug) {
    const shouldDeleteDrug: boolean = this.$window.confirm(
      `Are you sure you want to delete ${drug.data.brand_name}?`
    );
    if (shouldDeleteDrug) {
      this.deleteDrugInProgress = true;

      this.PatientProcedureService.deleteDrug(this?.record?.id, drug)
        .then(() => {
          this.drugToEdit = undefined;
          this.toastr.success(`Successfully deleted ${drug.data.brand_name}!`);
        })
        .catch(() => {
          this.toastr.error(this.errorMessages.drugs.delete);
        })
        .finally(() => {
          this.deleteDrugInProgress = false;
          this.handleDrugsUpdate();
          // ensure the drug is removed if selected
          if (this.checkIfDrugSelected(drug)) {
            this.toggleSelection(drug);
          }
        });
    }
  }

  // PRESCRIPTIONS
  createPrescriptionsFromDrugs(drugs: PatientProcedureDrug[]) {
    // values (we can typecase directly from parent -> child)
    const chunkedDrugs: PatientProcedureDrug[][] =
      this.PrescriptionsService.chunkProcedureDrugs(
        drugs
      ) as PatientProcedureDrug[][];

    // promise chain, all are chunked and failed ones will not process
    return Promise.all(
      map(chunkedDrugs, (chunk: PatientProcedureDrug[]) =>
        this._createPrescriptionsRecordPromise(chunk)
          .then((response) => {
            // record must be created or else throw an error
            if (!response) {
              return Promise.reject();
            }
            return response;
          })
          .then((prescription) => {
            // send to printer in chunks
            this.toastr.success(
              `Successfully created prescription ${prescription.id}!`
            );
            this.PrescriptionsService.pdf({
              drugs: chunk.map((d: PatientProcedureDrug) => d.data),
              prescriber: this.prescriber,
              patient: this.patient,
              prescription,
            }).then((pdf) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const window = this.$window.open(URL.createObjectURL(pdf));
            });
          })
          .catch(() => {
            this.toastr.error(this.errorMessages.prescription.create.all);
            return;
          })
      )
    ).finally(() => {
      this.handlePrescriptionsUpdate();
    });
  }

  // prescriptions created
  private _createPrescriptionsRecordPromise(drugs: PatientProcedureDrug[]) {
    const authorityDrug: PatientProcedureDrug | null = drugs.find(
      (drug: PatientProcedureDrug) =>
        drug.data.authority_script && !drug.data?.private
    );

    // service
    return this.PrescriptionsService.createPrescription({
      record_id: this.useRecordIdOnPrescribe ? this?.record?.id : null,
      patient_id: this.patient.id,
      treatment_ids: drugs.map((d: PatientProcedureDrug) => d.id),
      data: drugs.map((d) => {
        return {
          ...d.data,
          date_created_at: d.created_at,
          treatment_id: d.id,
        };
      }),
      authority_number: authorityDrug?.data?.treatment_of_code ?? null,
    });
  }

  private _createDrugSavePromise(
    drug: PatientProcedureDrug
  ): IPromise<PatientProcedureDrug> {
    return !isNil(drug?.id)
      ? this.PatientProcedureService.updateDrug(this?.record?.id, drug)
      : this.PatientProcedureService.createDrug(
          this.useRecordIdOnPrescribe ? this?.record?.id : null,
          drug
        );
  }

  private _createFavouriteDrugSavePromise(
    drug: PatientProcedureDrug
  ): IPromise<PatientProcedureDrug> {
    return !isNil(drug?.id)
      ? this.UserFavouriteDrugsService.updateUserFavouriteDrug(
          this.prescriber.id,
          drug,
          {
            drug_id: drug.data.id,
            favourite_group_ids: drug.favourite_groups,
          }
        )
      : this.UserFavouriteDrugsService.createUserFavouriteDrug(
          this.prescriber.id,
          drug,
          {
            drug_id: drug.data.id,
            favourite_group_ids: drug.favourite_groups,
          }
        );
  }
}

export class PatientDrugsComponent implements IComponentOptions {
  static selector = "patientDrugs";
  static template = require("./patient-drugs.html");
  static controller = PatientDrugsController;
  static bindings = {
    mode: "@",
    record: "<",
    drugs: "<",
    drops: "<?",
    favouriteDrugs: "<",
    favouriteDrugGroups: "<",
    prescriber: "<?",
    patient: "<?",
    useRecordIdOnPrescribe: "<?",
    printMode: "<?",
    onDrugsUpdate: "&",
    onPrescriptionsUpdate: "&",
    onFavouritesUpdate: "&",
    isEditable: "<",
    disableCreate: "<?",
  };
}
