/* eslint-disable */
import { isArray } from "angular";
import {
  Appendix,
  IGlOption,
  IGlOptionExtra,
} from "app/core/services/appendix";
import { AuthService } from "app/core/services/auth.service";
import { DiagnosisService } from "app/core/services/diagnosis.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { ValueAutofillService } from "app/core/services/value-autofill/value-autofill.service";
import {
  cloneDeep,
  defaultsDeep,
  isEmpty,
  isNil,
  isObject,
  isUndefined,
  set,
} from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlBilateral,
  GlDiagnosis,
  GlDiagnosisOption,
  PatientRecordData,
} from "models/patient-record.model";
import { PATIENT_RECORD_EVENT_SIGN } from "../../../../../app/pages/main.record/record";
import { GlFormController } from "../../gl-form-controller";

export class BilateralSelectMultipleController
  extends GlFormController
  implements angular.IComponentController, angular.IOnInit
{
  field: PatientRecordData;
  enableRight = false;
  enableLeft = false;
  generalDefaultOption: IGlOption;
  defaultLeft: IGlOption;
  defaultRight: IGlOption;

  copy: boolean;
  optionKey: string;
  options: IGlOption[];
  customOptions: IGlOption[];
  useCustomOptions: boolean = false;

  other: boolean = true;
  otherKey: string;
  key: string;
  path: string;
  legacyKey: string;
  title: string;

  longestArrayNumber: number;

  diagnosisErrors = this.ErrorAppendix.getDiagnosisErrorMessages();
  observationErrors = this.ErrorAppendix.getObservationErrorMessages();

  // form
  bilateralSelectMultipleForm: angular.IFormController;

  // timestamp array
  timestampKeyArray: GlBilateral<number[]> = {};

  constructor(
    private $scope: angular.IScope,
    private PatientRecordService: PatientRecordService,
    private ValueAutofillService: ValueAutofillService,
    private DiagnosisService: DiagnosisService,
    private ErrorAppendix: ErrorAppendix,
    private appendix: Appendix,
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // setup listener
    this.$scope.$on(PATIENT_RECORD_EVENT_SIGN, () => {
      if (this?.bilateralSelectMultipleForm?.$dirty) {
        this.bilateralSelectMultipleForm.$setPristine();
      }
    });

    // initialise options
    if (this.optionKey) {
      this.options = this.appendix.get(this.optionKey);
    } else {
      this.options = this.appendix.get(this.key);
    }
  }

  // eslint-disable-next-line
  $onChanges(changes: angular.IOnChangesObject): void {
    // directive will trigger first before this
    if (this.isEditMode() && this.field) {
      // else defaults for the bilateral field as it
      // needs something to render into
      // gl-model will clear up the null case
      const defaults = {};
      defaults[this.key] = {};

      // use default if specified otherwise null
      if (this.enableLeft) {
        defaults[this.key].left = [this?.getDefaultOptionForSide("left")];
      }
      if (this.enableRight) {
        defaults[this.key].right = [this?.getDefaultOptionForSide("right")];
      }

      defaultsDeep(this.field, defaults);

      // edge case if set by GL MODEL
      // GL-MODEL sets values as an object
      // this converts it into an array
      if (!isNil(this.field[this.key])) {
        if (
          !isArray(this.field[this.key]?.left) &&
          isObject(this.field[this.key]?.left)
        ) {
          this.field[this.key].left = [this.field[this.key].left];
        }
        if (
          !isArray(this.field[this.key]?.right) &&
          isObject(this.field[this.key]?.right)
        ) {
          this.field[this.key].right = [this.field[this.key].right];
        }
      }

      // instantiate array for timestamp reference
      if (isEmpty(this.timestampKeyArray)) {
        this.timestampKeyArray = {
          left: this.field[this.key]?.left?.map(() => null),
          right: this.field[this.key]?.right?.map(() => null),
        };
      }
      // left side
      if (
        changes?.enableLeft &&
        this.enableLeft &&
        !this.timestampKeyArray?.left
      ) {
        this.timestampKeyArray.left = this.field[this.key]?.left?.map(
          () => null
        );
      }

      if (
        changes?.enableRight &&
        this.enableRight &&
        !this.timestampKeyArray?.right
      ) {
        this.timestampKeyArray.right = this.field[this.key]?.right?.map(
          () => null
        );
      }
    }
  }

  // EXPERIMENTAL
  // for autofill before confirming
  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  // array balance
  getBiggestObservationArray() {
    const observations: GlBilateral<IGlOption[]> = this?.field?.[this.key];
    const left = observations?.left ?? [];
    const right = observations?.right ?? [];
    return left.length > right.length ? left : right;
  }

  getPath(side: IGlSideBilateral): string {
    if (this.path) {
      return `${this.path}.${this.key}.${side}`;
    }
    return `${this.key}.${side}`;
  }

  getOtherPath(): string {
    if (this.path) {
      return `${this.path}.${this.otherKey}`;
    }
    return this.otherKey;
  }

  // custom options will only be used if not empty and toggled
  getOptions() {
    return this.useCustomOptions &&
      !isNil(this.customOptions) &&
      !isEmpty(this.customOptions)
      ? this.customOptions
      : this.options;
  }

  // if custom options in use then use custom options
  // else follow gl-model
  getDefaultOptionForSide(side: IGlSideBilateral) {
    //  do left or right as usual and return null otherwise
    return side === "left"
      ? this?.defaultLeft ?? null
      : this?.defaultRight ?? null;
  }

  // description?
  shouldShowDescription(val: IGlOption) {
    if (val) {
      const keyOpt = this.options.find((opt) => opt.key === val.key);
      const shouldShowOther = (keyOpt && keyOpt.showOther) || false;
      return (shouldShowOther || val.key === "other") && this.other === true;
    } else {
      return false;
    }
  }

  // AUTOFILL RELATED
  // same as above but handles multiple selection arrays
  // FOR MULTIPLE REFERENCE WISE
  selectDidChange(
    side: IGlSideBilateral,
    index: number,
    oldObservations: GlBilateral<[IGlOption]>
  ) {
    // if disabled dont bother
    // empty instantiate
    this.selectDidChangeAutofill(side, index, oldObservations);

    // fitler out empty observations
    this._observationsDidChange(side);
  }

  // autofill option for stuff
  selectDidChangeAutofill(
    side: IGlSideBilateral,
    index: number,
    oldObservations: GlBilateral<[IGlOption]>
  ) {
    // IF DISABLED DONT EVEN BOTHER
    if (
      (!this.enableLeft && side === "left") ||
      (!this.enableRight && side === "right")
    ) {
      return;
    }

    // get current value for cross reference
    const previousValue: IGlOption = oldObservations[side][index];
    const currentValue: IGlOption = this.field[this.key][side][index];
    const currentObservations: IGlOption[] = this.field[this.key][side] ?? [];
    // previous and current value check
    const previousValueExtra: IGlOptionExtra = this.appendix.getExtraWhereKey(
      this.key,
      previousValue?.key
    );
    const currentValueExtra: IGlOptionExtra = this.appendix.getExtraWhereKey(
      this.key,
      currentValue?.key
    );

    // CASE 1: PREVIOUS AUTOFILL REVERSE
    // if old value is autofill and new one sint undo first
    if (
      previousValueExtra?.autofill &&
      this.isOptionAutofillActive(previousValue, side, index)
    ) {
      // find autofill key and remove
      this.removeAutofillOption(previousValueExtra, side, index);
    }

    // CASE 2: NON-MULTI-SELECT (single option only)
    // if multi-select disabled and first index
    if (this._sideHasNonMultipleSelectValue(side) && index === 0) {
      // ensure autofill is removed for all observations in the target row
      this.removeAutofillOptionsOnSide(oldObservations[side], side);
      // remove all current observations except the current one
      set(this.field, `${this.key}.${side}`, [cloneDeep(currentValue)]);
      set(this.timestampKeyArray, side, [null]);
      return;
    }

    // CASE 3: is it a duplicate within its own field?
    // fixes edge case where gl model sets it to object
    const existingValues: IGlOption[] = isArray(currentObservations)
      ? (currentObservations ?? [])?.filter(
          (o) => o?.name === currentValue?.name
        )
      : [];
    // duplicate autofill found in observation
    if (existingValues.length > 1 && currentValueExtra?.autofill) {
      return this.toastr.info(
        this.observationErrors.autofill.observation_duplicate
      );
    } else if (
      // this case is for diagnosis
      this.PatientRecordService.checkIfAutofillDiagnosisExists(
        this.field,
        side,
        currentValue
      ) &&
      previousValue
    ) {
      const timestampKey: number =
        this.ValueAutofillService.getKeyTimestampFromIndex({
          timestampArray: this.timestampKeyArray,
          side,
          index,
        });

      this.ValueAutofillService.removeAutofillValueByKeyAndSide({
        parent_key: this.key,
        option_key: currentValue?.key,
        side,
        timestamp_key: timestampKey,
      });

      // this is to prevent screen overload
      // this is to prevent screen overload
      return this.ValueAutofillService.shouldShowAutofillWarnings()
        ? this.toastr.info(this.diagnosisErrors.autofill.diagnosis_exists)
        : null;
    }

    // CASE 4: autofill?
    if (currentValueExtra?.autofill) {
      // add timestamp key
      const timestampKey: number =
        this.ValueAutofillService.setKeyTimestampByIndex({
          timestampArray: this.timestampKeyArray,
          side,
          index,
        });

      // internal key to use for observation side
      // references new value so old value can be replaced
      const _referenceKey: string =
        this.PatientRecordService.generateAutofillReferenceKey({
          parent_key: this.key,
          option_key: currentValue?.key,
          side,
          timestamp_key: timestampKey,
        });

      // save old value to stack
      this.setPreviousStatusToStack(_referenceKey, previousValue);

      // autofill new value
      this.autofillDiagnosis(currentValue, this.key, timestampKey, side);
    }
  }

  // add data
  setPreviousStatusToStack(
    key: string,
    previousData: GlDiagnosisOption | number | string | boolean
  ) {
    this.PatientRecordService.updateChangesStack(key, previousData);
  }

  // undo diagnosis
  undoAutofillDiagnosis(
    diagnosis: IGlOption,
    index: number,
    side: IGlSideBilateral
  ) {
    const foundTimestamp: number =
      this.ValueAutofillService.getKeyTimestampFromIndex({
        timestampArray: this.timestampKeyArray,
        side,
        index,
      });
    // undo
    this.PatientRecordService.undoAutofillFromObservationSide({
      record: this.field,
      parent_key: this.key,
      side,
      option: diagnosis,
      timestamp_key: foundTimestamp,
    });

    // undo for timestamp
    this.ValueAutofillService.removeKeyTimestampByIndex({
      timestampArray: this.timestampKeyArray,
      side,
      index,
    });
  }

  // confirm diagnosis
  acceptAutofillDiagnosis(
    diagnosis: IGlOption,
    index: number,
    side: IGlSideBilateral
  ) {
    const foundTimestamp: number =
      this.ValueAutofillService.getKeyTimestampFromIndex({
        timestampArray: this.timestampKeyArray,
        side,
        index,
      });

    // confirm autofill
    this.PatientRecordService.confirmAutofillDiagnosisFromObservation({
      option: diagnosis,
      parent_key: this.key,
      side,
      timestamp_key: foundTimestamp,
    });
  }

  autofillDiagnosis(
    status: IGlOption,
    key: string,
    timestampKey: number,
    side: IGlSideBilateral
  ) {
    this.PatientRecordService.autofillDiagnosisSide({
      record: this.field,
      side,
      parent_key: key,
      timestamp_key: timestampKey,
      option: {
        name: status.name,
        key: status.key,
        diagnosis: status.name,
      },
    });
  }

  handleOnCopy(
    side: IGlSideBilateral,
    previousRecordData: PatientRecordData,
    index: number
  ) {
    // take the entire bloody array and loop through
    const previousObservations: GlBilateral<[IGlOption]> =
      previousRecordData[this.key];
    const toSide: IGlSideBilateral = side === "right" ? "left" : "right";

    // check if other side's first value has a non-duplicate field
    if (this._sideHasNonMultipleSelectValue(toSide) && index > 0) {
      return this.toastr.error(
        this.observationErrors.selection.multi_select_not_allowed[toSide]
      );
    }

    // handle for specific index
    this.selectDidChange(side, index, previousObservations);
  }

  // copy from one side to other since we doing individual
  copyObservationRowSide(index: number, toSide: IGlSideBilateral) {
    const fromSide: IGlSideBilateral = toSide === "right" ? "left" : "right";
    const fromValue: IGlOption = this.field?.[this.key][fromSide][index];
    const currentObservations: IGlOption[] = this.field[this.key][toSide];

    // CASE COPY OVER
    // check if other side's first value has a non-duplicate field
    if (this._sideHasNonMultipleSelectValue(toSide)) {
      switch (index) {
        // for index 0 copying is freely allowed so continue
        case 0:
          break;
        default:
          // otherwise prevent issues
          return this.toastr.error(
            this.observationErrors.selection.multi_select_not_allowed[toSide]
          );
      }
      // if we are copying a non-multiple-field value over, we need to set
      // the other side to be the exact same
    } else if (this._sideHasNonMultipleSelectValue(fromSide)) {
      // remove autofill and timestamp and leave only one
      this.removeAutofillOptionsOnSide(currentObservations, toSide);
      set(this.field, `${this.key}.${toSide}`, [cloneDeep(fromValue)]);
      set(this.timestampKeyArray, toSide, [null]);
      return;
    }

    // timestamp
    this.ValueAutofillService.clearKeyTimestampByIndex({
      timestampArray: this.timestampKeyArray,
      side: toSide,
      index,
    });

    // value
    set(this.field, `${this.key}.${toSide}.${index}`, cloneDeep(fromValue));
    // set dirty as a value has been changed;
    this.bilateralSelectMultipleForm.$setDirty();
  }

  // ROW HANDLING
  addObservation(
    observationArray: IGlOption[],
    side: IGlSideBilateral,
    index: number
  ) {
    // override by making any non 0 index values just not examined
    const newIndex = index + 1;
    const defaultOption =
      newIndex === 0
        ? (this.appendix.getDefaultKey(this.key) as IGlOption)
        : // : { name: "Not Examined", key: "not-examined" };
          (side === "left" ? this.defaultLeft : this.defaultRight) ?? null;

    // option adding
    observationArray.push(cloneDeep(defaultOption));
    // replicate for duplicated array
    this.ValueAutofillService.addToKeyTimestampSide({
      timestampArray: this.timestampKeyArray,
      side,
      index,
    });

    // mock dirty
    this.bilateralSelectMultipleForm.$setDirty();
  }

  deleteObservation(
    observationArray: IGlOption[],
    side: IGlSideBilateral,
    index: number
  ) {
    // if autofill exists remove
    this.removeAutofillOption(observationArray[index], side, index);
    // splice
    observationArray.splice(index, 1);
    // do same for timestamp array
    this.ValueAutofillService.removeKeyTimestampByIndex({
      timestampArray: this.timestampKeyArray,
      side,
      index,
    });

    // mock dirty
    this.bilateralSelectMultipleForm.$setDirty();

    // double check if both sides are empty in case
    if (
      isUndefined(this.field[this.key].left[index]) &&
      isUndefined(this.field[this.key].right[index])
    ) {
      const otherSide: IGlSideBilateral = side === "left" ? "right" : "left";
      this.field[this.key][otherSide].splice(index, 1);
      this.ValueAutofillService.removeKeyTimestampByIndex({
        timestampArray: this.timestampKeyArray,
        side,
        index,
      });
    }
  }

  // AUTOFILL HELPER
  // remove all autofill options on a given side
  removeAutofillOptionsOnSide(
    observations: IGlOption[],
    targetSide: IGlSideBilateral
  ) {
    // if existing remove
    for (const [index, option] of observations.entries()) {
      if (this.isOptionAutofillActive(option, targetSide, index)) {
        this.undoAutofillDiagnosis(option, index, targetSide);
      }
    }
  }

  // single option
  removeAutofillOption(
    option: IGlOption,
    side: IGlSideBilateral,
    index: number
  ) {
    // only if active
    if (this.isOptionAutofillActive(option, side, index)) {
      // find autofill key
      const diagnosis: GlDiagnosis =
        this.DiagnosisService.getDiagnosisAutofillMapping(option.key);
      // undo for diagnosisSide
      this.PatientRecordService.undoAutofillForDiagnosisSideOnly({
        record: this.field,
        diagnosisKey:
          this.DiagnosisService.convertDiagnosisOptionToKey(diagnosis) ??
          option.key,
        side,
      });

      // timestamp also
      this.ValueAutofillService.clearKeyTimestampByIndex({
        timestampArray: this.timestampKeyArray,
        side,
        index,
      });
    }
  }

  // option autofill active?
  isOptionAutofillActive(
    option: IGlOption,
    side: IGlSideBilateral,
    index: number
  ) {
    // check if this is the second one
    const optionsArray: IGlOption[] = this.field[this.key][side];
    if (
      isNil(optionsArray) ||
      isEmpty(optionsArray) ||
      !isArray(optionsArray)
    ) {
      return false;
    }

    // timestamp key and internal observation key required
    const timestampKey: number =
      this.ValueAutofillService.getKeyTimestampFromIndex({
        timestampArray: this.timestampKeyArray,
        index,
        side,
      });

    const internalObservationKey: string =
      this.ValueAutofillService.generateAutofillReferenceKey({
        parent_key: this.key,
        option_key: option?.key,
        side,
        timestamp_key: timestampKey,
      });

    // otherwise check
    return !isNil(
      this.ValueAutofillService.getAutofillValue(internalObservationKey)
    );
  }

  // is option a multi-select value?
  isOptionMultiSelect(option: IGlOption) {
    return this.appendix.isOptionMultiSelect(this.key, option?.key);
  }

  // for display mode, should it be displayed?
  shouldDisplayOption(option: IGlOption) {
    // as long as it has a name and key isnt part of the hidden ones
    return this.appendix.shouldDisplayOption(option);
  }

  // CLEAR BY ROW INDEX
  /**
    @ignore
    // ONLY USE IF WE INTRODUCE CLEAR FOR BILATERAL SELECT AGAIN
  */
  clearSelections(field: GlBilateral<IGlOption[]>, index: number) {
    if (index >= 0) {
      // otherwise for other ones should always be a remove
      // if it exists
      if (field?.right?.[index]) {
        this.deleteObservation(field.right, "right", index);
      }
      if (field?.left?.[index]) {
        this.deleteObservation(field.left, "left", index);
      }

      // edge case if deleting last row remaining
      if (isEmpty(field?.right?.[index]) && index === 0) {
        set(field, `${this.key}.right.${index}`, [undefined]);
      }
      if (isEmpty(field?.left?.[index]) && index === 0) {
        set(field, `${this.key}.left.${index}`, [undefined]);
      }
    }
  }

  private _observationsDidChange(side: IGlSideBilateral) {
    // left side
    if (!isEmpty(this.field[this.key].left) && side === "left") {
      // gl-model has a tendency to set to an object so this sets this back to
      // nominal
      if (!isArray(this.field[this.key].left)) {
        this.field[this.key].left = [];
      }

      // console.log("left", this.key, this.field[this.key].left);
      // only if not empty or undefined  or first index
      this.field[this.key].left = this.field[this.key].left?.filter(
        // this case allows no option selected
        (o, i) => !isUndefined(o) || i === 0
        // (o, i) => (!isUndefined(o) && !isEmpty(o)) || i === 0
        // (o) => !isNil(o)
      );
      // console.log("left", this.key, this.field[this.key].left);
    }

    // right side
    if (!isEmpty(this.field[this.key].right) && side === "right") {
      // gl-model has a tendency to set to an object so this sets this back to
      // nominal
      if (!isArray(this.field[this.key].right)) {
        this.field[this.key].right = [];
      }
      // console.log("right", this.key, this.field[this.key].left);

      // only if not empty or undefined  or first index
      this.field[this.key].right = this.field[this.key].right?.filter(
        (o, i) => !isUndefined(o) || i === 0
        // (o, i) => (!isUndefined(o) && !isEmpty(o)) || i === 0
        // (o) => !isNil(o)
      );
      // console.log("right", this.key, this.field[this.key].left);
    }
  }

  // helper to identify if observation side has a non-multi-select value
  // where its limited to only one row
  private _sideHasNonMultipleSelectValue(sideToCheck: IGlSideBilateral) {
    // its mostly always the first value
    const sideValue: IGlOption = this.field[this.key][sideToCheck][0];
    // we return the inverse as the function checks if its multi-select
    return !this.isOptionMultiSelect(sideValue);
  }
}

export class BilateralSelectMultiple implements angular.IComponentOptions {
  static selector = "bilateralSelectMultiple";
  static template = require("./bilateral-select-multiple.html");
  static controller = BilateralSelectMultipleController;
  static bindings = {
    key: "@",
    title: "@",
    path: "@",
    mode: "@",

    other: "<?",
    field: "<",
    enableRight: "<",
    enableLeft: "<",

    // toggle state between custom options or regular
    useCustomOptions: "<?",
    customOptions: "<?",

    copy: "<?",
    defaultMode: "@?",
    generalDefaultOption: "<?",
    defaultLeft: "<?",
    defaultRight: "<?",

    otherKey: "@?",
    legacyKey: "@?",
    optionKey: "@",

    isEditable: "<?",
    glRequired: "<?",
    glChange: "&",
  };
}
