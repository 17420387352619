import { IGlApiResponse } from "models/gl-api-response.model";
import { API_PATH_v2 } from "../api-paths";

export interface IGlSignature {
  id: number;
  user_id: number;
  template: string;
  image: string;
  created_at: string;
  updated_at: string;
}

export class SignatureService {
  static injectionName = "SignatureService";

  private cache = this.$cacheFactory.get("$http");

  constructor(
    private $http: angular.IHttpService,
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService
  ) {
    "ngInject";
  }

  fetchAll() {
    return this.$http
      .get<IGlApiResponse<IGlSignature[]>>(this.signatureUrl(), { cache: true })
      .then((response) => response.data.data);
  }

  update(signature: IGlSignature) {
    // on update clear the cache;
    this.cache.remove(this.signatureUrl());
    const promise = signature.id
      ? this.$http.put<IGlApiResponse<IGlSignature>>(
          this.signatureUrl(signature.id),
          signature
        )
      : this.$http.post<IGlApiResponse<IGlSignature>>(
          this.signatureUrl(),
          signature
        );

    return promise.then((response) => response.data.data);
  }

  delete(signatureId: number) {
    this.cache.remove(this.signatureUrl());
    return this.$http.delete(this.signatureUrl(signatureId));
  }

  private signatureUrl(id?: number) {
    const path = `${this.API_URL}${API_PATH_v2}/signatures`;
    return id ? `${path}/${id}` : path;
  }
}
