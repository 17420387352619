import { LetterType } from "../../../models/letter-types.model";
import { API_PATH } from "./api-paths";

export class LetterTypesService {
  static injectionName = "LetterTypesService";
  public ngResource: angular.resource.IResourceClass<
    angular.resource.IResource<LetterType>
  >;
  private cache: angular.ICacheObject;
  private path = `${this.API_URL}${API_PATH}/letter_types`;

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $resource: angular.resource.IResourceService
  ) {
    "ngInject";
    this.ngResource = this.$resource(this.path, undefined, {
      query: {
        method: "GET",
        isArray: true,
        cache: true,
      },
    }) as angular.resource.IResourceClass<
      angular.resource.IResource<LetterType>
    >;
    this.cache = this.$cacheFactory.get("$http");
  }

  // Gets all data for a single patient
  getAll() {
    // Request to the API for the user associated with the token
    return this.ngResource.query();
  }
}
