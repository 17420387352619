import { IHttpPromise, IPromise } from "angular";
import { defaults, isNil } from "lodash";
import { IGlSide } from "models/gl-side.model";
import { IGlInjection } from "models/injection";
import {
  GlBilateral,
  GlDiagnosis,
  PatientRecordData,
} from "models/patient-record.model";
import { GlPrescriptionDrugData } from "models/prescription.model";
import { IGlApiResponse } from "../../../models/gl-api-response.model";
import {
  PatientProcedure,
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
  PatientProcedureInjection,
} from "../../../models/patient-procedure";
import { Patient, User } from "../../../models/user.model";
import { API_PATH, API_PATH_v2 } from "./api-paths";
import { Appendix, IPrescriptionDetail } from "./appendix";
import { DiagnosisService } from "./diagnosis.service";
import { DropSorter } from "./drop-sorter.service";
import {
  IConsolidatedInjection,
  InjectionHelperService,
} from "./injection-helper/injection-helper.service";
import moment = require("moment");

export interface IProceduresResponse {
  laser: PatientProcedureInHouse[];
  surgical: PatientProcedureExternal[];
  drops: PatientProcedureDrop[];
}

const PROCEDURE_TYPE_DROP = "drops";
const PROCEDURE_TYPE_EXTERNAL = "external";
const PROCEDURE_TYPE_IN_HOUSE = "inHouse";
// eslint-disable-next-line
const PROCEDURE_TYPE_INJECTION = "inHouse";
const PROCEDURE_TYPE_DRUGS = "drugs";

export class PatientProcedureService {
  static injectionName = "PatientProcedureService";
  apiBase = `${this.API_URL}${API_PATH}`;
  apiV2Base = `${this.API_URL}${API_PATH_v2}`;

  private drugs: PatientProcedureDrug[] = [];
  private drops: PatientProcedureDrop[] = [];
  private injections: PatientProcedureInjection[] = [];
  private consolidatedInjections: IConsolidatedInjection[] = [];
  private externalProcedures: PatientProcedureExternal[] = [];
  private inHouseProcedures: PatientProcedureInHouse[] = [];
  private patientId: number;

  constructor(
    private $http: angular.IHttpService,
    private API_URL: string,
    private DropSorter: DropSorter,
    private appendix: Appendix,
    private InjectionHelperService: InjectionHelperService,
    private DiagnosisService: DiagnosisService
  ) {
    "ngInject";
  }

  getAllForPatient({
    patient,
    patientId,
  }: {
    patient?: User;
    patientId?: number;
  }) {
    this.patientId = patientId || patient.id;
    return this.$http
      .get<IGlApiResponse<PatientProcedure[]>>(
        `${this.apiV2Base}/patients/${this.patientId}/procedures`
      )
      .then((response) => {
        const procedures = response.data.data;
        this.drops = this.DropSorter.sortDrops(
          procedures.filter((p) => p.type === "drops") as PatientProcedureDrop[]
        );
        this.externalProcedures = procedures
          .filter(
            (p) =>
              p.type === "external" ||
              (["surgical", "laser"].includes(p.type) &&
                p.status !== "complete")
          )
          .sort((a, b) => b.id - a.id) as PatientProcedureExternal[];
        this.inHouseProcedures = procedures
          .filter((p) => p.type === "inHouse" || p.status === "complete")
          .sort((a, b) => b.id - a.id) as PatientProcedureInHouse[];
        this.injections = procedures
          .filter((p) => p.type === "injection")
          .sort((a, b) => b.id - a.id) as PatientProcedureInjection[];
        this.consolidatedInjections =
          this.InjectionHelperService.getConsolidatedInjections(
            this.injections
          );
        // add for drugs
        this.drugs = procedures
          .filter((d) => d.type === "drugs")
          .sort((a, b) => b.id - a.id) as PatientProcedureDrug[];
        return procedures;
      });
  }

  getAllForPatientAsObject({
    patient,
    patientId,
  }: {
    patient?: User;
    patientId?: number;
  }) {
    this.patientId = patientId || patient.id;
    return this.$http
      .get<IGlApiResponse<PatientProcedure[]>>(
        `${this.apiV2Base}/patients/${this.patientId}/procedures`
      )
      .then((response) => {
        const procedures = response.data.data;
        const drops: PatientProcedureDrop[] = this.DropSorter.sortDrops(
          procedures.filter((p) => p.type === "drops") as PatientProcedureDrop[]
        );
        const externalProcedures: PatientProcedureExternal[] = procedures
          .filter(
            (p) =>
              p.type === "external" ||
              (["surgical", "laser"].includes(p.type) &&
                p.status !== "complete")
          )
          .sort((a, b) => b.id - a.id) as PatientProcedureExternal[];
        const inHouseProcedures: PatientProcedureInHouse[] = procedures
          .filter((p) => p.type === "inHouse" || p.status === "complete")
          .sort((a, b) => b.id - a.id) as PatientProcedureInHouse[];
        const injections: PatientProcedureInjection[] = procedures
          .filter((p) => p.type === "injection")
          .sort((a, b) => b.id - a.id) as PatientProcedureInjection[];
        const consolidatedInjections: IConsolidatedInjection[] =
          this.InjectionHelperService.getConsolidatedInjections(
            this.injections
          );
        // add for drugs
        const drugs: PatientProcedureDrug[] = procedures
          .filter((d) => d.type === "drugs")
          .sort((a, b) => b.id - a.id) as PatientProcedureDrug[];

        return {
          drops,
          externalProcedures,
          inHouseProcedures,
          injections,
          consolidatedInjections,
          drugs,
        };
      });
  }

  reset() {
    this.drops = [];
    this.drugs = [];
    this.inHouseProcedures = [];
    this.externalProcedures = [];
    this.patientId = undefined;
  }

  getDrugs() {
    return this.drugs;
  }

  getDrops() {
    return this.drops;
  }

  getExternalProcedures() {
    return this.externalProcedures.sort((ar1, ar2) => {
      const ar1ProcedureData = ar1.procedure_date
        ? new Date(ar1.procedure_date).getTime()
        : Math.max(
            ar1.data.left?.date ? new Date(ar1.data.left?.date).getTime() : 0,
            ar1.data.right?.date ? new Date(ar1.data.right?.date).getTime() : 0
          );

      const ar2ProcedureData = ar2.procedure_date
        ? new Date(ar2.procedure_date).getTime()
        : Math.max(
            ar2.data.left?.date ? new Date(ar2.data.left?.date).getTime() : 0,
            ar2.data.right?.date ? new Date(ar2.data.right?.date).getTime() : 0
          );

      return ar2ProcedureData - ar1ProcedureData;
    });
  }

  getInjections() {
    return this.injections;
  }

  getConsolidatedInjections() {
    return this.consolidatedInjections;
  }

  getRecordInHouseProcedures(
    procedures: PatientProcedureInHouse[],
    recordId: number
  ) {
    //
    return procedures.filter((p) => p.data.completed_in_record_id === recordId);
  }

  getInHouseProcedures() {
    return this.inHouseProcedures.sort((ar1, ar2) => {
      return (
        new Date(ar2.procedure_date).getTime() -
        new Date(ar1.procedure_date).getTime()
      );
    });
  }

  createDrop(recordId: number, procedure: PatientProcedureDrop) {
    defaults(procedure, {
      user_id: this.patientId,
      type: PROCEDURE_TYPE_DROP,
    });

    const createPromise: IPromise<PatientProcedure> = !isNil(recordId)
      ? this.create(recordId, procedure)
      : this.createWithoutRecord(procedure);

    return createPromise.then((drug: PatientProcedureDrug) => {
      this.drugs = [drug, ...this.drugs];
      return drug;
    });
  }

  createExternalProcedure(
    recordId: number,
    procedure: PatientProcedureExternal
  ) {
    defaults(procedure, {
      user_id: this.patientId,
      type: PROCEDURE_TYPE_EXTERNAL,
    });

    if (recordId) {
      return this.create(recordId, procedure).then(
        (surgery: PatientProcedureExternal) => {
          this.externalProcedures = [surgery, ...this.externalProcedures];
          return surgery;
        }
      );
    } else {
      return this.createWithoutRecord(procedure).then(
        (surgery: PatientProcedureExternal) => {
          this.externalProcedures = [surgery, ...this.externalProcedures];
          return surgery;
        }
      );
    }
  }

  createInHouseProcedure(recordId: number, procedure: PatientProcedureInHouse) {
    defaults(procedure, {
      user_id: this.patientId,
      type: PROCEDURE_TYPE_IN_HOUSE,
    });
    if (recordId) {
      return this.create(recordId, procedure).then(
        (laser: PatientProcedureInHouse) => {
          this.inHouseProcedures = [laser, ...this.inHouseProcedures];
          return laser;
        }
      );
    } else {
      return this.createWithoutRecord(procedure).then(
        (laser: PatientProcedureInHouse) => {
          this.inHouseProcedures = [laser, ...this.inHouseProcedures];
          return laser;
        }
      );
    }
  }

  createInjection(
    recordId: number,
    newInjections: { left?: IGlInjection; right?: IGlInjection }
  ) {
    return this.$http
      .post<IGlApiResponse<PatientProcedureInjection[]>>(
        `${this.apiV2Base}/records/${recordId}/injections`,
        newInjections
      )
      .then((response) => response.data.data)
      .then((injections) => {
        this.injections = this.injections.concat(injections);
        this.consolidatedInjections =
          this.InjectionHelperService.getConsolidatedInjections(
            this.injections
          );
        return injections;
      });
  }

  updateDrop(recordId: number, drop: PatientProcedureDrop) {
    const updatePromise: IPromise<PatientProcedure> = !isNil(recordId)
      ? this.update(recordId, drop)
      : this.updateWithoutRecord(drop);

    return updatePromise.then((updatedDrop: PatientProcedureDrop) => {
      const index = this.drops.findIndex((d) => d.id === updatedDrop.id);
      this.drops = [...this.drops];
      this.drops[index] = updatedDrop;
      return updatedDrop;
    });
  }

  updateExternalProcedure(
    recordId: number,
    procedure: PatientProcedureExternal
  ) {
    if (recordId) {
      return this.update(recordId, procedure).then(
        (updatedProc: PatientProcedureExternal) => {
          const index = this.externalProcedures.findIndex(
            (d) => d.id === updatedProc.id
          );
          this.externalProcedures = [...this.externalProcedures];
          this.externalProcedures[index] = updatedProc;
          return updatedProc;
        }
      );
    } else {
      return this.updateWithoutRecord(procedure).then(
        (updatedProc: PatientProcedureExternal) => {
          const index = this.externalProcedures.findIndex(
            (d) => d.id === updatedProc.id
          );
          this.externalProcedures = [...this.externalProcedures];
          this.externalProcedures[index] = updatedProc;
          return updatedProc;
        }
      );
    }
  }

  updateInHouseProcedure(recordId: number, procedure: PatientProcedureInHouse) {
    if (recordId) {
      return this.update(recordId, procedure).then(
        (updatedProc: PatientProcedureInHouse) => {
          const index = this.inHouseProcedures.findIndex(
            (d) => d.id === updatedProc.id
          );
          this.inHouseProcedures = [...this.inHouseProcedures];
          this.inHouseProcedures[index] = updatedProc;
          return updatedProc;
        }
      );
    } else {
      return this.updateWithoutRecord(procedure).then(
        (updatedProc: PatientProcedureInHouse) => {
          const index = this.inHouseProcedures.findIndex(
            (d) => d.id === updatedProc.id
          );
          this.inHouseProcedures = [...this.inHouseProcedures];
          this.inHouseProcedures[index] = updatedProc;
          return updatedProc;
        }
      );
    }
  }

  updateInjection(
    recordId: number,
    injection: Pick<PatientProcedureInjection, "id" | "data">
  ) {
    return this.$http
      .put<PatientProcedure>(
        `${this.apiV2Base}/records/${recordId}/injections/${injection.id}`,
        injection.data
      )
      .then((response) => response.data)
      .then((updatedProc: PatientProcedureInjection) => {
        const index = this.injections.findIndex((d) => d.id === updatedProc.id);
        this.injections = [...this.injections];
        this.injections[index] = updatedProc;
        this.consolidatedInjections =
          this.InjectionHelperService.getConsolidatedInjections(
            this.injections
          );
        return updatedProc;
      });
  }

  deleteDrop(recordId: number, drop: PatientProcedureDrop) {
    return this.delete(recordId, { procedure: drop }).then(() => {
      this.drops = this.drops.filter((d) => d.id !== drop.id);
    });
  }

  deleteExternalProcedure(
    recordId: number,
    procedure: PatientProcedureExternal
  ) {
    if (recordId) {
      return this.delete(recordId, { procedure }).then(() => {
        this.externalProcedures = this.externalProcedures.filter(
          (d) => d.id !== procedure.id
        );
      });
    } else {
      return this.deleteWithoutRecord({ procedure }).then(() => {
        this.externalProcedures = this.externalProcedures.filter(
          (d) => d.id !== procedure.id
        );
      });
    }
  }

  deleteInHouseProcedure(recordId: number, procedure: PatientProcedureInHouse) {
    if (recordId) {
      return this.delete(recordId, { procedure }).then(() => {
        this.inHouseProcedures = this.inHouseProcedures.filter(
          (d) => d.id !== procedure.id
        );
      });
    } else {
      return this.deleteWithoutRecord({ procedure }).then(() => {
        this.inHouseProcedures = this.inHouseProcedures.filter(
          (d) => d.id !== procedure.id
        );
      });
    }
  }

  deleteInjection(recordId: number, injectionId: number) {
    return this.delete(recordId, { id: injectionId }).then(() => {
      this.injections = this.injections.filter((d) => d.id !== injectionId);
      this.consolidatedInjections =
        this.InjectionHelperService.getConsolidatedInjections(this.injections);
    });
  }

  create(recordId: number, procedure: PatientProcedure) {
    return this.$http
      .post<PatientProcedure>(
        `${this.apiV2Base}/records/${recordId}/procedures`,
        procedure
      )
      .then((response) => response.data);
  }

  createWithoutRecord(procedure: PatientProcedure) {
    return this.$http
      .post<PatientProcedure>(`${this.apiV2Base}/procedures`, procedure)
      .then((response) => response.data);
  }

  // Saves a new provider
  update(recordId: number, procedure: PatientProcedure) {
    return this.$http
      .put<PatientProcedure>(
        `${this.apiV2Base}/records/${recordId}/procedures/${procedure.id}`,
        procedure
      )
      .then((response) => response.data);
  }

  updateWithoutRecord(procedure: PatientProcedure) {
    return this.$http
      .put<PatientProcedure>(
        `${this.apiV2Base}/procedures/${procedure.id}`,
        procedure
      )
      .then((response) => response.data);
  }

  // Saves a new provider
  delete(
    recordId: number,
    { id, procedure }: { id?: number; procedure?: PatientProcedure }
  ) {
    const procedureId = id || procedure.id;
    return this.$http.delete<void>(
      `${this.apiV2Base}/records/${recordId}/procedures/${procedureId}`
    );
  }

  deleteWithoutRecord({ procedure }: { procedure?: PatientProcedure }) {
    const procedureId = procedure.id;
    return this.$http.delete<void>(
      `${this.apiV2Base}/procedures/${procedureId}`
    );
  }

  // DRUGS
  createDrug(recordId: number, drug: PatientProcedureDrug) {
    defaults(drug, {
      user_id: this.patientId,
      type: PROCEDURE_TYPE_DRUGS,
    });

    // change to create without record
    const createPromise: IPromise<PatientProcedure> = !isNil(recordId)
      ? this.create(recordId, drug)
      : this.createWithoutRecord(drug);

    return createPromise.then((drug: PatientProcedureDrug) => {
      this.drugs = [drug, ...this.drugs];
      return drug;
    });
  }

  updateDrug(recordId: number, drug: PatientProcedureDrug) {
    // change to update without record
    const updatePromise: IPromise<PatientProcedure> = !isNil(recordId)
      ? this.update(recordId, drug)
      : this.updateWithoutRecord(drug);

    return updatePromise.then((updatedDrug: PatientProcedureDrug) => {
      const index = this.drugs.findIndex((d) => d.id === updatedDrug.id);
      this.drugs = [...this.drugs];
      this.drugs[index] = updatedDrug;
      return updatedDrug;
    });
  }

  deleteDrug(recordId: number, drug: PatientProcedureDrug) {
    const deletePromise: IPromise<void> | IHttpPromise<void> = !isNil(recordId)
      ? this.delete(recordId, { procedure: drug })
      : this.deleteWithoutRecord({ procedure: drug });

    return deletePromise.then(() => {
      this.drops = this.drops.filter((d) => d.id !== drug.id);
    });
  }

  filterDrugsByRecordId(
    recordId: number,
    drugs: PatientProcedureDrug[] = this.drugs
  ) {
    if (!drugs) {
      return [];
    }
    if (!recordId) {
      return drugs;
    }
    return (
      drugs.filter((d: PatientProcedureDrug) => d.record_id === recordId) ?? []
    );
  }

  // interfacers
  mergeProcedureDropsLegacyWithDrugs(
    drops: PatientProcedureDrop[] = [],
    drugs: PatientProcedureDrug[] = []
  ) {
    return this.convertDropsToDrugs(drops).concat(drugs);
  }

  convertDropsToDrugs(drops?: PatientProcedureDrop[]): PatientProcedureDrug[] {
    if (!drops) {
      return [];
    }
    return (drops ?? this.drops ?? []).map((d) => {
      return {
        ...d,
        type: "drugs",
        data: this.convertProcedureDropToDrugDataInterface(d),
      } as PatientProcedureDrug;
    });
  }

  convertProcedureDropToDrugDataInterface(
    drop: PatientProcedureDrop
  ): GlPrescriptionDrugData {
    const description: string | IPrescriptionDetail =
      this.appendix.getTreatmentPrescriptionText(drop.data);

    return {
      brand_name: drop.data.name,
      rstr_flag: drop.data.authority_script ? "A" : null,
      root: this._convertEyeSideHelper(drop.data.eye || drop.data.root),
      dose: drop.data.dose,
      mq: drop.data.drop_number ?? drop.data.quantity,
      quantity: drop.data.drop_number ?? drop.data.quantity,
      quantityOther:
        typeof description === "object" ? description?.quantity ?? null : null,
      repeats: drop.data.repeats,
      frequency: String(drop.data.drop_frequency || drop.data.frequency),
      frequency_other: drop.data.frequency_other ?? null,
      instructions: drop.data.instructions,
      treatment_of_code: drop.data.authority_number ?? null,
      treatment_start_date: drop.data.treatment_start_date,
      treatment_end_date: drop.data.treatment_end_date ?? null,
      discontinuation_reason: drop.data.discontinuation_reason,
      discontinuation_reason_other: drop.data.discontinuation_reason_other,
      one_off: drop.data.one_off ?? false,
      authority_script: drop.data.authority_script && !drop.data?.private,
      private: drop.data?.private ?? false,
      date_created_at: drop.created_at ?? new Date().toISOString(),
      treatment_id: drop.id,
      // we either use the description name or name_other if
      // description doesnt exist
      tpuu_or_mpp_pt:
        (typeof description === "string"
          ? description
          : description?.description) || drop.data.name_other,
      // favourite drugs
      favourite: drop.data.favourite ?? false,
    };
  }

  externalProcedureHasAutofill(procedureName: string) {
    // get  name appendix and name defaults
    const defaultExternalProcedure =
      this.appendix.getDefaultExternalProcedure(procedureName);

    // ignore if nothing
    return !isNil(defaultExternalProcedure);
  }

  createTemporaryExternalProcedure({
    procedureName,
    side,
    patient,
    recordId,
  }: {
    procedureName: string;
    side: IGlSide;
    patient: Patient;
    recordId: number;
  }) {
    const currTime = moment().toISOString();
    // get  name appendix and name defaults
    const defaultExternalProcedure =
      this.appendix.getDefaultExternalProcedure(procedureName);

    // ignore if nothing
    if (!isNil(defaultExternalProcedure)) {
      // temp proedure
      const tempProcedure: PatientProcedureExternal = {
        record_id: recordId,
        user_id: patient.id,
        type: "external",
        data: {
          created_in_record_id: recordId,
          eye: side,
          ...defaultExternalProcedure,
        },
        procedure_date: null,
        status: null,
        created_at: currTime,
        updated_at: null,
        is_temp: true,
      };

      // setup the procedure
      switch (side) {
        case "both":
          tempProcedure.data.left = { complete: false };
          tempProcedure.data.right = { complete: false };
          tempProcedure.data.order = "right_left";
          break;
        case "left":
          tempProcedure.data.left = { complete: false };
          break;
        case "right":
          tempProcedure.data.right = { complete: false };
          break;
        default:
          break;
      }

      return tempProcedure;
    }

    return null;
  }

  // DIAGNOSIS -> PROCEDURE
  createTempExternalProcedureFromDiagnosisArray(
    recordId: number,
    recordData: PatientRecordData,
    diagnosis: string,
    patient: Patient
  ) {
    if (isNil(patient)) {
      return;
    }

    const diagnosisArray: GlBilateral<GlDiagnosis[]> =
      recordData.management?.diagnosis_array;

    // do on either left or right
    const leftHasDiagnosis = diagnosisArray?.left?.find(
      (d) => this.DiagnosisService.getDiagnosis(d) === diagnosis
    );
    const rightHasDiagnosis = diagnosisArray?.right?.find(
      (d) => this.DiagnosisService.getDiagnosis(d) === diagnosis
    );

    let tempProcedure: PatientProcedureExternal = null;
    // if both then create a R/L option
    if (leftHasDiagnosis && rightHasDiagnosis) {
      tempProcedure = this.createTemporaryExternalProcedure({
        procedureName: diagnosis,
        side: "both",
        patient,
        recordId: recordId,
      });
    } else if (leftHasDiagnosis) {
      tempProcedure = this.createTemporaryExternalProcedure({
        procedureName: diagnosis,
        side: "left",
        patient,
        recordId: recordId,
      });
    } else if (rightHasDiagnosis) {
      tempProcedure = this.createTemporaryExternalProcedure({
        procedureName: diagnosis,
        side: "right",
        patient,
        recordId: recordId,
      });
    }

    // if no procedure was found dont bother
    return tempProcedure;
  }

  // procedure exists
  activeExternalProcedureExists(
    externalProcedures: PatientProcedureExternal[],
    procedure: PatientProcedureExternal
  ) {
    // then check by name appendix association, we just
    // need the main operation and as long as its not a temp one
    const foundProcedure = externalProcedures.find(
      (p) => p.data.nameAppendix.key === procedure.data.nameAppendix.key
    );
    return foundProcedure;
  }

  activeExternalProcedureExistsByDiagnosis(
    externalProcedures: PatientProcedureExternal[],
    diagnosisName: string
  ) {
    // then check by name appendix association, we just
    // need the main operation and as long as its not a temp one
    const foundProcedure = externalProcedures.find((p) => {
      return p.data.nameAppendix.name === diagnosisName;
    });
    return foundProcedure;
  }

  getIndexOfExternalProcedure(
    procedure: PatientProcedureExternal,
    recordId: number
  ) {
    // get external procedures
    const externalProcedures: PatientProcedureExternal[] = (
      this.externalProcedures ?? []
    ).filter((p) => this.showProcedureInEditMode(p, recordId));

    // get found
    return externalProcedures.findIndex(
      (p) => p.data.nameAppendix.key === procedure.data.nameAppendix.key
    );
  }

  // taken from patien-procedures
  // line 97ish onwards
  showProcedureInEditMode(
    procedure: PatientProcedureExternal,
    recordId: number
  ) {
    const data = procedure.data;
    // eslint-disable-next-line
    const { left: leftProcedure, right: rightProcedure, eye } = data;
    // if it is not completed then show it
    const completionDateLeft = this.getProcedureDate(procedure, "left");
    const completionDateRight = this.getProcedureDate(procedure, "right");
    // Include this procedure if there is a left procedure and it hasn't been
    // completed, or if there is a right procedure and the right procedure
    // hasn't been completed
    if (
      (eye === "both" && !(completionDateLeft && completionDateRight)) ||
      (eye === "left" && !completionDateLeft) ||
      (eye === "right" && !completionDateRight)
    ) {
      return true;
    }
    // check if this procedure has been created or marked as completed in this record
    if (data.created_in_record_id) {
      // if this is a new procedure, then use the associated record ids to associate
      const associatedRecordIds = [data.created_in_record_id];
      if (data.left) {
        associatedRecordIds.push(data.left.completed_in_record_id);
      }
      if (data.right) {
        associatedRecordIds.push(data.right.completed_in_record_id);
      }
      return associatedRecordIds.includes(recordId);
    } else {
      // this is an old procedure type so just include id
      return false;
    }
  }

  getProcedureDate(procedure: PatientProcedureExternal, side: IGlSide) {
    if (procedure.data?.[side]) {
      return procedure.data[side].date;
    } else {
      return procedure.procedure_date;
    }
  }

  private _convertEyeSideHelper(side: string) {
    switch (side) {
      case "right":
        return "Right Eye";
      case "left":
        return "Left Eye";
      case "both":
        return "Both Eyes";
      default:
        return side;
    }
  }
}
