import { User, GlUserTypeString } from "../../../../../models/user.model";
import { isFunction } from "angular";
import { PatientRecord } from "../../../../../models/patient-record.model";

class TechButtonController implements angular.IController {
  user: User;
  gotoState: ({ state }: { state: string }) => void;
  record: PatientRecord;
  disableSave: boolean = true;

  // button functions
  completeRecord: () => void;
  reopenRecord: () => void;
  signRecord: () => void;
  save: () => void;

  constructor() {
    "ngInject";
  }

  saveAndProgressClicked(state: string) {
    if (isFunction(this.gotoState)) {
      this.gotoState({ state });
    }
  }

  userIsOptometrist() {
    return !!(this.user.type.name === "optometrist");
  }

  userIsAdministrator() {
    return !!this.user && this.user.type.name === "administrator";
  }

  canSign() {
    const allowedUserTypes: GlUserTypeString[] = [
      "ophthalmologist",
      "optometrist",
      "technician",
    ];
    return allowedUserTypes.includes(this.user.type.name);
  }

  fromSameClinic() {
    return (
      !!this.record &&
      !!this.user &&
      this.record.clinic.id === this.user.clinic.id
    );
  }

  canReOpen() {
    const authorizedUserTypes: GlUserTypeString[] = [
      "technician",
      "ophthalmologist",
      "optometrist",
    ];
    return (
      this.fromSameClinic() && authorizedUserTypes.includes(this.user.type.name)
    );
  }

  recordIsSigned() {
    if (!this.record) {
      return true;
    }
    return this.record.data_status === "SIGNED";
  }
}

export class TechButtonProgressComponent implements angular.IComponentOptions {
  static selector = "techButtonProgress";
  static transclude = {
    sendToButtons: "?sendToButtons",
  };
  static template = require("./tech-button-progress.html");
  static controller = TechButtonController;
  static bindings = {
    showLoader: "<",
    gotoState: "&",
    reopenRecord: "&",
    completeRecord: "&",
    signRecord: "&",
    save: "&",
    user: "<",
    record: "<",
    disableSave: "<?",
    lockInProgress: "<",
    reopenInProgress: "<",
    saveInProgress: "<",
  };
}
