import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  IOnChangesObject,
  IWindowService,
  isFunction,
} from "angular";
import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import { isNil, map } from "lodash";
import {
  GlPrescription,
  GlPrescriptionDrugData,
} from "models/prescription.model";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { GlStaff, GlUserTypeString, Patient } from "models/user.model";
import { IGlFormMode } from "../../../../../models/gl-form-mode";
import {
  PatientProcedure,
  PatientProcedureDrop,
  PatientProcedureDrug,
} from "../../../../../models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { QzPrinterService } from "../../../services/qz-printer/qz-printer";

/* eslint-disable */
/**
 * @deprecated replaced with patient-drugs
 */
class PatientDropsController implements IComponentController, IOnChanges {
  mode: IGlFormMode = "display";
  record: PatientRecord;

  dropToEdit: PatientProcedureDrop | {};
  drops: PatientProcedureDrop[];
  dropsForRecord: PatientProcedureDrop[];
  selectedDrops: PatientProcedureDrop[] = [];

  favouriteDrugGroups: UserFavouriteDrugGroup[] | [];

  prescriber: GlStaff;
  patient: Patient;

  saveDropInProgress: boolean = false;
  deleteInProgress: boolean = false;

  showPrintPrescriptions: boolean = true;
  printPdfInProgress: boolean = false;
  printSelectedPdfInProgress: boolean = false;
  printPrescriptionInProgress: boolean = false;
  printSelectedPrescriptionInProgress: boolean = false;

  // whether it should be appended to a record or not
  useRecordIdOnPrescribe: boolean = true;

  show1ClickPrint = this.QzPrinterService.getQzTrayIsInstalled();

  onDrugsUpdate: (arg: { patientId: number }) => void;
  onFavouritesUpdate: (arg: { userId: number }) => void;
  onPrescriptionsUpdate: (arg: { patientId: number }) => void;

  constructor(
    private $window: IWindowService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    private PatientProcedureService: PatientProcedureService,
    private PrescriptionsService: PrescriptionsService,
    private UserFavouriteDrugsService: UserFavouriteDrugsService,
    private toastr: angular.toastr.IToastrService,
    public QzPrinterService: QzPrinterService
  ) {
    "ngInject";
  }

  $onChanges(changes: IOnChangesObject) {
    if ((changes.record || changes.drops) && this.record && this.drops) {
      this.dropsForRecord =
        this.PatientProcedureHelperService.getDropsForRecord(
          this.record,
          this.drops
        );
    }
    // update prescription data
    if (changes?.patient && this.patient) {
      this.handlePrescriptionsUpdate();
    }
  }

  // groups
  getFavouriteDrugGroups() {
    return this.favouriteDrugGroups;
  }

  // handle prescriptions update
  handlePrescriptionsUpdate() {
    if (isFunction(this.onPrescriptionsUpdate) && this.patient.id) {
      this.onPrescriptionsUpdate({ patientId: this.patient.id });
    }
  }

  handleDrugsUpdate() {
    if (isFunction(this.onDrugsUpdate) && this.patient.id) {
      this.onDrugsUpdate({ patientId: this.patient.id });
    }
  }

  handleFavouritesUpdate() {
    if (isFunction(this.onFavouritesUpdate)) {
      this.onFavouritesUpdate({ userId: this.prescriber.id });
    }
  }

  // TOGGLABLES
  isEditMode() {
    return this.mode === "edit";
  }

  isCreateMode() {
    return this.mode === "create";
  }

  // also for html template ng-disabled
  checkIfSelectable(drop: PatientProcedureDrop) {
    const indexOfSelectedDrop = this.selectedDrops.findIndex(
      (d) => d.id === drop.id
    );

    // AUTHORITY
    // if selected drops has auth, stop there as one auth per prescription
    if (
      indexOfSelectedDrop === -1 &&
      this.selectedDrops.find(
        (drop: PatientProcedureDrop) => drop.data.authority_script
      )
    ) {
      return false;
    }

    // if regular ones are selected, disable all auth ones
    if (
      indexOfSelectedDrop === -1 &&
      drop.data.authority_script &&
      this.selectedDrops.length >= 1
    ) {
      return false;
    }

    // more than 5 regular prescriptions selected
    if (indexOfSelectedDrop === -1 && this.selectedDrops.length >= 5) {
      return false;
    }

    return true;
  }

  toggleSelection(drop: PatientProcedureDrop) {
    if (this.selectedDrops.length < 1) {
      this.selectedDrops.push(drop);
    } else {
      // otherwise continue
      const indexOfSelectedDrop = this.selectedDrops.findIndex(
        (d) => d.id === drop.id
      );

      // regular operations
      indexOfSelectedDrop === -1
        ? this.selectedDrops.push(drop)
        : this.selectedDrops.splice(indexOfSelectedDrop, 1);
    }
  }

  // PRINT SECTION
  // by printer
  printPrescription(drop?: PatientProcedureDrop) {
    this.printPrescriptionInProgress = true;
    const drops = drop ? [drop] : this.getAllDropsToPrescribe();

    this._createPrescriptionsFromDrops(drops).finally(() => {
      this.printPrescriptionInProgress = false;
      this.handlePrescriptionsUpdate();
    });
  }

  printSelectedPrescriptions(drop?: PatientProcedureDrop) {
    this.printSelectedPrescriptionInProgress = true;
    const drops: PatientProcedureDrop[] = drop ? [drop] : this.selectedDrops;
    this._createPrescriptionsFromDrops(drops).finally(() => {
      this.printSelectedPrescriptionInProgress = false;
      this.handlePrescriptionsUpdate();
    });
  }

  // PRINT HANDLERS
  // change to promise chain pipe .then, .catch, .finally
  createAndPrintPrescriptionPdf(drop?: PatientProcedureDrop) {
    this.printPdfInProgress = true;
    // all drops
    const drops = drop ? [drop] : this.getAllDropsToPrescribe();

    // send to generalised chunked prescription handler
    this._createPrescriptionsFromDrops(drops).finally(() => {
      this.printPdfInProgress = false;
      this.handlePrescriptionsUpdate();
    });
  }

  // change to promise chain pipe .then, .catch, .finally
  createAndPrintSelectedPrescriptionPdf(drop?: PatientProcedureDrop) {
    this.printSelectedPdfInProgress = true;
    // selected drops only
    const drops = drop ? [drop] : this.selectedDrops;

    // send to generalised chunked prescription handler
    this._createPrescriptionsFromDrops(drops).finally(() => {
      this.printSelectedPdfInProgress = false;
      this.handlePrescriptionsUpdate();
    });
  }

  // MODIFIERS
  addDrop() {
    this.dropToEdit = {};
  }

  editDrop(index: number) {
    this.dropToEdit = this.drops[index];
  }

  canEditDrop(drop: PatientProcedureDrop) {
    return !drop.data.treatment_end_date;
  }

  getDrops() {
    return this.dropsForRecord || this.drops;
  }

  handleSaveDrop(drop: PatientProcedureDrop) {
    if (drop.data.favourite) {
      this.saveDropFavourite(drop);
      return;
    }

    this.saveDrop(drop);
  }

  saveDrop(drop: PatientProcedureDrop) {
    this.saveDropInProgress = true;
    let savePromise;
    if (drop.id) {
      savePromise = this.PatientProcedureService.updateDrop(
        this.record?.id ?? null,
        drop
      );
    } else {
      savePromise = this.PatientProcedureService.createDrop(
        this.record?.id ?? null,
        drop
      );
    }
    savePromise
      .then(() => {
        this.dropToEdit = undefined;
        this.toastr.success(
          `Successfully ${!isNil(drop?.id) ? "updated" : "created"} ${
            drop.data.name
          }`
        );
      })
      .finally(() => {
        this.saveDropInProgress = false;
        this.handleDrugsUpdate();
      });
  }

  saveDropFavourite(drop: PatientProcedureDrop) {
    this.saveDropInProgress = true;

    const isExistingFavourite: boolean = drop.category === "favourite";

    const formattedDropAsDrug: PatientProcedureDrug = {
      ...drop,
      type: "drugs",
      data: this._convertToPrescriptionDrugDataInterface(drop),
    };

    // drops have no drug id as its legacy
    let savePromise = isExistingFavourite
      ? this.UserFavouriteDrugsService.updateUserFavouriteDrug(
          this.prescriber.id,
          formattedDropAsDrug,
          {
            favourite_group_ids: drop.favourite_groups ?? [],
          }
        )
      : this.UserFavouriteDrugsService.createUserFavouriteDrug(
          this.prescriber.id,
          formattedDropAsDrug,
          {
            favourite_group_ids: formattedDropAsDrug.favourite_groups ?? [],
          }
        );

    savePromise
      .then((response) => {
        this.toastr.success(
          `Successfully ${
            drop?.id ? "updated" : "created"
          } user favourite drug ${drop.data.name}!`
        );
        this.dropToEdit = undefined;
      })
      .catch((err) => {
        this.toastr.error(
          `Error ${drop?.id ? "saving" : "creating"} ${
            drop.data.name
          } user favourite drug, please try agian.`
        );
      })
      .finally(() => {
        this.saveDropInProgress = false;
        this.handleDrugsUpdate();
        this.handleFavouritesUpdate();
      });
  }

  deleteDrop(drop: PatientProcedureDrop) {
    const shouldDeleteDrop = this.$window.confirm(
      "Are you sure you want to remove this drop?"
    );
    if (shouldDeleteDrop) {
      this.deleteInProgress = true;
      this.PatientProcedureService.deleteDrop(this.record.id, drop)
        .then(() => {
          this.dropToEdit = undefined;
          this.toastr.success(`Successfully deleted ${drop.data.name}`);
        })
        .finally(() => {
          this.deleteInProgress = false;
          this.handleDrugsUpdate();
        });
    }
  }

  cancelDrop() {
    this.dropToEdit = undefined;
  }

  getDiscontinuationReason(drop: PatientProcedureDrop) {
    if (
      drop.data.discontinuation_reason === "Other" &&
      drop.data.discontinuation_reason_other
    ) {
      return drop.data.discontinuation_reason_other;
    }
    return drop.data.discontinuation_reason;
  }

  canPrintPrescription() {
    const prescriberTypes: GlUserTypeString[] = [
      "optometrist",
      "ophthalmologist",
    ];
    return (
      this.showPrintPrescriptions &&
      this.prescriber &&
      prescriberTypes.includes(this.prescriber.type.name)
    );
  }

  private getAllDropsToPrescribe() {
    return this.PatientProcedureHelperService.createRecordActionsForDropsList(
      this.record,
      this.drops
    )
      .filter((dropWithAction) =>
        ["started", "continue", "prescribed"].includes(dropWithAction.action)
      )
      .map((dropWithAction) => dropWithAction.drop);
  }

  // generalised prescription record
  private _createPrescriptionsRecord(drops: PatientProcedureDrop[]) {
    // attempts to create a record, if failed will not trigger anything
    const authorityDrop: PatientProcedureDrop | null = drops.find(
      (drop: PatientProcedureDrop) => drop.data.authority_script
    );

    // service
    return this.PrescriptionsService.createPrescription({
      record_id: this?.record?.id ?? null,
      patient_id: this.patient.id,
      treatment_ids: drops.map((d: PatientProcedureDrop) => d.id),
      data: drops.map((d) => this._convertToPrescriptionDrugDataInterface(d)),
      authority_number: authorityDrop?.data?.authority_number ?? null,
    }).then((response) => {
      return response;
    });
  }

  // SERVICE
  private _createPrescriptionsFromDrops(drops: PatientProcedureDrop[]) {
    // chunked
    const chunkedDrops: PatientProcedure[][] =
      this.PrescriptionsService.chunkProcedureDrugs(drops);

    return Promise.all(
      map(chunkedDrops, (chunk: PatientProcedureDrop[]) =>
        this._createPrescriptionsRecord(chunk)
          .then((response) => {
            // record must be created or else throw an error
            if (!response) {
              return Promise.reject();
            }
            return response;
          })
          .then((prescription) => {
            this.toastr.success(
              `Successfully created prescription ${prescription.id}!`
            );
            this._printPrescriptionsPdf(chunk, prescription);
          })
          .catch((error) => {
            this.toastr.error("Error creating prescriptions, please try again");
            return;
          })
      )
    );
  }

  private _convertToPrescriptionDrugDataInterface(
    drop: PatientProcedureDrop
  ): GlPrescriptionDrugData {
    return this.PatientProcedureService.convertProcedureDropToDrugDataInterface(
      drop
    );
  }

  // generic print function
  private _printPrescriptionsPdf(
    drops: PatientProcedureDrop[],
    prescription: GlPrescription
  ) {
    this.PrescriptionsService.pdf({
      drugs: drops.map((d: PatientProcedureDrop) =>
        this._convertToPrescriptionDrugDataInterface(d)
      ),
      prescriber: this.prescriber,
      patient: this.patient,
      prescription,
    }).then((pdf) => {
      const window = this.$window.open(URL.createObjectURL(pdf));
    });
  }
}

export class PatientDropsComponent implements IComponentOptions {
  static selector = "patientDrops";
  static template = require("./patient-drops.html");
  static controller = PatientDropsController;
  static bindings = {
    mode: "@",
    record: "<",
    drops: "<",
    favouriteDrugs: "<",
    favouriteDrugGroups: "<",
    prescriber: "<?",
    patient: "<?",
    useRecordIdOnPrescribe: "<?",
    showPrintPrescriptions: "<?",
    onDrugsUpdate: "&",
    onPrescriptionsUpdate: "&",
    onFavouritesUpdate: "&",
  };
}
