import {
  IComponentOptions,
  IController,
  IOnChanges,
  IOnChangesObject,
} from "angular";
// import { isEmpty } from "lodash";
import { isNil } from "lodash";
import { IGlSide, IGlSideBilateral } from "models/gl-side.model";
import { PatientProcedureInjection } from "models/patient-procedure";
import { PatientRecordData } from "models/patient-record.model";
import {
  IGlInjectionAdminDisplay,
  IGlInjectionRecordData,
} from "../../../../../models/injection";
import { IConsolidatedInjection } from "../../../../core/services/injection-helper/injection-helper.service";
import "./admin-injection-summary.component.scss";
import moment = require("moment");

export class AdminInjectionSummaryController
  implements IController, IOnChanges
{
  // @Inputs()
  debug = false;
  leftInjectionDetails: IGlInjectionRecordData;
  rightInjectionDetails: IGlInjectionRecordData;
  allLeftInjections: IConsolidatedInjection[];
  allRightInjections: IConsolidatedInjection[];
  recordId: number;
  currentRecord: PatientRecordData;

  // Class Bindings

  leftMostRecentInjection: IGlInjectionRecordData;
  rightMostRecentInjection: IGlInjectionRecordData;
  leftNextInjectionDate: Date;
  rightNextInjectionDate: Date;
  leftNextAppointmentDetails: string;
  rightNextAppointmentDetails: string;

  // v2
  rightInjectionsToDisplay: IGlInjectionAdminDisplay[];
  leftInjectionsToDisplay: IGlInjectionAdminDisplay[];

  constructor() {
    "ngInject";
  }

  $onChanges(onChangesObj: IOnChangesObject): void {
    /* V2 FOR MULTIPLE */
    // left
    if (onChangesObj?.allLeftInjections) {
      // for all found related injections in this cycle
      // map them similar to the format above
      if (this.allLeftInjections?.length) {
        this.leftInjectionsToDisplay = this.formatInjectionsToDisplay(
          this.allLeftInjections,
          "left"
        );
      } else {
        this.leftInjectionsToDisplay = [];
      }
    }

    // right
    if (onChangesObj?.allRightInjections) {
      // for all found related injections in this cycle
      // map them similar to the format above
      if (this.allRightInjections?.length) {
        this.rightInjectionsToDisplay = this.formatInjectionsToDisplay(
          this.allRightInjections,
          "right"
        );
      } else {
        this.rightInjectionsToDisplay = [];
      }
    }
  }

  getFormattedInjection(injection: PatientProcedureInjection) {
    if (!injection) {
      return;
    }
    // const { currentCycle } = injection;
    return `${injection.data.name_other || injection.data.name.name} - ${
      injection.data.frequency.name
    }`;
  }

  getLongestInjectionArray() {
    return this.rightInjectionsToDisplay?.length >
      this.leftInjectionsToDisplay?.length
      ? this.rightInjectionsToDisplay
      : this.leftInjectionsToDisplay;
  }

  private formatInjectionsToDisplay(
    consolidatedInjections: IConsolidatedInjection[],
    side: IGlSideBilateral
  ) {
    // no injections? dont bother
    if (isNil(consolidatedInjections) || !consolidatedInjections?.length) {
      return [];
    }

    // else format
    return (
      consolidatedInjections
        // remove any completed injections
        .filter(
          (injection) =>
            injection.currentCycle.data.frequency.key !== "complete"
        )
        // then map
        .map((injection) => {
          // master data is here
          const currentCycle = injection.currentCycle;
          const currentCycleCount = currentCycle.cycleCount;
          const currentCycleRepeatCount = currentCycle.data.repeat_count;
          const cycleInterval = currentCycle.data.frequency;
          /* 
            we just find the most recent injection since we dont 
            rely on old record data now
          */
          const mostRecentInjectionData = this.findMostRecentInjection(
            injection,
            side
          )?.data?.[side];

          // if empty use fallback
          // mostRecentInjectionData = !isEmpty(mostRecentInjectionData)
          //   ? mostRecentInjectionData
          //   : this.leftInjectionDetails;

          // get the next injection date based on the interval
          const nextInjectionDate = moment(mostRecentInjectionData?.date)
            .add(cycleInterval.key.replace("+", ""), "weeks") //.replace("+","") bug fix for older data
            .toDate();

          // appt deets
          const nextAppointmentDetails =
            currentCycleCount === currentCycleRepeatCount
              ? "Review"
              : cycleInterval.key !== "complete"
              ? `${currentCycleCount + 1}/${currentCycleRepeatCount}`
              : "--";

          // return as a formatted structure
          return {
            injection,
            mostRecentInjectionData,
            nextAppointmentDetails,
            nextInjectionDate,
          } as IGlInjectionAdminDisplay;
        })
    );
  }

  private findMostRecentInjection(
    consolidatedInjection: IConsolidatedInjection,
    side: IGlSide
  ) {
    const allPreviousInjections = consolidatedInjection.injections
      .flatMap((i) => i.records)
      // old: since current data is being passed, if empty find other ones
      // .filter((r) => r.id !== this.recordId && side in r.data);

      // new tweak to include current record
      //
      // since the old logic was
      // use passed data if existing else find old record, but most of the time we
      // base off existing data instead
      .filter((r) => side in r.data);
    return allPreviousInjections?.[0];
  }
}

export class AdminInjectionSummaryComponent implements IComponentOptions {
  static selector = "glAdminInjectionSummary";
  static template = require("./admin-injection-summary.component.html");
  static controller = AdminInjectionSummaryController;
  static bindings = {
    debug: "<?",
    leftInjectionDetails: "<",
    rightInjectionDetails: "<",
    allLeftInjections: "<",
    allRightInjections: "<",
    recordId: "<",
    currentRecord: "<",
  };
}
