export class AddressHelperService {
  static injectionName: string = "AddressHelperService";
  constructor() {
    "ngInject";
  }

  // these are pulled from patient.demographic.form
  getAddressFromGoogleAddress(googleAddress: google.maps.places.PlaceResult) {
    const address_parts = googleAddress.formatted_address.split(",");
    return {
      line_1: address_parts.length > 0 ? address_parts[0] : "",
      postal_code: this.getField(googleAddress, "postal_code"),
      suburb: this.getField(googleAddress, "locality"),
      state: this.getField(googleAddress, "administrative_area_level_1"),
    };
  }

  getField(googleAddress: google.maps.places.PlaceResult, fieldName: string) {
    const component = (googleAddress?.address_components ?? []).find((c) => {
      return c.types.includes(fieldName);
    });

    return component?.short_name;
  }
}
