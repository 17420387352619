import { Clinic } from "../../../../../models/clinic.model";
import { PatientReferral } from "../../../../../models/patient-record.model";
import "./refer-to.component.scss";
import { ClinicService } from "../../../../core/services/clinic.service";
import { GlStaff } from "../../../../../models/user.model";

class ReferToComponentController
  implements angular.IController, angular.IOnChanges
{
  // @Inputs();
  referral: PatientReferral;
  clinics: Clinic[];

  // Controller Bindings
  selectedClinic: Clinic;
  clinicProviders: GlStaff[] = [];

  constructor(private ClinicService: ClinicService) {
    "ngInject";
  }

  $onChanges() {
    if (this.referral?.referral_clinic_id && this.clinics) {
      this.fetchProvidersForSelectedClinic();
    }
  }

  selectedClinicDidChange() {
    if (this.referral?.referral_clinic_id) {
      this.clinicProviders = [];
      delete this.referral.referral_provider_id;
      this.fetchProvidersForSelectedClinic();
    }
  }

  private fetchProvidersForSelectedClinic() {
    if (this.referral.referral_clinic_id) {
      const clinic = this.clinics.find(
        (c) => c.id === this.referral?.referral_clinic_id
      );
      if (clinic) {
        return this.ClinicService.getProvidersForClinic(clinic).then(
          (providers) => {
            const heathcote = providers.find((p) =>
              p.name.toLocaleLowerCase().includes("heathcote")
            );
            this.clinicProviders = [heathcote];

            this.referral.referral_provider_id = heathcote.id;
          }
        );
      }
    }
  }
}

export class ReferToComponent implements angular.IComponentOptions {
  static selector = "glReferTo";
  static template = require("./refer-to.component.html");
  static controller = ReferToComponentController;
  static bindings = {
    referral: "<?",
    clinics: "<?",
  };
}
