import { createHash } from "crypto";
import { isNil } from "lodash";
import { IGlApiResponse } from "models/gl-api-response.model";
import { DrugDbRecordV2, DrugHashParams } from "models/gl-drug";
import { PatientProcedureDrug } from "models/patient-procedure";
import { DrugDbRecordV2ExtensionDecorator } from "models/user-favourite-drugs";
import { API_PATH_v2 } from "../api-paths";
// import { GlPrescriptionDrugData } from "models/prescription.model";

export class DrugsDbService {
  static injectionName = "DrugsDbService";
  apiV2Base = `${this.API_URL}${API_PATH_v2}`;

  customDrugs: DrugDbRecordV2[];

  // constructor
  constructor(private API_URL: string, private $http: angular.IHttpService) {
    "ngInject";
  }

  getCustomDrugs() {
    return this.customDrugs;
  }

  // API
  searchDrugsDb(query: string) {
    return this.$http
      .get<IGlApiResponse<DrugDbRecordV2[]>>(`${this.apiV2Base}/pbs?q=${query}`)
      .then((response) => {
        return response.data.data;
      });
  }

  // CUSTOM DRUGS
  getAllCustomDrugs() {
    return this.$http
      .get<IGlApiResponse<DrugDbRecordV2[]>>(`${this.apiV2Base}/custom-drugs`)
      .then((response) => {
        this.customDrugs = response.data.data;
        return this.customDrugs;
      });
  }

  getCustomDrugsForUser(user_id: number) {
    return this.$http
      .get<IGlApiResponse<DrugDbRecordV2[]>>(`${this.apiV2Base}/custom-drugs`, {
        params: { user_id },
      })
      .then((response) => {
        this.customDrugs = response.data.data;
        return this.customDrugs;
      });
  }

  createCustomDrug(params: DrugDbRecordV2) {
    return this.$http
      .post<IGlApiResponse<DrugDbRecordV2>>(`${this.apiV2Base}/custom-drugs`, {
        ...params,
      })
      .then((response) => {
        return response.data.data;
      });
  }

  updateCustomDrug(params: DrugDbRecordV2) {
    return this.$http
      .put<IGlApiResponse<DrugDbRecordV2>>(
        `${this.apiV2Base}/custom-drugs/${params.custom_drug_id}`,
        { ...params }
      )
      .then((response) => {
        return response.data.data;
      });
  }

  deleteCustomDrug(customDrugId: number) {
    return this.$http
      .delete<IGlApiResponse<DrugDbRecordV2>>(
        `${this.apiV2Base}/custom-drugs/${customDrugId}`
      )
      .then((response) => {
        return response.data.data;
      });
  }

  // FORMATTER
  formatPatientProcedureDrugsIntoSearchResult(
    drugs: PatientProcedureDrug[]
  ): DrugDbRecordV2ExtensionDecorator[] {
    if (isNil(drugs)) {
      return [];
    }
    return drugs.map((d) => this.formatPatientProcedureDrugIntoSearchResult(d));
  }

  formatPatientProcedureDrugIntoSearchResult(
    drug: PatientProcedureDrug
  ): DrugDbRecordV2ExtensionDecorator {
    return {
      li_item_id: drug.data.id,
      drug_name: drug.data.mp_pt,
      brand_name: drug.data.brand_name,
      program_code: drug.data.program_code,
      benefit_type_code: drug.data.authority_script ? "A" : null,
      maximum_quantity_units: drug.data.mq,
      number_of_repeats: drug.data.repeats,
      frequency: !isNil(drug.data.frequency) ? Number(drug.data.frequency) : 1,

      pack_size: Number(drug.data.pack_size),
      schedule_form: drug.data.tpuu_or_mpp_pt,
      // frequency: d?.data?.frequency,
      pbs_code: drug.data?.item_code,
      treatment_of_code: Number(drug.data.treatment_of_code),

      version: 2,

      // favourites stuff
      user_id: drug.user_id,
      favourite_groups: drug.favourite_groups,
      favourite_drug_id: drug.id,
      favourite: drug?.favourite_groups?.length ? true : false,
      treatment_id: drug.data.treatment_id || drug.id,

      prescribing_texts: drug.data.restriction_text
        ? [
            {
              prescribing_txt: drug.data.restriction_text,
            },
          ]
        : null,
    };
  }

  // copied from api side
  generateDrugLiItemId(params: DrugHashParams) {
    // regex includes words, letters and . to avoid confusion
    // e.g. 12.5 and 125 might end up the same and information might be lost
    const PUNCTUATION_REGEX: RegExp = /[^\w\s.]+| /gi;
    const values: string[] = Object.values(params);
    // concat
    const hashString: string = values
      .map((v) => String(v ?? "").trim())
      .join("");
    return (
      createHash("md5")
        // punctuation and spacing removed
        .update(hashString.toLowerCase().replace(PUNCTUATION_REGEX, ""))
        .digest("hex")
    );
  }
}
