export class Row implements angular.IComponentOptions {
  static selector = "row";
  static transclude = true;
  static bindings = {
    class: "@?",
    ngClass: "<?"
  };
  static template =
    '<div class="row" ng-class="$ctrl.class || $ctrl.ngClass" ng-transclude></div>';
}
