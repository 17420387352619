import { Appendix } from "../../../services/appendix";
import { defaults } from "lodash";
import { GlFormController } from "../../gl-form-controller";

class GlProcedureController extends GlFormController
  implements angular.IController, angular.IOnChanges {
  procedureTypes: any;
  procedure: any;
  type: string;

  constructor(private appendix: Appendix) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.type && this.type) {
      this.procedureTypes = this.appendix.get(this.type);
    }
    if (changes.procedure && this.procedure) {
      defaults(this.procedure, { eye: "both", date: new Date() });
    }
  }

  shouldShowDescription(val: any) {
    return val && (val.showOther || val.key === "other") ? true : false;
  }
}

export class GlProcedureComponent implements angular.IComponentOptions {
  static selector = "glProcedure";
  static template = require("./gl-procedure.html");
  static controller = GlProcedureController;
  static bindings = {
    procedure: "<",
    type: "@",
    mode: "@?",
    isEditable: "<?",
  };
}
