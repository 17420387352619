import { isFunction } from "angular";
import { Appendix } from "app/core/services/appendix";
import { ContactService } from "app/core/services/contact.service";
import { PatientContactService } from "app/core/services/patient-contact.service";
import { cloneDeep, isEmpty, isNil, lowerCase } from "lodash";
import { Contact } from "models/contact.model";
import {
  PatientContact,
  PatientContactEdit,
} from "models/patient-contact.model";
import { Patient } from "models/user.model";
import "./gl-patient-contact.scss";
import angular = require("angular");

export class GlPatientContactController
  implements angular.IController, angular.IOnChanges
{
  contact: PatientContact;
  //
  contactEditModel: PatientContactEdit; // edit model that will clone contact
  contacts: PatientContact[];
  savePatientProviderInProgress: boolean;
  deletePatientContactInProgress: boolean;
  patient: Patient;
  modelPatientContact: Contact;
  contactsForm: angular.IFormController;
  // contactTypeOptions: IGlOption[] = this.appendix.get("patientContactTypes");
  contactTypeOptionNames: string[] = this.appendix
    .get("patientContactTypes")
    ?.map((o) => o.name);
  emptyContact: Contact = {
    clinic_name: "",
    first_name: "",
    email: "",
    last_name: "",
    address: "",
    phone: "",
    fax: "",
  };
  onPatientContactUpdate: () => void;

  constructor(
    private PatientContactService: PatientContactService,
    private $q: angular.IQService,
    private $window: angular.IWindowService,
    private ContactService: ContactService,
    private toastr: angular.toastr.IToastrService,
    private appendix: Appendix
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject): void {
    if (changes?.contact && this.contact) {
      this.contactEditModel = cloneDeep(this.contact);
      // then check for type
      this._handleContactTypeDidChange();
    }
  }

  // handles legacy conversion for editing
  _handleContactTypeDidChange() {
    const contactType: string = this.contactEditModel.type;
    // find the closest option based on a simple test
    const foundOption: string = this.contactTypeOptionNames.find(
      (name) => lowerCase(name).trim() === lowerCase(contactType).trim()
    );
    // if we find one then set it
    if (foundOption) {
      // set
      this.contactEditModel.type = foundOption;
      this.contactEditModel.type_other = null;
    } else {
      // otherwise it will default be an "other option"
      this.contactEditModel.type = "Other";
      this.contactEditModel.type_other = contactType;
    }
  }

  contactTypeInOptions(value: string) {
    // if not found then its different and considered an other option
    return !isNil(
      this.contactTypeOptionNames.find(
        (name) => lowerCase(name) === lowerCase(value)
      )
    );
  }

  savePatientContact() {
    this.savePatientProviderInProgress = true;
    // on save, we always set type_other to be type if existing
    // we do it via a clone to avoid issues
    const saveModel: PatientContactEdit = cloneDeep(this.contactEditModel);
    if (!isNil(saveModel.type_other)) {
      saveModel.type = saveModel.type_other;
      delete saveModel.type_other;
    }

    // standard workflow
    this.$q
      .resolve()
      .then(() => {
        return this.ContactService.createContact(saveModel.data).then((c) => {
          return this.PatientContactService.update(
            this.patient.id,
            c,
            saveModel.type,
            saveModel.referrer === true ? true : false
          );
        });
      })
      .then(() => {
        this.contactChanged();
        this.contactEditModel.data = this.emptyContact;
        this.contactEditModel.referrer = false;
        this.contactEditModel.type = "";
        this.contactEditModel.type_other = "";
        this.contactsForm.$setPristine();
      })
      .finally(() => (this.savePatientProviderInProgress = false));
  }

  isContactNew() {
    return (
      isEmpty(this.contactEditModel?.data?.clinic_name) &&
      isEmpty(this.contactEditModel?.data?.first_name) &&
      isEmpty(this.contactEditModel?.data?.email) &&
      isEmpty(this.contactEditModel?.data?.last_name) &&
      isEmpty(this.contactEditModel?.data?.address) &&
      isEmpty(this.contactEditModel?.data?.phone) &&
      isEmpty(this.contactEditModel?.data?.fax)
    );
  }

  contactChanged() {
    if (isFunction(this.onPatientContactUpdate)) {
      this.onPatientContactUpdate();
    }
  }

  searchContacts(searchString: string) {
    if (searchString.length >= 3) {
      return this.ContactService.getAll({
        searchString,
      }).then((contacts) => {
        return contacts;
      });
    }
  }

  displayContactSearch(contact: Contact) {
    return `${contact?.first_name} ${contact?.last_name} (${contact?.clinic_name})`;
  }

  setCurrentContact() {
    const ifContactAlreadyExists = this.contacts.findIndex(
      (c) => c?.data?.id === this.modelPatientContact.id
    );
    if (ifContactAlreadyExists > -1) {
      this.modelPatientContact = null;
      this.toastr.error("Contact already exists for patient.");
    } else {
      this.contactEditModel.data = this.modelPatientContact;
    }
  }

  // just resets ewverything
  resetContact() {
    this.contactEditModel.data = this.emptyContact;
    this.modelPatientContact = null;
  }

  deletePatientContact(contact: Contact) {
    const confirm = this.$window.confirm(
      "Are you sure you want to delete this contact from this patient. This won't delete the contact, it will just remove this contact from this patient."
    );
    if (confirm && contact.id) {
      this.deletePatientContactInProgress = true;
      this.$q
        .resolve()
        .then(() => {
          this.PatientContactService.deletePatientContact(
            this.patient.id,
            contact
          );
        })
        .then(() => {
          this.contactChanged();
          this.resetContact();
        })
        .catch((error) => {
          this.toastr.error(error);
        })
        .finally(() => (this.deletePatientContactInProgress = false));
    }
  }
}

export class GlPatientContactComponent implements angular.IComponentOptions {
  static selector = "glPatientContact";
  static template = require("./gl-patient-contact.html");
  static controller = GlPatientContactController;
  static bindings = {
    contact: "<",
    contacts: "<",
    patient: "<",
    onPatientContactUpdate: "&?",
  };
}
