/*
  This service is responsible for determining the Best Calculated Visual Acuity (BCVA)
  Read the function comments below to understand what they do and how they do it
*/

import { IGlSide } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";
import { IGlOption } from "./appendix";
import { SegmentHelperService } from "./segment-helper.service";

export interface IGlVa {
  numerator: IGlOption;
  denominator: IGlOption;
}

export class VisualAcuityService {
  static injectionName = "VisualAcuityService";
  static $inject = [SegmentHelperService.injectionName];

  constructor(private segmentHelper: SegmentHelperService) {}

  /*
    this.calculateBCVAFraction() is a function that calculates that fractional value of the va numerator and the va denominator
    it takes as inputs the laterality, the record data and the VA type
  */
  calculateBCVAFraction(va: IGlVa) {
    if (va.numerator && va.denominator) {
      return (
        this.ifStringAssignNumber(va.numerator) /
        this.ifStringAssignNumber(va.denominator)
      );
    }
  }

  getVa(
    recordData: PatientRecordData,
    vaType: "subjective" | "aided" | "unaided" | "pinhole",
    side: "left" | "right"
  ): IGlVa {
    let va_denominator: keyof PatientRecordData;
    let va_numerator: keyof PatientRecordData;
    switch (vaType) {
      case "subjective":
        va_denominator = "subjective_va_denominator";
        va_numerator = "subjective_va_numerator";
        break;

      case "aided":
        va_denominator = "aided_va_denominator";
        va_numerator = "aided_va_numerator";
        break;

      case "unaided":
        va_denominator = "va_denominator";
        va_numerator = "va_numerator";
        break;
      case "pinhole":
        va_denominator = "ph_denominator";
        va_numerator = "ph_numerator";
    }

    return {
      numerator: recordData[va_numerator]?.[side],
      denominator: recordData[va_denominator]?.[side],
    };
  }

  /*
    this.getBestFractionLeft() is a function that returns the va type (aided, unaided, subjective) that has the highest fractional value from 
    this.calculateBCVAFraction() for the left eye
  */

  getBcva(recordData: PatientRecordData, side: "left" | "right") {
    let bcva = this.getVa(recordData, "unaided", side);
    for (const type of ["subjective", "aided", "unaided", "pinhole"]) {
      const bcvaFraction = this.calculateBCVAFraction(bcva) ?? 0;
      const va = this.getVa(recordData, type as any, side);
      const vaFraction = this.calculateBCVAFraction(va) ?? 0;
      if (vaFraction > bcvaFraction) {
        bcva = va;
      }
    }

    return bcva;
  }

  // This is a function that assigns a number to the below strings (CF, HM, PL, NPL) as we need to calculate fractional values
  // we need to assign numbers to assure we can calculate this.

  ifStringAssignNumber(vaValue: IGlOption) {
    const val = vaValue.key ?? vaValue.name;
    switch (val) {
      case "CF":
        return 300;

      case "HM":
        return 400;

      case "PL":
        return 500;

      case "NPL":
        return 600;

      default:
        return parseInt(val, 10);
    }
  }

  hasVa(recordData: PatientRecordData, side: IGlSide = "both") {
    return (
      this.hasVaAided(recordData, side) ||
      this.hasVaUnaided(recordData, side) ||
      this.hasVaSubjective(recordData, side) ||
      this.hasVaPinHole(recordData, side)
    );
  }

  hasVaUnaided(recordData: PatientRecordData, side: IGlSide = "both") {
    const va_fields =
      !side || side === "both"
        ? ["va_numerator", "va_denominator"]
        : [`va_numerator.${side}`, `va_denominator.${side}`];

    return this.segmentHelper.hasFields(recordData, va_fields);
  }

  hasVaAided(recordData: PatientRecordData, side: IGlSide = "both") {
    const va_fields =
      !side || side === "both"
        ? ["aided_va_numerator", "aided_va_denominator"]
        : [`aided_va_numerator.${side}`, `aided_va_denominator.${side}`];

    return this.segmentHelper.hasFields(recordData, va_fields);
  }

  hasVaSubjective(recordData: PatientRecordData, side: IGlSide = "both") {
    const va_fields =
      !side || side === "both"
        ? ["subjective_va_numerator", "subjective_va_denominator"]
        : [
            `subjective_va_numerator.${side}`,
            `subjective_va_denominator.${side}`,
          ];

    return this.segmentHelper.hasFields(recordData, va_fields);
  }

  hasVaPinHole(recordData: PatientRecordData, side: IGlSide = "both") {
    const va_fields =
      !side || side === "both"
        ? ["ph_numerator", "ph_denominator"]
        : [`ph_numerator.${side}`, `ph_denominator.${side}`];

    return this.segmentHelper.hasFields(recordData, va_fields);
  }
}
