/* eslint-disable @typescript-eslint/member-ordering */
import { GlStaff, User } from "models/user.model";

const LOCAL_STORAGE_KEY_PROVIDER_ID = "glDashboardFilterByProviderId";
const ALL_PROVIDERS = "__ALL__";

export function getSavedProviderId({
  clinicId,
  providers,
  defaultProvider,
  $window,
}: {
  clinicId: number;
  providers: User[];
  defaultProvider: User;
  $window: angular.IWindowService;
}) {
  const savedId = $window.localStorage.getItem(
    `${LOCAL_STORAGE_KEY_PROVIDER_ID}:${clinicId}`
  );
  if (savedId === ALL_PROVIDERS) {
    return;
  } else if (savedId) {
    return providers.find((p) => p.id === +savedId);
  } else {
    return defaultProvider;
  }
}

class PersistValueController
  implements angular.IController, angular.IOnChanges, angular.IPostLink
{
  ngModelCtrl: angular.INgModelController;
  ngModel: GlStaff;
  clinicId: number;

  constructor(private $window: angular.IWindowService) {
    "ngInject";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $onChanges(changes: angular.IOnChangesObject) {
    // not empty
  }

  $postLink() {
    if (this.ngModelCtrl) {
      this.ngModelCtrl.$viewChangeListeners.push(this.ngChange.bind(this));
    }
  }

  ngChange() {
    const provider = this.ngModelCtrl.$modelValue;
    if (provider?.id) {
      this.$window.localStorage.setItem(
        `${LOCAL_STORAGE_KEY_PROVIDER_ID}:${this.clinicId}`,
        provider.id
      );
    } else if (this.clinicId) {
      this.$window.localStorage.setItem(
        `${LOCAL_STORAGE_KEY_PROVIDER_ID}:${this.clinicId}`,
        ALL_PROVIDERS
      );
    } else {
      this.$window.localStorage.removeItem(
        `${LOCAL_STORAGE_KEY_PROVIDER_ID}:${this.clinicId}`
      );
    }
  }
}

export class PersistValueDirective
  implements angular.IDirective<angular.IScope>
{
  static selector = "glPersistValue";

  scope = {
    ngModel: "<",
    clinicId: "<",
  };
  require = {
    ngModelCtrl: "ngModel",
  };
  controller = PersistValueController;
  bindToController = true;

  constructor() {
    "ngInject";
  }

  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new PersistValueDirective();
    };
    return directive;
  }
}
