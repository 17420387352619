import { Patient, User } from "models/user.model";

export const pipeName = "glInitials";
export function pipe() {
  "ngInject";
  // eslint-disable-next-line no-empty-pattern
  return (user: User | Patient, {}) => {
    const { first_name = "", last_name = "" } = user?.data ?? {};
    return first_name === "Reception"
      ? "Reception"
      : [first_name[0], last_name[0], last_name[1].toLowerCase()].join("");
  };
}
