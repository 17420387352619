import { Patient } from "models/user.model";

class DashboardPatientAlertIconController
  implements angular.IComponentController
{
  levels: string[] = [];
  patient: Patient;

  constructor() {
    "ngInject";
  }
}

export class DashboardPatientAlertIcon {
  static selector = "dashboardPatientAlertIcon";
  static template = require("./dashboard-patient-alert-icon.html");
  static controller = DashboardPatientAlertIconController;
  static bindings = {
    levels: "<?",
    patient: "<",
  };
}
