import { DrugsDbService } from "app/core/services/drugs-db/drugs-db.service";
import { DrugDbRecordPbsV2 } from "models/gl-drug";
import { User } from "models/user.model";
import { ClinicService } from "../../core/services/clinic.service";
import "./settings.scss";

class SettingsPageController implements angular.IController {
  user: User;
  customDrugs: DrugDbRecordPbsV2[];

  constructor(
    private $q: angular.IQService,
    private toastr: angular.toastr.IToastrService,
    public $state: angular.ui.IStateService,
    public ClinicService: ClinicService,
    private DrugsDbService: DrugsDbService
  ) {
    "ngInject";
  }
}

export class SettingsPageComponent implements angular.IComponentOptions {
  static selector = "glSettingsPage";
  static template = require("./settings.html");
  static controller = SettingsPageController;
  static bindings = {
    user: "<",
    customDrugs: "<",
  };
}
