import { isString } from "lodash";

export const pipeName = "gender";
export function genderPipe() {
  "ngInject";
  return (gender: "M" | "F") => {
    if (isString(gender)) {
      return gender.toLowerCase().startsWith("m") ? "Male" : "Female";
    }
  };
}
