class GlOnDestroyController implements angular.IController, angular.IOnDestroy {
  ngModelCtrl: angular.INgModelController;
  ngModel: any;

  constructor() {
    "ngInject";
  }

  $onDestroy() {
    this.ngModelCtrl.$setViewValue(undefined, "glModelOnDestroy");
  }
}

export class GlOnDestroyDirective
  implements angular.IDirective<angular.IScope> {
  static selector = "glOnDestroy";
  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new GlOnDestroyDirective();
    };
    return directive;
  }

  scope = {
    ngModel: "<",
  };
  require = {
    ngModelCtrl: "ngModel",
  };
  controller = GlOnDestroyController;
  bindToController = true;

  constructor() {
    "ngInject";
  }
}
