import { isNumber } from "lodash";
import { PatientProcedureDrop } from "../../../models/patient-procedure";

export class DropSorter {
  static injectionName = "DropSorter";
  constructor() {
    "ngInject";
  }

  sortDrops(drops: PatientProcedureDrop[]) {
    return drops.sort((d1: PatientProcedureDrop, d2: PatientProcedureDrop) => {
      const {
        treatment_end_date: d1EndDate,
        treatment_start_date: d1StartDate,
      } = d1.data;
      const {
        treatment_end_date: d2EndDate,
        treatment_start_date: d2StartDate,
      } = d2.data;

      if (d1EndDate && d2EndDate) {
        return this.sortDropsByDate(d1EndDate, d2EndDate);
      } else if (!d1EndDate && !d2EndDate) {
        return this.sortDropsByDate(d1StartDate, d2StartDate);
      } else if (!d1EndDate) {
        // console.log({ type: "!d1EndDate", d1, d2 });
        return -1;
      } else if (!d2EndDate) {
        // console.log({ type: "!d2EndDate", d1, d2 });
        return 1;
      }
    });
  }
  private sortDropsByDate(
    d1: string | number | Date,
    d2: string | number | Date
  ) {
    const d1Date = this.parseDate(d1);
    const d2Date = this.parseDate(d2);

    if (d1Date < d2Date) {
      return 1;
    } else if (d1Date > d2Date) {
      return -1;
    } else {
      return 0;
    }
  }

  private parseDate(d: string | number | Date) {
    if (isNumber(d)) {
      // older drops have a date in UTC seconds
      return new Date(d * 1000);
    } else {
      return new Date(d);
    }
  }
}
