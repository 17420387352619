/**!
 * AngularJS dropzone directive
 * @author Uday Hiwarale <uhiwarale@gmail.com>
 * https://www.github.com/thatisuday/ngDropzone
 */

import * as angular from "angular";
import Dropzone from "dropzone/src/dropzone";

export const moduleName = "glauconet.dropzone";

angular
  .module(moduleName, [])
  .provider("dropzoneOps", function () {
    /*
     *  Add default options here
     **/
    const defOps = {
      //Add your options here
    };

    return {
      setOptions: function (newOps: any) {
        angular.extend(defOps, newOps);
      },
      $get: function () {
        return defOps;
      },
    };
  })
  .directive("ngDropzone", [
    "$timeout",
    "dropzoneOps",
    function ($timeout: angular.ITimeoutService, dropzoneOps: any) {
      return {
        restrict: "AE",
        template: "<div ng-transclude></div>",
        replace: true,
        transclude: true,
        scope: {
          options: "=?", //http://www.dropzonejs.com/#configuration-options
          callbacks: "=?", //http://www.dropzonejs.com/#events
          methods: "=?", //http://www.dropzonejs.com/#dropzone-methods
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        link: function (scope: any, iElem: JQLite, iAttr: any) {
          //Set options for dropzone {override from dropzone options provider}
          scope.options = scope.options || {};
          const initOps = angular.extend({}, dropzoneOps, scope.options);

          //Instantiate dropzone with initOps
          const dropzone = new Dropzone(iElem[0], initOps);

          /*********************************************/

          //Instantiate Dropzone methods (Control actions)
          scope.methods = scope.methods || {};

          scope.methods.getDropzone = function () {
            return dropzone; //Return dropzone instance
          };

          scope.methods.getAllFiles = function () {
            return dropzone.files; //Return all files
          };

          const controlMethods = [
            "removeFile",
            "removeAllFiles",
            "processQueue",
            "getAddedFiles",
            "getAcceptedFiles",
            "getRejectedFiles",
            "getQueuedFiles",
            "getUploadingFiles",
            "disable",
            "enable",
            "confirm",
            "createThumbnailFromUrl",
          ];

          angular.forEach(controlMethods, function (methodName: string) {
            scope.methods[methodName] = function () {
              // eslint-disable-next-line
              dropzone[methodName].apply(dropzone, arguments);
              if (!scope.$$phase && !scope.$root.$$phase) {
                scope.$apply();
              }
            };
          });

          /*********************************************/

          //Set invents (callbacks)
          if (scope.callbacks) {
            const callbackMethods = [
              "drop",
              "dragstart",
              "dragend",
              "dragenter",
              "dragover",
              "dragleave",
              "addedfile",
              "removedfile",
              "thumbnail",
              "error",
              "processing",
              "uploadprogress",
              "sending",
              "success",
              "complete",
              "canceled",
              "maxfilesreached",
              "maxfilesexceeded",
              "processingmultiple",
              "sendingmultiple",
              "successmultiple",
              "completemultiple",
              "canceledmultiple",
              "totaluploadprogress",
              "reset",
              "queuecomplete",
            ];
            angular.forEach(callbackMethods, function (method: string) {
              const callback = scope.callbacks[method] || angular.noop;
              dropzone.on(method, function () {
                // eslint-disable-next-line
                callback.apply(null, arguments);
                if (!scope.$$phase && !scope.$root.$$phase) {
                  scope.$apply();
                }
              });
            });
          }
        },
      };
    },
  ]);
