import * as cleanDeepJs from "clean-deep";

import * as angular from "angular";

export const pipeName = "cleanDeep";
export const cleanDeep: angular.Injectable<angular.FilterFactory> = () => {
  "ngInject";
  return (input: any) => {
    if (angular.isObject(input)) {
      return cleanDeepJs(input);
    }
    return input;
  };
};
