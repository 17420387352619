import "./vaccine-label.scss";
import { VACCINE_STATUSES } from "../../../../services/appendix";
import angular = require("angular");

class PatientVaccineLabelController implements angular.IController {
  vaccineTypes = VACCINE_STATUSES;
  vaccineStatus: string;
  tooltipPosition: string;

  constructor() {
    "ngInject";
  }
}

export class PatientVaccineLabel implements angular.IComponentOptions {
  static selector = "vaccineLabel";
  static template = require("./vaccine-label.html");
  static bindings = {
    vaccineStatus: "<",
    tooltipPosition: "<",
  };
  static controller = PatientVaccineLabelController;
}
