import { get, isNil, max } from "lodash";
import { IGlSide, IGlSideBilateral } from "../../../../../models/gl-side.model";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { SegmentHelperService } from "../../../../core/services/segment-helper.service";
import { VisualAcuityService } from "../../../../core/services/va.service";

class GlLetterClinicalDataController implements angular.IController {
  static $inject = [
    VisualAcuityService.injectionName,
    SegmentHelperService.injectionName,
  ];

  record: PatientRecord;
  // This is the consolidated historical record with all the latest
  history: PatientRecordData;

  constructor(
    private vaSvc: VisualAcuityService,
    private segmentHelper: SegmentHelperService
  ) {
    // not empty
  }

  showClinicalDataTable() {
    return (
      this.hasVa() || this.hasCct() || this.hasIop() || this.hasLensStatus()
    );
  }

  hasVa(side?: IGlSide) {
    return this.vaSvc.hasVa(this.record.data, side);
  }

  hasIop() {
    return this.segmentHelper.hasFields(this.record.data, ["iop"]);
  }

  hasCct() {
    return this.segmentHelper.hasFields(this.record.data, ["cct"]);
  }

  hasLensStatus() {
    return this.segmentHelper.hasFields(this.record.data, [
      "lens_status",
      "lens_status_other",
    ]);
  }

  hasDominance(side?: IGlSideBilateral) {
    // if no side specified its a generic check
    if (isNil(side)) {
      return this.segmentHelper.hasFields(this.record?.data, ["dominance"]);
    }

    // else do check by side on value equality
    return get(this.record, "data.dominance") === side;
  }

  getMaxIop(side: IGlSide) {
    const iopFromHistory = get(this.history, `iop.${side}`);
    const maxIopFromHistory = get(this.history, `max_iop.${side}`);
    const iopFromRecord = get(this.record.data, `iop.${side}`);
    const maxIopFromRecord = get(this.record.data, `max_iop.${side}`);
    return max([
      iopFromHistory,
      maxIopFromHistory,
      iopFromRecord,
      maxIopFromRecord,
    ]);
  }
}

export class GlLetterClinicalDataComponent
  implements angular.IComponentOptions
{
  static selector = "glLetterClinicalData";
  static template = require("./gl-letter-clinical-data.html");
  static controller = GlLetterClinicalDataController;
  static bindings = {
    record: "<",
    history: "<",
  };
}
