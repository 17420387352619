import { PatientRecordData } from "models/patient-record.model";
import { VisualAcuityService } from "../services/va.service";

export const pipeName = "glBcva";
export function pipe(VisualAcuityService: VisualAcuityService) {
  "ngInject";
  return (recordData: PatientRecordData, side: "left" | "right") => {
    if (recordData) {
      return VisualAcuityService.getBcva(recordData, side);
    }
  };
}
