import { DocumentWindowService } from "app/core/services/document-window.service";
import { Patient } from "models/user.model";

class ButtonPlinyController implements angular.IController {
  // @Input
  patient: Patient;
  condition: string;
  klass = "btn-success";

  constructor(private DocumentWindowService: DocumentWindowService) {
    "ngInject";
  }

  openPliny() {
    this.DocumentWindowService.openPlinyViewer(this.patient, this.condition);
  }
}

export class ButtonPlinyComponent implements angular.IComponentOptions {
  static selector = "glButtonPliny";
  static controller = ButtonPlinyController;
  static bindings = {
    patient: "<",
    condition: "@?",
    klass: "@?class",
  };
  static template = `
  <button
    type="button"
    ng-click="$ctrl.openPliny()"
    class="btn btn-success"
    ng-class="$ctrl.klass"
    ng-disabled="!$ctrl.patient.id"
  >
    <gl-pliny-logo></gl-pliny-logo>
    Pliny
  </button>
    `;
}
