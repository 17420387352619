import { ClinicService } from "app/core/services/clinic.service";
import { Appointment } from "models/appointment.model";
import { User } from "models/user.model";

class SetProviderForRecordFromPatientModalController
  implements angular.IOnInit {
  resolve: any;
  user: User;
  appointment: Appointment;
  clinicProviders: User[];
  providerForRecord: any;
  close: (args: any) => void;
  sendProvider: (args: any) => void;

  constructor(
    private $scope: angular.IScope,
    private ClinicService: ClinicService
  ) {
    "ngInject";
  }

  $onInit() {
    this.providerForRecord = null;
    this.fetchProvidersForSelectedClinic(this.resolve.user);
  }

  fetchProvidersForSelectedClinic(user: User) {
    return this.ClinicService.getProvidersForClinic(user.clinic).then(
      (providers) => {
        this.clinicProviders = providers;
      }
    );
  }

  createRecord() {
    if (this.providerForRecord) {
      this.close({ $value: this.providerForRecord });
    }
  }
}

export class SetProviderForRecordFromPatientComponent
  implements angular.IComponentOptions {
  static selector = "setProviderForRecord";
  static template = require("./set_provider_record.html");
  static controller = SetProviderForRecordFromPatientModalController;

  static bindings = {
    resolve: "<",
    close: "&",
    sendProvider: "&",
    dismiss: "&",
  };
}
