declare module "angular" {
  ///////////////////////////////////////////////////////////////////////////////
  // ngSanitize module (angular-sanitize.js)
  ///////////////////////////////////////////////////////////////////////////////
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace sanitize {
    ///////////////////////////////////////////////////////////////////////////
    // SanitizeProvider
    // see https://docs.angularjs.org/api/ngSanitize/provider/$sanitizeProvider
    ///////////////////////////////////////////////////////////////////////////
    export interface ISanitizerProvider {
      enableSvg(): boolean;
      enableSvg(flag: boolean): ISanitizerProvider;
      addValidAttrs(attrs: string[]): ISanitizerProvider;
      addValidElements(elements: string[]): ISanitizerProvider;
      addValidElements(elements: {
        htmlElements: string[];
        htmlVoidElements?: string[];
        svgElements?: string[];
      }): ISanitizerProvider;
    }
  }
}

export function glLetterConfiguration(
  $provide: angular.auto.IProvideService,
  $sanitizeProvider: angular.sanitize.ISanitizerProvider
) {
  "ngInject";

  /**
   * for gl-letter, we require id to be allowed in the html letter template. By
   * default the angular text parser strips out the id attribute. We whitelist
   * it here so it doesn't get removed and the gl-letter component can correctly
   * match specific tags between letters
   **/
  $sanitizeProvider.addValidAttrs(["id", "style"]);
  /**
   * CONFIGURE textangular
   * https://github.com/textAngular/textAngular/blob/master/demo/static-demo.html#L75
   */
  // this demonstrates how to register a new tool and add it to the default toolbar
  $provide.decorator("taOptions", (taRegisterTool: any, $delegate) => {
    "ngInject";
    // $delegate is the taOptions we are decorating
    const taOptions = $delegate;
    // We force text-angular to use the in-built sanitizer and not throw an
    // error if the textangualr text santizer isn't included
    taOptions.forceTextAngularSanitize = false;
    taOptions.toolbar = [
      // ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
      ["bold", "italics", "underline", "ul", "ol", "redo", "undo", "clear"],
      ["justifyLeft", "justifyCenter", "justifyRight", "indent", "outdent"],
      // ['html', 'insertImage','insertLink', 'insertVideo', 'wordcount', 'charcount']
    ];
    return taOptions;
  });
}
