/**
 * This directive is used to set the src of an <embed> object. It fixes an error
 * in safari where the pdf isn't shown. This directive replaces the <embed>
 * object if the src value changes. This allows the document to be shown in
 * Safari
 *
 * See https://github.com/angular/angular.js/issues/339
 */

export class GlEmbedSrcDirective implements angular.IDirective<angular.IScope> {
  static selector = "embedSrc";
  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new GlEmbedSrcDirective();
    };
    return directive;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  restrict = "A";
  link(scope: angular.IScope, element: JQLite, attrs: angular.IAttributes) {
    let current = element;
    scope.$watch(
      () => attrs.embedSrc,
      () => {
        const clone = element.clone().attr("src", attrs.embedSrc);
        current.replaceWith(clone);
        current = clone;
      }
    );
  }
}
