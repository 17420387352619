class GlLetterSignatureController implements angular.IController {}

export class GlLetterSignatureComponent implements angular.IComponentOptions {
  static selector = "glLetterSignature";
  static template = require("./gl-letter-signature.html");
  static controller = GlLetterSignatureController;
  static bindings = {
    signature: "<",
    user: "<",
  };
}
