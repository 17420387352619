import {
  GlDocumentType,
  DocumentsService,
} from "../services/documents-service/documents.service";

export const pipeName = "documentName";
export function documentNamePipe(DocumentsService: DocumentsService) {
  "ngInject";
  return (docType: GlDocumentType) => {
    // DOB is stored as a unix time stamp in seconds
    return DocumentsService.getHumanDocumentName(docType);
  };
}
