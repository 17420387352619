import "./document-upload-thumbnail.scss";
// eslint-disable-next-line
import { IDocumentImportMatchUpload } from "../../services/document-import/document-import.service";
import { PatientDocumentService } from "app/core/services/patient-document.service";
import { OrthancApiService } from "app/core/services/orthanc-api/orthanc-api.service";
import { DocumentsService } from "app/core/services/documents-service/documents.service";
import { IDocumentMatchWithId } from "../document-uploads/document-uploads";

/**
 * @deprecated
 * unused
 */
class DocumentUploadThumbnailController
  implements angular.IComponentController
{
  upload: IDocumentMatchWithId;
  progress = 0;
  onComplete: () => void;
  constructor(
    private OrthancApiService: OrthancApiService,
    private PatientDocumentService: PatientDocumentService,
    private DocumentsService: DocumentsService,
    private $q: angular.IQService
  ) {
    "ngInject";
  }

  $onInit() {
    // If there is an existing document to delete, remove it prior to uploading
    // the new one
    const deletePromise = this.upload.existingPatientDocumentToDelete
      ? this.PatientDocumentService.delete(
          this.upload.existingPatientDocumentToDelete
        )
      : this.$q.resolve();

    deletePromise
      .then(() =>
        this.OrthancApiService.instanceGetFile(this.upload.orthancDocumentId)
      )
      .then((file) => {
        return this.PatientDocumentService.create(
          this.upload.record.user_id,
          this.upload.record.id,
          file,
          this.upload.orthancDocumentId,
          {
            ...this.DocumentsService.getDocumentDataFieldsForDocType(
              this.upload.documentType
            ),
            dicom_data: this.upload.orthancDocumentTags,
          },
          (progress) => {
            this.progress = progress.loaded / progress.total;
          }
        );
      })
      .then(() => {
        this.onComplete();
      });
  }
}

export class DocumentUploadThumbnailComponent
  implements angular.IComponentOptions
{
  static selector = "documentUploadThumbnail";
  static template = require("./document-upload-thumbnail.html");
  static controller = DocumentUploadThumbnailController;
  static bindings = {
    upload: "<",
    onComplete: "&?",
  };
}
