class DashboardPatientController implements angular.IController {
  constructor() {
    "ngInject";
  }
}

/**
 * This is a directive as it is not possible to insert components
 * into tables. The directive allows it to be injected into a table
 */
export class DashboardPatientComponent implements angular.IDirective {
  static selector = "dashboardPatient";
  static factory: angular.IDirectiveFactory = () =>
    new DashboardPatientComponent();

  template = require("./dashboard-patient.html");
  restrict = "EA";
  controller = DashboardPatientController;
  bindings = {
    patient: "<",
    record: "<",
  };
}
