import { IGlApiResponse } from "../../../models/gl-api-response.model";
import { Invoice } from "../../../models/invoice.model";
import { API_PATH } from "./api-paths";

export class InvoiceService {
  static injectionName = "InvoiceService";
  $httpDefaultCache = this.$cacheFactory.get("$http");

  constructor(
    private $http: angular.IHttpService,
    private $cacheFactory: angular.ICacheFactoryService,
    private API_URL: string
  ) {
    "ngInject";
  }

  ////////////////

  getAll(params: { patient_id: number }) {
    const id = params.patient_id;
    const url = this.getUrlForId(id);
    // Request to the API for the user associated with the token
    return this.$http
      .get<IGlApiResponse<Invoice[]>>(url, {
        cache: true,
      })
      .then((response) => {
        return response.data.data;
      });
  }

  get(params: { patient_id: number; invoice_id: number }) {
    const patientId = params.patient_id;
    const invoiceId = params.invoice_id;
    const url = this.getUrlForId(patientId) + "/" + invoiceId;
    // Request to the API for the user associated with the token
    return this.$http.get<IGlApiResponse<string>>(url).then((response) => {
      return response.data.data;
    });
  }

  getUrlForId(id: number) {
    return `${this.API_URL}${API_PATH}` + "/patient/" + id + "/invoice";
  }

  flushCacheForUser(id: number) {
    const url = this.getUrlForId(id);
    this.$httpDefaultCache.remove(url);
  }
}
