import { PatientDocument } from "models/patient-document.model";
import { sortDatesDesc } from "../helpers/date-helper";
import { DOCUMENT_TAGS_V2 } from "../services/appendix";

export const pipeName = "lettersByTag";
export function lettersByTag() {
  "ngInject";
  return (patientDocuments: PatientDocument[], tag: string) => {
    if (!patientDocuments) {
      return [];
    }
    try {
      let tagType = DOCUMENT_TAGS_V2.documents.find((t) => {
        return tag === t.type;
      }).type;

      if (tagType === "Other") {
        //other tag is actually null value
        tagType = undefined;
      }

      const sortedDocs = patientDocuments
        .filter((d) => {
          const curDocType = DOCUMENT_TAGS_V2.documents.find((t) => {
            return d.data.tags
              ?.toLocaleLowerCase()
              .split(",")
              .find((dt) => dt === t.name?.toLocaleLowerCase());
          })?.type;
          return curDocType === tagType;
        })
        .sort((d1, d2) => {
          let date1 = new Date();
          if (d1.data.updated_created_date) {
            date1 = new Date(d1.data.updated_created_date);
          } else {
            date1 = new Date(d1.created_at);
          }

          let date2 = new Date();
          if (d2.data.updated_created_date) {
            date2 = new Date(d2.data.updated_created_date);
          } else {
            date2 = new Date(d2.created_at);
          }
          return sortDatesDesc(date1, date2);
        });

      //put active referral first regardless of date
      return [
        ...sortedDocs.filter((d) =>
          d.data.tags?.split(",").find((t) => t.toLowerCase() === "referral")
        ),
        ...sortedDocs.filter(
          (d) =>
            !d.data.tags?.split(",").find((t) => t.toLowerCase() === "referral")
        ),
      ];
    } catch (e) {
      console.log(e);
    }
  };
}
