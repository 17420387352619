import { first } from "lodash";
import { GlMessage, GlThread } from "../../../../../models/messages.model";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import { User } from "../../../../../models/user.model";
import { ThreadFacadeService } from "./../../../services/thread-facade.service";
import "./messenger-messages.scss";

class MessengerMessagesController
  implements angular.IController, angular.IOnChanges
{
  threads: GlThread[];
  user: User;
  messageBody: string = "";
  managingOphthal: PatientClinic;
  sendingMessageInProgress = false;
  deleteInProgress = true;
  private editMode = false;
  private patientRecordId: number;
  private messageToEdit: GlMessage;
  private messageToDelete: GlMessage;
  constructor(
    public ThreadFacadeService: ThreadFacadeService,
    private $window: angular.IWindowService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes?.patientRecordId) {
      // init threads when patientRecordId changes
      // this.ThreadFacadeService.init(this.patientRecordId).then(threads => {
      // the threads are initialised and can be used now. You would do this is you wanted to show a loading spinner whilst threads are being loaded.
      // });
    }
  }

  send() {
    // Send Message
    const threadToEdit: GlThread =
      this.threads && this.threads.length > 0 ? this.threads[0] : null;
    this.sendingMessageInProgress = true;
    let promise;

    // New Thread: !threadtoEdit, && PatientRecordId
    if (!threadToEdit) {
      promise = this.ThreadFacadeService.newThread(
        this.messageBody,
        this.patientRecordId
      );
    }

    // New Message: threadtoEdit && !editMessage
    if (threadToEdit && !this.messageToEdit) {
      promise = this.ThreadFacadeService.newMessage(
        this.messageBody,
        threadToEdit
      );
    }

    // Edit Message: editMessage
    if (this.messageToEdit) {
      promise = this.ThreadFacadeService.editMessage(
        this.messageBody,
        this.messageToEdit
      );
    }

    promise
      .then(() => {
        this.messageBody = "";
        this.editMode = false;
        this.messageToEdit = null;
      })
      .finally(() => (this.sendingMessageInProgress = false));
  }

  edit(message: GlMessage) {
    this.editMode = true;
    this.messageToEdit = message;
    this.messageBody = message.body;
  }

  cancelEdit() {
    this.editMode = false;
    this.messageToEdit = null;
    this.messageBody = "";
  }

  delete(message: GlMessage) {
    let confirmDelete = false;
    confirmDelete = this.$window.confirm(
      "Are you sure you want to delete this message?"
    );
    if (confirmDelete) {
      this.deleteInProgress = true;
      this.messageToDelete = message;
      this.ThreadFacadeService.delete(message).finally(() => {
        this.deleteInProgress = false;
      });
      this.messageToDelete = null;
    }
  }

  isManagingOphthal() {
    return (
      this.managingOphthal &&
      this.user &&
      this.managingOphthal.provider.id === this.user.id
    );
  }

  isOptom() {
    return this.user && this.user.type.name === "optometrist";
  }

  haveMessages() {
    return (
      this.threads &&
      this.threads.length > 0 &&
      first(this.threads).messages &&
      first(this.threads).messages.length > 0
    );
  }

  canSendMessages() {
    // anyone from the same clinic can send messages
    return true;
    // return this.isManagingOphthal() || this.isOptom();
  }
}

export class MessengerMessages implements angular.IComponentOptions {
  static selector = "messengerMessages";
  static template = require("./messenger-messages.html");
  static bindings = {
    threads: "<",
    patientRecordId: "<?",
    user: "<",
    managingOphthal: "<",
  };
  static controller = MessengerMessagesController;
}
