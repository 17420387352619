import { isNil } from "lodash";

class GlValidateSignatureController implements angular.IPostLink {
  ngModelCtrl: angular.INgModelController;

  constructor(private $element: JQLite) {
    "ngInject";
  }

  $postLink(): void {
    this.$element.on("change", (e) => {
      // fetch file from input
      const inputTarget: HTMLInputElement = e.target as HTMLInputElement;
      const file: File = inputTarget?.files?.[0];

      // file counts as a blob technically so you can just use it
      if (!isNil(file)) {
        const fr: FileReader = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          // load into view value (this is b64)
          this.ngModelCtrl.$setViewValue(
            fr.result as string,
            "glValidateSignature"
          );
          this.ngModelCtrl.$render();
        };
        fr.onerror = () => {
          // eslint-disable-next-line
        };
      }
    });
  }
}

export class GlValidateSignatureDirective implements angular.IDirective {
  static selector = "glValidateSignature";
  controller = GlValidateSignatureController;
  require = {
    ngModelCtrl: "ngModel",
  };
  scope = {
    ngModel: "<",
    onChangeCustom: "&?",
  };
  bindToController = true;

  constructor() {
    "ngInject";
  }

  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new GlValidateSignatureDirective();
    };
    return directive;
  }
}
