import * as moment from "moment";
import { isDate } from "angular";

export const pipeName = "procedureDate";
export function procedureDate() {
  "ngInject";
  return (dateString: string | number) => {
    if (isDate(dateString)) {
      return dateString;
    }
    const date = moment(dateString, ["X", moment.defaultFormat]);
    return date.toDate();
  };
}
