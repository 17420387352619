import { isArray, isObject } from "angular";
import { Appendix } from "app/core/services/appendix";
import { ValueAutofillService } from "app/core/services/value-autofill/value-autofill.service";
import { cloneDeep, each, get, isFunction, isNil, set } from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import { PatientRecordData } from "models/patient-record.model";

class CopyButtonController implements angular.IController, angular.IOnInit {
  leftSymbol: string;
  rightSymbol: string;
  fields: any;
  record: PatientRecordData;
  clearTooltip?: string = "Clear fields";
  // copy everything over or just by reference?
  mode: "all" | "index" = "all";

  onClear: () => void;

  onCopy: (arg: {
    side: string;
    previousRecordData: PatientRecordData;
  }) => void;

  copyFieldsFromCustom: (
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    arg: { fromSide: IGlSideBilateral; toSide: IGlSideBilateral } | any
  ) => void;

  constructor(
    private appendix: Appendix,
    private ValueAutofillService: ValueAutofillService
  ) {
    "ngInject";
  }
  $onInit() {
    this.leftSymbol = this.leftSymbol || "fa-arrow-left";
    this.rightSymbol = this.rightSymbol || "fa-arrow-right";
  }

  onClickRight() {
    // fields[0 is always the original]
    const previousRecordData: PatientRecordData = cloneDeep(this.record);
    this.copyFieldsFrom("right", "left");

    if (this._autofillValueExists("right")) {
      this.handleOnCopy("left", previousRecordData);
    }
  }

  onClickLeft() {
    // fields[0 is always the original]
    const previousRecordData: PatientRecordData = cloneDeep(this.record);
    this.copyFieldsFrom("left", "right");

    // check if autofill on left side means we need to preserve state on right
    if (this._autofillValueExists("left")) {
      this.handleOnCopy("right", previousRecordData);
    }
  }

  handleOnCopy(side: string, previousRecordData: PatientRecordData) {
    if (isFunction(this.onCopy)) {
      this.onCopy({ side, previousRecordData });
    }
  }

  copyFieldsFrom(fromSide: string, toSide: string) {
    // if custom function defined used that first
    if (isFunction(this.copyFieldsFromCustom)) {
      this.copyFieldsFromCustom({ fromSide, toSide });
    } else {
      // otherwise default
      each(this.fields, (field) => {
        const fromValue = get(this.record, `${field}.${fromSide}`);
        set(this.record, `${field}.${toSide}`, cloneDeep(fromValue));
      });
    }
  }

  handleOnClear() {
    if (isFunction(this.onClear)) {
      this.onClear();
    }
  }

  // from the fields provided, try and find if one of them has
  // autofill active
  private _autofillValueExists(side: IGlSideBilateral) {
    const autofillField = this.fields.find((f: string) => {
      const _field = get(this.record, `${f}.${side}`);
      // if array
      if (isArray(_field)) {
        // find any field that potentially has an autofill field
        return _field.find((o) =>
          this.ValueAutofillService.isOptionAutofill(f, o?.key)
        );
      }

      // object
      if (isObject(_field)) {
        return this.ValueAutofillService.isOptionAutofill(f, _field?.key);
      }

      return false;
    });
    // if we found it then autofill exsits
    return !isNil(autofillField);
  }
}
export class CopyButton implements angular.IComponentOptions {
  static selector = "buttonCopy";
  static transclude = true;
  static bindings = {
    mode: "@",
    fields: "<",
    record: "<",
    leftSymbol: "<",
    rightSymbol: "<",
    onCopy: "&",
    onClear: "&",
    clearTooltip: "@?",
    copyFieldsFromCustom: "&?",
  };
  static controller = CopyButtonController;
  static template = require("./button-copy.html");
}
