import {
  IComponentOptions,
  IController,
  IOnChanges,
  IOnChangesObject,
  isFunction,
} from "angular";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  ExtraDetails,
  PatientDocument,
  ReferralDetails,
} from "../../../../../models/patient-document.model";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { AuthService } from "../../../services/auth.service";
import { PatientDocumentService } from "../../../services/patient-document.service";
import "./patient-document.scss";
class PatientDocumentController implements IController, IOnChanges {
  /**
   * Inputs
   */
  disabled = false;
  document: PatientDocument;
  extraDetails: ExtraDetails;
  eye?: IGlSide;
  field: string;
  hasRecord: boolean;
  mode: string;
  needsReview: boolean;
  patient: User;
  record: PatientRecord;
  referralDetails: ReferralDetails;
  tags: string;

  deleteInProgress = false;

  // ref for dropzone
  dzRef: any;
  dzOptions = {
    acceptedFiles: "application/pdf,image/*",
    url: this.getFileUrl.bind(this),
    sendingMultiple: true,
    headers: {
      authorization: `Bearer ${this.AuthService.getAccessToken()}`,
    },
    params: this.getDzUploadParams.bind(this),
    disabled: true,
  };
  dzCallbacks = {
    success: this.dzOnSuccess.bind(this),
  };

  // deflare functions you want to call here
  dzMethods: {
    removeFile?: (file: Dropzone.DropzoneFile) => void;
    removeAllFiles?: (cancelIfNecessary?: boolean) => void;
    disable?: () => void;
    enable?: () => void;
    getQueuedFiles?: () => any;
    getAddedFiles?: () => any;
    getAcceptedFiles?: () => any;
    getAllFiles?: () => any;
  } = {
    disable: () => {
      //not empty
      // this is a proxy that allows us to call the disable method on the dropzone instance
    },
    enable: () => {
      //not empty
      // this is a proxy that allows us to call the enable method on the dropzone instance
    },
    getQueuedFiles: () => {
      // not empty
    },
    getAddedFiles: () => {
      // not empty
    },
    getAcceptedFiles: () => {
      // not empty
    },
  };

  documentDidChange: (arg: { document: PatientDocument }) => void;

  constructor(
    public PatientDocumentService: PatientDocumentService,
    private AuthService: AuthService,
    private API_URL: string,
    private $scope: angular.IScope,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onChanges(onChangesObj: IOnChangesObject): void {
    if (onChangesObj.disabled?.currentValue === true) {
      this.dzMethods.disable();
    } else if (onChangesObj.disabled?.currentValue === false) {
      this.dzMethods.enable();
    }
  }

  getQueuedFiles() {
    return this.dzMethods.getAllFiles();
  }

  removeClicked() {
    this.deleteInProgress = true;
    this.PatientDocumentService.delete(this.document)
      .then(() => (this.document = undefined))
      .finally(() => (this.deleteInProgress = false));
  }

  callDocumentDidChange(document?: PatientDocument) {
    if (isFunction(this.documentDidChange)) {
      this.documentDidChange({ document });
    }
  }

  // NOTE: only this API will be a multiple for the time being
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFileUrl(file: Dropzone.DropzoneFile) {
    return `${this.API_URL}api/v2/patient/${this.patient.id}/document`;
  }

  // TO DO: translate and move this over into upload params for post request
  getDzUploadParams() {
    const params: any = {};
    if (this.record?.id) {
      params.patient_record_id = this.record.id;
    }
    if (this.eye) {
      params.eye = this.eye;
    }
    if (this.tags) {
      params.tags = this.tags;
    }
    if (this.referralDetails) {
      params.referral_details = JSON.stringify(this.referralDetails);
    }

    if (this.extraDetails) {
      params.extra_details = JSON.stringify(this.extraDetails);
    }

    if (this.needsReview) {
      params.review_status = "NEEDS_REVIEW";
    }

    if (this.field) {
      params.field = this.field;
    }

    return params;
  }

  dzOnSuccess(file: Dropzone.DropzoneFile, document: PatientDocument) {
    this.document = new this.PatientDocumentService.ngResource(document);
    if (!this.$scope.$$phase && !this.$scope.$root.$$phase) {
      this.$scope.$apply();
    }

    if (!this.hasRecord) {
      this.toastr.success("Successfully uploaded file");
      this.dzMethods.removeFile(file);
      this.callDocumentDidChange(document);
    }
  }
}

export class PatientDocumentComponent implements IComponentOptions {
  static selector = "patientDocument";
  static template = require("./patient-document.html");
  static bindings = {
    disabled: "<",
    document: "<",
    extraDetails: "<",
    eye: "@?",
    field: "@",
    hasRecord: "<",
    mode: "@",
    needsReview: "<",
    patient: "<",
    record: "<",
    referralDetails: "<",
    tags: "<",
    // Outputs
    documentDidChange: "&?",
  };
  static controller = PatientDocumentController;
}
