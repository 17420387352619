// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import uiRouterModule from "@uirouter/angularjs";
import * as angular from "angular";
import { moduleName as coreModule } from "../../core/core.module";
import { DocumentUploadThumbnailComponent } from "./components/document-upload-thumbnail/document-upload-thumbnail";
import { DocumentUploadsComponent } from "./components/document-uploads/document-uploads";
import { DocumentImportService } from "./services/document-import/document-import.service";
/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (): // $stateProvider: angular.ui.IStateProvider,
// $urlRouterProvider: angular.ui.IUrlRouterProvider
void => {
  "ngInject";
  // $stateProvider.state("main.document-upload", {
  //   url: "/dashboard",
  //   component: DashboardPage.selector,
  //   data: {
  //     requiresLogin: true
  //   }
  // });

  // $urlRouterProvider.otherwise("/dashboard");
};

/**
 * Module Configuration
 */
const configuration = (): void => {
  "ngInject";
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.document-upload", [uiRouterModule, coreModule])
    .component(DocumentUploadsComponent.selector, DocumentUploadsComponent)
    .component(
      DocumentUploadThumbnailComponent.selector,
      DocumentUploadThumbnailComponent
    )
    .service(DocumentImportService.injectionName, DocumentImportService)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
