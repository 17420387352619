import { isFunction, isNil } from "lodash";
import "./diagnosis-icon-popover.scss";

export type DiagnosisAutofillOrigin = "observation" | "diagnosis";
export type DiagnosisAutofillTarget = "diagnosis" | "procedure";

class DiagnosisIconPopoverController
  implements angular.IComponentController, angular.IOnInit
{
  title: string;
  tooltip: string = "Click to show autofill options";
  side: string;
  autofillOrigin: DiagnosisAutofillOrigin = "observation";
  autofillTarget: string = "diagnosis";
  autofillName: string;
  popoverPlacement: string = "top";
  isOrigin: boolean = false;
  isTarget: boolean = false;

  undoAutofill: () => void;
  acceptAutofill: () => void;

  constructor() {
    "ngInject";
  }

  $onInit(): void {
    // handle popover placement for style reasons else default
    if (!isNil(this.side)) {
      switch (this.side) {
        case "left":
          this.popoverPlacement = "top-right";
          break;
        case "right":
          this.popoverPlacement = "top-left";
          break;
        default:
          this.popoverPlacement = "top";
          break;
      }
    }
  }

  handleUndoAutofill() {
    if (isFunction(this.undoAutofill)) {
      this.undoAutofill();
    }
  }

  handleAcceptAutofill() {
    if (isFunction(this.acceptAutofill)) {
      this.acceptAutofill();
    }
  }
}

export class DiagnosisIconPopover implements angular.IComponentOptions {
  static selector = "diagnosisIconPopover";
  static template = require("./diagnosis-icon-popover.html");
  static controller = DiagnosisIconPopoverController;
  static bindings = {
    title: "@",
    tooltip: "<",
    side: "<",
    autofillName: "@?",
    autofillOrigin: "@?",
    autofillTarget: "@?",
    isTarget: "<?",
    isOrigin: "<?",
    popoverPlacement: "@?",
    undoAutofill: "&",
    acceptAutofill: "&",
  };
}
