class DocumentWrapperController implements angular.IController {
  constructor() {
    "ngInject";
  }
}

export class DocumentWrapperComponent implements angular.IComponentOptions {
  static selector = "documentWrapper";
  static template = require("./document-wrapper.html");
  static controller = DocumentWrapperController;
  static bindings = {
    patient: "<",
    document: "<",
    mode: "@?"
  };
}
