import { Appendix, PbsProgramCode } from "../services/appendix";

const appendix: Appendix = new Appendix();
export const pipeName = "programCodeToDrugTypes";
export const programCodeToDrugTypesPipe: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (programCode: PbsProgramCode) => {
    return appendix.getPbsDrugTypeFromProgramCode(programCode)?.join(", ");
  };
};
