import {
  IComponentOptions,
  IController,
  IOnInit,
  isDefined,
  isFunction,
  isUndefined,
} from "angular";
import { Appendix } from "app/core/services/appendix";
import { clamp } from "lodash";
import { GlFormController } from "../../gl-form-controller";
import { IStats } from "../bilateral-number/bilateral-number";

class SegmentFieldNumberController
  extends GlFormController
  implements IController, IOnInit
{
  stats: IStats;
  field: any;

  name: string;
  key: string;
  path: string;
  title: string;

  placeholder: number;

  ngRequired: boolean = true;
  onChange: (arg: { value: number }) => void;

  constructor(private appendix: Appendix) {
    "ngInject";
    super();
  }

  $onInit(): void {
    this.stats = this.appendix.getOptions(this.key);
  }

  handleOnChange(value: number) {
    if (isFunction(this.onChange)) {
      this.onChange({ value });
    }
  }

  validation(event: any) {
    const val: number = parseFloat(event.target.value);
    if (isDefined(this.stats)) {
      if (isUndefined(this.field)) {
        this.field[this.key] = null;
      }
      this.field[this.key] = clamp(val, this.stats.min, this.stats.max);
      this.handleOnChange(this.field[this.key]);
    }
  }

  getPlaceholder() {
    return isUndefined(this.stats)
      ? this.title
      : `${this.stats.min.toFixed(
          this.stats.decimals
        )} - ${this.stats.max.toFixed(this.stats.decimals)}`;
  }
}

export class SegmentFieldNumber implements IComponentOptions {
  static selector = "segmentFieldNumber";
  static template = require("./segment-field-number.html");
  static controller = SegmentFieldNumberController;
  static bindings = {
    field: "<",
    isEditable: "<?",
    name: "@",
    key: "@",
    mode: "@?",
    ngRequired: "<?",
    onChange: "&?",
    path: "@?",
    title: "@",
    valueLabel: "<?",
  };
}
