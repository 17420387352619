import { DiagnosisService } from "app/core/services/diagnosis.service";
import { GlDiagnosisOption } from "models/patient-record.model";

class PatientDiagnosisModifierController
  implements angular.IController, angular.IOnChanges
{
  diagnosis: GlDiagnosisOption;

  modifiers: GlDiagnosisOption[];

  constructor(private DiagnosisService: DiagnosisService) {
    "ngInject";
  }

  $onChanges() {
    if (this.diagnosis) {
      this.modifiers = this.DiagnosisService.getModifiersForDiagnosis(
        this.diagnosis
      );
    }
  }

  // eslint-disable-next-line
  checkBoxDidChange(modifier: GlDiagnosisOption, checked: boolean) {
    // not empty
  }
}

export class PatientDiagnosisModifierComponent
  implements angular.IComponentOptions
{
  static selector = "glPatientDiagnosisModifier";
  static controller = PatientDiagnosisModifierController;
  static template = require("./patient-diagnosis-modifier.component.html");
  static bindings = {
    diagnosis: "<",
  };
}
