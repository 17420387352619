import { GlPlan } from "../../../models/plan.model";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import { PlansService } from "../../core/services/plans.service";

class UserPaymentStep1Controller
  implements angular.IController, angular.IOnInit
{
  patient: IPatientResource;
  patient_id: number;
  saveInProgress = false;
  plans: GlPlan[] = [];

  constructor(
    private toastr: angular.toastr.IToastrService,
    private PatientService: PatientService,
    private $state: angular.ui.IStateService,
    private $stateParams: angular.ui.IStateParamsService,
    private PlansService: PlansService
  ) {
    "ngInject";
  }

  $onInit() {
    this.patient_id = this.$stateParams.patient_id;
    this.patient = this.PatientService.get(this.patient_id);

    this.PlansService.get().then((plans) => (this.plans = plans));
  }

  updateDetails() {
    this.saveInProgress = true;
    this.patient
      .$update()
      .then(() => {
        this.toastr.success("Successfully updated billing details");
        this.$state.go("main.payment-step2", {
          patient_id: this.patient_id,
        });
      })
      .finally(() => (this.saveInProgress = false));
  }
}

export class UserPaymentStep1Component implements angular.IComponentOptions {
  static selector = "glUserPaymentStep1";
  static template = require("./user-payment-step1.html");
  static controller = UserPaymentStep1Controller;
}
