/**
 * Import the Component styles
 */
import "./record-edit.scss";
import { PatientService } from "../../core/services/patient.service";
import { User } from "../../../models/user.model";
import { PatientDocument } from "../../../models/patient-document.model";
import { PatientDocumentService } from "../../core/services/patient-document.service";
import { ProviderService } from "../../core/services/provider.service";

class RecordEditController {
  user: User;
  patient: angular.resource.IResource<User>;
  document: angular.resource.IResource<PatientDocument>;
  rightEnabled = true;
  leftEnabled = true;
  new_record = {};
  gonioEdit = false;
  mode = {
    providers: "edit"
  };
  isEditable = {
    medicalHistory: true
  };

  constructor(
    public PatientService: PatientService,
    public PatientDocumentService: PatientDocumentService,
    public ProviderService: ProviderService
  ) {
    "ngInject";
  }

  $onInit() {
    this.patient = this.PatientService.get(636);
    // this.ProviderService.getByType("gp").$promise.then(r => console.log({ r }));
    // this.ProviderService.get(119).$promise.then(response =>
    //   console.log({ response })
    // );
  }

  toggleGonio(mode: boolean) {
    // console.log({ mode });
    this.gonioEdit = mode;
  }
}

export class RecordEditPage implements angular.IComponentOptions {
  static selector = "recordEdit";
  static template = require("./record-edit.html");
  static controller = RecordEditController;
  static bindings = {
    user: "<"
  };
}
