import { AlertHelperService } from "app/core/services/alert-helper.service";
import { Appendix, IGlOption } from "app/core/services/appendix";
import { defaults, isEmpty } from "lodash";
import { GlAlert } from "models/alert.model";
import { PatientRecordData } from "models/patient-record.model";
import { IGlPatientData, Patient } from "models/user.model";
import { APPOINTMENT_REGEX } from "../../../../core/services/appendix";
import { GlFormController } from "../../gl-form-controller";

class MedicalHistoryController
  extends GlFormController
  implements angular.IController, angular.IOnChanges, angular.IPostLink
{
  // @Inputs()
  showReasonForReferral = true;
  appointmentNotes?: string;

  // formatted
  allergy_alerts_list: string;

  public patient: Patient;
  public record: PatientRecordData;

  public medicalHistoryOptions = [
    {
      key: "current_smoker",
      name: "Current Smoker",
    },
    {
      key: "asthma",
      name: "Asthma",
    },
    {
      key: "hypertension",
      name: "Hypertension",
    },
    {
      key: "diabetes",
      name: "Diabetes",
    },
    {
      key: "sleep_apnea",
      name: "Sleep Apnea",
    },
    {
      key: "has_other",
      name: "Other",
    },
  ];

  public ocularHistoryOptions = [
    {
      key: "diabetic_retinopathy",
      name: "Diabetic Retinopathy",
    },
    {
      key: "macular_degeneration",
      name: "Macular Degeneration",
    },
    {
      key: "Cataract",
      name: "Cataract",
    },

    {
      key: "has_other",
      name: "Other",
    },
  ];

  constructor(
    private appendix: Appendix,
    private AlertHelperService: AlertHelperService,
    private $scope: angular.IScope
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // this updates allergy alerts
    this.$scope.$watch(
      () => this.patient?.data,
      (newPatientData: IGlPatientData) => {
        // filter out allergy alerts
        const allergy_alerts: GlAlert[] =
          this.AlertHelperService.filterAllergyAlerts(newPatientData?.alerts) ??
          [];
        // map it out
        this.allergy_alerts_list = !isEmpty(allergy_alerts)
          ? allergy_alerts.map((a) => a?.message).join("\r\n")
          : undefined;
      },
      true
    );
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (
      (changes.record || changes.isEditable) &&
      this.record &&
      this.isEditable
    ) {
      this._recordDidChange();
    }
  }

  // extra change to attempt to solve medical history not showing up issue
  // this time on full link
  $postLink(): void {
    this._recordDidChange();
  }

  hasHistory(obj: any) {
    return Object.keys(obj || {}).reduce(
      (hasHistory: boolean, k) => hasHistory || !!obj[k],
      false
    );
  }

  getNameFor(key: string, list: { key: string; name: string }[]) {
    const { name } = list.find((item) => item.key === key);
    return name;
  }

  private _recordDidChange() {
    defaults(this.record, {
      presenting_complaint: this.getDefaultPresentingComplaint(),
    });
  }

  private getDefaultPresentingComplaint(): IGlOption {
    const presentingComplaintFromAppointmentNotes =
      this.determinePresentingComplaintFromAppointment(this.appointmentNotes);
    return (
      presentingComplaintFromAppointmentNotes ??
      (this.appendix.getDefaultKey("presentingComplaint") as IGlOption)
    );
  }

  private determinePresentingComplaintFromAppointment(
    appointmentNotes: string
  ): IGlOption {
    if (!appointmentNotes || typeof appointmentNotes !== "string") {
      return;
    }

    const lowercaseAppointmentNotes = appointmentNotes.toLocaleLowerCase();
    /**
     * 104R 105R 105IJ OPINJ
     */

    const isRetinaRegex = APPOINTMENT_REGEX.Retina.regex;
    const isGlaucomaRegex = APPOINTMENT_REGEX.Glaucoma.regex;
    const isCataractRegex = APPOINTMENT_REGEX.Cataract.regex;

    const allOptions = this.appendix.get("presentingComplaint");

    if (isRetinaRegex.test(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "retina");
    } else if (isGlaucomaRegex.test(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "glaucoma");
    } else if (isCataractRegex.test(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "cataract");
    } else {
      return allOptions.find((o) => o.key === "cataract");
    }
  }
}

export class MedicalHistory implements angular.IComponentOptions {
  static selector = "medicalHistory";
  static template = require("./medical-history.html");
  static controller = MedicalHistoryController;
  static bindings = {
    isEditable: "<?",
    mode: "@?",
    record: "<",
    patient: "<",
    enableLeft: "<",
    enableRight: "<",
    showReasonForReferral: "<?",
    appointmentNotes: "<?",
  };
}
