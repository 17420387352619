import { PageTitleService } from "app/core/services/page-title.service";
import { capitalize, find, findLast, first, isEmpty, last } from "lodash";
import { PatientRecord } from "../../../models/patient-record.model";
import {
  DocumentsService,
  IRecordDocuments,
} from "../../core/services/documents-service/documents.service";
import { PatientRecordService } from "../../core/services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";
import "./document-viewer.scss";

/**
 * @deprecated
 * unused and can be removed
 */
class DocumentViewerController implements angular.IController {
  patientId = this.$stateParams.patient_id;
  recordKey = parseInt(this.$stateParams.record_id, 10);
  side = this.$stateParams.side as string;
  patient: IPatientResource;
  recordHistory: PatientRecord[];
  baseLineRecord: PatientRecord;
  currentRecord: PatientRecord;
  showHistory = false;
  baselineDocuments: IRecordDocuments;
  currentDocuments: IRecordDocuments;
  viewMode: "compare" | "grid" = "compare";

  constructor(
    private $stateParams: angular.ui.IStateParamsService,
    private PatientRecordService: PatientRecordService,
    private PatientService: PatientService,
    private toastr: angular.toastr.IToastrService,
    private DocumentsService: DocumentsService,
    private PageTitleService: PageTitleService
  ) {
    "ngInject";
  }

  ////////////////

  $onInit() {
    this.patient = this.PatientService.get(this.patientId);
    this.patient.$promise.then((patient) =>
      this.PageTitleService.set(
        `${patient.data.first_name} ${patient.data.last_name} - ${capitalize(
          this.side
        )} Eye Documents - GlaucoNet`
      )
    );
    this.PatientRecordService.getRecordHistoryForUser(this.patientId)
      .then((records) => {
        this.recordHistory =
          this.PatientRecordService.getPatientRecordsForDisplay(records);

        this.setBaselineRecord(first(this.recordHistory));
        this.setCurrentRecord(this.getRecordUsingRecordId(this.recordKey));

        // Once all the patient's records have been loaded, check if the app
        // should default to compare or grid mode
        this.viewMode = this.defaultViewMode(records);
      })
      .catch(() => {
        this.toastr.error("Could not find the patient");
      });
  }

  getRecordUsingRecordId(recordId: number) {
    const record = this.recordHistory
      .filter((r) => !isEmpty(r.documents))
      .find((r) => r.id >= recordId);
    return record || last(this.recordHistory);
  }

  getNextBaselineRecord() {
    const record = this.recordHistory.find(
      (r) => r.id > this.baseLineRecord.id
    );
    this.setBaselineRecord(record);
  }

  getPreviousBaselineRecord() {
    const record = findLast(this.recordHistory, (record) => {
      return record.id < this.baseLineRecord.id;
    });
    this.setBaselineRecord(record);
  }

  setBaselineRecord(record: PatientRecord) {
    if (record) {
      this.baseLineRecord = record;
      this.baselineDocuments = this.DocumentsService.getNamedDocuments(
        record.documents
      );
    }
  }

  getNextCurrentRecord() {
    const record = find(this.recordHistory, (record) => {
      return record.id > this.currentRecord.id;
    });
    this.setCurrentRecord(record);
  }

  getPreviousCurrentRecord() {
    const record = findLast(this.recordHistory, (record) => {
      return record.id < this.currentRecord.id;
    });
    this.setCurrentRecord(record);
  }

  setCurrentRecord(record: PatientRecord) {
    if (record) {
      this.currentRecord = record;
      this.currentDocuments = this.DocumentsService.getNamedDocuments(
        record.documents
      );
    }
  }

  private defaultViewMode(records: PatientRecord[] = []) {
    const numberOfRecordsWithDocuments = records.filter(
      (r) => r.documents && r.documents.length > 0
    ).length;
    return numberOfRecordsWithDocuments > 1 ? "compare" : "grid";
  }
}

export class DocumentViewerComponent implements angular.IComponentOptions {
  static selector = "documentViewer";
  static template = require("./document-viewer.html");
  static controller = DocumentViewerController;
}
