/**
 * Import the polyfills and vendor files
 */
import "./polyfills";
import "./vendor";

/**
 * Import the global styles
 */
import "./index.scss";

// import "./assets/images/favicon.ico";

/**
 * Temporary Import angular
 * see: https://github.com/Microsoft/TypeScript/issues/10178
 */
import * as angular from "angular";

/**
 *  Import module to be bootstrapped
 */
import { moduleName as appModule } from "./app/app.module";

/**
 * Bootstrap the application using the imported moduleName
 */
// eslint-disable-next-line
const bootstrapModuleName = angular.module("gl.bootstrap", [appModule]).name;
