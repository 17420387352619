import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";

class GlDocumentController
  extends GlFormController
  implements angular.IController {
  ngModel: any;
  ngModelCtrl: angular.INgModelController;
  title: string;

  constructor() {
    "ngInject";
    super();
  }

  textAreaOnChange() {
    this.ngModelCtrl.$setViewValue(this.ngModel);
  }
}

export class GeneralFindings implements angular.IComponentOptions {
  static selector = "glGeneralFindings";
  static template = require("./general-findings.html");
  static require = {
    ngModelCtrl: "ngModel",
  };
  static bindings = {
    isEditable: "<?",
    mode: "@?",
    ngModel: "<",
    title: "@",
    record: "<",
    ...GlFormControllerBindings,
  };
  static controller = GlDocumentController;
}
