import { set } from "lodash";
import { PatientRecordData } from "models/patient-record.model";
import { GlFormController } from "../../gl-form-controller";

class BilateralTextareaController
  extends GlFormController
  implements angular.IController
{
  field: PatientRecordData;
  key: string;

  ngRequired = true;

  constructor() {
    "ngInject";
    super();
  }

  /**
   * @ignore
   * IGNORE UNTIL WE REINTRODUCE BILATERAL TEXT CLEAR AGAIN
   */
  clearText() {
    set(this.field, this.key, {
      left: "",
      right: "",
    });
  }
}

export class BilateralTextareaComponent implements angular.IComponentOptions {
  static selector = "bilateralTextarea";
  static template = require("./bilateral-textarea.html");
  static controller = BilateralTextareaController;
  static bindings = {
    field: "<",
    key: "@",
    title: "@",
    enableLeft: "<",
    enableRight: "<",
    copy: "<",
    leftIcon: "@",
    rightIcon: "@",
    mode: "@?",
    ngRequired: "<?",
    isEditable: "<?",
  };
}
