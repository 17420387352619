import { defaults, get, isEmpty, clamp } from "lodash";
import { Appendix } from "../../../services/appendix";
import { GlFormController } from "../../gl-form-controller";

class RefractionController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  record: any;
  enableLeft = true;
  enableRight = true;
  required = true;
  sphereInputOpts = this.appendix.getOptions("sphere");
  cylinderInputOpts = this.appendix.getOptions("cylinder");
  axisInputOpts = this.appendix.getOptions("axis");
  ngRequired = true;

  constructor(public appendix: Appendix) {
    "ngInject";
    super();
  }

  // Validate Sphere
  validateSphere(side: string, event: any) {
    const val = parseFloat(event.target.value);
    if (val) {
      this.record.refraction.sphere[side] = clamp(
        val,
        this.sphereInputOpts.min,
        this.sphereInputOpts.max
      );
    }
  }

  // Validate Cylinder
  validateCylinder(side: string, event: any) {
    const val = parseFloat(event.target.value);
    if (val) {
      this.record.refraction.cylinder[side] = clamp(
        val,
        this.cylinderInputOpts.min,
        this.cylinderInputOpts.max
      );
    }
  }

  // Validate Axis
  validateAxis(side: string, event: any) {
    const val = parseFloat(event.target.value);
    if (val) {
      this.record.refraction.axis[side] = clamp(
        val,
        this.axisInputOpts.min,
        this.axisInputOpts.max
      );
    }
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.record && this.record && this.isEditable) {
      defaults(this.record, {
        refraction: {
          sphere: {},
          axis: {},
          cylinder: {},
        },
      });
    }
  }

  // this returns true if the
  hasCylinder(cylinderValue: number | string) {
    return +cylinderValue !== 0;
  }

  hasRefraction() {
    return !isEmpty(get(this.record, "refraction"));
  }
}

export class RefractionComponent implements angular.IComponentOptions {
  static selector = "refraction";
  static template = require("./refraction.html");
  static bindings = {
    record: "<",
    mode: "@?",
    isEditable: "<?",
    enableLeft: "<?",
    enableRight: "<?",
    required: "<?",
    ngRequired: "<?",
  };
  static controller = RefractionController;
}
