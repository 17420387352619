import {
  IComponentController,
  IOnChanges,
  IOnChangesObject,
  copy,
} from "angular";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { DrugDbRecordPbsV2 } from "models/gl-drug";
import { User } from "models/user.model";
import { GlFormController } from "../../../../core/components/gl-form-controller";

import { DrugsDbService } from "app/core/services/drugs-db/drugs-db.service";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { defaultsDeep, isFunction } from "lodash";
import "./gl-custom-drug.scss";

export class GlCustomDrugController
  extends GlFormController
  implements IComponentController, IOnChanges
{
  isEditable: boolean;

  user: User;
  // drug we are using as reference
  customDrug: DrugDbRecordPbsV2;
  customDrugModel: DrugDbRecordPbsV2;

  // custom drug form
  customDrugForm: angular.IFormController;

  saveInProgress: boolean;
  deleteInProgress: boolean;

  // authority drug toggle
  isAuthorityDrug: boolean = false;

  onSave: (arg: { drug: DrugDbRecordPbsV2 }) => void;
  onUpdate: (arg: { drug: DrugDbRecordPbsV2 }) => void;
  onDelete: (arg: { drug: DrugDbRecordPbsV2 }) => void;
  onCancel: () => void;
  onCustomDrugsUpdate: () => void;

  constructor(
    private PrescriptionsService: PrescriptionsService,
    private DrugsDbService: DrugsDbService,
    private ErrorAppendix: ErrorAppendix
  ) {
    "ngInject";
    super();
  }

  // eslint-disable-next-line
  $onChanges(changes: IOnChangesObject): void {
    if (changes.customDrug && this.customDrug) {
      this.customDrugModel = copy(this.customDrug);
      defaultsDeep(this.customDrugModel, {
        number_of_repeats: 5,
        maximum_quantity_units: 1,
        version: 2,
      });

      // if authority flip
      this.isAuthorityDrug = this.PrescriptionsService.isAuthorityDrug(
        this.customDrugModel.benefit_type_code
      );
    }
  }

  toggleAuthorityScript() {
    this.isAuthorityDrug = !this.isAuthorityDrug;
    this.customDrugForm.$setDirty();
  }

  // handle if save or create
  saveDrugClicked() {
    // first check formatting on authority drug info
    if (!this.isAuthorityDrug) {
      // if not authority ensure to remove all info on that
      this.customDrugModel = {
        ...this.customDrugModel,
        benefit_type_code: "U",
        restriction_number: null,
        treatment_of_code: null,
        restriction_text: null,
      };
    } else {
      this.customDrugModel = {
        ...this.customDrugModel,
        benefit_type_code: "A",
      };
    }

    // then ensure to generate the id based on the data created
    this.customDrugModel.li_item_id = this.DrugsDbService.generateDrugLiItemId({
      item_code: this.customDrugModel.pbs_code,
      brand_name: this.customDrugModel.brand_name,
      mp_pt: this.customDrugModel.drug_name,
      tpuu_or_mpp_pt: this.customDrugModel.schedule_form,
      mq: this.customDrugModel.maximum_quantity_units,
      repeats: this.customDrugModel.number_of_repeats,
    });

    // save and create will be handled outside
    if (isFunction(this.onSave)) {
      this.onSave({ drug: this.customDrugModel });
    }
  }

  handleOnCancel() {
    this.customDrugModel = undefined;
    if (isFunction(this.onCancel)) {
      this.onCancel();
    }
  }

  deleteDrug() {
    if (isFunction(this.onDelete)) {
      this.onDelete({ drug: this.customDrugModel });
    }
  }
}

export class GlCustomDrug implements IComponentController {
  static selector = "glCustomDrug";
  static template = require("./gl-custom-drug.html");
  static controller = GlCustomDrugController;
  static bindings = {
    mode: "@",
    isEditable: "<",

    user: "<",
    customDrug: "<",

    saveInProgress: "<",
    deleteInProgress: "<",

    onSave: "&",
    onUpdate: "&",
    onDelete: "&",
    onCancel: "&",

    onCustomDrugsUpdate: "&",
  };
}
