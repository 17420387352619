// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { moduleName as SettingsPageModule } from "../main.settings/settings.module";
import { SettingsProcedureOptionsComponent } from "./settings-procedure-options";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.settings.procedure-options", {
    url: "/settings/procedure-options",
    component: SettingsProcedureOptionsComponent.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.settings.procedure-options", [
      SettingsPageModule,
      "ui.router",
      "gl.core",
    ])

    /**
     * Register Module Components
     */
    .component(
      SettingsProcedureOptionsComponent.selector,
      SettingsProcedureOptionsComponent
    )
    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
