import { has } from "lodash";
import { API_PATH } from "./api-paths";

export class ProviderModel {
  static injectionName = "providerModel";
  private cache: angular.ICacheObject;
  private API_BASE = `${this.API_URL}${API_PATH}`;

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $resource: angular.resource.IResourceService,
    private $http: angular.IHttpService
  ) {
    "ngInject";
    this.cache = this.$cacheFactory.get("$http");
  }

  updateWithNewUser(provider: any) {
    const url = this.API_BASE + "/provider/with_new_user";

    return this.$http.put(url, provider);
  }

  update(provider: any) {
    const url = this.API_BASE + "/provider";

    return this.$http.put(url, provider);
  }

  // Gets all data for a single patient
  getAllByType(type: "gp" | "optometrist" | "all" = "all") {
    const url = this.API_BASE + "/providers/" + type;
    // Request to the API for the user associated with the token
    return this.$http.get(url, { cache: true });
  }

  getById(id: string | number) {
    const url = this.API_BASE + "/providers?id=" + id;
    // Request to the API for the user associated with the token
    return this.$http.get(url);
  }

  getPatientsForProvider(id: string | number) {
    const url = this.API_BASE + "/provider/" + id + "/patients";
    // Request to the API for the user associated with the token
    return this.$http.get(url);
  }

  // Gets all data for a single patient
  getByUserId(user_id: number) {
    const url = this.API_BASE + "/provider/by_user/" + user_id;
    // Request to the API for the user associated with the token
    return this.$http.get(url);
  }

  create(provider: any) {
    if (has(provider, "email") && has(provider, "username")) {
      return this.saveNewWithUser(provider);
    } else {
      return this.saveNew(provider);
    }
  }

  // Saves a new provider
  saveNewWithUser(provider: any) {
    const url = this.API_BASE + "/provider/with_user";

    return this.$http.post(url, provider);
  }

  // Saves a new provider and user
  saveNew(provider: any) {
    const url = this.API_BASE + "/provider";

    return this.$http.post(url, provider);
  }

  destroy(id: number) {
    const url = this.API_BASE + "/provider/" + id;
    // Request to the API for the user associated with the token
    return this.$http.delete(url);
  }

  validateProviderNumberLocal(input: string) {
    if (!input) {
      return true;
    }

    // The Medicare provider number comprises:
    //  - six digits (provider stem)
    //  -  a practice location character (one alphanum char)
    //  -  a check-digit (one alpha character)
    const locTable = "0123456789ABCDEFGHJKLMNPQRTUVWXY";
    const checkTable = "YXWTLKJHFBA";
    const weights = [3, 5, 8, 4, 2, 1];
    const re = /^(\d{5,6})([0123456789ABCDEFGHJKLMNPQRTUVWXY])([YXWTLKJHFBA])$/;

    const providerNumber = input.toString().replace(/ /g, "");
    const matches = providerNumber.match(re);
    if (matches) {
      let stem = matches[1];
      // accommodate dropping of leading 0
      if (stem.length === 5) {
        stem = "0" + stem;
      }

      const location = matches[2];
      const checkDigit = matches[3];

      // IMPORTANT - letters I, O, S and Z are not included
      // Some documentation incorrectly removes the digit 1.
      const plv = locTable.indexOf(location);
      const sum = weights.reduce(function (
        sum: number,
        weight: number,
        index: number
      ) {
        return sum + parseInt(stem[index], 10) * weight;
      },
      plv * 6);

      return checkDigit === checkTable[sum % 11] ? true : false;
    }
    return false;
  }
}
