import * as moment from "moment";

export const pipeName = "agePipe";
export function agePipe() {
  "ngInject";
  return (dob: number, fromDate?: string) => {
    // DOB is stored as a unix time stamp in seconds
    if (dob) {
      const dateOfBirth = new Date(dob * 1000);
      const now = fromDate ? moment(fromDate) : moment();
      return now.diff(dateOfBirth, "years");
    }
  };
}
