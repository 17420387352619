import * as angular from "angular";
import { moduleName as SettingsPageModule } from "../main.settings/settings.module";
import { PatientMergeComponent } from "./components/patient-merge/patient-merge";
import { SettingsAdministrativePageComponent } from "./settings-administrative";

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.settings.administrative", {
    url: "/settings/administrative",
    component: SettingsAdministrativePageComponent.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.settings.administrative", [
      SettingsPageModule,
      "ui.router",
      "gl.core",
    ])

    /**
     * Register Module Components
     */
    .component(
      SettingsAdministrativePageComponent.selector,
      SettingsAdministrativePageComponent
    )
    .component(PatientMergeComponent.selector, PatientMergeComponent)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
