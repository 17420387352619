import { GlThread } from "../../../models/messages.model";
import { API_PATH_v2 } from "./api-paths";
import { IGlApiResponse } from "../../../models/gl-api-response.model";

export class ThreadService {
  static injectionName = "ThreadService";

  private baseUrl = `${this.API_URL}${API_PATH_v2}`;

  constructor(private $http: angular.IHttpService, private API_URL: string) {
    "ngInject";
  }

  getAll(
    patientRecordId: number = null,
    clinicId: number = null,
    unRead: number = 0,
    clinicMessages: number = 0
  ) {
    const params = {
      record_id: patientRecordId,
      clinic_id: clinicId,
      unread: unRead,
      clinic_msgs: clinicMessages,
    };

    return this.$http
      .get<IGlApiResponse<GlThread[]>>(`${this.baseUrl}/thread`, { params })
      .then((response) => response.data.data);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getThreadById(threadId: number) {
    return {};
  }

  // Post reply to existing thread
  postReply(threadId: number, message: string) {
    return this.$http
      .post<IGlApiResponse<GlThread>>(`${this.baseUrl}/thread/${threadId}`, {
        body: message,
      })
      .then((response) => response.data.data);
  }

  // Create new thread and post message
  newThread(message: string, patientRecordId?: number) {
    return this.$http
      .post<IGlApiResponse<GlThread>>(`${this.baseUrl}/thread`, {
        body: message,
        patient_record_id: patientRecordId,
      })
      .then((response) => response.data.data);
  }

  // Post reply to existing thread
  editMessage(messageId: number, message: string) {
    return this.$http
      .put<IGlApiResponse<GlThread>>(`${this.baseUrl}/message/${messageId}`, {
        body: message,
      })
      .then((response) => response.data.data);
  }

  // Delete Message
  deleteMessage(messageId: number) {
    return this.$http
      .delete<IGlApiResponse<GlThread>>(`${this.baseUrl}/message/${messageId}`)
      .then((response) => response.data.data);
  }
}
