import { isFunction } from "angular";
import { PatientRecordData } from "models/patient-record.model";
import { IGlFormMode } from "../../../../../models/gl-form-mode";
import angular = require("angular");

interface ICardScope extends angular.IScope {
  cardForm: angular.IFormController;
}
// common card state, should be exported relative to parent
export interface IGlCardState {
  collapsed: boolean;
  isEditable: boolean;
  mode: IGlFormMode;
  leftEnabled: boolean;
  rightEnabled: boolean;
}

export class CardController implements angular.IController {
  collapsed = false;
  allowEditing = false;
  leftEnabled: boolean;
  rightEnabled: boolean;
  isEditable = false;
  record: PatientRecordData;
  mode: IGlFormMode = "edit";
  isEditableDidChange: (arg: { isEditable: boolean }) => void;
  displayModeDidChange: (arg: { mode: IGlFormMode }) => void;

  constructor(
    private $scope: ICardScope,
    private $window: angular.IWindowService
  ) {
    "ngInject";
  }

  getForm() {
    return this.$scope.cardForm;
  }

  toggleIsEditable() {
    let canChangeMode = true;
    if (this.isEditable) {
      canChangeMode = this.$window.confirm(
        "Do you want to discard your changes?"
      );
    }
    if (canChangeMode) {
      const isEditable = !this.isEditable;
      this.isEditableDidChange({ isEditable });
      this.getForm().$setPristine();
    }
  }

  toggleEditMode() {
    const mode = this.isEditMode() ? "display" : "edit";
    if (isFunction(this.displayModeDidChange)) {
      this.displayModeDidChange({ mode });
    }
  }

  isEditMode() {
    return this.mode === "edit";
  }
}

export class Card implements angular.IComponentOptions {
  static selector = "card";
  static transclude = {
    title: "cardTitle",
    content: "cardContent",
    link: "?cardLink",
    buttons: "?cardTitleButtons",
  };
  static template = require("./card.html");
  static controller = CardController;
  static bindings = {
    collapsed: "<?",
    allowEditing: "<?",
    leftEnabled: "<?",
    rightEnabled: "<?",
    record: "<",
    isEditable: "<?editable",
    mode: "@?displayMode",
    isEditableDidChange: "&?",
    displayModeDidChange: "&?",
  };
}
