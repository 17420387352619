import { IOnChangesObject } from "angular";
import { GlModelService } from "app/core/services/gl-model.service";
import { get } from "lodash";
import { PatientRecordData } from "../../../../../models/patient-record.model";
import { GlFormController } from "../../gl-form-controller";
import "./segment-management-comments.scss";
export class SegmentManagementCommentsController
  extends GlFormController
  implements angular.IController
{
  recordData: PatientRecordData;
  recordHistory: PatientRecordData;
  showAdminComments = true;

  constructor(private GlModelService: GlModelService) {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    if (this.recordData && changes?.recordData) {
      this.recordHistory = this.GlModelService.consolidatedRecordHistory;
    }
  }

  isCommentArray() {
    return Array.isArray(get(this.recordData, "management.comments"));
  }
}

export class SegmentManagementCommentsComponent
  implements angular.IComponentOptions
{
  static selector = "segmentManagementComments";
  static template = require("./segment-management-comments.html");
  static controller = SegmentManagementCommentsController;
  static bindings = {
    mode: "@?",
    isEditable: "<?",
    recordData: "<",
    showAdminComments: "<?",
  };
}
