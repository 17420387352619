import { GlProvider } from "../../../../../models/patient-record.model";

class GlProviderController implements angular.IController {
  provider: GlProvider;
  mode: string;
  // type is the key used by gl-model to bring forward previous providers
  type: string;
  glModelPath: string;

  constructor() {
    "ngInject";
  }

  faxNumberForLink() {
    if (this.provider?.fax) {
      return this.provider.fax.replace(/\s+/g, "");
    }
  }
}

export class GlPatientProviderComponent implements angular.IComponentOptions {
  static selector = "glPatientProvider";
  static template = require("./gl-patient-provider.html");
  static controller = GlProviderController;
  static bindings = {
    mode: "@",
    provider: "<",
    type: "@",
    glModelPath: "<",
  };
}
