import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  copy,
} from "angular";
import { Appendix } from "app/core/services/appendix";
import {
  ErrorAppendix,
  GlErrorMessage,
} from "app/core/services/error-appendix.service";
import { UserFavouriteDrugsService } from "app/core/services/user-favourite-drugs.service.ts/user-favourite-drugs.service";
import {
  defaultsDeep,
  get,
  isDate,
  isEmpty,
  isFunction,
  isNil,
  some,
  uniq,
} from "lodash";
import { PatientProcedureDrug } from "models/patient-procedure";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { GlStaff } from "models/user.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { GlFormController } from "../../gl-form-controller";
import "./gl-drug.scss";

export class GlDrugController
  extends GlFormController
  implements IComponentController, IOnChanges
{
  drug: PatientProcedureDrug;
  drugModel: PatientProcedureDrug;

  recordId: number;
  drugFrequencyOptions = this.appendix.get("drugFrequency");
  drugRootOptions = this.appendix.get("drugRoot");
  showAuthorityField: boolean = false;
  disableCreate: boolean = false;

  prescriber: GlStaff;

  // to handle adding/removing from favourite groups
  selectedFavouriteGroupOption: string;
  selectedFavouriteGroups: string[] = [];
  // maintain integrity
  otherFavouriteGroupIds: number[] = [];
  currentFavouriteGroup: UserFavouriteDrugGroup;

  favouriteDrugs: PatientProcedureDrug[];
  favouriteDrugGroups: UserFavouriteDrugGroup[];
  selectableFavouriteDrugGroups: UserFavouriteDrugGroup[];

  globalGroupId: number = this.UserFavouriteDrugsService.getGlobalGroupId();

  drugSaveErrors: GlErrorMessage[] = [];
  drugSaveWarnings: GlErrorMessage[] = [];

  // discontinuation
  discontinuationReasons = this.appendix.get(
    "treatmentReasonForDiscontinuation"
  );

  // error messages
  drugErrorMessages = this.ErrorAppendix.getDrugErrorMessages();

  // dates
  procedureDatePopup = { opened: false };
  procedureEndDatePopup = { opened: false };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };

  // functions
  onCreate: (arg: { drug: PatientProcedureDrug }) => void;
  onSave: (arg: { drug: PatientProcedureDrug }) => void;
  onDelete: (arg: { drug: PatientProcedureDrug }) => void;

  constructor(
    public appendix: Appendix,
    private ErrorAppendix: ErrorAppendix,
    private UserFavouriteDrugsService: UserFavouriteDrugsService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject): void {
    if (changes.drug && this.drug) {
      this.drugModel = copy(this.drug);
      defaultsDeep(this.drugModel, {
        data: {
          treatment_start_date: new Date(),
          favourite: false, // default not a favourite drug
          drop_number: 1,
          quantity: 1,
          repeats: 5,
          dose: 1,
          frequency: "BD",
        },
      });

      // convert any treatment_start/end_date to date objects
      const startDate = get(this.drugModel, "data.treatment_start_date");
      const endDate = get(this.drugModel, "data.treatment_end_date");
      if (startDate && !isDate(startDate)) {
        this.drugModel.data.treatment_start_date = new Date(startDate);
      }
      if (endDate && !isDate(endDate)) {
        this.drugModel.data.treatment_end_date = new Date(endDate);
      }

      // if its associated with groups ensure theyre included for selection
      const drugGroupIds = get(this.drugModel, "favourite_groups");
      this.selectableDrugGroupsOnChange(drugGroupIds);
    }

    // check for errors
    if (
      (changes?.drug && this?.drug?.data) ||
      (changes?.favouriteDrugs && this?.favouriteDrugs) ||
      (changes?.favouriteDrugGroups && this.favouriteDrugGroups)
    ) {
      this.handleErrorAndWarningChecks(this.drugModel);
    }

    // general filtering
    if (changes.favouriteDrugGroups && this.favouriteDrugGroups) {
      this.selectableFavouriteDrugGroups = this.filterSelectableDrugGroups(
        this.favouriteDrugGroups
      );
    }
  }

  // filters selectable drug groups based on certain logic
  filterSelectableDrugGroups(groups: UserFavouriteDrugGroup[]) {
    // check for global
    // return cases where either no sepcific user restriciton
    // or if exists matches
    return groups.filter((g) => {
      return (
        isEmpty(g.editable_by_user_ids) ||
        (g.editable_by_user_ids &&
          g.editable_by_user_ids.includes(this?.prescriber.id))
      );
    });
  }

  selectableDrugGroupsOnChange(drugGroupIds: number[]) {
    const _selectedFavouriteGroups: string[] = [];
    const _otherFavouriteGroupIds: number[] = [];

    // sort by whether group belongs to user or is a favourite
    if (!isEmpty(drugGroupIds)) {
      for (const groupId of drugGroupIds) {
        const _found = this.favouriteDrugGroups.find((g) => g.id === groupId);
        if (
          !isNil(_found) &&
          // is global group or can be selected
          (_found.id === this.globalGroupId ||
            this.canSelectFavouriteGroup(_found.name))
        ) {
          _selectedFavouriteGroups.push(_found.name);
        } else {
          _otherFavouriteGroupIds.push(groupId);
        }
      }
    }

    // final check if a current favourite group exists
    if (this.currentFavouriteGroup) {
      this.drugModel.data.favourite = true;
      _selectedFavouriteGroups.push(this.currentFavouriteGroup.name);
    }
    // all names here must be unique
    this.selectedFavouriteGroups = uniq(_selectedFavouriteGroups);
    // index 1 are the ones we must preserve when saving
    this.otherFavouriteGroupIds = _otherFavouriteGroupIds;
  }

  // name is used here because on select the name value is passed on to
  // ng-change
  handleSelectFavouriteGroup(groupName: string, mode: "add" | "remove") {
    // determine if they have permission/authority to add
    /**
     * check:
     * if they own
     * if they have access if they dont
     */
    if (isNil(groupName) || !this.canSelectFavouriteGroup(groupName)) {
      return;
    }

    // adding works if it just doesnt exist
    // this also accounts for adding via select and duplicates
    if (mode === "add") {
      if (!this.selectedFavouriteGroups.includes(groupName)) {
        this.selectedFavouriteGroups.push(groupName);
      }
    } else {
      // otherwise we remove if manually excluded
      const indexOfSelectedGroupName: number =
        this.selectedFavouriteGroups.findIndex((g) => g === groupName);

      if (indexOfSelectedGroupName >= 0) {
        this.selectedFavouriteGroups.splice(indexOfSelectedGroupName, 1);
      }
    }

    this.handleErrorAndWarningChecks(this.drugModel);
  }

  // date
  openDatepicker(popup: string) {
    if (popup !== "procedure_end_date") {
      this.procedureDatePopup.opened = true;
    } else {
      this.procedureEndDatePopup.opened = true;
    }
  }

  // end date
  endDateDidChange() {
    // if this drop has an endData - set the record id - otherwise clear it
    if (isDate(this.drugModel.data.treatment_end_date)) {
      this.drugModel.data.treatment_end_record_id = this.recordId;
    } else {
      delete this.drugModel.data.treatment_end_record_id;
    }
  }

  // show save buttons
  showSaveButtons() {
    return this.drugFilledIn() && (this.isEditMode() || this.isCreateMode());
  }

  // can only save if all required fields are filled in
  checkDrugFormat() {
    // cehck values
    const drugValueChecks: boolean = [
      this.drugModel?.data?.mq,
      this.drugModel?.data?.dose,
      this.drugModel?.data?.frequency,
      this.drugModel?.data?.repeats,
      this?.drugModel?.data.root,
    ].every((val: string | number) => !isNil(val));

    return drugValueChecks;
  }

  canCreate() {
    return this.checkDrugFormat() && !this.disableCreate;
  }

  canSave() {
    // check if favourite
    const _favouriteErrors: GlErrorMessage[] =
      this._getDrugErrorByType("favourite");
    if (this.drugModel.data.favourite && !isEmpty(_favouriteErrors)) {
      return false;
    }

    return this.checkDrugFormat();
  }

  // BUTTON ACTIONS
  createClicked() {
    if (isFunction(this.onCreate)) {
      this._beforeOnSaveHandler();
      this.onCreate({ drug: this.drugModel });
    }
  }

  saveClicked() {
    if (isFunction(this.onSave)) {
      this._beforeOnSaveHandler();
      // this is for saving templates
      // creation requires the pre-existing one whilst not saving
      this._userCanSaveGlobalDrugTemplate();
      this.onSave({ drug: this.drugModel });
    }
  }

  deleteDrug() {
    if (isFunction(this.onDelete)) {
      this.onDelete({ drug: this.drugModel });
    }
  }

  toggleCreateAsFavourite() {
    this.drugModel.data.favourite = !this.drugModel.data.favourite;
    // if deselected, clear all favourites
    if (!this.drugModel.data.favourite) {
      this.selectedFavouriteGroups = [];
    }
  }

  // form related
  drugFilledIn() {
    return this.drugModel.data.brand_name || this.drugModel.data.name;
  }

  // can we add/remove?
  canSelectFavouriteGroup(groupName: string) {
    const _foundGroup: UserFavouriteDrugGroup = this.favouriteDrugGroups.find(
      (g) => g.name === groupName
    );

    // no group
    if (!_foundGroup) {
      return false;
    }

    // is owner or has editing authority
    if (
      _foundGroup.user_id === this.prescriber.id ||
      (!isEmpty(_foundGroup.editable_by_user_ids) &&
        _foundGroup.editable_by_user_ids.includes(this.prescriber.id))
    ) {
      return true;
    }

    // else no
    return false;
  }

  canEditDrug() {
    // regular drug category (created) or default (no group)
    if (isNil(this.drug.category) || !isNil(this.currentFavouriteGroup)) {
      return true;
    }

    // if favourite drug and user owns it
    if (
      this.drug.category === "favourite" &&
      this.drug.user_id === this.prescriber.id
    ) {
      return true;
    }

    // if favourite check if its in global
    // global drugs cannot be edited unless its by owner
    if (this._drugNotInGlobalGroup(this.drug)) {
      return true;
    }

    return false;
  }

  isCreatedDrug() {
    return this.drugModel.id !== undefined && this.drugModel.id !== null;
  }

  // if in patient-drugs section you can only save
  canSaveDrug() {
    return (
      (this?.drugModel.type && this?.drugModel.data.favourite) ||
      this?.drugModel.id ||
      this.drugModel.data.favourite
    );
  }

  canDeleteDrug() {
    if (!this?.drugModel?.favourite_groups) {
      return true;
    }
    // check if in favourite group
    if (this._drugNotInGlobalGroup(this.drugModel)) {
      return true;
    }

    // user owns it
    if (
      this.drugModel?.category === "favourite" &&
      this.drugModel?.user_id === this.prescriber.id
    ) {
      return true;
    }

    return false;
  }

  // ERROR HANDLERS
  handleErrorAndWarningChecks(drug: PatientProcedureDrug) {
    this.checkDrugSaveErrors(drug);
    this.checkDrugSaveWarnings(drug);
  }

  // users can add them to their groups but editing is not allowed
  // information on what is missing
  checkDrugSaveErrors(drug: PatientProcedureDrug) {
    const errors: GlErrorMessage[] = [];
    const drugToCheck: PatientProcedureDrug = drug ?? this.drugModel;

    if (
      (drugToCheck.data.favourite || drugToCheck.category === "favourite") &&
      isEmpty(this.selectedFavouriteGroups)
    ) {
      errors.push({
        key: "drug",
        type: "favourite",
        message: this.drugErrorMessages.favourite.group_selection_empty,
      });
    }

    // drug exists
    if (this._favouriteDrugExistsAlready(drugToCheck)) {
      errors.push({
        key: "drug",
        type: "favourite",
        message: this.drugErrorMessages.favourite.drug_exists,
      });
    }

    // values
    if (isNil(drugToCheck.data.mq)) {
      errors.push({
        key: "drug",
        type: "value",
        message: this.drugErrorMessages.drugs.quantity_required,
      });
    }

    if (isNil(drugToCheck.data.dose)) {
      errors.push({
        key: "drug",
        type: "value",
        message: this.drugErrorMessages.drugs.dose_required,
      });
    }

    if (isNil(drugToCheck.data.frequency)) {
      errors.push({
        key: "drug",
        type: "value",
        message: this.drugErrorMessages.drugs.frequency_required,
      });
    }

    if (isNil(drugToCheck.data.repeats)) {
      errors.push({
        key: "drug",
        type: "value",
        message: this.drugErrorMessages.drugs.repeats_required,
      });
    }

    if (isNil(drugToCheck.data.root)) {
      errors.push({
        key: "drug",
        type: "value",
        message: this.drugErrorMessages.drugs.root_required,
      });
    }

    this.drugSaveErrors = errors;
  }

  // eslint-disable-next-line
  checkDrugSaveWarnings(drug: PatientProcedureDrug) {
    const warnings: GlErrorMessage[] = [];

    if (this._containsGlobalGroupInSelection()) {
      warnings.push({
        key: "drug",
        type: "favourite",
        message: this.drugErrorMessages.favourite.drug_in_global_group,
      });
    }

    this.drugSaveWarnings = warnings;
  }

  // private
  private _drugNotInGlobalGroup(drug: PatientProcedureDrug) {
    return (
      drug.category === "favourite" &&
      !isEmpty(drug.favourite_groups) &&
      !drug?.favourite_groups?.includes(1)
    );
  }

  private _favouriteDrugExistsAlready(drug: PatientProcedureDrug) {
    // no drugs to reference
    if (
      !this?.favouriteDrugs ||
      isEmpty(this?.favouriteDrugs) ||
      isNil(drug) ||
      isNil(drug?.data)
    ) {
      return false;
    }

    // editing?
    if (this._checkIfEditingDrug(drug)) {
      return false;
    }

    // find existing
    const existingDrug: PatientProcedureDrug = this?.favouriteDrugs.find(
      (d) => {
        /**
         * duplicate is counted as
         * - same brand name
         * - same item code
         * - same hash id (if applicable)
         */
        return (
          d?.data?.brand_name === drug?.data?.brand_name &&
          d?.data?.id === drug?.data?.id &&
          d?.data?.mp_pt === drug?.data?.mp_pt &&
          d?.data?.item_code === drug?.data?.item_code
        );
      }
    );

    // not empty === exists
    return !isNil(existingDrug);
  }

  private _checkIfEditingDrug(drug: PatientProcedureDrug) {
    // does the user own it and isnt just editing?
    if (
      drug?.user_id === this.prescriber.id &&
      drug?.category === "favourite"
    ) {
      return true;
    }

    // does the user have authority to edit the drug based on the
    // group its in if existing?
    if (
      drug.id &&
      this.UserFavouriteDrugsService.canEditFavouriteGroup(
        this.currentFavouriteGroup,
        this.prescriber
      )
    ) {
      // determine based on whether state will be favourite or not
      return !this.drugModel.data.favourite;
    }

    return false;
  }

  private _beforeOnSaveHandler() {
    if (this.drugModel.data.one_off) {
      this.drugModel.data.treatment_end_date =
        this.drugModel.data.treatment_start_date;
    }

    // if not a favourite delete
    if (!this.drugModel.data.favourite) {
      delete this.drugModel.favourite_groups;
      delete this.drugModel.data.favourite_groups;
      this.drugModel.data.favourite = false;
    } else {
      // otherwise convert to id to apply as an argument
      // merge with non-user owned drugs also
      const _mappedSelectedGroupIds: number[] =
        this.selectedFavouriteGroups.map((groupName) => {
          const _found = this.favouriteDrugGroups.find(
            (g) => g.name === groupName
          );
          return _found.id;
        });

      const _favouriteGroupIds: number[] = uniq(
        _mappedSelectedGroupIds.concat(this.otherFavouriteGroupIds)
      );

      this.drugModel.favourite_groups = _favouriteGroupIds;
      // contingency data
      this.drugModel.data.favourite_groups = _favouriteGroupIds;
    }

    // if we are turning a regular drug into a favourite
    // ensure we reference PRESCRIBER
    if (!this.drug.data.favourite && this.drugModel.data.favourite) {
      this.drugModel.id = null;
      this.drugModel.user_id = this.prescriber.id;
    }
  }

  /**
   * users that cant edit the template can add them to their groups
   * so if they dont have that privelleage they can only edit groups
   * by default this is the global group
   *
   * there is a distinguishment between global and user drugs
   * global drugs cannot be added into user defaults but can be added into groups
   */
  private _userCanSaveGlobalDrugTemplate() {
    // if we find a drug is part of a global group
    if (
      !isNil(this.drugModel.favourite_groups) &&
      this.drugModel.favourite_groups.includes(1)
    ) {
      const globalGroup: UserFavouriteDrugGroup = this.favouriteDrugGroups.find(
        (g) => g.id === this.globalGroupId
      );

      // if we find out the user has no edit privelleages
      // and its an existing drug, override it
      // global group drugs are just templates
      if (
        !this.UserFavouriteDrugsService.canEditFavouriteGroup(
          globalGroup,
          this.prescriber
        ) &&
        this.drugModel.id
      ) {
        this.drugModel.data = this.drug.data;
      }
    }
  }

  // selection has a global group drug
  private _containsGlobalGroupInSelection() {
    return some(
      this.favouriteDrugGroups,
      (g: UserFavouriteDrugGroup) =>
        this?.selectedFavouriteGroups.includes(g.name) && g.type === "global"
    );
  }

  // check if theres specific errors for certain drug type
  private _getDrugErrorByType(type: string) {
    if (isNil(this.drugSaveErrors)) {
      return [];
    }

    return this.drugSaveErrors.filter((e) => e.type === type);
  }
}

export class GlDrug implements IComponentOptions {
  static selector = "glDrug";
  static template = require("./gl-drug.html");
  static controller = GlDrugController;
  static bindings = {
    drug: "<",
    prescriber: "<",
    currentFavouriteGroup: "<",
    favouriteDrugs: "<",
    favouriteDrugGroups: "<",
    onCreate: "&",
    onSave: "&",
    onCancel: "&",
    onDelete: "&",
    mode: "@?",
    recordId: "<",
    isEditable: "<?",
    disableCreate: "<?",
    saveInProgress: "<",
    createInProgress: "<",
    deleteInProgress: "<?",
    showAuthorityField: "<?",
  };
}
