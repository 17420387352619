import { isFunction } from "angular";
import { isEmpty } from "lodash";
import { Contact } from "models/contact.model";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import { PatientRecordData } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { ContactService } from "../../../services/contact.service";
import { PatientContactService } from "../../../services/patient-contact.service";
import { GlFormController } from "../../gl-form-controller";
import "./patient-providers.scss";
import angular = require("angular");
// eslint-disable-next-line
import angularFileUploadDefaultExport = require("ng-file-upload");
class ProvidersController
  extends GlFormController
  implements angular.IController
{
  record: PatientRecordData;
  patient: User;
  managingOptom: PatientClinic;
  newContact: Contact;
  contact: Contact;
  managingOphthal: PatientClinic;
  contacts: Contact[];
  activeTabIndex = 0;
  showTabsInView = false;
  onContactUpdate: () => void;
  savePatientProviderInProgress: boolean;
  deletePatientContactInProgress: boolean;
  emptyContact: Contact = {
    clinic_name: "",
    first_name: "",
    email: "",
    last_name: "",
    address: "",
    phone: "",
    fax: "",
  };

  constructor(
    private ContactService: ContactService,
    private PatientContactService: PatientContactService,
    private $q: angular.IQService,
    private $timeout: angular.ITimeoutService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
    super();
  }

  shouldDisplayContactName(contact: Contact) {
    return contact.first_name && contact.clinic_name;
  }

  convertProviderToContact(providerType: string) {
    this.savePatientProviderInProgress = true;
    return this.$q
      .resolve()
      .then(() => {
        this.createOrSavePatientContact(
          this.record.providers[providerType],
          providerType,
          this.getReferrerTitle(providerType)
        );
      })
      .then(() => {
        delete this.record.providers[providerType];
      })
      .catch((error) => {
        this.toastr.error(error);
      })
      .finally(() => (this.savePatientProviderInProgress = false));
  }

  createOrSavePatientContact(contact: any, type?: string, isReferrer?: string) {
    this.newContact = this.emptyContact;
    let referrer = false;
    this.newContact.clinic_name = contact.clinicName;
    this.newContact.first_name = contact.firstName;
    this.newContact.last_name = contact.lastName;
    this.newContact.email = contact.email;
    this.newContact.address = contact.address;
    this.newContact.phone = contact.phone;
    this.newContact.fax = contact.fax;
    if (isReferrer) {
      referrer = true;
    } else {
      referrer = false;
    }
    this.ContactService.createContact(this.newContact).then((c) => {
      this.PatientContactService.update(
        this.patient.id,
        c,
        type,
        referrer
      ).then(() => {
        this.contactChanged();
      });
    });
  }

  contactChanged() {
    if (isFunction(this.onContactUpdate)) {
      this.onContactUpdate();
    }
  }

  patientHasOptomProvider() {
    return !isEmpty(this.record?.providers?.optometrist);
  }

  patientHasGpProvider() {
    return !isEmpty(this.record?.providers?.gp);
  }

  addToContacts() {
    this.contacts.push(this.emptyContact);
    this.$timeout(250).then(() => {
      // -1 for the end of the contacts and + 2 to pass managing ophthal and
      // managing optom tabs
      this.activeTabIndex = this.contacts.length - 1 + 2;
    });
  }

  getContact(contactId: number) {
    return this.ContactService.getContactDetails(contactId).then((response) => {
      return response;
    });
  }

  removeFromContacts(item: any) {
    const index = this.contacts.indexOf(item);
    this.contacts.splice(index, 1);
  }

  contactIsNew(contact: Contact) {
    return angular.equals(this.emptyContact, contact);
  }

  getReferrerTitle($tabProvider: string) {
    if (
      this.record?.providers &&
      this.record.providers.referrer === $tabProvider
    ) {
      return "(Referrer)";
    }
  }
}

export class PatientProviders implements angular.IComponentOptions {
  static selector = "patientProviders";
  static template = require("./patient-providers.html");
  static controller = ProvidersController;
  static bindings = {
    mode: "@?",
    record: "<",
    patient: "<",
    isEditable: "<?",
    managingOptom: "<",
    managingOphthal: "<",
    contacts: "<?",
    onContactUpdate: "&?",
  };
}
