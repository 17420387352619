import { AlertHelperService } from "../services/alert-helper.service";

export const pipeName = "alertLevelToClass";
export const alertLevelToClassPipe: angular.Injectable<
  angular.FilterFactory
> = (AlertHelperService: AlertHelperService) => {
  "ngInject";
  return (alertLevelKey: string): string => {
    const CLASS_MAPPINGS = AlertHelperService.getAlertLevelClassMapping();
    return CLASS_MAPPINGS[alertLevelKey?.toLowerCase()] ?? "";
  };
};
