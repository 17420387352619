import { IGlSide } from "models/gl-side.model";
import { IGlInjectionRecord } from "models/injection";
import { PatientProcedureInjection } from "models/patient-procedure";
import { IGlOption } from "../appendix";
import moment = require("moment");

export interface IConsolidatedInjection {
  eye: IGlSide;
  name: IGlOption;
  injections: IGlPatientProcedureInjectionExtended[];
  currentTotal: number;
  currentCycle: IGlPatientProcedureInjectionExtended;
}

export interface IConsolidatedInjectionPerType {
  name: string;
  amount: number;
}

export interface IConsolidatedInjectionPerEye {
  amount: 0;
  types: IConsolidatedInjectionPerType;
}

export interface IConsolidatedInjectionForLetter {
  left: IConsolidatedInjectionPerEye;
  right: IConsolidatedInjectionPerEye;
  both: IConsolidatedInjectionPerEye;
}

export interface IGlPatientProcedureInjectionExtended
  extends PatientProcedureInjection {
  cycleCount: number;
}

export class InjectionHelperService {
  static injectionName = "InjectionHelperService";

  constructor() {
    "ngInject";
  }

  isInjectionActive(injection: IConsolidatedInjection) {
    // the injection is active if the most recent injection is not complete
    const latestInjection = injection?.injections?.[0];
    return latestInjection?.data.frequency.key !== "complete";
  }

  isSelectedInjectionActive(injection: IGlPatientProcedureInjectionExtended) {
    const latestInjection: IGlInjectionRecord = injection.records?.[0];
    return latestInjection?.status !== "COMPLETE";
  }

  getConsolidatedInjections(injections: PatientProcedureInjection[]) {
    const sortedInjections = this.sortInjectionsNewestToOldest(injections);
    const injectionMap = sortedInjections.reduce((map, injection) => {
      const cycleCount = this.getInjectionCountForRecord(injection);
      const extendedInjection: IGlPatientProcedureInjectionExtended = {
        ...injection,
        cycleCount,
      };
      const key = `${injection.data.eye} - ${injection.data.name.key}`;
      if (!map.has(key)) {
        map.set(key, {
          eye: injection.data.eye,
          name: injection.data.name,
          injections: [],
          currentCycle: extendedInjection,
          currentTotal: 0,
        });
      }

      const value = map.get(key);
      value.injections.push(extendedInjection);
      return map;
    }, new Map<string, IConsolidatedInjection>());

    for (const consolidatedInjection of injectionMap.values()) {
      consolidatedInjection.currentTotal = this.getInjectionGroupTotal(
        consolidatedInjection
      );
    }

    // now sort the consolidated injections so that if they are for the same
    // eye, in progress injections are sorted ahead of completed injections.
    return [...injectionMap.values()].sort((i1, i2) => {
      // eslint-disable-next-line
      const isSameEye = i1.eye === i2.eye;
      const isInjection1Active = this.isInjectionActive(i1);
      const isInjection2Active = this.isInjectionActive(i2);
      /**
       * if the injections are for different eyes, or they are both inactive,
       * then leave them in the current order
       */
      if (!isInjection1Active && !isInjection2Active) {
        return 0;
      }
      // now we have only injections that are for the same eye and where 1 or both injections are
      // active
      if (isInjection1Active && isInjection2Active) {
        // If both injections are active, sort injections that have the most
        // number of repeats in the cycle to go first.
        const numberOfInjectionsLeftInCycle1 =
          i1.currentCycle.data.repeat_count - i1.currentCycle.cycleCount;
        const numberOfInjectionsLeftInCycle2 =
          i2.currentCycle.data.repeat_count - i2.currentCycle.cycleCount;
        return numberOfInjectionsLeftInCycle2 - numberOfInjectionsLeftInCycle1;
      }
      if (isInjection1Active) {
        // If Injection 1 is active (but 2 isn't) then Injection 1 should go
        // first
        return -1;
      } else {
        // Injection 2 is active (but 1 isn't) so Injection 2 should go first
        return 1;
      }
    });
  }

  getInjectionGroupTotal(injectionGroup: IConsolidatedInjection) {
    // not empty
    return injectionGroup.injections.reduce((sum, injection) => {
      sum += this.getTotalInjectionCount(injection);
      return sum;
    }, 0);
  }

  getTotalInjectionCount(procedure: PatientProcedureInjection) {
    if (!procedure) {
      return 0;
    }

    // sort the completed injection records from oldest to newest
    const records = [...(procedure?.records ?? [])].sort(
      (r1, r2) => r1.id - r2.id
    );
    const injectionCount = records.reduce((sum, r) => {
      if (procedure.data.eye === "left" && r.data.left) {
        return r.data.left.count ?? sum + 1;
      }
      if (procedure.data.eye === "right" && r.data.right) {
        return r.data.right.count ?? sum + 1;
      }
      return sum;
    }, procedure.data.initial_count ?? 0);
    return injectionCount;
  }

  getNewInjectionCount(
    procedure: PatientProcedureInjection,
    recordId?: number
  ) {
    if (recordId) {
      return this.getInjectionCountForRecord(procedure, recordId);
    }
    return this.getTotalInjectionCount(procedure) + 1;
  }

  getInjectionCountForRecord(
    procedure: PatientProcedureInjection,
    recordId?: number
  ) {
    if (!procedure) {
      return;
    }
    const side = procedure.data.eye;
    let injectionCount = 0;
    // when calculating the count, make sure the records are ordered from start to finish
    const records = [...(procedure.records ?? [])].sort(
      (r1, r2) => r1.id - r2.id
    );
    for (const record of records) {
      const countFromRecord =
        side === "left" ? record.data?.left?.count : record.data?.right?.count;
      injectionCount = countFromRecord ?? injectionCount + 1;
      if (recordId && record.id === recordId) {
        // the array is ordered by id. If this record.id === the matching
        // recordId then stop looping
        break;
      }
    }
    return injectionCount;
  }

  // only completed records
  getCycleCountForSelectedInjectionRecord(
    selectedInjection: IGlPatientProcedureInjectionExtended
  ) {
    return (
      selectedInjection.records.filter((r) => r.status === "COMPLETE").length ??
      0
    );
  }

  private sortInjectionsNewestToOldest(
    injections: PatientProcedureInjection[]
  ) {
    if (injections) {
      const sortedInjections = [...injections];
      sortedInjections.sort((i1, i2) => {
        const i1Date = moment(i1.created_at).startOf("day");
        const i2Date = moment(i2.created_at).startOf("day");
        if (!i1Date.isSame(i2Date)) {
          return i2Date.diff(i1Date);
        } else {
          const isSameEye = i1.data.eye === i2.data.eye;
          const i1IsComplete = i1.data.frequency.key === "complete";
          const i2IsComplete = i2.data.frequency.key === "complete";
          if (!isSameEye || i1IsComplete === i2IsComplete) {
            // if both injects are from a different eye, or if the are from the
            // same eye and for both eyes is the same (ie: both in progress or
            // both complete) then sort them by date
            return (
              new Date(i2.created_at).getTime() -
              new Date(i1.created_at).getTime()
            );
          } else if (i1IsComplete) {
            // If both injects are for the same eye AND injection 1 is complete,
            // but injection 2 is not. Then sort it so the inprogress injection is
            // first.
            return 1;
          } else {
            // If both injects are for the same eye AND injection 2 is complete,
            // but injection 1 is not. Then sort it so the inprogress injection is
            // first.
            return -1;
          }
        }
      });
      return sortedInjections;
    }
  }
}
