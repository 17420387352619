import { PatientRecord } from "../../../../../models/patient-record.model";
import {
  DocumentsService,
  IRecordDocuments,
} from "../../../../core/services/documents-service/documents.service";
import { IPatientResource } from "../../../../core/services/patient.service";

class ComparisonViewController
  implements angular.IController, angular.IOnChanges
{
  patientId = this.$stateParams.patient_id;
  recordKey = parseInt(this.$stateParams.record_id, 10);
  side = this.$stateParams.side as string;
  patient: IPatientResource;
  recordHistory: PatientRecord[];
  baseLineRecord: PatientRecord;
  currentRecord: PatientRecord;
  showHistory = false;
  baselineDocuments: IRecordDocuments;
  currentDocuments: IRecordDocuments;

  setBaselineRecord: ({ record }: { record: PatientRecord }) => void;
  setCurrentRecord: ({ record }: { record: PatientRecord }) => void;

  constructor(
    private $stateParams: angular.ui.IStateParamsService,
    private DocumentsService: DocumentsService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.baseLineRecord && this.baseLineRecord) {
      this.baselineDocuments = this.DocumentsService.getNamedDocuments(
        this.baseLineRecord.documents
      );
    }
    if (changes.currentRecord && this.currentRecord) {
      this.currentDocuments = this.DocumentsService.getNamedDocuments(
        this.currentRecord.documents
      );
    }
  }
}

export class ComparisonViewComponent implements angular.IComponentOptions {
  static selector = "comparisonView";
  static template = require("./comparison-vew.html");
  static controller = ComparisonViewController;
  static bindings = {
    baseLineRecord: "<",
    recordHistory: "<",
    currentRecord: "<",
    setBaselineRecord: "&?",
    setCurrentRecord: "&?",
  };
}
