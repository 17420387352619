import { AddressHelperService } from "app/core/services/AddressHelperService/address.helper.service";
import { defaultsDeep, has } from "lodash";
import { IPatientResource } from "../../../services/patient.service";

class PatientBillingDetailsController
  implements angular.IController, angular.IOnChanges
{
  patient: IPatientResource;

  constructor(private AddressHelperService: AddressHelperService) {
    "ngInject";
  }

  ////////////////

  $onChanges(changesObj: angular.IOnChangesObject) {
    if (changesObj.patient && this.patient) {
      if (this.patient.$promise) {
        this.patient.$promise.then(() => this.updateBillingAddress());
      } else {
        this.updateBillingAddress();
      }
    }
  }

  updateBillingAddress() {
    if (has(this.patient, "data.address")) {
      const defaultBillingAddress = this.getAddressFromGoogleAddress(
        this.patient.data.address
      );
      defaultsDeep(this.patient.data, {
        billing_address: defaultBillingAddress,
      });
    }
  }

  getAddressFromGoogleAddress(googleAddress: google.maps.places.PlaceResult) {
    return this.AddressHelperService.getAddressFromGoogleAddress(googleAddress);
  }

  getField(googleAddress: google.maps.places.PlaceResult, fieldName: string) {
    this.AddressHelperService.getField(googleAddress, fieldName);
  }
}

export class PatientBillingDetailsComponent
  implements angular.IComponentOptions
{
  static selector = "patientBillingDetails";
  static template = require("./patient-billing-details.html");
  static controller = PatientBillingDetailsController;
  static bindings = {
    patient: "<",
  };
}
