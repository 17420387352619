class GlValidateJsonController implements angular.IController {
  ngModelCtrl: angular.INgModelController;

  $postLink() {
    //
    this.ngModelCtrl.$validators.validJson = (modelValue, viewValue) => {
      if (!viewValue) {
        return true;
      }
      try {
        const o = JSON.parse(viewValue);

        return o && typeof o === "object";
      } catch (error) {
        return false;
      }
    };
  }
}

export class GlValidateJsonDirective implements angular.IDirective {
  static selector = "glValidateJson";
  static factory(): angular.IDirectiveFactory {
    const directive: angular.IDirectiveFactory = () => {
      "ngInject";
      return new GlValidateJsonDirective();
    };
    return directive;
  }

  bindToController = true;
  controller = GlValidateJsonController;
  require = {
    ngModelCtrl: "ngModel",
  };
}
