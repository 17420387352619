import angular = require("angular");
import { IOnChangesObject } from "angular";
import { set } from "lodash";
import { PatientRecord, PatientRecordData } from "models/patient-record.model";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";

class AimController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  record: PatientRecordData;
  recordHistory: PatientRecord[];
  enableRight: boolean = true;
  enableLeft: boolean = true;

  // if only this could be an assertDominance field...
  showDominance: boolean = false;

  modeAlreadyUpdated: boolean = false;

  constructor() {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    if (
      (changes.record || changes.recordHistory || changes.mode) &&
      this.record &&
      this.mode === "edit"
    ) {
      // mode checks
      if (this.modeAlreadyUpdated) {
        //only update tab selections once
        return;
      }
      if (changes.mode && this.mode === "edit") {
        this.modeAlreadyUpdated = true;
      }

      // handle change
      this.handleAimDidChange(this.record);
    }

    // check on side changes (ONLY AFTER INSTANTIATED)
    if (changes?.enableLeft && this.modeAlreadyUpdated) {
      // if value is left and left is disabled set to undefined
      if (!this.enableLeft && this.record?.dominance === "left") {
        set(this.record, "dominance", undefined);
      }
    }

    if (changes?.enableRight && this.modeAlreadyUpdated) {
      // if value is right and right is disabled set to undefined
      if (!this.enableRight && this.record?.dominance === "right") {
        set(this.record, "dominance", undefined);
      }
    }
  }

  handleAimDidChange(recordData: PatientRecordData) {
    // check if theres any aim fields
    const currentRecordHasAnyAimField: boolean = this.hasDominance(recordData);
    // if there is, use current data
    if (currentRecordHasAnyAimField) {
      this.showDominance = this.hasDominance(recordData);
    } else {
      // else go by historical data
      const mostRecentAimRecord = this.recordHistory
        ?.map((r) => r.data)
        ?.reverse()
        ?.find((r) => {
          return this.hasDominance(r);
        });

      this.showDominance = this.hasDominance(mostRecentAimRecord);
    }
  }

  hasDominance(recordData: PatientRecordData) {
    return !!recordData?.dominance;
  }
}

export class AimComponent implements angular.IComponentOptions {
  static selector = "aim";
  static template = require("./aim.html");
  static controller = AimController;
  static bindings = {
    enableLeft: "<?",
    enableRight: "<?",
    record: "<",
    recordHistory: "<",
    ...GlFormControllerBindings,
  };
}
