// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { RecordEditPage } from "./record-edit";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.record-edit", {
    url: "/record-edit",
    component: RecordEditPage.selector,
    data: {
      requiresLogin: true
    }
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.record-edit", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(RecordEditPage.selector, RecordEditPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
