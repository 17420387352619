(function() {
  "use strict";

  //Directive used to set metisMenu and minimalize button
  angular.module("inspinia").factory("validationService", validationService);

  validationService.$inject = ["toastr"];

  function validationService(toastr) {
    // Holds rule patterns for use in validation fields
    // This is called by our controllers.
    // For example the patient controller patient.validation = validationService.patterns.patient
    // and used within the views as patient.validation.ruleKey
    // for example ng-pattern="patient.validation.ruleKey",
    // where ruleKey is a key in the object returned by this method
    var patterns = (function() {
      return {
        // Patterns specifically used for the patient record fields
        patient: {
          iopField: "/[d]/"
        }
        // Here we can add different validation fules for different parts of the site
      };
    })();

    // Holds global validation config
    var all = {
      required: true,
      showErrors: false
    };

    function showErrors(val) {
      all.showErrors = val;
    }

    function shouldShowErrors() {
      return all.showErrors;
    }

    //
    var hasBaselineFields = function(patient) {
      // Make sure any documents exist
      if (typeof patient.info.record.documents == "undefined") return false;

      // Also, eyes can be "switched off" meaning we don't take data from them, perhaps because of a glass eye
      // This option is selected by a technician in ophthalmic_history directive -
      // sets patient.info.record.eye_status to true if an eye is to be switched off

      // Make sure all fields are set
      // This inherently checks for 'reason for no data' fields,
      //  because they are set agains the left and right objects of each field
      if (
        typeof patient.info.record.eye_status == "undefined" ||
        _.isUndefined(patient.info.record.eye_status.left)
      ) {
        if (
          typeof patient.info.record.documents.baseline_field == "undefined" ||
          patient.info.record.documents.baseline_field == null ||
          typeof patient.info.record.documents.baseline_field.left ==
            "undefined" ||
          patient.info.record.documents.baseline_field.left == null ||
          (patient.info.record.documents.baseline_field.left !== null &&
            patient.info.record.documents.baseline_field.left.no_data_reason ===
              null)
        ) {
          //console.log(patient.info.record.documents, 'left');
          return false;
        }
      } else {
        return true;
      }
      if (
        typeof patient.info.record.eye_status == "undefined" ||
        _.isUndefined(patient.info.record.eye_status.right)
      ) {
        if (
          typeof patient.info.record.documents.baseline_field == "undefined" ||
          patient.info.record.documents.baseline_field == null ||
          typeof patient.info.record.documents.baseline_field.right ==
            "undefined" ||
          patient.info.record.documents.baseline_field.right == null ||
          (patient.info.record.documents.baseline_field.right !== null &&
            patient.info.record.documents.baseline_field.right
              .no_data_reason === null)
        ) {
          //console.log(patient.info.record.documents, 'right');
          return false;
        }
      } else {
        return true;
      }

      // If we get here, all the documents hav been uploaded
      return true;
    };

    //
    var hasAllDocuments = function(patient) {
      // Make sure any documents exist
      if (typeof patient.info.record.documents == "undefined") return false;
      // Make sure all fields are set
      // This inherently checks for 'reason for no data' fields,
      //  because they are set agains the left and right objects of each field
      // Also, eyes can be "switched off" meaning we don't take data from them, perhaps because of a glass eye
      // This option is selected by a technician in ophthalmic_history directive -
      // sets patient.info.record.eye_status to true if an eye is to be switched off
      if (
        typeof patient.info.record.eye_status == "undefined" ||
        _.isUndefined(patient.info.record.eye_status.left)
      ) {
        if (
          patient.info.record.documents.baseline_field == null ||
          patient.info.record.documents.baseline_disc == null ||
          patient.info.record.documents.baseline_oct == null ||
          patient.info.record.documents.baseline_field.left == null ||
          patient.info.record.documents.baseline_disc.left == null ||
          patient.info.record.documents.baseline_oct.left == null ||
          typeof patient.info.record.documents.baseline_field == "undefined" ||
          typeof patient.info.record.documents.baseline_field.left ==
            "undefined" ||
          typeof patient.info.record.documents.baseline_oct == "undefined" ||
          typeof patient.info.record.documents.baseline_oct.left ==
            "undefined" ||
          typeof patient.info.record.documents.baseline_disc == "undefined" ||
          typeof patient.info.record.documents.baseline_disc.left ==
            "undefined" ||
          // Certain fields don't inherently have a left and right side - Referral and AScan
          // We assigned these field left and right for display purposes and to tap into
          // existing functionality. Referral is considered 'right' because heath wanted it on the right hand side of the page
          // while the AScan field is set to left, so it can sit with the other left fields.
          patient.info.record.documents.a_scan == null ||
          patient.info.record.documents.a_scan.left == null ||
          typeof patient.info.record.documents.a_scan == "undefined" ||
          typeof patient.info.record.documents.a_scan.left == "undefined" ||
          (patient.info.record.documents.baseline_disc.left === null ||
            (!_.isUndefined(
              patient.info.record.documents.baseline_disc.left.no_data_reason
            ) &&
              patient.info.record.documents.baseline_disc.left.no_data_reason ==
                null))
        ) {
          return false;
        }
      }

      if (
        typeof patient.info.record.eye_status == "undefined" ||
        _.isUndefined(patient.info.record.eye_status.right)
      ) {
        if (
          patient.info.record.documents.baseline_field == null ||
          patient.info.record.documents.baseline_disc == null ||
          patient.info.record.documents.baseline_oct == null ||
          patient.info.record.documents.baseline_field.right == null ||
          patient.info.record.documents.baseline_disc.right == null ||
          patient.info.record.documents.baseline_oct.right == null ||
          typeof patient.info.record.documents.baseline_field == "undefined" ||
          typeof patient.info.record.documents.baseline_field.right ==
            "undefined" ||
          typeof patient.info.record.documents.baseline_oct == "undefined" ||
          typeof patient.info.record.documents.baseline_oct.right ==
            "undefined" ||
          typeof patient.info.record.documents.baseline_disc == "undefined" ||
          typeof patient.info.record.documents.baseline_disc.right ==
            "undefined" ||
          // Certain fields don't inherently have a left and right side - Referral and AScan
          // We assigned these field left and right for display purposes and to tap into
          // existing functionality. Referral is considered 'right' because heath wanted it on the right hand side of the page
          // while the AScan field is set to left, so it can sit with the other left fields.
          patient.info.record.documents.referral == null ||
          patient.info.record.documents.referral.right == null ||
          typeof patient.info.record.documents.referral == "undefined" ||
          typeof patient.info.record.documents.referral.right == "undefined" ||
          (patient.info.record.documents.baseline_disc.right === null ||
            (!_.isUndefined(
              patient.info.record.documents.baseline_disc.right.no_data_reason
            ) &&
              patient.info.record.documents.baseline_disc.right
                .no_data_reason === null))
        ) {
          return false;
        }
      }
      // If we get here, all the documents hav been uploaded
      return true;
    };

    // On each page we can set vm.documentRequirements which specify which upload fields
    // to display and which ones of those are required. Usually an upload won't be required
    // if the previous management settings don't call for it.
    var hasRequiredDocuments = function(patient) {
      // Check if our page controller (within Patient.controller) has any required fields set
      if (
        typeof patient.documentRequirements != "undefined" &&
        patient.documentRequirements.length > 0
      ) {
        // console.log(patient.documentRequirements);
        // Each upload field displayed on the page should be in here,
        for (var i = 0; i < patient.documentRequirements.length; i++) {
          var doc = patient.documentRequirements[i];
          // But not necessarily required
          if (doc.required) {
            // If it's required we need to make sure the upload for each eye has been done
            var sides = ["right", "left"];

            for (var k = 0; k < sides.length; k++) {
              var side = (function(side) {
                return side;
              })(sides[k]);

              // We can set a sides: { left: true, right: false } on a document type which means we don't need
              // to collect data for that side, nor validate it
              //if( typeof doc.sides !== 'undefined')
              //{
              //    if( doc.sides[side] === false )
              //        continue;
              //}
              // And we don't need to check if the eye reading isn't required
              if (
                typeof patient.info.record.eye_status == "undefined" ||
                _.isUndefined(patient.info.record.eye_status[side])
              ) {
                // if any of these conditions don't match then the required field hasn't been uploaded
                if (
                  patient.info.record.documents[doc.key] == null ||
                  patient.info.record.documents[doc.key][side] == null ||
                  typeof patient.info.record.documents[doc.key][side] ==
                    "undefined" ||
                  (patient.info.record.documents[doc.key][side] === null ||
                    (!_.isUndefined(
                      patient.info.record.documents[doc.key][side]
                        .no_data_reason
                    ) &&
                      patient.info.record.documents[doc.key][side]
                        .no_data_reason === null))
                ) {
                  return false;
                }
              }
              //else {
              //    return true;
              //}
            }
            //return false;
          }
        }
      }
      return true;
    };

    /*
        var hasProcedureConsentDocuments = function(patient)
        {

            console.log(patient);
            if( patient.info.record.procedures[pa])


            return true;
        };
*/

    var run = function($scope, type) {
      if (
        Object.keys($scope.patientInfo.$error).length > 0 &&
        type !== "partial_record"
      ) {
        toastr.error("Please fill in all required fields");
        showErrors(true);
        $scope.patient.validation.all.showErrors = true;
        return false;
      }
      return true;
    };

    var getIncompleteFieldList = function($scope) {
      var list = [];
      _.forEach($scope.patientInfo.$error.required, function(error) {
        list.push(error.$name);
      });
      return list;
    };

    var displayErrors = function(error) {
      var message = "";
      for (var key in error.data) {
        for (var i = 0; i < error.data[key].length; i++) {
          message += error.data[key][i] + "<br />";
        }
      }
      toastr.error(message);
    };

    return {
      all: all,
      showErrors: showErrors,
      shouldShowErrors: shouldShowErrors,
      patterns: patterns,
      hasBaselineFields: hasBaselineFields,
      hasAllDocuments: hasAllDocuments,
      hasRequiredDocuments: hasRequiredDocuments,
      //hasProcedureConsentDocuments:  hasProcedureConsentDocuments,
      run: run,
      displayErrors: displayErrors,
      getIncompleteFieldList: getIncompleteFieldList
    };
  }
})();
