import { isFunction } from "angular";
import { AuthService } from "app/core/services/auth.service";
import { DiagnosisService } from "app/core/services/diagnosis.service";
import {
  IConsolidatedInjection,
  InjectionHelperService,
} from "app/core/services/injection-helper/injection-helper.service";
import {
  IDropWithAction,
  PatientProcedureHelperService,
} from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { GL_VIEWS } from "app/pages/main.record/components/view-manager/view-manager.component";
import { get, includes, isNil, values } from "lodash";
import { GlPrescription } from "models/prescription.model";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "../../../../../models/patient-procedure";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { Appendix } from "../../../services/appendix";
import { GlModelService } from "../../../services/gl-model.service";
import { GlFormController } from "../../gl-form-controller";
import "./segment-management.scss";
import angular = require("angular");

export class SegmentManagementController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // @Inputs()
  consolidatedRecord: PatientRecordData;
  dayProcedures: PatientProcedureInHouse[];
  drops: PatientProcedureDrop[];
  dropsFiltered: PatientProcedureDrop[];
  injections: IConsolidatedInjection[];
  activeInjections: IConsolidatedInjection[];
  enableLeft: boolean;
  enableRight: boolean;
  patient: User;
  procedures: PatientProcedureExternal[];
  proceduresFiltered: PatientProcedureExternal[];
  record: PatientRecord;
  selectedCondition: GL_VIEWS;
  user: User;
  hideFields: boolean;

  // Controller Properties
  dropsActionsForCurrentRecord: IDropWithAction[];
  whenOptions = this.appendix.get("managementFrequency", true);
  recordData: PatientRecordData;

  prescriptions: GlPrescription[];
  prescriptionsFiltered: GlPrescription[];
  drugs: PatientProcedureDrug[];
  drugsFiltered: PatientProcedureDrug[];

  favouriteDrugs: PatientProcedureDrug[] | [];
  favouriteDrugGroups: UserFavouriteDrugGroup[] | [];

  useRecordIdOnPrescribe: boolean = true;

  // print mode to hide all buttons and UI
  printMode: boolean = false;

  // to ensure state is preserved when transitioning from
  // display to edit etc
  useUserCataractOptions: boolean = false;

  // tab ref to allow toggling within own component state
  cataractTabEditRef: JQLite;
  glaucomaTabEditRef: JQLite;

  onPrescriptionsUpdate: (arg: { patientId: number }) => void;
  onDrugsUpdate: (arg: { patientId: number }) => void;
  onFavouritesUpdate: (arg: { userId: number }) => void;

  constructor(
    private appendix: Appendix,
    private GlModelService: GlModelService,
    private InjectionHelperService: InjectionHelperService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    private AuthService: AuthService,
    private PatientProcedureService: PatientProcedureService,
    private DiagnosisService: DiagnosisService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.record && this.record?.data) {
      this.recordData = this.record.data;
    }
    if ((changes.drops || changes.record) && this.drops && this.record) {
      this.dropsActionsForCurrentRecord =
        this.PatientProcedureHelperService.getDropListForRecord(
          this.record,
          this.drops
        ).filter((dropWithAction) => {
          return (
            !!dropWithAction.action ||
            (dropWithAction.drop.data.one_off &&
              dropWithAction.drop.record_id === this.record.id)
          );
        });
    }

    if (changes.injections && this.injections) {
      this.activeInjections = this.injections.filter((i) =>
        this.InjectionHelperService.isInjectionActive(i)
      );
    }

    if (changes.procedures && this.procedures) {
      this.proceduresFiltered = this.procedures.filter(
        (p) => p.status !== "historical"
      );
    }

    // ongoing or same record drops only
    if ((changes.record || changes.drops) && this.drops && this.record) {
      this.dropsFiltered = this.drops.filter(
        (d: PatientProcedureDrop) =>
          d.record_id === this.record.id || !d.data.treatment_end_date
      );
    }

    if ((changes.record || changes.drugs) && this.drugs && this.record) {
      this.drugsFiltered = this.drugs.filter(
        (d: PatientProcedureDrug) =>
          d.record_id === this.record.id || !d.data.treatment_end_date
      );
    }

    if (
      (changes.record || changes.prescriptions) &&
      this.prescriptions &&
      this.record
    ) {
      this.prescriptionsFiltered = this.prescriptions.filter(
        (p: GlPrescription) => p.record_id === this.record.id
      );
    }

    // if toggle to edit mode
    if (changes?.mode && this.isEditMode()) {
      this.toggleManagementProcedureTabState("cataract", true);
      this.toggleManagementProcedureTabState("glaucoma", true);
    }
  }

  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  updatePatientPrescriptions(patientId: number) {
    if (isFunction(this.onPrescriptionsUpdate) && patientId) {
      this.onPrescriptionsUpdate({ patientId });
    }
  }

  updatePatientDrugs(patientId: number) {
    if (isFunction(this.onDrugsUpdate) && patientId) {
      this.onDrugsUpdate({ patientId });
    }
  }

  updateUserFavouriteDrugs(userId: number) {
    if (isFunction(this.onFavouritesUpdate) && userId) {
      this.onFavouritesUpdate({ userId });
    }
  }

  getIop(side: IGlSide) {
    const path = `iop.${side}`;
    return get(this.recordData, path, this.GlModelService.get(path));
  }

  getGlaucomaRisk(side: IGlSide) {
    const path = `management.glaucoma_risk.${side}.key`;
    return get(this.recordData, path, this.GlModelService.get(path));
  }

  getSuggestedIOP(side: IGlSide) {
    const iop = this.getIop(side);
    const risk = this.getGlaucomaRisk(side);
    if (iop && risk) {
      switch (risk) {
        case "high":
          return Math.round(iop - iop * 0.4);
        case "moderate":
          return Math.round(iop - iop * 0.3);
        case "low":
          return Math.round(iop - iop * 0.2);
        case "negligible":
          return Math.round(iop);
        default:
          return false;
      }
    }
  }

  toggleManagementProcedureTabState(
    tab: "glaucoma" | "cataract",
    state: boolean
  ) {
    switch (tab) {
      case "cataract":
        this.cataractTabEditRef?.[0] &&
          this._toggleManagementProcedureTabState(
            this.cataractTabEditRef[0],
            state
          );
        break;
      case "glaucoma":
        this.glaucomaTabEditRef?.[0] &&
          this._toggleManagementProcedureTabState(
            this.glaucomaTabEditRef[0],
            state
          );
        break;
      default:
        break;
    }
  }

  isWhatFieldChecked() {
    const management_what = get(this.recordData, "management.what", {});
    return includes(values(management_what), true) ? true : false;
  }

  isCommentArray() {
    return Array.isArray(get(this.recordData, "management.comments"));
  }

  recordHasExternalCataractProcedure() {
    const externalProcedures: PatientProcedureExternal[] =
      this.PatientProcedureService.getExternalProcedures();

    return this.PatientProcedureHelperService.getExternalProcedureForRecordByName(
      this.record.id,
      "Cataract",
      externalProcedures
    );
  }

  recordHasConsentForm() {
    return !isNil(this?.record?.cataract_consent_form);
  }

  displayManagementProceduresSection() {
    // if either glaucoma or cataract or whatever shows up
    const hasCataractDiagnosis: boolean =
      this.DiagnosisService.diagnosisIncludesDisease(
        this.recordData?.management?.diagnosis_array,
        "cataract",
        "both"
      ) && !isNil(this.recordHasExternalCataractProcedure());

    const hasGlaucomaDiagnosis: boolean =
      this.DiagnosisService.diagnosisIncludesDisease(
        this?.recordData?.management?.diagnosis_array,
        "glaucoma",
        "both"
      );

    // if either guards appear (should be the same as the html one)
    // show
    return hasCataractDiagnosis || hasGlaucomaDiagnosis;
  }

  // toggle state of a tab by ref
  private _toggleManagementProcedureTabState(
    ref: HTMLElement,
    state?: boolean
  ) {
    const headingElem: HTMLElement = ref.querySelector(".panel-heading");
    if (headingElem) {
      const scope: any = angular.element(headingElem).scope();
      scope.isOpen = state ?? !scope.isOpen;
    }
  }
}

export class SegmentManagement implements angular.IComponentOptions {
  static selector = "segmentManagement";
  static template = require("./segment-management.html");
  static controller = SegmentManagementController;
  static bindings = {
    consolidatedRecord: "<",
    dayProcedures: "<",
    drops: "<",
    hideFields: "<",
    injections: "<",
    enableLeft: "<",
    enableRight: "<",
    isEditable: "<?",
    mode: "@?",
    patient: "<?",
    procedures: "<",
    record: "<",
    selectedCondition: "<?",
    user: "<",
    prescriptions: "<",
    drugs: "<",
    favouriteDrugs: "<",
    favouriteDrugGroups: "<",
    useRecordIdOnPrescribe: "<?",
    printMode: "<?",
    onDrugsUpdate: "&",
    onPrescriptionsUpdate: "&",
    onFavouritesUpdate: "&",
  };
}
