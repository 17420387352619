export const pipeName = "eyeOrder";
export const eyeOrder: angular.Injectable<angular.FilterFactory> = () => {
  "ngInject";
  return (input: string, fullWords: boolean = false) => {
    switch (input) {
      case "left_right":
        return fullWords ? "(Left -> Right)" : "(L -> R)";
      case "right_left":
        return fullWords ? "(Right -> Left)" : "(R -> L)";
      default:
        return "";
    }
  };
};
