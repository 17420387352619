import { isNil, map } from "lodash";

/* NOTE: 
  these funnctions are taken/inspired from uib-bootstrap
  uibTypeaheadHighlight implementation  

  this is to get around having to apply the pipe
  multiple times on conditional multiple queries and
  render as html per documentation examples
*/
const escapeRegexp = (queryToEscape: string) => {
  // Regex: capture the whole query string and replace it with the string that will be used to match
  // the results, for example if the capture is "a" the result will be \a
  return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
};

// Replaces the capture string with a the same string inside of a "strong" tag
const formatMatchItemWithMatch = (matchItem: string, query) => {
  return (matchItem = query
    ? ("" + matchItem).replace(
        new RegExp(escapeRegexp(query), "gi"),
        "<strong>$&</strong>"
      )
    : matchItem);
};

export const pipeName = "typeaheadQueryHighlightFormat";
export const typeaheadQueryHighlightFormat: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (
    matchItem: string,
    originalQuery: string,
    queries: Record<string, string>[]
  ): string => {
    // no item to compare
    if (isNil(matchItem)) {
      return "";
    }

    let _checkThrough: string[] = [];
    // add original query string there
    if (!isNil(originalQuery)) {
      _checkThrough.push(originalQuery);
    }

    // if there are queries add those in as well
    _checkThrough = _checkThrough.concat(map(queries, (q) => q.value) ?? []);

    // iterate and apply over the match item multiple times
    // TO FIX: why isnt it working for multiple broken up
    let formatted: string = matchItem;
    for (const query of _checkThrough) {
      formatted = formatMatchItemWithMatch(formatted, query);
    }
    // return
    return formatted;
  };
};
