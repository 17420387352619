// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { TechButtonProgressComponent } from "./components/tech-button-progress/tech-button-progress";
import { RecordTechPage } from "./record-tech";
import { ManagementAdminCommentComponent } from "./components/management-admin-comment/management-admin-comment";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.record-tech", {
    url:
      "/patient/{patientId:int}/tech-record/{recordId:int}?{debug:bool}&{review-record:int}&{goToMessages:bool}",
    component: RecordTechPage.selector,
    data: {
      requiresLogin: true,
    },
    params: {
      // This makes the recordId optional
      recordId: {
        squash: true,
        value: null,
      },
    },
  });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.record-tech", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(
    ManagementAdminCommentComponent.selector,
    ManagementAdminCommentComponent
  )
  .component(RecordTechPage.selector, RecordTechPage)
  .component(TechButtonProgressComponent.selector, TechButtonProgressComponent)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
