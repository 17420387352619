// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import * as ngAnimate from "angular-animate";
import * as ngAria from "angular-aria";
import * as googlePlaces from "angular-google-places-autocomplete";
import * as ngJwt from "angular-jwt";
import * as ngLadda from "angular-ladda";
import * as ngMessages from "angular-messages";
import * as ngMoment from "angular-moment";
import * as ngResource from "angular-resource";
import * as ngSanitize from "angular-sanitize";
import * as ngToastr from "angular-toastr";
import * as uiBootstrap from "angular-ui-bootstrap";
import * as uiValidate from "angular-ui-validate";
// import "textangularjs/dist/textAngular-sanitize";
import * as textAngular from "textangularjs";
import {
  checkAuthOnLoad,
  configuration,
  CONSTANTS,
  isDevEnvironment,
  startPace,
} from "./app.config";
/**
 * Import Application Modules
 */
import { moduleName as coreModule } from "./core/core.module";
import { moduleName as dropzoneModule } from "./core/directive/ng-dropzone/ng-dropzone.directive";
import { moduleName as loginModule } from "./pages/login/login.module";
import { moduleName as clinicAdminModule } from "./pages/main.clinic-admin/clinic-admin.module";
import { moduleName as contactMigrationModule } from "./pages/main.contact-migration/contact-migration.module";
import { moduleName as dashboardModule } from "./pages/main.dashboard/dashboard.module";
import { moduleName as cataractConsentPageModule } from "./pages/main.cataract-consent/cataract-consent.module";
import { moduleName as documentViewerPageModule } from "./pages/main.document-viewer/document-viewer.module";
import { moduleName as injectionsPageModule } from "./pages/main.injections/injections.module";
import { moduleName as patientPageModule } from "./pages/main.patient/patient.module";
import { moduleName as recordEditModule } from "./pages/main.record-edit/record-edit.module";
import { moduleName as techReviewModule } from "./pages/main.record-tech/record-tech.module";
import { moduleName as recordPageModule } from "./pages/main.record/record.module";
import { moduleName as referralPageModule } from "./pages/main.referral/referral.module";
import { moduleName as reportingPageModule } from "./pages/main.reporting/reporting.module";
import { moduleName as selectClinicPageModule } from "./pages/main.select-clinic/select-clinic.module";
import { moduleName as settingsPageAdministrativeModule } from "./pages/main.settings.administrative/settings-administrative.module";
import { moduleName as settingsPageAdvancedModule } from "./pages/main.settings.advanced/settings-advanced.module";
import { moduleName as settingsPageCustomDrugsModule } from "./pages/main.settings.custom-drugs/settings-custom-drugs.module";
import { moduleName as settingsPrintPageModule } from "./pages/main.settings.print/settings-print.module";
import { moduleName as settingsProcedureOptionsModule } from "./pages/main.settings.procedure-options/settings-procedure-options.module";
import { moduleName as settingsProfilePageModule } from "./pages/main.settings.profile/settings-profile.module";
import { moduleName as settingsPageUserClinics } from "./pages/main.settings.user-clinic/user-clinic.module";
import { moduleName as paymentStep1Module } from "./pages/main.user-payment-step1/user-payment-step1.module";
import { moduleName as paymentStep2Module } from "./pages/main.user-payment-step2/user-payment-step2.module";
import { moduleName as paymentStep3Module } from "./pages/main.user-payment-step3/user-payment-step3.module";
import { moduleName as virtualReviewPageModule } from "./pages/main.virtual-review/virtual-review.module";
import { moduleName as mainPageModule } from "./pages/main/main.module";
import { moduleName as printLetterModule } from "./pages/print-letter/print-letter.module";
import { moduleName as printRecordModule } from "./pages/print-record/print-record.module";
// import { moduleName as dicomTestModule } from "./pages/main.dicom-test/dicom-test.module";

import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations";

// modules to load
const modules: string[] = [
  // Add dependancies
  ngAnimate,
  ngSanitize,
  ngMessages,
  ngAria,
  ngResource,
  ngJwt,
  ngToastr,
  ngLadda,
  ngMoment,
  uiBootstrap,
  uiValidate,
  textAngular,
  googlePlaces,
  // Core Module
  coreModule,
  // Page Modules
  clinicAdminModule,
  dashboardModule,
  documentViewerPageModule,
  dropzoneModule,
  loginModule,
  mainPageModule,
  patientPageModule,
  reportingPageModule,
  contactMigrationModule,
  injectionsPageModule,
  paymentStep1Module,
  paymentStep2Module,
  paymentStep3Module,
  printLetterModule,
  printRecordModule,
  recordEditModule,
  recordPageModule,
  referralPageModule,
  selectClinicPageModule,
  settingsPageUserClinics,
  settingsPrintPageModule,
  settingsProfilePageModule,
  techReviewModule,
  virtualReviewPageModule,
  cataractConsentPageModule,
  settingsPageAdvancedModule,
  settingsPageCustomDrugsModule,
  settingsPageAdministrativeModule,
  settingsProcedureOptionsModule,
  // dicomTestModule,
  // "ngSentry",
] as string[];

// add sentry if not on dev
if (!isDevEnvironment()) {
  Sentry.init({
    dsn: "https://742fd43907aa2e684b9570441b334dbc@o4507251887112192.ingest.de.sentry.io/4507251891896400",
    integrations: [new AngularIntegration()],
  });

  // if not there then add in
  if (!modules.includes("ngSentry")) {
    modules.push("ngSentry");
  }
}

// instantiate
const module = angular
  .module("gl.app", modules)
  .config(configuration)
  .run(checkAuthOnLoad)
  .run(startPace);

CONSTANTS.forEach((c) => module.constant(c.name, c.value));

export const moduleName = module.name;
