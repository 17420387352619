import { Contact } from "../../../models/contact.model";
import { IGlApiResponse } from "../../../models/gl-api-response.model";
import { PatientContact } from "../../../models/patient-contact.model";
import { API_PATH_v2 } from "./api-paths";

export interface IPatientContactResourceClass
  extends angular.resource.IResourceClass<IPatientContactResource> {
  getAllPatientContacts: () => IPatientContactResource[];
}

export interface IPatientContactResource
  extends Contact,
    angular.resource.IResource<Contact> {}

export class PatientContactService {
  static injectionName = "PatientContactService";
  v2BasePath = `${this.API_URL}${API_PATH_v2}`;
  contactPath = `${this.v2BasePath}/contacts`;
  getAllPatientContactsPath = `${this.v2BasePath}/contacts_basic`;

  patientContactResource = this.$resource(
    this.contactPath,
    { id: "@id" },
    {
      getAllPatientContacts: {
        method: "get",
        url: this.getAllPatientContactsPath,
        isArray: true,
        cache: true,
      },
    }
  ) as IPatientContactResourceClass;

  private cache = this.$cacheFactory.get("$http");

  constructor(
    private $cacheFactory: angular.ICacheFactoryService,
    private $http: angular.IHttpService,
    private $resource: angular.resource.IResourceService,
    private API_URL: string
  ) {
    "ngInject";
  }

  // getManagingOptom() {
  //   if (this.patientClinics) {
  //     return first(
  //       this.patientClinics.filter(
  //         (p) => p.provider?.type?.name === "optometrist"
  //       )
  //     );
  //   } else {
  //     return;
  //   }
  // }

  // getManagingOphthal() {
  //   if (this.patientClinics) {
  //     return first(
  //       this.patientClinics.filter(
  //         (p) => p?.provider?.type?.name === "ophthalmologist"
  //       )
  //     );
  //   } else {
  //     return;
  //   }
  // }

  getAllPatientsContacts({ contactId }: { contactId?: number }) {
    const id = contactId;
    const params: any = {};

    if (contactId) {
      params.contactId = contactId;
    }

    return this.$http
      .get<IGlApiResponse<Contact[]>>(`${this.v2BasePath}/contacts/${id}`, {
        params,
      })
      .then((response) => response.data.data);
  }

  getAll() {
    return this.patientContactResource.getAllPatientContacts();
  }

  update(userId: number, contact: Contact, type?: string, referrer?: boolean) {
    return this.$http
      .put<IGlApiResponse<PatientContact>>(
        `${this.v2BasePath}/patients/${userId}/contacts/${contact.id}`,
        {
          type: type,
          referrer: referrer,
        }
      )
      .then((response) => response.data);
  }

  deletePatientContact(patientId: number, contact: Contact) {
    const url = `${this.v2BasePath}/patients/${patientId}/contacts/${contact.id}`;
    return this.$http.delete<Contact>(url).then((response) => response.data);
  }

  clearCache() {
    this.cache.remove(this.contactPath);
    this.cache.remove(this.getAllPatientContactsPath);
  }
}
