import SignaturePad from "signature_pad";

import { isNil } from "lodash";
import "./gl-signature.scss";
export class GlSignatureController
  implements angular.IController, angular.IOnChanges, angular.IPostLink
{
  signaturePad: SignaturePad;
  signatureParentRef: HTMLElement;
  signatureCanvasRef: HTMLCanvasElement;

  disableSign: boolean = false;
  signInProgress: boolean = false;

  signature: string; // base64

  // handlers
  onSign: (arg: { signature: string }) => void;
  onReopen: (arg: { signature: string }) => void;

  constructor(
    private $timeout: angular.ITimeoutService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onChanges(): void {
    if (!this.signaturePad && this.signatureCanvasRef) {
      this._initialiseSignaturePad();
    }
  }

  // after init and setup, call this to instantiate everything
  $postLink(): void {
    this._initialiseSignaturePad();
    this.$timeout(200).then(() => this.resizeCanvas());
    // event listener for canvas
    window.addEventListener("resize", () =>
      this.resizeCanvas(this.signaturePad)
    );
  }

  // remove event lsitener
  $onDestroy(): void {
    window.removeEventListener("resize", () =>
      this.resizeCanvas(this.signaturePad)
    );
  }

  // SIGNATURE
  handleOnClear() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  handleOnSign() {
    if (!this.signaturePad || this.signaturePad?.isEmpty()) {
      return this.toastr.error("Please sign before saving.");
    }
    const signature = this.signaturePad.toDataURL("image/svg+xml");

    if (this.onSign) {
      this.onSign({ signature });
    }
  }

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  resizeCanvas(signaturePad?: SignaturePad) {
    const sp: SignaturePad = signaturePad ?? this.signaturePad;
    if (!isNil(sp) && !isNil(this.signatureCanvasRef)) {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      this.signatureCanvasRef[0].width =
        this.signatureCanvasRef[0].offsetWidth * ratio;
      this.signatureCanvasRef[0].height =
        this.signatureCanvasRef[0].offsetHeight * ratio;
      this.signatureCanvasRef[0].getContext("2d").scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      sp.clear();
    }
  }

  private _initialiseSignaturePad() {
    if (this.signatureCanvasRef) {
      this.signaturePad = new SignaturePad(this.signatureCanvasRef[0]);
      this.resizeCanvas();
    }
  }
}

export class GlSignature implements angular.IComponentOptions {
  static selector = "glSignature";
  static template = require("./gl-signature.html");
  static controller = GlSignatureController;
  static bindings = {
    user: "<",
    patient: "<",
    onSign: "&?",
    onReopen: "&?",
    signature: "<?",

    disableSign: "<?",
    signInProgress: "<",
  };
}
