// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { DocumentViewerComponent } from "./document-viewer";
import { DocumentWrapperComponent } from "./components/document-wrapper/document-wrapper";
import { ComparisonViewComponent } from "./components/comparison-view/comparison-view";
import { GridViewComponent } from "./components/grid-view/grid-view";
/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.document-viewer", {
    url: "/patient/{patient_id:int}/record/{record_id:int}/documents/:side",
    component: DocumentViewerComponent.selector,
    data: {
      requiresLogin: true
    }
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.document-viewer", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(DocumentViewerComponent.selector, DocumentViewerComponent)
    .component(DocumentWrapperComponent.selector, DocumentWrapperComponent)
    .component(ComparisonViewComponent.selector, ComparisonViewComponent)
    .component(GridViewComponent.selector, GridViewComponent)
    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
