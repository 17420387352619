import { IGlOption } from "app/core/services/appendix";
import { GlModelService } from "app/core/services/gl-model.service";
import { get } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import { IGlSide } from "models/gl-side.model";
import { PatientRecord, PatientRecordData } from "models/patient-record.model";
import { GlStaff, Patient } from "models/user.model";
import { GlFormController } from "../../gl-form-controller";

export class GlaucomaSectionController
  extends GlFormController
  implements angular.IController
{
  user: GlStaff;
  patient: Patient;
  mode: IGlFormMode;
  isEditable: boolean;
  record: PatientRecord;
  recordData: PatientRecordData;
  consolidatedRecord: PatientRecordData;
  enableLeft: boolean = false;
  enableRight: boolean = false;

  defaultOption: IGlOption = null;

  // toggle between user and all
  // can also be associated with state from a parent
  useUserOptions: boolean = false;

  constructor(private GlModelService: GlModelService) {
    "ngInject";
    super();
  }

  getIop(side: IGlSide) {
    const path = `iop.${side}`;
    return get(this.recordData, path, this.GlModelService.get(path));
  }

  getGlaucomaRisk(side: IGlSide) {
    const path = `management.glaucoma_risk.${side}.key`;
    return get(this.recordData, path, this.GlModelService.get(path));
  }

  getSuggestedIOP(side: IGlSide) {
    const iop = this.getIop(side);
    const risk = this.getGlaucomaRisk(side);
    if (iop && risk) {
      switch (risk) {
        case "high":
          return Math.round(iop - iop * 0.4);
        case "moderate":
          return Math.round(iop - iop * 0.3);
        case "low":
          return Math.round(iop - iop * 0.2);
        case "negligible":
          return Math.round(iop);
        default:
          return false;
      }
    }
  }
}

export class GlaucomaSectionComponent implements angular.IComponentOptions {
  static selector = "glaucomaSection";
  static template = require("./glaucoma-section.html");
  static controller = GlaucomaSectionController;
  static bindings = {
    user: "<",
    patient: "<",
    record: "<",
    recordData: "<",
    mode: "@",
    enableLeft: "<",
    enableRight: "<",
    isEditable: "<",
    consolidatedRecord: "<",
  };
}
