import { get } from "lodash";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import {
  DocumentsService, IRecordDocuments
} from "../../../services/documents-service/documents.service";

class SegmentDocumentController
  implements angular.IController, angular.IOnChanges {
  mode: string;
  record: PatientRecord;
  enableLeft: boolean;
  enableRight: boolean;
  patient: User;
  documentsMap: IRecordDocuments;
  debug = false;

  constructor(private DocumentsService: DocumentsService) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.record && this.record) {
      this.documentsMap = this.DocumentsService.getNamedDocuments(
        this.record.documents
      );
    }
  }

  showDocumentRow(documentNames: string[] = []) {
    if (this.mode === "edit") {
      return true;
    }

    return documentNames.reduce(
      (hasDocument, docName) =>
        hasDocument || get(this.record.data.documents, docName),
      false
    );
  }
}

export class SegmentDocuments implements angular.IComponentOptions {
  static selector = "segmentDocuments";
  static template = require("./segment-documents.html");
  static bindings = {
    mode: "@",
    record: "<",
    enableLeft: "<",
    enableRight: "<",
    patient: "<",
    debug: "<?",
  };
  static controller = SegmentDocumentController;
}
