export class PatientInfo implements angular.IComponentOptions {
  static selector = "patientInfo";
  static template = require("./patient-info.html");
  static bindings = {
    mode: "@",
    record: "<",
    enableLeft: "<",
    enableRight: "<"
  };
}
