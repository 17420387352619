import { IGlSignature } from "app/core/services/signature-service/signature.service";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";

export class ReferralLetterController implements angular.IComponentController {
  // Controller Inputs
  record: PatientRecord;
  patient: User;
  user: User;
  signature: IGlSignature;

  // Calculated properties
  date = new Date();

  constructor() {
    "ngInject";
  }

  getReasonForReferral() {
    if (!this.record?.data?.reason_for_referral_array) {
      return;
    }

    const leftString = (this.record.data.reason_for_referral_array.left ?? [])
      .filter((option) => !!option)
      .map((option) => `L/E ${option.name}`)
      .join(", ");
    const rightString = (this.record.data.reason_for_referral_array.right ?? [])
      .filter((option) => !!option)
      .map((option) => `R/E ${option.name}`)
      .join(", ");
    if (leftString && rightString) {
      return `${leftString}, ${rightString}`;
    } else {
      return leftString || rightString;
    }
  }
}
