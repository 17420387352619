/**
 * $pageTitle
 * ==========
 *
 * Allows to control the page title from the AngularJS route system, controllers
 * or any other component through an injectable service.
 *
 * ### ngPageTitle - Page title service
 *
 * To get started add the module to your app and configure the page title
 * provider:
 *
 * ```js
 * angular.module('app', ['ngPageTitle'])
 * .config(function($pageTitleProvider) {
 *   // Use the current doc title
 *   $pageTitleProvider.setDefaultTitle();
 *   // Set a custom default title
 *   $pageTitleProvider.setDefaultTitle('My App');
 * });
 * ```
 *
 * Then setup the `$pageTitle` property along your routes:
 *
 * ```js
 * $routeProvider.when('path/to', {
 *   // ...
 *   pageTitle: 'Route title'
 * });
 * ```
 *
 * The document title will automatically update every time the route changes
 * with success.
 *
 * It's also possible to change the routes within any component that supports
 * injection through the `$pageTitle` service, with the `set` method:
 *
 * ```js
 * function MyAppCtrl($pageTitle) {
 *   $pageTitle.set('Controller title');
 * }
 * ```
 */

export class PageTitleService {
  static injectionName = "PageTitleService";

  private defaultTitle = "";
  private currentTitle = "";
  constructor(
    private $window: angular.IWindowService,
    private $transitions: any
  ) {
    "ngInject";
    if (this.defaultTitle) {
      $window.document.title = this.defaultTitle;
    } else {
      this.defaultTitle = $window.document.title;
    }

    this.$transitions.onSuccess({}, (transition) => {
      const state: angular.ui.IState = transition.to();
      this.setTitleFromState(state);
    });
  }

  setDefaultTitle(title: string) {
    this.defaultTitle = title;
  }

  /**
   * Returns the current document title.
   * @return {string}
   */
  get() {
    return this.$window.document.title;
  }

  /**
   * Sets the document title.
   * @param {String} title - The title.
   */
  set(title: string) {
    this.currentTitle = title || this.defaultTitle;
    this.$window.document.title = this.currentTitle;
  }

  private setTitleFromState(state: angular.ui.IState) {
    const title = state?.data?.title;
    if (title) {
      this.set(title);
    }
  }
}
