import { IGlSide } from "../../../../../models/gl-side.model";
import {
  PatientRecordData,
  GlBilateral,
  GlDiagnosis,
} from "../../../../../models/patient-record.model";
import { DiagnosisService } from "../../../services/diagnosis.service";
import { GlModelService } from "../../../services/gl-model.service";
import { GlFormController } from "../../gl-form-controller";

export class BilateralRiskCalculatorController
  extends GlFormController
  implements angular.IController
{
  recordData: PatientRecordData;
  enableLeft = true;
  enableRight = true;
  hasRightErrors = true;
  hasLeftErrors = true;
  consolidatedRecord: PatientRecordData;
  patientAge: number;

  // current record data
  // iop
  // psd
  // cct
  // cdr
  // dm // if diabetic dm = 1, else dm = 0

  constructor(
    private GlModelService: GlModelService,
    private DiagnosisService: DiagnosisService
  ) {
    "ngInject";
    super();
  }

  $onInit() {
    //
  }

  $onChanges() {
    //
  }
  calculateRisk(side: IGlSide) {
    // GET VALUES FOR CALCULATION
    const iop = this.getFromConsolidatedRecord(`iop.${side}`);
    const psd = this.getFromConsolidatedRecord(`psd.${side}`);
    const cct = this.getFromConsolidatedRecord(`cct.${side}`);
    const cdr = this.getFromConsolidatedRecord(`cdr.${side}`);
    const dm = this.getFromConsolidatedRecord(`medical_history.diabetes`)
      ? 1
      : 0;
    const age = this.patientAge;

    // RISK CALCULATOR FROM https://oil.wilmer.jhu.edu/risk/

    // VALIDATE VALUES
    const errorMsg = this.hasErrors(side);

    if (errorMsg) {
      return `Could not calculate risk.\n ${errorMsg}`;
    }

    // CALCULATE RISK
    let risk =
      1 -
      Math.pow(
        0.906,
        Math.exp(
          (Math.log(1.25) / 10) * (age - 55.4) +
            Math.log(1.11) * (iop - 24.9) +
            (Math.log(1.25) / 0.2) * (psd - 1.9) -
            (Math.log(1.82) / 40) * (cct - 574.5) +
            (Math.log(1.32) / 0.1) * (cdr - 0.39) +
            Math.log(0.35) * (dm - 0.121)
        )
      );
    risk = Math.round(risk * 1000) / 10;

    return `${risk}%`;
  }

  getFromConsolidatedRecord(field: string) {
    return this.GlModelService.getFromRecordOrHistory(
      field,
      this.recordData,
      this.consolidatedRecord
    );
  }

  hasOcular(eye: IGlSide = "both") {
    if (!this.getFromConsolidatedRecord("management.diagnosis_array")) {
      return;
    }
    const ocularHypertensionDiagnosis = "Ocular Hypertension";

    const diagnosis: GlBilateral<GlDiagnosis[]> =
      this.getFromConsolidatedRecord("management.diagnosis_array");
    const leftDiagnosis = diagnosis?.left?.map((d) =>
      this.DiagnosisService.getDiagnosis(d)
    );
    const rightDiagnosis = diagnosis?.right?.map((d) =>
      this.DiagnosisService.getDiagnosis(d)
    );

    if (eye === "left") {
      return leftDiagnosis?.includes(ocularHypertensionDiagnosis);
    } else if (eye === "right") {
      return rightDiagnosis?.includes(ocularHypertensionDiagnosis);
    } else {
      return (
        rightDiagnosis?.includes(ocularHypertensionDiagnosis) ||
        leftDiagnosis?.includes(ocularHypertensionDiagnosis)
      );
    }
  }
  hasErrors(side: string) {
    // GET VALUES FOR CALCULATION
    const iop = this.getFromConsolidatedRecord(`iop.${side}`);
    const psd = this.getFromConsolidatedRecord(`psd.${side}`);
    const cct = this.getFromConsolidatedRecord(`cct.${side}`);
    const cdr = this.getFromConsolidatedRecord(`cdr.${side}`);
    const age = this.patientAge;

    let errorMsg = "";
    if (isNaN(age)) {
      errorMsg = `${errorMsg} Age is required to calculate the risk\n`;
    } else if (age < 40 || age > 90) {
      errorMsg = `${errorMsg} The patient's age should be between 40 and 90\n`;
    }
    if (isNaN(iop)) {
      errorMsg = `${errorMsg} IOP is required to calculate the risk\n`;
    } else if (iop < 22 || iop > 32) {
      errorMsg = `${errorMsg} The valid range for IOP is between 22 and 32\n`;
    }
    if (isNaN(psd)) {
      errorMsg = `${errorMsg} PSD is required to calculate the risk\n`;
    } else if (psd < 0.5 || psd > 4.0) {
      errorMsg = `${errorMsg} The valid range for PSD is 0.50 to 4.00\n`;
    }
    if (isNaN(cct)) {
      errorMsg = `${errorMsg} CCT is required to calculate the risk\n`;
    } else if (cct < 450 || cct > 700) {
      errorMsg = `${errorMsg} The valid range for central corneal thickness is 450 to 700 microns\n`;
    }
    if (isNaN(cdr)) {
      errorMsg = `${errorMsg} CDR is required to calculate the risk\n`;
    } else if (cdr < 0 || cdr > 0.9) {
      errorMsg = `${errorMsg} The valid range for vertical cup-to-disc ratio is between 0 and 0.9\n`;
    }

    if (errorMsg) {
      return errorMsg;
    } else {
      return false;
    }
  }
  showRisk(eye: string = "both") {
    if (this.mode !== "edit") {
      if (eye === "both") {
        return !this.hasErrors("left") || !this.hasErrors("right");
      } else {
        return !this.hasErrors(eye);
      }
    }
    return true;
  }
}

export class BilateralRiskCalculator implements angular.IComponentOptions {
  static selector = "bilateralRiskCalculator";
  static template = require("./bilateral-risk-calculator.html");
  static controller = BilateralRiskCalculatorController;
  static bindings = {
    recordData: "<",
    consolidatedRecord: "<",
    enableLeft: "<?",
    enableRight: "<?",
    patientAge: "<",
    mode: "@?",
  };
}
