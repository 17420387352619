export const pipeName = "pronounPipe";
export function pronounPipe() {
  "ngInject";
  return (gender: "M" | "F") => {
    if (typeof gender !== "string") {
      return gender;
    }
    return gender.toLowerCase().startsWith("m") ? "he" : "she";
  };
}
