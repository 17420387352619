import { PatientRecord, PatientRecordData } from "models/patient-record.model";
import { SEGMENT_FIELD_CLINICAL_DATA } from "../services/segment-helper.service";

// const CLINICAL_DATA_KEYS = [
//   "va_numerator",
//   "aided_va_numerator",
//   "subjective_va_numerator",
//   "ph_numerator",
//   "iop",
//   "refraction.axis",
//   "cct",
//   "ph_denominator",
//   "lens_status",
// ];

export function checkSideAvailableClinicalData(
  data: PatientRecordData,
  side: string
) {
  for (const key of SEGMENT_FIELD_CLINICAL_DATA) {
    if (data?.[key]?.[side]) {
      return true;
    }
  }
  if (
    data?.refraction?.sphere &&
    (data.refraction?.sphere[side] || data.refraction?.cylinder[side])
  ) {
    return true;
  }
  return false;
}

export function getClinicalLateralities(
  currentRecord: PatientRecord,
  records?: PatientRecord[]
) {
  let enableLeft = true;
  let enableRight = true;
  if (records && records.length > 1) {
    enableLeft = records.some((r) =>
      checkSideAvailableClinicalData(r.data, "left")
    );
    enableRight = records.some((r) =>
      checkSideAvailableClinicalData(r.data, "right")
    );
  } else {
    if (currentRecord.created_at === currentRecord.updated_at) {
      enableLeft = true;
      enableRight = true;
    } else {
      enableLeft = checkSideAvailableClinicalData(currentRecord.data, "left");
      enableRight = checkSideAvailableClinicalData(currentRecord.data, "right");
    }
  }

  return { enableLeft, enableRight };
}

export function getManagementLateralities(
  currentRecord: PatientRecord,
  records?: PatientRecord[]
) {
  let enableLeft = true;
  let enableRight = true;
  if (currentRecord.created_at === currentRecord.updated_at) {
    if (records && records.length > 1) {
      enableLeft = records.some(
        (r) => r.data?.management?.diagnosis_array?.left
      );
      enableRight = records.some(
        (r) => r.data?.management?.diagnosis_array?.right
      );
    } else {
      enableLeft = true;
      enableRight = true;
    }
  } else {
    enableLeft = !!currentRecord.data?.management?.diagnosis_array?.left;
    enableRight = !!currentRecord.data?.management?.diagnosis_array?.right;
  }

  // if (enableLeft === false && enableRight === false) {
  //   enableLeft = true;
  //   enableRight = true;
  // }

  return { enableLeft, enableRight };
}
