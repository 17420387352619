import { AppointmentService } from "app/core/services/appointments/appointments.service";
import { IPatientResource } from "app/core/services/patient.service";
import { Appointment } from "models/appointment.model";
import { GlClinicActiveRecord } from "models/clinic-active-records.model";
import { IGlFormMode } from "models/gl-form-mode";
import { PatientRecord } from "models/patient-record.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../lib/date_picker_alt_formats";
import { parseServerDate } from "../../../lib/parse_server_date";
import { Letter } from "../../../models/letter.model";
import { Subscription } from "../../../models/subscription.model";
import { User } from "../../../models/user.model";
import { ClinicService } from "../../core/services/clinic.service";
import { LetterService } from "../../core/services/letter.service";
import {
  REPORTING_CATEGORIES,
  LETTER_STATUSES,
} from "../../core/services/appendix";

import "./reporting.scss";
import angular = require("angular");
import moment = require("moment");

class ReportingController implements angular.IController, angular.IOnInit {
  user: User;
  dateOfBilling: any;
  dateOfLetters: any;
  dobDatePopIsOpen = false;
  datepickerOptions = {
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    minDate: "11/11/2021",
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  toggleBetweenRecords = "all";
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;
  appointments: Appointment[];
  letter: Letter;
  record: PatientRecord;
  createNewHistoryRecordInProgress = false;
  createNewInjectionInProgress = false;
  createNewRecordInProgress = false;
  createNewTechRecordInProgress = false;
  patient: IPatientResource;
  PatientAllLetters: Letter[];
  PatientId = this.$stateParams.Patient_id;
  providersForm: angular.IFormController;
  providersViewMode: IGlFormMode = "display";
  records: GlClinicActiveRecord[];
  letters: Letter[];
  patientId = this.$stateParams.patient_id;
  saveProvidersInProgress = false;
  currentRecord: PatientRecord;
  selectedRecord: PatientRecord;
  loadingSubscription: boolean;
  reportingCategories = REPORTING_CATEGORIES;
  letterStatuses = LETTER_STATUSES;
  activeLetterFilters: string[] = [];
  subscriptionDetails: Subscription;
  initialLoadState = true;
  currentState: string;
  dateFilter = this.$filter("date");

  constructor(
    public toastr: angular.toastr.IToastrService,
    private $stateParams: angular.ui.IStateParamsService,
    private $filter: angular.IFilterService,
    private $q: angular.IQService,
    private ClinicService: ClinicService,
    private LetterService: LetterService,
    private $window: angular.IWindowService,
    private $state: angular.ui.IStateService,
    private AppointmentService: AppointmentService
  ) {
    "ngInject";
  }

  $onInit() {
    this.dateOfBilling = moment().toISOString();
    this.dateOfLetters = moment().toISOString();
    this.currentState = "billing";
    this.loadBillingReporting();
  }

  loadBillingReporting() {
    this.initialLoadState = false;
    this.getRecords();
    this.getAppointments();
  }

  getAppointments() {
    return this.AppointmentService.get({ date: this.dateOfBilling }).then(
      (appointments) => (this.appointments = appointments)
    );
  }

  formatDateForDisplay(date: Date) {
    return moment(date).format("DD/MM/YYYY");
  }

  openDatePicker() {
    this.dobDatePopIsOpen = true;
  }

  updateDob() {
    this.getRecords();
    this.getAppointments();
  }

  updateLetters() {
    this.getLetters();
  }

  showReportingDetails(reportingItem: string) {
    this.initialLoadState = false;
    this.currentState = reportingItem;
    switch (reportingItem) {
      case "billing":
        this.loadBillingReporting();
        break;

      case "letters":
        this.getLetters();
        break;

      default:
        return null;
    }
  }

  selectLetterFilter(filter: string) {
    const isSelectedFilter = this.activeLetterFilters?.indexOf(filter);
    isSelectedFilter === -1
      ? this.activeLetterFilters?.push(filter)
      : this.activeLetterFilters?.splice(isSelectedFilter);
    this.getLetters();
  }

  isFilterActive(filter: string) {
    return this.activeLetterFilters.includes(filter);
  }

  getLetters() {
    const fetchLettersByDatePromise = this.LetterService.getLettersFromClinic({
      clinicId: this.user.clinic_id,
      date: this.dateOfLetters,
    });
    return this.$q.all([fetchLettersByDatePromise]).then(([letters]) => {
      return (this.letters = letters.filter((l) => {
        const now = moment().format("DD/MM/YYYY");
        const letterDate = moment(parseServerDate(l.created_at)).format(
          "DD/MM/YYYY"
        );
        if (this.activeLetterFilters.length > 0) {
          return (
            now === letterDate && this.activeLetterFilters.includes(l.status)
          );
        } else {
          return now === letterDate;
        }
      }));
    });
  }

  getRecords() {
    const fetchRecordsByDatePromise = this.ClinicService.getRecordsForClinic({
      date: this.dateOfBilling,
      clinic: this.user.clinic,
      activeRecords: false,
    });

    return this.$q.all([fetchRecordsByDatePromise]).then(([records]) => {
      this.records = records.filter((rec) => {
        const now = moment().format("DD/MM/YYYY");
        const recordDate = moment(
          parseServerDate(rec.record.created_at)
        ).format("DD/MM/YYYY");
        return now === recordDate;
      });
      this.records = records;
    });
  }

  print() {
    this.$window.print();
  }

  goToPatient(patientId: number) {
    this.$state.go("main.patient", {
      patient_id: patientId,
    });
  }

  goToRecord(recordId: number, patientId: number) {
    this.$state.go("main.record", {
      patientId: patientId,
      recordId: recordId,
    });
  }
}

export class ReportingPage implements angular.IComponentOptions {
  static selector = "reporting";
  static template = require("./reporting.html");
  static controller = ReportingController;
  static bindings = {
    user: "<",
    records: "<",
  };
}
