import { cloneDeep, defaultsDeep, set } from "lodash";
import { IGlSide } from "../../../../../models/gl-side.model";
import { PatientRecordData } from "../../../../../models/patient-record.model";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../../../core/components/gl-form-controller";
import { IGlOption, Appendix } from "../../../../core/services/appendix";

class ReasonForReferralComponentController
  extends GlFormController
  implements angular.IController, angular.IOnChanges {
  // @Input()
  record: PatientRecordData;
  enableLeft = true;
  enableRight = true;

  // Controller Properties
  referralOptions = this.appendix.get("reasonForReferral", true);
  referralUrgencyOptions = this.appendix.get("referralUrgency", true);

  constructor(private appendix: Appendix) {
    "ngInject";
    super();
  }
  ////////////////

  $onChanges(changesObj: angular.IOnChangesObject) {
    if (
      changesObj.record &&
      this.record &&
      this.isEditable &&
      this.isEditMode()
    ) {
      const defaults = {
        reason_for_referral_array: {
          left: [null],
          right: [null],
        },
      };
      defaultsDeep(this.record, defaults);
    }
  }

  getBiggestDiagnosisArray() {
    const diagnosis = this.record?.reason_for_referral_array;
    const left = diagnosis?.left || [];
    const right = diagnosis?.right || [];
    return left.length > right.length ? left : right;
  }

  addDiagnosis(diagnosisArray: IGlOption[]) {
    diagnosisArray.push(null);
  }

  deleteDiagnosis(diagnosisArray: IGlOption[], index: number) {
    diagnosisArray.splice(index, 1);
  }

  copyReferral(index: number, toSide: IGlSide) {
    const fromSide = toSide === "right" ? "left" : "right";
    const fromValue = this.record?.reason_for_referral_array?.[fromSide][index];
    set(
      this.record,
      `reason_for_referral_array.${toSide}[${index}]`,
      cloneDeep(fromValue)
    );
  }
}

export class ReasonForReferralComponent implements angular.IComponentOptions {
  static selector = "glReasonForReferral";
  static template = require("./reason-for-referral.component.html");
  static controller = ReasonForReferralComponentController;
  static bindings = {
    record: "<",
    enableLeft: "<",
    enableRight: "<",
    ...GlFormControllerBindings,
  };
}
