import { defaults } from "lodash";
import {
  Appendix,
  APPOINTMENT_REGEX,
  IGlOption,
} from "../../../../../app/core/services/appendix";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import "./segment-clinical-data.scss";
import angular = require("angular");

export const FIELDS = [
  "va",
  "iop",
  "refraction",
  "lens",
  "lens_notes",
  "cct",
  "tech_notes",
  "dominance",
] as const;

class SegmentClinicalDataController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  showReasonForReferral = true;
  appointmentNotes?: string;
  presentingComplaintOptions = this.appendix.get("presentingComplaint", true);
  public record: {
    presenting_complaint: IGlOption;
    reason_for_referral: string;
  };

  constructor(private appendix: Appendix) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (
      (changes.record || changes.isEditable) &&
      this.record &&
      this.isEditable
    ) {
      defaults(this.record, {
        presenting_complaint: this.getDefaultPresentingComplaint(),
      });
    }
  }

  private getDefaultPresentingComplaint(): IGlOption {
    const presentingComplaintFromAppointmentNotes =
      this.determinPresentingComplaintFromAppointment(this.appointmentNotes);
    return (
      presentingComplaintFromAppointmentNotes ??
      (this.appendix.getDefaultKey("presentingComplaint") as IGlOption)
    );
  }

  private determinPresentingComplaintFromAppointment(
    appointmentNotes: string
  ): IGlOption {
    if (!appointmentNotes || typeof appointmentNotes !== "string") {
      return;
    }

    const lowercaseAppointmentNotes = appointmentNotes.toLocaleLowerCase();
    /**
     * 104R 105R 105IJ OPINJ
     */

    const isRetinaRegex = APPOINTMENT_REGEX.Retina.regex;
    const isGlaucomaRegex = APPOINTMENT_REGEX.Glaucoma.regex;
    const isCataractRegex = APPOINTMENT_REGEX.Cataract.regex;

    const allOptions = this.appendix.get("presentingComplaint");

    if (isRetinaRegex.test(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "retina");
    } else if (isGlaucomaRegex.test(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "glaucoma");
    } else if (isCataractRegex.test(lowercaseAppointmentNotes)) {
      return allOptions.find((o) => o.key === "cataract");
    } else {
      return allOptions.find((o) => o.key === "cataract");
    }
  }
}

export class SegmentClinicalData implements angular.IComponentOptions {
  static selector = "segmentClinicalData";
  static template = require("./segment-clinical-data.html");
  static controller = SegmentClinicalDataController;
  static bindings = {
    record: "<",
    medicalHistoryData: "<?",
    recordHistory: "<",
    showReasonForReferral: "<?",
    enableLeft: "<",
    appointmentNotes: "<?",
    enableRight: "<",
    newRecord: "<?",
    ...GlFormControllerBindings,
  };
}
