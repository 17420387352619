import { Provider, ProviderType } from "../../../../../models/provider.model";
import { ProviderModel } from "../../../services/provider-v1.service";

class CreateProviderController implements angular.IController {
  providerId: number;
  providerDidChange: (providerId: number) => void;
  provider: any;
  type: ProviderType;

  constructor(public providerModel: ProviderModel) {
    "ngInject";
  }

  searchProvider(filterText: string) {
    const p = this.providerModel
      .getAllByType(this.type)
      .then((response) => response.data)
      .then((providers: Provider[]) =>
        providers
          .filter((p: Provider) => p.name.includes(filterText))
          .slice(0, 8)
      );
    return p;
  }
}

export class CreateProviderComponent implements angular.IComponentOptions {
  static selector = "createProvider";
  static template = require("./create-provider.html");
  static controller = CreateProviderController;
  static bindings = {
    providerId: "<",
    providerDidChange: "&",
    type: "@",
  };
}
