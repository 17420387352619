/* eslint-disable @typescript-eslint/no-unused-vars */
import { IOnChangesObject, isFunction } from "angular";
import { PatientProcedureFormService } from "app/core/services/PatientProcedureFormService/patient-procedure-form.service";
import { AuthService } from "app/core/services/auth.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { cloneDeep, defaultsDeep, isEmpty, isNil, set } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import { IGlSide } from "models/gl-side.model";
import { PatientProcedureExternal } from "models/patient-procedure";
import {
  PatientCataractConsentRecord,
  PatientRecord,
} from "models/patient-record.model";
import {
  GlStaff,
  IGlUserCataractFormSettings,
  Patient,
} from "models/user.model";
import { GlModelDefaultMode } from "../../../../../app/core/directive/gl-model";
import {
  Appendix,
  CATARACT_FORM_KEYS,
  IGlOption,
} from "../../../../../app/core/services/appendix";
import {
  // PATIENT_RECORD_EVENT_SAVE,
  PATIENT_RECORD_SET_PRISTINE,
} from "../../../../../app/pages/main.record/record";
import { GlFormController } from "../../gl-form-controller";

import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import "./cataracts-section.scss";

export class CataractsSectionController
  extends GlFormController
  implements angular.IController, angular.IOnInit, angular.IOnChanges
{
  user: GlStaff;
  patient: Patient;
  mode: IGlFormMode;
  isEditable: boolean;

  // form
  cataractsSectionModel: angular.IFormController;

  // change this to record and all instances outside
  // recordData: PatientRecordData;
  record: PatientRecord;
  cataractConsentRecord: PatientCataractConsentRecord;

  // external procedures
  externalProcedures: PatientProcedureExternal[];

  enableLeft: boolean = false;
  enableRight: boolean = false;

  defaultOption: IGlOption = null;
  // toggle between user and all
  // can also be associated with state from a parent
  useUserOptions: boolean = false;
  // taken from user
  userCataractConsentFormSettings: IGlUserCataractFormSettings;

  // error
  errorMessages = this.ErrorAppendix.getRecordsErrorMessages();

  // saveCataractConsentFormInProgress
  saveCataractConsentInProgress: boolean = false;

  // progress state (use for both signing/unsigning)
  userSignInProgress: boolean = false;
  patientSignInProgress: boolean = false;

  // re-open
  reopenAndEditInProgress: boolean = false;

  toggleTab: (arg: { state: boolean }) => void;

  constructor(
    public appendix: Appendix,
    private AuthService: AuthService,
    private PatientRecordService: PatientRecordService,
    private PatientProcedureService: PatientProcedureService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    private PatientProcedureFormService: PatientProcedureFormService,
    private ErrorAppendix: ErrorAppendix,
    private toastr: angular.toastr.IToastrService,
    private $scope: angular.IScope,
    private $q: angular.IQService,
    private $state: angular.ui.IStateService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    this._updateUserCataractConsentFormSettings();
  }

  $onChanges(changes: IOnChangesObject): void {
    // if there are any changes to mode or the user, update
    if (changes?.user && this.user) {
      this._updateUserCataractConsentFormSettings();
    }

    // if changed to edit mode set defaults
    if (changes?.mode && this.isEditMode()) {
      this._focusBilateralSelectRows();
    }
    if (changes?.record && this.record) {
      if (this.record?.cataract_consent_form) {
        this.cataractConsentRecord = this?.record?.cataract_consent_form;
      }
    }
  }

  // get mode
  getMode(): IGlFormMode {
    // if surgeon has signed, cannot edit
    // it is the onus of the practitioner to get it right
    if (this.userSignedCataractConsentForm()) {
      return "display";
    }

    // otherwise follow inherited or default to edit
    return this.mode;
  }

  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  // CONSENT FORM DISPLAY RELATED
  // should show row?
  shouldDisplayField(fieldKey: string) {
    // if we are not using user options then always display
    if (!this.useUserOptions) {
      return true;
    }

    // otherwise by default always check
    return this?.userCataractConsentFormSettings?.fields_to_display?.includes(
      fieldKey
    );
  }

  // side here is depenednet on whether it should be dispalyed also
  shouldEnableSide(fieldKey: string, side: IGlSide) {
    if (side === "left") {
      return this.enableLeft && this.shouldDisplayField(fieldKey);
    } else if (side === "right") {
      return this.enableRight && this.shouldDisplayField(fieldKey);
    } else {
      // both
      return (
        (this.enableLeft || this.enableRight) &&
        this.shouldDisplayField(fieldKey)
      );
    }
  }

  // default mode is based on the user's settings
  // and not card display
  getDefaultModeForField(fieldKey: string) {
    return this.userCataractConsentFormSettings?.fields_to_display?.includes(
      fieldKey
    )
      ? GlModelDefaultMode.default
      : GlModelDefaultMode.none;
  }

  // USER DEFAULT OPTIONS RELATED
  // on toggle, handles setting defaults on empty fields
  // if defaults are defined
  handleOnToggleUserOptions() {
    // if we toggle to use options
    this.useUserOptions = !this.useUserOptions;
  }

  getUserDefaultOption(fieldKey: string) {
    // if we are using user default options
    if (
      this?.userCataractConsentFormSettings?.field_options &&
      this.useUserOptions
    ) {
      const defaultOption: IGlOption =
        this?.userCataractConsentFormSettings?.field_options?.[fieldKey]?.find(
          (o) => o.default
        );
      return defaultOption ?? null;
    } else {
      // otherwise default will be null
      return null;
    }
  }

  // CONSENT FORM RELATED
  hasLinkedCataractConsentForm() {
    return !isNil(this.cataractConsentRecord);
  }

  // SIGNING STUFF
  // is it signed?
  userSignedCataractConsentForm() {
    if (!this.cataractConsentRecord) {
      return false;
    }
    return this.cataractConsentRecord?.data_status === "SIGNED";
  }

  // is it singed by patient?
  patientSignedCataractConsentForm() {
    if (!this.cataractConsentRecord) {
      return false;
    }
    return (
      this.cataractConsentRecord.data?.signature_data?.patient?.status ===
      "SIGNED"
    );
  }

  // SINGATURE CHECKS
  bothSignaturesPresent() {
    return (
      this.userSignedCataractConsentForm() &&
      this.patientSignedCataractConsentForm()
    );
  }

  eitherSignaturePresent() {
    return (
      this.userSignedCataractConsentForm() ||
      this.patientSignedCataractConsentForm()
    );
  }

  // current record signed
  recordIsSigned() {
    return this?.record?.data_status === "SIGNED";
  }

  // CRUD RELATED
  // creates consent form
  createLinkedCataractConsentFormRecord() {
    return this.PatientRecordService.createLinkedRecord(
      this.record,
      "consent_form_cataract"
    );
  }

  // either fetches an existing cataract form record or
  // creates a new one
  getOrCreateLinkedCataractConsentFormRecord() {
    if (!this.hasLinkedCataractConsentForm()) {
      return this.createLinkedCataractConsentFormRecord();
    } else {
      return Promise.resolve(this.cataractConsentRecord);
    }
  }

  // create and save
  saveCataractConsentForm(saveParent: boolean = true) {
    this.saveCataractConsentInProgress = true;
    this.getOrCreateLinkedCataractConsentFormRecord()
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        this.cataractConsentRecord = cataractConsentForm;
        // instantiate
        if (
          isNil(this.cataractConsentRecord?.data?.management) ||
          isEmpty(this.cataractConsentRecord?.data?.management)
        ) {
          set(this.cataractConsentRecord, "data.management", {});
        }

        // update
        this.cataractConsentRecord.data.management = cloneDeep(
          this.record.data.management
        );

        // do for cataract form
        return this.PatientRecordService.updateAndSetPractitioner(
          this.cataractConsentRecord,
          this.user
        );
      })
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        // set consent form
        this.cataractConsentRecord = cataractConsentForm;
        this.toastr.success("Successfully saved cataract consent form!");
        // set pristine only if existing
        if (this?.cataractsSectionModel) {
          this.cataractsSectionModel.$setPristine();
        }
      })
      .then(() => {
        // perform the same on the original record as well
        if (saveParent) {
          return this.PatientRecordService.updateAndSetPractitioner(
            this.record,
            this.user
          )
            .then(() => {
              this.toastr.success("Successfully saved record!");
              this.$scope.$emit(PATIENT_RECORD_SET_PRISTINE, this.record.id);
              this.toggleExternalTabState();
            })
            .catch((err) => {
              console.error(err);
              this.toastr.error("Error saving record. Please try again.");
            });
        }
      })

      .catch((err) => {
        console.error(err);
        this.toastr.error("Error saving cataract consent form");
      })
      .finally(() => {
        this.saveCataractConsentInProgress = false;
      });
  }

  // n changes for post op section
  // depending on what EXISTING (not temporary post op procedures exist)
  // autofill
  handleAutofillWhenWithPostOp(option: IGlOption) {
    // 1. get created procedure that matches it
    // latest one will show
    const cataractProcedureInRecord: PatientProcedureExternal =
      this.getExistingCataractProcedureForRecord();

    // based on an existing cataract proceedure done in this record
    // go based on eye order or eye
    if (!isNil(cataractProcedureInRecord)) {
      let optionToChoose: IGlOption;
      // both eye check order
      switch (cataractProcedureInRecord.data.eye) {
        case "both":
          // depends on left right order
          optionToChoose =
            this?.record?.data?.management?.cataract_post_op[
              cataractProcedureInRecord.data.order === "left_right"
                ? "left"
                : "right"
            ];
          break;
        case "left":
          optionToChoose =
            this?.record?.data?.management?.cataract_post_op?.left;
          break;
        case "right":
          optionToChoose =
            this?.record?.data?.management?.cataract_post_op?.right;
          break;
        default:
          optionToChoose = option;
          break;
      }

      // 2. then update based on that cardinality
      this.PatientProcedureFormService.handleAutofillWhenWithPostOp(
        this.record.data,
        optionToChoose ?? option
      );
    }
  }

  // does an existing procedure exist
  getExistingCataractProcedureForRecord() {
    return this.PatientProcedureHelperService.getExternalProcedureForRecordByName(
      this.record.id,
      "Cataract",
      this.externalProcedures
    );
  }

  // toggle state
  toggleExternalTabState(state: boolean = false) {
    if (isFunction(this.toggleTab)) {
      this.toggleTab({ state });
    }
  }

  // reopen and edit consent form, will navigate to it after
  reopenAndEditConsentForm() {
    // first check if consent form exists
    if (!this.hasLinkedCataractConsentForm()) {
      return this.toastr.error(
        "Error re-opening consent form, please try again."
      );
    }

    this.reopenAndEditInProgress = true;
    // otherwise continue
    // first check if user has signed
    this.$q
      .resolve()
      .then(() => {
        // if we want to edit the form, the user/surgeon
        // must not have signed it
        if (this.userSignedCataractConsentForm()) {
          return this.reopenCataractConsentFormUser();
        }
        // otherwise if its just patient signature
        // then just continue on
        return this.cataractConsentRecord;
      })
      .then((cataractConsentForm: PatientCataractConsentRecord) => {
        this.toastr.success("Successfully reopened consent form for editing!");
        this.cataractConsentRecord = cataractConsentForm;
      })
      .catch((err: any) => {
        console.error("Error reopening and editing record", err);
        this.toastr.error(
          "Error reopening and editing consent form, please try again"
        );
      })
      .finally(() => {
        this.reopenAndEditInProgress = false;
      });
  }

  // reopen consent form
  reopenCataractConsentFormUser() {
    this.cataractConsentRecord.status = "IN PROGRESS";
    return this.PatientRecordService.reopen(this.cataractConsentRecord).then(
      (cataractConsentForm: PatientCataractConsentRecord) => {
        this.toastr.success("Successfully unsigned cataract form!");
        this.cataractConsentRecord = cataractConsentForm;
        return cataractConsentForm;
      }
    );
  }

  // set focus to trigger gl model
  private _focusBilateralSelectRows() {
    const selectElements = document.querySelectorAll(
      "bilateral-select,gl-select"
    );
    // reverse to avoid it scrolling down
    for (const element of Array.from(selectElements ?? [])) {
      // then do for the select elements
      const selectElements = element.getElementsByTagName("select");
      for (const select of Array.from(selectElements ?? [])) {
        const _s = select as HTMLElement;
        _s.focus({
          preventScroll: true,
        });
      }
    }
  }

  private _updateUserCataractConsentFormSettings() {
    // assign defaults
    const _cataractConsentFormDefaults = {
      fields_to_display: [],
      field_options: {},
      use_by_default: false,
    };
    this.userCataractConsentFormSettings =
      this.user?.data?.cataract_form_settings ?? _cataractConsentFormDefaults;
    defaultsDeep(
      this.userCataractConsentFormSettings,
      _cataractConsentFormDefaults
    );

    // user options
    this._handleUserDefaultOptions();
    // fields to display
    this._handleUserFieldsToDisplay();
    // check options
    this.useUserOptions =
      this?.user?.data?.cataract_form_settings?.use_by_default ?? false;

    // then focus
    this._focusBilateralSelectRows();
  }

  private _handleUserDefaultOptions() {
    // then defaults
    if (
      !isNil(this?.user?.data?.cataract_form_settings?.field_options) &&
      !isEmpty(this?.user?.data?.cataract_form_settings?.field_options)
    ) {
      // assign directly after filtering through existing options only
      set(
        this.userCataractConsentFormSettings,
        "field_options",
        this.appendix.filterCataractOptionsByExisting(
          this?.user?.data?.cataract_form_settings?.field_options
        )
      );
    } else {
      set(this.userCataractConsentFormSettings, "field_options", {});
    }
  }

  private _handleUserFieldsToDisplay() {
    // also for fields to display
    if (
      !isNil(this?.user?.data?.cataract_form_settings?.fields_to_display) &&
      !isEmpty(this?.user?.data?.cataract_form_settings?.fields_to_display)
    ) {
      set(
        this.userCataractConsentFormSettings,
        "fields_to_display",
        this.user?.data?.cataract_form_settings?.fields_to_display
      );
    } else {
      // set for all by default
      set(
        this.userCataractConsentFormSettings,
        "fields_to_display",
        CATARACT_FORM_KEYS.map((k) => k.key)
      );
    }

    // after setting field
    // do a double check and remove data for any
    // disabled field to prevent unwanted carrying over
    // e.g. when defaults are used
    // but only triggers on edit mode
    if (this.isEditMode()) {
      for (const formKey of CATARACT_FORM_KEYS) {
        // if the key exists in current data set it all to null
        if (
          !isNil(this?.record.data?.management?.[formKey.key]) &&
          !this?.userCataractConsentFormSettings?.fields_to_display?.includes(
            formKey?.key
          )
        ) {
          // if either side exsits remove
          delete this?.record.data?.management[formKey.key];
        }
      }
    }
  }
}

export class CataractsSectionComponent implements angular.IComponentOptions {
  static selector = "cataractsSection";
  static template = require("./cataracts-section.html");
  static controller = CataractsSectionController;
  static bindings = {
    user: "<",
    patient: "<",
    record: "<",
    // recordData: "<",
    mode: "@",
    enableLeft: "<",
    enableRight: "<",
    isEditable: "<",
    useUserOptions: "=?",

    // toggle external tab state
    toggleTab: "&?",

    // cataract form
    cataractForm: "<?",
    externalProcedures: "<",
  };
}
