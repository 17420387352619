import { isFunction } from "angular";
import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { SegmentHelperService } from "app/core/services/segment-helper.service";
import { last } from "lodash";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";
import { GlPrescription } from "models/prescription.model";
import {
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { User } from "../../../../../models/user.model";
import { GlModelService } from "../../../services/gl-model.service";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import { IPatientResource } from "../../../services/patient.service";
import "./patient-history.scss";

export class PatientHistoryController
  implements angular.IController, angular.IOnChanges
{
  // @Input()
  collapsed: boolean;
  debug = false;
  drops: PatientProcedureDrop[];
  externalProcedures: PatientProcedureExternal[];
  inHouseProcedures: PatientProcedureInHouse[];
  patient: IPatientResource;
  records: PatientRecord[];
  showPrint = false;
  user: User;

  // Controller properties
  /**
   * This property stores this historical records for the active record.
   */
  recordsToDisplay: PatientRecord[] = [];
  recordHistory: PatientRecord[];
  currentRecord: PatientRecord;
  consolidatedRecordFromHistory: PatientRecordData;
  virtualReview: PatientRecord;
  dilationReview: PatientRecord;
  virtualReviewDate: Date | string;
  dilationReviewDate: Date | string;
  inHouseProceduresToDisplay: PatientProcedureInHouse[];
  externalProceduresForCurrentRecord: PatientProcedureExternal[];

  drugs: PatientProcedureDrug[];
  prescriptions: GlPrescription[];
  onDrugsUpdate: (arg: { patientId: number }) => void;
  onPrescriptionsUpdate: (arg: { patientId: number }) => void;

  constructor(
    private GlModelService: GlModelService,
    private PatientProcedureHelperService: PatientProcedureHelperService,
    public PatientRecordService: PatientRecordService,
    private SegmentHelperService: SegmentHelperService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.records && this.records) {
      this.recordsToDisplay =
        this.PatientRecordService.getPatientRecordsForDisplay(this.records);
      this.changeActive(last(this.recordsToDisplay));
    }
    if (changes.inHouseProcedures || changes.records) {
      this.updateInHouseProcedures();
    }
    if (changes.externalProcedures || changes.records) {
      this.updateExternalProcedures();
    }
  }

  changeActive(record: PatientRecord) {
    this.currentRecord = record;
    // virtual review
    this.virtualReview = this.currentRecord?.virtual_review
      ? this.currentRecord.virtual_review
      : null;
    this.virtualReviewDate = this.virtualReview
      ? this.virtualReview.data_signed_at
        ? this.virtualReview.data_signed_at
        : this.virtualReview.data_updated_at
      : null;
    // dilation review
    this.dilationReview = this.currentRecord?.dilation_review
      ? this.currentRecord.dilation_review
      : null;
    this.dilationReviewDate = this.dilationReview
      ? this.dilationReview.data_signed_at
        ? this.dilationReview.data_signed_at
        : this.dilationReview.data_updated_at
      : null;

    this.recordHistory = this.recordsToDisplay.filter(
      (r) => r.id <= this.currentRecord.id
    );
    this.consolidatedRecordFromHistory =
      this.GlModelService.getConsolidatedRecordFromHistory(this.recordHistory);
    this.updateInHouseProcedures();
    this.updateExternalProcedures();
  }

  updateInHouseProcedures() {
    if (!(this.currentRecord && this.inHouseProcedures)) {
      return;
    }
    this.inHouseProceduresToDisplay =
      this.PatientProcedureHelperService.getInHouseProceduresForRecord(
        this.currentRecord,
        this.inHouseProcedures
      );
  }

  updateExternalProcedures() {
    if (!(this.currentRecord && this.externalProcedures)) {
      return;
    }
    this.externalProceduresForCurrentRecord =
      this.PatientProcedureHelperService.getExternalProceduresForRecord(
        this.currentRecord,
        this.externalProcedures
      );
  }

  updatePatientPrescriptions() {
    if (isFunction(this.onPrescriptionsUpdate) && this.patient.id) {
      this.onPrescriptionsUpdate({ patientId: this.patient.id });
    }
  }

  updatePatientDrugs() {
    if (isFunction(this.onDrugsUpdate) && this.patient.id) {
      this.onDrugsUpdate({ patientId: this.patient.id });
    }
  }

  print() {
    this.PatientRecordService.openPrintRecordWindow(
      this.currentRecord.user_id,
      this.currentRecord.id,
      true
    );
  }

  canEditRecord() {
    if (this.currentRecord) {
      const today = new Date();
      const recordDate = new Date(this.currentRecord.updated_at);
      return (
        this.currentRecord.clinic_id === this.user.clinic_id &&
        (today.toDateString() === recordDate.toDateString() ||
          this.currentRecord.type === "history")
      );
    }
  }

  showMedicalHistory() {
    return this.SegmentHelperService.recordHasSegment(
      this.currentRecord?.data,
      "medicalHistory"
    );
  }
}

export class PatientHistory implements angular.IComponentOptions {
  static selector = "patientHistory";
  static template = require("./patient-history.html");
  static bindings = {
    collapsed: "<",
    debug: "<?",
    drops: "<?",
    externalProcedures: "<?",
    inHouseProcedures: "<?",
    patient: "<?",
    records: "<",
    showPrint: "<?",
    user: "<",
    drugs: "<",
    prescriptions: "<",
    onPrescriptionsUpdate: "&",
  };
  static controller = PatientHistoryController;
}
