import {
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";

export const pipeName: string = "getProcedureByKey";
export const getProcedureByKeyPipe: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (
    procedures: (PatientProcedureInHouse | PatientProcedureExternal)[],
    key: string
  ) => {
    if (!procedures || !key) {
      return null;
    }
    const foundProcedure: PatientProcedureInHouse | PatientProcedureExternal =
      procedures.find((p) => p.data?.nameAppendix.key === key);
    return foundProcedure ?? null;
  };
};
