import { IPromise, copy } from "angular";
import * as cleanDeep from "clean-deep";
import {
  capitalize,
  cloneDeep,
  defaults,
  filter,
  findLast,
  first,
  get,
  includes,
  intersection,
  isArray,
  isEmpty,
  isNil,
  isObject,
  last,
  lowerCase,
  map,
  max,
  omit,
  pick,
  reverse,
  set,
  some,
} from "lodash";
import { IGlInjectionRecord } from "models/injection";
import { Moment } from "moment";
import { parseServerDate } from "../../../../lib/parse_server_date";
import { IGlSide, IGlSideBilateral } from "../../../../models/gl-side.model";
import {
  GL_LENS_ATTRIBUTES,
  GlBilateral,
  GlDiagnosis,
  GlDiagnosisOption,
  GlPatientRecordPractitioner,
  GlPatientRecordType,
  GlPatientRecordWorkflowState,
  PatientRecord,
  PatientRecordData,
  PatientReferral,
} from "../../../../models/patient-record.model";
import { User } from "../../../../models/user.model";
import { API_PATH_v2 } from "../api-paths";
import { IGlOption } from "../appendix";
import { AuthService } from "../auth.service";
import { DiagnosisService } from "../diagnosis.service";
import {
  IValueAutofillKeyParams,
  ValueAutofillService,
} from "../value-autofill/value-autofill.service";

export interface IApiArrayResponse<T> {
  data: T[];
}

export interface IGlDiagnosisOptionStackDecorator extends IGlOption {
  // for a diagnosis its an observation
  // for an observation its a diagnosis
  // allows to link the both without any coupling since all references are internal
  // and related to autofill
  associated_key: string;
  side: string;
  path: string;
}

// FOR ARRAY FIELDS
const MULTIPLE_SELECTION_OBSERVATION_KEYS: string[] = [
  "oct_mac_v2",
  "macular_v2",
];

export class PatientRecordService {
  static injectionName = "PatientRecordService";
  public baseUrl = `${this.API_URL}${API_PATH_v2}`;
  public DIAGNOSIS_ARRAY_KEY: string = "management.diagnosis_array";
  private cache = this.$cacheFactory.get("$http");

  // stack to keep track of changes done to any data field
  // extendable to account for anything
  // key must be very specific
  private recordChangesStack: Map<string, any> = new Map<string, any>();

  constructor(
    private API_URL: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $http: angular.IHttpService,
    private DiagnosisService: DiagnosisService,
    private $window: angular.IWindowService,
    private $state: angular.ui.IStateService,
    private toastr: angular.toastr.IToastrService,
    private ValueAutofillService: ValueAutofillService,
    private AuthService: AuthService
  ) {
    "ngInject";
  }

  // STACK CHANGES
  // get the map
  getChangesStack() {
    return this.recordChangesStack;
  }

  // fetch value
  getValueFromChangesStack(key: string): any {
    if (!this.existingInChangesStack(key)) {
      return;
    }
    return this.recordChangesStack.get(key);
  }

  // if not existing or existing we still need to update
  updateChangesStack(key: string, value: any) {
    this.recordChangesStack.set(key, value);
  }

  // key exists
  existingInChangesStack(key: string): boolean {
    return this.recordChangesStack.has(key);
  }

  // on destroy always call this
  resetChangesStack() {
    this.recordChangesStack = new Map<string, any>();
  }

  // apply previous changes to record if found
  applyPreviousStackChangeToRecord({ record, key }: { record; key }) {
    // then get and update
    const previousData: any = this.recordChangesStack.get(key);
    // update using the actual key to set
    set(record, key, previousData);
    // // remove old change from stack
    this.recordChangesStack.delete(key);
  }

  // for multi-aray field
  applyPreviousStackChangeToObservationMultiField({
    record,
    parent_key,
    side,
    internalObservationKey,
  }: {
    record: PatientRecordData;
    parent_key: string;
    internalObservationKey: string;
    side: IGlSideBilateral;
  }) {
    // get the value
    const previousData: any = this.recordChangesStack.get(
      internalObservationKey
    );

    // find related autofill index
    // get observation fields
    const observationKeySplit: string[] = reverse(
      internalObservationKey.split(".")
    );
    const observationFields: IGlOption[] = get(record, `${parent_key}.${side}`);
    // find where key is included
    const indexOfApplied: number = observationFields?.findIndex((o) =>
      observationKeySplit.includes(o.key)
    );

    // set that specific index or remove if fail
    if (indexOfApplied >= 0) {
      set(
        record,
        `${parent_key}.${side}[${indexOfApplied}]`,
        cloneDeep(previousData)
      );
    }
    // detete key
    this.recordChangesStack.delete(internalObservationKey);
  }

  // observation lens field
  applyPreviousStackChangeToObservationLensField({
    record,
    parent_key,
    side,
    option,
  }: {
    record: PatientRecordData;
    side: IGlSideBilateral;
    parent_key: string;
    option: IGlOption;
  }) {
    const lensKeys: string[] = this.getLensAttributes();
    for (const key of lensKeys) {
      const recordKey: string = `${parent_key}.${key}.${side}`;
      // for observation its better to replace by index
      switch (key) {
        case "observations":
          // try and find first index
          const foundIndex: number = record.lens[key][side]?.findIndex(
            (o) => o?.type?.key === option?.key
          );

          // if we have a match do a direct replace
          if (foundIndex >= 0 && !isNil(record.lens[key][side])) {
            set(
              record,
              `${recordKey}.[${foundIndex}]`,
              this.recordChangesStack.get(recordKey)
            );
            record;
            this.recordChangesStack.delete(recordKey);
            break;
          } else {
            // otherwise default action
            this.applyPreviousStackChangeToRecord({
              record,
              key: recordKey,
            });
            break;
          }
        default:
          this.applyPreviousStackChangeToRecord({
            record,
            key: recordKey,
          });
          break;
      }
    }
  }

  deletePreviousStackChange(key: string) {
    this.recordChangesStack.delete(key);
  }

  // AUTO-DIAGNOSIS FILLERS
  // edge case see if already existsing
  checkIfAutofillDiagnosisExists(
    record: PatientRecordData,
    side: IGlSideBilateral,
    diagnosisOption: GlDiagnosisOption
  ) {
    /*
      check if there is an existing diagnosis already
      we account for alternative keys as well
    */
    const alternativeKey: string =
      this.DiagnosisService.convertDiagnosisKeyToObservationKey(
        diagnosisOption?.key
      );
    const existingDiagnosis = record?.management?.diagnosis_array[side]?.find(
      (d) => {
        // if there are no keys in common, i.e. intersection is empty
        return !isEmpty(
          intersection(
            map([d?.level1?.key, d?.level2?.key], lowerCase),
            map([diagnosisOption?.key, alternativeKey], lowerCase)
          )
        );
      }
    );

    return !isNil(existingDiagnosis);
  }

  // same thing but by option index
  getIndexOfDiagnosisByOption(
    diagnosisArray: GlBilateral<GlDiagnosis[]>,
    side: IGlSideBilateral,
    diagnosisOption: GlDiagnosisOption
  ) {
    // if nothing dont bother
    if (isNil(diagnosisArray) || isEmpty(diagnosisArray)) {
      return -1;
    }
    /*
        check if there is an existing diagnosis already
        we account for alternative keys as well
  */
    const alternativeKey: string =
      this.DiagnosisService.convertDiagnosisKeyToObservationKey(
        diagnosisOption?.key
      );
    const existingDiagnosisIndex = diagnosisArray?.[side]?.findIndex((d) => {
      // if there are no keys in common, i.e. intersection is empty
      return !isEmpty(
        intersection(
          map([d?.level1?.key, d?.level2?.key], lowerCase),
          map([diagnosisOption?.key, alternativeKey], lowerCase)
        )
      );
    });

    return existingDiagnosisIndex;
  }

  // DIAGNOSIS ARRAY
  // check if diagnosis array side does not allow for multi-select
  checkIfDiagnosisSideAllowsMultiSelect(
    record: PatientRecordData,
    side: IGlSideBilateral
  ) {
    for (const diagnosis of record.management.diagnosis_array[side] ?? []) {
      const { level1, level2 } = diagnosis;

      if (some([level1, level2], (o) => !this.isDiagnosisMultiSelect(o))) {
        return false;
      }
    }

    return true;
  }

  // general helper, multi select === allows for extra diagnoses
  isDiagnosisMultiSelect(diagnosis: GlDiagnosisOption) {
    return this.DiagnosisService.checkIfDiagnosisAllowsMultiSelect(diagnosis);
  }

  // AUTOFILL
  // autofill generic
  autofillDiagnosisSide({
    record,
    side,
    parent_key,
    timestamp_key,
    option,
  }: {
    record: PatientRecordData;
    side: IGlSideBilateral;
    parent_key: string;
    timestamp_key: number;
    option: GlDiagnosisOption;
  }) {
    // no option dont bother
    if (isNil(option)) {
      return;
    }

    // EDGE CASE: if the same diagnosis exists force the user to just use it
    // based on mapping get the appropriate diagnosis
    if (this.checkIfAutofillDiagnosisExists(record, side, option)) {
      return this.ValueAutofillService.shouldShowAutofillWarnings()
        ? this.toastr.info(
            `Autofilled diagnosis ${option.key} already exists, will not create a new row.`
          )
        : null;
    }

    // fetch diagnosis array
    const diagnosisArrayKey = `${this.DIAGNOSIS_ARRAY_KEY}.${side}`;

    const oldDiagnosisArray: GlDiagnosis[] = get(record, diagnosisArrayKey);

    // find enhanced diagnosis information
    // add value to temp, these will mostly be lvl 1 diagnosis
    // new diagnosis should always replace the top most one, default to level 1 if not found
    const foundDiagnosis = this.DiagnosisService.getDiagnosisAutofillMapping(
      option.key
    ) ?? {
      level1: option,
    };

    // new diagnosis array
    let newDiagnosisArray: GlDiagnosis[] = [cloneDeep(foundDiagnosis)];

    // GENERATE KEYS
    // same encoding to make it consistent
    // observation should use IGlOption
    const internalObservationKey: string = this.generateAutofillReferenceKey({
      parent_key,
      option_key: option.key,
      side,
      timestamp_key,
    });

    // use diagnosis option up to level2 as a reference
    const internalDiagnosisKey: string = this.generateAutofillReferenceKey({
      section: this.DIAGNOSIS_ARRAY_KEY,
      option_key:
        this.DiagnosisService.convertDiagnosisOptionToKey(foundDiagnosis),
      side,
    });

    // diagnosis save
    this.updateChangesStack(internalDiagnosisKey, oldDiagnosisArray);

    // if current diagnosis array isnt empty concat with new entry on top
    if (oldDiagnosisArray && oldDiagnosisArray?.length > 0) {
      // SECOND EDGE CASE
      // if side we are autofilling to doesnt allow multi-select,
      // disregard and change
      if (this.checkIfDiagnosisSideAllowsMultiSelect(record, side)) {
        newDiagnosisArray = oldDiagnosisArray.concat(newDiagnosisArray);
      }
    }

    // set diagnosis array
    set(record, diagnosisArrayKey, cloneDeep(newDiagnosisArray));

    // autofill
    // this links observation to autofill and autofill to observation
    this.ValueAutofillService.setAutofillValueBidirectionalKeyAndSide(
      internalDiagnosisKey,
      internalObservationKey
    );

    // alert user
    this.toastr.info(
      `The selected observation ${option.name} has been autofilled in the Management Section!`
    );
  }

  // autofill as prefil
  autofillDiagnosisSideAsPrefill({
    record,
    side,
    parent_key,
    timestamp_key,
    option,
  }: {
    record: PatientRecordData;
    side: IGlSideBilateral;
    parent_key: string;
    timestamp_key: number;
    option: GlDiagnosisOption;
  }) {
    // no option dont bother
    if (isNil(option)) {
      return;
    }

    // get index of found diagnosis
    const diagnosisIndex: number = this.getIndexOfDiagnosisByOption(
      record.management.diagnosis_array,
      side,
      option
    );
    // dont bother if not there
    if (diagnosisIndex === -1) {
      return;
    }

    // find enhanced diagnosis information
    // add value to temp, these will mostly be lvl 1 diagnosis
    // new diagnosis should always replace the top most one, default to level 1 if not found
    const foundDiagnosis = this.DiagnosisService.getDiagnosisAutofillMapping(
      option.key
    ) ?? {
      level1: option,
    };

    // GENERATE KEYS
    // same encoding to make it consistent
    // observation should use IGlOption
    const internalObservationKey: string = this.generateAutofillReferenceKey({
      parent_key,
      option_key: option.key,
      side,
      timestamp_key,
    });

    // NOTE: we dont need to save previous state for prefills
    // use diagnosis option up to level2 as a reference
    const internalDiagnosisKey: string = this.generateAutofillReferenceKey({
      section: this.DIAGNOSIS_ARRAY_KEY,
      option_key:
        this.DiagnosisService.convertDiagnosisOptionToKey(foundDiagnosis),
      side,
    });

    // autofill
    // this links observation to autofill and autofill to observation
    this.ValueAutofillService.setAutofillValueBidirectionalKeyAndSide(
      internalDiagnosisKey,
      internalObservationKey
    );

    // alert user
    this.toastr.info(`${option.name} has been prefilled!`);
  }

  /*
    undo diagnosis: both will remove autofill
    one reverts everything, the other reverts the field only
  */
  // if clicked from the middle section
  undoAutofillFromObservationSide({
    record,
    parent_key,
    timestamp_key,
    side,
    option,
  }: {
    record: PatientRecordData;
    side: IGlSideBilateral;
    timestamp_key: number;
    parent_key: string;
    option: IGlOption;
  }) {
    // get internal key from autofill map
    // generate observation key
    const internalObservationKey: string = this.generateAutofillReferenceKey({
      parent_key,
      option_key: option.key,
      side,
      timestamp_key,
    });
    // its existence will dictate whether its been autofilled or not
    const internalDiagnosisKey: string =
      this.ValueAutofillService.getAutofillValue(internalObservationKey) ??
      null;

    // MIDDLE SECTION
    // handle edge cases with specific keys
    if (parent_key === "lens") {
      // some of them have multiple
      this.applyPreviousStackChangeToObservationLensField({
        record,
        parent_key,
        side,
        option,
      });
    } else if (isArray(record[parent_key][side])) {
      // observation
      // then apply and fill that in custom
      this.applyPreviousStackChangeToObservationMultiField({
        record,
        parent_key,
        internalObservationKey,
        side,
      });
    } else {
      // otherwise its just regular with side
      this.applyPreviousStackChangeToRecord({
        record,
        key: `${parent_key}.${side}`,
      });

      // TO DO: handle other case
      // also handle if theres an other section as well
      // this.applyPreviousStackChangeToRecord(
      //   record,
      //   `${parent_key}_other.${side}`
      // );
    }

    // RIGHT SECTION
    // remove diagnosis
    this.removeDiagnosisFromManagement({
      record,
      diagnosisKey:
        this.DiagnosisService.findDiagnosisAutofillKey(internalDiagnosisKey),
      side,
      internalDiagnosisKey,
    });

    // remove autofill
    this.ValueAutofillService.removeAutofillValueForBothByKey(
      internalObservationKey
    );

    // notify
    this.toastr.success("Successfully undone autofill!");
  }

  // this undo is from right side
  // will revert only right xsection and leave middle alone
  undoAutofillForDiagnosisSideOnly({
    record,
    parent_key,
    side,
    diagnosisKey,
  }: {
    record: PatientRecordData;
    parent_key?: string;
    side: IGlSideBilateral;
    diagnosisKey?: string;
  }) {
    // its existence will dictate whether its been autofilled or not
    const internalDiagnosisKey: string = this.generateAutofillReferenceKey({
      section: this.DIAGNOSIS_ARRAY_KEY,
      option_key: diagnosisKey,
      side,
    });

    const internalObservationKey: string =
      this.ValueAutofillService.getAutofillValue(internalDiagnosisKey);

    if (isNil(parent_key) && !isNil(internalObservationKey)) {
      parent_key = internalObservationKey?.split(".")[1];
    }

    // MIDDLE SECTION
    // handle edge cases with specific keys
    if (parent_key === "lens") {
      // some of them have multiple
      const lensKeys: string[] = this.getLensAttributes();
      for (const key of lensKeys) {
        this.recordChangesStack.delete(`${parent_key}.${key}.${side}`);
      }
    } else {
      // otherwise its just regular with side
      this.recordChangesStack.delete(internalObservationKey);
      this.recordChangesStack.delete(internalDiagnosisKey);
    }

    // RIGHT SECTION
    this.removeDiagnosisFromManagement({
      record,
      diagnosisKey:
        this.DiagnosisService.findDiagnosisAutofillKey(internalDiagnosisKey),
      side,
      internalDiagnosisKey,
    });

    // AUTOFILL REMOVAL
    this.ValueAutofillService.removeAutofillValueForBothByKey(
      internalDiagnosisKey
    );

    // notify user
    this.toastr.success("Successfully undone autofill!");
  }

  // remove diagnosis by reference
  removeDiagnosisFromManagement({
    record,
    diagnosisKey,
    side,
    internalDiagnosisKey,
  }: {
    record: PatientRecordData;
    side: IGlSideBilateral;
    diagnosisKey: string;
    internalDiagnosisKey: string;
  }) {
    // if management for some reason isnt defined dont bother
    if (isNil(record?.management)) {
      return;
    }

    // find the value by key
    const foundAutofillDiagnosisIndex: number = this.findAutofillDiagnosisIndex(
      {
        record,
        side,
        diagnosisKey,
      }
    );

    // EDGE CASE
    // if there is one left do not remove but replace with default diagnosis
    if (
      isNil(record?.management?.diagnosis_array?.[side]) ||
      record.management.diagnosis_array[side].length <= 1
    ) {
      // apply default diagnosis
      // all should just be healthy
      record.management.diagnosis_array[side] = [
        cloneDeep(this.DiagnosisService.getDefaultDiagnosis("All")),
      ];
    }
    // if found remove else throw error and tell user to do manually
    else if (foundAutofillDiagnosisIndex >= 0) {
      record.management.diagnosis_array[side].splice(
        foundAutofillDiagnosisIndex,
        1
      );
    }

    // clean up stack
    this.deletePreviousStackChange(internalDiagnosisKey);
  }

  // confirm autofill
  confirmAutofillDiagnosisFromObservation({
    option,
    parent_key,
    side,
    timestamp_key,
  }: {
    option: IGlOption;
    parent_key: string;
    timestamp_key: number;
    side: IGlSideBilateral;
  }) {
    // GENERATE KEYS
    // same encoding to make it consistent
    // observation should use IGlOption
    const internalObservationKey: string = this.generateAutofillReferenceKey({
      parent_key,
      option_key: option.key,
      side,
      timestamp_key,
    });

    const internalDiagnosisKey: string =
      this.ValueAutofillService.getAutofillValue(internalObservationKey);

    // then confirm
    this._confirmAutofillDiagnosis({
      internalDiagnosisKey,
      internalObservationKey,
      parent_key,
      side,
    });
  }

  confirmAutofillDiagnosisFromDiagnosis({
    diagnosis,
    side,
    toastrEnabled = true, // if we need to surpress the call
  }: {
    diagnosis: GlDiagnosis;
    side: IGlSideBilateral;
    toastrEnabled?: boolean;
  }) {
    // use diagnosis option up to level2 as a reference
    const internalDiagnosisKey: string = this.generateAutofillReferenceKey({
      section: this.DIAGNOSIS_ARRAY_KEY,
      option_key: this.DiagnosisService.convertDiagnosisOptionToKey(diagnosis),
      side,
    });

    const internalObservationKey: string =
      this.ValueAutofillService.getAutofillValue(internalDiagnosisKey);

    // then confirm
    // if it turns out there isnt a key (i.e. false positive then ignore)
    // cleanup will occur when adding a different diagnosis
    if (!isNil(internalObservationKey)) {
      this._confirmAutofillDiagnosis({
        internalDiagnosisKey,
        internalObservationKey,
        parent_key: internalObservationKey.split(".")[0],
        side,
        toastrEnabled,
      });
    }
  }

  // confirms all autofill diagnoses for a given side
  confirmAutofillDiagnosesForSide(
    recordData: PatientRecordData,
    side: IGlSideBilateral
  ) {
    const diagnoses: GlDiagnosis[] =
      recordData?.management?.diagnosis_array?.[side] ?? [];

    for (const diagnosis of diagnoses) {
      // mock confirming from diagnosis side to "clear"
      this.confirmAutofillDiagnosisFromDiagnosis({
        diagnosis,
        side,
        toastrEnabled: false,
      });
    }
  }

  // for events like saving or signing,
  // this will set the state of all autofills by diagnosis and clear the
  // state of the autofill mappings
  confirmAllAutofillDiagnoses(recordData: PatientRecordData) {
    this.confirmAutofillDiagnosesForSide(recordData, "left");
    this.confirmAutofillDiagnosesForSide(recordData, "right");
  }

  // get index of diagnosis value by key
  findAutofillDiagnosisIndex({
    record,
    side,
    diagnosisKey,
  }: {
    record: PatientRecordData;
    side: IGlSideBilateral;
    diagnosisKey;
  }) {
    // the value is found only if the diagnosis key matches and it is
    // confirmed to be an autofill value active
    return (
      record?.management?.diagnosis_array?.[side]?.findIndex((d) => {
        const foundAutofillDiagnosis: GlDiagnosis =
          this.DiagnosisService.getDiagnosisAutofillMapping(diagnosisKey);
        return (
          [d?.level1?.key, d?.level2?.key].includes(diagnosisKey) &&
          !isNil(foundAutofillDiagnosis)
        );
      }) ?? -1
    );
  }

  /// generate internal key format
  generateAutofillReferenceKey(params: IValueAutofillKeyParams) {
    return this.ValueAutofillService.generateAutofillReferenceKey(params);
  }

  // RECORD HELPERS
  create(
    patientId: number,
    {
      type = "patient_record",
      workflowState,
      appointment_id,
      provider_id,
    }: {
      type?: GlPatientRecordType;
      workflowState?: GlPatientRecordWorkflowState;
      provider_id?: number;
      appointment_id?: number;
    } = { type: "patient_record" }
  ) {
    const params = {
      ...(workflowState && { workflow_state: workflowState }),
      ...(appointment_id && { appointment_id }),
      ...(provider_id && { provider_id }),
      type,
    };

    return this.$http
      .post<PatientRecord>(this.getRecordUrl({ patientId }), params)
      .then((response) => response.data);
  }

  createInjection(
    patientId: number,
    params: { record: Partial<IGlInjectionRecord>; appointment_id?: number }
  ) {
    const recordClone = copy(params.record);
    recordClone.data = cleanDeep(params.record?.data);

    const body = {
      ...recordClone,
      type: "procedure",
    };

    if (params.appointment_id) {
      body.appointment_id = params.appointment_id;
    }

    return this.$http
      .post<PatientRecord>(this.getRecordUrl({ patientId }), body)
      .then((response) => response.data);
  }

  createReferral(patientId: number) {
    return this.$http
      .post<PatientRecord>(this.getRecordUrl({ patientId }), {
        type: "referral",
        data: {
          version: 2,
        },
      })
      .then((response) => response.data);
  }

  get(patientId: number, recordId: number) {
    return this.$http
      .get<PatientRecord>(this.getRecordUrl({ patientId, recordId }))
      .then((response) => response.data)
      .then((record) => {
        const cleanedRecord = this.mapOldRecordToNew(record);
        if (!isObject(cleanedRecord.data)) {
          cleanedRecord.data = {};
        }
        this.mapDiagnosisToDiagnosisArray(record);
        return cleanedRecord;
      });
  }

  getForToday(patientId: number, recordId: number) {
    return this.$http
      .get<PatientRecord>(this.getRecordUrl({ patientId, recordId }))
      .then((response) => response.data)
      .then((record) => {
        const cleanedRecord = this.mapOldRecordToNew(record);
        if (!isObject(cleanedRecord.data)) {
          cleanedRecord.data = {};
        }
        this.mapDiagnosisToDiagnosisArray(record);
        return cleanedRecord;
      });
  }

  // this function simply sets the version number on the record data to v2 by default.
  // it is used when editing a record and we want to mark the record data version number
  getRecordV2ForEditing(patientId: number, recordId: number) {
    return this.get(patientId, recordId).then((record) => {
      defaults(record.data, { version: 2 });
      return record;
    });
  }

  getRecordHistoryForUser(id: number) {
    return this.$http
      .get<IApiArrayResponse<PatientRecord>>(
        this.getRecordUrl({ patientId: id })
      )
      .then((response) => {
        const records = response.data.data
          .filter(this.endOfCareEpisodes, this)
          .map((r) => this.mapOldRecordToNew(r))
          .map(this.fixMaxIop, this)
          .map(this.mapDiagnosisToDiagnosisArray, this);
        return records;
      });
  }

  update(
    record: PatientRecord,
    sign: boolean = false,
    reopen: boolean = false
    // skipOphthalReview?: boolean
  ) {
    const recordClone = copy(record);
    recordClone.data = cleanDeep(record.data);
    let url = sign
      ? `${this.getRecordUrl({ record })}/sign`
      : this.getRecordUrl({ record });
    if (reopen) {
      url = `${this.getRecordUrl({ record })}/reopen`;
    }
    return this.$http
      .put<PatientRecord>(url, recordClone)
      .then((response) => response.data);
  }

  updateWorkflow(workflow_state: string, record: PatientRecord) {
    const url = this.getRecordUrl({ record });
    return this.$http
      .put<PatientRecord>(url, { status: record.status, workflow_state })
      .then((response) => response.data);
  }

  updateProvider(providerId: number, record: PatientRecord) {
    const url = this.getRecordUrl({ record });
    return this.$http
      .put<PatientRecord>(url, {
        provider_id: providerId,
      })
      .then((response) => response);
  }

  sign({
    record,
    skipOphthalReview,
    signature,
  }: {
    record: PatientRecord;
    skipOphthalReview?: boolean;
    signature?: string;
  }) {
    const recordClone = copy(record);
    recordClone.data = cleanDeep(record.data);
    const url = `${this.getRecordUrl({ record })}/sign`;
    return this.$http
      .put<PatientRecord>(url, {
        ...recordClone,
        ...{ signature },
        ...(skipOphthalReview && { "skip-ophthal": skipOphthalReview }),
      })
      .then((response) => {
        return response.data;
      });
  }

  updateAsPatient({
    record,
    sign = true,
    signature,
  }: {
    record: PatientRecord;
    sign?: boolean;
    signature?: string;
  }) {
    const recordClone = copy(record);
    recordClone.data = cleanDeep(record.data);
    const url = `${this.getRecordUrl({ record })}/${
      sign ? `patient-sign` : `patient-reopen`
    }`;

    return this.$http
      .put<PatientRecord>(url, {
        ...recordClone,
        ...{ signature },
      })
      .then((response) => {
        return response.data;
      });
  }

  referralUpdate(record: PatientReferral) {
    const referralStatus = copy(record?.referral_status);
    const url = `${this.getRecordUrl({ record })}/referral_status`;

    return this.$http
      .put<PatientReferral>(url, cleanDeep({ referral_status: referralStatus }))
      .then((response) => response.data);
  }

  reopen(record: PatientRecord) {
    return this.update(record, false, true);
  }

  reopenAsPatient(record: PatientRecord) {
    return this.updateAsPatient({
      record,
      sign: false,
    });
  }

  delete(record: PatientRecord) {
    return this.$http.delete(
      this.getRecordUrl({ record, patientId: record.user_id })
    );
  }

  updateAndSetPractitioner(record: PatientRecord, practitioner: User) {
    const recordPractitioner = record.data.practitioner;
    // const currentPractitioner = this.getRecordPractitionerDetails(practitioner);
    /**
     * The practitioner field in the record saves the most senior person to
     * update the record. ie: admin < tech < optom < ophthal. If the most senior
     * person to update the record is a tech, then this field will be a tech. If
     * an ophth then updates, the record will be updated with the ophthal
     * details.
     */

    record.data.practitioner = this.getMostSeniorPractitioner(
      recordPractitioner,
      practitioner
    );
    return this.update(record);
  }

  getWhatStringFromRecord(recordData: PatientRecordData) {
    const what: Record<string, boolean> =
      get(recordData, "management.what") || {};
    const whatToDo = Object.keys(what).filter(
      (whatKey) => what[whatKey] === true
    );
    return whatToDo.map((what) => {
      if (what === "iop_only") {
        return "IOP Only";
      } else if (["iop", "oct"].includes(what)) {
        return what.toUpperCase();
      } else {
        return capitalize(what);
      }
    });
  }

  getMaxIopForSide(
    side: IGlSide,
    recordHistory: PatientRecord[] = [],
    currentRecordData: PatientRecordData = {}
  ): any {
    const maxIopFromHistory = this.getMaxIop(recordHistory);
    const currentIop = +get(currentRecordData, `iop.${side}`);
    const currentMapIop = +get(currentRecordData, `max_iop.${side}`);
    return max([maxIopFromHistory[side], currentIop, currentMapIop]);
  }

  getMaxIop(recordHistory: PatientRecord[] = []) {
    const maxRecordedIop = this.getMaxRecordedIop(recordHistory);
    const previousManualMaxIop = this.getMostRecentManualMaxIop(recordHistory);
    // make sure these value are numbers and not strings
    return {
      left: max([maxRecordedIop.left, previousManualMaxIop.left]),
      right: max([maxRecordedIop.right, previousManualMaxIop.right]),
    };
  }

  getRecordUrl({
    patientId,
    record,
    recordId,
  }: {
    patientId?: number;
    record?: PatientRecord;
    recordId?: number;
  }) {
    if (record) {
      return `${this.baseUrl}/patients/${record.user_id}/records/${record.id}`;
    } else if (patientId && recordId) {
      return `${this.baseUrl}/patients/${patientId}/records/${recordId}`;
    } else {
      return `${this.baseUrl}/patients/${patientId}/records`;
    }
  }

  /**
   * This function is used to filter patient records and return the
   * end of care episodes (ie: the last record on a particular day)
   * @example
   * // const careEpisodes = <PatientRecord[]>records.filter(this.endOfCareEpisodes, this)
   * @param record PatientRecord
   * @param index
   * @param records
   */
  endOfCareEpisodes(
    record: PatientRecord,
    index: number,
    records: PatientRecord[]
  ) {
    if (index >= records.length - 1 || this.recordIsNewFormat(record)) {
      // always return the last record in the list
      return true;
    }
    const nextRecordDate = parseServerDate(records[index + 1].created_at);
    const recordDate = parseServerDate(record.created_at);
    return !recordDate.isSame(nextRecordDate, "day");
  }

  getPatientRecordsForDisplay(records: PatientRecord[]) {
    return records?.filter((r) => r.type !== "admin_update");
  }

  recordIsNewFormat(record: PatientRecord) {
    const { data } = record;
    return (data && data.version === 2) || record.type === "admin_update";
  }

  openPrintRecordWindow(
    patientId: number,
    recordId: number,
    printOnLoad: boolean = false
  ) {
    const url = this.$state.href(
      "print-record",
      { patientId, recordId, print: printOnLoad },
      { absolute: false }
    );
    this.$window.open(
      url,
      "record_printer",
      "width=740,height=700,menubar=no,location=no,resizable=yes,scrollbars=yes,status=no"
    );
  }

  /**
   * updates any record data based on new additions
   *
   * different to old data mapping as that is for legacy, to be taken as a process
   * @param oldRecord old data
   * @returns new formatted data
   */
  mapOldRecordToNew(oldRecord: PatientRecord): PatientRecord {
    // see if mapping is required first to convert legacy to new
    const record: PatientRecord = this._mapLegacyRecord(oldRecord);

    // V2 STUFF
    // convert data
    let newData: PatientRecordData = record.data;
    newData = this.convertMacOctToV2(newData);
    newData = this.convertMacularToV2(newData);

    // assign
    record.data = newData;
    return record;
  }

  cleanManagement(management: any, recordDate: Date | Moment) {
    if (management) {
      const {
        plans,
        comments: oldComments,
        diagnosis: oldDiagnosis,
      } = management;
      const comments = this.cleanComments(oldComments, recordDate);
      const diagnosis =
        this.DiagnosisService.mapOldDiagnosisToNew(oldDiagnosis);
      return Object.assign(
        omit(management, "plans", "diagnosis"),
        {
          comments,
          diagnosis,
        },
        last(plans)
      );
    }
  }

  cleanComments(comments: any, recordDate: Date | Moment) {
    if (isArray(comments)) {
      return comments.filter(
        (c) =>
          !!c.comment && parseServerDate(c.timestamp).isSame(recordDate, "day")
      );
    }
  }

  updateNextGonioDate(data: any) {
    const { gonio_date, repeat, ...newData } = data;
    const nextGonioDate = parseServerDate(gonio_date);
    if (repeat && repeat !== "not_required") {
      // for old record, we need to calc the next date for the gonio
      // work out what the gonio date is due
      const yearsToAdd = repeat === "five_years" ? 5 : 10;
      nextGonioDate.add(yearsToAdd, "years");

      newData.gonio_date = nextGonioDate.toISOString();
    }
    return newData;
  }

  // conversion of old MAC_OCT data if exisitng to new
  convertMacOctToV2(data: PatientRecordData): PatientRecordData {
    try {
      let newData: GlBilateral<IGlOption[]> = {};
      // if old data exists and new data doesnt
      if (isNil(data?.oct_mac) && !isNil(data?.oct_mac_v2)) {
        // assign and clean
        data.oct_mac_v2 = this.cleanMultipleRowBilateralObservation(
          data.oct_mac_v2
        );
        return data;
      }

      // set data and only set others if existing
      const leftData: IGlOption = data?.oct_mac?.left;
      const rightData: IGlOption = data?.oct_mac?.right;
      if (!isNil(leftData) && !isNil(data?.oct_mac_other?.left)) {
        leftData.other = data?.oct_mac_other?.left;
      }
      if (!isNil(rightData) && !isNil(data?.oct_mac_other?.right)) {
        rightData.other = data?.oct_mac_other?.right;
      }

      // add
      // otherwise if both exist in the same record merge them both
      // with original data first
      if (!isNil(data?.oct_mac) && !isNil(data?.oct_mac_v2)) {
        newData = {
          left: [leftData, ...(data?.oct_mac_v2?.left ?? [])],
          right: [rightData, ...(data?.oct_mac_v2?.right ?? [])],
        };
      } else if (!isNil(data?.oct_mac)) {
        // else convert regularly if oct_mac is defined
        newData = {
          left: [leftData],
          right: [rightData],
        };
      }

      // assign and clean
      data.oct_mac_v2 = this.cleanMultipleRowBilateralObservation(newData);
      // add another step to clean and remove anything that is WNL outside row 1
      return data;
    } catch (error) {
      // console.error('oct_mac_v2_convert err', error)
      return data;
    }
  }

  // convert macular to new version
  convertMacularToV2(data: PatientRecordData): PatientRecordData {
    try {
      let newData: GlBilateral<IGlOption[]> = {};
      // if old data exists and new data doesnt
      if (isNil(data?.macular) && !isNil(data?.macular_v2)) {
        // clean
        data.macular_v2 = this.cleanMultipleRowBilateralObservation(
          data.macular_v2
        );
        return data;
      }

      // set data and only set others if existing
      const leftData: IGlOption = data?.macular?.left;
      const rightData: IGlOption = data?.macular?.right;
      if (!isNil(leftData) && !isNil(data?.macular_other?.left)) {
        leftData.other = data?.macular_other?.left;
      }
      if (!isNil(rightData) && !isNil(data?.macular_other?.right)) {
        rightData.other = data?.macular_other?.right;
      }

      // other cases
      // otherwise if both exist in the same record merge them both
      // with original data first
      if (!isNil(data?.macular) && !isNil(data?.macular_v2)) {
        newData = {
          left: [leftData, ...(data?.macular_v2?.left ?? [])],
          right: [rightData, ...(data?.macular_v2?.right ?? [])],
        };
      } else if (!isNil(data?.macular)) {
        // else convert regularly
        newData = {
          left: [leftData],
          right: [rightData],
        };
      }

      // assign
      data.macular_v2 = this.cleanMultipleRowBilateralObservation(newData);
      return data;
    } catch (error) {
      // console.error("macular_v2_convert err", error);
      return data;
    }
  }

  // for one row side
  cleanMultipleObservationRows(arraySide: IGlOption[]) {
    // 1. clean first
    const filtered: IGlOption[] = filter(arraySide ?? [], (option, index) => {
      // accept first value always, clear everything that is WNL
      return index === 0 && arraySide.length <= 1
        ? true
        : !isEmpty(option) &&
            !["WNL", "Not Examined", "None"].includes(option.name);
    });

    // 2. edge case if theres more after but first is WNL or not examined (after checking all)
    // return anything but the first option
    // we ignore the length 0 - 1 options as a regular WNL observation is ok
    if (
      filtered.length > 1 &&
      ["WNL", "Not Examined", "None"].includes(filtered[0].name)
    ) {
      return filtered.slice(1);
    }

    // otherwise go as usual, if no length just treat as null
    return filtered.length ? filtered : null;
  }

  // bilateral multiple observations only
  cleanMultipleRowBilateralObservation(observation: GlBilateral<IGlOption[]>) {
    return cloneDeep({
      left: this.cleanMultipleObservationRows(observation?.left),
      right: this.cleanMultipleObservationRows(observation?.right),
    });
  }

  /**
   * this function selects only documents that were created in the parent record.
   * It does this by comparing the record date and only returns documents that were
   * created on the same day
   * @param documents v1 documents object
   * @param recordDate the date of the parent record
   */
  cleanDocuments(documents: any, recordDate: Date | Moment) {
    const keys = Object.keys(documents);
    const docs = keys.reduce((newDocs: any[], k: string) => {
      const { left: leftDoc, right: rightDoc } = documents[k];
      if (leftDoc && this.checkDocDate(leftDoc, recordDate)) {
        newDocs.push(leftDoc);
      }
      if (rightDoc && this.checkDocDate(rightDoc, recordDate)) {
        newDocs.push(rightDoc);
      }
      return newDocs;
    }, []);
    return docs;
  }

  checkDocDate(document: any, date: Date | Moment) {
    const { created_at } = document;
    const createAtDate = parseServerDate(created_at);
    return createAtDate.isSame(date, "day");
  }

  cleanLensFields(record: any) {
    if (!record?.lens?.status || !record.lens.observations) {
      return record;
    }
    const {
      lens: { status, observations },
    } = record;

    const newRecord = omit(record, "lens");

    const newObservations = Object.keys(status).reduce(
      (obs: any, side: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const lensStatus = status[side];
        const observationsSide = observations[side];

        obs[side] = observationsSide.map((o) =>
          this.cleanObservation(status, o)
        );
        return obs;
      },
      {}
    );

    newRecord.lens = {
      status,
      observations: newObservations,
    };

    return newRecord;
  }

  cleanObservation(lensStatus: any, observation: any) {
    // this allows this component to work with both v1 legacy observations
    // and v2 new observations.
    if (!observation?.cataractType || !observation.IOLType) {
      // this is a v2 observation
      return observation;
    }
    const observationType = this.lensStatusIsCataract(lensStatus)
      ? observation.cataractType
      : observation.IOLType;
    return { type: observationType };
  }

  lensStatusIsCataract(lensType: any) {
    return lensType.key === "cataract";
  }

  lensStatusIsIol(lensType: any) {
    return includes(["ACIOL", "PCIOL", "sulcusIOL"], lensType.key);
  }

  cleanOphthalmicHistory(record: any) {
    const key = "ophthalmic_history.other";
    const newKey = "lens_notes";
    const lensNotes = get(record, key);
    if (!lensNotes) {
      return record;
    }
    const newRecord = omit(record, key);
    newRecord[newKey] = lensNotes;
    return newRecord;
  }

  whoIsReferrer(gp: User, optometrist: User, referrer: User) {
    if (referrer && optometrist && referrer.id === optometrist.id) {
      return "optometrist";
    } else if (referrer && gp && referrer.id === gp.id) {
      return "gp";
    }
  }

  cleanProvider(oldProvider: any) {
    if (
      !oldProvider ||
      oldProvider.firstName ||
      oldProvider.lastName ||
      oldProvider.fax
    ) {
      // oldProvider is in the correct format
      return oldProvider;
    }
    const {
      data: { fax, clinic: clinicDetails },
      name = "",
    } = oldProvider;
    let clinicName;
    if (clinicDetails?.name) {
      clinicName = clinicDetails.name;
    }
    const nameParts = name.split(/\s+/g);
    const firstName = first(nameParts);
    nameParts.shift();
    const lastName = nameParts.join(" ");

    return {
      firstName,
      lastName,
      fax,
      clinicName,
    };
  }

  cleanGlaucomaHistory(glaucoma_history: any) {
    if (!glaucoma_history) {
      return;
    }
    const { ocular_history, reason_for_referral } = glaucoma_history;
    const returnObj: any = { reason_for_referral };
    if (ocular_history) {
      returnObj.ocular_history = { has_other: true, other: ocular_history };
    }
    return returnObj;
  }

  getLensAttributes() {
    return GL_LENS_ATTRIBUTES;
  }

  // observations
  checkIfMultipleObservationField(key: string) {
    return MULTIPLE_SELECTION_OBSERVATION_KEYS.includes(key);
  }

  // get linked record
  getLinkedRecord(
    patientId: number,
    recordId: number,
    linked_record_type: GlPatientRecordType
  ): IPromise<PatientRecord> {
    const url = `${this.getRecordUrl({
      patientId,
      recordId,
    })}/linked-record`;
    return this.$http
      .get<PatientRecord>(url, {
        params: {
          linked_record_type,
        },
      })
      .then((response) => response.data);
  }

  // create linked record
  createLinkedRecord(
    record: PatientRecord,
    linked_record_type: GlPatientRecordType
  ): IPromise<PatientRecord> {
    const recordClone = copy(record);
    recordClone.data = cleanDeep(record.data);
    const url = `${this.getRecordUrl({ record })}/linked-record`;

    return this.$http
      .post<PatientRecord>(url, {
        ...recordClone,
        linked_record_type,
      })
      .then((response) => response.data);
  }

  private _mapLegacyRecord(oldRecord: any): PatientRecord {
    // this record has no data - so no need to update it
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data, created_at } = oldRecord;
    if (!data?.meta || this.recordIsNewFormat(oldRecord)) {
      return oldRecord;
    }
    const newRecord = omit(oldRecord, "data");
    try {
      const createdAtDate = parseServerDate(created_at);

      const visitType: string | undefined =
        data?.meta?.current_state?.type?.key;
      /**
       * clinical_data, ophthalmic_data, documents, management.plans all need to
       * be cleaned up from the old record format
       */
      const {
        clinical_data,
        ophthalmic_data,
        procedure_technician_conduct,
        patient_procedure_ophthalmologist_review,
        optom_review_data,
        tech_review_data,
        documents,
        management,
        gp,
        optometrist,
        referrer: referrerDetails,
        glaucoma_history,
      } = data;

      const newDocuments = this.cleanDocuments(documents, createdAtDate);
      const newManagement = this.cleanManagement(management, createdAtDate);
      const newGp = this.cleanProvider(gp);
      const newOptom = this.cleanProvider(optometrist);
      const referrer = this.whoIsReferrer(gp, optometrist, referrerDetails);
      // only get the referrer details if referrer is false (ie: the managing optom/go/optom is not the referrer)
      const newReferrerDetails = !referrer
        ? this.cleanProvider(referrerDetails)
        : null;
      const newGlaucomaHistory = this.cleanGlaucomaHistory(glaucoma_history);

      // get the record without the particular models to include
      let newData = omit(data, [
        "clinical_data",
        "ophthalmic_data",
        "procedure_technician_conduct",
        "patient_procedure_ophthalmologist_review",
        "optom_review_data",
        "tech_review_data",
        "documents",
        "management",
        "gp",
        "optometrist",
        "referrer",
        "workflow_state_resets",
        "glaucoma_history",
      ]);
      // now merge in the right bits
      Object.assign(
        newData,
        {
          management: newManagement,
          providers: {
            gp: newGp,
            optometrist: newOptom,
            referrer,
            referrerDetails: newReferrerDetails,
          },
        },
        newGlaucomaHistory
      );

      switch (visitType) {
        case "new_patient_technician_initial":
        case "new_patient_technician_undilated_documents":
        case "new_patient_technician_dilated_documents":
        case "new_patient_ophthalmologist_undilated":
        case "new_patient_ophthalmologist_dilated":
        case "new_patient_ophthalmologist_management_plan":
          Object.assign(newData, clinical_data, ophthalmic_data);
          break;
        case "patient_review_optometrist":
          Object.assign(newData, optom_review_data);
          break;
        case "patient_virtual_review_ophthalmologist_management_plan":
        case "patient_review_ophthalmologist_virtual_review":
          break;
        case "patient_review_ophthalmologist_technician_review":
        case "patient_review_ophthalmologist":
        case "patient_review_complete":
        case "patient_review_ophthalmologist_management_plan":
          Object.assign(
            newData,
            clinical_data,
            tech_review_data,
            ophthalmic_data
          );
          break;

        case "patient_procedure_technician_new":
        case "patient_procedure_technician_review":
        case "patient_procedure_ophthalmologist_conducting":
        case "patient_procedure_ophthalmologist_review":
        case "patient_procedure_ophthalmologist_management_plan":
          Object.assign(
            newData,
            procedure_technician_conduct,
            patient_procedure_ophthalmologist_review
          );
          break;
        default:
          break;
      }
      newData = this.cleanLensFields(newData);
      // clean next gonio date...
      newData = this.updateNextGonioDate(newData);

      return {
        ...newRecord,
        data: newData,
        documents: newDocuments,
      } as PatientRecord;
    } catch (error) {
      // console.error("Failed mapping old data to v2 format.", oldRecord);
      return { ...newRecord, data: {} } as PatientRecord;
    }
  }

  private getRecordPractitionerDetails(
    practitioner: User
  ): GlPatientRecordPractitioner {
    const clinic = pick(practitioner.clinic, ["id", "name"]);
    const user = pick(practitioner, [
      "id",
      "name",
      "clinic_id",
      "type",
      "type_id",
    ]);
    return { ...user, clinic };
  }

  private getMostSeniorPractitioner(
    prac1: GlPatientRecordPractitioner,
    prac2: User
  ): GlPatientRecordPractitioner {
    if (
      !prac1 ||
      prac2.type.name === "ophthalmologist" ||
      (prac2.type.name === "optometrist" &&
        ["administrator", "technician", "optometrist"].includes(
          prac1.type.name
        )) ||
      (prac2.type.name === "technician" &&
        ["administrator", "technician"].includes(prac1.type.name)) ||
      (prac2.type.name === "administrator" &&
        ["administrator"].includes(prac1.type.name))
    ) {
      return this.getRecordPractitionerDetails(prac2);
    } else {
      return prac1;
    }
  }

  /**
   * This function is used to review all IOP & Max IOP Values and fix them. each
   * record is checked to see if the max_iop is correct. It is correct if it is
   * greater than all the previously recorded IOP values. If it is, then max_iop
   * is left as the current max_iop that has been manually entered.
   *
   * If not, we delete max_iop as it can be calculated based on the maximum of
   * the previous iop recorded values
   */
  private fixMaxIop(
    record: PatientRecord,
    index: number,
    records: PatientRecord[]
  ) {
    if (!record.data) {
      return record;
    }
    const { iop, max_iop } = record.data;

    // there are some occasions when max_iop exists but iop doesn't. In this
    // case, remove max_iop
    if (!iop && max_iop) {
      delete record.data.max_iop;
    } else if (iop && max_iop) {
      // if this record has either an IOP or a max IOP then we need to fix it
      // up. Only keep the Max IOP is it is manually set to a number higher
      // than any previous iop. Otherwise it can be calculated automatically.
      // The first step is to work out what the max IOP up to this visit is.
      const maxIopUpToThisVisit = this.getMaxRecordedIop(
        records.slice(0, index + 1)
      );
      // get the most recent max_iop value
      const previousMaxIop = this.getMostRecentManualMaxIop(
        records.slice(0, index)
      );

      if (
        max([maxIopUpToThisVisit.left, previousMaxIop.left]) >= +max_iop.left
      ) {
        delete max_iop.left;
      }
      if (
        max([maxIopUpToThisVisit.right, previousMaxIop.right]) >= +max_iop.right
      ) {
        delete max_iop.right;
      }
      if (!max_iop.left && !max_iop.right) {
        // if both are undefined, then delete the entire max_iop field
        delete record.data.max_iop;
      }
    }

    return record;
  }

  private getMaxRecordedIop(recordHistory: PatientRecord[]) {
    return recordHistory.reduce(
      (maxIop: GlBilateral<number>, record) => {
        if (!record.data) {
          return maxIop;
        }
        const { iop } = record.data;
        const maxLeft = [+maxIop.left];
        const maxRight = [+maxIop.right];
        if (iop) {
          // note the + converts strings to numbers. Older records store
          // max_iop as a string not a number
          maxLeft.push(+iop.left);
          maxRight.push(+iop.right);
        }

        maxIop.left = max(maxLeft);
        maxIop.right = max(maxRight);

        return maxIop;
      },
      { left: 0, right: 0 }
    );
  }

  private getMostRecentManualMaxIop(recordHistory: PatientRecord[]) {
    const previousLeftMaxIop = findLast(
      recordHistory,
      (r) => r.data && !!r.data.max_iop && !!r.data.max_iop.left
    );
    const previousRightMaxIop = findLast(
      recordHistory,
      (r) => r.data && !!r.data.max_iop && !!r.data.max_iop.right
    );

    return {
      left: +get(previousLeftMaxIop, "data.max_iop.left", 0),
      right: +get(previousRightMaxIop, "data.max_iop.right", 0),
    };
  }

  private mapDiagnosisToDiagnosisArray(record: PatientRecord) {
    const diagnosis = record.data?.management?.diagnosis;
    if (diagnosis) {
      if (diagnosis.left || diagnosis.right) {
        record.data.management.diagnosis_array = {};
      }
      if (diagnosis.left) {
        record.data.management.diagnosis_array.left = [diagnosis.left];
      }
      if (diagnosis.right) {
        record.data.management.diagnosis_array.right = [diagnosis.right];
      }
      delete record.data.management.diagnosis;
    }
    if (record.virtual_review) {
      this.mapDiagnosisToDiagnosisArray(record.virtual_review);
    }
    return record;
  }

  private _confirmAutofillDiagnosis({
    internalDiagnosisKey,
    internalObservationKey,
    parent_key,
    side,
    toastrEnabled = true,
  }: {
    internalDiagnosisKey: string;
    internalObservationKey: string;
    parent_key: string;
    side: IGlSideBilateral;
    toastrEnabled?: boolean;
  }) {
    // remove stack for both
    // observation
    if (parent_key === "lens") {
      const lensKeys: string[] = this.getLensAttributes();
      for (const key of lensKeys) {
        this.recordChangesStack.delete(`${parent_key}.${key}.${side}`);
      }
    } else {
      this.recordChangesStack.delete(internalObservationKey);
    }

    // diagnosis
    this.deletePreviousStackChange(internalDiagnosisKey);

    // remove autofill for both
    this.ValueAutofillService.removeAutofillValueForBothByKey(
      internalObservationKey
    );

    // REMOVE FROM STACK
    toastrEnabled && this.toastr.success("Autofill confirmed!");
  }

  /**
   *  v1 "models"
   * procedure_technician_conduct
   * patient_procedure_ophthalmologist_review
   * ophthalmic_data
   * optom_review_data = patient_review_optometrist
   * tech_review_data
   * clinical_data
   * ophthalmic_data
   **/

  /**
   * Workflow states
   *
   * new_patient_technician_initial
   * new_patient_technician_undilated_documents
   * new_patient_technician_dilated_documents
   * new_patient_ophthalmologist_undilated
   * new_patient_ophthalmologist_dilated
   * new_patient_ophthalmologist_management_plan
   * patient_review_optometrist
   * patient_review_ophthalmologist_virtual_review
   * patient_review_ophthalmologist_technician_review
   * patient_review_ophthalmologist
   * patient_review_complete
   * patient_review_ophthalmologist_management_plan
   * patient_virtual_review_ophthalmologist_management_plan
   * patient_procedure_technician_new
   * patient_procedure_technician_review
   * patient_procedure_ophthalmologist_conducting
   * patient_procedure_ophthalmologist_review
   * patient_procedure_ophthalmologist_management_plan
   **/
}
