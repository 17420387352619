import { DocumentWindowService } from "app/core/services/document-window.service";

class ButtonUploadsController implements angular.IController {
  // @Input
  patientId: number;
  recordId: number;
  klass = "btn-primary";

  constructor(private DocumentWindowService: DocumentWindowService) {
    "ngInject";
  }

  openDocumentViewer() {
    this.DocumentWindowService.openDocumentWindows(
      this.patientId,
      this.recordId
    );
  }
}

export class ButtonUploadsComponent implements angular.IComponentOptions {
  static selector = "glButtonUploads";
  static controller = ButtonUploadsController;
  static bindings = {
    patientId: "<",
    recordId: "<",
    klass: "@?class",
  };
  static template = `
    <button
        type="button"
        ng-click="$ctrl.openDocumentViewer()"
        class="btn"
        ng-class="$ctrl.klass"
        >
        <i class="fa fa-expand m-r-xs" aria-hidden="true"></i> Uploads
        </button>
    `;
}
