/**
 * Import the Component styles
 */
import { User, UserAuxiliaryClinic } from "../../../models/user.model";
import { AuthService } from "../../core/services/auth.service";
import { ClinicService } from "../../core/services/clinic.service";
import "./select-clinic.scss";

class SelectClinicPageController
  implements angular.IController, angular.IOnInit
{
  public user: User;
  public selectedAuxiliaryClinic: UserAuxiliaryClinic;
  public updateClinicInProgress = false;

  constructor(
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService,
    private $state: angular.ui.IStateService,
    public ClinicService: ClinicService
  ) {
    "ngInject";
  }

  $onInit() {
    if (
      !this.user.auxiliary_clinics ||
      this.user.auxiliary_clinics.length === 0
    ) {
      this.gotoDashboard();
    }
  }

  selectedClinicDidChange() {
    this.updateClinicInProgress = true;
    this.AuthService.setClinic(this.user, this.selectedAuxiliaryClinic)
      .then(() => {
        this.gotoDashboard();
      })
      .catch((err) => {
        const message = err.data?.message ? err.data.message : err.message;
        this.toastr.error(message, "Error");
      })
      .finally(() => (this.updateClinicInProgress = false));
  }

  gotoDashboard() {
    /**
     * set reload: true so that the user which is injected by ui-router is
     * refreshed. If not, stale user info is passed into new pages. See
     * https://stackoverflow.com/a/23609343/251352
     */
    this.$state.go("main.dashboard", undefined, { reload: true });
  }
}

export class SelectClinicPage implements angular.IComponentOptions {
  static selector = "selectClinic";
  static template = require("./select-clinic.html");
  static controller = SelectClinicPageController;
  static bindings = {
    user: "<",
  };
}
