import { PatientRecordData } from "../../../../../models/patient-record.model";
import { GlFormController } from "../../../../core/components/gl-form-controller";

class ManagementAdminCommentController extends GlFormController
  implements angular.IController {
  recordData: PatientRecordData;

  constructor() {
    "ngInject";
    super();
  }
}

export class ManagementAdminCommentComponent
  implements angular.IComponentOptions {
  static selector = "managementAdminComment";
  static template = require("./management-admin-comment.html");
  static controller = ManagementAdminCommentController;
  static bindings = {
    mode: "@?",
    isEditable: "<?",
    recordData: "<",
  };
}
