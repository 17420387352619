import { isFunction } from "angular";
import { GlFormController } from "../../gl-form-controller";

import { every, isEmpty, isNil, set } from "lodash";
import {
  IPatientDocumentData,
  IPatientDocumentDicomData,
} from "models/patient-document.model";
import "./gl-dropzone.scss";

/* should work as an extension of generic document data
   this references as an extension such where we can store 
   not only the file to upload but include core 
   metadata as well
*/
export interface GlDropzoneFileWrapper {
  file: File;
  data: IPatientDocumentData;
  dicom_data?: IPatientDocumentDicomData;
}

export class GlDropzoneController
  extends GlFormController
  implements angular.IComponentController
{
  fileInputRef: JQLite;
  isEditable: boolean = true;

  private queuedFiles: GlDropzoneFileWrapper[] = [];
  private dropzoneText: string = "Drop file(s) here or click to upload";

  onEdit: (arg: { fileObj: GlDropzoneFileWrapper; index: number }) => void;
  onDelete: (...args: any[]) => void;

  constructor(
    private $transitions: any,
    private $scope: angular.IScope,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // add a hook to stop exiting if there are files that arent uplaoded yet
    this.$transitions.onExit({}, () => {
      // only if there are any queued files this will trigger
      if (this.queuedFiles?.length) {
        const confirm: boolean = window.confirm(
          `There are pending files waiting to be uploaded. Are you sure you want to leave and discard those files?`
        );

        // reset if confirm changes will be discarded
        if (confirm) {
          this.resetQueuedFiles();
        }
        return confirm;
      }
    });
  }

  $postLink(): void {
    // add event listener to assist with detecting
    // if files have been selected via on-click rather than
    // on drag
    this?.fileInputRef?.[0].addEventListener("change", (e: any) =>
      this.handleOnClickUpload(e)
    );
  }

  /* HELEPRS */
  getQueuedFiles() {
    return this.queuedFiles;
  }

  setQueuedFiles(newFiles: GlDropzoneFileWrapper[]) {
    this.queuedFiles = newFiles;
  }

  resetQueuedFiles() {
    this.queuedFiles = [];
  }

  // updates by file reference to ensure integrity
  updateQueuedFile(newFile: GlDropzoneFileWrapper) {
    // find file by index
    const indexToUpdate: number = this.getQueuedFileIndexByWrapper(newFile);
    // index splice update
    if (indexToUpdate !== -1) {
      // safer update without changing the file, only targetting data
      set(this.queuedFiles[indexToUpdate], "data", newFile?.data);
      set(
        this.queuedFiles[indexToUpdate].dicom_data,
        "dicom_data",
        newFile?.dicom_data
      );
    }
  }

  // deletes a file by index
  removeQueuedFile(index: number) {
    const confirm: boolean = window.confirm(
      "Are you sure you want to delete this file for uploading?"
    );

    if (confirm) {
      this._removeQueuedFile(index);
    }

    // callback
    if (isFunction(this.onDelete)) {
      this.onDelete();
    }
  }

  // by file reference
  removeQueuedFileByFileWrapper(fileObj: GlDropzoneFileWrapper) {
    const index: number = this.getQueuedFileIndexByWrapper(fileObj);

    if (index !== -1) {
      this._removeQueuedFile(index);
    }

    // callback
    if (isFunction(this.onDelete)) {
      this.onDelete();
    }
  }

  // clears all
  removeAllFiles() {
    const confirm: boolean = window.confirm(
      "Are you sure you want to delete all pending files to be uploaded?"
    );

    if (confirm) {
      this.resetQueuedFiles();
      this.toastr.success("Successfully removed all files!");

      // callback
      if (isFunction(this.onDelete)) {
        this.onDelete();
      }
    }
  }

  // type testing
  isPdf(file: File) {
    return file?.type?.includes("pdf");
  }

  isImage(file: File) {
    return file?.type?.includes("image");
  }

  // edit
  handleEditDocument(fileObj: GlDropzoneFileWrapper, index: number) {
    if (isFunction(this.onEdit)) {
      this.onEdit({ fileObj, index });
    }
  }

  /* GETTERS */
  // retreives index by file reference
  getQueuedFileIndexByFile(file: File) {
    return this.queuedFiles.findIndex((f) => {
      return (
        f?.file?.name === file?.name &&
        f?.file?.size === file?.size &&
        f?.file?.lastModified === file?.lastModified
      );
    });
  }

  // retrieves index by reference to file in wrapper
  getQueuedFileIndexByWrapper(fileObj: GlDropzoneFileWrapper) {
    return this.getQueuedFileIndexByFile(fileObj?.file);
  }

  // comma delimited tags
  getFileTagsAsArray(fileObj: GlDropzoneFileWrapper) {
    if (isNil(fileObj?.data?.tags) || isEmpty(fileObj?.data?.tags)) {
      return [];
    }

    return (fileObj?.data?.tags ?? "").split(",");
  }

  /* CHECKERS */
  canUpload() {
    // baseline is that all files must have a tag and a file
    return this.allFilesTagged();
  }

  allFilesTagged() {
    return every(this.queuedFiles ?? [], (fileWrapper: GlDropzoneFileWrapper) =>
      // file and tag must not be null
      every([fileWrapper.file, fileWrapper?.data?.tags], (p) => !isNil(p))
    );
  }

  /* HANDLERS */
  // on drop is just the same as a generic upload
  handleOnDropUpload(e: any) {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    let filesToAdd: File[];

    // setup array
    if (e?.dataTransfer?.items) {
      // Use DataTransferItemList interface to access the file(s)
      filesToAdd = [...e.dataTransfer.items]
        .filter((item) => item?.kind === "file")
        .map((item) => item.getAsFile());
    } else {
      // Use DataTransfer interface to access the file(s)
      filesToAdd = e?.dataTransfer?.files ? [...e.dataTransfer.files] : [];
    }

    this._addFileToQueue(filesToAdd);
  }

  // if we click the hidden file input instead
  handleOnClickUpload(e: any) {
    const files = [...(e.target?.files ?? [])];
    this._addFileToQueue(files);

    // after upload remove files from input elem
    const inputElem: any = this.fileInputRef?.[0];
    inputElem.value = null;

    // update scope
    this.$scope.$apply();
  }

  // disables handling for drag over
  handleOnDragOver(e: Event) {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
  }

  // generic add to file function
  private _addFileToQueue(filesToAdd: File[]) {
    // iterate again to check what to adda nd what to replace
    for (const file of filesToAdd ?? []) {
      // find potential dupes
      const foundDupeIndex: number = this.queuedFiles.findIndex((f) => {
        return (
          f?.file?.name === file.name &&
          f?.file?.size === file.size &&
          f?.file?.lastModified === file.lastModified
        );
      });

      // if dupe, replace by index
      // else add
      foundDupeIndex !== -1
        ? this.queuedFiles.splice(foundDupeIndex, 1, {
            file,
            data: null,
          })
        : this.queuedFiles.push({
            file,
            data: null,
          });
    }
  }

  // generic function
  private _removeQueuedFile(index: number) {
    if (index < 0 || index >= this.queuedFiles?.length) {
      return;
    }

    // splice
    this.queuedFiles.splice(index, 1);

    this.toastr.success("Successfully removed file to upload!");
  }
}

export class GlDropzone implements angular.IComponentOptions {
  static selector = "glDropzone";
  static template = require("./gl-dropzone.html");
  static controller = GlDropzoneController;
  static bindings = {
    dropzoneText: "@?",
    onEdit: "&",
    onDelete: "&?",
    isEditable: "<?",
  };

  static transclude = {
    customOptions: "?glDropzoneExtraButtons",
  };
}
