import { GlPrescriptionDrugData } from "models/prescription.model";

export const pipeName = "drugsHasAuthorityDrug";
export const drugsHasAuthorityDrugPipe: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (drugs: GlPrescriptionDrugData[]) => {
    if (!drugs) {
      return false;
    }
    return drugs.find(
      (drug: GlPrescriptionDrugData) => drug?.authority_script && !drug?.private
    );
  };
};
