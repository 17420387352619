import {
  IComponentController,
  IComponentOptions,
  IWindowService,
  isFunction,
} from "angular";
import { Appendix } from "app/core/services/appendix";
import { PrescriptionsService } from "app/core/services/prescriptions/prescriptions.service";
import { QzPrinterService } from "app/core/services/qz-printer/qz-printer";
import { map, omit } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import { IGlPrescriptionViewMode } from "models/gl-prescription-view-mode";
import { PatientRecord } from "models/patient-record.model";
import {
  GlPrescription,
  GlPrescriptionDrugData,
  GlPrescriptionDrugDataExtended,
  GlPrescriptionSelectionWrapper,
} from "models/prescription.model";
import { GlStaff, Patient, User } from "models/user.model";
import "./patient-prescriptions.scss";

class PatientPrescriptionsController implements IComponentController {
  mode: IGlFormMode = "display";
  record: PatientRecord;
  prescriptionToEdit: GlPrescription | object;
  prescriptions: GlPrescriptionSelectionWrapper[];
  selectedPrescriptions: GlPrescriptionSelectionWrapper[] = [];
  selectedPrescriptionDrugs: GlPrescriptionDrugDataExtended[] = []; // conver this back to drug data
  patient: Patient;
  prescriber: GlStaff;
  user: User;

  highlightedPrescriptionDrugIndex: number;
  currentPrescriptionActioned: GlPrescription | object;

  // print
  showPrintPrescriptions = true;
  show1ClickPrint = this.QzPrinterService.getQzTrayIsInstalled();

  // boolean
  createTreatmentInProgress: boolean = false;
  createPrescriptionInProgress: boolean = false;
  updatePrescriptionInProgress: boolean = false;

  rePrescribePrescriptionInProgress: boolean = false;
  rePrescribeAllPrescriptionsInProgress: boolean = false;

  printSelectedPrescriptionsPdfInProgress: boolean = false;
  printAllPrescriptionsPdfInProgress: boolean = false;

  printSelectedPrescriptionsInProgress: boolean = false;
  printAllPrescriptionsInProgress: boolean = false;

  // separate between drugs breakdown view and full prescriptions view
  prescriptionsViewMode: IGlPrescriptionViewMode = "drugs";
  // for future
  descendingTimeOrder: boolean = true;
  // for single prescriptions
  useRecordIdOnPrescribe: boolean = true;

  // print mode
  printMode: boolean = false;

  // updaters
  onPrescriptionsUpdate: (arg: { patientId: number }) => void;

  constructor(
    public appendix: Appendix,
    private $window: IWindowService,
    private toastr: angular.toastr.IToastrService,
    private PrescriptionsService: PrescriptionsService,
    public QzPrinterService: QzPrinterService
  ) {
    "ngInject";
  }

  // edit prescriptions
  isEditMode() {
    return this.mode === "edit";
  }

  // toggle view
  onViewModeToggle(viewMode: IGlPrescriptionViewMode) {
    // if not the same
    if (viewMode !== this.prescriptionsViewMode) {
      this.prescriptionsViewMode = viewMode;
      this.selectedPrescriptionDrugs = [];
      this.selectedPrescriptions = [];
      this.cancelEditPrescription();
    }
  }

  // prescription related
  handlePrescriptionsUpdate() {
    if (isFunction(this.onPrescriptionsUpdate)) {
      this.onPrescriptionsUpdate({ patientId: this.patient.id });
    }
  }

  checkCurrentPrintingPrescription() {
    return this.currentPrescriptionActioned;
  }

  // fetch
  getPrescriptions() {
    return (
      (this.descendingTimeOrder
        ? this._reversePrescriptions()
        : this.prescriptions) ?? []
    );
  }

  addPrescription() {
    this.prescriptionToEdit = {};
  }

  // for prescription view
  editPrescription(index: number) {
    // reverse part needed here only
    this.prescriptionToEdit =
      this.getPrescriptions()[
        // regular is using reversed index, else revert it
        this.descendingTimeOrder
          ? index
          : this._convertReverseIndexToRegular(index)
      ];
  }

  // for drugs view
  editPrescriptionByDrug(prescriptionIndex: number, drugIndex?: number) {
    // reverse part needed here only
    this.prescriptionToEdit =
      this.getPrescriptions()[
        // regular is using reversed index, else revert it
        this.descendingTimeOrder
          ? prescriptionIndex
          : this._convertReverseIndexToRegular(prescriptionIndex)
      ];
    this.highlightedPrescriptionDrugIndex = drugIndex;
  }

  // general cancel function
  cancelEditPrescription() {
    this.prescriptionToEdit = undefined;
    this.highlightedPrescriptionDrugIndex = undefined;
  }

  // create and cancel
  toggleCreatePrescription() {
    this.createPrescriptionInProgress = true;
  }

  toggleCancelPrescription() {
    this.createPrescriptionInProgress = false;
  }

  showMainButtons() {
    return (
      !this.createPrescriptionInProgress &&
      !this.prescriptionToEdit &&
      !this.printMode
    );
  }

  canPrescribe() {
    return this.PrescriptionsService.canPrescribe(this.prescriber);
  }

  // PRINTER
  canPrintPrescription() {
    return (
      this.showPrintPrescriptions && this.prescriber && this.canPrescribe()
    );
  }

  // TOGGLABLES
  toggleSelectionDrugsChecked(
    drug: GlPrescriptionDrugDataExtended,
    prescriptionId: number,
    state: boolean = false
  ) {
    // drugs checked needs to find prescription id first
    const prescriptionIndex: number = this.prescriptions.findIndex(
      (p) => p.id === prescriptionId
    );

    if (prescriptionId === -1) {
      return;
    }

    const foundDrugIndex: number = this.prescriptions[
      prescriptionIndex
    ].data.findIndex(
      (d) => d.id === drug.id || d.treatment_id === drug.treatment_id
    );

    if (foundDrugIndex !== -1) {
      this.prescriptions[prescriptionIndex].data[foundDrugIndex].selected =
        state;
    }
  }

  // FOR DRUGS VIEW
  toggleSelectionDrugs(drug: GlPrescriptionDrugData, prescriptionId: number) {
    const indexOfSelectedDrug: number =
      this.selectedPrescriptionDrugs.findIndex((d) => {
        return (
          (d.item_code === drug.item_code ||
            d.treatment_id === drug.treatment_id) &&
          d.prescription_id === prescriptionId
        );
      });

    if (indexOfSelectedDrug === -1) {
      this.toggleSelectionDrugsChecked(drug, prescriptionId, true);
      this.selectedPrescriptionDrugs.push({
        ...drug,
        prescription_id: prescriptionId,
      });
    } else {
      this.toggleSelectionDrugsChecked(drug, prescriptionId, false);
      this.selectedPrescriptionDrugs.splice(indexOfSelectedDrug, 1);
    }
  }

  clearSelectedDrugs() {
    // O(nm) but realistically its faster than looping all prescriptions and their drugs
    for (const selectedDrug of this.selectedPrescriptionDrugs) {
      const prescription = this.prescriptions.find(
        (p) => p.id === selectedDrug.prescription_id
      );
      if (prescription) {
        for (const drug of prescription.data) {
          drug.selected = false;
        }
      }
    }

    this.selectedPrescriptionDrugs = [];
  }

  toggleSelectionPrescriptionChecked(
    prescription: GlPrescription,
    state: boolean = false
  ) {
    const index: number = this.selectedPrescriptions.findIndex(
      (p) => p.id === prescription.id
    );
    if (index !== -1) {
      this.prescriptions[index].selected = state;
    }
  }

  // FOR PRESCRIPTIONS VIEW
  toggleSelectionPrescriptions(prescription: GlPrescription) {
    const indexOfSelectedPrescription = this.selectedPrescriptions.findIndex(
      (p) => p.id === prescription.id
    );

    if (indexOfSelectedPrescription === -1) {
      this.toggleSelectionPrescriptionChecked(prescription, true);
      this.selectedPrescriptions.push(prescription);
    } else {
      this.toggleSelectionPrescriptionChecked(prescription, false);
      this.selectedPrescriptions.splice(indexOfSelectedPrescription, 1);
    }
  }

  clearSelectedPrescriptions() {
    this.selectedPrescriptions = [];
    for (const prescription of this.prescriptions) {
      prescription.selected = false;
    }
  }

  // UPDATE PRESCRIPTION
  updatePrescription(prescription: GlPrescription) {
    this.updatePrescriptionInProgress = true;
    this.currentPrescriptionActioned = prescription;

    // ensure that the authority code is also changed if its an authority script with an authority drug
    if (
      prescription.data.length &&
      prescription.data[0].authority_script &&
      !prescription?.data[0]?.private
    ) {
      prescription.data[0].treatment_of_code = prescription.authority_number;
    }

    this.updatePrescriptionRecord(prescription)
      .then((response) => {
        this.toastr.success(
          `Successfully updated prescription id ${prescription.id}`
        );
        return response;
      })
      .catch((error) => {
        if (error) {
          this.toastr.error(
            "Failed to update prescription details. Please try again."
          );
        }
      })
      .finally(() => {
        this.updatePrescriptionInProgress = false;
        this.currentPrescriptionActioned = {};
        this.cancelEditPrescription();
        this.handlePrescriptionsUpdate();
      });
  }

  // FOR WHOLE PRESCRIPTIONS
  // RE-PRESCRIBE PRESCRIPTION
  rePrescribePrescription(prescription: GlPrescription) {
    this.rePrescribePrescriptionInProgress = true;
    this.currentPrescriptionActioned = prescription;

    this.rePrescribePrescriptionRecord(prescription)
      .then((response) => {
        if (!response) {
          return Promise.reject();
        }
        return response;
      })
      .then((prescription) => {
        this.toastr.success(
          `Successfully re-prescribed Prescription No${prescription.id}!`
        );
        this.printPrescriptionPdf(prescription);
      })
      .catch(() => {
        this.toastr.error(
          "Error re-prescribing prescription, please try again"
        );
      })
      .finally(() => {
        this.rePrescribePrescriptionInProgress = false;
        this.currentPrescriptionActioned = {};
        this.handlePrescriptionsUpdate();
      });
  }

  // for selected prescriptions
  rePrescribeSelectedPrescriptionsPdf() {
    this.rePrescribePrescriptionInProgress = true;
    Promise.all(
      map(this.selectedPrescriptions, (prescription: GlPrescription) =>
        this.rePrescribePrescriptionRecord(prescription)
          .then((response) => {
            if (!response) {
              return Promise.reject();
            }
            return response;
          })
          .then((prescriptionNew) => {
            this.toastr.success(
              `Successfully re-prescribed Prescription No${prescription.id} as No${prescriptionNew.id}!`
            );
            this.printPrescriptionPdf(prescriptionNew);
          })
          .catch(() => {
            this.toastr.error(
              "Error re-prescribing prescription, please try again"
            );
            return;
          })
      )
    )
      .then(() => {
        this.clearSelectedPrescriptions();
      })
      .finally(() => {
        this.rePrescribePrescriptionInProgress = false;
        this.handlePrescriptionsUpdate();
      });
  }

  rePrescribeAllPrescriptions() {
    this.rePrescribeAllPrescriptionsInProgress = true;
    Promise.all(
      map(this.prescriptions, (prescription: GlPrescription) =>
        this.rePrescribePrescriptionRecord(prescription)
          .then((response) => {
            if (!response) {
              return Promise.reject();
            }
            return response;
          })
          .then((prescriptionNew) => {
            this.toastr.success(
              `Successfully re-prescribed Prescription No${prescription.id} as No${prescriptionNew.id}!`
            );
            this.printPrescriptionPdf(prescriptionNew);
          })
          .catch(() => {
            this.toastr.error(
              "Error re-prescribing prescription, please try again"
            );
            return;
          })
      )
    ).finally(() => {
      this.rePrescribeAllPrescriptionsInProgress = false;
      this.handlePrescriptionsUpdate();
    });
  }

  // SELECTED DRUGS ONLY
  // for selected drugs or single drug
  prescribeSelectedPrescriptionDrugsPdf(drugs?: GlPrescriptionDrugData[]) {
    // get drugs and turn them into model
    this.rePrescribePrescriptionInProgress = true;

    const selectedDrugs: GlPrescriptionDrugData[] =
      drugs ?? this.selectedPrescriptionDrugs;

    const chunkedDrugs: GlPrescriptionDrugData[][] =
      this.PrescriptionsService.chunkPrescriptionDrugs(selectedDrugs);

    Promise.all(
      map(chunkedDrugs, (drugs: GlPrescriptionDrugData[]) =>
        this.createPrescriptionRecord(drugs)
          .then((response) => {
            if (!response) {
              return Promise.reject();
            }
            return response;
          })
          .then((prescription) => {
            this.toastr.success(
              `Successfully created Prescription No${prescription.id}!`
            );
            this.printPrescriptionPdf(prescription);
          })
          .catch(() => {
            this.toastr.error(
              "Error re-prescribing prescription, please try again"
            );
            return;
          })
      )
    )
      .then(() => {
        this.clearSelectedDrugs();
      })
      .finally(() => {
        this.rePrescribePrescriptionInProgress = false;
        this.handlePrescriptionsUpdate();
      });
  }

  prescribeSelectedPrescriptionDrugs(drugs?: GlPrescriptionDrugData[]) {
    // get drugs and turn them into model
    this.rePrescribePrescriptionInProgress = true;

    const selectedDrugs: GlPrescriptionDrugData[] =
      drugs ?? this.selectedPrescriptionDrugs;

    const chunkedDrugs: GlPrescriptionDrugData[][] =
      this.PrescriptionsService.chunkPrescriptionDrugs(selectedDrugs);

    Promise.all(
      map(chunkedDrugs, (drugs: GlPrescriptionDrugData[]) =>
        this.createPrescriptionRecord(drugs)
          .then((response) => {
            if (!response) {
              return Promise.reject();
            }
            return response;
          })
          .then((prescription) => {
            this.toastr.success(
              `Successfully created Prescription No${prescription.id}!`
            );
            this.printPrescription(prescription);
          })
          .catch(() => {
            this.toastr.error(
              "Error re-prescribing prescription, please try again"
            );
            return;
          })
      )
    )
      .then(() => {
        this.clearSelectedDrugs();
      })
      .finally(() => {
        this.rePrescribePrescriptionInProgress = false;
        this.handlePrescriptionsUpdate();
      });
  }

  // HELPERS
  // GENERIC PRESCRIPTION CREATOR
  createPrescription(drugs: GlPrescriptionDrugData[]) {
    this.createPrescriptionInProgress = true;

    this.createPrescriptionRecord(drugs)
      .then((response) => {
        if (!response) {
          return Promise.reject();
        }
        return response;
      })
      .then((prescription) => {
        this.toastr.success(
          `Successfully created Prescription No${prescription.id}!`
        );
        this.printPrescriptionPdf(prescription);
      })
      .catch(() => {
        this.toastr.error("Error creating prescription, please try again");
      })
      .finally(() => {
        this.createPrescriptionInProgress = false;
        this.handlePrescriptionsUpdate();
      });
  }

  // GENERAL HELPER FOR PRESCRIPTION
  createPrescriptionRecord(drugs: GlPrescriptionDrugData[]) {
    const authorityTreatment: GlPrescriptionDrugData | null = drugs.find(
      (drug: GlPrescriptionDrugData) => drug.authority_script && !drug?.private
    );

    return this.PrescriptionsService.createPrescription({
      record_id: this.useRecordIdOnPrescribe ? this?.record?.id : null,
      patient_id: this.patient.id,
      // just do item code
      treatment_ids: drugs.map((d: GlPrescriptionDrugData) =>
        String(d.treatment_id || d.item_code)
      ),
      // to remove any instance of helper attributes
      data: drugs.map((d: GlPrescriptionDrugDataExtended) =>
        omit(d, ["prescription_id"])
      ),
      authority_number: authorityTreatment?.treatment_of_code ?? null,
    }).then((response) => {
      return response;
    });
  }

  // GENERAL HELPER FOR UPDATE
  updatePrescriptionRecord(prescription: GlPrescription) {
    const prescriptionId: number = prescription.id;
    const patientId: number = prescription.patient_id;
    const authorityNumber: string | null =
      prescription.authority_number ?? null;

    return this.PrescriptionsService.updatePrescription(prescriptionId, {
      patient_id: patientId,
      data: prescription.data,
      authority_number: authorityNumber,
      treatment_ids: prescription.data.map(
        (d: GlPrescriptionDrugData) => d.treatment_id
      ),
    });
  }

  // GENERAL HELPER FOR REPRESCRIBE
  rePrescribePrescriptionRecord(prescription: GlPrescription) {
    return this.PrescriptionsService.createPrescription({
      record_id: this.useRecordIdOnPrescribe ? this?.record?.id : null,
      patient_id: this.patient.id,
      treatment_ids: prescription.data.map(
        (d: GlPrescriptionDrugData) => d.treatment_id
      ),
      data: prescription.data,
      authority_number: prescription.authority_number ?? null,
    }).then((response) => {
      return response;
    });
  }

  // PRINTERS
  printAllPrescriptions() {
    this.printAllPrescriptionsInProgress = true;
    Promise.all(
      map(this.prescriptions, (prescription: GlPrescription) =>
        this.printPrescriptionPdf(prescription)
      )
    ).finally(() => {
      this.printAllPrescriptionsInProgress = false;
    });
  }

  // FOR DRUGS
  printSelectedPrescriptionDrugs() {
    this.printSelectedPrescriptionsInProgress = true;
  }

  // FOR PRESCRIPTIONS
  printSelectedPrescriptions() {
    this.printSelectedPrescriptionsInProgress = true;
    Promise.all(
      map(this.selectedPrescriptions, (prescription: GlPrescription) =>
        this.printPrescriptionPdf(prescription)
      )
    )
      .then(() => {
        this.clearSelectedPrescriptions();
      })
      .finally(() => {
        this.printSelectedPrescriptionsInProgress = false;
      });
  }

  printSelectedPrescriptionsPdf() {
    this.printSelectedPrescriptionsPdfInProgress = true;
    Promise.all(
      map(this.selectedPrescriptions, (prescription: GlPrescription) =>
        this.printPrescriptionPdf(prescription)
      )
    )
      .then(() => {
        this.clearSelectedPrescriptions();
      })
      .finally(() => {
        this.printSelectedPrescriptionsPdfInProgress = false;
      });
  }

  printAllPrescriptionsPdf() {
    this.printAllPrescriptionsPdfInProgress = true;
    Promise.all(
      map(this.prescriptions, (prescription: GlPrescription) =>
        this.printPrescriptionPdf(prescription)
      )
    ).finally(() => {
      this.printAllPrescriptionsPdfInProgress = false;
    });
  }

  // PRINT HELPERS
  // generic print function
  printPrescriptionPdf(prescription: GlPrescription) {
    this.toastr.info(`Printing PDF for Prescription(s)...`);
    this.PrescriptionsService.pdf({
      drugs: prescription.data,
      prescriber: this.prescriber,
      patient: this.patient,
      prescription,
    }).then((pdf) => {
      // eslint-disable-next-line
      const window = this.$window.open(URL.createObjectURL(pdf));
    });
  }

  printPrescription(prescription: GlPrescription) {
    this.toastr.info(
      `Printing Prescription(s), please check for printer prompts...`
    );
    this.PrescriptionsService.pdf({
      drugs: prescription.data,
      prescriber: this.prescriber,
      patient: this.patient,
      prescription,
    }).then((pdf) => {
      return this.QzPrinterService.printPdf(pdf);
    });
  }

  private _convertReverseIndexToRegular = (index: number) => {
    return this.prescriptions.length - index - 1;
  };

  private _reversePrescriptions() {
    if (!this.prescriptions) {
      return [];
    }
    return this.prescriptions.sort((a, b) => b.id - a.id);
  }
}

export class PatientPrescriptionsComponent implements IComponentOptions {
  static selector = "patientPrescriptions";
  static template = require("./patient-prescriptions.html");
  static controller = PatientPrescriptionsController;
  static bindings = {
    mode: "@",
    record: "<",
    user: "<",
    patient: "<?",
    prescriber: "<?",
    prescriptions: "<?",
    showPrintPrescriptions: "<?",
    useRecordIdOnPrescribe: "<?",
    printMode: "<?",
    onPrescriptionsUpdate: "&",
  };
}
