import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  IOnChangesObject,
  isFunction,
} from "angular";
import { orderBy } from "lodash";
import { IGlFormMode } from "models/gl-form-mode";
import {
  PatientProcedureDrug,
  PatientProcedureDrugSelectionWrapper,
} from "models/patient-procedure";

import { GlFormController } from "../../gl-form-controller";
import "./patient-drug-table.scss";

class PatientDrugTableController
  extends GlFormController
  implements IComponentController, IOnChanges
{
  mode: IGlFormMode = "display";
  // different display styles, either regular or favourite
  displayStyle: "default" | "favourites" | "print" = "default";
  drugs: PatientProcedureDrugSelectionWrapper[]; // anything that inherits from this should be eligible
  selectedDrugs: PatientProcedureDrugSelectionWrapper[] = [];

  // toggle selection
  toggleSelection: (arg: { drug: PatientProcedureDrug }) => void;

  // edit
  toggleEdit: (arg: { index: number }) => void;

  // create
  createDrug: (arg: { drug: PatientProcedureDrug }) => void;

  constructor() {
    "ngInject";
    super();
  }

  $onChanges(changes: IOnChangesObject): void {
    if (changes.drugs && this.drugs) {
      this.drugs = orderBy(
        this.drugs,
        [(d) => new Date(d.created_at), "created_at"],
        "desc"
      );
    }
  }

  // pirnt mode url
  isPrintStyle() {
    return this.displayStyle === "print";
  }

  // display styles
  isDefaultStyle() {
    return this.displayStyle === "default";
  }

  isFavouritesStyle() {
    return this.displayStyle === "favourites";
  }

  // toggles
  handleToggleSelection(drug: PatientProcedureDrugSelectionWrapper) {
    if (isFunction(this.toggleSelection)) {
      this.toggleSelection({ drug });
    }
  }

  handleToggleEdit(index: number) {
    if (isFunction(this.toggleEdit)) {
      this.toggleEdit({ index });
    }
  }

  handleCreateDrug(drug: PatientProcedureDrug) {
    if (isFunction(this.createDrug) && drug) {
      this.createDrug({ drug });
    }
  }

  isTableEditable() {
    return this.isCreateMode() || this.isEditMode();
  }

  // list of drugs
  getDrugs() {
    return this.drugs;
  }
}

export class PatientDrugTable implements IComponentOptions {
  static selector = "patientDrugTable";
  static template = require("./patient-drug-table.html");
  static controller = PatientDrugTableController;
  static bindings = {
    mode: "@",
    displayStyle: "@",
    isEditable: "<",
    drugs: "<",
    toggleSelection: "&",
    toggleEdit: "&",
    createDrug: "&",
    selectedDrugs: "<",
  };
}
