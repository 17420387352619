export class BilateralDicomFieldController implements angular.IController {
  constructor() {
    "ngInject";
  }
}

export class BilateralDicomFieldComponent implements angular.IComponentOptions {
  static selector = "bilateralDicomField";
  static controller = BilateralDicomFieldController;
  static template = require("./bilateral-dicom-field.html");
  static bindings = {
    leftDocument: "<",
    rightDocument: "<",
    dicomId: "<",
    title: "@"
  };
}
