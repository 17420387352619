// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { ReasonForReferralComponent } from "./components/reason-for-referral/reason-for-referral.component";
import { ReferToComponent } from "./components/refer-to/refer-to.component";
import { ReferralConfirmationComponent } from "./components/referral-confirmation/referral-confirmation.component";
import { ReferralLetterComponent } from "./components/referral-letter/referral-letter.component";
import { ReferralPage } from "./referral";

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider
    .state("main.new-referral", {
      url: "/referral/{recordId:int}?{debug:bool}",
      component: ReferralPage.selector,
      data: {
        requiresLogin: true,
      },
      params: {
        // This makes the recordId optional
        recordId: {
          squash: true,
          value: null,
        },
      },
    })
    .state("main.referral", {
      url: "/patient/{patientId:int}/referral/{recordId:int}?{debug:bool}",
      component: ReferralPage.selector,
      data: {
        requiresLogin: true,
      },
      params: {
        // This makes the recordId optional
        recordId: {
          squash: true,
          value: null,
        },
      },
    });
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.referral", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(
    ReferralConfirmationComponent.selector,
    ReferralConfirmationComponent
  )
  .component(ReasonForReferralComponent.selector, ReasonForReferralComponent)
  .component(ReferralLetterComponent.selector, ReferralLetterComponent)
  .component(ReferralPage.selector, ReferralPage)
  .component(ReferToComponent.selector, ReferToComponent)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
