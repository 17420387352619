// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";

/**
 * Import Module Components
 */
import { SelectClinicPage } from "./select-clinic";

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.select-clinic", {
    url: "/select-clinic",
    component: SelectClinicPage.selector,
    data: {
      requiresLogin: true
    }
  });

  $urlRouterProvider.otherwise("/dashboard");
};

/**
 * Module Configuration
 */
const configuration = () => {
  "ngInject";
};

export const moduleName = angular
  .module("gl.pages.select-clinic", ["ui.router", "gl.core"])

  /**
   * Register Module Components
   */
  .component(SelectClinicPage.selector, SelectClinicPage)

  /**
   * Register Module Configuration
   */
  .config(configuration)
  .config(routing).name;
