import * as moment from "moment";

export const pipeName = "appointmentDate";
export function appointmentDatePipe() {
  "ngInject";
  return (currentRecord: any) => {
    const dateToUse =
      currentRecord.record.appointment_date || currentRecord.record.created_at;
    const now = moment();
    const date = moment(dateToUse);
    if (date.isSame(now, "day")) {
      return date.format("hh:mm");
    } else {
      const diff = now.diff(date, "days");
      return diff <= 1 ? "1 day ago" : `${diff} days ago`;
    }
  };
}
