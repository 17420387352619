import { copy } from "angular";
import { Appendix } from "app/core/services/appendix";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { PatientProcedureService } from "app/core/services/patient-procedure.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { ValueAutofillService } from "app/core/services/value-autofill/value-autofill.service";
import { GL_VIEWS } from "app/pages/main.record/components/view-manager/view-manager.component";
import {
  cloneDeep,
  defaultsDeep,
  first,
  isEmpty,
  isNil,
  keys,
  set,
  some,
} from "lodash";
import { IGlSide, IGlSideBilateral } from "models/gl-side.model";
import { PatientProcedureExternal } from "models/patient-procedure";
import {
  GlBilateral,
  GlDiagnosis,
  GlDiagnosisOption,
  PatientRecordData,
} from "models/patient-record.model";
import { Patient, User } from "models/user.model";
import { PATIENT_RECORD_EVENT_SIGN } from "../../../../../app/pages/main.record/record";
import { DiagnosisService } from "../../../services/diagnosis.service";
import { GlModelService } from "../../../services/gl-model.service";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
import {
  PATIENT_PROCEDURE_TEMPORARY_CLEAR_EVENT,
  PATIENT_PROCEDURE_TEMPORARY_CONFIRM_AUTOFILL,
  PATIENT_PROCEDURE_TEMPORARY_CREATE_EVENT,
  // PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_REQ,
  PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES,
} from "../patient-procedures/patient-procedures";
import "./patient-diagnosis.scss";

export const PATIENT_DIAGNOSIS_UPDATE_PROCEDURE_EVENT =
  "patientDiagnosis:updateProcedureEvent";
export const PATIENT_DIAGNOSIS_PREFILL_TRIGGER_EVENT =
  "patientDiagnosis:prefillTriggerEvent";

export class DiagnosisController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  DIAGNOSIS_ARRAY_KEY: string = "management.diagnosis_array";

  // @Input()
  enableLeft = true;
  enableRight = true;
  record: PatientRecordData;
  recordId: number = this.$stateParams.recordId;
  selectedCondition: GL_VIEWS;
  diagnosisForDisplay: GlBilateral<GlDiagnosis[]>;
  user: User;
  patient: Patient;

  patientDiagnosisForm: angular.IFormController;

  // to keep track of autofill state
  diagnosisAutofillInit: boolean = false;
  diagnosisProcedureAutofillStateMap: Map<string, IGlSide> = new Map<
    string,
    IGlSide
  >();

  diagnosisErrors = this.ErrorAppendix.getDiagnosisErrorMessages();

  constructor(
    private $scope: angular.IScope,
    private $rootScope: angular.IRootScopeService,
    private $timeout: angular.ITimeoutService,
    private $stateParams: angular.ui.IStateParamsService,
    private ErrorAppendix: ErrorAppendix,
    private DiagnosisService: DiagnosisService,
    private ValueAutofillService: ValueAutofillService,
    private GlModelService: GlModelService,
    private PatientRecordService: PatientRecordService,
    private PatientProcedureService: PatientProcedureService,
    private toastr: angular.toastr.IToastrService,
    private appendix: Appendix
  ) {
    "ngInject";
    super();
  }

  $onInit(): void {
    // on main record sign, it saves all form data incl of this
    // so have to set to pristine
    this.$scope.$on(PATIENT_RECORD_EVENT_SIGN, () => {
      if (this?.patientDiagnosisForm?.$dirty) {
        this.patientDiagnosisForm.$setPristine();
      }
    });

    // reciever to check if patient diagnosis section has
    // a temp procedure
    this.$rootScope.$on(
      PATIENT_PROCEDURE_TEMPORARY_HAS_PROCEDURE_RES,
      (event: any, diagnosisName: string, side: IGlSide, recordId: number) => {
        if (recordId !== this.recordId) {
          return;
        }

        this.handleUpdateDiagnosisProcedureAutofillMap(diagnosisName, side);
      }
    );

    // this is usually caleld from the observation section
    this.$rootScope.$on(
      PATIENT_DIAGNOSIS_UPDATE_PROCEDURE_EVENT,
      (event: any, diagnosis: GlDiagnosis, recordId: number) => {
        if (recordId !== this.recordId) {
          return;
        }
        // only perform when a diagnosis is passed
        if (diagnosis) {
          this.handleDiagnosisChangeUpdateAutofill(
            this?.record?.management?.diagnosis_array,
            "level1",
            diagnosis
          );
        }
      }
    );

    // // THIS SHOULD TRIGGER ONLY IF AUTOFILL IS TRIGGERED ON OTHER SIDE
    // // then do changes only once patient is loaded
    this.$rootScope.$on(
      PATIENT_DIAGNOSIS_PREFILL_TRIGGER_EVENT,
      (event: any, diagnosisName: string, recordId: number) => {
        if (recordId !== this.recordId) {
          return;
        }
        // it can only be triggered once
        if (
          !this.diagnosisAutofillInit &&
          this.patient &&
          this.diagnosisHasExternalProcedureAutofill(diagnosisName)
        ) {
          this.diagnosisAutofillInit = true;
          const diagnosis: GlDiagnosis =
            this.DiagnosisService.getDiagnosisAutofillMapping(diagnosisName);
          // delay to avoid race conditions
          this.$timeout(100).then(() => {
            this.handleDiagnosisChangeAutofill("level1", diagnosis);
          });
        }
      }
    );
  }

  $onChanges() {
    if (this.record && this.isEditable && this.isEditMode()) {
      // If we are in edit mode, migrate any legacy diagnosis fields to the
      // diagnosis_array and delete the old key

      const diagnosis = this.record?.management?.diagnosis;
      if (diagnosis) {
        this.record.management.diagnosis_array = {
          ...(this.enableLeft && { left: [diagnosis.left] }),
          ...(this.enableRight && { right: [diagnosis.right] }),
        };
        delete this.record.management.diagnosis;
      }
      const baseDiagnosis: GlBilateral<GlDiagnosis[]> = {
        ...(this.enableLeft && { left: [] }),
        ...(this.enableRight && { right: [] }),
      };

      const defaultsRaw = {
        management: {
          diagnosis_array:
            this.GlModelService.get("management.diagnosis_array") ||
            baseDiagnosis,
        },
      };

      const isTechnician = this.user.type.name === "technician";

      const defaults = {
        management: {
          diagnosis_array: {
            right: !isTechnician
              ? this.getDiagnosisDefaults(
                  defaultsRaw.management.diagnosis_array.right,
                  this.record.management?.diagnosis_array?.right
                )
              : undefined,
            left: !isTechnician
              ? this.getDiagnosisDefaults(
                  defaultsRaw.management.diagnosis_array.left,
                  this.record.management?.diagnosis_array?.left
                )
              : undefined,
          },
        },
      };

      defaultsDeep(this.record, defaults);

      const defaultDiagnosis = this.DiagnosisService.getDefaultDiagnosis(
        !isTechnician ? this.selectedCondition : undefined
      );
      if (
        !this.record.management.diagnosis_array.left ||
        (this.record.management.diagnosis_array.left?.length === 0 &&
          this.enableLeft)
      ) {
        this.record.management.diagnosis_array.left = [copy(defaultDiagnosis)];
      }

      // right side declared or is empty and enabled
      if (
        !this.record.management.diagnosis_array.right ||
        (this.record.management.diagnosis_array.right?.length === 0 &&
          this.enableRight)
      ) {
        this.record.management.diagnosis_array.right = [copy(defaultDiagnosis)];
      }

      // left side declared or is empty and enabled
      if (this.enableLeft === false || this.enableRight === false) {
        this.record.management.diagnosis_array = {
          ...(this.enableLeft && {
            left: this.record.management.diagnosis_array.left,
          }),
          ...(this.enableRight && {
            right: this.record.management.diagnosis_array.right,
          }),
        };
      }
    }
  }

  // DIAGNOSIS RELATED
  getDiagnosisDefaults(currentDefaults: any, currentRecord: any) {
    // this function goes through each of the default values and filters out any that
    // already have that value in current record.
    // Otherwise if a field has 1 level but the default
    // (from previous record) has more than 1 level,
    // the other levels are displayed
    // -> ie current record = healthy but previous record retina, t
    // he no dr field gets shown.
    if (!currentRecord || !currentDefaults || currentDefaults.length === 0) {
      return currentDefaults;
    }
    const diagnosis_array = [];
    for (let i = 0; i < currentDefaults.length; i++) {
      if (!currentRecord[i]) {
        diagnosis_array.push(currentDefaults[i]);
      } else {
        diagnosis_array.push(currentRecord[i]);
      }
    }
    return diagnosis_array;
  }

  getDiagnosisForDisplay() {
    const { diagnosis, diagnosis_array } = this.record?.management || {};
    if (diagnosis_array) {
      return diagnosis_array;
    } else if (!diagnosis_array && diagnosis) {
      return {
        left: [diagnosis.left],
        right: [diagnosis.right],
      };
    }
  }

  getBiggestDiagnosisArray() {
    const diagnosis = this.getDiagnosisForDisplay();
    const left = diagnosis?.left || [];
    const right = diagnosis?.right || [];
    return left.length > right.length ? left : right;
  }

  getDiagnosis(diagnosis: GlDiagnosis) {
    return this.DiagnosisService.getDiagnosis(diagnosis);
  }

  showOtherTextField(diagnosis: GlDiagnosis) {
    return this.DiagnosisService.showOtherTextField(diagnosis);
  }

  // DIAGNOSIS ON CHANGES
  level1OnChange(
    side: IGlSideBilateral,
    diagnosis: GlDiagnosis,
    oldDiagnosis: GlDiagnosis,
    index: number
  ) {
    if (!diagnosis) {
      return;
    }
    // if level 1 changes. then set the child options to defaults
    const { level1 } = diagnosis;
    // get a clone
    const diagnosisArrayClone: GlBilateral<GlDiagnosis[]> = cloneDeep(
      this.record.management.diagnosis_array
    );

    // if the diagnosis is found
    if (level1) {
      // EDGE CASE: if level 1 doenst allow for additional options
      // replace all with just healthy
      if (!this.isDiagnosisMultiSelect(level1)) {
        // if exists replace

        const diagnosisArray: GlDiagnosis[] = diagnosisArrayClone[side];
        if (index >= 0) {
          diagnosisArray[index] = oldDiagnosis;
        }
        // replace with previous one for sorting
        // undo autofill for all current diagnoses
        this.removeAutofillDiagnosesOnSide(diagnosisArray, side);
        // perform cleanup for procedure
        this.handleDiagnosisChangeUpdateAutofill(
          diagnosisArrayClone,
          "level1",
          diagnosis
        );

        // remove all other diagnoses
        delete diagnosis.level2;
        delete diagnosis.level3;
        delete diagnosis.level4;

        // set as the only option
        this.record.management.diagnosis_array[side] = [cloneDeep(diagnosis)];
      } else {
        // set level 2 to the default value
        diagnosis.level2 = first(
          this.DiagnosisService.LEVEL2_OPTIONS[level1.key]
        );
      }
    } else {
      delete diagnosis.level2;
    }

    // OBS <-> DIAG AUTOFILL RELATED
    // check if on deselect we should undo autofill for regular
    if (this.isDiagnosisAutofillActive(oldDiagnosis.level1, side)) {
      this.undoAutofillDiagnosisRow(oldDiagnosis, side);
    }

    // cleanup diag <-> external procedure related old diangosis changes
    this.handleLevel1ClearOldExternalProcedureAutofill(
      diagnosis,
      oldDiagnosis,
      side,
      diagnosisArrayClone
    );

    // set the child values
    this.level2OnChange(side, diagnosis, oldDiagnosis);
  }

  // DIAG <-> PROCEDURE RELATED
  // SECOND EDGE CASE: old diagnosis autofills a procedure
  // if diagnosis that created the procedure is removed and
  // a procedure exsits, remove it
  // ALSO will not be triggered on single options like Healthy
  handleLevel1ClearOldExternalProcedureAutofill(
    diagnosis: GlDiagnosis,
    oldDiagnosis: GlDiagnosis,
    side: IGlSideBilateral,
    diagnosisArrayClone: GlBilateral<GlDiagnosis[]>
  ) {
    if (
      this.isDiagnosisMultiSelect(diagnosis?.level1) &&
      this.diagnosisHasExternalProcedureAutofill(oldDiagnosis?.level1?.key)
    ) {
      // check if we need to undo the old one
      this.undoAutofillExternalProcedure(oldDiagnosis, "level1", side);
      // this.handleDiagnosisChangeUpdateAutofill(
      //   this?.record?.management?.diagnosis_array,
      //   "level1",
      //   oldDiagnosis
      // );
    } else {
      // then based on the changes done,
      // check through old array to see if any autofills need to be cleared
      this.handleClearAutofillExternalProcedureForSide(
        diagnosisArrayClone,
        "level1",
        side
      );
    }

    // avoid a race condition by setting a minor delay
    this.$timeout(100).then(() => {
      // only trigger if on change, not auto
      if (
        this.diagnosisHasExternalProcedureAutofill(oldDiagnosis?.level1?.key)
      ) {
        // then check autofill for the new ones
        this.handleDiagnosisChangeAutofill("level1", diagnosis);
      }
    });
  }

  level2OnChange(
    side: IGlSideBilateral,
    diagnosis: GlDiagnosis,
    // eslint-disable-next-line
    oldDiagnosis: GlDiagnosis
  ) {
    // if level 1 changes. then set the child options to defautls
    const { level2 } = diagnosis;

    if (level2) {
      // set level 2 to the default value
      diagnosis.level3 = first(
        this.DiagnosisService.LEVEL3_OPTIONS[level2.key]
      );
    } else {
      delete diagnosis.level3;
    }

    if (!this.showOtherTextField(diagnosis)) {
      delete diagnosis.level2_other;
    }

    // set the child values
    this.level3OnChange(side, diagnosis, oldDiagnosis);
  }

  level3OnChange(
    side: IGlSideBilateral,
    diagnosis: GlDiagnosis,
    // eslint-disable-next-line
    oldDiagnosis: GlDiagnosis
  ) {
    // if level 1 changes. then set the child options to defautls
    const { level3 } = diagnosis;

    if (level3) {
      // set level 2 to the default value
      diagnosis.level4 = first(
        this.DiagnosisService.LEVEL4_OPTIONS[level3.key]
      );
    } else {
      delete diagnosis.level4;
    }
  }

  // OPTIONS RELATED
  getLevel1Options() {
    return this.DiagnosisService.LEVEL1_OPTIONS;
  }

  getLevel2Options(diagnosis: GlDiagnosis) {
    if (diagnosis?.level1) {
      return this.DiagnosisService.LEVEL2_OPTIONS[diagnosis.level1.key];
    }
  }

  getLevel3Options(diagnosis: GlDiagnosis) {
    if (diagnosis?.level2) {
      return this.DiagnosisService.LEVEL3_OPTIONS[diagnosis.level2.key];
    }
  }

  getLevel4Options(diagnosis: GlDiagnosis) {
    if (diagnosis?.level3) {
      return this.DiagnosisService.LEVEL4_OPTIONS[diagnosis.level3.key];
    }
  }

  // GENERAL DIAGNOSIS CRUD
  addDiagnosis(diagnosisArray: GlDiagnosis[]) {
    diagnosisArray.push({ level1: undefined });
    // set form dirty mock
    this.patientDiagnosisForm.$setDirty();
  }

  deleteDiagnosis(
    diagnosisArray: GlDiagnosis[],
    index: number,
    side: IGlSideBilateral
  ) {
    // if empty remove other side
    this.removeAutofillForDiagnosis(diagnosisArray[index], side);
    // splice
    diagnosisArray.splice(index, 1);

    // check the symbol
    this.handleDiagnosisChangeAutofill("level1");

    // set form dirty mock
    this.patientDiagnosisForm.$setDirty();
  }

  // split into two parts, one is diagnosis autofill
  // the other is a procedure autofill
  copyDiagnosis(index: number, toSide: IGlSideBilateral) {
    const fromSide = toSide === "right" ? "left" : "right";
    // NEW VALUE
    const fromValue =
      this.record?.management?.diagnosis_array?.[fromSide][index];
    // OLD VALUE
    const toValue = this.record?.management?.diagnosis_array?.[toSide][index];
    const oldDiagnosisArray: GlBilateral<GlDiagnosis[]> = cloneDeep(
      this.record.management.diagnosis_array
    );

    // baseline error
    if (isNil(fromValue)) {
      return this.toastr.error(
        this.diagnosisErrors.copy.diagnosis.doesnt_exist
      );
    }

    // CASE: check if other side has a non-multi-select field
    else if (this.sideHasNonMultiSelectDiagnosis(toSide)) {
      // then block and error out
      switch (index) {
        // by defautl should be an identical clone
        case 0:
          set(this.record, `management.diagnosis_array.${toSide}`, [
            cloneDeep(fromValue),
          ]);
          break;
        default:
          return this.toastr.error(
            this.diagnosisErrors.copy.multi_select
              .cannot_copy_to_non_multiselect
          );
      }
    } else if (this.sideHasNonMultiSelectDiagnosis(fromSide)) {
      // CASE: are we copying a non-multi-select field over
      // handle and remove autofill
      this.removeAutofillDiagnosesOnSide(
        this.record.management.diagnosis_array[toSide],
        toSide
      );

      // then assign
      set(this.record, `management.diagnosis_array.${toSide}`, [
        cloneDeep(fromValue),
      ]);

      // clear with reference to old one to compare changes
      this.handleClearAutofillExternalProcedureForSide(
        oldDiagnosisArray,
        "level1",
        toSide
      );
    }

    // DEFAULT: just go about as usual and hceck for erros
    set(
      this.record,
      `management.diagnosis_array.${toSide}[${index}]`,
      cloneDeep(fromValue)
    );

    // perform cleanup
    this.handleDiagnosisChangeUpdateAutofill(
      this.record.management.diagnosis_array,
      "level1",
      toValue
    );

    // avoid a race condition by setting a minor delay
    // triger after update
    this.$timeout(100).then(() => {
      // then check autofill for the new ones
      this.handleDiagnosisChangeAutofill(
        "level1",
        cloneDeep(fromValue) // this is the new diagnosis
      );
    });
  }

  // AUTOFILL HELPERS
  // OBSERVATION -> DIAGNOSIS AUTOFILL
  // checks if we need to autofill anything
  handleDiagnosisChangeAutofill(
    level: keyof Omit<GlDiagnosis, "level2_other">,
    diagnosis?: GlDiagnosis
  ) {
    if (this?.patient && this.recordId) {
      // get list of autofill procedures
      let autofillKeys: string[] = Object.keys(
        this.appendix.getDefaultExternalProcedureOptions()
      );

      // if an option is declared, we must check it or else ignored
      if (diagnosis?.[level]) {
        autofillKeys = autofillKeys?.filter(
          (k) => k === diagnosis?.[level]?.key
        );
      }

      // for each key:
      for (const key of autofillKeys) {
        // check if exsits in L/R
        const leftHasDiagnosis =
          this?.record?.management?.diagnosis_array?.left?.find(
            (d) => d?.[level]?.key === key
          );
        const rightHasDiagnosis =
          this?.record?.management?.diagnosis_array?.right.find(
            (d) => d?.[level]?.key === key
          );

        // autofill if either exists
        if (leftHasDiagnosis || rightHasDiagnosis) {
          // use as a reference to determine what to create
          const side: IGlSide =
            leftHasDiagnosis && rightHasDiagnosis
              ? "both"
              : leftHasDiagnosis
              ? "left"
              : "right";

          // create it based on either diagnosis
          const diagnosis: GlDiagnosis = leftHasDiagnosis ?? rightHasDiagnosis;
          this.handleAutofillExternalProcedure({
            patient: this?.patient,
            level: level,
            diagnosis,
            side,
          });
        }
      }
    }
  }

  // if an old diagnosis was a procedure autofill trigger
  // comb through array to check if not exsiting
  // if not, remove from autofill
  handleDiagnosisChangeUpdateAutofill(
    diagnosisArray: GlBilateral<GlDiagnosis[]>,
    level: keyof Omit<GlDiagnosis, "level2_other">,
    oldDiagnosis: GlDiagnosis
  ) {
    if (this?.patient && this?.record && this?.recordId) {
      // get list of autofill procedures
      const autofillKeys: string[] = Object.keys(
        this.appendix.getDefaultExternalProcedureOptions()
      );

      // we only target that which matches the old diagnosis
      const key: string = autofillKeys?.find(
        (k) => oldDiagnosis?.[level]?.key === k
      );

      if (isNil(key)) {
        return;
      }

      // check if exsits in L/R
      const leftHasDiagnosis = diagnosisArray?.left?.find(
        (d) => d?.[level]?.key === key
      );
      const rightHasDiagnosis = diagnosisArray?.right?.find(
        (d) => d?.[level]?.key === key
      );

      // clear autofill if doenst exist in either
      if (!leftHasDiagnosis && !rightHasDiagnosis) {
        // use either as a reference
        this.handleClearAutofillExternalProcedure({
          optionLevel: level,
          diagnosis: oldDiagnosis,
        });
      } else if (leftHasDiagnosis || rightHasDiagnosis) {
        // use as a reference to determine what to create
        const side: IGlSide =
          leftHasDiagnosis && rightHasDiagnosis
            ? "both"
            : leftHasDiagnosis
            ? "left"
            : "right";
        // just update it with a new one
        this.handleAutofillExternalProcedure({
          diagnosis: oldDiagnosis,
          level: level,
          patient: this.patient,
          side,
        });
      }
    }
  }

  // OBS <-> DIAGNOSIS AUTOFILL PROMPT BUTTONS
  // undo autofill on undo click
  undoAutofillDiagnosisRow(diagnosis: GlDiagnosis, side: IGlSideBilateral) {
    // remove autofill
    this.PatientRecordService.undoAutofillForDiagnosisSideOnly({
      record: this.record,
      side,
      diagnosisKey:
        this.DiagnosisService.convertDiagnosisOptionToKey(diagnosis),
    });

    // then check for procedure autofill
    this.undoAutofillExternalProcedure(diagnosis, "level1", side);
  }

  confirmAutofillDiagnosisRow(diagnosis: GlDiagnosis, side: IGlSideBilateral) {
    // handles diagnosis side
    this.PatientRecordService.confirmAutofillDiagnosisFromDiagnosis({
      diagnosis,
      side,
    });
  }

  // OBS <-> DIAGNOSIS
  // remove autofill for one diagnosis
  removeAutofillForDiagnosis(diagnosis: GlDiagnosis, side: IGlSideBilateral) {
    const autofillOptionLevel: string = this.getLevelKeyWithDiagnosis(
      diagnosis,
      side
    );

    if (!isNil(autofillOptionLevel)) {
      this.undoAutofillDiagnosisRow(diagnosis, side);
    }
  }

  // same as above but for ALL diagnoses on one side
  removeAutofillDiagnosesOnSide(
    diagnoses: GlDiagnosis[],
    targetSide: IGlSideBilateral
  ) {
    // if existing remove
    for (const diagnosis of diagnoses) {
      const activeAutofillOption: string = Object.keys(diagnosis).find((k) =>
        this.isDiagnosisAutofillActive(diagnosis[k], targetSide)
      );

      // if we found one undo
      if (!isNil(activeAutofillOption)) {
        this.undoAutofillDiagnosisRow(diagnosis, targetSide);
      }
    }
  }

  // AUTOFILL HELPERS
  // does side have autofill? if so get the relevant index
  getLevelKeyWithDiagnosis(diagnosis: GlDiagnosis, side: IGlSideBilateral) {
    for (const option of keys(diagnosis)) {
      if (this.isDiagnosisAutofillActive(diagnosis[option], side)) {
        return option;
      }
    }
    return null;
  }

  // Is the OBS <-> Diagnosis Autofill Active
  isDiagnosisAutofillActive(
    diagnosis: GlDiagnosisOption,
    side: IGlSideBilateral
  ) {
    if (!isNil(diagnosis)) {
      // check dictionary
      const foundDiagnosis: GlDiagnosis =
        this.DiagnosisService.getDiagnosisAutofillMapping(diagnosis.key);
      if (isNil(foundDiagnosis)) {
        return false;
      }

      // generate key
      const internalDiagnosisKey: string = !isNil(foundDiagnosis)
        ? this.DiagnosisService.convertDiagnosisOptionToKey(foundDiagnosis)
        : null;

      // check if existing
      return this.ValueAutofillService.hasAutofill(
        this.ValueAutofillService.generateAutofillReferenceKey({
          section: this.DIAGNOSIS_ARRAY_KEY,
          option_key: internalDiagnosisKey,
          side,
        })
      );
    }

    return false;
  }

  // diagnosis side has a restricted diagnosis?
  // e.g. Healthy is one as you cant be diagnoses with
  // anything else after healthy
  sideHasNonMultiSelectDiagnosis(side: IGlSideBilateral) {
    for (const diagnosis of this.record.management.diagnosis_array[side] ??
      []) {
      if (isNil(diagnosis)) {
        continue;
      }

      const { level1, level2 } = diagnosis;

      if (some([level1, level2], (o) => !this.isDiagnosisMultiSelect(o))) {
        return true;
      }
    }

    return false;
  }

  // general helper, multi select === allows for extra diagnoses
  // anythign that isnt healthy basically
  isDiagnosisMultiSelect(diagnosis: GlDiagnosisOption) {
    // DEBUG: for bypassing multiple diagnosis selection
    // return true;
    return this.DiagnosisService.checkIfDiagnosisAllowsMultiSelect(diagnosis);
  }

  // DIAG <-> EXTERNAL PROCEDURE related
  handleAutofillExternalProcedure({
    diagnosis,
    level,
    patient,
    side,
  }: {
    diagnosis: GlDiagnosis;
    level: keyof Omit<GlDiagnosis, "level2_other">;
    patient: Patient;
    side: IGlSide;
  }) {
    // get option of new diagnosis
    const option: GlDiagnosisOption = diagnosis[level];
    // create a new temp procedure, if successful will return the new value
    const tempProcedure: PatientProcedureExternal =
      this.PatientProcedureService.createTempExternalProcedureFromDiagnosisArray(
        this.recordId,
        this.record,
        option.diagnosis,
        patient
      );

    // if not created or doesnt exist return
    if (isNil(tempProcedure)) {
      return;
    }
    // set side after
    tempProcedure.data.eye = side;

    // then broadcast and update the autofill
    this.$rootScope.$broadcast(
      PATIENT_PROCEDURE_TEMPORARY_CREATE_EVENT,
      tempProcedure,
      this.recordId
    );
  }

  // clear procedure based on a given diagnosis
  handleClearAutofillExternalProcedure({
    diagnosis,
    optionLevel,
  }: {
    diagnosis: GlDiagnosis;
    optionLevel: keyof Omit<GlDiagnosis, "level2_other">;
  }) {
    // given a record id, check if temp procedure exists there
    const option: GlDiagnosisOption = diagnosis?.[optionLevel];

    // if we know that is an autofill procedure
    const isAutofill: boolean =
      this.PatientProcedureService.externalProcedureHasAutofill(
        option?.diagnosis
      );

    // if not created or doesnt exist return
    if (!isAutofill) {
      return;
    }

    // then broadcast and update the autofill
    this.$rootScope.$broadcast(
      PATIENT_PROCEDURE_TEMPORARY_CLEAR_EVENT,
      option?.diagnosis,
      this.recordId
    );
  }

  // with previous isntance of the diagnosis array as a reference
  // check through current array to see if any autofills need to be cleared
  // this is an edge case
  handleClearAutofillExternalProcedureForSide(
    referenceDiagnosisArray: GlBilateral<GlDiagnosis[]>,
    level: keyof Omit<GlDiagnosis, "level2_other">,
    side: IGlSideBilateral
  ) {
    // for each of the old key
    for (const diagnosis of referenceDiagnosisArray?.[side] ?? []) {
      // get list of autofill procedures
      const autofillKeys: string[] = Object.keys(
        this.appendix.getDefaultExternalProcedureOptions()
      );

      // we only check through autofill keys, else they are ignored
      const key: string = autofillKeys?.find(
        (k) => diagnosis?.[level]?.key === k
      );
      if (isNil(key)) {
        continue;
      }

      // check if exsits in L/R for the new record array
      const leftHasDiagnosis =
        this?.record?.management?.diagnosis_array?.left?.find(
          (d) => d?.[level].key === key
        );
      const rightHasDiagnosis =
        this?.record?.management?.diagnosis_array?.right?.find(
          (d) => d?.[level].key === key
        );

      // clear autofill if doenst exist in either
      if (!leftHasDiagnosis && !rightHasDiagnosis) {
        // use either as a reference
        this.handleClearAutofillExternalProcedure({
          optionLevel: level,
          diagnosis: diagnosis,
        });
      } else if (leftHasDiagnosis || rightHasDiagnosis) {
        // use as a reference to determine what to create
        const side: IGlSide =
          leftHasDiagnosis && rightHasDiagnosis
            ? "both"
            : leftHasDiagnosis
            ? "left"
            : "right";

        // just update it with a new one
        this.handleAutofillExternalProcedure({
          diagnosis,
          level: level,
          patient: this.patient,
          side,
        });
      }
    }
  }

  // for showing the user autofill icon
  isExternalProcedureAutofillActive(
    diagnosis: GlDiagnosis,
    optionLevel: keyof Omit<GlDiagnosis, "level2_other">,
    side: IGlSide
  ) {
    const option: GlDiagnosisOption = diagnosis?.[optionLevel];
    const autofillSide: IGlSide = this.diagnosisProcedureAutofillStateMap.get(
      option?.diagnosis
    );

    // check index so this always applies to first instance
    // since this is called whilst mapping through all
    // current diagnoses, it will always exist
    const firstInstanceIndex: number =
      this?.record?.management?.diagnosis_array?.[side]?.findIndex(
        (d) => d?.level1?.key === diagnosis?.level1?.key
      );
    const index: number = this?.record?.management?.diagnosis_array?.[
      side
    ]?.findIndex((d) => d === diagnosis);
    if (firstInstanceIndex !== index) {
      return;
    }

    // based on autofill, show cog on the relevant sides
    switch (autofillSide) {
      case "both":
        return side === "left" || side === "right";
      case "left":
        return side === "left";
      case "right":
        return side === "right";
      default:
        return false;
    }
  }

  // DIAG <-> EXTERNAL confirm options
  // confirm means that the autofill will be set
  confirmExternalProcedureAutofill(
    diagnosis: GlDiagnosis,
    optionLevel: keyof Omit<GlDiagnosis, "level2_other">
  ) {
    // given a record id, check if temp procedure exists there
    const option: GlDiagnosisOption = diagnosis?.[optionLevel];
    // if we know that is an autofill procedure
    const isAutofill: boolean =
      this.PatientProcedureService.externalProcedureHasAutofill(
        option?.diagnosis
      );

    if (isAutofill) {
      this.$rootScope.$broadcast(
        PATIENT_PROCEDURE_TEMPORARY_CONFIRM_AUTOFILL,
        option?.diagnosis,
        this.recordId
      );
    }
  }

  // this is on undo
  undoAutofillExternalProcedure(
    diagnosis: GlDiagnosis,
    optionLevel: keyof Omit<GlDiagnosis, "level2_other">,
    side: IGlSideBilateral
  ) {
    // this is pretty dependent on which side remains
    const option: GlDiagnosisOption = diagnosis[optionLevel];

    // but we still need to check if an autofill external procedure exists
    // get  name appendix and name defaults
    const defaultExternalProcedure = this.appendix.getDefaultExternalProcedure(
      option?.diagnosis
    );

    // state side to compare and determine what to do
    const autofillState: IGlSide = this.diagnosisProcedureAutofillStateMap.get(
      option?.diagnosis
    );

    // if its an autofill and previously it was a bialteral autofill
    if (defaultExternalProcedure && autofillState === "both") {
      // create a new procedure
      const tempProcedure: PatientProcedureExternal =
        this.PatientProcedureService.createTempExternalProcedureFromDiagnosisArray(
          this.recordId,
          this.record,
          option.diagnosis,
          this.patient
        );

      // replace the laterality
      // i.e. if B/E and we undo R/E, update with a L/E procedure
      tempProcedure.data.eye = side === "right" ? "left" : "right";
      // also remove order and side
      delete tempProcedure.data[side];
      delete tempProcedure.data.order;

      // broadcast a create event
      this.$rootScope.$broadcast(
        PATIENT_PROCEDURE_TEMPORARY_CREATE_EVENT,
        tempProcedure,
        this.recordId
      );
    } else {
      // otherwise its just a clear and should be dealt with
      this.$rootScope.$broadcast(
        PATIENT_PROCEDURE_TEMPORARY_CLEAR_EVENT,
        option?.diagnosis,
        this.recordId
      );
    }
  }

  // DIAG <-> EXTERNAL
  // this is what keeps track of the autofill state of
  // diagnosis and external procedure
  // its all based on eye side
  handleUpdateDiagnosisProcedureAutofillMap(
    diagnosisName: string,
    diagnosisSide?: IGlSide
  ) {
    // if existing set
    if (!isNil(diagnosisSide)) {
      // else set
      this.diagnosisProcedureAutofillStateMap.set(diagnosisName, diagnosisSide);
    } else {
      // remove if not existing anymore
      this.diagnosisProcedureAutofillStateMap.has(diagnosisName) &&
        this.diagnosisProcedureAutofillStateMap.delete(diagnosisName);
    }
  }

  // diag <-> external procedure is autofill?
  diagnosisHasExternalProcedureAutofill(diagnosisName: string) {
    return !isNil(this.appendix.getDefaultExternalProcedure(diagnosisName));
  }

  // CLEAR ROW
  /**
   * @ignore
   * IGNORE UNTIL BILATERAL CLEAR SELECTIONS RENEABLED
   */
  clearSelections(diagnosisArray: GlBilateral<GlDiagnosis[]>, index: number) {
    if (index >= 0) {
      // otherwise for other ones should always be a remove
      // if it exists
      if (diagnosisArray?.right?.[index]) {
        this.deleteDiagnosis(diagnosisArray.right, index, "right");
      }
      if (diagnosisArray?.left?.[index]) {
        this.deleteDiagnosis(diagnosisArray.left, index, "left");
      }

      // if all empty replace with healthy
      const defaultDiagnosis: GlDiagnosis =
        this.DiagnosisService.getDefaultDiagnosis("All");
      if (isEmpty(diagnosisArray.left)) {
        diagnosisArray.left = [cloneDeep(defaultDiagnosis)];
      }
      if (isEmpty(diagnosisArray.right)) {
        diagnosisArray.right = [cloneDeep(defaultDiagnosis)];
      }
    }
  }
}

export class PatientDiagnosisComponent implements angular.IComponentOptions {
  static selector = "patientDiagnosis";
  static template = require("./patient-diagnosis.html");
  static controller = DiagnosisController;

  static bindings = {
    enableLeft: "<",
    enableRight: "<",
    record: "<",
    selectedCondition: "<?",
    user: "<",
    patient: "<",
    ...GlFormControllerBindings,
  };
}
