import {
  IPatientResource,
  PatientService,
} from "../../core/services/patient.service";

class PaymentStep2Controller implements angular.IController, angular.IOnInit {
  patient_id: number;
  patient: IPatientResource;
  patientAcceptedTerms = false;

  constructor(
    private PatientService: PatientService,
    private $stateParams: angular.ui.IStateParamsService,
    private $state: angular.ui.IStateService
  ) {
    "ngInject";
  }

  $onInit() {
    this.patient_id = this.$stateParams.patient_id;
    this.patient = this.PatientService.get(this.patient_id);
  }

  update() {
    if (!this.patientAcceptedTerms) {
      return;
    }
    this.$state.go("main.payment-step3", {
      patient_id: this.patient_id,
    });
  }
}

export class PaymentStep2Component implements angular.IComponentOptions {
  static selector = "glPaymentStepTwo";
  static template = require("./user-payment-step2.html");
  static controller = PaymentStep2Controller;
  static bindings = {};
}
