import { IPromise } from "angular";
import { DrugsDbService } from "app/core/services/drugs-db/drugs-db.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { isNil } from "lodash";
import { DrugDbRecordPbsV2 } from "models/gl-drug";
import { User } from "models/user.model";
import "./settings-custom-drugs.scss";

export class SettingsCustomDrugsController implements angular.IController {
  user: User;

  customDrugs: DrugDbRecordPbsV2[];
  customDrugToEdit: DrugDbRecordPbsV2 | object = undefined;

  actionedDrug: DrugDbRecordPbsV2;

  editCustomDrugInProgress: boolean = false;

  saveCustomDrugInProgress: boolean = false;
  deleteCustomDrugInProgress: boolean = false;

  refreshCustomDrugsInProgress: boolean = false;

  errorMessages = this.ErrorAppendix.getAllErrorMessages();

  constructor(
    private $window: angular.IWindowService,
    private toastr: angular.toastr.IToastrService,
    private DrugsDbService: DrugsDbService,
    private ErrorAppendix: ErrorAppendix
  ) {
    "ngInject";
  }

  getCustomDrugs() {
    return this.customDrugs;
  }

  // if editing, show current drug as a reference
  // else use whole list
  getArrayToShowCustomDrugs() {
    return !isNil(this.customDrugToEdit)
      ? [this.customDrugToEdit]
      : this.customDrugs;
  }

  updateCustomDrugsAll() {
    return this.DrugsDbService.getAllCustomDrugs().then(
      (drugs: DrugDbRecordPbsV2[]) => {
        this.customDrugs = drugs ?? [];
        return drugs;
      }
    );
  }

  handleRefreshCustomDrugs() {
    this.refreshCustomDrugsInProgress = true;
    try {
      this.updateCustomDrugsAll();
      this.toastr.success("Refreshed custom drugs list");
    } catch (err) {
      this.toastr.error(
        "Failed to refresh custom drugs list, please try again."
      );
    } finally {
      this.refreshCustomDrugsInProgress = false;
    }
  }

  // EDITING
  createDrug() {
    this.customDrugToEdit = {};
    this.editCustomDrugInProgress = true;
  }

  // edit drug
  editDrug(index: number) {
    // set a custom drug
    this.customDrugToEdit = this.customDrugs[index];
  }

  // cancel drug
  cancelDrug() {
    this.customDrugToEdit = undefined;
    this.editCustomDrugInProgress = false;
  }

  // API RELATED
  // eslint-disable-next-line
  handleSaveDrug(drug: DrugDbRecordPbsV2) {
    this.saveCustomDrugInProgress = true;
    this.actionedDrug = drug;
    // // determine if create or update

    // eslint-disable-next-line
    const savePromise: IPromise<DrugDbRecordPbsV2> = !isNil(
      drug?.custom_drug_id
    )
      ? this.DrugsDbService.updateCustomDrug(drug)
      : this.DrugsDbService.createCustomDrug(drug);

    savePromise
      .then((drug) => {
        this.toastr.success(
          `Successfully ${
            drug.custom_drug_id ? "saved" : "created"
          } custom drug ${drug.brand_name}!`
        );
        this.customDrugToEdit = undefined;
      })
      .catch(() => {
        this.toastr.error(this.errorMessages.drugs.save);
      })
      .finally(() => {
        this.actionedDrug = undefined;
        this.saveCustomDrugInProgress = false;
        this.updateCustomDrugsAll();
      });
  }

  // delete
  handleDeleteDrug(drug: DrugDbRecordPbsV2) {
    const shouldDeleteDrug: boolean = this.$window.confirm(
      `Are you sure you want to delete ${drug.brand_name}?`
    );

    if (shouldDeleteDrug) {
      this.deleteCustomDrugInProgress = true;
      this.actionedDrug = drug;

      this.DrugsDbService.deleteCustomDrug(drug.custom_drug_id)
        .then(() => {
          this.customDrugToEdit = undefined;
          this.toastr.success(
            `Successfully deleted custom drug ${drug.brand_name}!`
          );
        })
        .catch(() => {
          this.toastr.error(this.errorMessages.drugs.delete);
        })
        .finally(() => {
          this.actionedDrug = undefined;
          this.deleteCustomDrugInProgress = false;
          this.updateCustomDrugsAll();
        });
    }
  }
}

export class SettingsCustomDrugs implements angular.IComponentOptions {
  static selector = "glSettingsCustomDrugs";
  static template = require("./settings-custom-drugs.html");
  static controller = SettingsCustomDrugsController;
  static bindings = {
    user: "<",
    customDrugs: "<",
  };
}
