import { isNil } from "lodash";
import { UserFavouriteDrugGroup } from "models/user-favourite-drugs";
import { UserFavouriteDrugsService } from "../services/user-favourite-drugs.service.ts/user-favourite-drugs.service";

export const pipeName: string = "favouriteGroupIdsToNames";
export const favouriteGroupIdsToNamesPipe: angular.Injectable<
  angular.FilterFactory
> = (UserFavouriteDrugsService: UserFavouriteDrugsService) => {
  "ngInject";
  return (groupIds: number[]) => {
    // get favourite groups
    const favouriteGroups: UserFavouriteDrugGroup[] =
      UserFavouriteDrugsService.getUserFavouriteDrugGroups();

    // sanity check
    if (isNil(groupIds) || isNil(favouriteGroups)) {
      return "";
    }

    // given a selection of groups
    // filters out the ones in the selection and returns them as a string
    return (
      favouriteGroups
        ?.filter((g) => groupIds.includes(g.id))
        .map((g) => g.name)
        .join(", ") ?? []
    );
  };
};
