import { GlClinicActiveRecord } from "../../../../../models/clinic-active-records.model";
import { GlMessage, GlThread } from "../../../../../models/messages.model";
import { User } from "../../../../../models/user.model";
import { ThreadFacadeService } from "../../../services/thread-facade.service";
import "./messenger-threads.scss";

class MessengerThreadsController
  implements angular.IController, angular.IOnChanges
{
  threads: GlThread[];
  user: User;
  messageBody: string = "";
  sendingMessageInProgress = false;
  deleteInProgress = true;
  records: GlClinicActiveRecord[];
  private editMode = false;
  private patientRecordId: number;
  private messageToEdit: GlMessage;
  private messageToDelete: GlMessage;
  constructor(
    private $state: angular.ui.IStateService,
    public ThreadFacadeService: ThreadFacadeService,
    private $window: angular.IWindowService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes?.patientRecordId) {
      // init threads when patientRecordId changes
      // this.ThreadFacadeService.init(this.patientRecordId).then(threads => {
      // the threads are initialised and can be used now. You would do this is you wanted to show a loading spinner whilst threads are being loaded.
      // });
    }
  }

  isUnread(thread: GlThread) {
    // Checks if a message is still unread by the current user

    // This function will mark the messages as read in the
    // "Dashboard: Unread Messages - Clinic tab" IF::
    // this user is a participant of the thread && this user has read the message

    const participants = thread.participants;
    const participant = participants.find((p) => p.user_id === this.user.id);

    let unread = true;
    if (participant && thread.updated_at < participant.last_read) {
      unread = false;
    }

    return unread;
  }

  openThreadRecord(thread: GlThread) {
    if (thread.record.virtual_review) {
      this.$state.go("main.virtual-review", {
        patientId: thread.record.patient_id,
        recordId: thread.record.virtual_review.id,
        "review-record": thread.record.id,
        goToMessages: true,
      });
    } else {
      this.$state.go("main.record", {
        patientId: thread.record.patient_id,
        recordId: thread.record.id,
        goToMessages: true,
      });
    }
  }
}

export class MessengerThreads implements angular.IComponentOptions {
  static selector = "messengerThreads";
  static template = require("./messenger-threads.html");
  static bindings = {
    threads: "<",
    patientRecordId: "<?",
    user: "<",
  };
  static controller = MessengerThreadsController;
}
