import { get, isEmpty, isNil } from "lodash";
import { IGlSide } from "../../../../../models/gl-side.model";
import {
  GlDiagnosis,
  PatientRecord,
} from "../../../../../models/patient-record.model";
import { Appendix } from "../../../../core/services/appendix";
import { SegmentHelperService } from "../../../../core/services/segment-helper.service";
import { DiagnosisService } from "../../../services/diagnosis.service";

export class GlLetterDryEyeTableController implements angular.IController {
  static $inject = [
    DiagnosisService.injectionName,
    SegmentHelperService.injectionName,
    Appendix.injectionName,
  ];
  record: PatientRecord;
  allowedFields: string[];
  osdiRanges = this.appendix.getNumericRangesByKey("osdi");

  constructor(
    private DiagnosisService: DiagnosisService,
    private segmentHelper: SegmentHelperService,
    private appendix: Appendix
  ) {
    // not empty
  }

  // get field
  getLargestFieldSide(fieldName: string) {
    const field = get(this.record.data, `${fieldName}`) ?? [];
    if (!isEmpty(field)) {
      // if left larger than right return else right, same
      // length doesnt matter
      return field?.left?.length > field?.right?.length
        ? field.left
        : field.right;
    }
    return [];
  }

  hasFields(fields: string[]) {
    return this.segmentHelper.hasFields(this.record.data, fields);
  }

  showFields(field: string) {
    if (!this.allowedFields?.includes("*")) {
      return this.allowedFields?.includes(field);
    } else if (this.allowedFields?.includes("*") || !this.allowedFields) {
      return true;
    }
  }

  getFieldToDisplay(
    fieldName: string,
    side: IGlSide,
    otherFieldName: string = `${fieldName}_other`
  ) {
    // OSDI EDGE CASE
    if (fieldName === "osdi") {
      return get(this.record.data, fieldName);
    }

    // else normal
    const val = get(this.record.data, `${fieldName}.${side}`);
    const otherValue =
      get(this.record.data, `${otherFieldName}.${side}`) ?? null;

    // private func
    return this._prettifyFieldToDisplay(val, otherValue);
  }

  // used for multiple observation values when doing a ng-repeat
  getFieldToDisplayByObservationIndex(
    fieldName: string,
    side: IGlSide,
    index: number
  ) {
    const val = get(this.record.data, `${fieldName}.${side}.${index}`);
    const otherValue = val?.other ?? null;
    if (isNil(val)) {
      return "";
    }

    return this._prettifyFieldToDisplay(val, otherValue);
  }

  getDiagnosis(side: IGlSide) {
    const diagnosisArray: GlDiagnosis[] =
      this.record?.data?.management?.diagnosis_array?.[side];
    if (diagnosisArray) {
      return diagnosisArray
        .map((d) => this.DiagnosisService.getDiagnosis(d))
        .join(", ");
    }
  }

  getOsdiClassification() {
    const osdiScore: number = get(this.record.data, "osdi");
    let classification: string = "";
    if (!this.osdiRanges || isNil(osdiScore)) {
      return;
    } else if (isNaN(osdiScore) || osdiScore < 0 || osdiScore > 100) {
      return;
    } else {
      for (const range of this.osdiRanges) {
        if (osdiScore > range.min && osdiScore <= range.max) {
          classification = range.title;
          break;
        }
      }
    }
    return classification;
  }

  private _prettifyFieldToDisplay(val: any, otherValue: any) {
    /**
     * Val can be an Object or a primitive (ie: number) - in this case, just
     * return val if it exists
     */
    if (val?.key !== "other" && !otherValue) {
      return val?.name || val;
    } else if (val?.key !== "other" && otherValue) {
      return `${val?.name || val}${
        // there is a weird edge vase where otherValue can return
        // undefined as a string
        !isNil(otherValue) && otherValue?.trim() !== "undefined"
          ? ` : ${otherValue}`
          : ""
      }`;
    } else {
      // same instance can happen here
      return otherValue?.trim() !== "undefined" ? otherValue : val?.name ?? val;
    }
  }
}

export class GlLetterDryEyeTableComponent implements angular.IComponentOptions {
  static selector = "glLetterDryEyeTable";
  static template = require("./gl-letter-dry-eye-table.html");
  static controller = GlLetterDryEyeTableController;
  static bindings = {
    record: "<",
    allowedFields: "<",
  };
}
