import {
  IComponentController,
  IOnChanges,
  IOnInit,
  extend,
  isFunction,
} from "angular";
import { IConsolidatedInjection } from "app/core/services/injection-helper/injection-helper.service";
import { IGlSignature } from "app/core/services/signature-service/signature.service";
import { omit } from "lodash";
import { Contact } from "models/contact.model";
import { PatientContact } from "models/patient-contact.model";
import { LetterType } from "../../../../../models/letter-types.model";
import { GlLetterType, Letter } from "../../../../../models/letter.model";
import { PatientClinic } from "../../../../../models/patient-clinic.model";
import {
  PatientProcedureDrop,
  PatientProcedureDrug,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "../../../../../models/patient-procedure";
import {
  GlPatientProviders,
  GlProvider,
  PatientRecord,
  PatientRecordData,
} from "../../../../../models/patient-record.model";
import { Patient, User } from "../../../../../models/user.model";
import { Appendix, LETTER_STATUSES } from "../../../services/appendix";
import { GlModelService } from "../../../services/gl-model.service";
import { LetterService } from "../../../services/letter.service";
import "./gl-letter.scss";
import { LetterController } from "./letter-controller";

import angular = require("angular");

export const GL_LETTER_EVENT_UPDATE = "glLetter:updateLetters";
export const GL_LETTER_EVENT_SAVE = "glLetter:saveLetters";
export const GL_LETTER_EVENT_UPDATE_SAVE = "glLetter:saveLetters";
export const GL_LETTER_EVENT_PRINT = "glLetter:printLetters";

export class GlLetterController
  implements IComponentController, IOnChanges, IOnInit
{
  // Inputs
  canDelete: boolean;
  drops: PatientProcedureDrop[];
  drugs: PatientProcedureDrug[];
  decidedReferrer: any;
  newAddressees: any;
  externalProcedures: PatientProcedureExternal[];
  consolidatedInjections: IConsolidatedInjection[];
  consolidatedInjectionsForLetter: any;
  consolidatedInjectionsDoneForLetter: any;
  history: PatientRecordData;
  inHouseProcedures: PatientProcedureInHouse[];
  letter: Letter;
  contacts: PatientContact[];
  managingOptom: PatientClinic;
  masterLetter: string; // this is the master template that can be edited and the changes will propagate into the child templates
  mode: "edit" | "display" = "edit";
  patient: Patient;
  record: PatientRecord;
  records: PatientRecord[];
  providers: GlPatientProviders;
  onLetterCreation: (args?: { letter: Letter }) => void;
  letterProviders: GlPatientProviders;
  letterContacts: PatientContact[];
  letterContactsWithoutReferrer: any;
  deletedReferrers = [];
  signature: IGlSignature;
  selectedTemplate: LetterType;
  numberOfLetters: number;
  letters: Letter[];
  letterStatuses = LETTER_STATUSES;
  letterStatus: string;
  selectedReferrer: number;
  selectedCC: any;
  letterManagingOptom: Letter;
  letterOptom: Letter;
  letterGp: Letter;
  letterReferrer: Letter;
  template: string;
  type: GlLetterType;
  user: User;
  shouldDisableBtn: boolean;
  letterTemplates: LetterType[] = [
    {
      id: 1,
      title: "Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/initial.html"),
    },
    {
      id: 2,
      title: "Review",
      key: "1",
      raw_template: require("../gl-letter-templates/review.html"),
    },
    {
      id: 3,
      title: "Post Procedure",
      key: "1",
      raw_template: require("../gl-letter-templates/post-procedure.html"),
    },
    {
      id: 4,
      title: "Post Operation",
      key: "1",
      raw_template: require("../gl-letter-templates/post-op.html"),
    },
    {
      id: 5,
      title: "Cataract New",
      key: "1",
      raw_template: require("../gl-letter-templates/cataract-new.html"),
    },
    {
      id: 6,
      title: "Retina New",
      key: "1",
      raw_template: require("../gl-letter-templates/retina-new.html"),
    },
    {
      id: 7,
      title: "Cataract Discharge",
      key: "1",
      raw_template: require("../gl-letter-templates/cataract-discharge.html"),
    },
    {
      id: 8,
      title: "Retina Review",
      key: "1",
      raw_template: require("../gl-letter-templates/retina-review.html"),
    },
    {
      id: 9,
      title: "Generic Review",
      key: "1",
      raw_template: require("../gl-letter-templates/generic.html"),
    },
    {
      id: 10,
      title: "Plastics Discharge",
      key: "1",
      raw_template: require("../gl-letter-templates/plastics-discharge.html"),
    },
    {
      id: 11,
      title: "Plastics Initial",
      key: "1",
      raw_template: require("../gl-letter-templates/plastics-initial.html"),
    },
    {
      id: 12,
      title: "Capsulotomy",
      key: "1",
      raw_template: require("../gl-letter-templates/capsulotomy.html"),
    },
    {
      id: 13,
      title: "Doctor Referral",
      key: "1",
      raw_template: require("../gl-letter-templates/doctor-referral.html"),
    },
    {
      id: 14,
      title: "Dry Eye",
      key: "1",
      raw_template: require("../gl-letter-templates/dry-eye.html"),
    },
  ];
  // Outputs
  letterDidChange: ({
    letter,
    notSaved,
  }: {
    letter: string;
    notSaved?: boolean;
  }) => void;

  compiledTemplate: string = "";
  letterTemplate: string = "";
  angularTemplate: JQLite;
  saveInProgress = false;
  deleteInProgress = false;
  letterForm?: angular.IFormController;
  saveLetterFromRecord: any;
  emailTo: string;
  subject: string;
  date: Date = new Date();

  constructor(
    private $compile: angular.ICompileService,
    private $controller: angular.IControllerService,
    private $q: angular.IQService,
    private $scope: angular.IScope,
    private $timeout: angular.ITimeoutService,
    private GlModelService: GlModelService,
    private LetterService: LetterService,
    public appendix: Appendix,
    private $window: angular.IWindowService,
    private $transitions: any
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.letter && this.letter) {
      this.compiledTemplate = this.letter.compiled_template;
    } else if (!this.compiledTemplate) {
      this.letterTemplateDidChange();
    }
    if (changes.providers || changes.contacts) {
      this.letterProviders = angular.copy(this.providers);
      this.letterContacts = angular.copy(this.contacts);
      if (this.letter?.addressees && !this.isAddresseesProvider()) {
        this.selectedReferrer = this.letter.addressees.findIndex(
          (addressee: PatientContact) => addressee.referrer === true
        );
        this.getCCList();
      }
      if (this.letter?.addressees && this.isAddresseesProvider()) {
        this.selectedReferrer = this.letter?.addressees?.referrer?.toString();
      } else {
        this.selectedReferrer = this.letterContacts?.findIndex(
          (addressee: PatientContact) => addressee.referrer === 1
        );
        this.changeReferrer();
      }
      this.letterContactsWithoutReferrer = omit(
        this.letterContacts,
        "referrer"
      );
    }
  }

  removeAddProvider(key: string) {
    if (this.letter.addressees[key].deleted) {
      this.letter.addressees[key].deleted = false;
    } else {
      this.letter.addressees[key].deleted = true;
    }
    return this.save();
  }

  shouldDisable(key: string) {
    return this.deletedReferrers.includes(key);
  }

  filterDropDown(referrer: any) {
    if (referrer != null) {
      return referrer;
    }
  }

  onLetterTypeChange(selectedTemplate: LetterType) {
    this.sortLetterContacts();
    this.template = selectedTemplate.raw_template;
    this.compiledTemplate = selectedTemplate.raw_template;
    this.letterTemplateDidChange();
  }

  $onInit() {
    this.$scope.$on(GL_LETTER_EVENT_SAVE, () => this.save());

    /**
     * Add an onExit hook and check if the letter form is dirty. If it is,
     * prompt the user to confirm if they want to leave the page without saving.
     */
    this.$transitions.onExit({}, () => {
      if (this.letterForm?.$dirty) {
        return window.confirm(
          `There are unsaved letter changes.
Are you sure you want to leave? 
Press OK to discard changes and Cancel to return and save the changes.`
        );
      }
    });
  }

  changeReferrer() {
    if (this.letterContacts?.length > 0) {
      if (this.selectedReferrer === -1) {
        this.letterContacts[0].referrer = true;
        this.selectedReferrer = 0;
      } else {
        this.letterContacts[this.selectedReferrer].referrer = true;
        const currentReferrer = this.letterContacts[this.selectedReferrer];
        this.letterContacts.map((lc) => {
          if (lc.data.id !== currentReferrer.data.id) {
            lc.referrer = 0;
          }
        });
      }
    }
  }

  getReferrer() {
    if (this.letter?.addressees) {
      const index = [this.letter.addressees].findIndex(
        (la) => la.referrer === true
      );
      return this.formatContactForAddressee(
        this.letter?.addressees[index]?.data
      );
    }
  }

  getCCList() {
    if (this.letter?.addressees) {
      const firstPart = this.letter.addressees.filter(
        (e) => e.referrer === true
      );
      const secondPart = this.letter.addressees.filter(
        (e) => e.referrer !== true
      );

      this.letter.addressees = firstPart.concat(secondPart);
      return this.letter.addressees;
    }
  }

  sortLetterContacts() {
    if (!this.letter?.addressees) {
      const firstPart = this.letterContacts.filter((e) => e.referrer === true);
      const secondPart = this.letterContacts.filter((e) => e.referrer !== true);
      const merged = firstPart.concat(secondPart);
      this.letterContacts = merged;
      return this.letterContacts;
    }
  }

  letterTemplateDidChange() {
    return this.$q.resolve().then(() => {
      const template = this.getLetterTemplate();
      if (template) {
        return this.compileTemplate(template);
      }
    });
  }

  changeLetterStatus(letter: Letter) {
    this.letter.status = letter.status;
    this.LetterService.update(letter);
  }

  addToLetters(x: Letter) {
    this.letters.push(x);
  }

  getLetterTemplate() {
    if (!this.template) {
      if (this.letter?.letter_template) {
        this.template = this.letter.letter_template;
      }
    }
    return this.template;
  }

  formatContactForAddressee(contact: Contact) {
    return this.LetterService.contactAddresseeNameFormat(contact);
  }

  compileTemplate(templateString: string) {
    const controller = this.$controller(LetterController);
    controller.letterType = this.type;
    controller.isReferrer = this.isReferrer();
    controller.managingOptom = this.managingOptom;
    controller.record = this.record;
    controller.records = this.records.filter((r) => r.id !== this.record?.id);
    controller.patient = this.patient;
    controller.history = this.history;
    controller.providers = this.letterProviders;
    controller.contacts = this.letterContacts;
    controller.selectedReferrer = this.selectedReferrer;
    controller.setDrops(this.drops);
    controller.setDrugs(this.drugs);
    controller.setInHouseProcedures(this.inHouseProcedures);
    controller.setExternalProcedures(this.externalProcedures);
    controller.setConsolidatedInjections(this.consolidatedInjections);
    controller.setConsolidatedInjectionsForLetter(this.consolidatedInjections);
    controller.setConsolidatedInjectionsDoneForLetter(
      this.consolidatedInjections
    );
    controller.user = this.user;
    controller.signature = this.signature;
    /**
     * the trick here is that for gl-letter-observations,
     * only the data will be parsed, so we borrow the current record as a
     * host and only use the data from it
     **/
    controller.consolidatedRecord = {
      ...this.record,
      data: this.GlModelService.getConsolidatedRecordFromHistory(
        // save comp time, take from records that have been filtered
        controller.records
      ),
    };

    const $scope = this.$scope.$new(true);
    extend($scope, { $ctrl: controller });
    // wrap the raw template in a div to get the entire node once compiled
    const template = `<div>${templateString}</div>`;
    this.angularTemplate = this.$compile(template)($scope);
    /**
     * Use $timeout to allow angular digest cycle to complete before the template
     * is fully compiled
     */

    return this.$timeout().then(() => {
      this.compiledTemplate = this.mergeMasterLetter();
      if (this.letterForm) {
        this.letterForm.$setDirty();
        this.ngOnChange();
      }
    });
  }

  mergeMasterLetter() {
    if (!this.masterLetter) {
      return this.angularTemplate.html();
    }
    const parser = new DOMParser();
    const masterTemplateDom = parser.parseFromString(
      this.masterLetter,
      "text/html"
    );

    // the letter template is based on the masterLetter, except with elements
    // with the .template-overwrite class applied on top
    const templateOverwriteElements = this.angularTemplate[0].querySelectorAll(
      ".template-overwrite"
    );
    templateOverwriteElements.forEach((el) => {
      // get the same element in the master letter

      const masterEl = masterTemplateDom.getElementById(el.id);
      if (masterEl) {
        masterEl.innerHTML = el.innerHTML;
      }
    });
    return masterTemplateDom.body.innerHTML;
  }

  ngOnChange() {
    if (isFunction(this.letterDidChange)) {
      this.letterDidChange({
        letter: this.compiledTemplate,
        notSaved: this.letterForm.$pristine ? false : true,
      });
    }
  }

  isReferrer() {
    if (this.type === "gp") {
      return (
        this.GlModelService.getFromRecordOrHistory(
          "providers.referrer",
          this.record.data,
          this.history
        ) === "gp"
      );
    } else if (this.type === "optometrist") {
      return (
        this.GlModelService.getFromRecordOrHistory(
          "providers.referrer",
          this.record.data,
          this.history
        ) === "optometrist"
      );
    } else if (this.type === "managing_optom") {
      return (
        this.GlModelService.getFromRecordOrHistory(
          "providers.referrer",
          this.record.data,
          this.history
        ) === "managing_optom"
      );
    } else {
      // this is a referrer letter, the referrer is true only if record.data.referrer is not set
      return !this.GlModelService.getFromRecordOrHistory(
        "providers.referrer",
        this.record.data,
        this.history
      );
    }
  }

  getProviderNameTooltip(referrer: GlProvider) {
    if (referrer.firstName && referrer.lastName) {
      return `${referrer.firstName + " " + referrer.lastName}`;
    } else if (referrer.firstName && !referrer.lastName) {
      return `${referrer.firstName}`;
    }
  }

  save() {
    this.saveInProgress = true;
    const letter: Partial<Letter> = {
      compiled_template: this.compiledTemplate,
      type: this.type,
      status: "created",
      clinic_id: this.user?.clinic_id,
    };
    if (this.letter?.addressees) {
      letter.addressees = this.getCCList();
    } else {
      letter.addressees = this.sortLetterContacts();
    }
    if (this.record?.id) {
      letter.patient_record_id = this.record.id;
    }
    if (this.template) {
      letter.letter_template = this.template;
    }
    if (this.type === "managing_optom" && this.managingOptom) {
      letter.clinic_id = this.managingOptom.clinic.id;
      letter.recipient_id = this.managingOptom.provider.id;
    }

    const promise = this.letter
      ? this.LetterService.update({
          ...this.letter,
          compiled_template: this.compiledTemplate,
        })
      : this.LetterService.createForPatient(letter);

    return promise
      .then(() => {
        this.letterDidChange({
          letter: this.compiledTemplate,
          notSaved: this.letterForm.$pristine ? false : true,
        });
      })
      .then(() => {
        if (this.letterForm) {
          this.letterForm.$setPristine();
        }
        if (isFunction(this.onLetterCreation)) {
          this.syncLetters(this.letter);
        }
        return this.LetterService.fetchAllForPatient();
      })
      .finally(() => (this.saveInProgress = false));
  }

  cancel() {
    this.letterForm.$setPristine();
    this.compiledTemplate = this.letter.compiled_template;
    this.ngOnChange();
  }

  delete() {
    this.deleteInProgress = true;
    this.LetterService.delete(this.letter)
      .then(() => this.LetterService.fetchAllForPatient())
      .finally(() => (this.deleteInProgress = false));
    if (isFunction(this.onLetterCreation)) {
      this.syncLetters();
    }
  }

  print(key?: string) {
    this.LetterService.openPrintLetterWindow(
      this.letter.patient_id || this.patient.id,
      [this.letter.id],
      true,
      key
    );
  }

  isLegacyLetter() {
    if (!this.letter?.addressees && !this.letterContacts) {
      return true;
    }
  }

  isAddresseesProvider() {
    if (this.letter?.addressees) {
      return (
        "gp" in this.letter.addressees ||
        "optometrist" in this.letter.addressees ||
        "referrerDetails" in this.letter.addressees
      );
    } else {
      return false;
    }
  }

  printAllRecipients() {
    if (!this.letter?.addressees) {
      this.LetterService.openPrintLetterWindowMultipleRecipients(
        this.letter.patient_id || this.patient.id,
        [this.letter.id],
        true,
        []
      );
    } else {
      this.LetterService.openPrintLetterWindowMultipleRecipients(
        this.letter.patient_id || this.patient.id,
        [this.letter.id],
        true,
        this.letterContacts || this.letter?.addressees
      );
    }
  }

  syncLetters(letter?: Letter) {
    this.onLetterCreation({ letter: letter });
  }

  singlePdfPreview(key?: string) {
    this.LetterService.openPdfLetterWindow(
      this.letter.patient_id || this.patient.id,
      [this.letter.id],
      true,
      key
    );
  }

  emailPdfPreview() {
    let confirm: boolean = false;
    if (this.patient.data.email_authority !== true) {
      confirm = this.$window.confirm(
        `${this.patient.name}` +
          " has not authorised Glauconet to send emails on their behalf. Do you still want to send this email?"
      );
    }
    if (confirm || this.patient.data.email_authority) {
      this.LetterService.openPdfForEmail(
        this.letter.patient_id || this.patient.id,
        [this.letter.id],
        true
      );
    }
  }

  getEmailAddressee() {
    if (this.letter.addressees) {
      const index = this.letter.addressees.findIndex(
        (la) => la.referrer === true
      );
      this.emailTo = this.letter.addressees[index].data.email;
      this.subject = `Patient: ${this.patient.name}`;
      const addressee = this.formatContactForAddressee(
        this.letter.addressees[index]?.data
      );
      return addressee.substring(0, addressee.indexOf("("));
    }
  }

  multiplePdfPreview() {
    if (!this.isAddresseesProvider()) {
      // to avoid breaking legacy code
      // eslint-disable-next-line no-var
      var currentProviders = angular
        .copy(this.letter.addressees)
        .map((e, i) => (e.deleted !== true ? i : ""))
        .filter(String);
    } else {
      currentProviders = Object.keys(angular.copy(this.providers));
    }
    currentProviders.forEach((key) => {
      if (key !== "referrer") {
        this.LetterService.openPdfLetterWindow(
          this.letter.patient_id || this.patient.id,
          [this.letter.id],
          true,
          key
        );
      }
    });
  }

  isEditMode() {
    return this.mode === "edit";
  }
}

export class GlLetter implements angular.IComponentOptions {
  static selector = "glLetter";
  static template = require("./gl-letter.html");
  static controller = GlLetterController;
  static bindings = {
    canDelete: "<?",
    consolidatedInjections: "<",
    consolidatedInjectionsForLetter: "<",
    consolidatedInjectionsDoneForLetter: "<",
    decidedReferrer: "<?",
    drops: "<",
    drugs: "<",
    externalProcedures: "<",
    gpLetter: "<?",
    history: "<",
    inHouseProcedures: "<",
    letter: "<",
    letterDidChange: "&?",
    managingOptom: "<",
    managingOptomLetter: "<?",
    masterLetter: "<?",
    mode: "<?",
    onLetterCreation: "&?",
    optometristLetter: "<?",
    contacts: "<?",
    patient: "<",
    providers: "<",
    record: "<",
    records: "<",
    referrerLetter: "<?",
    saveLetterFromRecord: "&?",
    signature: "<",
    type: "@",
    user: "<",
    date: "<",
  };
}
