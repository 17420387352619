// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { SettingsPageComponent } from "./settings";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.settings", {
    abstract: true,
    component: SettingsPageComponent.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.setting2", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(SettingsPageComponent.selector, SettingsPageComponent)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
