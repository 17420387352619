import * as braintreeWebDropIn from "braintree-web-drop-in";
import { has } from "lodash";
import {
  IPatientResource,
  PatientService,
} from "../../../services/patient.service";

class CreditCardController implements angular.IController, angular.IOnChanges {
  loading = false;
  patient: IPatientResource;
  braintreeInstance: braintreeWebDropIn.Dropin;
  braintreeReady: (arg: { instance: braintreeWebDropIn.Dropin }) => void;

  constructor(private PatientService: PatientService) {
    "ngInject";
  }

  ////////////////

  // $onInit = function() {};
  $onChanges(changesObj: angular.IOnChangesObject) {
    if (has(changesObj, "patient") && has(this.patient, "id")) {
      // initialise braintree drop in
      this.getBraintreeToken();
    } else if (changesObj.patient && this.patient.$promise) {
      this.patient.$promise.then(() => this.getBraintreeToken());
    }
  }
  // $onDestroy = function() {};

  getBraintreeToken() {
    this.loading = true;
    return this.PatientService.getBraintreeToken({
      patient_id: this.patient.id,
    })
      .catch(this.getClientTokenError.bind(this))
      .then((data) => {
        const token = data.token;

        return braintreeWebDropIn.create({
          authorization: token,
          container: "#checkout",
          locale: "en-AU",
          card: {
            cardholderName: {
              required: true,
            },
            overrides: {},
          },
        });
      })
      .catch(this.braintreeCreateError.bind(this))
      .then(this.braintreeOnCreate.bind(this))
      .finally(() => {
        this.loading = false;
      });
  }

  braintreeOnCreate(instance: braintreeWebDropIn.Dropin) {
    // Form is not submitted by default when paymentMethodNonceReceived is implemented
    this.braintreeInstance = instance;
    this.braintreeReady({ instance: instance });
  }

  getClientTokenError(error: Error) {
    console.error("An error occured fetching a client token", error);
  }

  braintreeCreateError(error: Error) {
    console.error("Braintree create error", error);
  }
}

export class CreditCardComponent implements angular.IComponentOptions {
  static selector = "creditCard";
  static template = require("./credit-card.html");
  static controller = CreditCardController;
  static bindings = {
    patient: "<",
    noPaymentMethodRequestable: "&",
    paymentMethodRequestable: "&",
    braintreeReady: "&",
  };
}
