import angular = require("angular");
import { PatientProcedureDrug } from "models/patient-procedure";
import { Appendix, TreatmentDisplayStatus } from "../services/appendix";

const appendix: Appendix = new Appendix();
export const pipeName = "drugRowClassType";
export const drugRowClassTypePipe: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (drug: PatientProcedureDrug): any => {
    const ALLERGIC_REACTION_DISCONTINUATION: string = "Allergic Reaction";
    const treatmentStatusClassMap: { [key in TreatmentDisplayStatus]: string } =
      appendix.getTreatmentStatusClasses();

    // regular
    if (!drug?.data.treatment_end_date) {
      return treatmentStatusClassMap.active;
    }

    // temporary
    if (drug.data.one_off) {
      return treatmentStatusClassMap.temporary;
    }

    // danger
    if (
      drug.data?.treatment_end_date &&
      drug.data?.discontinuation_reason &&
      drug.data?.discontinuation_reason.trim() ===
        ALLERGIC_REACTION_DISCONTINUATION
    ) {
      return treatmentStatusClassMap.critical;
    }

    if (
      drug.data.treatment_end_date &&
      drug.data.discontinuation_reason !== ALLERGIC_REACTION_DISCONTINUATION
    ) {
      return treatmentStatusClassMap.discontinued;
    }

    return treatmentStatusClassMap.active;
  };
};
