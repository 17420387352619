export interface GlErrorMessage {
  key: string;
  type: string;
  message: string;
  array_points?: (string | number)[];
}

const DRUG_ERROR_MESSAGES = {
  drugs: {
    root_required: "Route must be specified",
    quantity_required: "Quantity must be specified",
    dose_required: "Dose must be specified",
    frequency_required: "Frequency must be specified",
    repeats_required: "Repeats must be specified",
    is_empty: "Please select a drug",

    // generic
    create: "An error has occured whilst creating the drug, please try again",
    save: "An error has occured whilst saving the drug, please try again",
    delete: "An error has occured whilst deleting the drug, please try again",
  },
  favourite: {
    drug_exists:
      "Drug already exists in one of the favourites groups or user defaults, please refer to existing template. User will still be able to 'Create Drug'.",
    drug_in_global_group:
      "Drug is a part of a global group.\nOnly authorised users or creator will be able to edit and save the template, but regular users will be able to add them to their own favourite groups.",
    group_selection_empty:
      "Favourite drugs must be added into at least one favourite group to be saved.",
  },
};

const FAVOURITE_GROUP_ERROR_MESSAGE = {
  favourite_group: {
    general: {
      no_group_specified: "Group must be specified when creating or updating",
    },
    create: {
      group_name_exists:
        "Group name already exists, please use a different name",
      name_length: "Group name must be between 2 and 100 characters",
    },
    delete: {
      group_not_empty: "Please ensure favourite group is empty before deleting",
      global_group_primary_delete:
        "Main global favourite group cannot be deleted",
    },
  },
};

const PRESCRIPTION_ERROR_MESSAGES = {
  prescription: {
    save: {
      no_drugs: "Prescriptions must include at least one drug",
    },
    create: {
      selection: "Error creating prescriptions from all, please try again.",
      all: "Error creating prescriptions from selection, please try again.",
      single: "Error creating prescription, please try again.",
    },
    print: {
      selection: "",
      all: "",
    },
  },
};

const GENERIC_ERROR_MESSAGES = {
  error: {
    please_try_again: "An error has occured, please try again",
    refresh_try_again: "Please refresh the page and try again",
  },
};

const DIAGNOSIS_ERROR_MESSAGES = {
  osdi: {
    value: "OSDI score must be a number between 0 and 100",
  },
  autofill: {
    diagnosis_exists: `Autofilled diagnosis already exists, will not create a new row.`,
  },
  copy: {
    diagnosis: {
      doesnt_exist: "Cannot copy an empty diagnosis over",
    },
    multi_select: {
      cannot_copy_to_non_multiselect:
        "Target side to copy to does not allow for multiple diagnoses",
    },
  },
};

const OBSERVATIONS_ERROR_MESSAGES = {
  autofill: {
    observation_duplicate: `Duplicate autofill observation selected, will not autofill`,
  },
  selection: {
    multi_select_not_allowed: {
      left: `Unable to copy value, please select a different value on the left observations first.`,
      right: `Unable to copy value, please select a different value on the right observations first.`,
    },
  },
};

const DROPS_ERROR_MESSAGES = {
  edit: {
    unsign_before_changing:
      "Please un-sign the Post Dilation IOP readings before making any changes",
  },
};

const RECORDS_ERROR_MESSAGES = {
  save: {
    autosave: {
      error: "Record autosave failed, data might be lost if not manually saved."
    },
    error_try_again: "Could not save record, data might be lost if you leave the page. Please try again",
  },
  sign: {
    error_try_again: "Could not sign record, data might be lost if you leave the page. Please try again",
  },
  re_open: {
    error_try_again: "Could not re-open record. Please try again",

  },
  state: {
    error_try_again: "Could not change record state, data might be lost if you leave the page. Please try again",
  },
  access_lock: {
    override: {
      error_try_again: "Could not override error lock. Please try again",
    }
  },
  injection: {
    create: {
      error_try_again: "Error creating new injection, please try again."
    }
  },
  dilation_record: {
    sign: {
      error: "Error signing dilation review, please try again",
    },
    re_open: {
      create_before_sign:
        "Dilation record must be created first before signing, please try again.",
    },
  },
};

const COMBINED_ERROR_MESSAGES = {
  ...DRUG_ERROR_MESSAGES,
  ...PRESCRIPTION_ERROR_MESSAGES,
  ...GENERIC_ERROR_MESSAGES,
  ...FAVOURITE_GROUP_ERROR_MESSAGE,
  ...DIAGNOSIS_ERROR_MESSAGES,
  ...OBSERVATIONS_ERROR_MESSAGES,
  ...DROPS_ERROR_MESSAGES,
  ...RECORDS_ERROR_MESSAGES,
};

export class ErrorAppendix {
  static injectionName = "ErrorAppendix";

  getDrugErrorMessages() {
    return DRUG_ERROR_MESSAGES;
  }

  getFavouriteGroupErrorMessage() {
    return FAVOURITE_GROUP_ERROR_MESSAGE;
  }

  getPrescriptionErrorMessages() {
    return PRESCRIPTION_ERROR_MESSAGES;
  }

  getGenericErrorMessages() {
    return GENERIC_ERROR_MESSAGES;
  }

  getDiagnosisErrorMessages() {
    return DIAGNOSIS_ERROR_MESSAGES;
  }

  getObservationErrorMessages() {
    return OBSERVATIONS_ERROR_MESSAGES;
  }

  getDropsErrorMessages() {
    return DROPS_ERROR_MESSAGES;
  }

  getRecordsErrorMessages() {
    return RECORDS_ERROR_MESSAGES;
  }

  getAllErrorMessages() {
    return COMBINED_ERROR_MESSAGES;
  }
}
