import { last } from "lodash";
import * as moment from "moment";
import { Letter } from "../../../../../models/letter.model";
import { LetterService } from "../../../services/letter.service";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import "./patient-letters.scss";

class PatientLettersController
  implements angular.IController, angular.IOnChanges
{
  letters: Letter[];
  letter: Letter;
  collapsed: boolean;
  debug = false;
  editCurrentLetter: (args?: { letter: Letter }) => void;
  showPrint = false;
  dates: string[];
  canEditLetter: boolean;
  selectedDate: string;
  lettersGroupedByDate: Map<string, Letter[]>;
  lettersForSelectedDate: Letter[];
  activeTabIndex = 0;
  constructor(
    public PatientRecordService: PatientRecordService,
    private LetterService: LetterService,
    private $timeout: angular.ITimeoutService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.letters && this.letters) {
      this.sortLettersByDate(this.letters);
      this.letter = this.lettersGroupedByDate[0];
    }
  }

  setLetter(selectedLetter: Letter) {
    this.letter = selectedLetter;

    this.editCurrentLetter({ letter: this.letter });
  }

  changeDate(date: string) {
    this.selectedDate = date;
    this.updateLetters(date);
    const indexOfSelectedLetter = this.lettersForSelectedDate?.findIndex(
      (letter) => {
        return letter.id === this.letter?.id;
      }
    );
    // This is a nasty hack to automatically select the letter tab when changing tabs
    this.$timeout().then(() => {
      if (indexOfSelectedLetter === -1) {
        this.activeTabIndex = 0;
      } else {
        this.activeTabIndex = indexOfSelectedLetter;
      }
    });
  }

  onGeneratePdf($letter: Letter, key?: string) {
    this.LetterService.openPdfLetterWindow(
      $letter.patient_id,
      [$letter.id],
      true,
      key
    );
  }

  onPrint($letter: Letter, key?: string) {
    this.LetterService.openPrintLetterWindow(
      $letter.patient_id,
      [$letter.id],
      true,
      key
    );
  }

  sortLettersByDate(letters: Letter[]) {
    this.lettersGroupedByDate = letters.reduce((map, l) => {
      const date = moment(l.created_at).format("L");
      const thisDaysLetters = map.get(date) || [];
      map.set(date, [...thisDaysLetters, l]);
      return map;
    }, new Map<string, Letter[]>());
    this.dates = Array.from(this.lettersGroupedByDate.keys());
    this.selectedDate = last(this.dates);
    this.changeDate(this.selectedDate);
  }

  updateLetters(dateKey: string) {
    this.lettersForSelectedDate = this.lettersGroupedByDate.get(dateKey);
  }

  // tabWasSelected(letterId: number) {
  //   this.selectedLetter = letterNumber;
  // }

  dateKeyToDate(dateKey: string) {
    return moment(dateKey, "L").toDate();
  }
}

export class PatientLetters implements angular.IComponentOptions {
  static selector = "patientLetters";
  static template = require("./patient-letters.html");
  static controller = PatientLettersController;
  static bindings = {
    letters: "<",
    editCurrentLetter: "&?",
    canEditLetter: "<",
  };
}
