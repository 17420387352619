import "./pliny-logo.component.scss";

export class PlinyLogoComponent implements angular.IComponentOptions {
  static selector = "glPlinyLogo";
  static template = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 130 157" style="enable-background:new 0 0 130 157;" xml:space="preserve">
  <g>
      <rect x="13.2" fill="white" y="18.2" width="27.5" height="121.7"/>
      <path fill="white" d="M76.7,18.2h-17v27h14.4c8.2,0,13.4,4.4,13.4,12c0,7.6-5.1,12-13.4,12H59.7v27h17c24.4,0,38.5-19.1,38.5-39.1
          C115.2,37.3,101.1,18.2,76.7,18.2z" />
  </g>
  </svg>
  
      `;
}
