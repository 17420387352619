import { PatientProcedureDrug } from "../../../../../models/patient-procedure";

class GlLetterDrugController implements angular.IController {
  drug: PatientProcedureDrug;
}

export class GlLetterDrugComponent implements angular.IComponentOptions {
  static selector = "glLetterDrug";
  static template = require("./gl-letter-drug.html");
  static controller = GlLetterDrugController;
  static bindings = {
    drug: "<",
  };
}
