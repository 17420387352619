/**
 * Import the Component styles
 */
import { AuthService } from "../../core/services/auth.service";
import "./main.scss";

declare const BUILD_DATE: number;

class MainPageController {
  public BUILD_DATE = BUILD_DATE;
  constructor(
    public $state: angular.ui.IStateService,
    private AuthService: AuthService
  ) {
    "ngInject";
  }

  getUser() {
    return this.AuthService.user;
  }
}

export class MainPage implements angular.IComponentOptions {
  static selector = "glMain";
  static template = require("./main.html");
  static controller = MainPageController;
}
