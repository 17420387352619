import { AuthService } from "app/core/services/auth.service";
import { DiagnosisService } from "app/core/services/diagnosis.service";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { ValueAutofillService } from "app/core/services/value-autofill/value-autofill.service";
import { defaultsDeep, isEmpty, isFunction, isNil } from "lodash";
import { IGlSideBilateral } from "models/gl-side.model";
import {
  GlBilateral,
  GlDiagnosis,
  GlLens,
  GlLensObservation,
  PatientRecordData,
} from "models/patient-record.model";
import {
  Appendix,
  IGlOption,
  IGlOptionExtra,
} from "../../../services/appendix";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../gl-form-controller";
export class PosteriorLensController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // CONSTANTS
  PARENT_KEY: string = "lens"; // actual key
  PARENT_KEY_APPENDIX: string = "lensPhakic"; // internal reference

  // @Input()
  enable: boolean = true;
  record: PatientRecordData;
  recordId: number = this.$stateParams.recordId;
  side: IGlSideBilateral;
  // Controller Properties
  lensOptions = this.appendix.get(this.PARENT_KEY_APPENDIX);
  cataractTypeOptions = this.appendix.get("cataractType", true); // Pass true to signify we don't want to sort the returned list;
  IOLTypeOptions = this.appendix.get("IOLType");
  quantifierOptions = this.appendix.get("quantifier");

  // form
  posteriorLensForm: angular.IFormController;

  // passed down from bilateral-posterior-lens
  timestampKeyArrayObservations: GlBilateral<number[]> = {};
  timestampKeyArrayStatus: GlBilateral<number[]> = { left: [], right: [] };

  lensAttributes: string[] = this.PatientRecordService.getLensAttributes();
  diagnosisErrors = this.ErrorAppendix.getDiagnosisErrorMessages();

  // prefills are always set on init once
  prefillsSet: boolean = false;

  onChangeAutofill: (args: {
    section: "status" | "observation";
    side: IGlSideBilateral;
    timestampKey: number;
    previousLensData: GlLens;
    currentStatus: IGlOption;
    index: number;
  }) => void;

  onChangeAutofillObservation: (args: {
    side: IGlSideBilateral;
    previousLensData: GlLens;
    currentStatus: IGlOption;
    observationIndex: number;
  }) => void;

  undoAutofill: (arg: {
    side: IGlSideBilateral;
    timestampKey: number;
    observation: IGlOption;
  }) => void;

  acceptAutofill: (arg: {
    side: IGlSideBilateral;
    timestampKey: number;
    observation: IGlOption;
  }) => void;

  // prefil
  onPrefill: (arg: {
    section: "status" | "observation";
    side: IGlSideBilateral;
    timestampKey: number;
    currentStatus: IGlOption;
    index: number;
  }) => void;

  // dirty
  formDidChange: () => void;

  constructor(
    private appendix: Appendix,
    private PatientRecordService: PatientRecordService,
    private ValueAutofillService: ValueAutofillService,
    private DiagnosisService: DiagnosisService,
    private ErrorAppendix: ErrorAppendix,
    private AuthService: AuthService,
    private toastr: angular.toastr.IToastrService,
    private $stateParams: angular.ui.IStateParamsService
  ) {
    "ngInject";
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $onChanges(changes: angular.IOnChangesObject) {
    if (this.isEditMode() && this.record) {
      const defaults = {
        lens: {
          observations: {},
        },
      };
      defaultsDeep(this.record, defaults);

      // status
      // instantiate array
      if (isEmpty(this.timestampKeyArrayStatus[this.side])) {
        this.timestampKeyArrayStatus[this.side] = [null];
      }

      // observations
      if (isEmpty(this.timestampKeyArrayObservations[this.side])) {
        this.timestampKeyArrayObservations[this.side] =
          this.record.lens.observations?.[this.side]?.map(() => null) ?? [];
      }

      // this works on init once, so we need a state flag
      this.setPrefillsForLensStatus(this.record);
    }
  }

  /* 
    on init or after gl model initiated, check if any of the 
    observations and diagnosis that are autofills are there

    and immediately add them
  */
  setPrefillsForLensStatus(recordData: PatientRecordData) {
    if (this.prefillsSet) {
      return;
    }
    // else check for each side

    this.setLensStatusPrefillsForSide(recordData, "left");
    this.setLensStatusPrefillsForSide(recordData, "right");

    // set true
    this.prefillsSet = true;
    return;
  }

  // helper
  setLensStatusPrefillsForSide(
    recordData: PatientRecordData,
    side: IGlSideBilateral
  ) {
    // dont bother if empty
    const diagnosisArray: GlBilateral<GlDiagnosis[]> =
      recordData?.management?.diagnosis_array;
    const lensStatus: GlBilateral<IGlOption> = recordData?.lens.status;
    const option: IGlOption = lensStatus?.[side];

    // ignore if empty
    if (isNil(lensStatus)) {
      return;
    }

    if (isNil(option) || isEmpty(option)) {
      return;
    }

    // if not a prefill option dont bother
    if (
      !this.appendix.isObservationOptionPrefill(
        this.PARENT_KEY_APPENDIX,
        option?.key
      )
    ) {
      return;
    }

    // double check if this has been processed already
    // only for CATARACT for now
    if (this.isOptionAutofillActive(option, "status", 0)) {
      return;
    }
    // else check diagnosis and find index
    const foundDiagnosisIndex =
      this.PatientRecordService.getIndexOfDiagnosisByOption(
        diagnosisArray,
        side,
        option
      );

    // check if found
    if (foundDiagnosisIndex === -1) {
      return;
    }

    // if found then do as a prefill
    const timestampKey: number = this.ValueAutofillService.createKeyTimestamp();

    this.onPrefill({
      section: "status",
      side,
      timestampKey,
      currentStatus: option,
      index: 0,
    });
  }

  // EXPERIMENTAL
  // for autofill before confirming
  experimentalFeaturesEnabled() {
    return this.AuthService.experimentalFeaturesEnabled();
  }

  // autofill handler
  handleAutofillSide(previousLensData: GlLens) {
    // if enabled only
    if (!this.enable) {
      return;
    }

    const currentStatus: IGlOption = this.record.lens.status[this.side];
    const currentStatusExtra: IGlOptionExtra =
      this.appendix.getExtraWhereKey(this.PARENT_KEY, currentStatus?.key) ??
      null;

    const previousStatus: IGlOption = previousLensData?.status?.[this.side];
    const previousStatusExtra: IGlOptionExtra =
      this.appendix.getExtraWhereKey(this.PARENT_KEY, previousStatus?.key) ??
      null;

    // if old value is autofill make sure we reverse that
    if (
      currentStatus &&
      previousStatus &&
      previousStatusExtra?.autofill &&
      this.isOptionAutofillActive(previousStatus, "status", 0)
    ) {
      this._undoAutofillForDiagnosisSide(previousStatusExtra);
    }
    // otherwise if current value is change to an autofill value
    // continue
    if (
      !isNil(previousStatus) &&
      currentStatusExtra?.autofill &&
      isFunction(this.onChangeAutofill)
    ) {
      // for element refererncing: lens.status will not have an index and will
      // be 0 by default
      const timestampKey: number =
        this.ValueAutofillService.createKeyTimestamp();
      this.onChangeAutofill({
        section: "status",
        side: this.side,
        timestampKey,
        previousLensData,
        currentStatus,
        index: 0,
      });
    }
  }

  // second level observation things
  lensObservationDidChange(
    index: number,
    selectedObservation: IGlOption,
    previousLensData: GlLens
  ) {
    // // EXPERIMENTAL
    // // if enabled only use autofill
    // if (!this.experimentalFeaturesEnabled()) {
    //   return;
    // }

    // if disabled dont bother
    if (!this.enable) {
      return;
    }

    // selected observation requires a specific check on the key used
    const selectedObservationExtra: IGlOptionExtra =
      this.appendix.getExtraWhereKey(
        this.getSelectedOptionsObservationKey(),
        selectedObservation?.key
      );

    const previousObservation: GlLensObservation =
      previousLensData?.observations?.[this.side]?.[index] ?? null;
    const previousObservationExtra: IGlOptionExtra =
      this.appendix.getExtraWhereKey(
        this.getSelectedOptionsObservationKey(),
        previousObservation?.type?.key
      );

    // if target observation is defined already just ignore
    const existingObservations: GlLensObservation[] =
      this.record.lens?.observations?.[this.side]?.filter(
        (o) => o?.type?.key === selectedObservation?.key
      ) ?? [];
    if (
      existingObservations?.length > 1 &&
      selectedObservationExtra?.autofill
    ) {
      // this is to prevent screen overload
      return this.ValueAutofillService.shouldShowAutofillWarnings()
        ? this.toastr.info(this.diagnosisErrors.autofill.diagnosis_exists)
        : null;
    }

    // if previous was autofill undo that first
    if (
      previousObservation &&
      previousObservationExtra?.autofill &&
      selectedObservation &&
      this.isOptionAutofillActive(
        previousObservation?.type,
        "observations",
        index
      )
    ) {
      this._undoAutofillForDiagnosisSide(previousObservationExtra);
    }

    // otherwise check and autofill
    if (
      selectedObservationExtra?.autofill &&
      isFunction(this.onChangeAutofill)
    ) {
      // create timestamp for referencing
      const timestampKey: number =
        this.ValueAutofillService.createKeyTimestamp();
      this.onChangeAutofill({
        section: "observation",
        side: this.side,
        timestampKey: timestampKey,
        previousLensData,
        currentStatus: selectedObservation,
        index,
      });
    }
  }

  // accept autofill for status
  handleAcceptAutofillStatus(observation: IGlOption) {
    if (isFunction(this.acceptAutofill)) {
      const timestampKey = this.ValueAutofillService.getKeyTimestampFromIndex({
        timestampArray: this.timestampKeyArrayStatus,
        side: this.side,
        index: 0,
      });
      this.acceptAutofill({ side: this.side, timestampKey, observation });
    }
  }

  // observation
  handleAcceptAutofillObservation(observation: IGlOption, index: number) {
    if (isFunction(this.acceptAutofill)) {
      const timestampKey = this.ValueAutofillService.getKeyTimestampFromIndex({
        timestampArray: this.timestampKeyArrayObservations,
        side: this.side,
        index,
      });
      this.acceptAutofill({ side: this.side, timestampKey, observation });

      // if target observation is defined already cancel autofill
      if (!isNil(this.record.lens?.observations[this.side][index]?.type)) {
        this.ValueAutofillService.removeAutofillValueByKeyAndSide({
          parent_key: `${this.PARENT_KEY}.observations`,
          option_key: observation.key,
          side: this.side,
          timestamp_key: timestampKey,
        });
      }
    }
  }

  // undo autofill
  handleUndoAutofill(
    section: "status" | "observation",
    observation: IGlOption,
    index?: number
  ) {
    if (isFunction(this.undoAutofill)) {
      const timestampArray: GlBilateral<number[]> =
        section === "status"
          ? this.timestampKeyArrayStatus
          : this.timestampKeyArrayObservations;

      const timestampKey: number =
        this.ValueAutofillService.getKeyTimestampFromIndex({
          timestampArray,
          side: this.side,
          index: index ?? 0,
        });

      // undo autofill and timestamp
      this.undoAutofill({ side: this.side, timestampKey, observation });
      this.ValueAutofillService.removeKeyTimestampByIndex({
        timestampArray,
        side: this.side,
        index: index ?? 0,
      });
    }
  }

  // lens did change to trigger sanitisation
  lensDidChange(previousLensData: GlLens) {
    if (!this.record.lens.observations) {
      this.record.lens.observations = {};
    }
    // set changes autofill is required
    this.handleAutofillSide(previousLensData);

    // clean options
    this.sanitizeLensObservations();
  }

  // set depending on status/observation
  setTimestampKey(section: "status" | "observation", index?: number) {
    this.ValueAutofillService.setKeyTimestampByIndex({
      timestampArray:
        section === "status"
          ? this.timestampKeyArrayStatus
          : this.timestampKeyArrayObservations,
      side: this.side,
      index: index ?? 0,
    });
  }

  getSelectedOptions() {
    if (this.record.lens?.status[this.side]) {
      if (this.lensStatusIsIol()) {
        return this.IOLTypeOptions;
      } else if (this.lensStatusIsCataract()) {
        return this.cataractTypeOptions;
      }
      return null;
    } else {
      return null;
    }
  }

  getSelectedOptionsObservationKey() {
    if (this.record.lens?.status[this.side]) {
      if (this.lensStatusIsIol()) {
        return "IOLType";
      } else if (this.lensStatusIsCataract()) {
        return "cataractType";
      }
      return null;
    } else {
      return null;
    }
  }

  shouldShowObservations() {
    if (this.record.lens?.status[this.side]) {
      return !["Clear", "Aphakic", "Other", "Not Examined"].includes(
        this.getLensStatus().name
      );
    } else {
      return false;
    }
  }

  shouldShowDescription(observation: any) {
    return ["other", "dislocated", "subluxed"].includes(observation.type.key);
  }

  insertRow(index: number) {
    this.record.lens.observations[this.side].splice(index + 1, 0, {});
    this.posteriorLensOnChange();
  }

  removeRow(index: number) {
    const observationToDelete: GlLensObservation =
      this.record.lens.observations[this.side][index];
    if (
      this.isOptionAutofillActive(
        observationToDelete.type,
        "observations",
        index
      )
    ) {
      this._undoAutofillForDiagnosisSide(observationToDelete.type);
    }
    this.record.lens.observations[this.side].splice(index, 1);
    this.posteriorLensOnChange();

    this.ValueAutofillService.removeKeyTimestampByIndex({
      timestampArray: this.timestampKeyArrayObservations,
      index,
      side: this.side,
    });
  }

  // form did change
  posteriorLensOnChange() {
    this.posteriorLensForm.$setDirty();
    if (isFunction(this.formDidChange)) {
      this.formDidChange();
    }
  }

  cleanObservation(observation: any) {
    // this allows this component to work with both v1 legacy observations
    // and v2 new observations.
    if (!observation?.cataractType || !observation.IOLType) {
      // this is a v2 observation
      return observation;
    }
    const observationType = this.lensStatusIsCataract()
      ? observation.cataractType
      : observation.IOLType;
    return { type: observationType };
  }

  lensStatusIsCataract() {
    return this.getLensStatus().name === "Cataract";
  }

  lensStatusIsIol() {
    const lensStatus = this.getLensStatus();
    return ["ACIOL", "PCIOL", "Sulcus IOL"].includes(lensStatus.name);
  }

  // AUTOFILL ACTIVE?
  isOptionAutofillActive(
    option: IGlOption,
    lensKey: "status" | "observations" = "status",
    index?: number
  ) {
    if (isNil(option)) {
      return;
    }

    // CHECK SPECIAL CASE
    // for lens we have to differentiate between status and obseravations
    switch (lensKey) {
      // if status check immediately
      case "status":
        const statusTimestamp: number =
          this.ValueAutofillService.getKeyTimestampFromIndex({
            timestampArray: this.timestampKeyArrayStatus,
            side: this.side,
            index: index ?? 0,
          });
        // console.log(
        //   "lens autofill",
        //   option,
        //   this.timestampKeyArrayStatus,
        //   statusTimestamp
        // );
        return this.ValueAutofillService.getAutofillValueByKeyAndSide({
          parent_key: this.PARENT_KEY,
          option_key: option?.key,
          side: this.side,
          timestamp_key: statusTimestamp,
        });

      // if observation check multiples
      case "observations":
        // check if this is the second one
        // otherwise check
        const observationTimestamp: number =
          this.ValueAutofillService.getKeyTimestampFromIndex({
            timestampArray: this.timestampKeyArrayObservations,
            side: this.side,
            index,
          });

        // console.log(
        //   "lens autofill obs",
        //   option,
        //   this.timestampKeyArrayObservations,
        //   observationTimestamp,
        //   index,
        //   this.ValueAutofillService.getAutofillStatusStack()
        // );

        return this.ValueAutofillService.getAutofillValueByKeyAndSide({
          parent_key: this.PARENT_KEY,
          option_key: option?.key,
          side: this.side,
          timestamp_key: observationTimestamp,
        });

      // if not applicable just continue
      default:
        return false;
    }
  }

  private _undoAutofillForDiagnosisSide(option: IGlOption) {
    const relatedDiagnosis: GlDiagnosis =
      this.DiagnosisService.getDiagnosisAutofillMapping(option.key);

    this.PatientRecordService.undoAutofillForDiagnosisSideOnly({
      record: this.record,
      // parent_key: `${this.PARENT_KEY}.status`,
      side: this.side,
      diagnosisKey:
        this.DiagnosisService.convertDiagnosisOptionToKey(relatedDiagnosis) ??
        option.key,
    });
  }

  private sanitizeLensObservations() {
    // options can be applied

    // applicable observations
    const applicableObservationOptions = this.getSelectedOptions();
    if (applicableObservationOptions) {
      const observations = this.record.lens.observations[this.side];
      // filter them out
      const filteredObservations = (observations || []).filter((o) =>
        applicableObservationOptions.some((ob) => ob.key === o?.type?.key)
      );

      // if the filtered length isnt the same as the curren observation length
      // undo autofill for observations
      // we use a flag here as the timestamps are dependent on the new state of the observations
      let timestampFlag: boolean = false;
      if (filteredObservations?.length !== observations?.length) {
        this._undoAutofillForObservations();
        timestampFlag = true;
      }

      // filtered observations apply
      this.record.lens.observations[this.side] =
        filteredObservations.length > 0 ? filteredObservations : [{}];

      // flag
      if (timestampFlag) {
        // apply to timestamps
        this.timestampKeyArrayObservations[this.side] =
          this.record.lens.observations[this.side]?.map(() => null) ?? [];
      }
    } else {
      this._undoAutofillForObservations();
    }
  }

  private _undoAutofillForObservations() {
    const observations = this.record.lens.observations[this.side];

    // otherwise there are none and cancel autofill
    // check if any one of them has an observation autofill and whether that should be retained
    const filteredActiveAutofillObservations: GlLensObservation[] = (
      observations || []
    ).filter((o, i) => this.isOptionAutofillActive(o?.type, "observations", i));
    // if this isnt empty then undo autofill for the observations
    for (const obs of filteredActiveAutofillObservations) {
      this._undoAutofillForDiagnosisSide(obs?.type);
    }
  }

  private getLensStatus() {
    return this.record.lens.status[this.side];
  }
}

export class PosteriorLens implements angular.IComponentOptions {
  static selector = "posteriorLens";
  static template = require("./posterior-lens.html");
  static controller = PosteriorLensController;
  static bindings = {
    enable: "<",
    record: "<",
    side: "@",
    onChangeAutofill: "&",
    onChangeAutofillObservation: "&",
    undoAutofill: "&",
    acceptAutofill: "&",
    timestampKeyArrayObservations: "<",
    timestampKeyArrayStatus: "<",
    formDidChange: "&",
    onPrefill: "&",
    ...GlFormControllerBindings,
  };
}
