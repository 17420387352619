import moment = require("moment");

export const pipeName = "weeksAgo";
export function weeksAgoPipe() {
  "ngInject";
  return (injectionDateString: string) => {
    if (!injectionDateString) {
      return;
    }
    const injectionDate = moment(injectionDateString);
    const now = moment();
    const diffDays = now.diff(injectionDate, "days");
    return Math.round(diffDays / 7);
  };
}
