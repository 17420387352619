// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { ContactMigrationPage } from "./contact-migration";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.contact-migration", {
    url: "/contact-migration",
    component: ContactMigrationPage.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.contact-migration", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(ContactMigrationPage.selector, ContactMigrationPage)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
