import { LayoutDimension } from "puppeteer";

export class PdfPrintService {
  static injectionName = "PdfPrintService";
  constructor(
    private $http: angular.IHttpService,
    private $location: angular.ILocationService
  ) {
    "ngInject";
  }

  isPrintUrl() {
    const path: string = this.$location.path();
    const regex: RegExp = new RegExp(/[0-9]+\/print/);
    return regex.test(path);
  }

  print({
    headerData,
    footerData,
    pageData,
    background = false,
    margin,
  }: {
    headerData: string;
    footerData: string;
    pageData: string;
    background?: boolean;
    margin?: Partial<{
      top: LayoutDimension;
      left: LayoutDimension;
      right: LayoutDimension;
      bottom: LayoutDimension;
    }>;
  }) {
    const url =
      "https://flvn7ys47d.execute-api.ap-southeast-2.amazonaws.com/dev/pdf";
    return this.$http
      .post<Blob>(
        url,
        {
          html: `<!DOCTYPE html><html>${pageData}</html>`,
          options: {
            margin: {
              top: "50mm",
              left: "10mm",
              right: "10mm",
              bottom: "25mm",
              ...margin,
            },
            displayHeaderFooter: true,
            headerTemplate: headerData,
            footerTemplate: footerData,
            printBackground: background,
          },
        },
        {
          responseType: "blob",
          headers: {
            accept: "application/pdf",
          },
        }
      )
      .then((response) => response.data);
  }
}
