import {
  cloneDeep,
  defaults,
  defaultsDeep,
  get,
  isNil,
  isString,
} from "lodash";
import { IGlSideOrder } from "models/gl-side-order.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import { parseServerDate } from "../../../../../lib/parse_server_date";
import { IDayProcedureDetail } from "../../../../../models/gl-in-house-procedure";
import { IGlSide } from "../../../../../models/gl-side.model";
import { PatientProcedureInHouse } from "../../../../../models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { Appendix, IGlButtonOption } from "../../../services/appendix";
import { PatientProcedureService } from "../../../services/patient-procedure.service";
import { GlFormController } from "../../gl-form-controller";
import "./day-procedures.scss";
export class DayProceduresController
  extends GlFormController
  implements angular.IComponentController, angular.IOnChanges
{
  record: PatientRecord;
  procedures: PatientProcedureInHouse[];
  proceduresToDisplay: PatientProcedureInHouse[];
  nameOptions = this.appendix.get("dayProcedures");
  showEditForm = false;
  editModel: PatientProcedureInHouse;
  saveInProgress = false;
  deleteInProgress = false;
  historicalEditableOnly = false;
  openDatePicker = false;

  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  // proxy to allow handling of left_right and right_left
  editModelEyeSide: IGlSide | IGlSideOrder;

  constructor(
    public appendix: Appendix,
    private $window: angular.IWindowService,
    private PatientProcedureService: PatientProcedureService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if ((changes.procedures || changes.record) && this.procedures) {
      // if we pass in a specific record, only show procedures that were created in this record, otherwise show all
      this.proceduresToDisplay = this.record
        ? this.procedures.filter(
            (p) => p.data.completed_in_record_id === this.record?.id
          )
        : this.procedures;
    }
  }

  edit(procedure: PatientProcedureInHouse) {
    this.editModel = cloneDeep(procedure);
    defaultsDeep(this.editModel, {
      data: {
        left: {
          routine: true,
        },
        right: {
          routine: true,
        },
      },
    });
    if (this.historicalEditableOnly) {
      this.editModel.procedure_date = this.parseDateString(
        this.editModel.procedure_date
      );
    }

    // LEGACY ON INIT
    // all legacy data will be converted to right_left order since thats the default
    if (
      this.editModel.data.eye === "both" &&
      isNil(this.editModel?.data?.order)
    ) {
      defaults(this.editModel.data, { order: "right_left" });
    }

    // update eye side based on order or eye
    this.editModelEyeSide =
      this?.editModel?.data?.order ?? this.editModel?.data?.eye;

    this.showEditForm = true;
  }

  add() {
    const newProcedure: PatientProcedureInHouse = {
      type: "inHouse",
      data: {
        eye: "both" as IGlSide,
        left: {
          routine: true,
        },
        right: {
          routine: true,
        },
        completed_in_record_id: this.record?.id,
        nameAppendix: null,
      },
      procedure_date: new Date(),
      record_id: null,
      status: null,
      created_at: null,
      updated_at: null,
    };
    this.editModel = newProcedure;
    this.showEditForm = true;
  }

  hasSide(procedure: PatientProcedureInHouse, side: IGlSide) {
    if (!procedure?.data) {
      return false;
    }
    return ["both", "left_right", "right_left", side].includes(
      procedure.data.eye
    );
  }

  isLaser(procedure: PatientProcedureInHouse) {
    const data = procedure.data;
    return data?.nameAppendix && data.nameAppendix.type === "Laser";
  }

  getDescriptionPlaceholder(procedure: PatientProcedureInHouse) {
    return this.isLaser(procedure) ? "Settings" : "Description";
  }

  eyeDidChange(option: IGlButtonOption) {
    // quick check on eye side to handle order edge case
    this.eyeOrderOnSelect(option);

    const eyeDefaults = {
      routine: true,
    };
    const isLeft = this.isLeft(this.editModel);
    const isRight = this.isRight(this.editModel);
    if (isLeft) {
      defaultsDeep(this.editModel.data, { left: eyeDefaults });
    }
    if (isRight) {
      defaultsDeep(this.editModel.data, { right: eyeDefaults });
    }
    if (!isLeft) {
      delete this.editModel.data.left;
    }
    if (!isRight) {
      delete this.editModel.data.right;
    }
  }

  eyeOrderOnSelect(option: IGlButtonOption) {
    // if left/right or right/left
    if (option?.secondary_key === "both") {
      // set eye and option appropriately
      this.editModel.data.eye = "both";
      this.editModel.data.order = option?.key as IGlSideOrder;
    } else {
      // otherwise set as usual
      this.editModel.data.eye = option?.key as IGlSide;
      delete this.editModel.data.order;
    }
  }

  isLeft(procedure: PatientProcedureInHouse) {
    return this.hasSide(procedure, "left");
  }

  isRight(procedure: PatientProcedureInHouse) {
    return this.hasSide(procedure, "right");
  }

  getProcedureSummary(procedure: PatientProcedureInHouse, side: IGlSide) {
    const data = procedure.data;
    const eyeDetails = side === "left" ? data.left : data.right;
    const nameStr = get(data, "nameAppendix.name");
    const otherStr = get(data, "name_other");
    const complication = !eyeDetails
      ? ""
      : eyeDetails.routine
      ? "Routine"
      : "Complicated";
    return (
      complication
        ? `${complication} ${otherStr ? otherStr : nameStr}`
        : `${otherStr || nameStr}`
    ).trim();
  }

  getProcedureEyeOrder(procedure: PatientProcedureInHouse) {
    // order of procedure if it counts
    switch (procedure?.data?.order) {
      case "left_right":
        return "(L -> R)";
      case "right_left":
        return "(R -> L)";
      default:
        return "";
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getProcedureDate(procedure: PatientProcedureInHouse, side: IGlSide) {
    return procedure.procedure_date;
  }

  getProcedureBackground(procedure: PatientProcedureInHouse, side: IGlSide) {
    const completionDate = procedure.procedure_date;
    const details: IDayProcedureDetail = procedure.data[side];
    if (procedure.status === "historical") {
      return "bg-secondary";
    } else if (!completionDate) {
      return "bg-warning";
    } else if (completionDate && (!details || (details && details.routine))) {
      // if complete  & routine then green
      return "alert-success";
    } else if (completionDate && "routine" in details && !details.routine) {
      // if complete & complicated then red
      return "alert-danger";
    }
  }

  openDatepicker() {
    this.openDatePicker = true;
  }

  saveClicked() {
    this.saveInProgress = true;
    if (this.historicalEditableOnly) {
      this.editModel = { ...this.editModel, status: "historical" };
    }

    const savePromise = this.editModel.id
      ? this.PatientProcedureService.updateInHouseProcedure(
          this.record?.id,
          this.editModel
        )
      : this.PatientProcedureService.createInHouseProcedure(
          this.record?.id,
          this.editModel
        );
    savePromise
      .then(() => this.cancelClicked())
      .finally(() => (this.saveInProgress = false));
  }

  cancelClicked() {
    this.editModel = undefined;
    this.showEditForm = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(procedure: PatientProcedureInHouse) {
    const shouldDelete = this.$window.confirm(
      "Are you sure you want to remove this procedure?"
    );
    if (shouldDelete) {
      this.deleteInProgress = true;
      this.PatientProcedureService.deleteInHouseProcedure(
        this.record?.id,
        this.editModel
      )
        .then(() => this.cancelClicked())
        .finally(() => (this.deleteInProgress = false));
    }
  }

  private parseDateString(dateStr: string | Date) {
    // need to convert the procedure_date to a date object
    // older procedures have SQL date string formats
    if (isString(dateStr) && dateStr.includes("Z")) {
      // ISO8601 String
      return new Date(dateStr);
    } else if (isString(dateStr)) {
      return parseServerDate(dateStr).toDate();
    } else {
      return dateStr;
    }
  }
}

export class DayProceduresComponent implements angular.IComponentOptions {
  static selector = "dayProcedures";
  static template = require("./day-procedures.html");
  static controller = DayProceduresController;
  static bindings = {
    record: "<",
    mode: "@?",
    isEditable: "<?",
    procedures: "<",
    historicalEditableOnly: "<?",
  };
}
