import { IPatientResource } from "app/core/services/patient.service";
import { Dropin } from "braintree-web-drop-in";

class ModalDocumentController implements angular.IOnInit {
  resolve: any;
  patient: IPatientResource;
  braintreeInstance: Dropin;
  paymentReady = false;
  cardReady = false;
  updateCardInProgress = false;
  close: (args: any) => void;

  constructor(private $scope: angular.IScope) {
    "ngInject";
  }

  $onInit() {
    this.patient = this.resolve.patient;
  }

  ////////////////

  braintreeReady(instance: Dropin) {
    this.braintreeInstance = instance;
    this.braintreeOnCreate(instance);
  }

  updateCard() {
    this.updateCardInProgress = true;
    this.braintreeInstance
      .requestPaymentMethod()
      .then((payload) =>
        this.patient.$updateCreditCard({
          token: payload.nonce,
        })
      )
      .then(() => {
        this.close({ $value: true });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // required as the braintree callback kicks outside angular zone
        this.$scope.$evalAsync(() => {
          this.updateCardInProgress = false;
        });
      });
  }

  validateCard() {
    this.braintreeInstance.requestPaymentMethod().catch((err) => {
      console.error("Error occured when validating credit card", err);
    });
  }

  braintreeOnCreate(instance: Dropin) {
    // Form is not submitted by default when paymentMethodNonceReceived is implemented
    this.setCardButtonState(instance.isPaymentMethodRequestable());
    instance.on("noPaymentMethodRequestable", () => {
      this.updatePaymentReady(false);
      this.updateCardReady(false);
    });
    instance.on("paymentMethodRequestable", (event) => {
      this.setCardButtonState(event.paymentMethodIsSelected);
    });
  }

  setCardButtonState(isPaymentMethodRequestable: boolean) {
    if (isPaymentMethodRequestable) {
      this.updatePaymentReady(true);
    } else {
      this.updatePaymentReady(false);
      this.updateCardReady(true);
    }
  }

  updatePaymentReady(val: boolean) {
    this.$scope.$evalAsync(() => {
      this.paymentReady = val;
    });
  }

  updateCardReady(val: boolean) {
    this.$scope.$evalAsync(() => {
      this.cardReady = val;
    });
  }
}

export class UpdateCreditCardComponent implements angular.IComponentOptions {
  static selector = "updateCreditCard";
  static template = require("./update_credit_card.html");
  static controller = ModalDocumentController;

  static bindings = {
    resolve: "<",
    close: "&",
    dismiss: "&",
  };
}
