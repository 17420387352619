import { IOnChangesObject, copy } from "angular";
import {
  Appendix,
  IGlOption,
  ProcedureFormOption,
} from "app/core/services/appendix";
import { ErrorAppendix } from "app/core/services/error-appendix.service";
import { isEmpty, isNil, map, pickBy, set } from "lodash";
import { GlStaff } from "models/user.model";
import { AuthService } from "../../../app/core/services/auth.service";
import "./settings-procedure-options.scss";

// form keys
import { CATARACT_FORM_KEYS } from "../../../app/core/services/appendix";

export class SettingsProcedureOptionsController implements angular.IController {
  user: GlStaff;

  cataractOptionsForm: angular.IFormController;

  /*
    although we should stick to 
    IGlCataractFormSettings here, because select multiple sets data 
    by a value (i.e. string), we have to convert IGlOption[] into 
    string[]

    since IGlOption also has a default variable, we have to extract that 
    then piece it together before saving

    this means that we have to check two separate things
    - which options are selected
    - which of those options are considered a default
  */
  // field_options
  cataractFieldOptionsModel: { [key: string]: string[] } = {};
  // part of defaults for field_options
  cataractFieldOptionsDefaultsModel: { [key: string]: string } = {};

  // fields_to_display
  cataractFieldsToDisplayModel: { [key: string]: boolean } = {};

  // use_by_default
  defaultUseUserCataractOptions: boolean;

  // universal
  updateFormOptionsInProgress: boolean = false;

  // errors?
  emptyCataractFields: string[] = [];

  constructor(
    private toastr: angular.toastr.IToastrService,
    private AuthService: AuthService,
    private ErrorAppendix: ErrorAppendix,
    private appendix: Appendix
  ) {
    "ngInject";
  }

  // init is reset
  // $onInit(): void {
  //   this.getUser();
  // }

  $onChanges(changes: IOnChangesObject): void {
    // on change, copy over the saved form data belonging to the user
    if (changes?.user && this.user) {
      // OPTIONS CONVERSION EDGE CASE
      this._handleCataractFieldOptionsOnChange();
    }
  }

  getUser() {
    return this.AuthService.getUser()
      .then((user) => {
        this.user = user;
        return user;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // reset form options
  resetSelectedFormOptions(form: "cataract" | "injections") {
    if (form === "cataract") {
      // reset field options
      // default will just be selecting all options and setting to true
      for (const formKey of CATARACT_FORM_KEYS) {
        // fetch all options
        const options: IGlOption[] = this.getOptions(formKey.option_key);
        // if found then set all of the related ones to be true
        set(
          this.cataractFieldOptionsModel,
          formKey.key,
          options.map((o) => o.key)
        );
      }

      // reset fields to display
      // default will just be selecting all options and setting to true
      for (const formKey of CATARACT_FORM_KEYS) {
        // do the same for fields to display
        set(this.cataractFieldsToDisplayModel, formKey.key, true);
      }

      // reset defaults
      this.cataractFieldOptionsDefaultsModel = {};

      // default is always true
      this.defaultUseUserCataractOptions = true;

      // reset is a dirty change
      this.cataractOptionsForm.$setDirty();
    }
  }

  // options
  userHasSavedOptions() {
    return !isNil(this?.user?.data?.cataract_form_settings);
  }

  // CATARACTS
  // misc getters
  getCataractFormKeys() {
    return CATARACT_FORM_KEYS;
  }

  getOptions(optionKey: string) {
    const options: IGlOption[] = this.appendix.get(optionKey);

    // return all options that arent other, other will always be there
    return !isNil(options)
      ? options.filter((o) => !["other", "none"].includes(o.key))
      : [];
  }

  // OPTION RELATED STUFF
  // is option selected?
  isOptionSelected(key: string, optionKey: string) {
    return this?.cataractFieldOptionsModel?.[key]?.includes(optionKey);
  }

  // any changes detected
  handleOnOptionChange(key: string, optionKey: string) {
    // if anything was selected or deselected do a quick scan
    const _optionSelected =
      this.cataractFieldOptionsModel?.[key]?.includes(optionKey);

    // if key not in selection and its in the defaults
    if (
      !_optionSelected &&
      this?.cataractFieldOptionsDefaultsModel[key]?.includes(optionKey)
    ) {
      // remove it from defaults if exists
      delete this.cataractFieldOptionsDefaultsModel[key];
    }
  }

  // handle check if field is empty?
  handleCheckIfSomeCataractFieldsEmpty() {
    const emptyCataractKeys: string[] = [];

    // check if all are selected
    for (const formKey of CATARACT_FORM_KEYS) {
      if (
        // if undefined or empty
        (isNil(this?.cataractFieldOptionsModel?.[formKey.key]) ||
          isEmpty(this?.cataractFieldOptionsModel?.[formKey.key])) &&
        // and option is enabled
        this?.cataractFieldsToDisplayModel?.[formKey.key]
      ) {
        emptyCataractKeys.push(formKey.name);
      }
    }

    this.emptyCataractFields = emptyCataractKeys;
  }

  // FIELDS
  // how many of the fields are set to be displayed (i.e. toggle true?)
  getNFieldsToDisplay() {
    const nEnabledFields = pickBy(this.cataractFieldsToDisplayModel, (o) => o);
    return Object.keys(nEnabledFields).length;
  }

  isCataractFieldEnabled(key: string) {
    return this.cataractFieldsToDisplayModel[key];
  }

  // save
  saveUserFormOptions() {
    this.updateFormOptionsInProgress = true;

    // CHANGE KEY TO OBJECT
    // before saving, map them back to IGL option as we need to choose one for default
    const mappedModel = {};
    for (const formKey of Object.keys(this.cataractFieldOptionsModel)) {
      // for each string value assign it back to its IGLOption state
      const _options: IGlOption[] = map(
        this.cataractFieldOptionsModel[formKey],
        (v) => this._convertCataractOptionKeyToObject(formKey, v)
      );

      // then check if default options exists
      const _defaultOptionIndex: number = _options?.findIndex(
        (o) => o?.key === this?.cataractFieldOptionsDefaultsModel[formKey]
      );
      if (_defaultOptionIndex >= 0) {
        set(_options[_defaultOptionIndex], "default", true);
      }
      // then assign
      mappedModel[formKey] = _options.filter((o) => !isNil(o) && !isEmpty(o));
    }

    // then assign
    set(this.user, "data.cataract_form_settings", {
      fields_to_display: Object.keys(
        pickBy(this.cataractFieldsToDisplayModel ?? {}, (display) => display)
      ),
      field_options: mappedModel,
      use_by_default: this.defaultUseUserCataractOptions,
    });

    // then save that over
    return this.AuthService.update(this.user)
      .then((user) => {
        // this.getUser();
        this.user = user;
        this.cataractOptionsForm.$setPristine();
        this.toastr.success("Successfully updated procedure form options!");
      })
      .catch((err) => {
        this.toastr.error("Error occurred whilst saving, please try again.");
        console.error({ err });
      })
      .finally(() => {
        this.updateFormOptionsInProgress = false;
      });
  }

  // given a attribute key, find its option table and convert the option key
  // back to its IGlOption state
  private _convertCataractOptionKeyToObject(fieldKey: string, key: string) {
    const _formKey: ProcedureFormOption =
      this.appendix.getCataractFormKeyByKey(fieldKey);
    const options: IGlOption[] = this.getOptions(_formKey?.option_key);
    return options.find((o) => o.key === key);
  }

  // for field options and defautls
  private _handleUserFieldOptionsAndDefaultsOnChange() {
    const userFieldOptions =
      this?.user?.data?.cataract_form_settings?.field_options;

    // defaults
    const _cataractFieldOptionsModel = {};
    const _cataractFieldOptionsDefaultsModel = {};

    // no user options found? set for all of them
    if (isNil(userFieldOptions) || isEmpty(userFieldOptions)) {
      // default will just be selecting all options and setting to true
      for (const formKey of CATARACT_FORM_KEYS) {
        // fetch all options
        const options: IGlOption[] = this.getOptions(formKey.option_key);
        // if found then set all of the related ones to be true
        set(
          _cataractFieldOptionsModel,
          formKey.key,
          options.map((o) => o.key)
        );
      }
    } else {
      for (const formKey of CATARACT_FORM_KEYS) {
        const { key, option_key } = formKey;
        // if we found the option
        let _foundOptions: IGlOption[] = copy(userFieldOptions?.[key] ?? []);
        // if emtpy or not found, go with all defaults
        _foundOptions = _foundOptions?.length
          ? _foundOptions
          : this.getOptions(option_key);

        // filter by existing ones
        const _filteredOptions =
          this.appendix.filterCataractOptionsByOptionList(key, _foundOptions);

        // set options
        _cataractFieldOptionsModel[key] = map(_filteredOptions, (o) => o.key);
        // check if a default options exists
        // check if any of them is a default option
        const _existingDefaultOption: IGlOption = _filteredOptions?.find(
          (o) => o.default
        );
        // if we find a default option, assign it
        if (_existingDefaultOption?.key) {
          _cataractFieldOptionsDefaultsModel[key] = _existingDefaultOption.key;
        }
      }
    }

    // assign everything
    this.cataractFieldOptionsModel = _cataractFieldOptionsModel;
    this.cataractFieldOptionsDefaultsModel = _cataractFieldOptionsDefaultsModel;
  }

  private _handleUserFieldsToDisplayOnChange() {
    const userFieldsToDisplayOptions =
      this?.user?.data?.cataract_form_settings?.fields_to_display;

    // final assignable ones
    let _cataractFieldsToDisplayModel = {};

    // FIELDS TO DISPLAY
    if (
      isNil(userFieldsToDisplayOptions) ||
      isEmpty(userFieldsToDisplayOptions)
    ) {
      // default will just be selecting all options and setting to true
      for (const formKey of CATARACT_FORM_KEYS) {
        // do the same for fields to display
        set(_cataractFieldsToDisplayModel, formKey.key, true);
      }
    } else {
      // OPTIONS
      // if not empty, just filter based on what exisitng keys overlap
      const _foundOptions = copy(userFieldsToDisplayOptions ?? []);

      // reduce it for the whole lot
      _cataractFieldsToDisplayModel = _foundOptions.reduce((accum, currKey) => {
        accum[currKey] = true;
        return accum;
      }, {});
    }

    this.cataractFieldsToDisplayModel = _cataractFieldsToDisplayModel;
  }

  private _handleCataractFieldOptionsOnChange() {
    // OPTIONS
    this._handleUserFieldOptionsAndDefaultsOnChange();
    // FIELDS TO DISPLAY
    this._handleUserFieldsToDisplayOnChange();

    // use by default or?
    this.defaultUseUserCataractOptions =
      this?.user?.data?.cataract_form_settings?.use_by_default ?? true;
  }
}

export class SettingsProcedureOptionsComponent
  implements angular.IComponentOptions
{
  static selector = "glSettingsProcedureOptions";
  static template = require("./settings-procedure-options.html");
  static controller = SettingsProcedureOptionsController;
  static bindings = {
    user: "<",
  };
}
