import { Clinic } from "../../../models/clinic.model";
import { GlUserTypeString, User } from "../../../models/user.model";
import { API_PATH } from "./api-paths";

const AUTH_PATH = "oauth/token";
const USER_PATH = "/user";
const KEY_ACCESS_TOKEN = "token";
const KEY_REFRESH_TOKEN = "gl_refresh_token";
const KEY_EXPIRES_IN = "authTimestamp";

interface ISession {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface IUserResourceClass
  extends angular.resource.IResourceClass<IUserResource> {
  setClinic: (
    params: any,
    postData: { clinic_id: number; user_id: number }
  ) => IUserResource;
}

export interface IUserResource extends User, angular.resource.IResource<User> {}

export class AuthService {
  static injectionName = "AuthService";
  public user: User;
  public userPath = `${this.API_URL}${API_PATH}${USER_PATH}`;
  public userResource = this.$resource(
    this.userPath,
    { id: "@id" },
    {
      get: { cache: true, method: "GET" },
      setClinic: {
        method: "POST",
        url: `${this.userPath}/set_clinic`,
      },
    }
  ) as IUserResourceClass;
  private userPromise: angular.IPromise<User>;
  private cache: angular.ICacheObject;

  constructor(
    private $http: angular.IHttpService,
    private $window: angular.IWindowService,
    private jwtHelper: angular.jwt.IJwtHelper,
    private API_URL: string,
    private CLIENT_ID: string,
    private CLIENT_SECRET: string,
    private $cacheFactory: angular.ICacheFactoryService,
    private $resource: angular.resource.IResourceService,
    private $q: angular.IQService
  ) {
    "ngInject";
    this.cache = this.$cacheFactory.get("$http");
  }

  public login(email: string, password: string) {
    //
    const url = `${this.API_URL}${AUTH_PATH}`;
    // Token and API version not required for this endpoint
    return this.$http
      .post<ISession>(
        url,
        {
          grant_type: "password",
          client_id: this.CLIENT_ID,
          client_secret: this.CLIENT_SECRET,
          username: email,
          password: password,
          scope: "*",
        },
        {
          skipAuthorization: true,
        }
      )
      .then((response) => {
        const { access_token, expires_in, refresh_token } = response.data;
        this.$window.sessionStorage.setItem(KEY_ACCESS_TOKEN, access_token);
        this.$window.sessionStorage.setItem(
          KEY_EXPIRES_IN,
          expires_in.toString()
        );
        this.$window.sessionStorage.setItem(KEY_REFRESH_TOKEN, refresh_token);

        return this.getUser();
      });
  }

  public logOut() {
    this.$window.sessionStorage.removeItem(KEY_ACCESS_TOKEN);
    this.$window.sessionStorage.removeItem(KEY_EXPIRES_IN);
    this.$window.sessionStorage.removeItem(KEY_REFRESH_TOKEN);
    this.user = undefined;
    this.clearUserDataCache();
  }

  getUser() {
    return this.$http
      .get<User>(this.userPath, { cache: false })
      .then(({ data: user }) => {
        this.user = user;
        return user;
      });
  }

  experimentalFeaturesEnabled() {
    return this?.user?.data?.experimental_features ?? false;
  }

  userIs(type: GlUserTypeString) {
    return this.user?.type && this.user.type.name === type;
  }

  getAccessToken() {
    return this.$window.sessionStorage.getItem(KEY_ACCESS_TOKEN);
  }

  getRefreshToken() {
    return this.$window.sessionStorage.getItem(KEY_REFRESH_TOKEN);
  }

  getExpiresIn() {
    return this.$window.sessionStorage.getItem(KEY_EXPIRES_IN);
  }

  isAuthenticated() {
    return this.jwtHelper.isTokenExpired(this.getAccessToken());
  }

  setClinic(user: User, clinic: Clinic) {
    // when doing this, empty the cache so the next time the user is fetched
    // it returns the correct clinic_id
    this.clearUserDataCache();

    return this.$http
      .post(`${this.userPath}/set_clinic`, {
        clinic_id: clinic.id,
        user_id: user.id,
      })
      .then(() => this.getUser());
  }

  update(user: User) {
    this.clearUserDataCache();
    return this.$http
      .put<User>(`${this.userPath}/${user.id}`, user)
      .then(() => {
        return this.getUser();
      });
  }

  clearUserDataCache() {
    this.cache.remove(this.userPath);
    this.cache.removeAll();
  }
}
