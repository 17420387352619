import { Appointment } from "../../../../models/appointment.model";
import { IGlApiResponse } from "../../../../models/gl-api-response.model";
import { API_PATH_v2 } from "../api-paths";

export class AppointmentService {
  static injectionName = "AppointmentService";

  constructor(private $http: angular.IHttpService, private API_URL: string) {
    "ngInject";
  }

  get(args?: { providerId?: number; patientId?: number; date?: string }) {
    return this.$http
      .get<IGlApiResponse<Appointment[]>>(
        `${this.API_URL}/${API_PATH_v2}/appointments`,
        { params: args }
      )
      .then((response) => response.data.data);
  }

  delete(appointmentId: number) {
    return this.$http.delete(
      `${this.API_URL}/${API_PATH_v2}/appointments/${appointmentId}`
    );
  }
}
