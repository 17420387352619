import { findLast, get, last } from "lodash";
import * as moment from "moment";
import { PatientRecord } from "../../../../../models/patient-record.model";
import "./visit-what.scss";

const GONIO_DATE_PATH = "data.gonio_date";
// eslint-disable-next-line
const GONIO_REPEAT_PATH = "data.repeat";

class VisitWhatController implements angular.IController, angular.IOnChanges {
  recordHistory: PatientRecord[];
  currentRecord: PatientRecord;
  lastManagementPlan: any;
  what: { [what: string]: boolean };
  nextGonioDate: moment.Moment;
  today = moment();

  constructor() {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.recordHistory && this.recordHistory) {
      const lastRecord = this.getLastRecord();
      this.what = get(lastRecord, "data.management.what");
      this.getNextGonioDate();
    }
  }

  gonioWarningClass() {
    if (!this.nextGonioDate) {
      return;
    }

    if (this.nextGonioDate.isBefore(this.today, "day")) {
      return "bg-danger"; // show as danger background
    } else if (
      // moment.subtract mutates the original. TO avoid problems, create a new
      // object and subtract 6 months from that
      moment(this.nextGonioDate)
        .subtract(6, "months")
        .isBefore(this.today, "days")
    ) {
      return "bg-warning";
    }
  }

  getLastRecord() {
    return last(this.recordHistory);
  }

  getNextGonioDate() {
    const lastGonioRecord = findLast(
      this.recordHistory,
      (r) => !!get(r, GONIO_DATE_PATH)
    );
    if (!lastGonioRecord) {
      // there is no previous gonio record so return
      return;
    }
    const gonio_date = get(lastGonioRecord, GONIO_DATE_PATH);
    this.nextGonioDate = moment(gonio_date);
  }
}

export class VisitWhatComponent implements angular.IComponentOptions {
  static selector = "visitWhat";
  static template = require("./visit-what.html");
  static controller = VisitWhatController;
  static bindings = {
    recordHistory: "<",
    currentRecord: "<",
  };
}
