// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { PaymentStep2Component } from "./user-payment-step2";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = ($stateProvider: angular.ui.IStateProvider) => {
  "ngInject";
  $stateProvider.state("main.payment-step2", {
    url: "/patient/:patient_id/payment/step2",
    component: PaymentStep2Component.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.payment-step2", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(PaymentStep2Component.selector, PaymentStep2Component)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
