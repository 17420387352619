// temporary, until https://github.com/Microsoft/TypeScript/issues/10178 is implemented
import * as angular from "angular";
import { UserPaymentStep1Component } from "./user-payment-step1";

/**
 * Import Module Components
 */

/**
 * Module Routes
 */
const routing = (
  $stateProvider: angular.ui.IStateProvider,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
  "ngInject";
  $stateProvider.state("main.payment-step1", {
    url: "/patient/:patient_id/payment/step1",
    component: UserPaymentStep1Component.selector,
    data: {
      requiresLogin: true,
    },
  });
};

/**
 * Module Configuration
 */
const configuration = ($locationProvider: angular.ILocationProvider) => {
  "ngInject";
  $locationProvider.hashPrefix("");
};

export const moduleName =
  // export module name
  angular
    .module("gl.pages.payment-step1", ["ui.router", "gl.core"])

    /**
     * Register Module Components
     */
    .component(UserPaymentStep1Component.selector, UserPaymentStep1Component)

    /**
     * Register Module Configuration
     */
    .config(configuration)
    .config(routing).name;
