import { isFunction } from "angular";
import { IGlSideOrder } from "models/gl-side-order.model";
import { IGlSide } from "../../../../../models/gl-side.model";

class GlButtonEyeOrderController
  implements angular.IController, angular.IOnInit
{
  eye: IGlSide;
  order: IGlSideOrder;
  ngModelCtrl: angular.INgModelController;
  ngChange: () => void;
  disabled = false;

  constructor() {
    "ngInject";
  }

  $onInit() {
    if (!this.ngModelCtrl) {
      return;
    }
    // hook up this control to the ngModel value
    this.ngModelCtrl.$render = () => {
      this.order = this.ngModelCtrl.$modelValue;
    };
  }

  valueDidChange() {
    this.ngModelCtrl.$setViewValue(this.order);
    if (isFunction(this.ngChange)) {
      this.ngChange();
    }
  }
}

export class GlButtonEyeOrderComponent implements angular.IComponentOptions {
  static selector = "glButtonEyeOrder";
  static template = require("./gl-button-eye-order.html");
  static require = {
    ngModelCtrl: "ngModel",
  };
  static controller = GlButtonEyeOrderController;
  static bindings = {
    eye: "<",
    ngChange: "&?",
    disabled: "<?",
  };
}
