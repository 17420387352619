import { isFunction } from "angular";
import { IGlFormMode } from "models/gl-form-mode";

export class ButtonClearController implements angular.IController {
  mode: IGlFormMode;
  clearTooltip: string = "Clear selections";
  onClear: () => void;

  constructor() {
    "ngInject";
  }

  isEditMode() {
    return this.mode === "edit";
  }

  clear() {
    if (isFunction(this.onClear)) {
      this.onClear();
    }
  }
}

export class ButtonClear implements angular.IComponentOptions {
  static selector = "buttonClear";
  static template = require("./button-clear.html");
  static controller = ButtonClearController;
  static bindings = {
    mode: "@",
    clearTooltip: "@?",
    onClear: "&",
  };
}
