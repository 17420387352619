import { GlDiagnosis } from "models/patient-record.model";
import { DiagnosisService } from "../services/diagnosis.service";

export const pipeName = "patientDiagnosis";
export const pipe: angular.Injectable<angular.FilterFactory> = (
  DiagnosisService: DiagnosisService
) => {
  "ngInject";
  return (
    diagnosisArray: GlDiagnosis[] = [],
    previousDiagnosis?: GlDiagnosis[]
  ) => {
    if (diagnosisArray && diagnosisArray.length > 0) {
      return diagnosisArray
        .map(diagnosis => DiagnosisService.getDiagnosis(diagnosis))
        .join(", ");
    } else if (previousDiagnosis && previousDiagnosis.length > 0) {
      return previousDiagnosis
        .map(d => DiagnosisService.getDiagnosis(d))
        .join(", ");
    }
  };
};
