import { AuthService } from "../../../services/auth.service";
import { GlFormController } from "../../gl-form-controller";
import { DataService } from "../../../services/data.service";

export class GlDefaultsController
  extends GlFormController
  implements angular.IController, angular.IOnInit
{
  static $inject = [AuthService.injectionName, DataService.injectionName];

  /** Inputs */
  model: any;
  defaultsAppendixKey: string;
  pathToValFromHistory: string;
  /** End of Inputs */

  constructor(
    private AuthService: AuthService,
    private DataService: DataService
  ) {
    super();
  }

  $onInit(): void {
    if (
      this.shouldLoadDefaultsOnInit() &&
      this.defaultsAppendixKey &&
      !this.model
    ) {
      this.model = this.DataService.getDefaultForPath(
        this.pathToValFromHistory,
        this.defaultsAppendixKey
      );
    }
  }

  private shouldLoadDefaultsOnInit() {
    return (
      this.AuthService.userIs("ophthalmologist") ||
      this.AuthService.userIs("optometrist")
    );
  }
}

export class GlDefaultsComponent implements angular.IComponentOptions {
  static selector = "glDefaults";
  static template = "";
  static controller = GlDefaultsController;
  static bindings = {
    model: "=",
    pathToValFromHistory: "@",
    defaultsAppendixKey: "@",
  };
}
