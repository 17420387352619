import { isFunction } from "angular";
import { Appendix, IGlButtonOption } from "app/core/services/appendix";
import { isEmpty, isNil } from "lodash";

export class GlButtonCustomController
  implements angular.IController, angular.IOnInit
{
  field: any; // ngModel
  ngModelCtrl: angular.INgModelController; // controller
  disabled = false;

  optionsKey: string;
  customOptions: IGlButtonOption[];

  // main options after init
  options: IGlButtonOption[];

  setViewValue: (arg: { option: IGlButtonOption }) => void;
  ngChange: (arg: { option: IGlButtonOption }) => void;
  isDisabled: (arg: { option: IGlButtonOption }) => boolean;

  constructor(private appendix: Appendix) {
    "ngInject";
  }

  $onInit() {
    if (!this.ngModelCtrl) {
      return;
    }

    // if custom options are defined
    if (!isNil(this.customOptions) && !isEmpty(this.customOptions)) {
      this.options = this.customOptions;
    } else {
      // oherwise use from options key
      this.options = this.appendix.getButtonOptionsByKey(this.optionsKey);
    }
    // hook up this control to the ngModel value
    this.ngModelCtrl.$render = () => {
      this.field = this.ngModelCtrl.$modelValue;
    };
  }

  valueDidChange(option: IGlButtonOption) {
    // set view value
    this.ngModelCtrl.$setViewValue(this.field);
    // ng change
    if (isFunction(this.ngChange)) {
      this.ngChange({ option });
    }
  }

  checkIsDisabled(option: IGlButtonOption) {
    if (isFunction(this.isDisabled)) {
      return this.isDisabled({ option }) ?? false;
    }

    return false;
  }
}

export class GlButtonCustomComponent implements angular.IComponentOptions {
  static selector = "glButtonCustom";
  static template = require("./gl-button-custom.html");
  static require = {
    ngModelCtrl: "ngModel",
  };
  static controller = GlButtonCustomController;
  static bindings = {
    optionsKey: "<",
    customOptions: "<?",
    ngChange: "&",
    isDisabled: "&",
  };
}
