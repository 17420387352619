export const pipeName = "drugAuthDescSimplify";
export const drugAuthDescSimplify: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (
    desc?: string,
    completeWords: boolean = false,
    maxChars: number = 180,
    truncateText: string = "..."
  ) => {
    if (!desc) {
      return "";
    }
    // clean
    desc = desc.trim();
    if (completeWords) {
      maxChars = desc.substring(0, maxChars).lastIndexOf(" ");
    }
    return desc.length > maxChars
      ? `${desc.substring(0, maxChars)}${truncateText}`
      : desc;
  };
};
