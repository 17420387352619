import { Clinic } from "../../../models/clinic.model";
import { API_PATH, API_PATH_v2 } from "./api-paths";
import { IGlApiResponse } from "../../../models/gl-api-response.model";
import { User, IGlUserType, Patient } from "../../../models/user.model";
import { GlClinicActiveRecord } from "../../../models/clinic-active-records.model";
import { AuthService } from "./auth.service";

export interface IClinicResourceClass
  extends angular.resource.IResourceClass<IClinicResource> {
  getAllClinics: () => IClinicResource[];
}

export interface IClinicResource
  extends Clinic,
    angular.resource.IResource<Clinic> {}

export class ClinicService {
  static injectionName = "ClinicService";
  basePath = `${this.API_URL}${API_PATH}`;
  v2BasePath = `${this.API_URL}${API_PATH_v2}`;
  clinicPath = `${this.basePath}/clinic`;
  getAllClinicsPath = `${this.basePath}/clinics_basic`;
  clinicResource = this.$resource(
    this.clinicPath,
    { id: "@id" },
    {
      getAllClinics: {
        method: "get",
        url: this.getAllClinicsPath,
        isArray: true,
        cache: true,
      },
    }
  ) as IClinicResourceClass;
  private cache = this.$cacheFactory.get("$http");

  constructor(
    private $cacheFactory: angular.ICacheFactoryService,
    private $http: angular.IHttpService,
    private $resource: angular.resource.IResourceService,
    private API_URL: string,
    private AuthService: AuthService
  ) {
    "ngInject";
  }

  getRecordsForClinic({
    clinic,
    clinicId,
    date,
    activeRecords,
    includeVirtualReviews,
    filterPatientsByProvider,
  }: {
    clinic?: Clinic;
    clinicId?: number;
    activeRecords?: boolean;
    date?: string;
    includeVirtualReviews?: boolean;
    filterPatientsByProvider?: number;
  }) {
    const id = clinicId || clinic.id;
    const params: any = {};
    if (includeVirtualReviews) {
      params.include_reviews = 1;
    }

    if (date) {
      params.date = date;
    }
    if (activeRecords) {
      params.activeRecords = 1;
    } else {
      params.activeRecords = 0;
    }

    if (filterPatientsByProvider) {
      params.providerId = filterPatientsByProvider;
    }

    return this.$http
      .get<IGlApiResponse<GlClinicActiveRecord[]>>(
        `${this.v2BasePath}/clinics/${id}/records`,
        {
          params,
        }
      )
      .then((response) => response.data.data);
  }

  getClinicReferrals({
    clinic,
    clinicId,
    filterPatientsByProvider,
  }: {
    clinic?: Clinic;
    clinicId?: number;
    activeRecords?: boolean;
    filterPatientsByProvider?: number;
  }) {
    const id = clinicId || clinic.id;
    const params: any = { referrals: "sent" };
    if (filterPatientsByProvider) {
      params.providerId = filterPatientsByProvider;
    }
    const sentClinicReferrals = this.$http
      .get<IGlApiResponse<GlClinicActiveRecord[]>>(
        `${this.v2BasePath}/clinics/${id}/records`,
        {
          params,
        }
      )
      .then((response) => response.data.data);
    return sentClinicReferrals;
  }

  getClinicResultsByReviewStatus({
    clinic,
    clinicId,
    filterPatientsByProvider,
  }: {
    clinic?: Clinic;
    clinicId?: number;
    activeRecords?: boolean;
    filterPatientsByProvider?: number;
  }) {
    const id = clinicId || clinic.id;
    const params: any = { review_status: "NEEDS_REVIEW" };
    if (filterPatientsByProvider) {
      params.providerId = filterPatientsByProvider;
    }
    const resultsRequiringReview = this.$http
      .get<IGlApiResponse<Patient[]>>(
        `${this.v2BasePath}/clinics/${id}/records`,
        {
          params,
        }
      )
      .then((response) => {
        return response.data.data;
      });
    return resultsRequiringReview;
  }

  getClinicReceivedReferrals({
    clinic,
    clinicId,

    filterPatientsByProvider,
  }: {
    clinic?: Clinic;
    clinicId?: number;
    activeRecords?: boolean;
    filterPatientsByProvider?: number;
  }) {
    const id = clinicId || clinic.id;
    const params: any = { referrals: "received" };
    if (filterPatientsByProvider) {
      params.providerId = filterPatientsByProvider;
    }
    const receivedClinicReferrals = this.$http
      .get<IGlApiResponse<GlClinicActiveRecord[]>>(
        `${this.v2BasePath}/clinics/${id}/records`,
        {
          params,
        }
      )
      .then((response) => {
        return response.data.data;
      });
    return receivedClinicReferrals;
  }

  getAll() {
    return this.clinicResource.getAllClinics();
  }

  getClinicDetails(clinicId: number) {
    return this.$http
      .get<Clinic>(`${this.basePath}/clinic/${clinicId}`)
      .then((response) => response.data);
  }

  getProvidersForClinic(clinic: Clinic) {
    return this.$http
      .get<IGlApiResponse<User[]>>(
        `${this.v2BasePath}/clinics/${clinic.id}/staff`,
        {
          params: {
            type: "providers",
          },
        }
      )
      .then((response) => response.data.data);
  }

  getStaffForClinic(clinic: Clinic) {
    return this.$http
      .get<IGlApiResponse<User[]>>(
        `${this.v2BasePath}/clinics/${clinic.id}/staff`
      )
      .then((response) => response.data.data);
  }

  deleteUser(user: User) {
    const url = `${this.basePath}/user/${user.id}`;
    return this.$http.delete<User>(url).then((response) => response.data);
  }

  getUserTypes() {
    return this.$http
      .get<IGlUserType[]>(`${this.basePath}/user/types`, { cache: true })
      .then((response) => response.data);
  }

  updateUserClinicDetails(
    user: User,
    clinic: Clinic,
    {
      provider_number,
      prescription_address,
    }: { provider_number?: string; prescription_address?: string }
  ) {
    return this.$http
      .put<IGlApiResponse<string>>(
        `${this.v2BasePath}/clinics/${clinic.id}/staff/${user.id}`,
        {
          ...(provider_number && { provider_number }),
          ...(prescription_address && { prescription_address }),
        }
      )
      .then(() => {
        this.AuthService.clearUserDataCache();
      });
  }

  clearCache() {
    this.cache.remove(this.clinicPath);
    this.cache.remove(this.getAllClinicsPath);
  }
}
