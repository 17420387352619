import { IComponentOptions, IController, IOnChanges, IOnInit } from "angular";

class VirtualReviewConfirmationController
  implements IController, IOnInit, IOnChanges
{
  // @Input()

  // @Output()
  dismiss: (args?: { $value: any }) => void;
  close: (args?: {
    $value: { skipOphthalReview: boolean; virtualReviewComment?: string };
  }) => void;

  // Controller Bindings
  virtualReviewComment: string;

  constructor() {
    "ngInject";
  }

  $onInit() {
    // not empty
  }

  $onChanges() {
    // not empty
  }

  signWithoutVirtualReview() {
    this.close({ $value: { skipOphthalReview: true } });
  }

  signAndCreateVirtualReview() {
    this.close({
      $value: {
        skipOphthalReview: false,
        virtualReviewComment: this.virtualReviewComment,
      },
    });
  }
}

export class VirtualReviewConfirmationComponent implements IComponentOptions {
  static selector = "glVirtualReviewConfirmation";
  static template = require("./virtual-review-confirmation.component.html");
  static controller = VirtualReviewConfirmationController;
  static bindings = {
    dismiss: "&", // automatically injected by angular.ui.bootstrap modal
    close: "&", // automatically injected by angular.ui.bootstrap modal
  };
}
