import { isFunction } from "angular";
import { patient_import_file_types } from "../../../../../config/environment";
import { User } from "../../../../../models/user.model";
import { AuthService } from "../../../../core/services/auth.service";

class PatientListImportController implements angular.IController {
  user: User;
  importDidComplete: () => void;
  dzOptions = {
    acceptedFiles: patient_import_file_types,
    url: this.getFileUrl.bind(this),
    headers: {
      authorization: `Bearer ${this.AuthService.getAccessToken()}`,
    },
  };
  dzCallbacks = {
    success: this.dzOnSuccess.bind(this),
    error: this.dzOnError.bind(this),
  };
  dzMethods: {
    removeFile?: (file: Dropzone.DropzoneFile) => void;
    removeAllFiles?: (cancelIfNecessary?: boolean) => void;
  } = {};

  constructor(
    private AuthService: AuthService,
    private API_URL: string,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  // eslint-disable-next-line
  getFileUrl(file: Dropzone.DropzoneFile) {
    return `${this.API_URL}api/v2/clinics/${this.user.clinic_id}/import`;
  }

  // eslint-disable-next-line
  dzOnSuccess(file: Dropzone.DropzoneFile, response: any) {
    this.toastr.success("Successfully imported files");
    this.dzMethods.removeFile(file);
    if (isFunction(this.importDidComplete)) {
      this.importDidComplete();
    }
  }

  // eslint-disable-next-line
  dzOnError(file: Dropzone.DropzoneFile, errorMessage: string, error: any) {
    console.error();
    this.toastr.error("An error occurred whilst importing the file.");
    this.dzMethods.removeFile(file);
  }
}

export class PatientListImportComponent implements angular.IComponentOptions {
  static selector = "glPatientListImport";
  static template = require("./patient-list-import.html");
  static controller = PatientListImportController;
  static bindings = {
    user: "<",
    importDidComplete: "&",
  };
}
