import { GlFormController } from "../../gl-form-controller";

class GlDocumentController
  extends GlFormController
  implements angular.IController {
  ngModel: any;
  ngModelCtrl: angular.INgModelController;

  title: string;

  constructor() {
    "ngInject";
    super();
  }

  textAreaOnChange() {
    this.ngModelCtrl.$setViewValue(this.ngModel);
  }
}

export class GlTextarea implements angular.IComponentOptions {
  static selector = "glTextarea";
  static template = require("./gl-textarea.html");
  static require = {
    ngModelCtrl: "ngModel",
  };
  static bindings = {
    isEditable: "<?",
    mode: "@?",
    ngModel: "<",
    title: "@",
  };
  static controller = GlDocumentController;
}
