import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import {
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import { IPatientResource } from "../../../services/patient.service";

class SegmentDilationRecordController
  implements angular.IController, angular.IOnChanges
{
  dilationRecord: PatientRecord;
  collapsed: boolean;
  debug = false;
  showPrint = false;
  patient: IPatientResource;
  inHouseProcedures: PatientProcedureInHouse[];
  inHouseProceduresToDisplay: PatientProcedureInHouse[];
  externalProcedures: PatientProcedureExternal[];
  externalProceduresToDisplay: PatientProcedureExternal[];
  recordHistory: PatientRecord[];

  constructor(
    public PatientRecordService: PatientRecordService,
    private PatientProcedureHelperService: PatientProcedureHelperService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.dilationRecord || changes.inHouseProcedures) {
      this.updateInHouseProceduresForRecord();
    }
    if (changes.dilationRecord || changes.externalProcedures) {
      this.updateExternalProceduresForRecord();
    }
  }

  updateInHouseProceduresForRecord() {
    if (!(this.dilationRecord && this.inHouseProcedures)) {
      return;
    }
    this.inHouseProceduresToDisplay =
      this.PatientProcedureHelperService.getInHouseProceduresForRecord(
        this.dilationRecord,
        this.inHouseProcedures
      );
  }

  updateExternalProceduresForRecord() {
    if (!(this.dilationRecord && this.inHouseProcedures)) {
      return;
    }
    this.externalProceduresToDisplay =
      this.PatientProcedureHelperService.getExternalProceduresForRecord(
        this.dilationRecord,
        this.externalProcedures
      );
  }
}

export class SegmentDilationRecord implements angular.IComponentOptions {
  static selector = "segmentDilationRecord";
  static template = require("./segment-dilation-record.html");
  static bindings = {
    collapsed: "<",
    consolidatedRecord: "<?",
    debug: "<?",
    drops: "<?",
    externalProcedures: "<?",
    inHouseProcedures: "<?",
    patient: "<?",
    showPrint: "<?",
    dilationRecord: "<",
    recordHistory: "<",
  };
  static controller = SegmentDilationRecordController;
}
