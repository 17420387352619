import {
  IComponentController,
  IComponentOptions,
  IOnChanges,
  copy,
  isFunction,
} from "angular";
import { defaultsDeep, indexOf, isNil } from "lodash";
import { IGlPrescriptionViewMode } from "models/gl-prescription-view-mode";
import {
  GlPrescription,
  GlPrescriptionDrugData,
} from "models/prescription.model";
import { GlFormController } from "../../gl-form-controller";
import "./gl-prescription.scss";

class GlPrescriptionController
  extends GlFormController
  implements IComponentController, IOnChanges
{
  prescription: GlPrescription;
  prescriptionModel: GlPrescription;
  recordId: number;
  highlightedDrugIndex: number;
  highlightedDrug: GlPrescriptionDrugData;
  prescriptionsViewMode: IGlPrescriptionViewMode;
  onUpdate: (arg: { prescription: GlPrescription }) => void;
  onRePrescribe: (arg: { prescription: GlPrescription }) => void;
  onRePrescribeDrugs: (arg: { drugs: GlPrescriptionDrugData[] }) => void;
  onDelete: (arg: { prescription: GlPrescription }) => void;
  onCancel: () => void;

  constructor() {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject): void {
    // reset back to original if changed
    if (changes.prescription && this.prescription) {
      this.prescriptionModel = copy(this.prescription);
      defaultsDeep(this.prescriptionModel, this.prescription);
    }

    // changes highlighted drug from prescription breakdown
    if (
      (changes.highlightedDrugIndex || changes.prescription) &&
      !isNil(this.highlightedDrugIndex) &&
      this.prescription
    ) {
      this.highlightedDrug = this.prescription.data[this.highlightedDrugIndex];
    }
  }

  rePrescribeFullPrescription() {
    if (isFunction(this.onRePrescribe)) {
      this.onRePrescribe({ prescription: this.prescriptionModel });
    }
  }

  // represcribe with one (just like creating a new one)
  rePrescribeWithDrug() {
    if (isFunction(this.onRePrescribe)) {
      // send as drug
      this.onRePrescribeDrugs({
        drugs: [this.highlightedDrug],
      });
    }
  }

  saveClicked() {
    if (isFunction(this.onUpdate)) {
      this.onUpdate({ prescription: this.prescriptionModel });
    }
  }

  hasAuthorityDrugs() {
    return Array(...this.prescription.data).find(
      (drug: GlPrescriptionDrugData) => drug.authority_script && !drug.private
    );
  }

  getDrugs() {
    return !isNil(this.highlightedDrugIndex)
      ? this.prescription.data.filter(
          (d) =>
            indexOf(this.prescription.data, d) !== this.highlightedDrugIndex
        )
      : this.prescription.data ?? [];
  }
}

export class GlPrescriptionComponent implements IComponentOptions {
  static selector = "glPrescription";
  static template = require("./gl-prescription.html");
  static controller = GlPrescriptionController;
  static bindings = {
    prescription: "<",
    onUpdate: "&",
    onRePrescribe: "&",
    onRePrescribeDrugs: "&",
    onCancel: "&",
    onDelete: "&",
    mode: "@?",
    prescriptionsViewMode: "=",
    recordId: "<",
    highlightedDrugIndex: "<",
    updateInProgress: "<",
    rePrescribeInProgress: "<",
  };
}
