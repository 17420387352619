import { get } from "lodash";
import { PatientDocument } from "../../../../../models/patient-document.model";
import { PatientDocumentService } from "../../../services/patient-document.service";

class GlDocumentController implements angular.IController, angular.IOnChanges {
  //@Inputs
  document: PatientDocument;
  preview: boolean;

  _document: PatientDocument;
  loadingDocument = false;

  constructor(private PatientDocumentService: PatientDocumentService) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.document && this.document) {
      // here check if we need to update the signed url
      this.loadingDocument = true;
      this._document = null;
      this.PatientDocumentService.refreshUrl(this.document)
        .then((doc) => (this._document = doc))
        .finally(() => (this.loadingDocument = false));
    }
  }

  hasDocument() {
    return !!get(this._document, "id");
  }

  isPdf() {
    const isPdf =
      this.hasDocument() && get(this._document, "data.extension") === "pdf";
    return isPdf;
  }

  isImg() {
    const validExtensions = ["jpg", "jpeg", "gif", "png"];
    const extension = get(this._document, "data.extension");
    return this.hasDocument() && validExtensions.includes(extension);
  }

  isOther() {
    return this.hasDocument() && !this.isPdf() && !this.isImg();
  }
}

export class GlDocumentComponent implements angular.IComponentOptions {
  static selector = "glDocument";
  static template = require("./gl-document.html");
  static bindings = {
    document: "<",
    preview: "<",
  };
  static controller = GlDocumentController;
}
