import { User, IGlPatientData } from "../../../models/user.model";

const LIFE_EXPECTANCY = [
  { age: 0, male: 80.3, female: 84.4 },
  { age: 1, male: 79.6, female: 83.7 },
  { age: 2, male: 78.6, female: 82.7 },
  { age: 3, male: 77.6, female: 81.7 },
  { age: 4, male: 76.6, female: 80.7 },
  { age: 5, male: 75.6, female: 79.7 },
  { age: 6, male: 74.6, female: 78.7 },
  { age: 7, male: 73.6, female: 77.7 },
  { age: 8, male: 72.7, female: 76.8 },
  { age: 9, male: 71.7, female: 75.8 },
  { age: 10, male: 70.7, female: 74.8 },
  { age: 11, male: 69.7, female: 73.8 },
  { age: 12, male: 68.7, female: 72.8 },
  { age: 13, male: 67.7, female: 71.8 },
  { age: 14, male: 66.7, female: 70.8 },
  { age: 15, male: 65.7, female: 69.8 },
  { age: 16, male: 64.7, female: 68.8 },
  { age: 17, male: 63.7, female: 67.8 },
  { age: 18, male: 62.8, female: 66.8 },
  { age: 19, male: 61.8, female: 65.9 },
  { age: 20, male: 60.8, female: 64.9 },
  { age: 21, male: 59.9, female: 63.9 },
  { age: 22, male: 58.9, female: 62.9 },
  { age: 23, male: 57.9, female: 61.9 },
  { age: 24, male: 57, female: 60.9 },
  { age: 25, male: 56, female: 60 },
  { age: 26, male: 55, female: 59 },
  { age: 27, male: 54.1, female: 58 },
  { age: 28, male: 53.1, female: 57 },
  { age: 29, male: 52.2, female: 56 },
  { age: 30, male: 51.2, female: 55 },
  { age: 31, male: 50.2, female: 54.1 },
  { age: 32, male: 49.3, female: 53.1 },
  { age: 33, male: 48.3, female: 52.1 },
  { age: 34, male: 47.3, female: 51.1 },
  { age: 35, male: 46.4, female: 50.1 },
  { age: 36, male: 45.4, female: 49.2 },
  { age: 37, male: 44.5, female: 48.2 },
  { age: 38, male: 43.5, female: 47.2 },
  { age: 39, male: 42.6, female: 46.3 },
  { age: 40, male: 41.6, female: 45.3 },
  { age: 41, male: 40.7, female: 44.3 },
  { age: 42, male: 39.8, female: 43.4 },
  { age: 43, male: 38.8, female: 42.4 },
  { age: 44, male: 37.9, female: 41.4 },
  { age: 45, male: 37, female: 40.5 },
  { age: 46, male: 36, female: 39.5 },
  { age: 47, male: 35.1, female: 38.6 },
  { age: 48, male: 34.2, female: 37.6 },
  { age: 49, male: 33.3, female: 36.7 },
  { age: 50, male: 32.4, female: 35.8 },
  { age: 51, male: 31.4, female: 34.8 },
  { age: 52, male: 30.5, female: 33.9 },
  { age: 53, male: 29.6, female: 33 },
  { age: 54, male: 28.8, female: 32 },
  { age: 55, male: 27.9, female: 31.1 },
  { age: 56, male: 27, female: 30.2 },
  { age: 57, male: 26.1, female: 29.3 },
  { age: 58, male: 25.3, female: 28.4 },
  { age: 59, male: 24.4, female: 27.5 },
  { age: 60, male: 23.5, female: 26.6 },
  { age: 61, male: 22.7, female: 25.7 },
  { age: 62, male: 21.9, female: 24.8 },
  { age: 63, male: 21, female: 23.9 },
  { age: 64, male: 20.2, female: 23 },
  { age: 65, male: 19.4, female: 22.2 },
  { age: 66, male: 18.6, female: 21.3 },
  { age: 67, male: 17.8, female: 20.4 },
  { age: 68, male: 17, female: 19.6 },
  { age: 69, male: 16.3, female: 18.8 },
  { age: 70, male: 15.5, female: 17.9 },
  { age: 71, male: 14.8, female: 17.1 },
  { age: 72, male: 14, female: 16.3 },
  { age: 73, male: 13.3, female: 15.5 },
  { age: 74, male: 12.6, female: 14.7 },
  { age: 75, male: 11.9, female: 13.9 },
  { age: 76, male: 11.2, female: 13.2 },
  { age: 77, male: 10.6, female: 12.4 },
  { age: 78, male: 9.9, female: 11.7 },
  { age: 79, male: 9.3, female: 11 },
  { age: 80, male: 8.7, female: 10.3 },
  { age: 81, male: 8.2, female: 9.6 },
  { age: 82, male: 7.6, female: 9 },
  { age: 83, male: 7.1, female: 8.3 },
  { age: 84, male: 6.6, female: 7.7 },
  { age: 85, male: 6.1, female: 7.2 },
  { age: 86, male: 5.7, female: 6.6 },
  { age: 87, male: 5.3, female: 6.1 },
  { age: 88, male: 4.9, female: 5.7 },
  { age: 89, male: 4.5, female: 5.2 },
  { age: 90, male: 4.2, female: 4.8 },
  { age: 91, male: 3.9, female: 4.4 },
  { age: 92, male: 3.6, female: 4.1 },
  { age: 93, male: 3.4, female: 3.8 },
  { age: 94, male: 3.2, female: 3.5 },
  { age: 95, male: 3, female: 3.3 },
  { age: 96, male: 2.8, female: 3 },
  { age: 97, male: 2.6, female: 2.9 },
  { age: 98, male: 2.5, female: 2.7 },
  { age: 99, male: 2.4, female: 2.5 },
  { age: 100, male: 2.3, female: 2.4 },
];

function getLifeExpectancy(age: number, gender: "M" | "F") {
  const value = LIFE_EXPECTANCY.find(val => val.age === age);
  return gender === "M" ? value.male : value.female;
}

export const lifeExpectancyPipeName = "lifeExpectancy";
export const lifeExpectancyPipe: angular.Injectable<
  angular.FilterFactory
> = () => {
  "ngInject";
  return (input: User) => {
    if (!(input && input.data && (input.data as IGlPatientData).birth_date)) {
      return "";
    }
    const patientData = input.data as IGlPatientData;
    const dob = new Date(patientData.birth_date * 1000);
    const age = new Date().getFullYear() - dob.getFullYear();
    const gender = patientData.gender;
    return getLifeExpectancy(age, gender);
  };
};
