import { isFunction } from "angular";
import { User } from "../../../../../models/user.model";
import { VACCINE_STATUSES } from "../../../services/appendix";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import {
  IPatientResource,
  PatientService,
} from "../../../services/patient.service";
import "./patient-vaccine.scss";

import angular = require("angular");

class PatientVaccineController implements angular.IController, angular.IOnInit {
  // @Input()

  debug = false;
  collapse: boolean;
  patient: IPatientResource;
  user: User;
  allowEditing: boolean;
  vaccineTypes = VACCINE_STATUSES;
  patientVaccineStatus: any;
  changingVaccineStatus = false;
  onVaccineUpdate: () => void;

  constructor(
    public PatientRecordService: PatientRecordService,
    public PatientService: PatientService,
    private toastr: angular.toastr.IToastrService
  ) {
    "ngInject";
  }

  $onInit() {
    this.patient.$promise.then((patient) => {
      this.vaccineTypes.forEach((vax) => {
        if (vax.key === patient.vaccine_status) {
          this.patientVaccineStatus = vax;
        }
      });
    });
  }

  getVaccineStatusToDisplay(vaccineStatus: string) {
    let vaxName = "";
    this.vaccineTypes.forEach((vax) => {
      if (vax.key === vaccineStatus) {
        vaxName = vax.name;
      }
    });
    return vaxName;
  }

  getVaccineColor(vaccineStatus: string) {
    let vaxColour = "";
    this.vaccineTypes.forEach((vax) => {
      if (vax.key === vaccineStatus) {
        vaxColour = vax.color;
      }
    });
    return vaxColour;
  }

  vaccineChanged() {
    this.toastr.success("Vaccine Status Changed Successfully");
    if (isFunction(this.onVaccineUpdate)) {
      this.onVaccineUpdate();
    }
  }

  vaccineStatusDidChange() {
    this.changingVaccineStatus = true;
    this.PatientService.setVaccineStatus(
      this.patient,
      this.patientVaccineStatus.key
    )
      .then(() => {
        this.vaccineChanged();
      })
      .catch(() => {
        this.toastr.error("Vaccine Status Change Failed");
      })
      .finally(() => {
        this.changingVaccineStatus = false;
      });
  }
}

export class PatientVaccine implements angular.IComponentOptions {
  static selector = "patientVaccine";
  static template = require("./patient-vaccine.html");
  static bindings = {
    patient: "<?",
    allowEditing: "<",
    user: "<",
    onVaccineUpdate: "&?",
    collapse: "<",
  };
  static controller = PatientVaccineController;
}
