import { cloneDeep, isNumber } from "lodash";
import {
  PatientProcedure,
  PatientProcedureDrop,
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";
import { PatientRecord } from "models/patient-record.model";
import * as moment from "moment";

export interface IProceduresResponse {
  laser: PatientProcedureInHouse[];
  surgical: PatientProcedureExternal[];
  drops: PatientProcedureDrop[];
}
export type IDropAction = "started" | "stopped" | "continue" | "prescribed";
export interface IDropWithAction {
  action: IDropAction;
  drop: PatientProcedureDrop;
}

export class PatientProcedureHelperService {
  static injectionName = "PatientProcedureHelperService";

  constructor() {
    "ngInject";
  }

  getInHouseProceduresForRecord(
    record: PatientRecord,
    procedures: PatientProcedureInHouse[] = []
  ) {
    return procedures.filter(
      (proc) => proc.data.completed_in_record_id === record.id
    );
  }

  getExternalProceduresForRecord(
    record: PatientRecord,
    procedures: PatientProcedureExternal[] = []
  ) {
    return procedures
      .filter((proc) => {
        const { created_in_record_id, left, right } = proc.data;
        return (
          created_in_record_id === record.id ||
          (left && left.completed_in_record_id === record.id) ||
          (right && right.completed_in_record_id === record.id)
        );
      })
      .map((proc) => {
        const clonedProcedure = cloneDeep(proc);
        const { left, right } = clonedProcedure.data;
        // now adjust the procedure for the particular record.  Include the left
        // details or right details if it belows to this record
        if (left && left.completed_in_record_id > record.id) {
          delete clonedProcedure.data.left;
        }
        if (right && right.completed_in_record_id > record.id) {
          delete clonedProcedure.data.right;
        }
        return clonedProcedure;
      });
  }

  /**
   * @description this function maps a patient's drops list into a drops list
   * appropriate for this record.  For instance, a patient's drop list will
   * include all the drops they have been on over the duration of their
   * treatment. But for a patient history, this needs to be mapped into the drop
   * list as it would have been at that visit. For instance for a visit 1, a
   * drop that was stopped in visit 2 should have no end date. Similarly a drop
   * that was only started in visit 2 should not be included in the list.
   * @param record
   * @param drops
   */
  getDropListForRecord(record: PatientRecord, drops: PatientProcedureDrop[]) {
    return this.createRecordActionsForDropsList(record, drops)
      .filter(
        (dropWithAction) =>
          !!dropWithAction.action ||
          (!!dropWithAction.drop.data.one_off &&
            dropWithAction.drop.record_id === record.id)
      )
      .map((dropWithAction) => {
        const dropClone = cloneDeep(dropWithAction);
        // now adjust the drop for this particular record.  If it was created in
        // or continued in this record, then it shouldn't have a end date or
        // record
        if (["started", "continue"].includes(dropClone.action)) {
          delete dropClone.drop.data.discontinuation_reason;
          delete dropClone.drop.data.discontinuation_reason_other;
          delete dropClone.drop.data.treatment_end_date;
          delete dropClone.drop.data.treatment_end_record_id;
        }
        return dropClone;
      });
  }

  getDropsForRecord(record: PatientRecord, drops: PatientProcedureDrop[]) {
    return drops.filter((d) => {
      // drops belong to this visit if it was updated in this visit
      if (
        [
          d.record_id,
          d.data.treatment_start_record_id,
          d.data.treatment_end_record_id,
        ].includes(record.id) ||
        !d.data.treatment_end_date
      ) {
        return true;
      }
    });
  }

  createRecordActionsForDropsList(
    record: PatientRecord,
    drops: PatientProcedureDrop[]
  ): IDropWithAction[] {
    return drops.map((drop) => ({
      drop,
      action: this.getDropActionForRecord(record, drop),
    }));
  }

  getDropsForRecordIncludingLegacyDrops(
    record: PatientRecord,
    drops: PatientProcedureDrop[]
  ) {
    return drops.filter((d) => this.dropBelongsToRecord(record, d));
  }

  getDropActionForRecord(
    record: PatientRecord,
    drop: PatientProcedureDrop
  ): IDropAction {
    const cleanedDrop = this.cleanDrops(drop);
    const { treatment_end_date, one_off, treatment_start_record_id } =
      cleanedDrop.data;
    if (!treatment_end_date && this.dropStartedInRecord(cleanedDrop, record)) {
      return "started";
    } else if (
      treatment_end_date &&
      this.dropStoppedInRecord(cleanedDrop, record)
    ) {
      return "stopped";
    } else if (
      this.dropStartedBeforeRecord(cleanedDrop, record) &&
      !treatment_end_date
    ) {
      return "continue";
    } else if (
      this.dropStartedBeforeRecord(cleanedDrop, record) &&
      this.dropStoppedAfterRecord(cleanedDrop, record)
    ) {
      return "continue";
    } else if (one_off && treatment_start_record_id === record.id) {
      return "prescribed";
    }
  }

  getExternalProcedureForRecordByName(
    recordId: number,
    procedureName: string,
    externalProcedures: PatientProcedure[]
  ): PatientProcedureExternal {
    return (externalProcedures ?? []).find(
      (p) =>
        p?.type === "external" &&
        p?.data?.created_in_record_id === recordId &&
        p?.data?.nameAppendix?.name === procedureName
    ) as PatientProcedureExternal;
  }

  private dropStartedInRecord(
    drop: PatientProcedureDrop,
    record: PatientRecord
  ) {
    const recordDate = moment(record?.data_created_at);
    const recordId = record?.id;
    const { treatment_start_date, treatment_start_record_id } = drop.data;
    return treatment_start_record_id
      ? recordId === treatment_start_record_id
      : moment(treatment_start_date).isSame(recordDate, "day");
  }

  private dropStoppedInRecord(
    drop: PatientProcedureDrop,
    record: PatientRecord
  ) {
    const recordDate = moment(record?.data_created_at);
    const recordId = record?.id;
    const { treatment_end_date, treatment_end_record_id } = drop.data;
    return treatment_end_record_id
      ? recordId === treatment_end_record_id
      : moment(treatment_end_date).isSame(recordDate, "day");
  }

  private dropStartedBeforeRecord(
    drop: PatientProcedureDrop,
    record: PatientRecord
  ) {
    const recordDate = moment(record?.data_created_at);
    const recordId = record?.id;
    const { treatment_start_date, treatment_start_record_id } = drop.data;
    return (
      treatment_start_date &&
      (treatment_start_record_id < recordId ||
        moment(treatment_start_date).isBefore(recordDate, "day"))
    );
  }

  private dropStoppedAfterRecord(
    drop: PatientProcedureDrop,
    record: PatientRecord
  ) {
    const recordDate = moment(record?.data_created_at);
    const recordId = record?.id;
    const { treatment_end_date, treatment_end_record_id } = drop.data;
    return (
      treatment_end_date &&
      (treatment_end_record_id > recordId ||
        moment(treatment_end_date).isAfter(recordDate, "day"))
    );
  }

  private newDropBelongsToRecord(
    record: PatientRecord,
    drop: PatientProcedureDrop
  ) {
    return [
      drop.record_id,
      drop.data.treatment_start_record_id,
      drop.data.treatment_end_record_id,
    ].includes(record.id);
  }

  private dropBelongsToRecord(
    record: PatientRecord,
    drop: PatientProcedureDrop
  ) {
    if (drop.record_id) {
      // this is a v2 drop
      return this.newDropBelongsToRecord(record, drop);
    }
    const cleanedDrop = this.cleanDrops(drop);
    const { treatment_start_date, treatment_end_date } = cleanedDrop.data;
    const recordDate = moment(record.data_created_at);
    return (
      moment(treatment_start_date).isSame(recordDate, "day") ||
      moment(treatment_end_date).isSame(recordDate, "day")
    );
  }

  private cleanDrops(drop: PatientProcedureDrop) {
    const { treatment_end_date, treatment_start_date } = drop.data;
    const newDrop = cloneDeep(drop);
    if (isNumber(treatment_start_date)) {
      // convert the UTC timestamp in seconds to a ISO string
      newDrop.data.treatment_start_date = new Date(
        (treatment_start_date as number) * 1000
      ).toISOString();
    }
    if (isNumber(treatment_end_date)) {
      // convert the UTC timestamp in seconds to a ISO string
      newDrop.data.treatment_end_date = new Date(
        (treatment_end_date as number) * 1000
      ).toISOString();
    }
    return newDrop;
  }
}
