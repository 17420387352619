class EditClinicController implements angular.IController {
  constructor() {
    "ngInject";
  }
}

export class EditClinicComponent implements angular.IComponentOptions {
  static selector = "editClinic";
  static template = require("./edit-clinic.html");
  static controller = EditClinicController;
  static bindings = {};
}
