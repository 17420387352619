import { Appendix } from "app/core/services/appendix";
import { PatientReferral } from "models/patient-record.model";
import { DATE_PICKER_ALT_FORMATS } from "../../../../../lib/date_picker_alt_formats";
import {
  GlFormController,
  GlFormControllerBindings,
} from "../../../../core/components/gl-form-controller";
import { PatientRecordService } from "app/core/services/patient-record/patient-record.service";
import { defaults } from "lodash";
import { isFunction } from "angular";

class ReferralConfirmationController
  extends GlFormController
  implements angular.IController, angular.IOnChanges
{
  // @Input()
  referral: PatientReferral;
  updateInProgress: boolean;

  // @Output()
  update: () => void | null;

  referralStatusOptions = this.appendix.get("referralStatus");

  appointmentDate: Date;

  datePickerIsOpen = false;
  // Toggles the state
  datepickerOptions = {
    initDate: new Date(),
    showWeeks: false,
    format: "dd MMM yyyy",
    startingDay: 1,
    formatDay: "dd",
    formatMonth: "MMM",
    formatYear: "yyyy",
    ngModelOptions: {
      timezone: "Australia/Melbourne",
    },
  };
  dataPickerAltFormats = DATE_PICKER_ALT_FORMATS;

  constructor(
    private appendix: Appendix,
    private PatientRecordService: PatientRecordService
  ) {
    "ngInject";
    super();
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.referral && this.referral) {
      defaults(this.referral, { referral_status: {} });
    }
    if (
      changes.referral &&
      typeof this.referral?.referral_status?.appointment_date === "string"
    ) {
      this.referral.referral_status.appointment_date = new Date(
        this.referral?.referral_status.appointment_date
      );
    }
  }

  updateButtonClicked() {
    if (isFunction(this.update)) {
      this.update();
    }
  }
}

export class ReferralConfirmationComponent
  implements angular.IComponentOptions
{
  static selector = "glReferralConfirmation";
  static template = require("./referral-confirmation.component.html");
  static controller = ReferralConfirmationController;
  static bindings = {
    referral: "<",
    update: "&?",
    updateInProgress: "<?",
    ...GlFormControllerBindings,
  };
}
