import { IDropWithAction } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import { PatientProcedureDrop } from "../../../../../models/patient-procedure";

class PatientHistoryDropsController implements angular.IComponentController {
  drops: IDropWithAction[];

  constructor() {
    "ngInject";
  }

  getDiscontinuationReason(drop: PatientProcedureDrop) {
    if (
      drop.data.discontinuation_reason === "Other" &&
      drop.data.discontinuation_reason_other
    ) {
      return drop.data.discontinuation_reason_other;
    }
    return drop.data.discontinuation_reason;
  }
}

export class PatientHistoryDropsComponent implements angular.IComponentOptions {
  static selector = "patientHistoryDrops";
  static template = require("./patient-history-drops.html");
  static controller = PatientHistoryDropsController;
  static bindings = {
    drops: "<"
  };
}
