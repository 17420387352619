import { PatientProcedureHelperService } from "app/core/services/patient-procedure-helper/patient-procedure-helper.service";
import {
  PatientProcedureExternal,
  PatientProcedureInHouse,
} from "models/patient-procedure";
import { PatientRecord } from "../../../../../models/patient-record.model";
import { PatientRecordService } from "../../../services/patient-record/patient-record.service";
import { IPatientResource } from "../../../services/patient.service";

class SegmentVirtualRecordController
  implements angular.IController, angular.IOnChanges {
  virtualRecord: PatientRecord;
  collapsed: boolean;
  debug = false;
  showPrint = false;
  patient: IPatientResource;
  inHouseProcedures: PatientProcedureInHouse[];
  inHouseProceduresToDisplay: PatientProcedureInHouse[];
  externalProcedures: PatientProcedureExternal[];
  externalProceduresToDisplay: PatientProcedureExternal[];
  recordHistory: PatientRecord[];

  constructor(
    public PatientRecordService: PatientRecordService,
    private PatientProcedureHelperService: PatientProcedureHelperService
  ) {
    "ngInject";
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.virtualRecord || changes.inHouseProcedures) {
      this.updateInHouseProceduresForRecord();
    }
    if (changes.virtualRecord || changes.externalProcedures) {
      this.updateExternalProceduresForRecord();
    }
  }

  updateInHouseProceduresForRecord() {
    if (!(this.virtualRecord && this.inHouseProcedures)) {
      return;
    }
    this.inHouseProceduresToDisplay = this.PatientProcedureHelperService.getInHouseProceduresForRecord(
      this.virtualRecord,
      this.inHouseProcedures
    );
  }

  updateExternalProceduresForRecord() {
    if (!(this.virtualRecord && this.inHouseProcedures)) {
      return;
    }
    this.externalProceduresToDisplay = this.PatientProcedureHelperService.getExternalProceduresForRecord(
      this.virtualRecord,
      this.externalProcedures
    );
  }
}

export class SegmentVirtualRecord implements angular.IComponentOptions {
  static selector = "segmentVirtualRecord";
  static template = require("./segment-virtual-record.html");
  static bindings = {
    collapsed: "<",
    consolidatedRecord: "<?",
    debug: "<?",
    drops: "<?",
    externalProcedures: "<?",
    inHouseProcedures: "<?",
    patient: "<?",
    showPrint: "<?",
    virtualRecord: "<",
    recordHistory: "<",
  };
  static controller = SegmentVirtualRecordController;
}
