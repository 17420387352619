import { IController, IOnChanges, IOnInit, isFunction } from "angular";
import { IGlOption } from "app/core/services/appendix";
import { GlStaff } from "models/user.model";
import { APPOINTMENT_REGEX } from "../../../../core/services/appendix";

export type GL_VIEWS =
  | "Glaucoma"
  | "Retina"
  | "Cataract"
  | "Paediatric"
  | "Dry Eye"
  | "Plastics"
  | "All";

const VIEWS: GL_VIEWS[] = [
  "Glaucoma",
  "Retina",
  "Cataract",
  "Plastics",
  "Dry Eye",
  "All",
];
const DEFAULT_VIEW = "Cataract";

const VIEW_SEGMENT_DEFAULTS: Partial<Record<GL_VIEWS, string[]>> = {
  Retina: ["clinical", "documents", "anterior", "todaysDrops", "posterior"],
  Cataract: ["clinical", "documents", "anterior", "todaysDrops", "posterior"],
  // Paediatric: ["general"],
  Plastics: ["general"],
  Glaucoma: [
    "anterior",
    "field",
    "clinical",
    "documents",
    "todaysDrops",
    "gonio",
    "posterior",
  ],
  "Dry Eye": ["dryEye"],
  All: [
    "anterior",
    "field",
    "clinical",
    "documents",
    "todaysDrops",
    "gonio",
    "posterior",
    "dryEye",
  ],
};

class ViewManagerController implements IController, IOnInit, IOnChanges {
  // @Input()
  appointmentNotes: string;
  presentingComplaint: IGlOption;
  selectedView: GL_VIEWS = DEFAULT_VIEW;
  user: GlStaff;

  // @Output()
  openSegment?: (args: { selectedView: string }) => void;

  // Controller Properties
  views = VIEWS;

  constructor() {
    "ngInject";
  }

  $onInit() {
    this.selectedViewDidChange(this.selectedView);
  }

  $onChanges(changes: angular.IOnChangesObject) {
    if (changes.appointmentNotes || changes.presentingComplaint) {
      const view = this.determineDefaultView();
      if (view && view !== this.selectedView) {
        this.selectedViewDidChange(view);
      }
    }
  }

  // For admin/tech & optometrists, show all the segments.  Toggling between
  // views is only for ophthalmologists

  shouldShowSegment(segmentName: string) {
    const defaults: string[] | undefined =
      VIEW_SEGMENT_DEFAULTS[this.selectedView];
    if (
      !defaults ||
      !["optometrist", "ophthalmologist"].includes(this.user.type.name)
    ) {
      return true;
    } else {
      return defaults.includes(segmentName);
    }
  }

  selectedViewDidChange(view: GL_VIEWS) {
    if (view) {
      this.selectedView = view;
      if (isFunction(this.openSegment)) {
        this.openSegment({
          selectedView: this.selectedView,
        });
      }
    }
  }

  private determineDefaultView(): GL_VIEWS {
    if (this.presentingComplaint) {
      switch (this.presentingComplaint.key) {
        case "retina":
          return "Retina";
        case "glaucoma":
          return "Glaucoma";
        case "cataract":
          return "Cataract";
        default:
          return DEFAULT_VIEW;
      }
    } else if (typeof this.appointmentNotes === "string") {
      const lowercaseAppointmentNotes =
        this.appointmentNotes.toLocaleLowerCase();
      const isRetinaRegex = APPOINTMENT_REGEX.Retina.regex;
      const isGlaucomaRegex = APPOINTMENT_REGEX.Glaucoma.regex;
      const isCataractRegex = APPOINTMENT_REGEX.Cataract.regex;

      if (isRetinaRegex.test(lowercaseAppointmentNotes)) {
        return "Retina";
      } else if (isGlaucomaRegex.test(lowercaseAppointmentNotes)) {
        return "Glaucoma";
      } else if (isCataractRegex.test(lowercaseAppointmentNotes)) {
        return "Cataract";
      } else {
        return "Cataract";
      }
    }
  }
}

export class ViewManagerComponent implements angular.IComponentOptions {
  static selector = "glViewManager";
  static template = require("./view-manager.component.html");
  static controller = ViewManagerController;
  static bindings = {
    appointmentNotes: "<",
    openSegment: "&?",
    presentingComplaint: "<",
    selectedView: "<?",
    user: "<",
  };
}
